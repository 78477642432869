import { useCallback, useState, useEffect } from 'react'
import _ from 'lodash'
export function Countdown({ date, message, onComplete }) {
	const calculateTimeLeft = useCallback(() => {
		const difference = +new Date(date) - +new Date()
		if (difference <= 0) return {}

		return {
			days: Math.floor(difference / (1000 * 60 * 60 * 24)),
			hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
			minutes: Math.floor((difference / 1000 / 60) % 60),
			seconds: Math.floor((difference / 1000) % 60)
		}
	}, [date])

	const [timeLeft, setTimeLeft] = useState(() => calculateTimeLeft())
	const [isCompleted, setIsCompleted] = useState(false)

	useEffect(() => {
		if (Object.keys(timeLeft).length === 0) {
			onComplete && onComplete()
			setIsCompleted(true)
			return
		}

		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft())
		}, 1000)

		return () => clearInterval(timer)
	}, [calculateTimeLeft, timeLeft, onComplete])

	const formatTime = () => {
		const parts = []
		if (timeLeft.days > 0) parts.push(`${timeLeft.days}d`)
		if (timeLeft.hours > 0) parts.push(`${timeLeft.hours}h`)
		parts.push(`${timeLeft.minutes}m`)
		parts.push(`${timeLeft.seconds}s`)
		return parts.join(' ')
	}

	return (
		<p className="text-sm text-gray-400 truncate max-w-24">
			{_.isNil(date) ? '' : (isCompleted ? `Time's up!` : `${message} ${formatTime()}`)}
		</p>
	)
}
