import { truncateToDecimals } from 'utils/form.util'
import { ethers } from 'ethers'
import { contractsConfig, defaultNetwork } from '../config/constants'
import {
	sendContractBlockchainTransaction,
	sendRawBlockchainTransaction,
	getContractValue
} from '../magic'
import { getNativeTokenBalance } from '../config/utils'
import _ from 'lodash'

const nativeDecimals = 18
class BuyFanMethods {
	// Write Functions
	static async buyFanWithNative(
		amount,
		transactionNote,
		signer,
		network // `name` field in FanPurchasesNetworks table
	) {
		const trancatedAmount = truncateToDecimals(amount, nativeDecimals)
		const to = contractsConfig.fanPurchaseEscrow.address[network]

		return await sendRawBlockchainTransaction(
			to,
			ethers.utils.parseEther(trancatedAmount.toString()),
			transactionNote,
			signer,
			network
		)
	}

	static async buyFanWithTokenContract(
		tokenDetails, // { tokenName: string, decimals: number }
		amount,
		transactionNote,
		signer,
		network // `name` field in FanPurchasesNetworks table
	) {
		const trancatedAmount = truncateToDecimals(amount, tokenDetails.decimals)
		const to = contractsConfig.fanPurchaseEscrow.address[network]

		return await sendContractBlockchainTransaction(
			tokenDetails.tokenName,
			'transfer',
			[
				to,
				ethers.utils.parseUnits(
					trancatedAmount.toString(),
					tokenDetails.decimals
				)
			],
			transactionNote,
			signer,
			network
		)
	}

	// Read Functions
	static async getBalance(
		address,
		tokenDetails, // { tokenName: string, decimals: number, isNativeToken: boolean }
		network = defaultNetwork
	) {
		if (_.isNil(address)) {
			return 0
		}
		if (!tokenDetails.isNativeToken) {
			const rawBalance = await getContractValue(
				tokenDetails.name,
				'balanceOf',
				[address],
				network
			)
			return ethers.utils.formatUnits(rawBalance, tokenDetails.decimals)
		} else {
			return await getNativeTokenBalance(address, network)
		}
	}
}

export default BuyFanMethods
