import { LightTooltip } from '@/components/common'
import { Link } from 'react-router-dom'
import { Avatar, Box, Icon, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { formatDateToRelative } from 'utils/date.utils'
import styles from './UserInfoTimestamp.module.scss'
import { getFileUrl } from 'utils/url.util'

const UserInfoTimestamp = ({
	updatedAt,
	user,
	project,
	id,
	showInfo = false
}) => {
	const link = project
		? `/project/${project?.slug}?tab=1&newsreelId=${id}`
		: `/profile/${user?.username}?tab=1&newsreelId=${id}`
	const place = project ? project?.title : 'Their Profile'
	const modifier = project ? ' from ' : ' on '

	return (
		<Box className={styles.profile_link}>
			<LightTooltip title={`View ${user?.displayName}'s Profile`}>
				<Link to={`/profile/${user?.username}`}>
					<Avatar
						alt={user?.displayName}
						sx={{
							bgcolor: '#000000',
							border: '1px solid #ffffff',
							height: 35,
							width: 35,
							marginRight: '12px',
							marginTop: '4px'
						}}
						src={getFileUrl(user?.avatar)}
					/>
				</Link>
			</LightTooltip>
			<Box className={styles.info_container}>
				<LightTooltip title={`View ${user?.displayName}'s Profile`}>
					<Link to={`/profile/${user?.username}`}>
						<Box className={styles.display_name}>
							<Typography>{user?.displayName}</Typography>
							{user?.isVerified === 1 && (
								<Icon baseClassName="fas" className="fa-badge-check" />
							)}
						</Box>
					</Link>
				</LightTooltip>
				<Typography className={styles.profession}>
					Posted {formatDateToRelative(updatedAt)}
					{showInfo && (
						<>
							{modifier}
							<Link to={link}>{place}</Link>
						</>
					)}
				</Typography>
			</Box>
		</Box>
	)
}

UserInfoTimestamp.propTypes = {
	updatedAt: PropTypes.string,
	project: PropTypes.object,
	user: PropTypes.object,
	id: PropTypes.string,
	showInfo: PropTypes.bool
}

export default UserInfoTimestamp
