import packageOpen from '@/assets/images/package-open.svg'
import { formatDate } from '@/utils/date.utils'
import { UserPerk } from './SuperfanBenefitsDialog'
import { setSuperfanItemDialogData } from '@/components/header/signInSlice'
import { useDispatch } from 'react-redux'

export const UserPerkItem = ({
	userPerk,
	isCredit
}: {
	userPerk: UserPerk
	isCredit: boolean
}) => {
	const dispatch = useDispatch()
	const handleViewImage = () => {
		dispatch(
			setSuperfanItemDialogData({
				open: true,
				createdAt: userPerk?.createdAt,
				displayName: userPerk?.displayName,
				title: userPerk?.projectTitle
			})
		)
	}
	return (
		<div className="rounded-lg bg-gradient-to-r from-[#8C421D] to-[#D4A041] p-[1px]">
			<div className="rounded-lg bg-[#0A1C2A]">
				<div className="flex items-center justify-between bg-[#2196F3]/10 p-4">
					<div className="flex items-center gap-2">
						<img src={packageOpen.src} alt="" className="h-10 w-10" />
						<div>
							<div className="text-sm font-bold text-white">
								{userPerk?.perk?.name}
							</div>
							<div className="text-xs">
								Snagged{' '}
								<span className="font-semibold">
									{formatDate(userPerk?.createdAt, 'full')}
								</span>
							</div>
						</div>
					</div>
					{isCredit ? (
						<button
							onClick={handleViewImage}
							className="flex items-center gap-1 rounded bg-[#2196F333]/20 px-3 py-2 text-sm text-[#F2BE2F]"
						>
							View
						</button>
					) : (
						<button className="flex items-center gap-1 rounded bg-[#2196F333]/20 px-3 py-2 text-sm text-[#F2BE2F]">
							View
						</button>
					)}
				</div>
			</div>
		</div>
	)
}
