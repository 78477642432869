import { useWelcomeReferralDialog } from './useWelcomeReferralDialog'
import { CustomDialog } from '@/components/common-ui/CustomDialog'
import superfan2 from '@/assets/images/superfan2.svg'
import { Button } from '@/components/ui/button'

const pStyle =
	'font-inter text-sm md:text-base font-normal leading-6 md:leading-7'

export const WelcomeReferralDialog = () => {
	const {
		open,
		projectTitle,
		sharerDisplayName,
		handleBecomeSuperfan,
		handleClose
	} = useWelcomeReferralDialog()

	return (
		<CustomDialog
			open={open}
			onOpenChange={handleClose}
			className="max-w-[560px]"
		>
			<div className="flex flex-col items-center justify-center">
				<div className="flex flex-col items-center gap-2 p-2">
					<img
						src={superfan2.src}
						alt="fan icon"
						className="h-auto max-h-[160px] w-full rounded-lg md:max-h-[260px]"
					/>
					<div className="mt-6 flex flex-col gap-2">
						<p className={pStyle}>
							<strong>Welcome to the family!</strong> You just joined through{' '}
							{sharerDisplayName}
							&apos;s referral link for &quot;<strong>{projectTitle}</strong>
							&quot;, and received 100 $FAN tokens as a welcome gift! Now,
							become a SuperFan of this project!
						</p>
						<p className={pStyle}>
							<strong>SuperFan Status:</strong> Unlock your ticket to fame and
							immortality with a SuperFan Film Credit! See your name
							immortalized in this film&apos;s project page and end credits,
							living on forever as part of cinematic history, as well as other
							exclusive perks!
						</p>
					</div>
				</div>
				<Button
					onClick={handleBecomeSuperfan}
					className="mt-6 flex h-12 w-[160px] items-center justify-center gap-2 rounded-md bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] px-3 py-2 font-inter text-base font-semibold text-white md:h-14 md:w-[190px] md:text-[20px]"
				>
					Superfan Me!
				</Button>
			</div>
		</CustomDialog>
	)
}
