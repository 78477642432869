export const addStakeService = (data: any) => ({
	method: 'post',
	url: '/stake',
	data
})

export const addBlockchainStakeService = (data: any) => ({
	method: 'post',
	url: '/stake/blockchain',
	data
})

export const getProjectStakeService = (
	projectId: string,
	params: any,
	autoexec = true
) => ({
	method: 'get',
	url: `/stake/${projectId}`,
	params,
	autoexec
})

export const getUserStakeLimitService = (projectId: string) => ({
	method: 'get',
	url: `/stake/userLimit/${projectId}`
})

export const getUserStakeTotalService = (projectId: string) => ({
	method: 'get',
	url: `/stake/total/${projectId}`,
	autoexec: true
})

export const unstakeService = (data: any) => ({
	method: 'post',
	url: '/stake/unstake',
	data
})

export const unstakeBlockchainService = (data: any) => ({
	method: 'post',
	url: '/stake/blockchain/unstake',
	data
})

export const validateProjectStakeLimitService = (data: any) => ({
	method: 'post',
	url: '/stake/validateLimit',
	data
})
