import { Button } from '@/components/ui/button'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import useProfileMenu from './useProfileMenu'
import { ProfileMenuItems } from './profileMenuItems'
import { AvatarFilmio } from '@/components/common-ui'
import { cn } from '@/lib/utils'
import useKyc from '@/hooks/useKyc'
import { useEffect, useState } from 'react'
import useProfileMenuItems from './profileMenuItems/useProfileMenuItems'

export const ProfileMenu = ({
	triggerClassName
}: {
	triggerClassName?: string
}) => {
	const { signInUser } = useProfileMenu()
	const { showKyc } = useKyc()
	const { avatar, daoTierRingImg, displayName } = signInUser
	const [isOpen, setIsOpen] = useState(false)
	const { onOpenProfileMenu } = useProfileMenuItems()

	useEffect(() => {
		if (isOpen) {
			onOpenProfileMenu()
		}
	}, [isOpen])

	return (
		<DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
			<DropdownMenuTrigger asChild>
				<Button
					variant="outline"
					size="icon"
					className={cn(
						'relative overflow-hidden rounded-full bg-transparent hover:bg-transparent focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-opacity-50',
						triggerClassName
					)}
				>
					<AvatarFilmio
						ringImage={daoTierRingImg}
						avatar={avatar}
						displayName={displayName}
						className="h-[40px] w-[40px]"
					/>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent
				className="flex w-full flex-col items-start justify-between gap-2 p-3"
				side="left"
				align="start"
				alignOffset={20}
				sideOffset={-20}
			>
				<ProfileMenuItems showKyc={showKyc} />
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
