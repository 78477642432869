import axios from 'axios'
import {
	removeAuthenticated,
	setLoginDialogOpen,
	setShowConfetti,
	updateSignInUser
} from '@/components/header/signInSlice'
import { useDispatch, useSelector } from 'react-redux'
import { claimUserRewardsService } from 'services/auth.service'
import { getUserTierInfoService } from 'services/daoTier.service'
import { getLoggedInUserInfo, logoutService } from 'services/profile.service'
import { addObjectToLocalStorage } from 'utils/utils'
import { useNavigate } from 'react-router-dom'
import useWeb3 from 'hooks/useWeb3'
import { Mixpanel } from 'services/mixpanel.service'
import magic from 'utils/blockchain/config/constants'
import useLoadingMask from 'hooks/useLoadingMask'
import _ from 'lodash'
import { RootState } from '@/store/store'

const useHeader = () => {
	const dispatch = useDispatch()
	const { disconnectAsync } = useWeb3()
	const { showMask, hideMask } = useLoadingMask()
	const { isAuthenticated } = useSelector((state: RootState) => state.signIn)
	const navigate = useNavigate()

	const claimUserRewards = async (userId: string) => {
		await axios(claimUserRewardsService({ userId }))
		await reloadUserInfo()
	}

	const afterFetchUser = (data: any) => {
		addObjectToLocalStorage(data)
		dispatch(updateSignInUser(data))
	}

	const reloadUserInfo = async () => {
		if (!isAuthenticated) {
			return
		}
		loadUserTierInfo()
		const res = await axios(getLoggedInUserInfo())
		afterFetchUser(res.data)
	}

	const loadUserTierInfo = async (cb?: () => void) => {
		if (!isAuthenticated) {
			return
		}
		const daoInfo = await axios(getUserTierInfoService())
		addObjectToLocalStorage(daoInfo?.data)
		dispatch(updateSignInUser(daoInfo?.data))
		cb && cb()
	}

	const showConfetti = (time: number = 5000) => {
		dispatch(setShowConfetti(true))
		setTimeout(() => {
			dispatch(setShowConfetti(false))
		}, time)
	}

	const handleLogout = async () => {
		showMask()
		navigate('/')
		dispatch(removeAuthenticated())
		await axios(logoutService())
		// always disconnect for the case of whitelisted wallet connected
		await disconnectAsync()
		Mixpanel.reset()
		localStorage.clear()
		dispatch(setLoginDialogOpen(true))
		hideMask()
		if (!_.isNil(magic)) {
			await magic?.user?.logout()
		}
	}

	return {
		showConfetti,
		reloadUserInfo,
		loadUserTierInfo,
		claimUserRewards,
		handleLogout
	}
}

export default useHeader
