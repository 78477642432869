'use client'

import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'
import { Icon } from '@mui/material'
import ImageViewer from 'react-simple-image-viewer'
import ImageListItem from '@mui/material/ImageListItem'
import { getFileUrl } from 'utils/url.util'
import styles from './ImageWithViewerNewsreel.scss'

export const ImageWithViewerNewsreel = ({ media }) => {
	const [isViewerOpen, setIsViewerOpen] = useState(false)
	const [currentImage, setCurrentImage] = useState(0)

	const openImageViewer = useCallback(index => {
		setCurrentImage(index)
		setIsViewerOpen(true)
	}, [])

	const closeImageViewer = () => {
		setCurrentImage(0)
		setIsViewerOpen(false)
	}

	const imagesSrc = media.map(el => getFileUrl(el))

	return (
		<>
			{media.map((src, i) => (
				<ImageListItem key={i}>
					<img
						alt=""
						loading="lazy"
						onClick={() => openImageViewer()}
						id="main_image"
						src={getFileUrl(src)}
					/>
				</ImageListItem>
			))}
			{isViewerOpen && (
				<ImageViewer
					src={imagesSrc}
					currentIndex={currentImage}
					onClose={closeImageViewer}
					disableScroll={true}
					leftArrowComponent={
						<Icon baseClassName="fas" className="fa-regular fa-chevron-left" />
					}
					rightArrowComponent={
						<Icon baseClassName="fas" className="fa-regular fa-chevron-right" />
					}
					closeComponent={
						<Icon baseClassName="fas" className="fa-regular fa-xmark" />
					}
					backgroundStyle={{
						backgroundColor: 'rgba(0,0,0,0.75)',
						zIndex: 1000
					}}
					closeOnClickOutside={true}
					className={styles.image_viewer}
				/>
			)}
		</>
	)
}

ImageWithViewerNewsreel.propTypes = {
	media: PropTypes.array
}
