'use client'

import { setBronzeTierData } from '@/components/header/signInSlice'
import { RootState } from '@/store/store'
import useAxiosFetch from 'hooks/useAxiosFetch'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	calculateUserDaoGlobalProgress,
	getUserFullTiersMetricsService
} from 'services/daoTier.service'

const useDaoTiers = () => {
	const [data, setData] = useState<{ tiers: any[]; userMetrics: any }>({
		tiers: [],
		userMetrics: {}
	})
	const [globalProgress, setGlobalProgress] = useState(0)
	const { updateDaoTiers } = useSelector((state: RootState) => state.profile)
	const { user, isAuthenticated } = useSelector(
		(state: RootState) => state.signIn
	)

	const dispatch = useDispatch()

	const [{ loading }, getUserDaoTiers] = useAxiosFetch(
		getUserFullTiersMetricsService(),
		(response: any, error: any) => {
			if (!error) {
				const tiers = response?.tiers.map((tier: any) => {
					return {
						id: tier?.id,
						image: tier?.image,
						title: tier?.name,
						benefits: tier?.benefits,
						date: tier?.tierUser?.achievedDate,
						completed: tier?.tierUser?.completed === 1,
						confirmed: tier?.tierUser?.confirmed === 1,
						pointsThreshold: tier?.pointsThreshold
					}
				})
				const {
					projectsReviewed,
					stakesTotal,
					projectsFollowed,
					refers,
					isVerified
				} = response

				const userMetrics = {
					Review: projectsReviewed,
					Stake: stakesTotal,
					Follow: projectsFollowed,
					Refer: refers,
					'Is User Verified': isVerified,
					'Setup Platform Wallet':
						user?.isEmailVerified === 1 && !_.isNil(user?.walletAddress) ? 1 : 0
				}
				setData({ tiers, userMetrics })
				const bronzeData = tiers.find((el: any) => el?.title === 'Bronze')
				dispatch(setBronzeTierData({ ...bronzeData, userMetrics }))
			}
		}
	)

	const [{ loading: loadingProgress }, calculateProgress] = useAxiosFetch(
		calculateUserDaoGlobalProgress(),
		(data: any, error: any) => {
			if (!error) {
				setGlobalProgress(data?.progress)
			}
		},
		!isAuthenticated
	)

	useEffect(() => {
		if (!_.isNil(user?.id)) {
			getUserDaoTiers()
			calculateProgress()
		}
	}, [user, updateDaoTiers])

	return {
		...data,
		loading,
		loadingProgress,
		getUserDaoTiers,
		globalProgress
	}
}

export default useDaoTiers
