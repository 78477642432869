import { Box, Button, Icon, Typography } from '@mui/material'
import { CustomDialog, LightTooltip } from '@/components/common'
import ConnectWallet from '../connectWallet/ConnectWallet'
import useWhitelistTransferDialog from './useWhitelistTransferDialog'
import { NumericFormat } from 'react-number-format'
import { abbreviateNumberFixed } from 'utils/utils'
import styles from './WhitelistTransferDialog.module.scss'

const TransferContent = ({
	handleChangeForm,
	whitelistAddress,
	handleClickTransfer,
	availableToWithdraw,
	disableWithdraw,
	unlockedTokens,
	lockedRewards,
	stakesTotal,
	amount
}) => {
	return (
		<>
			<Box className={styles.content}>
				<Typography className={styles.transfer_text}>
					Transfer Tokens
					<LightTooltip
						enterTouchDelay={0}
						leaveTouchDelay={4000}
						placement="top"
						title="The Platform FAN Balance is a combination of both locked and unlocked tokens. Unlocked tokens are FAN$ transferred in, and they can be transferred out. Locked tokens are tokens received on the platform."
					>
						<span className={styles.help_icon} aria-haspopup="true">
							<Icon baseClassName="far" className={`fa-circle-info`} />
						</span>
					</LightTooltip>
				</Typography>
				<Box className={styles.input_container}>
					<input
						type="number"
						placeholder="0"
						value={amount}
						onChange={e => {
							handleChangeForm(e.target.value)
						}}
					/>
					<Typography className={styles.address}>
						<span>
							<Icon baseClassName="fas" className="fa-wallet" />
						</span>
						{whitelistAddress}
					</Typography>
				</Box>
				<Box className={styles.bottom}>
					<Typography>
						Unlocked Available $FAN:{' '}
						<NumericFormat
							value={availableToWithdraw}
							displayType="text"
							thousandSeparator=","
							decimalScale={2}
						/>
						<small>
							<br />({' '}
							<Icon baseClassName="far" className="fa-lock-keyhole-open" />{' '}
							{abbreviateNumberFixed(unlockedTokens, 2)},{' '}
							<Icon baseClassName="far" className="fa-hourglass-start" />{' '}
							{abbreviateNumberFixed(lockedRewards ?? 0, 2)} ){' '}
							{abbreviateNumberFixed(stakesTotal ?? 0, 2)} staked
							<br />
							FAN Rewards are subject to
							<LightTooltip
								enterTouchDelay={0}
								leaveTouchDelay={4000}
								placement="bottom"
								title="Reward and Bonus tokens are subject to Film.io's Rewards Release Schedule (RRS) and are released based on your activity, reputation, and time (coming soon)."
							>
								<strong> RRS</strong>
							</LightTooltip>
							<br />
							Staked Tokens are not Transferrable
						</small>
					</Typography>
					<Button onClick={() => handleChangeForm(availableToWithdraw)}>
						Max
					</Button>
				</Box>
			</Box>
			<Button
				className={styles.transfer}
				onClick={handleClickTransfer}
				disabled={disableWithdraw}
				endIcon={
					disableWithdraw && (
						<Icon baseClassName="far" className="fa-spinner fa-spin" />
					)
				}
			>
				Transfer
			</Button>
		</>
	)
}

const KycContent = ({ showKyc, setShowKycMessage }) => {
	return (
		<Box className={styles.kyc_content}>
			<Typography className={styles.title}>
				Complete KYC to Transfer Tokens
			</Typography>
			<Typography>
				To complete your transfer, you must first verify your identity (KYC).
				Please complete the KYC process to proceed with your token transfer.
			</Typography>

			<Box className={styles.footer}>
				<Button onClick={showKyc}>Start KYC Process</Button>
				<Button
					onClick={() => {
						setShowKycMessage(false)
					}}
				>
					Cancel
				</Button>
			</Box>
		</Box>
	)
}

const WhitelistTransferDialog = () => {
	const {
		open,
		amount,
		isConnected,
		stakesTotal,
		showKycMessage,
		lockedRewards,
		unlockedTokens,
		disableWithdraw,
		whitelistAddress,
		availableToWithdraw,
		openConnectWalletModal,
		setOpenConnectWalletModal,
		handleClickTransfer,
		setShowKycMessage,
		handleChangeForm,
		handleClose,
		showKyc
	} = useWhitelistTransferDialog()

	return (
		<CustomDialog
			{...{ open, handleClose, removeX: true, variant: 'whitelist_transfer' }}
		>
			{!isConnected && openConnectWalletModal && (
				<ConnectWallet setOpenConnectWalletModal={setOpenConnectWalletModal} />
			)}
			{showKycMessage ? (
				<KycContent {...{ showKyc, setShowKycMessage }} />
			) : (
				<TransferContent
					{...{
						handleChangeForm,
						whitelistAddress,
						handleClickTransfer,
						availableToWithdraw,
						disableWithdraw,
						unlockedTokens,
						lockedRewards,
						stakesTotal,
						amount
					}}
				/>
			)}
		</CustomDialog>
	)
}

export default WhitelistTransferDialog
