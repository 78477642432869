'use client'

import { CheckCircle, XCircle, Info, AlertCircle } from 'lucide-react'
import { NotificationType } from './notificationSlice'
import { useNotificationDialog } from './useNotificationDialog'
import {
	DialogContent,
	Dialog,
	DialogTitle,
	DialogClose,
	DialogDescription,
	DialogHeader
} from '@/components/ui/dialog'
import { Description } from '@radix-ui/react-dialog'
import { useEffect, JSX } from 'react'

const DEFAULTS: Record<NotificationType, { title: string; icon: JSX.Element }> =
	{
		success: {
			title: 'Success',
			icon: <CheckCircle className="h-8 w-8 text-green-500" />
		},
		error: {
			title: 'Error',
			icon: <XCircle className="h-8 w-8 text-red-500" />
		},
		warning: {
			title: 'Warning',
			icon: <AlertCircle className="h-8 w-8 text-yellow-500" />
		},
		info: {
			title: 'Information',
			icon: <Info className="h-8 w-8 text-blue-500" />
		}
	}

export const NotificationDialog = () => {
	const { notificationState, closeNotification } = useNotificationDialog()
	const { isOpen, type, title, message, buttonText } = notificationState

	const headingText = title ?? DEFAULTS[type as NotificationType]?.title
	const icon = DEFAULTS[type as NotificationType]?.icon
	useEffect(() => {
		if (!isOpen) {
			document.body.style.removeProperty('pointer-events')
		}
	}, [isOpen])

	if (!isOpen) return null

	return (
		<Dialog open={isOpen} onOpenChange={closeNotification}>
			<DialogContent className="rounded-lg border border-[#1b568c] bg-[#0A1C2A] p-4 font-inter">
				<DialogHeader>
					<DialogTitle className="mb-2 flex items-center justify-center gap-2 text-xl font-semibold text-white">
						{icon}
						{headingText}
					</DialogTitle>
				</DialogHeader>
				<div className="flex flex-col items-center text-center">
					<Description className="sr-only">{message}</Description>
					<DialogDescription className="mb-6 text-base text-white">
						{message}
					</DialogDescription>

					<DialogClose asChild>
						<button className="rounded-md bg-blue-600 px-6 py-2 text-base text-white transition-colors hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
							{buttonText}
						</button>
					</DialogClose>
				</div>
			</DialogContent>
		</Dialog>
	)
}
