import { Button, Grid, Icon, Typography } from '@mui/material'
import { NewsreelItem } from './newsreelItem'
import useNewsreelTab from './useNewsreelTab'
import { AddNewsreelForm } from './addNewsreelForm'
import { RecentActivity } from '@/components/projectDetail/dashboardTab/recentActivity'
import { renderProfileActivities } from '@/components/profile/dashboardTab/DashboardTab'
import { useInView } from 'react-intersection-observer'
import { useEffect } from 'react'
import images from 'assets/images'
import styles from './NewsreelTab.module.scss'

export const NEWSREEL_FEATURES = {
	project: 'project',
	profile: 'profile'
}

export const ACTIVITY_TYPES = {
	stake: 'stake',
	follow: 'follow',
	share: 'share'
}

const renderNewsreelItems = (newsreels: any) =>
	newsreels.map((newsreel: any) => (
		<NewsreelItem {...{ ...newsreel }} key={newsreel.id} />
	))

const NewsreelTab = ({ feature }: { feature: string }) => {
	const { ref, inView } = useInView()
	const {
		user,
		loadMore,
		isProject,
		isProfile,
		newsreels,
		emptyMessage,
		loadingNewsreels,
		handleSocialClick,
		hideAddNewsreelForm,
		isOneNewsreelView,
		loadMoreNewsreels
	} = useNewsreelTab(feature)

	useEffect(() => {
		const timer = setTimeout(() => {
			if (inView) {
				loadMoreNewsreels()
			}
		}, 100)
		return () => clearTimeout(timer)
	}, [inView])

	return (
		<div>
			<Grid
				container
				spacing={2}
				rowSpacing={2}
				className={styles.newsreel_container}
			>
				<Grid item md={7} xs={12}>
					{isOneNewsreelView && (
						<Button
							className={styles.back_btn}
							onClick={handleSocialClick(1)}
							startIcon={
								<Icon baseClassName="fas" className="fa-chevron-left" />
							}
						>
							Newsreel Home
						</Button>
					)}
					{!hideAddNewsreelForm && <AddNewsreelForm {...{ feature }} />}
					<Grid item md={12}>
						{newsreels.length > 0 ? (
							renderNewsreelItems(newsreels)
						) : (
							<div className={styles.message_box}>
								<img alt="" src={images.newsreelEmpty} />
								<Typography component={'h1'}>{emptyMessage}</Typography>
							</div>
						)}
					</Grid>
				</Grid>
				<Grid
					className={styles.recent_activity_container}
					item
					md={5}
					spacing={2}
				>
					<h3>Recent Activity</h3>
					{isProject && <RecentActivity />}
					{isProfile && renderProfileActivities(user?.lastActivities)}
				</Grid>
			</Grid>

			{/* Loading trigger */}
			<div ref={ref} className="h-4">
				{!loadMore && newsreels.length > 0 && (
					<div className={styles.loader}>
						Looks like you&apos;ve reached the end of the road
					</div>
				)}
				{loadingNewsreels && (
					<div className={styles.loader}>
						<Icon baseClassName="fas" className="fa-spinner fa-spin-pulse" />
					</div>
				)}
			</div>
		</div>
	)
}

export default NewsreelTab
