export const getSocialNetworks = () => ({
	method: 'get',
	url: '/seed/socialNetworks',
	autoexec: true
})

export const getUserGenders = () => ({
	method: 'get',
	url: '/seed/userGenders',
	autoexec: true
})

export const getProjectTypes = (autoexec = true) => ({
	method: 'get',
	url: '/seed/projectTypes',
	autoexec
})

export const getProjectStatus = (autoexec = true) => ({
	method: 'get',
	url: '/seed/projectStatus',
	autoexec
})

export const getProjectStages = () => ({
	method: 'get',
	url: '/seed/projectStages'
})

export const getTileTypes = () => ({
	method: 'get',
	url: '/seed/tileTypes'
})

export const getTeams = () => ({
	method: 'get',
	url: '/seed/teams',
	autoexec: true
})

export const getDepartments = () => ({
	method: 'get',
	url: '/seed/departments',
	autoexec: true
})

export const getCollaboratorRoles = () => ({
	method: 'get',
	url: '/seed/collaboratorRoles',
	autoexec: true
})

export const getCountries = () => ({
	method: 'get',
	url: '/seed/countries',
	autoexec: true
})

export const getDigestOptionsService = () => ({
	method: 'get',
	url: '/seed/digestEmailOptions',
	autoexec: true
})

export const getTriggeredOptionsService = () => ({
	method: 'get',
	url: '/seed/triggeredEmailOptions',
	autoexec: true
})

export const getFanTransferMaxService = () => ({
	method: 'get',
	url: '/seed/fanTransferMax',
	autoexec: true
})
