import _ from 'lodash'
import { useQueryState } from 'nuqs'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ProjectHero } from './ProjectHero'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { StakesTab } from '../projectDetail/stakesTab'
import { ReviewsTab } from '../projectDetail/reviewsTab'
import { TeamTab } from '../projectDetail/teamTab'
import { StatsBar } from './ProjectStatsBar'

import {
	ProjectCredits,
	ProjectCreatorInfo,
	ProjectStats,
	ProjectTitle,
	ProjectHeader,
	ProjectSuperfan,
	ProjectNewsreel
} from '.'
import { useProjectPage } from './useProjectPage'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { ProjectNoSuperfan } from './ProjectNoSuperfan'
import { useScrollToTop } from '@/hooks/useScrollToTop'
import { ProjectAboutTab } from './tabs/ProjectAboutTab'
import { ProjectSuperfanMobile } from './projectSuperfan/mobile/ProjectSuperfanMobile'
import { SuperfanMetricsDialog } from './projectSuperfan/metrics/SuperfanMetricsDialog'
import useProjectCommon from './useProjectCommon'

export function ProjectPage() {
	useScrollToTop()

	const { slug } = useParams()
	const [tab, setTab] = useQueryState('tab', { defaultValue: 'about' })
	const { loading, project, trailerInfo, isProjectAdmin, reloadProject } =
		useProjectPage({ slug: slug! })
	const { superfanUpdatedEvent } = useSelector(
		(state: RootState) => state.websocket
	)
	const { reloadLeaderboard } = useProjectCommon()

	useEffect(() => {
		if (superfanUpdatedEvent?.projectId === project?.id) {
			reloadProject()
			reloadLeaderboard?.()
		}
	}, [superfanUpdatedEvent])

	const [isActionsDialogOpen, setIsActionsDialogOpen] = useState(false)
	const tabsListRef = React.useRef<HTMLDivElement>(null)

	const handleMoveScrollEnd = () => {
		if (tabsListRef.current) {
			tabsListRef.current.scrollTo({
				left: tabsListRef.current.scrollWidth,
				behavior: 'smooth'
			})
		}
	}

	const handleMoveScrollStart = () => {
		if (tabsListRef.current) {
			tabsListRef.current.scrollTo({
				left: 0,
				behavior: 'smooth'
			})
		}
	}

	if (loading || _.isEmpty(project)) {
		return null
	}

	const {
		id: projectId,
		title,
		likes,
		user: { username, displayName: creatorName, isVerified },
		isFollowedByUser,
		reviewsAvg,
		stakesTotal,
		isSuperfan,
		socialMediaShares,
		decayStatus
	} = project

	return (
		<div className="h-full w-full overflow-y-auto overflow-x-hidden bg-background text-gray-200">
			{isProjectAdmin && (
				<Link
					className="absolute right-10 top-3 z-20 rounded-[50px] bg-[#152d53] px-4 py-1 text-[13px] text-white"
					to={`/project-details/${slug}`}
				>
					Creator View
				</Link>
			)}
			<ProjectHero url={project?.coverImg} />
			<div className="relative z-20 -mt-[22rem] w-full overflow-hidden px-4 pb-28 md:-mt-64 md:px-8 md:pb-8">
				<ProjectHeader>
					<ProjectStats
						projectId={projectId}
						projectDecayStatus={decayStatus}
					/>
					<ProjectTitle projectId={projectId}>{title}</ProjectTitle>
					<ProjectCreatorInfo
						username={username}
						displayName={creatorName}
						isVerified={!!isVerified}
					/>
				</ProjectHeader>

				<StatsBar
					className="my-2 lg:hidden"
					project={project}
					isFollowedByUser={isFollowedByUser}
					likes={likes}
					stakesTotal={stakesTotal}
					reviewsAvg={reviewsAvg}
					socialMediaShares={socialMediaShares}
					onTabChange={setTab}
				/>

				<div className="pointer-events-none absolute right-0 mt-7 h-5 w-20 bg-gradient-to-l from-background from-65% via-45% to-transparent to-100% sm:w-32 md:w-40 lg:w-[850px]" />

				<Tabs value={tab} onValueChange={setTab} className="w-full">
					<TabsList ref={tabsListRef}>
						<TabsTrigger value="about" onClick={handleMoveScrollStart}>
							About
						</TabsTrigger>
						<TabsTrigger value="newsreel" onClick={handleMoveScrollStart}>
							Newsreel
						</TabsTrigger>
						<TabsTrigger value="stakes">Stakes</TabsTrigger>
						<TabsTrigger value="reviews">Reviews</TabsTrigger>
						<TabsTrigger value="team">Team</TabsTrigger>
						<TabsTrigger
							className="md:hidden"
							value="superfan"
							onClick={handleMoveScrollEnd}
						>
							Superfan Credits
						</TabsTrigger>
					</TabsList>
					<div className="mt-6 flex w-full flex-col lg:flex-row lg:space-x-8">
						<TabsContent
							value="about"
							className="w-full space-y-4 overflow-hidden md:space-y-8"
						>
							<ProjectAboutTab project={project} trailerInfo={trailerInfo} />
						</TabsContent>
						<TabsContent
							value="newsreel"
							className="w-full space-y-6 overflow-hidden lg:pr-6"
						>
							<ProjectNewsreel projectId={project.id} />
						</TabsContent>
						<TabsContent
							value="stakes"
							className="w-full space-y-6 overflow-hidden lg:pr-6"
						>
							<StakesTab />
						</TabsContent>
						<TabsContent
							value="reviews"
							className="w-full space-y-6 overflow-hidden lg:-mt-10"
						>
							<ReviewsTab />
						</TabsContent>
						<TabsContent
							value="team"
							className="w-full space-y-6 overflow-hidden lg:pr-6"
						>
							<TeamTab />
						</TabsContent>
						<TabsContent
							value="superfan"
							className="w-full space-y-6 overflow-hidden lg:pr-6"
						>
							<ProjectCredits projectId={projectId} />
						</TabsContent>

						<aside className="space-y-7 overflow-hidden lg:sticky lg:right-[0%] lg:-mt-14 lg:min-w-[489px]">
							<StatsBar
								className="hidden lg:block"
								project={project}
								isFollowedByUser={isFollowedByUser}
								likes={likes}
								stakesTotal={stakesTotal}
								reviewsAvg={reviewsAvg}
								socialMediaShares={socialMediaShares}
								onTabChange={setTab}
							/>

							{isSuperfan ? (
								<ProjectSuperfan
									className="hidden md:flex"
									{...project}
									handleSuperfanActionsClick={() =>
										setIsActionsDialogOpen(true)
									}
								/>
							) : (
								<ProjectNoSuperfan className="hidden md:flex" {...project} />
							)}

							<ProjectSuperfanMobile
								className="md:hidden"
								project={project}
								handleSuperfanActionsClick={() => setIsActionsDialogOpen(true)}
							/>
							
							{project?.isOnPerksProgram && (
							<ProjectCredits
								className="hidden md:block"
								projectId={projectId}
							/>
							)}
							<SuperfanMetricsDialog
								isOpen={isActionsDialogOpen}
								onOpenChange={setIsActionsDialogOpen}
								totalPoints={project?.superfanStats?.totalPoints}
							/>
						</aside>
					</div>
				</Tabs>
			</div>
		</div>
	)
}
