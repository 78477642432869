import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Crown } from 'lucide-react'
import { UserAvatar } from './UserAvatar'
import type { Comment as CommentType } from '../../types'
import { formatDateToRelative } from 'utils/date.utils'
import { InteractionsBar } from './InteractionsBar'

interface CommentProps {
	comment: CommentType
}

export const Comment = ({ comment }: CommentProps) => {
	const { id, reactions } = comment

	const [itemReactions, setItemReactions] = useState(reactions)

	return (
		<div className="p-4">
			<div className="flex items-start gap-3">
				<UserAvatar user={comment.user} size="sm" />
				<div className="flex-1 space-y-1.5">
					<div className="flex items-center gap-2">
						<Link
							className="font-semibold text-white hover:underline"
							to={`/profile/${comment.user.username}`}
						>
							{comment.user.displayName}
						</Link>
						{comment.user.isVerified === 1 && (
							<span className="flex items-center gap-1 rounded-full bg-blue-500/10 px-2 py-0.5 text-xs text-blue-500">
								<Crown className="h-3 w-3" />
								Verified
							</span>
						)}
					</div>
					<div className="text-sm text-gray-400">
						<span>{formatDateToRelative(comment.createdAt)}</span>
					</div>
					<p className="text-white">{comment.content}</p>
					<InteractionsBar
						id={id}
						type={'comment'}
						hasComments={false}
						reactions={itemReactions}
						setReactions={setItemReactions}
					/>
				</div>
			</div>
		</div>
	)
}
