import { CustomDialog } from '@/components/common'
import styles from './onRampDialog.module.scss'
import { useSelector, useDispatch } from 'react-redux'
import { setOnRampDialogOpen } from '../signInSlice'

export const OnRampDialog = () => {
	const { user, onRampDialogOpen } = useSelector(state => state.signIn)
	const dispatch = useDispatch()
	const baseUrl = window.location.origin
	const successURL = encodeURIComponent(
		`${baseUrl}/profile/${user.username}?tab=4`
	)
	const failureURL = encodeURIComponent(
		`${baseUrl}/profile/${user.username}?tab=4`
	)
	const tokenIdentifier = 'fan_polygon'
	const networkIdentifier = 'polygon'

	const iframe = `https://buy.onramper.com?apiKey=${process.env.NEXT_PUBLIC_ONRAMP_KEY}&successRedirectUrl=${successURL}&failureRedirectUrl=${failureURL}
			&themeName=dark&mode=buy&onlyCryptoNetworks=${networkIdentifier}&defaultCrypto=${tokenIdentifier}&wallets=${tokenIdentifier}:${user.walletAddress}`

	const handleClose = () => {
		dispatch(setOnRampDialogOpen(false))
	}

	if (!user?.isOnRamperEnabled) {
		return null
	}

	return (
		<CustomDialog
			{...{ open: onRampDialogOpen, handleClose, variant: 'on_ramp' }}
		>
			<iframe
				className={styles.onRamp}
				src={iframe}
				height="580px"
				width="400px"
				title="Buy FAN"
				allow="accelerometer; autoplay; camera; gyroscope; payment"
			></iframe>
		</CustomDialog>
	)
}
