export const getSocialShareLink = ({
	socialNetwork,
	link
}: {
	socialNetwork: string
	link: string
}) => {
	const socialLinks = {
		linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${link}`,
		facebook: `https://www.facebook.com/sharer/sharer.php?u=${link}`,
		x: `https://twitter.com/intent/tweet?url=${link}`
	}
	return socialLinks[socialNetwork as keyof typeof socialLinks] ?? ''
}

export const getSocialNetworkId = (network: string) => {
	const socialNetworkMap = {
		facebook: 1,
		x: 3,
		linkedin: 4
	}
	return socialNetworkMap[network as keyof typeof socialNetworkMap] ?? null
}
