import _ from 'lodash'
import {
	Box,
	Dialog,
	DialogContent,
	Button,
	Tabs,
	Tab,
	Typography,
	Grid
} from '@mui/material'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import closeIcon from 'assets/images/fanPurchase/close.svg'
import { CustomButton } from '@/components/common'
import CustomSelectComponent from './CustomSelect'
import NetworkSelect from './networkSelect/networkSelect'
import styles from './FanPurchase.module.scss'

import CircularProgress from '@mui/material/CircularProgress'
import { truncateToDecimals } from 'utils/form.util'
import { useFanPurchaseDialog } from './useFanPurchaseDialog'

const DialogWithbackground = styled(Dialog)(({ theme }) => ({
	backgroundColor: '#00000052',
	padding: '20px 18px',
	...theme.typography.body2
}))

const CustomTabs = styled(Tabs)(({ theme }) => ({
	borderBottom: ' 1px solid rgba(255, 255, 255, 0.25)',
	...theme.typography.body2
}))

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	)
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

export const FanPurchaseDialog = () => {
	const {
		fanPurchanseDialogOpen,
		fanPurchaseTransaction,
		value,
		tokenAmount,
		tokenOptions,
		networkOptions,
		network,
		token,
		fanPrice,
		fanAmount,
		walletConnected,
		loadingBuyFan,
		balance,
		loadingBalance,
		paymentIntiated,
		loading,
		purchaseComplete,
		user,
		balanceError,
		selectNetwork,
		selectOption,
		handleChange,
		handleClickConnect,
		handleInputChange,
		handleNavigate,
		handleBuyFan,
		handleBuyAgain,
		handleCloseDialog
	} = useFanPurchaseDialog()

	const baseUrl = window.location.origin
	const successURL = encodeURIComponent(
		`${baseUrl}/profile/${user.username}?tab=4`
	)
	const failureURL = encodeURIComponent(
		`${baseUrl}/profile/${user.username}?tab=4`
	)
	const tokenIdentifier = 'fan_polygon'
	const networkIdentifier = 'polygon'

	const iframe = `https://buy.onramper.com?apiKey=${process.env.NEXT_PUBLIC_ONRAMP_KEY}&successRedirectUrl=${successURL}&failureRedirectUrl=${failureURL}
			&themeName=dark&mode=buy&onlyCryptoNetworks=${networkIdentifier}&defaultCrypto=${tokenIdentifier}&wallets=${tokenIdentifier}:${user.walletAddress}`

	const disableBuyFan =
		!fanAmount ||
		!tokenAmount ||
		!token ||
		loadingBuyFan ||
		!_.isNil(balanceError) ||
		loadingBalance
	const cryptoEnabled = user?.isFanPurchaseWithCryptoEnabled
	const cardEnabled = user?.isOnRamperEnabled
	const cryptoTabIndex = cryptoEnabled ? 0 : 1
	const cardTabIndex = !cryptoEnabled ? 0 : 1
	return (
		<DialogWithbackground
			open={fanPurchanseDialogOpen}
			disableScrollLock={true}
			className={`${styles.funPurchase}`}
			modal="true"
			classes={{
				paper: styles.dialogPaper,
				container: styles.dialogContainer
			}}
			onClose={handleCloseDialog}
		>
			<DialogContent className={styles.dialogContent}>
				<Box sx={{ width: '100%' }}>
					<Box
						className={styles.tabHeader}
						sx={{ borderBottom: 1, borderColor: 'divider' }}
					>
						<img
							className={styles.closeButton}
							onClick={handleCloseDialog}
							src={closeIcon.src}
							alt="close"
						/>

						<CustomTabs
							value={value}
							TabIndicatorProps={{ style: { display: 'none' } }}
							onChange={handleChange}
							centered={true}
							className={styles.tabsList}
							aria-label="basic tabs example"
						>
							{cryptoEnabled && (
								<Tab
									label="CRYPTO"
									className={styles.crypto}
									{...a11yProps(0)}
								/>
							)}
							{cardEnabled && (
								<Tab label="CARD" className={styles.card} {...a11yProps(1)} />
							)}
						</CustomTabs>
					</Box>
					{cardEnabled && (
						<CustomTabPanel
							className={styles.tabContent}
							value={value}
							index={cardTabIndex}
						>
							<Box className={styles.boxs}>
								<iframe
									className={styles.onRamp}
									src={iframe}
									height="580px"
									width="400px"
									title="Buy FAN"
									allow="accelerometer; autoplay; camera; gyroscope; payment"
								></iframe>
							</Box>
						</CustomTabPanel>
					)}

					{cryptoEnabled && networkOptions && network && (
						<CustomTabPanel
							className={styles.tabContent}
							value={value}
							index={cryptoTabIndex}
						>
							<Box className={styles.boxs}>
								{!paymentIntiated && (
									<Box className={styles.network_box}>
										<Box className={styles.secondBoxCrypto}>
											<Typography className={styles.text}>
												Select Network
											</Typography>
										</Box>
										<NetworkSelect
											options={networkOptions}
											selectOption={selectNetwork}
											selectedItem={network}
										/>
									</Box>
								)}
								{!paymentIntiated && (
									<>
										<Box className={styles.firstBox}>
											<Typography className={styles.text}>You Spend</Typography>
											<Box className={styles.rowFlex}>
												<input
													type="number"
													value={
														tokenAmount === 0
															? tokenAmount
															: tokenAmount.toString()
													}
													onChange={handleInputChange}
													placeholder="Enter Amount"
												/>
												<CustomSelectComponent
													options={tokenOptions}
													selectOption={selectOption}
													value={token}
												/>
											</Box>
											<Box>
												<Typography className={styles.underBox}>
													Balance:{' '}
													{loadingBalance ? (
														<CircularProgress
															sx={{ marginLeft: '7px' }}
															size={15}
															color="inherit"
														/>
													) : (
														truncateToDecimals(balance, 6)
													)}
												</Typography>
												{balanceError && (
													<Typography className={styles.underBoxError}>
														{balanceError}
													</Typography>
												)}
											</Box>
										</Box>
										<Box className={styles.secondBox}>
											<Box className={styles.secondBoxCrypto}>
												<Typography className={styles.text}>You Get</Typography>
												<Box className={styles.rowFlex}>
													<Typography component={'h1'}>{fanAmount}</Typography>
													<Button className={styles.sideButton}>FAN</Button>
												</Box>
											</Box>
											<Box>
												<Typography className={styles.underBox}>
													1 FAN ~ {fanPrice} USD
												</Typography>
											</Box>
										</Box>
									</>
								)}
								{loading && (
									<Box className={styles.successBox}>
										<Typography className={styles.text}>
											Your purchase of{' '}
										</Typography>
										<Typography component={'h1'}>
											<span className={styles.green}>
												{fanPurchaseTransaction?.awardedFanAmount}
											</span>{' '}
											FAN Tokens{' '}
										</Typography>
										<Typography className={styles.text}>
											is under way!
										</Typography>
									</Box>
								)}
								{purchaseComplete && (
									<Box className={styles.successBox}>
										<Typography className={styles.text}>
											Congrats! You purchased{' '}
										</Typography>
										<Typography component={'h1'}>
											<span className={styles.green}>
												{fanPurchaseTransaction?.awardedFanAmount}
											</span>{' '}
											FAN Tokens{' '}
										</Typography>
									</Box>
								)}
								<Box
									className={`${styles.bottomBox} ${styles.bottomBoxCrypto}`}
								>
									{!paymentIntiated && (
										<>
											{!walletConnected && (
												<Button
													className={styles.agreeButtonCrypto}
													onClick={handleClickConnect}
												>
													Connect your wallet to BUY
												</Button>
											)}
											{walletConnected && (
												<Button
													className={styles.agreeButton}
													onClick={handleBuyFan}
													disabled={disableBuyFan}
													startIcon={
														loadingBuyFan ? (
															<CircularProgress size={20} color="inherit" />
														) : null
													}
												>
													{loadingBuyFan ? 'Processing' : 'Buy FAN'}
												</Button>
											)}
										</>
									)}
									{paymentIntiated && (
										<Box className={styles.buttonSuccessGrid}>
											<Grid className={styles.buttonSuccessBox}>
												<Button
													onClick={handleBuyAgain}
													className={
														loading
															? styles.loadingButton
															: `${styles.agreeButton} ${styles.customHeight}`
													}
													disabled={loading}
													startIcon={
														loading ? (
															<CircularProgress size={20} color="inherit" />
														) : null
													}
												>
													{loading ? 'Processing' : 'Buy Again'}
												</Button>
												<CustomButton
													onClick={handleNavigate}
													customStyle="outlined_border"
													className={styles.CustomButton}
												>
													Visit your wallet
												</CustomButton>
											</Grid>

											{loading && (
												<Grid>
													<Typography className={styles.text}>
														Your transaction is processing and you will be
														notified when it completes (eta: 3-5 minutes)
													</Typography>
													<Typography className={styles.text}>
														You can close this window at any time
													</Typography>
												</Grid>
											)}
										</Box>
									)}
									<Typography className={styles.text}>
										FAN Tokens purchased will be deposited into your platform
										account.
									</Typography>
								</Box>
							</Box>
						</CustomTabPanel>
					)}
				</Box>
			</DialogContent>
		</DialogWithbackground>
	)
}

FanPurchaseDialog.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func
}
