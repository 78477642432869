import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useHeader from '../useHeader'
import { RootState } from '@/store/store'
import {
	openBecomeSuperfanDialog,
	setSuperfanWelcomeDialogOpen
} from '../signInSlice'
import { useNavigate } from 'react-router-dom'
import { closeWelcomeReferralDialog } from '@/store/modalSlice'

export const useWelcomeReferralDialog = () => {
	const { welcomeReferralDialog } = useSelector(
		(state: RootState) => state.modal
	)
	const dispatch = useDispatch()
	const { showConfetti } = useHeader()
	const navigate = useNavigate()
	const {
		open,
		projectTitle,
		projectId,
		projectSlug,
		sharerDisplayName,
		isOnPerksProgram
	} = welcomeReferralDialog

	const handleBecomeSuperfan = () => {
		dispatch(
			openBecomeSuperfanDialog({
				projectTitle,
				projectId,
				projectSlug,
				sharerDisplayName,
				isOnPerksProgram
			})
		)
		dispatch(setSuperfanWelcomeDialogOpen(false))
		dispatch(closeWelcomeReferralDialog())
	}
	const handleClose = () => {
		dispatch(setSuperfanWelcomeDialogOpen(false))
		dispatch(closeWelcomeReferralDialog())
	}

	useEffect(() => {
		if (open) {
			showConfetti()
			navigate(`/project/${projectSlug}`)
		}
	}, [open])

	return {
		open,
		projectTitle,
		sharerDisplayName,
		handleBecomeSuperfan,
		handleClose
	}
}
