import PropTypes from 'prop-types'
import {
	Box,
	Button,
	Dialog,
	Icon,
	IconButton,
	Typography
} from '@mui/material'
import loadingChest from 'assets/images/dao-tiers/loading.svg'
import chest from 'assets/images/dao-tiers/chest.svg'
import { useTierDialog } from './useTierDialog'
import { useSuperfanSection } from 'components/profile/dashboardTab/superfanSection/useSuperfanSection'
import styles from './TierDialog.module.scss'

export const TierDialog = ({
	id,
	open,
	name,
	metrics,
	benefits,
	pointsThreshold,
	handleClose
}) => {
	const {
		user,
		enableUpgradeBtn,
		handleClickUpgradeDao
	} = useTierDialog({
		id,
		handleClose
	})
	const { totalPoints } = useSuperfanSection()
	const renderTodo = () => {
		
			const threshold = pointsThreshold
			const completed = totalPoints >= threshold
			const classes = `${styles.dao_metric} ${
				completed ? styles.completed : ''
			}`

			return (
				<Typography className={classes}>
					<span>
						{' '}
						({totalPoints}/{threshold}) Superfan Points
					</span>
					{completed && <Icon baseClassName="fas" className="fa-check" />}
				</Typography>
			)
		}

	const renderBenefits = () =>
		benefits
			?.filter(b => {
				const isAidropUser =
					Number(user?.isAirdropFan) === 1 ||
					Number(user?.isAirdropCreator) === 1
				if (!isAidropUser && b.highlight) return false
				return true
			})
			.map(b => {
				return (
					<Typography key={b.id} className={b.highlight ? styles.green : ''}>
						<span>
							<img alt="" src={loadingChest.src} />
						</span>{' '}
						{b.description}
					</Typography>
				)
			})

	return (
		<>
			<Dialog
				open={open}
				disableScrollLock={false}
				className={styles.dialog}
				modal="true"
				classes={{
					paper: styles.dialog_paper,
					container: styles.dialog_container
				}}
				onClose={handleClose}
			>
				<IconButton onClick={handleClose}>
					<Icon baseClassName="fas" className="fa-xmark" />
				</IconButton>
				<Box className={styles.container}>
					<Box className={styles.dao_details}>
						<Typography>{name} DAO Membership Details</Typography>
					</Box>
					<Box className={styles.center}>
						{Object.keys(metrics).length > 0 && (
							<Box className={styles.left}>
								<Typography>
									WHAT YOU NEED TO DO
									<Icon baseClassName="fas" className="fa-list-check" />
								</Typography>
								{renderTodo()}
							</Box>
						)}
						<Box className={styles.right}>
							<Typography>WHAT YOU GET</Typography>
							<img alt="" src={chest.src} />
							{renderBenefits()}
						</Box>
					</Box>
					<Box className={styles.buttons}>
						<Button
							className={styles.upgrade_dao}
							endIcon={<Icon baseClassName="fal" className="fa-up-from-line" />}
							onClick={handleClickUpgradeDao}
							disabled={!enableUpgradeBtn}
						>
							Upgrade DAO
						</Button>
						<a
							href="https://help.film.io/knowledgebase/film.io-dao/what-are-dao-tiers"
							target="_blank"
							rel="noreferrer"
						>
							Learn about DAO Tiers
							<Icon
								baseClassName="fal"
								className="fa-arrow-up-right-from-square"
							/>
						</a>
					</Box>
				</Box>
			</Dialog>
		</>
	)
}

TierDialog.propTypes = {
	id: PropTypes.number,
	name: PropTypes.string,
	open: PropTypes.bool,
	benefits: PropTypes.array,
	metrics: PropTypes.object,
	userMetrics: PropTypes.object,
	handleClose: PropTypes.func
}
