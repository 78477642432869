import { useSelector } from "react-redux"
import { Mixpanel } from "services/mixpanel.service"

const useMixpanel = () => {
    const { signInUser } = useSelector(state => state.signIn)

    const addStakeMixPanelEvent = (transactionId, amount) => {
        Mixpanel.track('usr_stake_added_evt', {
            distinct_id: signInUser?.id,
            amount,
            transactionId,
            displayName: signInUser?.displayName,
            id: signInUser?.id,
            username: signInUser?.username
        })
    }

    const addFailStakeMixPanelEvent = (transactionId, amount) => {
        Mixpanel.track('transaction_failed_stake_evt', {
            distinct_id: signInUser?.id,
            amount,
            transactionId,
            displayName: signInUser?.displayName,
            id: signInUser?.id,
            username: signInUser?.username
        })
    }

    return {
        addStakeMixPanelEvent,
        addFailStakeMixPanelEvent
    }
}

export default useMixpanel 