import { Box, Button, Icon, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { CustomDialog } from '@/components/common'
import stagesImages from 'assets/images/stages'
import styles from './StageDialog.module.scss'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatDate } from 'utils/date.utils'

export const StageDialog = ({ open, handleClose, stage }) => {
	const [page, setPage] = useState(stage)
	const { project } = useSelector(state => state.projectDetail)

	const stagesInfo = [
		{
			icon: stagesImages.originality,
			title: 'Proof of Originality',
			description: (
				<>
					Proof of Originality is the initial step of the Go Score™ journey.
					This step is included in the draft project process and requires the
					creator to add all the necessary project assets for a new project to
					be submitted for approval on the film.io platform. The global
					creativity foundation will review the project and either approve or
					reject it based on the platform&apos;s Terms of Service. Upon being
					approved, your project will be published live and vault locked with a
					QR code that points to the immutable blockchain record.
				</>
			),
			subText: project.vaultlockQR ? (
				<Box className={styles.vaultlock}>
					<img alt="" src={project.vaultlockQR} />
					<Box>
						<Typography>{project.title} was Vault Locked on</Typography>
						<Typography className={styles.vaultlock_date}>
							{formatDate(project.vaultlockDate, 'med')}
						</Typography>
					</Box>
				</Box>
			) : (
				''
			),
			leftBtn: '',
			rightBtn: 'Proof of Adoption'
		},
		{
			icon: stagesImages.adoption,
			title: 'Proof of Adoption',
			description: (
				<>
					Once a project has passed the Proof of Originality stage and is
					published live on the platform, it moves on to the Proof of Adoption
					stage. In this stage, a project is live as a DAO proposal and must
					reach a certain threshold of FAN Tokens staked by a specified number
					of unique stakers in order for the DAO proposal to be approved by the
					DAO community, which will unlock the project’s Go Score and other
					functionality.
				</>
			),
			subText: (
				<Typography>
					To complete this stage: Accumulate a certain amount of{' '}
					<strong>FAN Tokens Staked</strong> by a certain number of{' '}
					<strong>Unique Stakers</strong>, and reach a project{' '}
					<strong>review</strong> and <strong>share</strong> threshold.
				</Typography>
			),
			leftBtn: 'Proof of Originality',
			rightBtn: 'Development & Funding'
		},
		{
			icon: stagesImages.development,
			title: 'Development & Funding',
			description: (
				<>
					When a project has reached this stage a “Congratulations!” is in
					order, as the project proposal has been officially approved by the DAO
					Community and the project will have unlocked its Go Score and other
					functionality. At this stage, a project will be reaching maturity and
					completing traditional pre-production steps. This means the whole
					package needed to produce a project will be coming together and fans
					should be able to see additional elements like trailers, scripts,
					locations, cast and crew additions and so on. Expect consistent
					updates from the creator within the newsreel, helping to keep
					engagement high and continue progressing the Go Score TM.
				</>
			),
			subText: (
				<Typography>
					To complete this stage: Add a{' '}
					<strong>
						Hero Image, LogLine, Poster Image, Lookbook, Treatment, Script,
						Trailer
					</strong>{' '}
					and <strong>Budget</strong> to your project and complete a{' '}
					<strong>Successful Funding Raise</strong>.
				</Typography>
			),
			leftBtn: 'Proof of Adoption',
			rightBtn: 'Distribution'
		},
		{
			icon: stagesImages.distribution,
			title: 'Distribution',
			description: (
				<>
					A project that has reached this stage of the Go Score™ Journey will be
					able to say those coveted words “it’s in the can,” which means the
					project is complete and ready for the world. In this stage, fans
					should be able to find out where the creator is planning on showing
					their film and early adopters, superfans and others should be kept
					informed of special screens, premiers and other events surrounding the
					film. There might even be some project swag that gets passed out to
					supporters.
				</>
			),
			subText: '',
			leftBtn: 'Development & Funding',
			rightBtn: ''
		}
	]

	const { icon, title, subText, leftBtn, rightBtn, description } =
		stagesInfo[page]

	const nextPage = () => {
		setPage(p => p + 1)
	}

	const prevPage = () => {
		setPage(p => p - 1)
	}

	useEffect(() => {
		setPage(stage)
	}, [stage])

	return (
		<CustomDialog {...{ open, handleClose, variant: 'stage_detail' }}>
			<Box className={styles.stage_dialog}>
				<img alt="" src={icon} className={styles.icon} />
				<Typography component={'h1'}>{title}</Typography>
				<Typography>{description}</Typography>
				{subText && <Box className={styles.sub_text}>{subText}</Box>}
				<Box className={styles.footer}>
					{leftBtn && (
						<Button className={styles.btn_left} onClick={prevPage}>
							<Icon baseClassName="fas" className="fa-angle-left" />
							{leftBtn}
						</Button>
					)}
					{rightBtn && (
						<Button className={styles.btn_right} onClick={nextPage}>
							{rightBtn}
							<Icon baseClassName="fas" className="fa-angle-right" />
						</Button>
					)}
				</Box>
			</Box>
		</CustomDialog>
	)
}

StageDialog.propTypes = {
	stage: PropTypes.number,
	icon: PropTypes.string,
	name: PropTypes.string,
	open: PropTypes.bool,
	handleClose: PropTypes.func
}
