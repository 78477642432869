import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	addStakeService,
	getUserStakeLimitService
} from 'services/stake.service'
import useAxios from 'hooks/useAxios'
import { StakingContract } from 'utils/blockchain/functions'
import {
	setCreatePlatformWalletDialogOpen,
	setFrontTransactionsInProgress
} from '@/components/header/signInSlice'
import useCommon from 'hooks/useCommon'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'

export const useStake = (options?: {
	afterCreateStakeCb?: () => void
	checkNcNoMagicCb?: () => boolean
}) => {
	const dispatch = useDispatch()
	const [stakeInProgress, setStakeInProgress] = useState(false)
	const { isAuthenticated } = useSelector((state: RootState) => state.signIn)
	const { axiosServiceSync } = useAxios()
	const { checkNcNoMagic } = useCommon()

	const afterCreateStake = async (transaction: any) => {
		const transactionId = transaction?.id
		dispatch(setFrontTransactionsInProgress(true))
		options?.afterCreateStakeCb?.()
		await StakingContract.stakeTokens(
			transaction?.objectId,
			Number(transaction?.amount),
			transactionId
		)
		setTimeout(() => {
			setStakeInProgress(false)
		}, 2000)
	}

	const addStake = async (projectId: string, amount: number) => {
		const transaction = await axiosServiceSync(
			addStakeService({
				amount,
				projectId
			})
		)
		afterCreateStake(transaction)
	}

	const getUserStakeLimit = async (projectId: string) => {
		if (!projectId) return
		const result = await axiosServiceSync(
			getUserStakeLimitService(projectId),
			!isAuthenticated
		)
		return result
	}

	const addStakeHandler =
		(stakeAmount: number | string, projectId: string) => async () => {
			if (checkNcNoMagic()) {
				dispatch(setCreatePlatformWalletDialogOpen(true))
				options?.checkNcNoMagicCb?.()
				return
			}
			setStakeInProgress(true)
			addStake(projectId, Number(stakeAmount))
		}

	return {
		stakeInProgress,
		getUserStakeLimit,
		addStakeHandler
	}
}
