export const getProjectBadgesService = (projectId: string) => ({
	method: 'get',
	url: `/goScoreBadge/project/${projectId}`
})

export const getGoScoreStagesService = () => ({
	method: 'get',
	url: '/goScoreStage',
	autoexec: true
})

export const getProjectGoScoreStagesService = (projectId: string) => ({
	method: 'get',
	url: `/goScoreStage/project/${projectId}`,
	autoexec: true
})

export const getProjectGoScoreMilestoneService = (projectId: string) => ({
	method: 'get',
	url: `/goScoreMilestone/project/${projectId}`
})

export const getProjectGoScoreService = (projectId: string) => ({
	method: 'get',
	url: `/goscore/${projectId}`,
	autoexec: true
})

export const getProjectGoScoreUnlockStatsService = (projectId: string) => ({
	method: 'get',
	url: `/goscore/unlockStats/${projectId}`,
	autoexec: true
})

export const getGoScoreConfigService = () => ({
	method: 'get',
	url: '/goscore/config',
	autoexec: true
})

export const checkGoScoreV2Service = () => ({
	method: 'get',
	url: '/goscore/checkV2',
	autoexec: true
})
