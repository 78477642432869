import { setDaoProposalUpdated, setReloadProposals } from '@/components/dashboard/dashboardSlice'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getDaoProposalByIdService,
	updateVoteDaoProposalService,
	voteDaoProposalService
} from 'services/daoProposal.service'
import FilmioDaoContract from 'utils/blockchain/functions/filmioDao'

const useDaoProposalDialog = () => {
	const [loading, setLoading] = useState(false)
	const { daoProposalUpdated } = useSelector(state => state.dashboard)
	const { axiosService } = useAxios()
	const dispatch = useDispatch()
	const { showMask, hideMask } = useLoadingMask()

	const updateVoteDaoProposal = async data => {
		const { hash: blockchainHash, votingWeight } = await FilmioDaoContract.vote(
			data?.proposalBlockchainId,
			data?.accepted === 1,
			data?.id
		)
		return new Promise(resolve => {
			axiosService(
				updateVoteDaoProposalService({
					id: data?.id,
					blockchainHash,
					votingWeight
				}),
				(_response, error) => {
					if (!error) {
						dispatch(setReloadProposals())
						getDaoProposalById(data?.daoProposalId)
					} else {
						hideMask()
						setLoading(false)
					}
					resolve(true)
				}
			)
		})
	}

	const getDaoProposalById = id => {
		axiosService(getDaoProposalByIdService(id), (response, error) => {
			if (!error) {
				dispatch(setDaoProposalUpdated(response))
			}
			hideMask()
			setLoading(false)
		})
	}

	const voteDaoProposal = data => {
		showMask(
			'Submitting your DAO Proposal Vote to the blockchain.\n Please wait.'
		)
		setLoading(true)
		axiosService(voteDaoProposalService(data), (response, error) => {
			if (!error) {
				updateVoteDaoProposal({ ...response })
			} else {
				setLoading(false)
				hideMask()
			}
		})
	}

	const handleVote = (accepted, daoProposalId) => async () => {
		voteDaoProposal({
			accepted,
			daoProposalId
		})
	}

	return { loading, daoProposalUpdated, handleVote, updateVoteDaoProposal, getDaoProposalById }
}

export default useDaoProposalDialog
