import { useEffect, useMemo, useState } from 'react'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { useSelector, useDispatch } from 'react-redux'
import {
	resetReviews,
	setIsReviewed,
	setReviews,
	setShowReviewsForm
} from '../projectDetailSlice'
import { getReviewService, isReviewedService } from 'services/review.service'
import useLoadingMask from 'hooks/useLoadingMask'
import { setLoginDialogOpen } from '@/components/header/signInSlice'
import useProjectCommon from '@/components/project/useProjectCommon'

const useReviewsTab = () => {
	const dispatch = useDispatch()
	const [loadMore, setLoadMore] = useState(true)
	const [page, setPage] = useState(0)
	const { showMask, hideMask } = useLoadingMask()
	const { isAddReviewDisabled, isReviewed, reviews } =
		useSelector(state => state.projectDetail)
	const { project } = useProjectCommon()
	const { isAuthenticated, user } = useSelector(state => state.signIn)
	const projectId = project?.id
	const isOwner = user?.id === project?.userId

	const [{ loading }, fetchReviews] = useAxiosFetch(
		getReviewService(projectId, page),
		afterFetch
	)

	function afterFetch(data, error) {
		if (!error) {
			setLoadMore(data?.length > 0)
			dispatch(setReviews(data))
		}
	}

	const [{ loading: loadingIsReviewed }, getIsReviewed] = useAxiosFetch(
		isReviewedService(projectId),
		(data, error) => {
			if (!error) {
				dispatch(setIsReviewed(data))
			}
		},
		!isAuthenticated
	)

	const onScroll = () => {
		if (
			window.innerHeight + window.scrollY >= document.body.offsetHeight - 10 &&
			loadMore &&
			!loading
		) {
			setPage(p => p + 1)
		}
	}

	const handleOpenLoginModal = () => {
		dispatch(setLoginDialogOpen(true))
	}

	useEffect(() => {
		fetchReviews()
	}, [page, projectId])

	useEffect(() => {
		getIsReviewed()
		dispatch(setShowReviewsForm(true))
		return () => {
			dispatch(resetReviews())
		}
	}, [projectId])

	useEffect(() => {
		loading || loadingIsReviewed ? showMask() : hideMask()
	}, [loading, loadingIsReviewed])

	const showWriteReview = useMemo(
		() =>
			(!isReviewed &&
				!isAddReviewDisabled &&
				!isOwner &&
				project?.stageId !== 5) ||
			!isAuthenticated,
		[isAddReviewDisabled, isOwner, isReviewed, project, isAuthenticated]
	)

	return {
		reviews,
		showWriteReview,
		isAuthenticated,
		handleOpenLoginModal,
		onScroll
	}
}

export default useReviewsTab
