import { createSlice } from '@reduxjs/toolkit'
import { getSocialNetworkId } from 'utils/socialShare.util'
import { getObjectFromLocalStorage } from 'utils/utils'

interface User {
	id?: string
	displayName?: string
	username?: string
	balance?: number
	isOnRamperEnabled?: boolean
	isFanPurchaseWithCryptoEnabled?: boolean
	email?: string
	avatar?: string
	daoTierId?: number
	daoTierImg?: string
	nextTierId?: number
	daoTierName?: string
	daoTierDate?: string
	nextTierName?: string
	nextTierImage?: string
	daoTierRingImg?: string
	daolevelUpImage?: string
	isDaoTermsAgreed?: boolean
	isNcWithoutMagic?: boolean
	nextTierCompleted?: boolean
	whitelistedAddress?: string
	unlockRewardsEndDate?: string
	tokenSaleConditionsAccepted?: boolean
	projectsPublishedCount?: number
	withdrawalRequireKyc?: boolean
	daoTierConfirmed?: boolean
	daoTierCompleted?: boolean
	isAirdropCreator?: boolean
	fanTokenImported?: boolean
	isEmailVerified?: boolean
	isNonCustodial?: boolean
	walletAddress?: string
	isAirdropFan?: boolean
	isRestricted?: boolean
	projectCount?: number
	isTokenSale?: boolean
	isSuperfan?: boolean
	isVerified?: boolean
	isVesting?: boolean
	appAccess?: boolean
	codeDate?: string
	shortUrl?: string
	fgrRate?: number
	isFraud?: boolean
	isAdmin?: boolean
	isHodl?: boolean
}

interface SignInState {
	isAuthenticated: boolean
	jwt: string | null
	user: User | null
	ncWalletAddress: {
		address?: string
		hash?: string
		signature?: string
	}
	showLoading: boolean
	loadingMessage: string | null
	startDialogOpen: boolean
	airdropCheckDialogOpen: boolean
	airdropSignUpDialogOpen: boolean
	airdropJoinOpen: boolean
	banDialogOpen: boolean
	loginDialogOpen: boolean
	connectDialogOpen: boolean
	onRampDialogOpen: boolean
	fanPurchanseDialogOpen: boolean
	loginDialogMessage: string | null
	saleEmailDialogOpen: boolean
	saleUserId: string | null
	joinDialogOpen: boolean
	joinDialogEmail: string
	showTierInfoMenu: boolean
	createAccountDialogOpen: boolean
	createPlatformWalletDialogOpen: boolean
	congratsDialogOpen: boolean
	showConfetti: boolean
	showSearchMobile: boolean
	transactionsInProgress: boolean
	frontTransactionsInProgress: boolean
	fraudWarningDialogOpen: boolean
	menuOpen: boolean
	tierProgress: number | null
	notificationsCount: number
	socialJoinParams: {
		userShortUrl: string | null
		projectShortUrl: string | null
		network: string | null
		campaignSlug: string | null
		socialNetworkId: string | null
	}
	bronzeTierData: {
		name: string
		metrics: any[]
		benefits: any[]
		userMetrics: any
	}
	activeContests: any[]
	contestJoinedDialogOpen: boolean
	isIngXRestrictedInvestor: boolean
	projectsStakeInProgress: string[]
	becomeSuperfanDialog: {
		open: boolean
		projectTitle: string
		projectId: string
		isOnPerksProgram: boolean
	}
	showFanSearchMobile: boolean
	addProjectDialogOpen: boolean
	daoTierLevelingDialogOpen: boolean
	importFanTokenDialogOpen: boolean
	daoTierLevelingChangeDialogOpen: boolean
	createMagicWalletDialogOpen: boolean
	platformWalletCreatedDialogOpen: boolean
	youAreSuperfanDialogOpen: boolean
	superfanWelcomeDialogOpen: boolean
	youAreSuperfanProjectDialogData: {
		open: boolean
		isOnPerksProgram: boolean
		title: string
		slug: string
		projectId: string | null
		createdAt: string | null
		isFirstSuperfan: boolean
		shortUrl: string
		poster: string
	}
	superfanItemDialogData: {
		open: boolean
		createdAt: string
		displayName: string
		title: string
	}
}

const initialState: SignInState = {
	isAuthenticated: !!localStorage.getItem('isAuthenticated'),
	jwt: localStorage.getItem('jwt'),
	user: getObjectFromLocalStorage([
		'id',
		'email',
		'avatar',
		'balance',
		'username',
		'daoTierId',
		'daoTierImg',
		'nextTierId',
		'displayName',
		'daoTierName',
		'daoTierDate',
		'nextTierName',
		'nextTierImage',
		'daoTierRingImg',
		'daolevelUpImage',
		'isDaoTermsAgreed',
		'isNcWithoutMagic',
		'nextTierCompleted',
		'whitelistedAddress',
		'unlockRewardsEndDate',
		'tokenSaleConditionsAccepted',
		'isFanPurchaseWithCryptoEnabled',
		'projectsPublishedCount',
		'withdrawalRequireKyc',
		'isOnRamperEnabled',
		'daoTierConfirmed',
		'daoTierCompleted',
		'isAirdropCreator',
		'fanTokenImported',
		'isEmailVerified',
		'isNonCustodial',
		'walletAddress',
		'isAirdropFan',
		'isRestricted',
		'projectCount',
		'isTokenSale',
		'isSuperfan',
		'isVerified',
		'isVesting',
		'appAccess',
		'codeDate',
		'shortUrl',
		'fgrRate',
		'isFraud',
		'isAdmin',
		'isHodl'
	]),
	ncWalletAddress: getObjectFromLocalStorage(['address', 'hash', 'signature']),
	showLoading: false,
	loadingMessage: null,
	startDialogOpen: false,
	airdropCheckDialogOpen: false,
	airdropSignUpDialogOpen: false,
	airdropJoinOpen: false,
	banDialogOpen: false,
	loginDialogOpen: false,
	connectDialogOpen: false,
	onRampDialogOpen: false,
	fanPurchanseDialogOpen:
		localStorage.getItem('fanPurchanseDialogOpen') === 'true',
	loginDialogMessage: null,
	saleEmailDialogOpen: false,
	saleUserId: null,
	joinDialogOpen: false,
	joinDialogEmail: '',
	showTierInfoMenu: false,
	createAccountDialogOpen: false,
	createPlatformWalletDialogOpen: false,
	congratsDialogOpen: false,
	showConfetti: false,
	showSearchMobile: false,
	transactionsInProgress: false,
	frontTransactionsInProgress: false,
	fraudWarningDialogOpen: false,
	menuOpen: false,
	tierProgress: null,
	notificationsCount: 0,
	socialJoinParams: {
		userShortUrl: null,
		projectShortUrl: null,
		network: null,
		campaignSlug: null,
		socialNetworkId: null
	},
	bronzeTierData: {
		name: '',
		metrics: [],
		benefits: [],
		userMetrics: {}
	},
	activeContests: [],
	contestJoinedDialogOpen: false,
	isIngXRestrictedInvestor: false,
	projectsStakeInProgress: [],
	becomeSuperfanDialog: {
		open: false,
		projectTitle: '',
		projectId: '',
		isOnPerksProgram: false
	},
	showFanSearchMobile: false,
	addProjectDialogOpen: false,
	daoTierLevelingDialogOpen: false,
	importFanTokenDialogOpen: false,
	daoTierLevelingChangeDialogOpen: false,
	createMagicWalletDialogOpen: false,
	platformWalletCreatedDialogOpen: false,
	youAreSuperfanDialogOpen: false,
	superfanWelcomeDialogOpen: false,
	youAreSuperfanProjectDialogData: {
		open: false,
		isOnPerksProgram: false,
		title: '',
		slug: '',
		projectId: null,
		createdAt: null,
		isFirstSuperfan: false,
		shortUrl: '',
		poster: ''
	},
	superfanItemDialogData: {
		open: false,
		createdAt: '',
		displayName: '',
		title: ''
	}
}

export const signInSlice = createSlice({
	name: 'signIn',
	initialState,
	reducers: {
		setAuthenticated: (state, { payload }) => {
			state.user = payload
			state.jwt = payload?.jwt
			state.isAuthenticated = true
		},
		removeAuthenticated: state => {
			state.isAuthenticated = false
			state.user = null
			state.jwt = null
		},
		updateSignInUser: (state, { payload }) => {
			state.user = {
				...state.user,
				...payload
			}
		},
		setMenuOpen: (state, { payload }) => {
			state.menuOpen = payload
		},
		setTierProgress: (state, { payload }) => {
			state.tierProgress = payload
		},
		setLoginDialogOpen: (state, { payload }) => {
			state.loginDialogOpen = payload
		},
		setConnectDialogOpen: (state, { payload }) => {
			state.connectDialogOpen = payload
		},
		setOnRampDialogOpen: (state, { payload }) => {
			state.onRampDialogOpen = payload
		},
		setFanPurchanseDialogOpen: (state, { payload }) => {
			state.fanPurchanseDialogOpen = payload
			localStorage.setItem('fanPurchanseDialogOpen', payload)
		},
		setBanDialogOpen: (state, { payload }) => {
			state.banDialogOpen = payload
		},
		setShowTierInfoMenu: (state, { payload }) => {
			state.showTierInfoMenu = payload
		},
		setBronzeTierData: (state, { payload }) => {
			state.bronzeTierData = payload
		},
		setLoginDialogMessage: (state, { payload }) => {
			state.loginDialogMessage = payload
		},
		setCreateAccountDialogOpen: (state, { payload }) => {
			state.createAccountDialogOpen = payload
		},
		setCreatePlatformWalletDialogOpen: (state, { payload }) => {
			state.createPlatformWalletDialogOpen = payload
		},
		setCongratsDialogOpen: (state, { payload }) => {
			state.congratsDialogOpen = payload
		},
		setJoinDialogOpen: (state, { payload }) => {
			state.joinDialogOpen = payload
		},
		setJoinDialogEmail: (state, { payload }) => {
			state.joinDialogEmail = payload
		},
		setFraudWarningDialogOpen: (state, { payload }) => {
			state.fraudWarningDialogOpen = payload
		},
		setAddProjectDialogOpen: (state, { payload }) => {
			state.addProjectDialogOpen = payload
		},
		setSocialJoinParams: (state, { payload }) => {
			const socialNetworkId = getSocialNetworkId(payload?.network)
			state.socialJoinParams = { ...payload, socialNetworkId }
		},
		setDaoTierLevelingDialogOpen: (state, { payload }) => {
			state.daoTierLevelingDialogOpen = payload
		},
		setImportFanTokenDialogOpen: (state, { payload }) => {
			state.importFanTokenDialogOpen = payload
		},
		setDaoTierLevelingChangeDialogOpen: (state, { payload }) => {
			state.daoTierLevelingChangeDialogOpen = payload
		},
		setStartDialogOpen: (state, { payload }) => {
			state.startDialogOpen = payload
		},
		setAirdropCheckDialogOpen: (state, { payload }) => {
			state.airdropCheckDialogOpen = payload
		},
		setAirdropSignUpDialogOpen: (state, { payload }) => {
			state.airdropSignUpDialogOpen = payload
		},
		setAirdropJoinOpen: (state, { payload }) => {
			state.airdropJoinOpen = payload
		},
		setShowLoading: (state, { payload }) => {
			state.showLoading = payload
		},
		setLoadingMessage: (state, { payload }) => {
			state.loadingMessage = payload
		},
		setTransactionsInProgress: (state, { payload }) => {
			state.transactionsInProgress = payload
		},
		setFrontTransactionsInProgress: (state, { payload }) => {
			state.frontTransactionsInProgress = payload
		},
		setCreateMagicWalletDialogOpen: (state, { payload }) => {
			state.createMagicWalletDialogOpen = payload
		},
		setNotificationsCount: (state, { payload }) => {
			state.notificationsCount = payload
		},
		setShowSearchMobile: (state, { payload }) => {
			state.showSearchMobile = payload
		},
		setSaleEmailDialogOpen: (state, { payload }) => {
			state.saleEmailDialogOpen = payload
		},
		setSaleUserId: (state, { payload }) => {
			state.saleUserId = payload
		},
		setNcWalletAddress: (state, { payload }) => {
			state.ncWalletAddress = payload
		},
		setShowConfetti: (state, { payload }) => {
			state.showConfetti = payload
		},
		setPlatformWalletCreatedDialogOpen: (state, { payload }) => {
			state.platformWalletCreatedDialogOpen = payload
		},
		setActiveContests: (state, { payload }) => {
			state.activeContests = payload
		},
		setContestJoinedDialogOpen: (state, { payload }) => {
			state.contestJoinedDialogOpen = payload
		},
		setYouAreSuperfanDialogOpen: (state, { payload }) => {
			state.youAreSuperfanDialogOpen = payload
		},
		setSuperfanWelcomeDialogOpen: (state, { payload }) => {
			state.superfanWelcomeDialogOpen = payload
		},
		setYouAreSuperfanProjectDialogData: (state, { payload }) => {
			state.youAreSuperfanProjectDialogData = payload?.open
				? payload
				: initialState.youAreSuperfanProjectDialogData
		},
		setSuperfanItemDialogData: (state, { payload }) => {
			state.superfanItemDialogData = payload?.open
				? payload
				: initialState.superfanItemDialogData
		},
		setIsIngXRestrictedInvestor: (state, { payload }) => {
			state.isIngXRestrictedInvestor = payload
		},
		addProjectsStakeInProgress: (state, { payload }) => {
			state.projectsStakeInProgress = [
				...state.projectsStakeInProgress,
				payload
			]
		},
		removeProjectsStakeInProgress: (state, { payload }) => {
			state.projectsStakeInProgress = state.projectsStakeInProgress.filter(
				el => el !== payload
			)
		},
		openBecomeSuperfanDialog: (state, { payload }) => {
			state.becomeSuperfanDialog = {
				open: true,
				projectId: payload?.projectId,
				projectTitle: payload?.projectTitle,
				isOnPerksProgram: payload?.isOnPerksProgram
			}
		},
		closeBecomeSuperfanDialog: state => {
			state.becomeSuperfanDialog = initialState.becomeSuperfanDialog
		},
		setShowFanSearchMobile: (state, { payload }) => {
			state.showFanSearchMobile = payload
		}
	}
})

export const {
	setMenuOpen,
	setSaleUserId,
	setShowLoading,
	setShowConfetti,
	setAuthenticated,
	updateSignInUser,
	setBanDialogOpen,
	setBronzeTierData,
	setJoinDialogOpen,
	setLoadingMessage,
	setLoginDialogOpen,
	setStartDialogOpen,
	setJoinDialogEmail,
	setAirdropJoinOpen,
	setNcWalletAddress,
	setOnRampDialogOpen,
	removeAuthenticated,
	setSocialJoinParams,
	setShowSearchMobile,
	setShowTierInfoMenu,
	setConnectDialogOpen,
	setNotificationsCount,
	setLoginDialogMessage,
	setSaleEmailDialogOpen,
	setAddProjectDialogOpen,
	openBecomeSuperfanDialog,
	setAirdropCheckDialogOpen,
	setFanPurchanseDialogOpen,
	setFraudWarningDialogOpen,
	setSuperfanItemDialogData,
	setTransactionsInProgress,
	closeBecomeSuperfanDialog,
	setCreateAccountDialogOpen,
	setYouAreSuperfanDialogOpen,
	setSuperfanWelcomeDialogOpen,
	setCreatePlatformWalletDialogOpen,
	setDaoTierLevelingChangeDialogOpen,
	setPlatformWalletCreatedDialogOpen,
	setYouAreSuperfanProjectDialogData,
	setCreateMagicWalletDialogOpen,
	setFrontTransactionsInProgress,
	removeProjectsStakeInProgress,
	setDaoTierLevelingDialogOpen,
	addProjectsStakeInProgress,
	setIsIngXRestrictedInvestor,
	setImportFanTokenDialogOpen,
	setContestJoinedDialogOpen,
	setAirdropSignUpDialogOpen,
	setCongratsDialogOpen,
	setActiveContests,
	setTierProgress,
	setShowFanSearchMobile
} = signInSlice.actions

export default signInSlice.reducer
