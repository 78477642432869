import _ from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { getUserProgressionService } from 'services/rewardBucket.service'
import { useDispatch, useSelector } from 'react-redux'
import { reloadRecentActivity } from '../walletTabSlice'
import {
	getSpeedUpValuesService,
	speedUpByAmountService
} from 'services/gemOption.service'
import { addObjectToLocalStorage } from 'utils/utils'
import useNotification from 'hooks/useNotification'

const useRewardUnlockProgression = () => {
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const [data, setData] = useState([])
	const [speedUpInfo, setSpeedUpInfo] = useState({
		days: 0,
		rewardsToUnlock: 0
	})
	const [loadingProgression, setLoadingProgression] = useState(false)
	const [gemAmount, setGemAmount] = useState(0)
	const { overview, fgrClaimed } = useSelector(state => state.walletTab)
	const { user: signInUser } = useSelector(state => state.signIn)
	const { showNotification } = useNotification()
	const isVesting = signInUser?.isVesting === 1
	
	const dispatch = useDispatch()

	const getUserProgression = () => {
		setLoadingProgression(true)
		axiosService(getUserProgressionService(), (response, error) => {
			if (!error) {
				setData(response)
			}
			setLoadingProgression(false)
		})
	}

	const handleSpeedUp = () => {
		showMask()
		axiosService(speedUpByAmountService({ gemAmount }), (_data, error) => {
			if (!error) {
				addObjectToLocalStorage({ fgrClaimed: 0 })
				dispatch(reloadRecentActivity())
				getUserProgression()
				setGemAmount(0)
			}
			hideMask()
		})
	}

	const handleGetSpeedUpValues = useMemo(() => {
		return _.debounce(amount => {
			showMask()
			axiosService(
				getSpeedUpValuesService({ gemAmount: amount }),
				(data, error) => {
					if (!error) {
						setSpeedUpInfo(data)
					}
					hideMask()
				}
			)
		}, 500)
	}, [])

	const isValidGemAmount = amount => {
		let message = ''
		let isValid = true
		if (amount < 0) {
			message = 'The amount should be higher than 0'
			setGemAmount(0)
			isValid = false
		}
		if (amount > overview?.gemTotal) {
			message = `The amount should be lower than ${overview?.gemTotal}`
			setGemAmount(overview?.gemTotal)
			isValid = false
		}
		if (!isValid) {
			showNotification({ message })
			handleGetSpeedUpValues(amount)
		}
		return isValid
	}

	const handleSpeedUpChange = value => {
		const amount = Number(value)
		if (isValidGemAmount(amount)) {
			setGemAmount(amount)
			handleGetSpeedUpValues(amount)
		}
	}

	useEffect(() => {
		getUserProgression()
	}, [])

	useEffect(() => {
		if (!_.isNil(fgrClaimed)) {
			getUserProgression()
			addObjectToLocalStorage({ fgrClaimed: 1 })
		}
	}, [fgrClaimed])

	return {
		data,
		overview,
		gemAmount,
		isVesting,
		speedUpInfo,
		loadingProgression,
		handleSpeedUpChange,
		handleSpeedUp,
		setGemAmount
	}
}

export default useRewardUnlockProgression
