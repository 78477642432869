import * as React from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger
} from '@/components/ui/tooltip'
import { LuNewspaper, LuWallet } from 'react-icons/lu'
import { FiCompass } from 'react-icons/fi'
import { cn } from '@/lib/utils'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'

export const UserKingIcon = ({ className }: { className?: string }) => (
	<svg
		className={className}
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 20 20"
		stroke="currentColor"
		fill="none"
	>
		<path d="M8.438 1.436v-.001L9.701.589h.001a.54.54 0 0 1 .596 0h.001l1.263.846c.38.253.872.253 1.25 0h.001L14.18.521A.12.12 0 0 1 14.25.5h.125c.068 0 .125.057.125.125v5a4.5 4.5 0 1 1-9 0v-5C5.5.557 5.557.5 5.625.5h.125q.039.002.06.017l.006.004 1.371.915c.38.252.872.252 1.25 0Zm5.312 3.439h.5v-4.1l-.778.52-.517.346-.001.001a1.39 1.39 0 0 1-1.528 0L10.276.877 10 .692l-.278.185-1.142.764-.001.001a1.39 1.39 0 0 1-1.529 0l-.523-.348L5.75.78v4.096h8Zm-7.5.25h-.5v.5a4.251 4.251 0 0 0 8.5 0v-.5h-8ZM17.5 19.25h.5v-.812a5.813 5.813 0 0 0-5.812-5.813H7.812A5.813 5.813 0 0 0 2 18.438v.812h15.5Zm-15.75-.812a6.06 6.06 0 0 1 6.063-6.063h4.375a6.06 6.06 0 0 1 6.062 6.063v.312c0 .415-.335.75-.75.75h-15a.75.75 0 0 1-.75-.75z"></path>
	</svg>
)

type Props = {
	className?: string
}

export const Sidebar = ({ className }: Props) => {
	const { user, isAuthenticated } = useSelector(
		(state: RootState) => state.signIn
	)
	const location = useLocation()

	if (!isAuthenticated) {
		return null
	}

	return (
		<aside className={cn('hidden w-20 flex-col bg-black sm:block', className)}>
			<nav className="flex w-full flex-col items-center gap-12 px-2 py-8">
				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger asChild>
							<Link
								to="/fan-dashboard"
								className="flex h-9 w-9 items-center justify-center rounded-lg transition-colors hover:text-primary"
							>
								<UserKingIcon
									className={cn(
										'h-6 w-6',
										location.pathname === '/fan-dashboard'
											? 'text-primary'
											: 'text-icons'
									)}
								/>
								<span className="sr-only">Dashboard</span>
							</Link>
						</TooltipTrigger>
						<TooltipContent side="right">Dashboard</TooltipContent>
					</Tooltip>
				</TooltipProvider>

				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger asChild>
							<Link
								to="/explore"
								className="flex h-9 w-9 items-center justify-center rounded-lg transition-colors hover:text-primary md:h-8 md:w-8"
							>
								<FiCompass
									className={cn(
										'h-6 w-6',
										location.pathname === '/explore'
											? 'text-primary'
											: 'text-icons'
									)}
								/>
								<span className="sr-only">Explore</span>
							</Link>
						</TooltipTrigger>
						<TooltipContent side="right">Explore</TooltipContent>
					</Tooltip>
				</TooltipProvider>

				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger asChild>
							<Link
								to="/newsreel"
								className="flex h-9 w-9 items-center justify-center rounded-lg transition-colors hover:text-primary md:h-8 md:w-8"
							>
								<LuNewspaper
									className={cn(
										'h-6 w-6',
										location.pathname === '/newsreel'
											? 'text-primary'
											: 'text-icons'
									)}
								/>
								<span className="sr-only">Newsreel</span>
							</Link>
						</TooltipTrigger>
						<TooltipContent side="right">Newsreel</TooltipContent>
					</Tooltip>
				</TooltipProvider>

				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger asChild>
							<Link
								to={`/profile/${user.username}?tab=4`}
								className="flex h-9 w-9 items-center justify-center rounded-lg transition-colors hover:text-primary md:h-8 md:w-8"
							>
								<LuWallet
									className={cn(
										'h-6 w-6',
										location.pathname === `/profile/${user.username}`
											? 'text-primary'
											: 'text-icons'
									)}
								/>
								<span className="sr-only">Wallet</span>
							</Link>
						</TooltipTrigger>
						<TooltipContent side="right">Wallet</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			</nav>
		</aside>
	)
}
