export const getSocialJoinProject = (
	userShortUrl: string,
	shortUrl: string,
	autoexec = true
) => ({
	method: 'get',
	url: `/socialJoin/project/${userShortUrl}/${shortUrl}`,
	autoexec
})

export const getJoinUsersByProjectId = (params: any) => ({
	method: 'get',
	url: '/socialJoin/users',
	params,
	autoexec: false
})

export const getJoinStatsBySharerId = (params: any) => ({
	method: 'get',
	url: '/socialJoin/shareUserId',
	params,
	autoexec: false
})

export const getTotalJoinStatsByShareUserId = (params: any) => ({
	method: 'get',
	url: '/socialJoin/shareUserId/total',
	params,
	autoexec: false
})
