import React, { useState, useCallback, useRef, useEffect } from 'react'
import { FiSearch, FiCheckCircle } from 'react-icons/fi'
import { IoMdClose } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'
import placeHolder from 'assets/images/placeholder.svg'
import debounce from 'lodash/debounce'
import { useQuery } from '@tanstack/react-query'
import { getGlobalSearchService } from 'services/dashboard.service'
import useAxios from '@/hooks/useAxios'
import { Input } from '@/components/ui/input'

interface BaseSearchResult {
	id: string
	name: string
	image?: string | null
	type: 'user' | 'project'
}

interface UserSearchResult extends BaseSearchResult {
	type: 'user'
	username: string
	description: string | null
	isVerified?: number
}

interface ProjectSearchResult extends BaseSearchResult {
	type: 'project'
	slug: string
	author: string
	isVerified?: number
}

type SearchResult = UserSearchResult | ProjectSearchResult

interface SearchInputProps {
	showFanSearchMobile?: boolean
	handleCloseSearch?: () => void
}

export function SearchInput({
	showFanSearchMobile,
	handleCloseSearch
}: SearchInputProps) {
	const [search, setSearch] = useState('')
	const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('')
	const [isResultsVisible, setIsResultsVisible] = useState(false)
	const [selectedIndex, setSelectedIndex] = useState(-1)
	const inputRef = useRef<HTMLInputElement>(null)
	const resultsRef = useRef<HTMLDivElement>(null)
	const containerRef = useRef<HTMLDivElement>(null)
	const resultItemsRef = useRef<HTMLDivElement[]>([])
	const { axiosServiceSync } = useAxios()
	const navigate = useNavigate()

	// Setup debounced function to update the debounced search term
	const updateDebouncedSearchTerm = useRef(
		debounce((value: string) => {
			setDebouncedSearchTerm(value)
		}, 300)
	).current

	// Define query key for search results
	const searchQueryKey = ['globalSearch', debouncedSearchTerm]

	// Use React Query for data fetching
	const { data: items = [], isLoading: loading } = useQuery<SearchResult[]>({
		queryKey: searchQueryKey,
		queryFn: async () => {
			if (!debouncedSearchTerm) return []
			const response = await axiosServiceSync(
				getGlobalSearchService({
					search: debouncedSearchTerm
				})
			)
			return response || []
		},
		enabled: debouncedSearchTerm.length > 0,
		staleTime: 1000 * 60 * 5 // 5 minutes
	})

	// Group items by type
	const projectItems = items.filter(
		(item): item is ProjectSearchResult => item.type === 'project'
	)
	const userItems = items.filter(
		(item): item is UserSearchResult => item.type === 'user'
	)

	// Flatten items for keyboard navigation
	const flattenedItems = [...projectItems, ...userItems]

	// Reset selected index when results change
	useEffect(() => {
		setSelectedIndex(-1)
		// Reset the refs array when items change
		resultItemsRef.current = []
	}, [items])

	// Handle search input changes
	const handleSearchChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const newValue = e.target.value
			setSearch(newValue)

			if (!newValue) {
				// Immediately clear results if search is empty
				setDebouncedSearchTerm('')
				setIsResultsVisible(false)
				updateDebouncedSearchTerm.cancel()
			} else {
				// Debounce the update of the search term
				updateDebouncedSearchTerm(newValue)
				setIsResultsVisible(true)
			}
		},
		[updateDebouncedSearchTerm]
	)

	const handleResultClick = useCallback(
		(to: string) => {
			setSearch('')
			setDebouncedSearchTerm('')
			setIsResultsVisible(false)
			showFanSearchMobile && handleCloseSearch?.()
			inputRef.current?.blur()
			navigate(to)
		},
		[navigate, showFanSearchMobile, handleCloseSearch]
	)

	// Navigate to the selected result
	const navigateToSelected = useCallback(() => {
		if (selectedIndex >= 0 && selectedIndex < flattenedItems.length) {
			const selectedItem = flattenedItems[selectedIndex]
			const to =
				selectedItem.type === 'user'
					? `/profile/${selectedItem.username}`
					: `/project/${selectedItem.slug}`
			handleResultClick(to)
		}
	}, [selectedIndex, flattenedItems, handleResultClick])

	// Close results when clicking outside
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				containerRef.current &&
				!containerRef.current.contains(event.target as Node)
			) {
				setIsResultsVisible(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	// Clean up debounce on unmount
	useEffect(() => {
		return () => {
			updateDebouncedSearchTerm.cancel()
		}
	}, [updateDebouncedSearchTerm])

	// Scroll selected item into view
	useEffect(() => {
		if (selectedIndex >= 0 && resultItemsRef.current[selectedIndex]) {
			resultItemsRef.current[selectedIndex].scrollIntoView({
				behavior: 'smooth',
				block: 'nearest'
			})
		}
	}, [selectedIndex])

	// Handle keyboard navigation
	const handleKeyDown = useCallback(
		(e: React.KeyboardEvent) => {
			if (!isResultsVisible) return

			switch (e.key) {
				case 'ArrowDown':
					e.preventDefault()
					setSelectedIndex(prev =>
						prev < flattenedItems.length - 1 ? prev + 1 : prev
					)
					break
				case 'ArrowUp':
					e.preventDefault()
					setSelectedIndex(prev => (prev > 0 ? prev - 1 : 0))
					break
				case 'Enter':
					e.preventDefault()
					navigateToSelected()
					break
				case 'Escape':
					e.preventDefault()
					setIsResultsVisible(false)
					break
				default:
					break
			}
		},
		[isResultsVisible, flattenedItems.length, navigateToSelected]
	)

	return (
		<div
			ref={containerRef}
			className={`relative w-full ${
				showFanSearchMobile ? 'max-[639px]:block' : 'max-[639px]:hidden'
			} `}
		>
			<SearchInputField
				ref={inputRef}
				value={search}
				onChange={handleSearchChange}
				onKeyDown={handleKeyDown}
				onFocus={() => {
					if (search) setIsResultsVisible(true)
				}}
				showCloseButton={showFanSearchMobile}
				onCloseClick={handleCloseSearch}
			/>

			{isResultsVisible && (
				<SearchResults
					ref={resultsRef}
					loading={loading}
					items={items}
					projectItems={projectItems}
					userItems={userItems}
					selectedIndex={selectedIndex}
					setSelectedIndex={setSelectedIndex}
					resultItemsRef={resultItemsRef}
					onResultClick={handleResultClick}
				/>
			)}
		</div>
	)
}

// SearchInputField Component
interface SearchInputFieldProps {
	value: string
	onChange: (_e: React.ChangeEvent<HTMLInputElement>) => void
	onKeyDown: (_e: React.KeyboardEvent) => void
	onFocus: () => void
	showCloseButton?: boolean
	onCloseClick?: () => void
}

const SearchInputField = React.forwardRef<
	HTMLInputElement,
	SearchInputFieldProps
>(
	(
		{ value, onChange, onKeyDown, onFocus, showCloseButton, onCloseClick },
		ref
	) => {
		return (
			<div className="relative flex w-full items-center justify-center">
				<div className="relative w-full">
					<span className="absolute inset-y-0 left-0 z-10 flex items-center pl-3">
						<FiSearch className="h-3 w-3 text-[#71717A]" />
					</span>

					<Input
						ref={ref}
						type="text"
						placeholder="Search..."
						className="h-10 w-full rounded-lg border py-2 pl-10 pr-4 text-base shadow-sm"
						value={value}
						onChange={onChange}
						onFocus={onFocus}
						onKeyDown={onKeyDown}
					/>

					{showCloseButton && (
						<span className="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3">
							<IoMdClose onClick={onCloseClick} />
						</span>
					)}
				</div>
			</div>
		)
	}
)
SearchInputField.displayName = 'SearchInputField'

interface SearchResultsProps {
	loading: boolean
	items: SearchResult[]
	projectItems: ProjectSearchResult[]
	userItems: UserSearchResult[]
	selectedIndex: number
	setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
	resultItemsRef: React.MutableRefObject<HTMLDivElement[]>
	onResultClick: (_to: string) => void
}

const SearchResults = React.forwardRef<HTMLDivElement, SearchResultsProps>(
	(
		{
			loading,
			items,
			projectItems,
			userItems,
			selectedIndex,
			setSelectedIndex,
			resultItemsRef,
			onResultClick
		},
		ref
	) => {
		return (
			<div
				ref={ref}
				className="custom-scrollbar absolute z-50 mt-2 max-h-[80vh] w-full overflow-y-auto rounded-lg border bg-background shadow-lg"
			>
				{loading ? (
					<SearchLoadingState />
				) : items.length === 0 ? (
					<SearchEmptyState />
				) : (
					<div className="p-2">
						{projectItems.length > 0 && (
							<div className="space-y-1">
								{projectItems.map((item, index) => (
									<SearchResultItem
										key={item.id}
										item={item}
										isSelected={selectedIndex === index}
										setRef={el => {
											if (el) resultItemsRef.current[index] = el
										}}
										onMouseEnter={() => setSelectedIndex(index)}
										onClick={() => onResultClick(`/project/${item.slug}`)}
									/>
								))}
							</div>
						)}

						{userItems.length > 0 && projectItems.length > 0 && (
							<div className="my-2 h-px bg-border" />
						)}

						{userItems.length > 0 && (
							<div className="space-y-1">
								{userItems.map((item, index) => {
									const globalIndex = projectItems.length + index
									return (
										<SearchResultItem
											key={item.id}
											item={item}
											isSelected={selectedIndex === globalIndex}
											setRef={el => {
												if (el) resultItemsRef.current[globalIndex] = el
											}}
											onMouseEnter={() => setSelectedIndex(globalIndex)}
											onClick={() => onResultClick(`/profile/${item.username}`)}
										/>
									)
								})}
							</div>
						)}
					</div>
				)}
			</div>
		)
	}
)
SearchResults.displayName = 'SearchResults'

const SearchLoadingState: React.FC = () => (
	<div className="py-6 text-center text-sm text-muted-foreground">
		Searching...
	</div>
)

const SearchEmptyState: React.FC = () => (
	<div className="py-6 text-center text-sm text-muted-foreground">
		No results found.
	</div>
)

interface SearchResultItemProps {
	item: SearchResult
	isSelected: boolean
	setRef: (_el: HTMLDivElement | null) => void
	onMouseEnter: () => void
	onClick: () => void
}

const SearchResultItem: React.FC<SearchResultItemProps> = ({
	item,
	isSelected,
	setRef,
	onMouseEnter,
	onClick
}) => {
	return (
		<div
			ref={setRef}
			className={`flex cursor-pointer items-center gap-3 rounded-md p-2 ${
				isSelected ? 'bg-primary/20' : 'hover:bg-primary/20'
			}`}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
		>
			<span
				className={`h-auto w-20 overflow-hidden ${
					item.type === 'user' ? 'rounded-full' : 'aspect-[10/16] rounded'
				} flex-shrink-0`}
			>
				<img
					src={item?.image ?? placeHolder.src}
					alt={item.name}
					className="h-full w-full object-cover"
				/>
			</span>
			<div className="flex flex-col">
				<span className="text-sm font-medium">{item.name}</span>
				{item.type === 'project' && (
					<span className="flex items-center text-xs text-muted-foreground">
						{item.author}
						{item.isVerified === 1 && (
							<FiCheckCircle className="ml-1 h-3 w-3 text-primary" />
						)}
					</span>
				)}
				{item.type === 'user' && item.description && (
					<span className="max-w-[200px] truncate text-xs text-muted-foreground">
						{item.description}
					</span>
				)}
			</div>
		</div>
	)
}
