/* eslint-disable no-console */
type LogLevel = 'debug' | 'info' | 'warn' | 'error'

class Logger {
	private isProduction: boolean

	constructor() {
		this.isProduction = process.env.NODE_ENV === 'production'
	}

	// Enable/disable all logs manually
	private enabled: boolean = true

	enable(): void {
		this.enabled = true
	}

	disable(): void {
		this.enabled = false
	}

	private shouldLog(level: LogLevel): boolean {
		if (!this.enabled) return false
		if (this.isProduction) {
			// In production, only show errors by default
			return level === 'error'
		}
		return true
	}

	// Add timestamp to logs
	private getTimestamp(): string {
		return new Date().toISOString()
	}

	debug(...args: any[]): void {
		if (this.shouldLog('debug')) {
			console.debug(`[DEBUG][${this.getTimestamp()}]:`, ...args)
		}
	}

	info(...args: any[]): void {
		if (this.shouldLog('info')) {
			console.info(`[INFO][${this.getTimestamp()}]:`, ...args)
		}
	}

	warn(...args: any[]): void {
		if (this.shouldLog('warn')) {
			console.warn(`[WARN][${this.getTimestamp()}]:`, ...args)
		}
	}

	error(...args: any[]): void {
		if (this.shouldLog('error')) {
			console.error(`[ERROR][${this.getTimestamp()}]:`, ...args)
		}
	}

	// For objects/data inspection
	table(data: any): void {
		if (!this.isProduction) {
			console.table(data)
		}
	}
}

// Create a singleton instance
export const logger = new Logger()
