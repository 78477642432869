import { createRef, useEffect, useState } from 'react'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { useNavigate, useParams } from 'react-router-dom'
import { updateMetaTags } from 'utils/utils'
import { getVideoInfoFromUrl } from 'utils/video.utils'
import { useDispatch, useSelector } from 'react-redux'
import { getSocialJoinProject } from 'services/socialJoin.service'
import {
	setJoinDialogOpen,
	setSocialJoinParams
} from '@/components/header/signInSlice'

const useSocialJoin = () => {
	const [project, setProject] = useState({})
	const [trailerSrc, setTrailerSrc] = useState(null)
	const [showMore, setShowMore] = useState(false)
	const [storyHeight, setStoryHeight] = useState(0)
	const { isAuthenticated } = useSelector(state => state.signIn)
	const { userShortUrl, shortUrl, network } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const storyRef = createRef()
	const [{ loading }] = useAxiosFetch(
		getSocialJoinProject(userShortUrl, shortUrl),
		(data, error) => {
			if (!error) {
				setProject(data)
			}
			const description = (data?.logline ?? '').split('.')[0]
			updateMetaTags({
				title: data.title,
				description,
				author: data?.user?.displayName,
				image: data?.poster,
				url: window.location.href
			})
		}
	)

	const handleOpenJoinDialog = () => {
		dispatch(setJoinDialogOpen(true))
	}

	const showMoreToggle = () => {
		setShowMore(!showMore)
	}

	useEffect(() => {
		const geTrailerSrc = async () => {
			const info = await getVideoInfoFromUrl(project?.trailer)
			setTrailerSrc(info?.player_embed_url)
		}
		geTrailerSrc()
		if (isAuthenticated) {
			navigate(`/project/${project?.slug}`)
		}
	}, [project])

	useEffect(() => {
		setStoryHeight(storyRef.current.clientHeight)
	})

	useEffect(() => {
		dispatch(
			setSocialJoinParams({ userShortUrl, projectShortUrl: shortUrl, network })
		)
	}, [userShortUrl, shortUrl, network])

	return {
		loading,
		project,
		storyRef,
		showMore,
		trailerSrc,
		storyHeight,
		isAuthenticated,
		handleOpenJoinDialog,
		showMoreToggle
	}
}

export default useSocialJoin
