import { useEffect, useState } from 'react'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { getSuperfanLevelService } from 'services/superfan.service'
import { getProjectSuperfanLeaderboard } from 'services/userFanLevel.service'
import {
	claimFgrBlockchainService,
	claimFGRService,
	getProjectFgrUnclaimed
} from 'services/project.service'
import useNotification from 'hooks/useNotification'
import { useDispatch, useSelector } from 'react-redux'
import useHeader from '@/components/header/useHeader'
import { setFanLevel } from './superfanSlice'
import useAxios from 'hooks/useAxios'
import {
	setClaiming,
	showFgrModal
} from '@/components/header/claimFgrDialog/claimFgrSlice'
import {
	fgrClaimingCompleted,
	reloadRecentActivity
} from '@/components/profile/walletTab/walletTabSlice'
import _ from 'lodash'

const useSuperfan = (autoexec = true) => {
	const { user, isAuthenticated } = useSelector(state => state.signIn)
	const { fanLevel } = useSelector(state => state.superfan)
	const [fgrEarned, setFGREarned] = useState(0)
	const [openDialog, setOpenDialog] = useState(false)
	const [openPerkDialog, setOpenPerkDialog] = useState(false)
	const { project } = useSelector(state => state.projectDetail)
	const { claiming } = useSelector(state => state.claimFgr)
	const { superfanUpdatedEvent } = useSelector(state => state.websocket)
	const dispatch = useDispatch()
	const { showNotification } = useNotification()
	const { reloadUserInfo, showConfetti } = useHeader()
	const { axiosService } = useAxios()
	const [leaderboardPagination, setLeaderboardPagination] = useState({
		offset: 0,
		limit: 10
	})
	const [leaderboard, setLeaderboard] = useState({
		rows: [],
		count: 0,
		userStats: null
	})
	const projectId = project?.id

	const [{ loading }, updateSuperfanLevel] = useAxiosFetch(
		getSuperfanLevelService(projectId),
		(data, error) => {
			if (!error) {
				dispatch(setFanLevel(data))
			}
		}
	)

	const [{ loading: loadingLeaderboard }, getLeaderboard] = useAxiosFetch(
		getProjectSuperfanLeaderboard({
			projectId,
			...leaderboardPagination
		}),
		(data, error) => {
			if (!error) {
				setLeaderboard(data)
			}
		}
	)

	const claimFgrBlockchain = transactionId => {
		axiosService(claimFgrBlockchainService(transactionId), (_data, error) => {
			if (!error) {
				dispatch(fgrClaimingCompleted())
				showNotification({ message: 'FGR tokens claimed successfully!' })
			}
			dispatch(setClaiming(false))
			dispatch(reloadRecentActivity())
			reloadUserInfo()
		})
	}

	const [{ loading: claimingFRG }, claimFGR] = useAxiosFetch(
		claimFGRService(projectId),
		(data, error) => {
			if (!error) {
				const title = project.title
				claimFgrBlockchain(data?.id)
				dispatch(showFgrModal({ fgrEarned, title, claiming: true }))
				showConfetti()
				setFGREarned(0)
			}
		}
	)

	const [{ loading: loadingUnclaimedFRG }, getProjectFGR] = useAxiosFetch(
		getProjectFgrUnclaimed(projectId),
		(data, error) => {
			if (!error) {
				setFGREarned(data.total)
			}
		},
		!isAuthenticated
	)

	const handleClaim = () => {
		claimFGR()
	}

	useEffect(() => {
		if (!_.isNil(projectId)) {
			if (autoexec) {
				updateSuperfanLevel()
				getProjectFGR()
			}
		}
	}, [projectId])

	useEffect(() => {
		if (!_.isNil(projectId)) {
			if (autoexec) {
				getLeaderboard()
			}
		}
	}, [projectId, leaderboardPagination])

	const disabledClaimBtn = fgrEarned === 0 || claiming
	const showMask =
		loading || claimingFRG || loadingUnclaimedFRG || loadingLeaderboard

	return {
		user,
		project,
		fanLevel,
		showMask,
		fgrEarned,
		projectId,
		openDialog,
		setOpenDialog,
		openPerkDialog,
		updateSuperfanLevel,
		setOpenPerkDialog,
		superfanUpdatedEvent,
		disabledClaimBtn,
		isAuthenticated,
		handleClaim,
		leaderboard,
		loadingLeaderboard,
		leaderboardPagination,
		setLeaderboardPagination
	}
}

export default useSuperfan
