import styled from 'styled-components'
import { Select, MenuItem } from '@mui/material'
import CustomArrow from 'assets/images/fanPurchase/customarrow.svg'
import PropTypes from 'prop-types'

const customArrowURL = CustomArrow.src

const CustomSelect = styled(Select)`
	margin-left: auto;
	margin-top: 10px;
	border: none;
	min-width: 89px;
	height: 37px;
	border-radius: 8px;
	font-family: Titillium Web;
	font-size: 20px;
	font-weight: 600;
	line-height: 24px;
	text-transform: uppercase;
	color: rgba(255, 255, 255, 1);
	background: rgba(63, 63, 67, 1);
	text-wrap: nowrap;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	padding: 0px 8px !important;
	.MuiOutlinedInput-notchedOutline {
		border: none;
	}

	.MuiSelect-select {
		padding: 0px !important;
		width: fit-content;
		display: flex;
		align-items: center;
		&::after {
			content: url('${customArrowURL}');
			margin-left: 10px;
			margin-bottom: 8px;
			display: flex;
			align-items: center;
		}
	}
	.MuiSelect-icon {
		display: none; // Hide the default arrow icon
	}
	li.Mui-selected {
		color: #000 !important;
	}

	&:focus {
		border-radius: 4px;
		background-color: #f0f0f0; /* Optional: Adds a background color on focus */
	}

	@media (max-width: 600px) {
		margin-top: 0px;
		.MuiSelect-select {
			font-size: 1rem;
		}
	}
`

const CustomMenuProps = {
	PaperProps: {
		style: {
			minWidth: '89px',
			borderRadius: '8px',
			backgroundColor: 'rgba(63, 63, 67, 1)'
		}
	},
	MenuListProps: {
		style: {
			color: 'rgba(255, 255, 255, 1)'
		}
	}
}
const CustomSelectComponent = ({ options, selectOption, value }) => {
	const handleChange = event => {
		selectOption(event.target.value)
	}

	return (
		<CustomSelect
			onChange={handleChange}
			value={value?.id ?? ''}
			displayEmpty={false}
			inputProps={{ 'aria-label': 'Without label' }}
			MenuProps={CustomMenuProps}
		>
			{options.map(ele => (
				<MenuItem key={ele.id} value={ele.id}>
					{ele.name}
				</MenuItem>
			))}
		</CustomSelect>
	)
}

CustomSelectComponent.propTypes = {
	options: PropTypes.array,
	selectOption: PropTypes.func,
	value: PropTypes.any
}

export default CustomSelectComponent
