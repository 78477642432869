export const getSuperfanLevelService = (projectId: string) => ({
	method: 'get',
	url: `/superfan/userlevel/${projectId}`,
	autoexec: false
})

export const getSuperfanRankService = (projectId: string) => ({
	method: 'get',
	url: `/superfan/rank/${projectId}`,
	autoexec: false
})

export const getSuperfanProjectsService = (userId: string) => ({
	method: 'get',
	url: `/superfan/projects/${userId}`,
	autoexec: false
})

export const getUserSuperFanProjectsService = (params: any) => ({
	method: 'get',
	url: `/superfan/projects`,
	params,
	autoexec: false
})

export const getUserSuperFanDashboardProjectsService = (params: any) => ({
	method: 'get',
	url: `/superfan/fan-dashboard/projects`,
	params,
	autoexec: false
})

export const getSuperfanTotalPointsService = () => ({
	method: 'get',
	url: `/superfan/totalPoints`,
	autoexec: false
})

export const getSuperfanLevelsService = () => ({
	method: 'get',
	url: `/superfan/levels`,
	autoexec: false
})

export const becomeFanService = (projectId: string) => ({
	method: 'put',
	url: '/fanRequirement/become-fan',
	data: { projectId }
})

export const checkAllFanRequirementsService = (projectId: string) => ({
	method: 'get',
	url: `/fanRequirement/check-all/${projectId}`
})

export const getSuperfanActions = () => ({
	method: 'get',
	url: `/superfan/actions`,
	autoexec: false
})
