import { getUserPerksProgramProjects } from '@/services/project.service'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { ProjectsCarouselItem } from '../becomeSuperFanFirstDialog/ProjectsCarouselItem'
import { ProjectsCarouselItemProps } from '../becomeSuperFanFirstDialog/ProjectsCarouselItem'
import useAxios from '@/hooks/useAxios'
import { queryKeys } from '@/lib/queryKeys'
import { useEffect } from 'react'
import { RootState } from '@/store/store'
import { useSelector } from 'react-redux'
import { X } from 'lucide-react'
import { ProjectCarouselItemSkeleton } from '../becomeSuperFanFirstDialog/ProjectsCarouselItem'

export const ProjectList = ({ handleClose }: { handleClose: () => void }) => {
	const { axiosServiceSync } = useAxios()
	const queryClient = useQueryClient()
	const { newSuperfanEvent } = useSelector(
		(state: RootState) => state.websocket
	)

	const { data: projects = [], isLoading } = useQuery({
		queryKey: queryKeys.project.perksProgramProjects(),
		queryFn: async () => {
			setTimeout(async () => {
				const projects = await axiosServiceSync(getUserPerksProgramProjects())
				return projects
			}, 10000)
		},
		staleTime: 1000 * 60 * 60 // 1 hour
	})

	useEffect(() => {
		if (projects?.length > 0) {
			queryClient.invalidateQueries({
				queryKey: queryKeys.project.perksProgramProjects()
			})
		}
	}, [newSuperfanEvent])

	return (
		<div className="absolute inset-x-0 bottom-0 flex h-[80%] w-full animate-bounce-in flex-wrap overflow-y-auto rounded-b-lg bg-black bg-opacity-50 py-4 backdrop-blur-sm">
			<div className="flex w-full items-center justify-between gap-2 px-4 pb-4 text-base text-white">
				<p>Check out these top projects!</p>
				<X
					onClick={handleClose}
					className="absolute right-4 top-4 cursor-pointer"
				/>
			</div>
			{isLoading
				? Array(5)
						.fill(null)
						.map((_, index) => (
							<div key={`skeleton-${index}`} className="mb-9">
								<ProjectCarouselItemSkeleton />
							</div>
						))
				: projects.map((project: ProjectsCarouselItemProps) => (
						<div key={project?.id} className="mb-9">
							<ProjectsCarouselItem {...project} />
						</div>
					))}
		</div>
	)
}
