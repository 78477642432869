'use client'
import { Provider } from 'react-redux'
import { NuqsAdapter } from 'nuqs/adapters/react-router'
import { WagmiConfig } from 'wagmi'
import { wagmiConfig } from 'utils/blockchain/config/constants'
import { ThemeProvider } from '@mui/material/styles'
import { ConfirmProvider } from 'material-ui-confirm'
import { theme } from './theme/Theme'
import { BrowserRouter } from 'react-router-dom'
import { ReactNode, useRef } from 'react'
import { makeStore } from './store/store'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ToastProvider } from '@radix-ui/react-toast'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 1 // Only retry failed requests once
		}
	}
})

type Props = {
	children: ReactNode
}

export const ContextProviders = ({ children }: Props) => {
	const storeRef = useRef<any>()
	if (!storeRef.current) {
		storeRef.current = makeStore()
	}

	return (
		<QueryClientProvider client={queryClient}>
			<NuqsAdapter>
				<BrowserRouter>
					<WagmiConfig config={wagmiConfig}>
						<ThemeProvider theme={theme}>
							<ConfirmProvider
								defaultOptions={{
									dialogProps: { classes: { root: 'modal_dark' } }
								}}
							>
								<Provider store={storeRef.current}>
									<ToastProvider>{children}</ToastProvider>
								</Provider>
							</ConfirmProvider>
						</ThemeProvider>
					</WagmiConfig>
				</BrowserRouter>
			</NuqsAdapter>
		</QueryClientProvider>
	)
}
