import { Box, Typography } from '@mui/material'
import { LightTooltip } from '@/components/common'
import { abbreviateNumberFixed, NumberWithDecimals } from 'utils/utils'
import GreenInfo from 'assets/images/walletIcons/green_info.svg'
import unLockIcon from 'assets/images/walletIcons/unlock.svg'
import newToken from 'assets/images/new_token_blue.svg'
import timerIcon from 'assets/images/walletIcons/timer.svg'
import infoIcon from 'assets/images/walletIcons/info.svg'
import styles from './RewardUnlockInfo.module.scss'

export const RewardUnlockInfo = ({
	balance,
	rewardsTokens,
	stakesTotal,
	isIngXRestrictedInvestor,
	availableToWithdraw
}) => {
	const handleFontSize = (balance, rewardsTokens, stakesTotal) => {
		const len1 = String(NumberWithDecimals(balance)).length
		const len2 = String(abbreviateNumberFixed(rewardsTokens, 2)).length
		const len3 = String(abbreviateNumberFixed(stakesTotal, 2)).length
		if (len1 < 6 && len2 < 6 && len3 < 6) return false
		return true
	}

	return (
		<Box className={styles.tokens_section}>
			<Box
				className={
					handleFontSize(balance, rewardsTokens, stakesTotal)
						? `${styles.arv_token} ${styles.small_gap} `
						: `${styles.arv_token}`
				}
			>
				{isIngXRestrictedInvestor && (
					<Box className={styles.green_info}>
						<LightTooltip
							enterTouchDelay={0}
							leaveTouchDelay={4000}
							placement="bottom"
							title="Your withdrawable amount is calculated based on the 30-day trailing Average Daily Trading Volume (ADTV) and the pro rata share of Principal FAN Token you have been awarded."
						>
							<span>
								<img src={GreenInfo.src} alt="gree Info" />
							</span>
						</LightTooltip>
					</Box>
				)}
				<Box className={styles.right_section}>
					<LightTooltip
						enterTouchDelay={0}
						leaveTouchDelay={4000}
						placement="bottom"
						title="Available Tokens are $FAN that have not yet been staked to any project. Staking these tokens will earn you more FGR."
					>
						<span>
							<img alt="info Icon" src={unLockIcon.src} />
						</span>
					</LightTooltip>
					<Typography
						component={'h3'}
						className={`${styles.header_tokens} ${styles.available_token}`}
					>
						Available Tokens
						<span className={styles.available_to_withdraw}>
							{availableToWithdraw}
						</span>
					</Typography>
				</Box>
				<Box
					className={
						handleFontSize(balance, rewardsTokens, stakesTotal)
							? `${styles.left_section} ${styles.small_font} `
							: `${styles.left_section}`
					}
				>
					<img alt="" className={styles.width_16} src={newToken.src} />
					<Typography component={'h2'} className={styles.text}>
						{NumberWithDecimals(balance, 2)}
					</Typography>
				</Box>
			</Box>
			<Box
				className={
					handleFontSize(balance, rewardsTokens, stakesTotal)
						? `${styles.arv_token} ${styles.small_gap} `
						: `${styles.arv_token}`
				}
			>
				<Box className={styles.right_section}>
					<LightTooltip
						enterTouchDelay={0}
						leaveTouchDelay={4000}
						placement="bottom"
						title="This is your Reward Wallet, where any rewards from completing tasks and staking will be stored. Please note that Fan rewards token cannot be transferred out of the platform."
					>
						<span>
							<img alt="info Icon" src={timerIcon.src} />
						</span>
					</LightTooltip>
					<Typography component={'h3'} className={styles.header_tokens}>
						Reward Tokens
					</Typography>
				</Box>
				<Box
					className={
						handleFontSize(balance, rewardsTokens, stakesTotal)
							? `${styles.left_section} ${styles.small_font} `
							: `${styles.left_section}`
					}
				>
					<img alt="" className={styles.width_16} src={newToken.src} />
					<Typography component={'h2'} className={styles.text}>
						{abbreviateNumberFixed(rewardsTokens, 2)}
					</Typography>
				</Box>
			</Box>
			<Box
				className={
					handleFontSize(balance, rewardsTokens, stakesTotal)
						? `${styles.arv_token} ${styles.small_gap} `
						: `${styles.arv_token}`
				}
			>
				<Box className={styles.right_section}>
					<LightTooltip
						enterTouchDelay={0}
						leaveTouchDelay={4000}
						placement="bottom"
						title="This represent the total amount of tokens that you have staked across various projects."
					>
						<span>
							<img alt="info Icon" src={infoIcon.src} />
						</span>
					</LightTooltip>
					<Typography component={'h3'} className={styles.header_tokens}>
						Staked Tokens
					</Typography>
				</Box>
				<Box
					className={
						handleFontSize(balance, rewardsTokens, stakesTotal)
							? `${styles.left_section} ${styles.small_font} `
							: `${styles.left_section}`
					}
				>
					<img alt="" className={styles.width_16} src={newToken.src} />
					<Typography component={'h2'} className={styles.text}>
						{abbreviateNumberFixed(stakesTotal ?? 0, 2)}
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}
