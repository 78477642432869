'use client'
import _ from 'lodash'
import { ChangeEvent, useState } from 'react'
import { addNewsreelService } from 'services/newsreel.service'
import useNotification from 'hooks/useNotification'
import { useDispatch, useSelector } from 'react-redux'
import { newsreelTabAddedHook } from '@/components/common/newsreelTab/newsreelTabSlice'
import useAxios from '@/hooks/useAxios'
import { useQueryClient } from '@tanstack/react-query'
import { queryKeys } from '@/lib/queryKeys'
import useProjectCommon from '../useProjectCommon'
import { RootState } from '@/store/store'

const useAddNewsreel = () => {
	const [media, setMedia] = useState<File[]>([])
	const [errors, setErrors] = useState({ content: '' })
	const [content, setContent] = useState('')
	const { showNotification } = useNotification()
	const { project } = useProjectCommon()
	const dispatch = useDispatch()
	const { axiosServiceSync } = useAxios()
	const queryClient = useQueryClient()
	const { user } = useSelector((state: RootState) => state.signIn)
	const projectId = project?.id
	const isCreator = user?.id === project?.userId
	const clearForm = () => {
		setContent('')
		setMedia([])
	}

	const createNewsreel = async () => {
		await axiosServiceSync(
			addNewsreelService({
				media,
				content,
				projectId
			})
		)
		clearForm()
		dispatch(newsreelTabAddedHook())
		showNotification({ message: 'Newsreel added successfully' })
		queryClient.invalidateQueries({
			queryKey: queryKeys.project.newsreel(projectId ?? '')
		})
	}

	const removeImg = (idx: number) => {
		setMedia(media.filter((_m, i) => i !== idx))
	}

	const handleContentChange = (e: any) => {
		setErrors({ content: '' })
		setContent(e.target.value)
	}

	const handleAddNewsreel = () => {
		if (_.isNil(content) || content === '')
			setErrors({ content: 'Please add text before posting to your Newsreel.' })
		else createNewsreel()
	}

	const handleUploadClick = (e: ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(e.target.files || [])
		setMedia([...media, ...files])
		e.target.value = ''
	}

	return {
		media,
		errors,
		content,
		isCreator,
		handleContentChange,
		handleAddNewsreel,
		handleUploadClick,
		clearForm,
		removeImg
	}
}

export default useAddNewsreel
