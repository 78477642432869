import { useDispatch, useSelector } from 'react-redux'
import {
	setFanPurchaseTransaction,
	updateFanPurchaseTransaction,
	setTransactionStatus,
	setFpPaymentCompleted,
	resetFanPurchaseTransaction
} from './webSocketSlice'
import { useEffect, useRef } from 'react'
import { isNil } from 'lodash'
import { getRandomInt } from 'utils/url.util'
import { reloadRecentActivity } from '@/components/profile/walletTab/walletTabSlice'
import useNotifications from '@/components/header/notifications/useNotifications'
import { setFanPurchanseDialogOpen } from '@/components/header/signInSlice'
import { addObjectToLocalStorage } from 'utils/utils'
import useHeader from '@/components/header/useHeader'
import { RootState } from '@/store/store'

const useWebsocketsHandlers = () => {
	const { getUnvisiteNotificationCount } = useNotifications()
	const { fanPurchaseTransaction, transactionStatus } = useSelector(
		(state: RootState) => state.websocket
	)
	const dispatch = useDispatch()
	const ref = useRef<any | null>(null)
	const { reloadUserInfo } = useHeader()

	const runCommonEvents = () => {
		getUnvisiteNotificationCount()
		dispatch(reloadRecentActivity())
		reloadUserInfo()
	}

	const sorryMessage = `Sorry for the delay! It's taking a bit longer than expected
	 to confirm your payment on the blockchain. 
	 Your tokens will be deposited into your wallet any minute now!`

	const handleFpPaymentProcessing = (data: any) => {
		const maxTimeSeconds = 5 * 60
		const progress = Math.min(
			(Number(data?.nowDiffSeconds) * 100) / maxTimeSeconds,
			100
		)
		const step = 100 / maxTimeSeconds
		const message =
			progress < 100
				? `Congratulations - your ${data?.awardedFanAmount} $FAN tokens are on
			the way! They will be deposited into your account as soon as your
			transaction is confirmed`
				: sorryMessage
		dispatch(
			setFanPurchaseTransaction({
				...data,
				message,
				progress,
				step
			})
		)
		dispatch(setTransactionStatus(1))
	}

	const handleFpPaymentCompleted = (data: any) => {
		dispatch(setFpPaymentCompleted(data))
		dispatch(setTransactionStatus(2))
		dispatch(setFanPurchanseDialogOpen(true))
		clearTimeout(ref.current)
		setTimeout(() => {
			dispatch(resetFanPurchaseTransaction())
		}, 3000)
	}

	const handleFpPaymentFailed = () => {
		dispatch(
			updateFanPurchaseTransaction({
				loadingBuyFan: false
			})
		)
		dispatch(setTransactionStatus(3))
	}

	const handleFanTransactionComplete = () => {
		reloadUserInfo()
	}

	const handleNotificationsUpdated = () => {
		getUnvisiteNotificationCount()
	}

	useEffect(() => {
		addObjectToLocalStorage({
			...fanPurchaseTransaction
		})
		if (!isNil(fanPurchaseTransaction?.message)) {
			if (fanPurchaseTransaction?.progress < 100) {
				const seconds = getRandomInt(1, 5)
				const percentIncrease = fanPurchaseTransaction?.step * seconds
				ref.current = setTimeout(() => {
					dispatch(
						updateFanPurchaseTransaction({
							progress: fanPurchaseTransaction?.progress + percentIncrease
						})
					)
				}, seconds * 1000)
			} else if (
				fanPurchaseTransaction?.message !== sorryMessage &&
				transactionStatus !== 2
			) {
				dispatch(
					updateFanPurchaseTransaction({ message: sorryMessage, progress: 100 })
				)
			}
		}
	}, [fanPurchaseTransaction])

	return {
		runCommonEvents,
		handleFpPaymentProcessing,
		handleFpPaymentCompleted,
		handleFpPaymentFailed,
		handleNotificationsUpdated,
		handleFanTransactionComplete
	}
}

export default useWebsocketsHandlers
