import PropTypes from 'prop-types'
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material'
import styles from './ModalNotification.module.scss'
import warning from 'assets/images/warning.png'

export const notificationTypes = {
	SUCCESS: 'success',
	WARNING: 'warning',
	ERROR: 'error',
	INFO: 'info'
}

const DEFAULTS = {
	[notificationTypes.SUCCESS]: {
		title: '',
		message: 'Operation Successfully Completed'
	},
	[notificationTypes.ERROR]: {
		title: 'Whoa there!',
		message: 'Oops! Something went wrong!'
	}
}

export const ModalNotification = ({
	type = notificationTypes.SUCCESS,
	handleClose,
	message,
	title,
	open,
	cancelButton,
	buttonCallback,
	variant,
	buttonText = 'Thanks',
	cancelButtonText = 'Cancel'
}) => {
	const headingText = title ?? DEFAULTS[type]?.title
	const msg = message || DEFAULTS[type].message

	return (
		<Dialog
			open={open}
			disableScrollLock={true}
			className={`${styles.dialog} ${styles[variant] ?? ''}`}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleClose}
		>
			{variant === 'warning' && <img alt="" src={warning.src} />}

			<DialogContent className={styles.dialog_content}>
				<Typography component={'h2'}>{headingText}</Typography>
				<Typography component={'p'}>{msg}</Typography>
				<Box className={styles.actions}>
					<Button
						size="small"
						className={styles.approve_btn}
						onClick={() => {
							buttonCallback && buttonCallback()
							handleClose()
						}}
					>
						{buttonText}
					</Button>
					{cancelButton && (
						<Button
							size="small"
							onClick={handleClose}
							className={styles.close_btn}
							style={{ marginLeft: '10px' }}
						>
							{cancelButtonText}
						</Button>
					)}
				</Box>
			</DialogContent>
		</Dialog>
	)
}

ModalNotification.propTypes = {
	type: PropTypes.string,
	title: PropTypes.string,
	handleClose: PropTypes.func,
	message: PropTypes.string,
	buttons: PropTypes.node,
	buttonText: PropTypes.string,
	variant: PropTypes.string,
	buttonCallback: PropTypes.func,
	cancelButtonText: PropTypes.string,
	cancelButton: PropTypes.bool,
	open: PropTypes.bool
}
