import PropTypes from 'prop-types'
import {
	Grid,
	Paper,
	FormControl,
	TextareaAutosize,
	Button,
	Box
} from '@mui/material'
import { TextFieldControl } from '@/components/common'
import { ReviewRating } from '../reviewRating'
import useWriteReview from './useWriteReview'
import styles from './WriteReview.module.scss'
import { CaptchaCheck } from '@/components/common/captchaCheck'

const WriteReview = ({ hide }) => {
	const {
		rating,
		heading,
		content,
		inputRef,
		isAuthenticated,
		handlePostReviewClick,
		handleClearForm,
		setHeading,
		setContent,
		setRating
	} = useWriteReview()

	const classes = hide
		? `${styles.create_review} ${styles.hide}`
		: styles.create_review

	const isAddingReviewDisabled =
		isAuthenticated && (content.length < 140 || content.length > 750)
	return (
		<Paper elevation={0} className={classes}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<h5>Add your review</h5>

					<FormControl sx={{ width: '100%' }}>
						<TextFieldControl
							inputRef={inputRef}
							value={heading}
							placeholder="Headline"
							className="light-theme"
							autoFocus
							onChange={e => {
								setHeading(e.target.value)
							}}
						/>
					</FormControl>

					<Box className={styles.chooseRating}>
						<ReviewRating {...{ rating, setRating }} />
					</Box>

					<TextareaAutosize
						value={content}
						aria-label="review content"
						placeholder="Review"
						style={{
							maxWidth: '100%'
						}}
						onChange={e => {
							setContent(e.target.value)
						}}
					/>
					<div
						className={styles.text_area_remaining_characters_feedback}
						style={{
							color:
								content.length < 140 || content.length > 750
									? 'rgba(255,255,255, 0.75)'
									: 'green'
						}}
					>
						{content.length < 140
							? `Minimum Characters Required: ${content.length}/140`
							: content.length > 750
								? 'Review is too long. Maximum 750 characters allowed.'
								: `Characters: ${content.length}/140`}
					</div>
					<Box className={styles.recaptcha}>
						<CaptchaCheck />
					</Box>
					<div className={styles.actions}>
						<Button
							className="square_button"
							onClick={handlePostReviewClick}
							disabled={isAddingReviewDisabled}
						>
							Post Review
						</Button>
						<Button
							variant="text"
							className={styles.cancelButton}
							onClick={handleClearForm}
						>
							Clear
						</Button>
					</div>
				</Grid>
			</Grid>
		</Paper>
	)
}

WriteReview.propTypes = {
	hide: PropTypes.bool
}

export default WriteReview
