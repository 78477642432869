import { useQueryClient } from '@tanstack/react-query'
import { queryKeys } from '@/lib/queryKeys'
import { useNavigate, useParams } from 'react-router-dom'
import { followUnfollowProject } from '@/services/followUnfollow.service'
import useProjectCommon from '../../useProjectCommon'
import { isNil } from 'lodash'

export const useYourImpactSection = () => {
	const { slug } = useParams()
	const queryClient = useQueryClient()
	const { project } = useProjectCommon()
	const navigate = useNavigate()

	const isFollowedByUser = project?.isFollowedByUser
	const followProject = async (projectId: string, follow: boolean) => {
		const res = await followUnfollowProject(projectId, follow)
		if (res.status === 200) {
			queryClient.invalidateQueries({
				queryKey: queryKeys.project.detail(slug)
			})
		}
	}

	const handleFollowClick = (projectId: string) => async () => {
		if (isNil(isFollowedByUser)) return
		await followProject(projectId, isFollowedByUser)
	}

	const handleGoToReviewTab = () => {
		navigate(`/project/${slug}?tab=reviews`)
	}

	const handleGoToNewsreelTab = () => {
		navigate(`/project/${slug}?tab=newsreel`)
	}

	return {
		project,
		isFollowedByUser,
		handleFollowClick,
		handleGoToReviewTab,
		handleGoToNewsreelTab
	}
}
