import { useSocialShareSection } from 'hooks/useSocialShareSection'
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogFooter,
	DialogTitle
} from '@/components/ui/dialog'
import { Textarea } from '@/components/ui/textarea'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { CiMail } from 'react-icons/ci'
import { SlSocialFacebook } from 'react-icons/sl'
import { FiTwitter, FiLinkedin } from 'react-icons/fi'
import { Label } from '@/components/ui/label'

export const SocialShareSection = ({ isMobile = false }) => {
	const {
		open,
		link,
		message,
		limitChars,
		handleOpenDialog,
		setMessage,
		handleClose,
		handleCopy,
		handleCopyLink,
		handleCopyMobile
	} = useSocialShareSection()
	return (
		<div
			className={` ${!isMobile ? 'max-[639px]:hidden sm:!flex' : 'max-[639px]:!flex sm:!hidden'} flex items-center justify-start gap-4`}
		>
			<Button variant="link" className="m-0 p-0" onClick={handleCopyLink}>
				<CiMail className="h-4 w-4" />
			</Button>
			<Button
				variant="link"
				className="m-0 p-0"
				onClick={handleOpenDialog('facebook')}
			>
				<SlSocialFacebook className="h-4 w-4" />
			</Button>
			<Button
				variant="link"
				className="m-0 p-0"
				onClick={handleOpenDialog('x')}
			>
				<FiTwitter className="h-4 w-4" />
			</Button>
			<Button
				variant="link"
				className="m-0 p-0"
				onClick={handleOpenDialog('linkedin')}
			>
				<FiLinkedin className="h-4 w-4" />
			</Button>
			{open && (
				<Dialog open={open} onOpenChange={handleClose}>
					<DialogContent className="sm:max-w-[335px]">
						<DialogHeader>
							<DialogTitle className="text-xl font-semibold tracking-[-0.025em]">
								Share
							</DialogTitle>
						</DialogHeader>
						<div className="space-y-4">
							<Label>Message</Label>
							<Textarea
								placeholder="Message"
								value={message}
								maxLength={limitChars}
								onChange={e => {
									if (e.target.value.length <= limitChars)
										setMessage(e.target.value)
								}}
								className="custom-scrollbar w-full text-xs"
								rows={5}
							/>
							<a
								href="#"
								onClick={handleCopyMobile}
								className="flex w-full items-center justify-end space-x-2 text-sm text-white hover:cursor-pointer sm:hidden"
							>
								<i className="far fa-copy"></i>
								<span>Copy your Message</span>
							</a>
							<span className="flex items-center space-x-2 text-sm text-white">
								Link
							</span>
							<div className="mt-2 text-gray-800">
								<Input
									value={link}
									readOnly
									className="w-full cursor-not-allowed"
								/>
							</div>
							<div className="text-sm">
								When you click <b>share</b>, your message will be copied to the
								clipboard. Simply paste it into the share box at your social
								network of choice.
							</div>
						</div>
						<DialogFooter>
							<Button variant="secondary" onClick={handleClose}>
								Cancel
							</Button>
							<Button
								onClick={handleCopy}
								variant="outline"
								className="text-white"
							>
								Share!
							</Button>
						</DialogFooter>
					</DialogContent>
				</Dialog>
			)}
		</div>
	)
}
