import { Box, Button } from '@mui/material'
import { CustomDialog } from '@/components/common'
import { useDispatch, useSelector } from 'react-redux'
import {
	setAirdropCheckDialogOpen,
	setLoginDialogOpen,
	setStartDialogOpen
} from '../signInSlice'
import styles from './StartDialog.module.scss'

export const StartDialog = () => {
	const { startDialogOpen } = useSelector(state => state.signIn)
	const dispatch = useDispatch()

	const openLoginDialog = () => {
		dispatch(setLoginDialogOpen(true))
		handleClose()
	}

	const openAirdropCheckDialog = () => {
		dispatch(setAirdropCheckDialogOpen(true))
		handleClose()
	}

	const handleClose = () => {
		dispatch(setStartDialogOpen(false))
	}

	return (
		<CustomDialog {...{ open: startDialogOpen, handleClose }}>
			<Box className={styles.header}>
				Be a Part of the World’s First Filmmaking DAO.
				<span>
					Let&apos;s make <span>great entertainment together.</span>
				</span>
			</Box>
			<Box className={styles.content}>
				<Button onClick={openLoginDialog}>Login to Early Access</Button>
				<Box className={styles.e_access_btn}>
					<Button onClick={openAirdropCheckDialog}>
						Check Early Access Eligibility
					</Button>
				</Box>
			</Box>
		</CustomDialog>
	)
}
