import { Progress } from '@/components/ui/progress'
import useDaoTiers from './useDaoTiers'
import { useSuperfanSection } from '@/components/profile/dashboardTab/superfanSection/useSuperfanSection'
import TierItem from './TierItem'

const DaoTiers = () => {
	const { tiers, globalProgress } = useDaoTiers()
	const { totalPoints } = useSuperfanSection()
	const renderTiers = () => {
		return tiers.map((tier, i) => (
			<TierItem
				key={`tier-${i}`}
				className={'flex-1 flex-shrink-0'}
				{...{
					...tier,
					totalPoints
				}}
			/>
		))
	}

	return (
		<div className="relative isolate w-full flex-1">
			<Progress
				value={globalProgress}
				className="absolute left-[10%] right-[10%] top-2 w-auto rounded-full"
			/>
			<div className="flex items-center justify-between">{renderTiers()}</div>
		</div>
	)
}

export default DaoTiers
