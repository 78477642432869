import PropTypes from 'prop-types'
import { useState } from 'react'
import { Box, Typography } from '@mui/material'
import stageImages from 'assets/images/stages'
import { CircularBar } from '@/components/projectDetail/dashboardTab/circularBar'
import styles from './GoScoreV2.module.scss'

const GoScore = ({ color, showText = true, goscore }) => {
	const [isHover, setIsHover] = useState(false)

	const handleMouseEnter = () => {
		setIsHover(true)
	}

	const handleMouseLeave = () => {
		setIsHover(false)
	}

	return (
		<Box className={styles.container}>
			<Box className={styles.score_container}>
				<Box
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					className={styles.score}
					style={{
						boxShadow: isHover ? `0 0 0.9rem 0.4rem ${color}` : 'none'
					}}
				>
					<CircularBar
						{...{
							height: 32,
							lineWidth: 10,
							background: '#101010',
							time: 0.5,
							value1: goscore?.stakingPercent,
							value2: goscore?.socialPercent,
							value3: goscore?.projectPercent,
							value4: 1,
							classes: goscore?.locked
								? `${styles.bar} ${styles.lockedBar}`
								: `${styles.bar}`,
							id: 'stake'
						}}
					/>

					<img
						alt=""
						src={stageImages.scoreRing}
						className={styles.ring_image}
					/>
					{goscore?.locked ? (
						<img
							alt=""
							src={stageImages.locked}
							className={styles.center_image}
						/>
					) : (
						<Typography className={styles.center_text}>
							{Math.round(goscore?.goScore) && Math.round(goscore?.goScore)}
						</Typography>
					)}
				</Box>
			</Box>
			{showText && (
				<Typography className={styles.bottom_text}>
					Go Score <sup>TM</sup>
				</Typography>
			)}
		</Box>
	)
}

GoScore.propTypes = {
	color: PropTypes.string,
	projectId: PropTypes.string,
	showText: PropTypes.bool,
	goscore: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	goScoreMilestones: PropTypes.array
}

export default GoScore
