import { isNil } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import useWebsocketsHandlers from './useWebsocketsHandlers'
import useSuperfanHandlers from './handlers/useSuperfanHandlers'
import useStakeHandlers from './handlers/useStakeHandlers'
import { RootState } from '@/store/store'
import { logger } from '@/utils/logger'
const websocketUrl =
	process.env.NEXT_PUBLIC_WEBSOCKET_URL ?? 'http://localhost:8000'

export const WSMessagesTypes = Object.freeze({
	FAN_PURCHASE: {
		PAYMENT_PROCESSING: 'fan_purchase.payment_processing',
		PAYMENT_CONFIRMED: 'fan_purchase.payment_confirmed',
		PAYMENT_FAILED: 'fan_purchase.payment_failed',
		DISTRIBUTION_INITIATED: 'fan_purchase.distribution_initiated',
		DISTRIBUTION_COMPLETE: 'fan_purchase.distribution_complete',
		DISTRIBUTION_FAILED: 'fan_purchase.distribution_failed'
	},
	SUPERFAN: {
		NEW_SUPERFAN_NOTIFICATION: 'new_superfan_notification',
		UPDATED_NOTIFICATION: 'superfan_updated_notification',
		POINTS_EARNED: 'superfan_points_earned'
	},
	STAKE: {
		COMPLETED_NOTIFICATION: 'stake_completed_notification',
		UNSTAKED_NOTIFICATION: 'stake_unstaked_notification'
	},
	NOTIFICATION: {
		NOTIFICATIONS_UPDATED: 'notifications_updated'
	},
	FAN_TRANSACTION: {
		COMPLETE: 'fan_transaction_complete'
	}
})

const useWebSocket = () => {
	const [socket, setSocket] = useState<WebSocket | null>(null)
	const [messages, setMessages] = useState<string[]>([])
	const reconnectTimeout = useRef<any | null>(null)
	const { isAuthenticated, jwt } = useSelector(
		(state: RootState) => state.signIn
	)
	const reconnectInterval = 5000
	const {
		runCommonEvents,
		handleFpPaymentProcessing,
		handleFpPaymentCompleted,
		handleFpPaymentFailed,
		handleNotificationsUpdated,
		handleFanTransactionComplete
	} = useWebsocketsHandlers()
	const {
		handleNewSuperfanNotification,
		handleSuperfanUpdatedNotification,
		handleSuperfanPointsEarned
	} = useSuperfanHandlers()
	const { handleStakeCompletedNotification, handleStakeUnstakedNotification } =
		useStakeHandlers()

	const handleEvent = (message: string) => {
		const data = JSON.parse(message)
		if (isNil(data.type)) {
			logger.info('Not a valid websocket event', data)
			return
		}
		const eventsMap = {
			[WSMessagesTypes.FAN_PURCHASE.PAYMENT_PROCESSING]:
				handleFpPaymentProcessing,
			[WSMessagesTypes.FAN_PURCHASE.DISTRIBUTION_COMPLETE]:
				handleFpPaymentCompleted,
			[WSMessagesTypes.FAN_PURCHASE.DISTRIBUTION_FAILED]: handleFpPaymentFailed,
			[WSMessagesTypes.FAN_PURCHASE.PAYMENT_CONFIRMED]: () => {},
			[WSMessagesTypes.FAN_PURCHASE.PAYMENT_FAILED]: () => {},
			[WSMessagesTypes.FAN_PURCHASE.DISTRIBUTION_INITIATED]: () => {},
			[WSMessagesTypes.SUPERFAN.NEW_SUPERFAN_NOTIFICATION]:
				handleNewSuperfanNotification,
			[WSMessagesTypes.SUPERFAN.UPDATED_NOTIFICATION]:
				handleSuperfanUpdatedNotification,
			[WSMessagesTypes.SUPERFAN.POINTS_EARNED]: handleSuperfanPointsEarned,
			[WSMessagesTypes.STAKE.COMPLETED_NOTIFICATION]:
				handleStakeCompletedNotification,
			[WSMessagesTypes.STAKE.UNSTAKED_NOTIFICATION]:
				handleStakeUnstakedNotification,
			[WSMessagesTypes.NOTIFICATION.NOTIFICATIONS_UPDATED]:
				handleNotificationsUpdated,
			[WSMessagesTypes.FAN_TRANSACTION.COMPLETE]: handleFanTransactionComplete
		}
		if (isNil(eventsMap[data.type])) {
			logger.info(`No handler for event ${data.type}`)
			return
		}
		eventsMap[data.type](data)
	}

	useEffect(() => {
		const connect = () => {
			logger.info('Connecting to ws') // TODO remove after testing
			if (!isNil(jwt)) {
				// Open a WebSocket connection
				logger.info({ websocketUrl }) // TODO remove after testing
				const ws = new WebSocket(`${websocketUrl}?jwt=${jwt}`)

				// Set the WebSocket object to state
				setSocket(ws)

				ws.onopen = () => {
					logger.info('Connection established!') // TODO remove after testing
					clearTimeout(reconnectTimeout.current)
				}

				// Handle incoming messages
				ws.onmessage = event => {
					// TODO remove after testing
					logger.info('WebSocket message received:', event.data)
					setMessages(prevMessages => [...prevMessages, event.data])
					runCommonEvents()
					handleEvent(event.data)
				}

				// Handle WebSocket errors
				ws.onerror = error => {
					logger.error('WebSocket error:', error)
				}

				// Handle WebSocket connection close
				ws.onclose = () => {
					logger.error('Closing WebSocket connection')
					reconnectTimeout.current = setTimeout(connect, reconnectInterval)
				}
			}
		}
		connect()

		// Cleanup on unmount
		return () => {
			clearTimeout(reconnectTimeout.current)
			if (socket) {
				socket.close()
			}
		}
	}, [isAuthenticated])

	// Function to send a message to the WebSocket server
	const sendMessage = (message: string) => {
		if (socket) {
			socket.send(message)
		}
	}

	return {
		socket,
		messages,
		sendMessage
	}
}

export default useWebSocket
