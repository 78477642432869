import { Description } from '../ProjectDescription'
import { ProjectGenreBadges } from '../ProjectGenreBadges'
import { ProjectMediaCarousel } from '../ProjectMediaCarousel'
import { ProjectNewsreel } from '../ProjectNewsreel'
import { Project, TrailerInfo } from '../useProjectPage'

interface ProjectAboutTabProps {
	project: Project
	trailerInfo: TrailerInfo
}

export function ProjectAboutTab({
	project,
	trailerInfo
}: ProjectAboutTabProps) {
	return (
		<>
			<ProjectMediaCarousel
				media={[
					...(trailerInfo.hasTrailer
						? [
								{
									url: trailerInfo.player_embed_url!,
									thumbnailUrl: trailerInfo.thumbnail_url!,
									kind: 'video' as const
								}
							]
						: []),
					...[project.poster, project.coverImg, project.thumbnail]
						.filter((url): url is string => url !== null)
						.map(url => ({
							url,
							kind: 'image' as const
						}))
				]}
			/>
			<ProjectGenreBadges
				title={project.title}
				stage={project.goScoreStage}
				genres={project.genres}
			/>

			<Description logline={project.logline} story={project.story} />
			<ProjectNewsreel projectId={project.id} />
		</>
	)
}
