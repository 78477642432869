import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAxios from 'hooks/useAxios'
import { setConnectDialogOpen, setFanPurchanseDialogOpen } from '../signInSlice'
import {
	setTransactionStatus,
	updateFanPurchaseTransaction,
	resetFanPurchaseTransaction
} from 'hooks/websocket/webSocketSlice'
import useWeb3 from 'hooks/useWeb3'
import BuyFanMethods from 'utils/blockchain/functions/buyFan'
import {
	getNetworkService,
	getTokenPriceService,
	postPurchaseService,
	postFailPurchaseService,
	checkInProgressTransactionService
} from 'services/fanPurchase.service'
import { chains } from 'utils/blockchain/config/constants'
import { signVerificationMessage } from 'utils/blockchain/config/utils'
import useNotification from 'hooks/useNotification'
import { logger } from '@/utils/logger'
import { Mixpanel } from 'services/mixpanel.service'
export const useFanPurchaseDialog = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { isAuthenticated, user, fanPurchanseDialogOpen } = useSelector(
		state => state.signIn
	)
	const { fanPurchaseTransaction, transactionStatus } = useSelector(
		state => state.websocket
	)
	const { signer, address, isConnected, chain, switchNetworkAsync } = useWeb3()
	const { showNotification } = useNotification()
	const { axiosService } = useAxios()
	const handleCloseDialog = () => {
		dispatch(setFanPurchanseDialogOpen(false))
	}
	const [value, setValue] = useState(0)
	const [tokenAmount, setTokenAmount] = useState(
		fanPurchaseTransaction?.amount ?? 0
	)
	const [tokenOptions, setTokenOptions] = useState([])
	const [networkOptions, setNetworkOptions] = useState([])
	const [network, setNetwork] = useState(
		fanPurchaseTransaction?.network ?? null
	)
	const [token, setToken] = useState(fanPurchaseTransaction?.token ?? null)
	const [fanPrice, setFanPrice] = useState(0)
	const [fanAmount, setFanAmount] = useState(
		fanPurchaseTransaction?.awardedFanAmount ?? 0
	)
	const [walletConnected, setWalletConnected] = useState(false)
	const [loadingBuyFan, setLoadingBuyFan] = useState(
		fanPurchaseTransaction?.loadingBuyFan
	)
	const [balance, setBalance] = useState(null)
	const [balanceError, setBalanceError] = useState(null)
	const [loadingBalance, setLoadingBalance] = useState(false)
	const [updatingNetwork, setUpdatingNetwork] = useState(false)
	const paymentIntiated = transactionStatus !== 0
	const loading = transactionStatus === 1
	const purchaseComplete = transactionStatus === 2

	const getNetworkOptions = () => {
		axiosService(getNetworkService(), (data, error) => {
			if (!error) {
				if (_.isNil(network)) {
					setNetwork(data[0])
				} else {
					const index = data?.findIndex(ele => ele.id === network?.id)
					if (index !== -1) {
						setNetwork(data[index])
					} else {
						setNetwork(data[0])
					}
				}
			}
			setNetworkOptions(data)
		})
	}

	const getTokenOptions = () => {
		if (_.isNil(network?.id)) return
		axiosService(getTokenPriceService(network?.id), (data, error) => {
			if (!error) {
				setTokenOptions(data?.tokens)
				setFanPrice((data?.fanPrice ?? 0).toFixed(6))
			}
		})
	}

	const checkInProgress = () => {
		axiosService(checkInProgressTransactionService(), (data, error) => {
			if (!error) {
				if (!data?.result) {
					dispatch(setTransactionStatus(0))
					setLoadingBuyFan(false)
					dispatch(resetFanPurchaseTransaction())
				}
			}
		})
	}

	const selectNetwork = async id => {
		if (networkOptions.length === 0) return
		const index = networkOptions.findIndex(ele => ele.id === id)
		setUpdatingNetwork(true)
		if (index !== -1) {
			const newNetwork = networkOptions[index]
			let chainId = null
			if (!_.isNil(newNetwork?.name)) {
				chainId = chains.find(chain => chain?.name === newNetwork?.name)?.id
			}
			setNetwork(newNetwork)
			dispatch(
				updateFanPurchaseTransaction({
					token,
					network: newNetwork
				})
			)
			if (
				!_.isNil(chain) &&
				chainId !== chain.id &&
				!_.isNil(switchNetworkAsync)
			) {
				switchNetworkAsync(chainId).finally(() => setUpdatingNetwork(false))
			} else {
				setUpdatingNetwork(false)
			}
		} else {
			setUpdatingNetwork(false)
		}
	}

	const selectOption = id => {
		if (tokenOptions.length === 0) return
		const index = tokenOptions.findIndex(ele => ele.id === id)
		if (index !== -1) {
			setLoadingBalance(true)
			BuyFanMethods.getBalance(address, tokenOptions[index], network.name).then(
				_balance => {
					setBalance(_balance)
					setLoadingBalance(false)
				}
			)
			setToken(tokenOptions[index])
		}
	}

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	const handleClickConnect = () => {
		dispatch(setConnectDialogOpen(true))
	}
	const handleInputChange = event => {
		const newValue = event.target.value === '' ? 0 : Number(event.target.value)
		setTokenAmount(newValue)
	}

	const afterFanPurchaseFailed = () => {
		dispatch(setTransactionStatus(0))
		setLoadingBuyFan(false)
		dispatch(resetFanPurchaseTransaction())
	}

	const afterFanBuy = async data => {
		if (!_.isNil(data)) {
			let hash = null
			try {
				if (token?.isNativeToken) {
					hash = await BuyFanMethods.buyFanWithNative(
						data?.purchaseAmount,
						data?.id,
						signer,
						token?.network?.name
					)
				} else {
					hash = await BuyFanMethods.buyFanWithTokenContract(
						{
							tokenName: token?.name,
							decimals: token?.decimals
						},
						data?.purchaseAmount,
						data?.id,
						signer,
						token?.network?.name
					)
				}
			} catch (error) {
				if (error?.message === 'Gas Price Error!') {
					showNotification({
						message: `${network?.name} gas fees are significantly higher than usual which caused your purchase to fail! Please either increase the gas fees of your transaction or give it a few minutes and try again later`
					})
				}
			}
			setLoadingBuyFan(false)
			if (_.isNil(hash) && !_.isNil(data?.id)) {
				axiosService(
					postFailPurchaseService({ fanPurchaseTransactionId: data?.id }),
					afterFanPurchaseFailed
				)
			}
			dispatch(setTransactionStatus(1))
			dispatch(
				updateFanPurchaseTransaction({
					loadingBuyFan: false
				})
			)
		} else {
			setLoadingBuyFan(false)
			dispatch(resetFanPurchaseTransaction())
			getTokenOptions()
		}
	}

	const handleBuyFan = async () => {
		if (address && signer && isAuthenticated) {
			try {
				setLoadingBuyFan(true)
				const message =
					'Please sign this message to verify you are the owner of this wallet.'
				const { hash, signature } = await signVerificationMessage(
					signer,
					message
				)
				dispatch(
					updateFanPurchaseTransaction({
						token,
						network,
						awardedFanAmount: fanAmount,
						amount: tokenAmount,
						loadingBuyFan: true
					})
				)

				const data = {
					purchaseAmount: tokenAmount,
					tokenId: token?.id,
					tokenPrice: token?.price,
					awardedFanAmount: fanAmount,
					buyingWallet: address,
					hash,
					signature
				}

				axiosService(postPurchaseService(data), afterFanBuy)
			} catch (error) {
				setLoadingBuyFan(false)
				dispatch(
					resetFanPurchaseTransaction({
						loadingBuyFan: false
					})
				)
				logger.error(error)
			}
		}
	}

	const handleNavigate = () => {
		dispatch(setFanPurchanseDialogOpen(false))
		navigate(`/profile/${user?.username}?tab=4`)
	}

	const handleBuyAgain = () => {
		dispatch(setTransactionStatus(0))
	}

	useEffect(() => {
		if (address && isConnected && isAuthenticated) {
			Mixpanel.track('fan-purchase-wallet-connected', {
				distinct_id: user?.id,
				displayName: user?.displayName,
				username: user?.username,
				address
			})
			setWalletConnected(true)
		} else {
			setWalletConnected(false)
			setBalance(null)
		}
	}, [address, isConnected])

	useEffect(() => {
		getNetworkOptions()
	}, [])

	useEffect(() => {
		if (tokenOptions && tokenOptions.length > 0) {
			if (_.isNil(token)) {
				setToken(tokenOptions[0])
			} else {
				const index = tokenOptions.findIndex(ele => ele.id === token?.id)
				if (index !== -1) {
					if (
						tokenOptions[index].id !== token.id ||
						_.isNil(balance) ||
						balance === 0
					) {
						setToken(tokenOptions[index])
					}
				} else {
					setToken(tokenOptions[0])
				}
			}
		}
	}, [tokenOptions, balance, token])

	useEffect(() => {
		if (_.isNil(token) || _.isNil(address) || _.isNil(network)) return

		setLoadingBalance(true)
		BuyFanMethods.getBalance(address, token, network.name).then(_balance => {
			setBalance(_balance)
			setLoadingBalance(false)
		})
	}, [token, address, network])

	useEffect(() => {
		if (
			!_.isNil(balance) &&
			!_.isNil(tokenAmount) &&
			!_.isNil(token) &&
			!loadingBalance
		) {
			if (balance < tokenAmount) {
				setBalanceError(`Not enough ${token.name}`)
			} else {
				setBalanceError(null)
			}
		} else {
			setBalanceError(null)
		}
	}, [balance, tokenAmount, token, loadingBalance])

	useEffect(() => {
		let intervalId
		if (fanPurchanseDialogOpen) {
			getTokenOptions()
			checkInProgress()
			intervalId = setInterval(getTokenOptions, 30000)
		}
		return () => {
			if (intervalId) clearInterval(intervalId)
		}
	}, [network, fanPurchanseDialogOpen])

	useEffect(() => {
		if (
			fanPrice <= 0 ||
			_.isNil(token) ||
			token?.price <= 0 ||
			_.isNil(tokenAmount)
		)
			return

		const amount = (token?.price * tokenAmount) / fanPrice
		setFanAmount((amount ?? 0).toFixed(6))
	}, [token, tokenAmount, fanPrice])

	useEffect(() => {
		if (!fanPurchanseDialogOpen || updatingNetwork) return
		setUpdatingNetwork(true)

		let chainId = null
		if (!_.isNil(network?.name)) {
			chainId = chains.find(chain => chain?.name === network?.name)?.id
		}
		if (
			!_.isNil(chain) &&
			chainId !== chain.id &&
			!_.isNil(switchNetworkAsync)
		) {
			switchNetworkAsync(chainId).finally(() => setUpdatingNetwork(false))
		} else {
			setUpdatingNetwork(false)
		}
	}, [
		chain,
		network,
		fanPurchanseDialogOpen,
		updatingNetwork,
		switchNetworkAsync
	])

	return {
		fanPurchanseDialogOpen,
		fanPurchaseTransaction,
		value,
		tokenAmount,
		tokenOptions,
		networkOptions,
		network,
		token,
		fanPrice,
		fanAmount,
		walletConnected,
		loadingBuyFan,
		balance,
		loadingBalance,
		paymentIntiated,
		loading,
		purchaseComplete,
		user,
		balanceError,
		selectNetwork,
		selectOption,
		handleChange,
		handleClickConnect,
		handleInputChange,
		handleNavigate,
		handleBuyFan,
		handleBuyAgain,
		handleCloseDialog
	}
}
