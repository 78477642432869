import { useState } from 'react'
import { Box, Typography, Popover, IconButton, Icon } from '@mui/material'
import PropTypes from 'prop-types'
import images from 'assets/images/product-detail'
import styles from './VaultlockPopover.module.scss'
import { formatDate } from 'utils/date.utils'

export default function VaultlockPopover({ title, date, vaultlockQR }) {
	const [anchorEl, setAnchorEl] = useState(null)

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)

	return (
		<Box>
			<Box
				className={styles.text_icon}
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup="true"
				onMouseEnter={handlePopoverOpen}
				sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center' }}
			>
				<IconButton size="small">
					<Icon baseClassName="fas" className="fa-shield-keyhole" />
				</IconButton>
				<span>Vaultlocked</span>
			</Box>
			<Popover
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left'
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
				classes={{
					paper: styles.vaultlock
				}}
			>
				<Box className={styles.content} onMouseLeave={handlePopoverClose}>
					<Box className={styles.left}>
						<Typography component={'h3'}>Vault Locked</Typography>
						<Box className={styles.images_container}>
							<img alt="" src={images.qrLogo} className={styles.logo} />
							<img alt="" src={vaultlockQR} className={styles.qr} />
						</Box>
					</Box>
					<Box className={styles.right}>
						<Typography component={'h3'}>
							{title} was added to the blockchain on{' '}
							<span>{formatDate(date, 'med')}</span>.
						</Typography>
					</Box>
				</Box>
			</Popover>
		</Box>
	)
}

VaultlockPopover.propTypes = {
	title: PropTypes.string,
	vaultlockQR: PropTypes.string,
	date: PropTypes.string
}
