import PropTypes from 'prop-types'
import { Box, Button, Icon, IconButton, Typography } from '@mui/material'
import { CustomDialog } from '@/components/common'
import { getFileUrl } from 'utils/url.util'
import { formatDate, getNowDateDiff } from 'utils/date.utils'
import useDaoProposalDialog from './useDaoProposalDialog'
import styles from './DaoProposalDialog.module.scss'
import { VotersList } from '../votersList'
import {
	abbreviateNumberFixed,
	formatAddress,
	getTransactionLink
} from 'utils/utils'
import { useEffect } from 'react'
import { ConfirmDialog } from '@/components/common-ui'

const DaoProposalDialog = ({ open, handleClose, daoProposal }) => {
	const { loading, daoProposalUpdated, handleVote, getDaoProposalById } =
		useDaoProposalDialog()
	const currentDaoProposal =
		daoProposalUpdated?.id === daoProposal?.id
			? daoProposalUpdated
			: daoProposal
	const {
		id,
		name,
		owner,
		poster,
		endDate,
		startDate,
		description,
		totalVotesBalance,
		totalVotes,
		votedByUser,
		yesPercent,
		noPercent,
		yesAmount,
		noAmount,
		ipfsHash,
		blockchainHash
	} = currentDaoProposal

	const dateDiff = getNowDateDiff(endDate)
	const isExpired = dateDiff > 0
	const pillText = isExpired ? 'Ended' : 'Active'
	const finalText = yesPercent >= noPercent ? 'Accepted' : 'Rejected'
	const pillClass = isExpired
		? `${styles.status_pill} ${styles.ended}`
		: styles.status_pill
	const percent = Math.max(yesPercent, noPercent)
	const percentText = yesPercent >= noPercent ? 'Yes' : 'No'
	const percentBackground =
		yesPercent >= noPercent
			? `linear-gradient(90deg, #01BF52, #01ACBD, #333333 ${percent}%);`
			: `linear-gradient(90deg, #e88b8b, #ef3e3e, #ff0000 ${percent}%)`

	useEffect(() => {
		getDaoProposalById(id)
	}, [id])

	return (
		<CustomDialog
			{...{
				open: open && !loading,
				handleClose,
				variant: 'proposalView'
			}}
		>
			<Box className={styles.content}>
				<Box className={styles.header}>
					<img
						loading="lazy"
						className={styles.poster}
						src={getFileUrl(poster + '?tr=w-503,h-170')}
						alt=""
					/>
				</Box>
				<Box className={styles.info}>
					<Box className={styles.top}>
						<Box>
							<Typography component={'h3'} className={styles.owner}>
								{' '}
								{owner}{' '}
							</Typography>
							<Typography component={'h2'} className={styles.title}>
								{' '}
								{name}{' '}
							</Typography>
						</Box>
						<Box className={pillClass}>{isExpired ? finalText : pillText}</Box>
					</Box>
					<Box className={styles.breakdown}>
						<Typography component={'h3'}>Current Results</Typography>
						<Box className={styles.results}>
							<Box className={styles.left}>
								<Box className={styles.votingWeight_container}>
									<IconButton>
										<Icon baseClassName="fa-kit" className="fa-fan-stake" />
									</IconButton>
									<Box className={styles.text}>
										{abbreviateNumberFixed(totalVotesBalance, 2)} FAN
									</Box>
									<Box
										className={styles.bar}
										sx={{
											background: percentBackground
										}}
									></Box>
									<Box className={styles.text}>{percentText}</Box>
								</Box>
								<Box className={styles.voteTotal}>
									<IconButton>
										<Icon baseClassName="fal" className="fa-box-ballot" />
									</IconButton>
									<Typography>{totalVotes} Votes</Typography>
								</Box>
							</Box>
							<Box className={styles.right}>
								<Box>
									<Typography>
										<strong>Start Date</strong>
									</Typography>
									<Typography>{formatDate(startDate, 'full')}</Typography>
								</Box>
								<Box>
									<Typography>
										<strong>End Date</strong>
									</Typography>
									<Typography>{formatDate(endDate, 'full')}</Typography>
								</Box>
								<Box>
									<Typography>
										<strong>Voting System</strong>
									</Typography>
									<Typography>Single Choice Voting</Typography>
								</Box>
								<Box>
									<Typography>
										<strong>Transaction</strong>
									</Typography>
									<Typography>{getTransactionLink(blockchainHash)}</Typography>
								</Box>
								<Box>
									<Typography>
										<strong>IPFS Link</strong>
									</Typography>
									<a
										href={`https://filmio.quicknode-ipfs.com/ipfs/${ipfsHash}`}
										target="_blank"
										rel="noreferrer"
									>
										<Typography>{formatAddress(ipfsHash)}</Typography>
									</a>
								</Box>
							</Box>
						</Box>
						<Box className={styles.summary}>
							<Typography component={'h3'} className={styles.name}>
								{' '}
								Summary{' '}
							</Typography>
							<Typography component={'div'} className={styles.desc}>
								<div dangerouslySetInnerHTML={{ __html: description }} />
							</Typography>
						</Box>
						<Box className={styles.vote_breakdown}>
							<Typography component={'h3'} className={styles.name}>
								Vote Breakdown
							</Typography>
							<Box>
								<Typography className={styles.text}>Yes</Typography>
								<Box
									className={styles.bar}
									sx={{
										background: `linear-gradient(90deg, #01BF52, #000 ${yesPercent}%);`
									}}
								></Box>
								<Typography className={styles.text_fan}>
									{abbreviateNumberFixed(yesAmount, 2)} FAN
								</Typography>
								<Typography className={styles.text_percent}>
									{Math.round(yesPercent)}%
								</Typography>
							</Box>
							<Box>
								<Typography className={styles.text}> No </Typography>
								<Box
									className={styles.bar}
									sx={{
										background: `linear-gradient(90deg, #B93838, #000 ${noPercent}%);`
									}}
								></Box>
								<Typography className={styles.text_fan}>
									{abbreviateNumberFixed(noAmount, 2)} FAN
								</Typography>
								<Typography className={styles.text_percent}>
									{Math.round(noPercent)}%
								</Typography>
							</Box>
						</Box>
						<VotersList {...{ daoProposalId: id }} />
					</Box>
					<Box className={styles.footer}>
						<ConfirmDialog
							message="Accept this proposal? This will vote to implement the algorithmic FGR with default 20% Annual FGR Rate."
							onConfirm={handleVote(1, id)}
							confirmText="Accept Proposal"
						>
							<Button
								className={styles.approveBtn}
								disabled={votedByUser || isExpired}
							>
								Approve
							</Button>
						</ConfirmDialog>
						<ConfirmDialog
							message="Reject this proposal? This will vote to maintain the current promotional FGR rate structure."
							onConfirm={handleVote(0, id)}
							confirmText="Reject Proposal"
						>
							<Button
								className={styles.rejectBtn}
								disabled={votedByUser || isExpired}
							>
								Reject
							</Button>
						</ConfirmDialog>
					</Box>
				</Box>
			</Box>
		</CustomDialog>
	)
}

DaoProposalDialog.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	daoProposal: PropTypes.object
}

export default DaoProposalDialog
