import Image from 'next/image'
import productDetailImages from 'assets/images/product-detail'

interface Props {
	url?: string | null
	alt?: string
}

export function ProjectHero({ url, alt }: Props) {
	const projectCover = url ?? productDetailImages.defaultProjectCover
	const altText = alt ?? 'Project Cover'

	return (
		<div className="relative h-96 w-full">
			<div className="via-background/40 absolute inset-x-0 bottom-0 z-10 h-full bg-gradient-to-t from-background from-[17.15%] via-[60.72%] to-transparent"></div>
			<Image
				src={projectCover}
				fill
				className="object-cover"
				alt={altText}
				blurDataURL="data:image/webp;base64,UklGRpIDAABXRUJQVlA4IIYDAAAwVACdASq+Ak8BPpFGoEylo6aioPM44NASCWlu4XSEDmNwuOAEUiAFaPl3Ser7/rMxWvv+szFa+/6zMVr7/okkfNoPV9/1mYrX3/WZitff9ZmK1+AOeD1ff9ZmK19/1mYrX3/WZitff95d9X3/WZitff9ZmK19/1mYrX3/XCS5cGoAg9X3/WZc9iuJ7rHBqAIPV9/18utZmK19/1mYrUKJFzYboLKG+1QKXLKBqAIPV59oOFHrMxWvv9pUSP+fr+wgR8afC3bSFNSovmpgBmdoxVvq+/6zMVrzpoL89X3/WZb9zcn9cNAiP5WOQW6oMvqPZJHPA7Av5Xc6L96y4DKBqAIPVKSEAQer7/rEMzGFACdzBky0qhmvMZw45Y4VWg3qF69ki5ZQNOi7r/rMxWvvucKXmFrs1SShl0fJFDkgAgnW5Dfvu43VDlA1AEHq86aC/PV9/1mEX8hDIatiDhCsSNjNYXlyxGLEf/YufVqnNcmSLllA0/l9W+DCigCD1QNE19DjQq8nJ0F4aPJAOgFx7xIYENATNqOlVoJvKraRcsoGoAOkyrX3/WZitRhTMHt3fy5Cm8vD2mA5cPBX4dPHcCMe+weAo4UeszFa8oafBllA1AEHq8zTJJcIJTJIcTA6mrILe5HD9ajiI4UeszFa8uPwCPWZitff9Nh0UMWGK+GffJvvlUk4/fb08W/slFAEHq+++BsDg1AEHq+/13EFXUzi4KAQq5V9sgOt3eJ7R/AUUAQer774GwODUAQer7/xxDVPi++HPq+/6zMVqKzW0bCxy7B+lA2xc0RMbJjpYfLkWOXYP0oG2Fc8u9qAIPV9/1mYrX3/WZitff9ZmKr9XwooAg9X3/WZitff9ZmK19/1mXK7r/rMxWvv+szFa+/6zMVr7/rMxG9AAP7+9R+AuXeB9CSb0oxgAAAVNp5FaYvHQrqFPKJjE7+ttDemOoumGorCrON8YDxzfeVjAZRJVR9eN7VVG/UFCyMC4V3WtA/9hrJ78sBVRXq+SV0vOyAxHZ2/c5D91k4zaRRhlxxTzlAVteCCTmNzqwrAGbG+yeRxDNxuqG/qnsoItqvWosHcfgP8XUIVTP6ckDhje5zzWEuSLV4TJTV0DpNDxYG0xLIIm592NCRcQLJgruNTmoMguwLxBkAMwTSUDb5NexQxcYABx94AEjIAJyQAAOBckAAAAAAAAA==" // TODO move this to a file
			/>
		</div>
	)
}
