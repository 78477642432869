import { Card, CardHeader, CardContent } from '@/components/ui/card' // adjust the import path based on your project

import { useState, useEffect } from 'react'
import clsx from 'clsx'
import { FaChartLine } from 'react-icons/fa6'
import { LuCoins } from 'react-icons/lu'
import { HiOutlineCircleStack } from 'react-icons/hi2'
import { TbSquareRotated } from 'react-icons/tb'
import { GoShareAndroid } from 'react-icons/go'
import { GoStar } from 'react-icons/go'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { getStatsService } from 'services/dashboard.service'
import { abbreviateNumberFixed } from 'utils/utils'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { UserKingIcon } from '../../../../sidebar'

const formatValue = (key, value, subValues, username) => {
	const fixedValue = abbreviateNumberFixed(value ?? 0, 2)
	switch (key) {
		case 'totalTokens':
		case 'withdrawable':
			return (
				<>
					<Link
						className="absolute top-0 h-[95%] w-full text-xl font-semibold tracking-[-0.025em] text-white"
						to={`/profile/${username}?tab=4`}
					></Link>
					{fixedValue}{' '}
				</>
			)
		case 'unclaimedFgr':
			return (
				<>
					<Link
						className="absolute top-0 h-[95%] w-full text-xl font-semibold tracking-[-0.025em] text-white"
						to={`/profile/${username}?tab=4&section=fgr`}
					></Link>
					{fixedValue}{' '}
				</>
			)
		case 'rewards':
		case 'staked':
			return `${fixedValue} $FAN`
		case 'availableGems':
			return fixedValue
		case 'referrals':
			return (
				<>
					{fixedValue}{' '}
					<span className="rounded-full bg-[#3cbb5b] px-[10px] py-[2px] text-sm font-semibold leading-4 text-background">
						+{abbreviateNumberFixed(subValues?.referralsChange ?? 0, 2)}%
					</span>{' '}
				</>
			)
		case 'followers':
			return (
				<>
					{fixedValue}{' '}
					<span className="rounded-full bg-[#3cbb5b] px-[10px] py-[2px] text-sm font-semibold leading-4 text-background">
						+{abbreviateNumberFixed(subValues?.followersChange ?? 0, 2)}%
					</span>{' '}
				</>
			)
		case 'reviews':
			return (
				<>
					{fixedValue} /
					<span className="text-sm font-normal text-white">
						{abbreviateNumberFixed(subValues?.averageRating)} avg
					</span>
				</>
			)
		default:
			return fixedValue
	}
}
const initialStats = [
	{
		title: 'Total Tokens',
		valueKey: 'totalTokens', // key matching the backend response
		border_color: 'border-primary',
		color: 'text-primary',
		icon: LuCoins
	},
	{
		title: 'Withdrawable',
		valueKey: 'withdrawable',
		border_color: 'border-[#FFFFFF33]',
		color: 'text-primary',
		icon: LuCoins
	},
	{
		title: 'Unclaimed FGR',
		valueKey: 'unclaimedFgr',
		border_color: 'border-[#FFFFFF33]',
		color: 'text-primary',
		icon: FaChartLine
	},
	{
		title: 'Rewards',
		valueKey: 'rewards',
		border_color: 'border-[#FFFFFF33]',
		color: 'text-primary',
		icon: LuCoins
	},
	{
		title: 'Staked',
		valueKey: 'staked',
		border_color: 'border-[#FFFFFF33]',
		color: 'text-primary',
		icon: HiOutlineCircleStack
	},
	{
		title: 'Available Gems',
		valueKey: 'availableGems',
		border_color: 'border-[#FFFFFF33]',
		color: 'text-[#BA68C8]',
		icon: TbSquareRotated
	},
	{
		title: 'Referrals',
		valueKey: 'referrals',
		border_color: 'border-[#3CBB5B]',
		color: 'text-white',
		icon: GoShareAndroid,
		referralsChange: 0
	},
	{
		title: 'Followers',
		valueKey: 'followers',
		border_color: 'border-[#3CBB5B]',
		color: 'text-white',
		icon: UserKingIcon,
		followersChange: 0
	},
	{
		title: 'Reviews',
		valueKey: 'reviews',
		border_color: 'border-[#FFFFFF33]',
		color: 'text-[#A0864F]',
		icon: GoStar,
		averageRating: 0
	}
]
const initialStatsValues = {
	totalTokens: 0,
	withdrawable: 0,
	unclaimedFor: 0,
	rewards: 0,
	staked: 0,
	availableGems: 0,
	referrals: 0,
	followers: 0,
	reviews: 0
}

const initialStatusSubValues = {
	referralsChange: 0,
	followersChange: 0,
	averageRating: 0
}

export function StatsCard({ className }) {
	const { user } = useSelector(state => state.signIn)
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const [values, setValues] = useState(initialStatsValues)
	const [subValues, setSubValues] = useState(initialStatusSubValues)
	const { stakeCompletedEvent } = useSelector(state => state.websocket)

	const afterFetchStats = (response, error) => {
		if (!error) {
			setValues({
				totalTokens: response?.wallet?.totalTokens,
				withdrawable: response?.wallet?.withdrawable,
				unclaimedFgr: response?.unclaimedFGR?.total,
				rewards: response?.wallet?.totalRewards,
				staked: response?.staked?.total,
				availableGems: response?.availableGems,
				referrals: response?.referrals?.totalJoins,
				followers: response?.followers?.currentCount,
				reviews: response?.reviews?.reviewCount
			})
			setSubValues({
				referralsChange: response?.referrals?.change,
				followersChange: response?.referrals?.change,
				averageRating: response?.reviews?.averageRating
			})
		}
		hideMask()
	}

	const getStats = () => {
		showMask()

		axiosService(getStatsService(), afterFetchStats)
	}

	useEffect(() => {
		getStats()
	}, [])

	useEffect(() => {
		if (stakeCompletedEvent) {
			getStats()
		}
	}, [stakeCompletedEvent])

	return (
		<div className={className}>
			{initialStats.map((stat, index) => {
				const value = values[stat.valueKey] || 0
				const formattedValue = formatValue(
					stat.valueKey,
					value,
					subValues,
					user?.username
				)
				return (
					<Card
						key={index}
						className={clsx(
							'custom-scrollbar relative flex flex-col items-center justify-center gap-1.5 rounded-lg border bg-card-background100 p-2',
							'aspect-video h-[82px] flex-1 flex-shrink-0 md:h-[110px]',
							stat.border_color
						)}
					>
						<CardHeader
							className={clsx(
								'flex flex-row items-center justify-center gap-1.5 p-0',
								'text-sm font-medium md:text-base md:font-bold',
								stat.color
							)}
						>
							<stat.icon className="h-4 w-4 text-sm max-[639px]:hidden md:!block" />

							<span className="!m-0 !p-0 text-sm font-bold leading-tight md:text-base">
								{stat.title}
							</span>
						</CardHeader>
						<CardContent className="p-0">
							<div
								align="center"
								className="flex items-center justify-center gap-[6px] p-0 text-sm leading-[14px] tracking-[-2.5%] text-white md:text-xl md:font-semibold"
							>
								{formattedValue}
							</div>
						</CardContent>
					</Card>
				)
			})}
		</div>
	)
}
