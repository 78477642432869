import { Button } from '@/components/ui/button'
import fanIconWhite from 'assets/images/fan-icon-white.svg'
import packageOpen from 'assets/images/package-open.svg'
import { useBecomeSuperFanDialog } from './useBecomeSuperFanDialog'
import { Check, Heart, LoaderCircle, TriangleAlert } from 'lucide-react'
import { cn } from '@/lib/utils'
import { CustomDialog } from '@/components/common-ui/CustomDialog'

export const BecomeSuperFanDialog = () => {
	const {
		open,
		isSuperfan,
		projectTitle,
		balanceEnough,
		stakeInProgress,
		stakeRequirement,
		isOnPerksProgram,
		followRequirement,
		showFanPurchaseDialog,
		handleBecomeSuperFan,
		handleClose
	} = useBecomeSuperFanDialog()

	return (
		<CustomDialog
			open={open}
			onOpenChange={handleClose}
			className="max-w-[455px]"
			title={`Become a Superfan of ${projectTitle}`}
		>
			<div className="flex flex-col gap-2 rounded-lg border border-[#2196F3] bg-black bg-opacity-25 p-3 sm:p-4">
				<h3 className="m-0 text-[14px] font-semibold text-white sm:text-[16px]">
					Superfan Requirements
				</h3>
				{balanceEnough ? (
					<div className="flex flex-col gap-3">
						{stakeRequirement?.completed && (
							<div className="flex items-center gap-2">
								<Heart className="w-4 sm:w-5" />
								<span className="text-xs sm:text-sm">
									Follow {projectTitle}
								</span>
								{!stakeInProgress ? (
									<Check
										className={cn('ml-auto w-4 sm:h-5 sm:w-5', {
											'text-green-500': followRequirement?.completed
										})}
									/>
								) : (
									<LoaderCircle className="ml-auto w-4 animate-spin sm:w-5" />
								)}
							</div>
						)}
						<div className="flex items-center gap-2">
							<img
								src={fanIconWhite.src}
								alt="fan icon"
								className="w-4 sm:w-5"
							/>
							<span className="text-xs sm:text-sm">
								Stake {stakeRequirement?.threshold} $FAN
							</span>
							{!stakeInProgress ? (
								<Check
									className={cn('ml-auto w-4 sm:h-5 sm:w-5', {
										'text-green-500': stakeRequirement?.completed,
										'opacity-50': !stakeRequirement?.completed
									})}
								/>
							) : (
								<LoaderCircle className="ml-auto w-4 animate-spin sm:w-5" />
							)}
						</div>
					</div>
				) : (
					<div className="flex flex-col gap-2">
						<div className="flex items-center">
							<span className="text-xs text-[#F2BE2F] sm:text-sm">
								Stake {stakeRequirement?.threshold} $FAN
							</span>
							<TriangleAlert
								className="ml-auto h-5 text-[#F2BE2F] sm:h-6"
								strokeWidth={1}
							/>
						</div>

						<span className="text-xs text-white sm:text-sm">
							Looks like you need more $FAN!
						</span>
						<div className="text-xs sm:text-sm">
							To top up,{' '}
							<span
								onClick={showFanPurchaseDialog}
								className="cursor-pointer bg-gradient-to-r from-[#21F359] from-[40%] to-[#6B3BFA] to-[60%] bg-clip-text font-bold text-transparent underline decoration-[#21F359] hover:underline"
							>
								Buy $FAN now
							</span>
							!
						</div>
					</div>
				)}
			</div>

			{isOnPerksProgram ? (
				<div className="flex flex-col gap-2 rounded-lg border border-[#8C421D] bg-black bg-opacity-25 p-3 sm:p-4">
					<h3 className="m-0 text-[14px] font-semibold text-white sm:text-[16px]">
						Exclusive Superfan Perks
					</h3>
					<div className="flex flex-col gap-2 sm:gap-3">
						<div className="flex items-center gap-2 text-xs sm:text-sm">
							<img src={packageOpen.src} alt="fan" className="w-5 sm:w-auto" />
							<p className="font-medium">
								Flimio Superfan Credit: your ticket to fame, get your name in
								the end credits!
							</p>
						</div>
						{!isSuperfan && (
							<div className="flex items-center gap-2 text-xs sm:text-sm">
								<img
									src={packageOpen.src}
									alt="fan icon"
									className="w-5 sm:w-auto"
								/>
								<p className="font-medium">
									First Time Superfan Bonus: 200 $FAN
								</p>
							</div>
						)}
					</div>
				</div>
			) : (
				<div className="flex flex-col gap-2 rounded-lg border border-[#33333] bg-black bg-opacity-25 p-3 sm:p-4">
					<h3 className="m-0 text-[14px] font-semibold text-white sm:text-[16px]">
						Exclusive Superfan Perks
					</h3>
					<div className="flex flex-col gap-2 sm:gap-3">
						<div className="flex items-center gap-2 text-xs sm:text-sm">
							<p className="font-medium">
								This project is not part of the perks program and will not
								reward a superfan film credit or other exciting perks. You can
								still earn increased FGR and a spot on the leaderboard.
							</p>
						</div>
					</div>
				</div>
			)}

			<Button
				disabled={stakeInProgress || !balanceEnough}
				onClick={handleBecomeSuperFan}
				className="m-auto h-auto w-[fit-content] bg-gradient-to-r from-purple-600 to-pink-600 px-4 py-3 text-sm text-white sm:px-6 sm:py-4 sm:text-base"
			>
				Make me a Superfan!
			</Button>
		</CustomDialog>
	)
}
