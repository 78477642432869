import { Icon, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import { ProjectItem } from '@/components/dashboard'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import {
	SnapList,
	SnapItem,
	useScroll,
	useDragToScroll,
	useVisibleElements
} from 'react-snaplist-carousel'
import styles from './ProjectCarousel.module.scss'

const ProjectCarousel = ({ items, status }) => {
	const snapList = useRef(null)

	const visible = useVisibleElements(
		{ debounce: 1, ref: snapList },
		([element]) => element
	)
	const goToSnapItem = useScroll({ ref: snapList })
	useDragToScroll({ ref: snapList, disabled: false })

	const handleNextClick = () => {
		goToSnapItem(visible + 2)
	}

	const handlePrevClick = () => {
		goToSnapItem(visible > 2 ? visible - 2 : 0)
	}

	const renderItems = () => {
		return items.map(item => {
			return (
				<>
					{item.stage.id === status && (
						<SnapItem key={item.id} className={styles.snapitem}>
							<ProjectItem
								{...{
									...item
								}}
							/>
						</SnapItem>
					)}
				</>
			)
		})
	}

	return (
		<Box className={styles.previews}>
			{visible > 0 && (
				<IconButton
					size="small"
					onClick={handlePrevClick}
					className={styles.prev}
				>
					<Icon baseClassName="fas" className="fa-chevron-left" />
				</IconButton>
			)}

			<SnapList ref={snapList} tabIndex={0}>
				{renderItems()}
			</SnapList>

			{visible < items?.length && (
				<IconButton
					size="small"
					onClick={handleNextClick}
					className={styles.next}
				>
					<Icon baseClassName="fas" className="fa-chevron-right" />
				</IconButton>
			)}
		</Box>
	)
}

ProjectCarousel.propTypes = {
	items: PropTypes.array,
	status: PropTypes.number
}

export default ProjectCarousel
