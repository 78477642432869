'use client'

import _ from 'lodash'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Icon, Popover, Typography } from '@mui/material'
import infoBackground from 'assets/images/dao-tiers/info_background.svg'
import { formatDate } from 'utils/date.utils'
import styles from './TierItem.module.scss'
import { TierDialog } from '../tierDialog/TierDialog'

const TierItem = ({
	id,
	date,
	image,
	title,
	completed,
	confirmed,
	benefits,
	metrics = {},
	userMetrics = {}
}) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const [open, setOpen] = useState(false)

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleTierClick = () => {
		setOpen(true)
	}

	const renderMetrics = () =>
		Object.keys(metrics).map((key, idx) => {
			const threshold = metrics[key]
			const value = userMetrics[key]
			const completed = value >= threshold
			let name = ''
			let label = ''
			let goal = ''
			switch (key) {
				case 'Is User Verified':
					name = 'Verified Account'
					break
				case 'Setup Platform Wallet':
					name = key
					break
				default:
					name = key
					label = key === 'Stake' ? 'FAN ' : 'projects '
					goal = threshold
					break
			}

			return (
				<Typography key={key} className={completed ? styles.completed : ''}>
					{idx + 1}. {name} {goal} {label}
					<span>
						{' '}
						({value}/{threshold})
					</span>
					{completed && <Icon baseClassName="fas" className="fa-check" />}
				</Typography>
			)
		})

	const name = _.capitalize(title)

	return (
		<>
			<Box
				className={`${styles.tier_item} ${
					(completed || confirmed) && styles.completed
				} ${confirmed && styles.confirmed}`}
			>
				<Icon baseClassName="fas" className="fa-check" />
				<Box>
					<img alt="" src={image} onClick={handleTierClick} />
				</Box>
				<Typography>{title}</Typography>
				<span onClick={handlePopoverOpen}>
					<Icon
						baseClassName="far"
						className={`fa-clock-desk ${styles.bottom_icon}`}
					/>
				</span>
			</Box>
			{open && (
				<TierDialog
					{...{
						id,
						open,
						name,
						metrics,
						benefits,
						userMetrics,
						handleClose
					}}
				/>
			)}
			<Popover
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handlePopoverClose}
				PaperProps={{
					className: `${styles.tier_detail} ${
						completed ? '' : styles.incompleted
					}`
				}}
			>
				<Box
					style={{ backgroundImage: `url(${infoBackground})` }}
					className={styles.container}
				>
					<Icon
						baseClassName="far"
						className={`fa-clock-desk ${styles.top_icon}`}
					/>
					<Box>
						<Typography component={'h2'}>{name} Rank</Typography>

						{confirmed ? (
							<>
								<Typography>You achieved {name} Rank</Typography>
								<Typography> on {formatDate(date, 'full')}</Typography>
								{/* <Button startIcon={<Icon baseClassName="fal" className="fa-share" />} disabled>
								Share This
							</Button> */}
							</>
						) : (
							<>
								<Typography component={'h3'}>
									To Earn {name} you need to
								</Typography>
								{renderMetrics()}
							</>
						)}
					</Box>
				</Box>
			</Popover>
		</>
	)
}

TierItem.propTypes = {
	id: PropTypes.number,
	image: PropTypes.string,
	title: PropTypes.string,
	date: PropTypes.string,
	benefits: PropTypes.array,
	metrics: PropTypes.object,
	userMetrics: PropTypes.object,
	completed: PropTypes.bool,
	confirmed: PropTypes.bool
}

export default TierItem
