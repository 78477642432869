import { LightTooltip } from '@/components/common'
import { Link } from 'react-router-dom'
import { Avatar, Box, Icon, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { formatDateToRelative } from 'utils/date.utils'
import styles from './StakeUserTimestamp.module.scss'
import { getTransactionLink } from 'utils/utils'
import { getFileUrl } from 'utils/url.util'
import { useSelector } from 'react-redux'

const StakeUserTimestamp = ({ createdAt, user, project, blockchainHash }) => {
	const { isAuthenticated } = useSelector(state => state.signIn)
	return (
		<Box className={styles.profile_link}>
			<LightTooltip title={`View ${user?.displayName}'s Profile`}>
				<Link to={`/profile/${user?.username}`}>
					<Avatar
						alt={user?.displayName}
						sx={{
							bgcolor: '#000000',
							border: '1px solid #ffffff',
							height: 35,
							width: 35,
							marginRight: '12px'
						}}
						src={getFileUrl(user?.avatar)}
					/>
				</Link>
			</LightTooltip>
			<Box className={styles.info_container}>
				<LightTooltip title={`View ${user?.displayName}'s Profile`}>
					<Link to={`/profile/${user?.username}`}>
						<Box className={styles.display_name}>
							<Typography>{user?.displayName}</Typography>
							{user?.isVerified === 1 && (
								<Icon baseClassName="fas" className="fa-badge-check" />
							)}
						</Box>
					</Link>
				</LightTooltip>
				<Typography className={styles.profession}>
					<span className={styles.hash}>
						{isAuthenticated ? (
							getTransactionLink(blockchainHash)
						) : (
							<a>{blockchainHash}</a>
						)}
					</span>{' '}
					{project && (
						<>
							{' '}
							Staked on{' '}
							<Link to={`/project/${project?.slug}`}>{project?.title}</Link>
						</>
					)}{' '}
					{formatDateToRelative(createdAt)}
				</Typography>
			</Box>
		</Box>
	)
}

StakeUserTimestamp.propTypes = {
	createdAt: PropTypes.string,
	project: PropTypes.object,
	blockchainHash: PropTypes.string,
	user: PropTypes.object
}

export default StakeUserTimestamp
