import { LuBadgeCheck } from 'react-icons/lu'
import { Button } from '@/components/ui/button'
import { Link } from 'react-router-dom'
interface CreatorInfoProps {
	displayName: string
	isVerified: boolean
	username: string
}

export const ProjectCreatorInfo = ({
	displayName,
	isVerified,
	username
}: CreatorInfoProps) => (
	<div className="flex items-center gap-x-2">
		<span className="text-sm font-medium text-gray-300">
			<Link to={`/profile/${username}`}>{displayName}</Link>
		</span>
		{isVerified && (
			<Button size="icon" className="h-5 w-5">
				<LuBadgeCheck width={14} height={14} />
			</Button>
		)}
	</div>
)
