import { useQueryClient } from '@tanstack/react-query'
import { Project } from './useProjectPage'
import { queryKeys } from '@/lib/queryKeys'
import { useParams } from 'react-router-dom'

const useProjectCommon = () => {
	const { slug } = useParams()
	const queryClient = useQueryClient()
	const project: Project | undefined = queryClient.getQueryData(
		queryKeys.project.detail(slug)
	)
	if (!project) return { project: null, superfansLeaderboard: null }

	const superfansLeaderboard: any[] | undefined = queryClient.getQueryData(
		queryKeys.project.superfansLeaderboard(project?.id)
	)

	const reloadLeaderboard = () => {
		queryClient.invalidateQueries({
			queryKey: queryKeys.project.superfansLeaderboard(project?.id)
		})
	}

	const reloadProject = () => {
		queryClient.invalidateQueries({
			queryKey: queryKeys.project.detail(slug)
		})
	}

	return { project, superfansLeaderboard, reloadLeaderboard, reloadProject }
}

export default useProjectCommon
