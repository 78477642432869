import { useQuery } from '@tanstack/react-query'

import axios from 'axios'
import Image from 'next/image'

import { getProjectBadgesService } from 'services/goscore.service'
import badgeLocked from 'assets/images/badge-locked.png'
import { ArrowLeftIcon, ArrowRightIcon } from 'lucide-react'
import {
	ProjectBadge,
	ProjectBadgeEmpty,
	ProjectBadgeGreen
} from './ProjectBadgeIcons'
import { Button } from '../ui/button'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger
} from '../ui/tooltip'
import { useRef } from 'react'

/**
 * Interface for the badge data structure returned from the API
 */
interface Badge {
	id: number
	name: string
	image: string
	description: string
	reward: number
	badgeOrder: number
	active: number
	threshold: number
	metricId: number | null
	createdAt: string
	updatedAt: string
	percent: number
	completed: boolean
}

/**
 * Fetches project badges from the API
 */
const fetchProjectBadges = async (projectId: string): Promise<Badge[]> => {
	const { data } = await axios(getProjectBadgesService(projectId))
	return data
}

interface Props {
	projectId: string
	projectCreationDate: string
}

/**
 * Component that displays a horizontal scrollable list of project badges
 */
export const AwardsAndBadges = ({ projectId, projectCreationDate }: Props) => {
	const {
		data: badges = [],
		isLoading,
		error
	} = useQuery({
		queryKey: ['projectBadges', projectId],
		queryFn: () => fetchProjectBadges(projectId),
		enabled: Boolean(projectId)
	})

	const scrollContainerRef = useRef<HTMLDivElement>(null)

	const scroll = (direction: 'left' | 'right') => {
		const scrollContainer = scrollContainerRef.current
		if (scrollContainer) {
			const scrollAmount = scrollContainer.clientWidth * 0.8
			scrollContainer.scrollBy({
				left: direction === 'left' ? -scrollAmount : scrollAmount,
				behavior: 'smooth'
			})
		}
	}

	if (error) {
		return (
			<div className="w-full">
				<div className="text-white">Error fetching badges</div>
			</div>
		)
	}

	/**
	 * Filters and renders badge items
	 * - Sorts badges by badgeOrder
	 * - Excludes Airdrop 2023 badge for projects created after Nov 30, 2023 12pm
	 * - Applies locked/unlocked badge images based on completion status
	 */
	const renderItems = () => {
		const AIRDROP_CUTOFF_DATE = new Date('2023-11-30T12:00:00')

		return badges
			.sort((a, b) => a.badgeOrder - b.badgeOrder)
			.filter(
				badge =>
					!(
						badge?.name === 'Airdrop 2023' &&
						new Date(projectCreationDate) > AIRDROP_CUTOFF_DATE
					)
			)
			.map(badge => {
				const image = badge.completed ? badge.image : badgeLocked.src

				return (
					<Tooltip key={badge.id} delayDuration={60}>
						<TooltipTrigger asChild>
							<div className="relative flex-shrink-0">
								{badge.completed ? (
									<ProjectBadgeGreen className="h-12 w-12" />
								) : (
									<ProjectBadge className="h-12 w-12" />
								)}
								<Image
									className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
									src={image}
									alt={badge.name}
									width={20}
									height={20}
								/>
							</div>
						</TooltipTrigger>
						<TooltipContent side="top">{badge.name}</TooltipContent>
					</Tooltip>
				)
			})
	}

	return (
		<TooltipProvider>
			<div className="w-full space-y-2">
				<div className="flex items-center justify-between space-x-8">
					<h3 className="m-0 text-base font-semibold leading-none text-white md:text-lg">
						Awards and Badges
					</h3>

					<div className="flex items-center gap-4">
						<Button
							variant="ghost"
							className="text-primary"
							onClick={() => scroll('left')}
						>
							<ArrowLeftIcon className="mr-1 h-4 w-4" />
						</Button>

						<div className="hidden text-xs xl:block">
							Scroll left and right for more Badges
						</div>

						<Button
							variant="ghost"
							className="text-primary"
							onClick={() => scroll('right')}
						>
							<ArrowRightIcon className="ml-1 h-4 w-4" />
						</Button>
					</div>
				</div>

				<div className="relative">
					<div
						className="custom-scrollbar flex gap-1 overflow-x-auto"
						ref={scrollContainerRef}
					>
						{isLoading
							? [1, 2, 3, 4, 5, 6, 7, 8].map(i => (
									<div className="flex-shrink-0" key={i}>
										<ProjectBadgeEmpty />
									</div>
								))
							: renderItems()}
					</div>
					<div className="pointer-events-none absolute bottom-0 right-0 top-0 h-full w-20 bg-gradient-to-l from-background from-0% via-45% to-transparent to-100% sm:w-32 md:w-40 lg:w-80" />
				</div>
			</div>
		</TooltipProvider>
	)
}
