import { useDispatch } from 'react-redux'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { getActiveContests } from 'services/contest.service'
import { setActiveContests } from '@/components/header/signInSlice'
import { Box, Button, Stack, Typography } from '@mui/material'
import { ActiveContestBadge } from './ActiveContestBadge'
import useSidebarDashboard, {
	CONTEST_PREFIX
} from '../sidebarDashboard/useSidebarDashboard'
import styles from './ActiveContestBanner.module.scss'

export const ActiveContestBanner = ({ contest }) => {
	const { handleFilterChange } = useSidebarDashboard()
	const dispatch = useDispatch()

	useAxiosFetch(getActiveContests(), (data, error) => {
		if (!error) {
			dispatch(setActiveContests(data))
		}
	})

	const contestName = contest?.name
	const badge = contest?.badge
	return (
		<Box className={styles.contest_badge}>
			<Stack className={styles.stack}>
				<Typography fontWeight={600} fontSize={16}>
					{contestName} is Live!
				</Typography>
				<Button
					onClick={handleFilterChange(
						'types',
						`${CONTEST_PREFIX}${contest.id}`
					)}
				>
					View Projects
					<ActiveContestBadge src={badge} />
				</Button>
			</Stack>
		</Box>
	)
}
