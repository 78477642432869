import { createSlice } from '@reduxjs/toolkit'
import {
	getNumberFromLocalStorage,
	getOneObjectFromLocalStorage
} from 'utils/utils'

export interface WebSocketMessage {
	userId: string
}

export interface SuperfanPointsEarnedEvent extends WebSocketMessage {
	title: string | null
	metricId: number
	points: number
	amount: number
	id: string | null
	isSuperfan: boolean
	projectId: string | null
	isIncrease: boolean
}

interface StakeCompletedMessage extends WebSocketMessage {
	blockchainHash: string | null
	projectId: string | null
	transactionId: string | null
	amount: number
}
interface SuperfanUpdatedMessage extends WebSocketMessage {
	projectId: string | null
}

interface WebsocketInitialState {
	fanPurchaseTransaction: {
		id: string | null
		awardedFanAmount: number
		amount: number
		message: string | null
		progress: number
		step: number
		token: string | null
		network: string | null
		loadingBuyFan: boolean
	}
	transactionStatus: number
	superfanUpdatedEvent: SuperfanUpdatedMessage
	superfanPointsEarnedEvent: SuperfanPointsEarnedEvent
	stakeCompletedEvent: StakeCompletedMessage
	stakeUnstakedEvent: StakeCompletedMessage
	newSuperfanEvent: {
		projectId: string | null
		isOnPerksProgram: boolean
	}
}

const initialState: WebsocketInitialState = {
	fanPurchaseTransaction: {
		id: null,
		awardedFanAmount: Number(
			getNumberFromLocalStorage('awardedFanAmount') ?? 0
		),
		amount: Number(getNumberFromLocalStorage('amount') ?? 0),
		message: null as string | null,
		progress: 0,
		step: 0,
		token: getOneObjectFromLocalStorage('token'),
		network: getOneObjectFromLocalStorage('network'),
		loadingBuyFan: localStorage.getItem('loadingBuyFan') === 'true'
	},
	transactionStatus: 0,
	superfanUpdatedEvent: {
		projectId: null,
		userId: ''
	},
	stakeCompletedEvent: {
		blockchainHash: null,
		projectId: null,
		transactionId: null,
		amount: 0,
		userId: ''
	},
	stakeUnstakedEvent: {
		blockchainHash: null,
		projectId: null,
		transactionId: null,
		amount: 0,
		userId: ''
	},
	superfanPointsEarnedEvent: {
		userId: '',
		title: null,
		metricId: 0,
		points: 0,
		amount: 0,
		id: null,
		isSuperfan: false,
		projectId: '',
		isIncrease: false
	},
	newSuperfanEvent: {
		projectId: null,
		isOnPerksProgram: false
	}
}

const websocketSlice = createSlice({
	name: 'websocket',
	initialState,
	reducers: {
		resetFanPurchaseTransaction: state => {
			const network = state.fanPurchaseTransaction.network
			const awardedFanAmount = +(
				state.fanPurchaseTransaction?.awardedFanAmount ?? 0
			)
			state.fanPurchaseTransaction = {
				...initialState.fanPurchaseTransaction,
				loadingBuyFan: false,
				amount: 0,
				awardedFanAmount,
				token: null,
				network
			}
		},
		setFanPurchaseTransaction: (state, { payload }) => {
			state.fanPurchaseTransaction = payload
		},
		updateFanPurchaseTransaction: (state, { payload }) => {
			state.fanPurchaseTransaction = {
				...state.fanPurchaseTransaction,
				...payload
			}
		},
		setFpPaymentCompleted: (state, { payload }) => {
			state.fanPurchaseTransaction = {
				...payload,
				network: state.fanPurchaseTransaction.network
			}
			state.fanPurchaseTransaction.progress = 100
			state.fanPurchaseTransaction.message = `Congrats! Your ${payload?.amount} $FAN Tokens are ready for use!`
		},
		setTransactionStatus: (state, { payload }) => {
			state.transactionStatus = payload
		},
		setSuperfanUpdatedEvent: (state, { payload }) => {
			state.superfanUpdatedEvent = payload
		},
		setStakeCompletedEvent: (state, { payload }) => {
			state.stakeCompletedEvent = payload
		},
		setNewSuperfanEvent: (state, { payload }) => {
			state.newSuperfanEvent = payload
		},
		setSuperfanPointsEarnedEvent: (state, { payload }) => {
			state.superfanPointsEarnedEvent = payload
		},
		setStakeUnstakedEvent: (state, { payload }) => {
			state.stakeUnstakedEvent = payload
		}
	}
})

export const {
	setNewSuperfanEvent,
	setStakeUnstakedEvent,
	setStakeCompletedEvent,
	setSuperfanUpdatedEvent,
	setFanPurchaseTransaction,
	resetFanPurchaseTransaction,
	setSuperfanPointsEarnedEvent,
	updateFanPurchaseTransaction,
	setFpPaymentCompleted,
	setTransactionStatus
} = websocketSlice.actions

export default websocketSlice.reducer
