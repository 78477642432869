import {
	Box,
	Button,
	Dialog,
	DialogContent,
	Grid,
	Typography
} from '@mui/material'
import PropTypes from 'prop-types'
import styles from './DetailsDialog.module.scss'
import { styled } from '@mui/material/styles'
import { formatAddress } from 'utils/utils'

const DialogWithbackground = styled(Dialog)(({ theme }) => ({
	background: '#0000001a',
	padding: '20px 18px',
	...theme.typography.body2
}))

export const DetailsDialog = ({ open, handleClose, data }) => {
	const handleCloseDialog = () => {
		handleClose()
	}
	const hash = data?.fanPurchaseDetails?.paymentBlockchainHash
	const amountOfTokensPaid = data?.fanPurchaseDetails?.purchaseAmount
	const tokenName = data?.fanPurchaseDetails?.token?.name
	const tokenPrice = data?.fanPurchaseDetails?.tokenPrice
	const awardedFanAmount = data?.fanPurchaseDetails?.awardedFanAmount
	const fanPrice =
		((amountOfTokensPaid ?? 0) * (tokenPrice ?? 0)) / (awardedFanAmount ?? 1)
	const paymentTransactionHash = hash ? formatAddress(hash) : '\u00A0'
	const transactionStatus = data?.status?.name
	const buyingWallet = formatAddress(data?.fanPurchaseDetails?.buyingWallet)

	return (
		<DialogWithbackground
			open={open}
			disableScrollLock={true}
			className={`${styles.detailsBox}`}
			modal="true"
			classes={{
				paper: styles.dialogPaper,
				container: styles.dialogContainer
			}}
			onClose={handleCloseDialog}
		>
			<DialogContent className={styles.dialogContent}>
				<Box className={styles.Header}>
					<Typography component={'h2'}>Details</Typography>
				</Box>
				<Box className={styles.contentBox}>
					<Grid container spacing={2}>
						<Grid item xs={8}>
							<ul className={styles.listOne}>
								<li>Amount of Tokens Paid</li>
								<li>Token Name</li>
								<li>Token Price</li>
								<li>Awarded Fan Amount</li>
								<li>Fan Price</li>
								<li>Payment transaction hash</li>
								<li>Transaction status</li>
								<li>Buying Wallet</li>
							</ul>
						</Grid>
						<Grid item xs={4}>
							<ul className={styles.listTwo}>
								<li> {amountOfTokensPaid}</li>
								<li> {tokenName}</li>
								<li>{tokenPrice}</li>
								<li> {awardedFanAmount}</li>
								<li>{fanPrice.toFixed(4)}</li>
								<li> {paymentTransactionHash}</li>
								<li> {transactionStatus}</li>
								<li> {buyingWallet}</li>
							</ul>
						</Grid>
					</Grid>
				</Box>
				<Box className={styles.closeButton}>
					<Button onClick={handleClose}>Close</Button>
				</Box>
			</DialogContent>
		</DialogWithbackground>
	)
}

DetailsDialog.propTypes = {
	open: PropTypes.bool,
	data: PropTypes.object,
	handleClose: PropTypes.func
}
