import { GoScoreV2 } from '@/components/common-ui/goScoreV2'
import { Button } from '@/components/ui/button'
import { getFileUrl } from '@/utils/url.util'
import React from 'react'
import {
	openBecomeSuperfanDialog,
	setSuperfanWelcomeDialogOpen,
	setYouAreSuperfanProjectDialogData
} from '../signInSlice'
import { useDispatch } from 'react-redux'
import { CheckIcon } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from '@/components/ui/skeleton'

export interface ProjectsCarouselItemProps {
	id: string
	poster: string
	title: string
	isUserFan: boolean
	slug: string
	goscore?: {
		rank: number
	}
	isOnPerksProgram: boolean
	isLoading?: boolean
}

export const ProjectCarouselItemSkeleton = () => {
	return (
		<div className="ml-8 flex w-[136px] max-w-[136px] flex-col gap-3">
			<Skeleton className="h-[188px] w-[136px]" />
			<div className="flex items-center justify-between">
				<Skeleton className="h-7 w-24" />
				<Skeleton className="h-[32px] w-[32px] rounded-full" />
			</div>
			<Skeleton className="h-9 w-full" />
		</div>
	)
}

export const ProjectsCarouselItem = (project: ProjectsCarouselItemProps) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const handleBecomeSuperfan = () => {
		dispatch(
			openBecomeSuperfanDialog({
				projectTitle: project.title,
				projectId: project.id,
				isOnPerksProgram: project.isOnPerksProgram
			})
		)
		dispatch(setSuperfanWelcomeDialogOpen(false))
		dispatch(setYouAreSuperfanProjectDialogData({ open: false }))
		navigate(`/project/${project.slug}`)
	}

	if (project.isLoading) {
		return <ProjectCarouselItemSkeleton />
	}

	return (
		<div
			key={project?.id}
			className="ml-8 flex w-[136px] max-w-[136px] flex-col gap-3"
		>
			<img
				src={getFileUrl(project.poster)}
				className="h-[188px] w-[136px] rounded-lg bg-black"
				alt={project.title}
			/>
			<div className="flex items-center justify-between">
				<span
					className="truncate text-base font-bold leading-7 text-white"
					title={project.title}
				>
					{project.title}
				</span>
				<div className="h-[32px] w-[32px] [&_div]:max-h-[32px] [&_div]:max-w-[32px]">
					<GoScoreV2
						color="#2196F3"
						showText={false}
						goscore={project?.goscore}
					/>
				</div>
			</div>
			<Button
				onClick={handleBecomeSuperfan}
				disabled={project?.isUserFan}
				className="flex h-9 w-full items-center justify-center gap-2 rounded-md bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] px-3 py-2 text-white"
			>
				Superfan Me!
				{project?.isUserFan && <CheckIcon className="h-5 w-5 text-white" />}
			</Button>
		</div>
	)
}
