import axios from 'axios'
import magic, {
	ethersInstance,
	contractsConfig,
	jsonRpcProvider,
	defaultNetwork
} from './config/constants'
import { getNetworkFeesData, waitForTransaction } from './config/utils'
import { ethers } from 'ethers'
import _ from 'lodash'
import { retryWithDelay } from 'utils/utils'
import { logger } from '../logger'

export const prepareContractTransaction = async (
	contractName,
	functionName,
	functionArgs,
	idNote,
	network = defaultNetwork
) => {
	const contract = new ethers.Contract(
		contractsConfig[contractName].address[network],
		contractsConfig[contractName].abi,
		jsonRpcProvider(network)
	)

	const rawTransaction = await contract.populateTransaction[functionName](
		...functionArgs
	)
	const hexNote = ethers.utils.hexlify(
		ethers.utils.toUtf8Bytes(idNote ?? 'No Note Provided!')
	)

	let txData = rawTransaction.data
	if (idNote) {
		txData += hexNote.substring(2)
	}
	const transaction = {
		to: contractsConfig[contractName].address[network],
		data: txData
	}

	return transaction
}

export const prepareRawTransaction = async (to, value, idNote) => {
	const rawTransaction = {
		to,
		value
	}

	const hexNote = ethers.utils.hexlify(
		ethers.utils.toUtf8Bytes(idNote ?? 'No Note Provided!')
	)

	const txData = idNote ? hexNote.substring(2) : ''

	const transaction = {
		...rawTransaction,
		data: txData
	}

	return transaction
}

const getHashByRequestId = requestId => async () => {
	const url = `https://gas-api.magic.link/v1/relayer/get-request-state?request_id=${requestId}`
	const response = await axios.get(url)
	const hash = response?.data?.tx_hash
	const state = response?.data?.state
	if (state === 'FAILED') return Promise.resolve(null)
	return _.isNil(hash)
		? Promise.reject(new Error('Hash empty'))
		: Promise.resolve(hash)
}

export const sendContractBlockchainTransaction = async (
	contractName,
	functionName,
	functionArgs,
	idNote,
	signer,
	network = defaultNetwork
) => {
	try {
		const transaction = await prepareContractTransaction(
			contractName,
			functionName,
			functionArgs,
			idNote,
			network
		)

		if (_.isNil(signer)) {
			const userWalletAddress = await getMagicWalletAddress()
			const gaslessRequest = await magic.wallet.sendGaslessTransaction(
				userWalletAddress,
				transaction
			)
			return await retryWithDelay(getHashByRequestId(gaslessRequest.request_id))
		} else {
			if (signer?.chain?.name !== network) {
				throw new Error('Please connect to the right network!')
			}
			const feeData =
				network === defaultNetwork ? await getNetworkFeesData() : {}
			const hash = await signer.sendTransaction({
				...transaction,
				...feeData
			})
			await waitForTransaction(hash, network)
			return hash
		}
	} catch (error) {
		logger.error({ error })
		if (
			error?.cause?.code === -32603 &&
			error?.cause?.details === 'Internal JSON-RPC error.' &&
			error?.cause?.name === 'InternalRpcError'
		) {
			throw new Error('Gas Price Error!')
		}
		return null
	}
}

export const sendRawBlockchainTransaction = async (
	to,
	value,
	idNote,
	signer,
	network = defaultNetwork
) => {
	try {
		const transaction = await prepareRawTransaction(to, value, idNote)

		if (_.isNil(signer)) {
			throw new Error('Please connect your wallet first!')
		} else if (signer?.chain?.name !== network) {
			throw new Error('Please connect to the right network!')
		} else {
			const feeData =
				network === defaultNetwork ? await getNetworkFeesData() : {}
			const hash = await signer.sendTransaction({
				...transaction,
				...feeData
			})
			await waitForTransaction(hash, network)
			return hash
		}
	} catch (error) {
		logger.error({ error })
		if (
			error?.cause?.code === -32603 &&
			error?.cause?.details === 'Internal JSON-RPC error.' &&
			error?.cause?.name === 'InternalRpcError'
		) {
			throw new Error('Gas Price Error!')
		}
		return null
	}
}

export const getContractValue = async (
	contractName,
	functionName,
	functionArgs,
	network = defaultNetwork
) => {
	const contract = new ethers.Contract(
		contractsConfig[contractName].address[network],
		contractsConfig[contractName].abi,
		jsonRpcProvider(network)
	)

	const value = await contract[functionName](...functionArgs)
	return value
}

export const getMagicWalletAddress = async () => {
	const metadata = await magic?.user?.getInfo()
	return metadata?.publicAddress
}

export const signMagicVerificationMessage = async () => {
	if (!ethersInstance || !magic) {
		throw new Error('Magic is not initialized')
	}

	const signer = ethersInstance.getSigner()

	const message = 'filmio-verification-message'
	const hash = ethers.utils.solidityKeccak256(['string'], [message])
	const signature = await signer.signMessage(ethers.utils.arrayify(hash))

	return {
		message,
		signature
	}
}
