import { Box, Button, Typography } from '@mui/material'
import { CustomDialog } from '@/components/common'
import { useDispatch, useSelector } from 'react-redux'
import {
	setPhaseNoStartedDialogOpen,
	setValidateIpDialogOpen
} from '../tokenSaleExperienceSlice'
import { CustomCountdown } from '@/components/common/customCountdown'
import styles from './PhaseNoStartedDialog.module.scss'
import useHeader from '@/components/header/useHeader'

export const PhaseNoStartedDialog = () => {
	const { phaseNoStartedDialogOpen, campaign } = useSelector(
		state => state.tokenSaleExperience
	)
	const dispatch = useDispatch()
	const { handleLogout } = useHeader()

	const handleClose = () => {
		dispatch(setPhaseNoStartedDialogOpen(false))
		handleLogout()
	}

	const afterComplete = () => {
		handleClose()
		dispatch(setValidateIpDialogOpen(true))
	}

	return (
		<CustomDialog {...{ open: phaseNoStartedDialogOpen, handleClose }}>
			<Typography className={styles.title}>
				<strong>Your phase of the pre-sale hasn’t started yet!</strong>
			</Typography>
			<Typography className={styles.subTitle}>
				<span>Please come back when it’s closer to your start time.</span>
			</Typography>

			{campaign && (
				<CustomCountdown
					completeComponent={afterComplete}
					date={campaign?.startDate}
					message={'Your Phase Begins in'}
				/>
			)}
			<Box className={styles.footer}>
				<Button onClick={handleLogout}>Logout</Button>
			</Box>
		</CustomDialog>
	)
}
