import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ModalState {
	perkDialog: {
		open: boolean
		image: string
	}
	welcomeReferralDialog: {
		open: boolean
		projectTitle: string
		projectId: string
		projectSlug: string
		sharerDisplayName: string
		isOnPerksProgram: boolean
	}
}

const initialState: ModalState = {
	perkDialog: {
		open: false,
		image: ''
	},
	welcomeReferralDialog: {
		open: false,
		projectTitle: '',
		projectId: '',
		projectSlug: '',
		sharerDisplayName: '',
		isOnPerksProgram: false
	}
}

const modalSlice = createSlice({
	name: 'modal',
	initialState,
	reducers: {
		openPerkDialog: (state, action: PayloadAction<{ image: string }>) => {
			state.perkDialog.open = true
			state.perkDialog.image = action.payload.image
		},
		closePerkDialog: state => {
			state.perkDialog.open = false
			state.perkDialog.image = ''
		},
		openWelcomeReferralDialog: (
			state,
			action: PayloadAction<{
				projectTitle: string
				projectId: string
				projectSlug: string
				sharerDisplayName: string
				isOnPerksProgram: boolean
			}>
		) => {
			state.welcomeReferralDialog.open = true
			state.welcomeReferralDialog.projectTitle = action.payload.projectTitle
			state.welcomeReferralDialog.projectId = action.payload.projectId
			state.welcomeReferralDialog.projectSlug = action.payload.projectSlug
			state.welcomeReferralDialog.sharerDisplayName =
				action.payload.sharerDisplayName
			state.welcomeReferralDialog.isOnPerksProgram =
				action.payload.isOnPerksProgram
		},
		closeWelcomeReferralDialog: state => {
			state.welcomeReferralDialog = initialState.welcomeReferralDialog
		}
	}
})

export const {
	openPerkDialog,
	closePerkDialog,
	openWelcomeReferralDialog,
	closeWelcomeReferralDialog
} = modalSlice.actions

export const selectPerkDialog = (state: { modal: ModalState }) =>
	state.modal.perkDialog

export default modalSlice.reducer
