import _ from 'lodash'
import axios from 'axios'
import { notificationTypes } from '@/components/common'
import useLoadingMask from 'hooks/useLoadingMask'
import useNotification from 'hooks/useNotification'
import { useNavigate } from 'react-router-dom'

const useAxios = () => {
	const { showNotification } = useNotification()
	const { hideMask } = useLoadingMask()
	const navigate = useNavigate()

	const handleError = (error) => {
		if (error?.response?.status === 404) {
			navigate('/notfound')
			hideMask()
		}

		const message = _.get(error, 'response.data.message', null)
		if (message) {
			showNotification({
				message,
				title: _.get(error, 'response.data.title', null),
				type: notificationTypes.ERROR
			})
		}
	}

	const handleErrorResponse = (response) => {
		const message = _.get(response, 'data.message', null)
		if (message) {
			showNotification({
				message,
				title: _.get(response, 'data.title', null),
				type: notificationTypes.ERROR
			})
		}
		hideMask()
	}

	const axiosService = (config, callback, stopRequest = false) => {
		if (stopRequest) {
			hideMask()
			return
		}
		axios(config)
			.then(response => {
				if (response.status === 200) {
					callback && callback(response.data, false)
				} else {
					handleErrorResponse(response)
				}
			})
			.catch(error => {
				callback && callback(null, error)
				handleError(error)
			})
	}

	const axiosServiceSync = async (config, stopRequest = false) => {
		if (stopRequest) {
			hideMask()
			return
		}
		try {
			const response = await axios(config)

			if (response.status === 200) {
				return response.data
			} else {
				handleErrorResponse(response)
			}
		} catch (error) {
			handleError(error)
		}
	}

	return { axiosService, axiosServiceSync }
}

export default useAxios
