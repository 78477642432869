import React, { useEffect } from 'react'
import useAxios from '@/hooks/useAxios'
import { getProjectSuperfanLeaderboard } from '@/services/userFanLevel.service'
import { cn } from '@/lib/utils'
import { AvatarFilmio } from '@/components/common-ui/AvatarFilmio'
import { useInView } from 'react-intersection-observer'
import { useInfiniteQuery } from '@tanstack/react-query'
import { LoaderCircle } from 'lucide-react'
import { queryKeys } from '@/lib/queryKeys'
import useProjectCommon from '../../useProjectCommon'
import medal from '@/assets/images/medal-2.svg'

const liDefaultStyle =
	'font-inter text-base font-bold leading-[28px] text-white'
const highlightStyle = 'text-[#2196F3]'

const ListItem = ({ user, highlight }: { user: any; highlight: boolean }) => {
	if (!user) return null
	const liStyle = cn(liDefaultStyle, {
		[highlightStyle]: highlight
	})
	const displayName = highlight ? 'You' : user.displayName
	return (
		<li
			key={user.username}
			className="flex items-center justify-between border-b border-[#2196F380] p-2"
		>
			<div className="flex items-center gap-2">
				<span className={cn(liStyle, 'min-w-[2.5rem] pr-2 tabular-nums')}>
					#{user.rank}
				</span>
				<AvatarFilmio
					ringImage={user.ringImage}
					avatar={user.avatar}
					displayName={displayName}
					className="h-[35px] w-[35px]"
				/>
				<span className={liStyle}>{displayName}</span>
			</div>
			<div className="flex items-center gap-1">
				<span className={liStyle}>{user.totalPoints}</span>
				<img src={medal?.src} alt="" className="z-7 ml-1 h-7" />
			</div>
		</li>
	)
}

export const SuperfanLeaderboardList = ({
	projectId
}: {
	projectId: string
}) => {
	const { ref, inView } = useInView()
	const { axiosServiceSync } = useAxios()
	const { project } = useProjectCommon()
	const userStats = project?.superfanStats

	const { data, fetchNextPage, hasNextPage, isFetchingNextPage } =
		useInfiniteQuery({
			queryKey: queryKeys.project.superfansLeaderboard(projectId),
			queryFn: async ({ pageParam = 1 }) => {
				const response = await axiosServiceSync(
					getProjectSuperfanLeaderboard({
						projectId,
						page: pageParam,
						limit: 10
					})
				)
				return response
			},
			getNextPageParam: lastPage => lastPage.pageInfo.nextPage,
			initialPageParam: 1,
			enabled: !!projectId
		})

	useEffect(() => {
		const timer = setTimeout(() => {
			if (inView && hasNextPage && !isFetchingNextPage) {
				fetchNextPage()
			}
		}, 100)

		return () => clearTimeout(timer)
	}, [inView, hasNextPage, isFetchingNextPage, fetchNextPage])

	const userInList = data?.pages.some(page =>
		page.rows.some((user: any) => user.username === userStats?.username)
	)

	return (
		<div className="flex flex-col py-4 px-4 gap-2">
			<span className="font-inter text-[18px] font-bold leading-[14px] mb-2 text-white">
				Top Superfans
			</span>
			<ul className="flex max-h-[180px] custom-scrollbar flex-col overflow-y-auto">
				{!userInList && <ListItem {...{ user: userStats, highlight: true }} />}
				{data?.pages.map(page =>
					page.rows.map((user: any) => (
						<ListItem
							key={user.username}
							{...{ user, highlight: user.username === userStats?.username }}
						/>
					))
				)}
				{/* Loading trigger */}
				<div ref={ref} className="h-4">
					{isFetchingNextPage && (
						<div className="flex justify-center py-2">
							<LoaderCircle className="h-6 w-6 animate-spin" />
						</div>
					)}
				</div>
			</ul>
		</div>
	)
}
