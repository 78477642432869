import { useDispatch } from 'react-redux'
import { Box, Button, Icon, Typography } from '@mui/material'
import { CustomButton, LightTooltip } from '@/components/common'
import { WhitelistWalletDialog } from '../../whitelistWalletDialog'
import { WhitelistTransferDialog } from '../../whitelistTransferDialog'
import { updateWhitelistWalletDialogData } from '@/components/profile/profileSlice'
import infoIcon from 'assets/images/walletIcons/info.svg'
import styles from './WhiteListWallet.module.scss'

export const WhiteListWallet = ({
	isWhitelisted,
	isNonCustodial,
	whitelistAddress,
	handleRemoveWallet,
	handleOpenWhitelistTransfer,
	whitelistTransferDialogOpen,
	handleOpen,
	isRestrictedHodl
}) => {
	const dispatch = useDispatch()

	const handleOpenWhitelistWalletDialog = () => {
		dispatch(updateWhitelistWalletDialogData({ open: true }))
	}

	return (
		<Box className={styles.whitelisted_wallet}>
			{isWhitelisted ? (
				<Box className={styles.content}>
					<Box className={styles.top}>
						<Box className={styles.wallet_info}></Box>
						<Box className={styles.flex_center}>
							<Typography component={'h2'} className={styles.text}>
								{!isNonCustodial ? 'Whitelist Wallet' : 'Linked Wallet'}
								<LightTooltip
									enterTouchDelay={0}
									leaveTouchDelay={4000}
									placement="bottom"
									title={
										!isNonCustodial
											? 'This allows you to whitelist an external wallet and transfer tokens.'
											: 'Your platform account has been linked to this external wallet address.'
									}
								>
									<span className={styles.help_icon} aria-haspopup="true">
										<img alt="info Icon" src={infoIcon.src} />
									</span>
								</LightTooltip>
							</Typography>
							<Box className={styles.white_address_container}>
								<span className={styles.white_address}>
									<Icon baseClassName="fal" className="fa-wallet" />{' '}
									{whitelistAddress}
								</span>
								{!isNonCustodial && (
									<Button
										className={styles.remove}
										onClick={handleRemoveWallet}
										disabled={isNonCustodial}
									>
										<Icon baseClassName="fas" className="fa-times" />
									</Button>
								)}
							</Box>
						</Box>
						<Box className={styles.buttons}>
							<Typography>Transfer</Typography>
							<Button className={styles.transferIn} onClick={handleOpen}>
								In
							</Button>
							<Button
								className={styles.transferOut}
								onClick={handleOpenWhitelistTransfer}
								disabled={isRestrictedHodl}
							>
								Out
							</Button>
						</Box>
						{whitelistTransferDialogOpen && <WhitelistTransferDialog />}
					</Box>
				</Box>
			) : (
				<Box>
					<CustomButton
						customStyle={'outlined_square_reverse'}
						className={styles.whitelist_wallet_btn}
						onClick={handleOpenWhitelistWalletDialog}
					>
						Whitelist Wallet
					</CustomButton>
					<WhitelistWalletDialog />
				</Box>
			)}
		</Box>
	)
}
