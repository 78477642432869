import React from 'react'
import { cn } from '@/lib/utils'

interface CustomTextAreaProps
	extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	variant?: 'black' | 'grey' | 'blue'
	label?: string
	limitChars?: number
	helperText?: string
	error?: string | boolean
	value?: string
}

export const CustomTextArea = ({
	label,
	limitChars,
	helperText,
	error,
	className,
	value = '',
	...props
}: CustomTextAreaProps) => {
	return (
		<div className="w-full">
			<div className="flex">
				{label && (
					<h3 className={cn('mb-2.5', error && 'font-bold text-[#d32f2f]')}>
						{label}
					</h3>
				)}
				{limitChars && (
					<em className="ml-auto text-sm">
						{value.length}/{limitChars}
					</em>
				)}
			</div>

			<textarea
				{...props}
				value={value}
				className={cn(
					'w-full min-w-[50%] max-w-full transition-all duration-200 ease-out',
					'px-4 py-3.5',
					"font-['Titillium_Web'] text-base font-light leading-5",
					'appearance-none rounded-lg',
					'resize-none text-white',
					'focus:outline-none',
					'border border-[#1b568c] bg-[#192432]',
					error && 'border-[#d32f2f]',
					className
				)}
			/>

			{(error || helperText) && (
				<p className={cn('mt-1 text-sm', error && 'font-bold text-[#d32f2f]')}>
					{error || helperText}
				</p>
			)}
		</div>
	)
}
