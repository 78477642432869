import { Button } from '@/components/ui/button'
import fanIconWhiteLg from 'assets/images/fan-icon-white-lg.svg'
import { useBecomeSuperFanFirstDialog } from './useBecomeSuperFanFirstDialog'
import { ProjectCarrousel } from './ProjectCarrousel'
import { CustomDialog } from '@/components/common-ui/CustomDialog'
import { setSuperfanItemDialogData } from '@/components/header/signInSlice'
import { useDispatch } from 'react-redux'

export const BecomeSuperFanFirstDialog = () => {
	const {
		open,
		title,
		createdAt,
		signInUser,
		isFirstSuperfan,
		isOnPerksProgram,
		handleClose
	} = useBecomeSuperFanFirstDialog()

	const dispatch = useDispatch()
	const handleViewImage = () => {
		dispatch(
			setSuperfanItemDialogData({
				open: true,
				createdAt: createdAt,
				displayName: signInUser?.displayName,
				title: title
			})
		)
	}

	return (
		<CustomDialog
			open={open}
			onOpenChange={handleClose}
			className="max-w-[750px] pt-[40px] md:pt-4"
			title={`Congratulations! You are now a superfan of "${title}"!`}
		>
			{/* Rewards Section */}
			<div className="flex flex-col gap-3 sm:gap-4">
				{isOnPerksProgram ? (
					<>
						<div className="flex w-full flex-row justify-between">
							<p className="flex text-sm sm:text-lg">
								{'Your Reward:'}
								<span className="font-semibold">
									&nbsp;Superfan Film Credit
								</span>
							</p>
							<p className="flex text-sm sm:text-lg md:hidden">
								<Button
									className="h-auto gap-2 bg-transparent p-0 text-[#53abc9] hover:bg-transparent sm:flex"
									onClick={handleViewImage}
								>
									View
								</Button>
							</p>
						</div>
						<div className="flex flex-row items-center gap-4 sm:flex-row sm:gap-6 md:flex-row md:gap-4">
							<div className="flex items-center justify-between gap-4 sm:justify-start">
								<div className="flex flex-col gap-2">
									<div className="flex h-[50px] w-[66px] cursor-default items-center justify-center rounded-lg border border-white bg-black bg-opacity-25 font-kalnia text-2xl sm:h-[60px] sm:w-[76px] sm:text-3xl">
										Fin
									</div>
								</div>
							</div>

							<div className="flex flex-1 flex-col gap-1 font-inter">
								<p className="text-xs sm:text-base">
									When this project is produced, you will be forever
									memorialized within its end credits as an early supporter of
									the project!
								</p>
							</div>

							<Button
								className="hidden gap-2 sm:flex"
								onClick={handleViewImage}
							>
								View
							</Button>
						</div>
					</>
				) : (
					<div className="flex flex-col gap-2 sm:flex-row sm:items-center sm:gap-6">
						<div className="flex flex-1 flex-col gap-2 font-inter">
							<p className="text-sm [word-break:auto-phrase] sm:text-base">
								As a Superfan, you will enjoy increased staking rewards
								on this project, as well as a place on the Superfan Leaderboard,
								showcasing your support!
							</p>
							<p className="text-xs italic [word-break:auto-phrase] sm:text-sm">
								This Project is not currently enrolled in the Filmio Perks
								Program, but when they do opt into this program, your name will
								be forever immortalized in the end credits of this project, as
								well as the potential of other exciting perks!
							</p>
						</div>
					</div>
				)}

				{!isFirstSuperfan && (
					<div className="flex items-start border p-3 rounded-xl gap-4 sm:flex-row sm:gap-6">
						<img
							src={fanIconWhiteLg.src}
							alt="fan icon"
							className="w-[46px] sm:w-[76px]"
						/>
						<div className="flex flex-1 flex-col gap-1">
							<p className="text-sm sm:text-lg">
								{'First Time Superfan Bonus: '}
								<span className="font-semibold">200 $FAN</span>
							</p>
							<p className="text-xs sm:text-base">
								Use the carousel below to find other projects to become a
								Superfan on! Unlock more unique rewards now!
							</p>
						</div>
					</div>
				)}
			</div>
			<p className="mt-1 text-center text-sm font-semibold md:mt-4 md:text-lg">
				Keep the fun rolling, become a Superfan of these top projects as well!
			</p>
			<ProjectCarrousel />
		</CustomDialog>
	)
}
