'use client'

import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import {
	resetNewsreels,
	setNewsreelPage,
	updateNewsreels
} from './newsreelTabSlice'
import {
	getNewsreelsByFeatureIdService,
	getNewsreelsByIdService
} from 'services/newsreel.service'
import { NEWSREEL_FEATURES } from './NewsreelTab'
import { useEffect, useState } from 'react'
import { setUserId } from '@/components/profile/profileSlice'
import { getProfileByUsername } from 'services/profile.service'
import useAxios from 'hooks/useAxios'
import { useParams, useSearchParams } from 'react-router-dom'
import useLoadingMask from 'hooks/useLoadingMask'
import { RootState } from '@/store/store'
import { RolePermission } from '@/types/project'

const useNewsreelTab = (feature: string) => {
	const dispatch = useDispatch()
	const [loadingNewsreels, setLoadingNewsreels] = useState(false)
	const [loadMore, setLoadMore] = useState(true)
	const { newsreels, page } = useSelector(
		(state: RootState) => state.newsreelTab
	)
	const { user: signInUser } = useSelector((state: RootState) => state.signIn)
	const { userId, user } = useSelector((state: RootState) => state.profile)
	const { project, projectId, rolePermissions } = useSelector(
		(state: RootState) => state.projectDetail
	)
	const { axiosServiceSync } = useAxios()
	const { username, slug } = useParams()
	const { hideMask, showMask } = useLoadingMask()
	const [searchParams, setSearchParams] = useSearchParams()

	const newsreelId = searchParams.get('newsreelId')
	const isProfile = feature === NEWSREEL_FEATURES.profile
	const isProject = feature === NEWSREEL_FEATURES.project
	const isNotNewsreelsOwner =
		(isProfile && signInUser.id !== userId) ||
		(isProject && signInUser.id !== (project as any).userId)

	const getUserId = async () => {
		const data = await axiosServiceSync(getProfileByUsername(username ?? ''))
		dispatch(setUserId(data?.id))
	}

	const fetchNewsreels = async () => {
		showMask()
		setLoadingNewsreels(true)

		const FEATURE_MAP = {
			[NEWSREEL_FEATURES.project]: getNewsreelsByFeatureIdService({
				projectId,
				page: page?.value
			}),
			[NEWSREEL_FEATURES.profile]: getNewsreelsByFeatureIdService({
				userId,
				page: page?.value
			})
		}

		const getNewsreels = _.isNil(newsreelId)
			? FEATURE_MAP[feature]
			: getNewsreelsByIdService(newsreelId)

		const data = await axiosServiceSync(getNewsreels)
		dispatch(updateNewsreels(data?.newsreels))
		setLoadMore(_.isNil(newsreelId) ? data?.pagination?.hasMore : false)
		setLoadingNewsreels(false)
		hideMask()
	}

	const handleSocialClick = (tab: string) => () => {
		setSearchParams({ tab })
	}

	const loadMoreNewsreels = () => {
		if (loadMore && !loadingNewsreels) {
			dispatch(setNewsreelPage(page?.value + 1))
		}
	}

	const haveNewsreelPermissions = () =>
		rolePermissions.some((el: RolePermission) => el?.permissionId === 2)

	useEffect(() => {
		if (
			(!_.isNil(userId) && isProfile) ||
			(!_.isNil(slug) && !_.isNil(projectId) && isProject)
		) {
			fetchNewsreels()
		}
	}, [userId, slug, page, projectId, newsreelId])

	useEffect(() => {
		if (isProfile && !userId) {
			getUserId()
			return () => {
				dispatch(setUserId(null))
			}
		}
	}, [feature])

	useEffect(() => {
		if (page?.value !== 1) dispatch(setNewsreelPage(1))
		return () => {
			dispatch(resetNewsreels())
		}
	}, [])

	const emptyMessage =
		isNotNewsreelsOwner && !haveNewsreelPermissions()
			? 'There are no Newsreel posts. You will be able to join the discussion as soon as the owner of the page creates one!'
			: 'Create a post so that your followers can join your conversation!'

	const isOneNewsreelView = !_.isNil(newsreelId)
	const hideAddNewsreelForm =
		(isNotNewsreelsOwner || isOneNewsreelView) && !haveNewsreelPermissions()

	return {
		user,
		loadMore,
		isProject,
		isProfile,
		newsreels,
		newsreelId,
		emptyMessage,
		loadingNewsreels,
		handleSocialClick,
		hideAddNewsreelForm,
		isOneNewsreelView,
		loadMoreNewsreels
	}
}

export default useNewsreelTab
