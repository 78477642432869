import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from '../header/Header'
import { Sidebar } from '../sidebar'
import { useSelector } from 'react-redux'
import { LoadMask } from '@/components/common'
import { Toaster } from '@/components/ui/toaster'
import { BanDialog } from '@/components/header/banDialog'
import { LoginDialog } from '@/components/header/loginDialog'
import { SaleMagicDialog } from '@/components/header/saleMagicDialog'
import { JoinDialog } from '@/components/header/joinDialog'
import { StartDialog } from '@/components/header/startDialog'
import { AirdropCheckDialog } from '@/components/header/airdropCheckDialog'
import { AirdropSignUpDialog } from '@/components/header/airdropSignUpDialog'
import { getReactions } from '@/services/reaction.service'
import { useDispatch } from 'react-redux'
import { populateReactions } from '@/components/common/reactions/reactionSlice'
import { OnRampDialog } from '@/components/header/onRampDialog'
import { CongratsDialog } from '@/components/header/congratsDialog'
import { PlatformWalletCreatedDialog } from '@/components/header/platformWalletCreatedDialog'
import { CreatePlatformWalletDialog } from '@/components/header/createPlatformWalletDialog'
import { NcWihoutMagicWarning } from '@/components/header/ncWihoutMagicWarning'
import FraudWarningDialog from '@/components/header/fraudWarningDialog/FraudWarningDialog'
import { CreateMagicWalletDialog } from '@/components/header/createMagicWalletDialog'
import { ClaimFgrDialog } from '@/components/header/claimFgrDialog'
import { ImportFanTokenDialog } from '@/components/header/importFanTokenDialog'
import { AddProjectDialog } from '@/components/header/addProjectDialog'
import { DaoTierLevelChangeDialog } from '@/components/header/daoTierLevelChangeDialog'
import { VerificationEmailDialog } from '@/components/profile/dashboardTab/verificationEmailDialog/VerificationEmailDialog'
import { MagicEmailUpdateDialog } from '@/components/profile/dashboardTab/magicEmailUpdateDialog/MagicEmailUpdateDialog'
import useLoginDialog from '@/components/header/loginDialog/useLoginDialog'
import useWeb3 from '@/hooks/useWeb3'
import useHeader from '@/components/header/useHeader'
import { ContestJoinedDialog } from '@/components/projectDetail/contestJoinedDialog'
import useCommonEvents from '@/hooks/useCommonEvents'
import { signVerificationMessage } from '@/utils/blockchain/config/utils'
import { BecomeSuperFanDialog } from '@/components/header/becomeSuperFanDialog'
import { BecomeSuperFanFirstDialog } from '@/components/header/becomeSuperFanFirstDialog'
import { FanPurchaseDialog } from '@/components/header/fanPurchaseDialog'
import { ConnectDialog } from '@/components/header/connectDialog'
import { RootState } from '@/store/store'
import { addObjectToLocalStorage } from '@/utils/utils'
import { updateSignInUser } from '@/components/header/signInSlice'
import { WelcomeDialog } from '@/components/header/welcomeDialog'
import { WelcomeReferralDialog } from '@/components/header/welcomeReferralDialog'
import { CreditPerkDialog } from '@/components/header/creditPerkDialog'
import { cn } from '@/lib/utils'
import Preset from 'react-canvas-confetti/dist/presets'
import RealisticConductor from 'react-canvas-confetti/dist/conductor/realistic'

const Layout = () => {
	const dispatch = useDispatch()
	const {
		showConfetti,
		frontTransactionsInProgress,
		socialJoinParams,
		isAuthenticated,
		joinDialogOpen,
		createPlatformWalletDialogOpen,
		showLoading,
		user
	} = useSelector((state: RootState) => state.signIn)
	const { reactions } = useSelector((state: RootState) => state.reactions)
	const { captchaToken } = useSelector((state: RootState) => state.captchaCheck)
	const { signer, address, isConnected } = useWeb3()
	const { authNcWallet } = useLoginDialog()
	const { handleLogout, reloadUserInfo } = useHeader()
	const { newSuperfanEvent } = useSelector(
		(state: RootState) => state.websocket
	)

	useCommonEvents()

	const fanTokenImported = user?.fanTokenImported === 1

	useEffect(() => {
		const fetchReactions = async () => {
			const reactions = await getReactions()
			dispatch(populateReactions(reactions.data))
		}

		if (isAuthenticated) {
			reloadUserInfo()
		}

		if (isAuthenticated && reactions.length === 0) {
			fetchReactions()
		}
	}, [isAuthenticated, reactions])

	useEffect(() => {
		const handleBeforeUnload = (event: Event) => {
			event.preventDefault()
		}
		const handlePageHide = (event: Event) => {
			event.stopPropagation()
		}

		if (frontTransactionsInProgress) {
			window.addEventListener('beforeunload', handleBeforeUnload)
			window.addEventListener('pagehide', handlePageHide)
		} else {
			window.removeEventListener('beforeunload', handleBeforeUnload)
			window.removeEventListener('pagehide', handlePageHide)
		}
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
			window.removeEventListener('pagehide', handlePageHide)
		}
	}, [frontTransactionsInProgress])

	useEffect(() => {
		const authUser = async () => {
			if (address && signer && !isAuthenticated) {
				try {
					const message =
						'Please sign this message to verify you are the owner of this wallet.'
					const { hash, signature } = await signVerificationMessage(
						signer,
						message
					)
					authNcWallet({
						captchaToken,
						hash,
						signature,
						address,
						...socialJoinParams
					})
				} catch (error) {
					await handleLogout()
					window.location.reload() // TODO find why is needed the reload and search for a better solution
				}
			}
		}
		authUser()
	}, [address, signer, isAuthenticated, isConnected])

	useEffect(() => {
		if (newSuperfanEvent?.projectId) {
			const isSuperfanObject = { isSuperfan: true }
			addObjectToLocalStorage(isSuperfanObject)
			dispatch(updateSignInUser(isSuperfanObject))
		}
	}, [newSuperfanEvent])

	return (
		<>
			<div className="flex h-full w-full flex-col overflow-hidden">
				<div className="isolate flex h-full w-full overflow-hidden pt-header">
					<main
						className={cn(
							'h-full w-full overflow-hidden',
							isAuthenticated ? 'sm:pl-sidebar' : ''
						)}
					>
						<div className="relative mx-auto h-full w-full max-w-screen-2xl sm:px-4">
							<Outlet />
						</div>
					</main>
				</div>

				{isAuthenticated ? (
					<Sidebar className="fixed top-header z-10 h-screen" />
				) : null}
				<Header className="fixed top-0" />
			</div>

			{isAuthenticated ? (
				<>
					<AddProjectDialog />
					<DaoTierLevelChangeDialog />
					{!fanTokenImported && <ImportFanTokenDialog />}
					<ClaimFgrDialog />
					<VerificationEmailDialog />
					<CreateMagicWalletDialog />
					<MagicEmailUpdateDialog />
					<FraudWarningDialog />
					<NcWihoutMagicWarning />
					<CreatePlatformWalletDialog
						open={createPlatformWalletDialogOpen}
						username={user.username}
					/>
					<PlatformWalletCreatedDialog />
					<CongratsDialog />
					<ContestJoinedDialog />
					<OnRampDialog />
					<CreditPerkDialog />
					<BecomeSuperFanDialog />
					<BecomeSuperFanFirstDialog />
					<WelcomeDialog />
					<WelcomeReferralDialog />
					<FanPurchaseDialog />
					<ConnectDialog />
				</>
			) : (
				<>
					<BanDialog />
					<LoginDialog />
					<SaleMagicDialog />
					{joinDialogOpen && <JoinDialog />}
					<StartDialog />
					<AirdropCheckDialog />
					<AirdropSignUpDialog />
				</>
			)}

			{showLoading && <LoadMask />}
			{showConfetti && (
				<div className="pointer-events-none fixed inset-0 z-[110]">
					<Preset
						Conductor={RealisticConductor}
						autorun={{
							speed: 0.2,
							duration: 5000
						}}
						decorateOptions={options => ({
							...options,
							particleCount: 200,
							spread: 180
						})}
					/>
				</div>
			)}

			<Toaster />
		</>
	)
}

export default Layout
