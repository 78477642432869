import { useState } from 'react'
import { ImageList } from '@mui/material'
import { UserAvatar } from './shared/UserAvatar'
import { ImageWithViewerNewsreel } from '@/components/common/imageWithViewerNewsreel/ImageWithViewerNewsreel'
import { useCommentHandler } from '../hooks/useCommentHandler'
import { FeedItem } from '../types'
import { UserInfo } from './shared/UserInfo'
import { CommentInput } from './shared/CommentInput'
import { InteractionsBar } from './shared/InteractionsBar'
import { Comment } from './shared/Comment'
import { cn } from '@/lib/utils'
import { RootState } from '@/store/store'
import { deleteNewsreelByIdService } from '@/services/newsreel.service'
import useNotification from '@/hooks/useNotification'
import useLoadingMask from '@/hooks/useLoadingMask'
import useAxios from '@/hooks/useAxios'
import { ConfirmDialog } from '@/components/common-ui/ConfirmDialog'
import { useSelector } from 'react-redux'
import { Button } from '@/components/ui/button'
import { getCommentService } from '@/services/comment.service'

/**
 * Button component with loading state
 */
const LoadMoreCommentsButton = ({
	onClick,
	isLoading,
	hasMoreComments
}: {
	onClick: () => void
	isLoading: boolean
	hasMoreComments: boolean
}) => (
	<Button
		size="sm"
		onClick={onClick}
		disabled={isLoading || !hasMoreComments}
		className="w-min"
	>
		{isLoading
			? 'Loading...'
			: hasMoreComments
				? 'Load More'
				: 'No more comments'}
	</Button>
)

export const NewsreelItem = ({
	rootStyles,
	cardStyles,
	inputStyles,
	item,
	onDelete
}: {
	rootStyles?: string
	cardStyles?: string
	inputStyles?: string
	item: FeedItem & { type: 'newsreel' }
	onDelete?: (_id: string) => void
}) => {
	const {
		id,
		reactions,
		user,
		createdAt,
		project,
		content,
		comments,
		media,
		commentsCount
	} = item?.record
	const [itemReactions, setItemReactions] = useState(reactions)
	const [itemComments, updateComments] = useState(comments)
	const [commentsPage, setCommentsPage] = useState(0)
	const { user: currentUser } = useSelector((state: RootState) => state.signIn)
	const isNewsreelOwner = currentUser?.id === user?.id
	const { showNotification } = useNotification()
	const { axiosServiceSync } = useAxios()
	const { showMask, hideMask } = useLoadingMask()

	const [expandedComments, setExpandedComments] = useState(false)
	const { comment, handleComment, handleSubmitComment, clearComment } =
		useCommentHandler(item.record.id, 'newsreel', comment => {
			updateComments(prev => [comment, ...prev])
		})

	const handleDeleteNewsreel = async () => {
		showMask()
		const data = await axiosServiceSync(deleteNewsreelByIdService(id))
		onDelete?.(id)
		showNotification({ message: data?.message })
		hideMask()
	}

	const loadMoreComments = async () => {
		const data = await axiosServiceSync(
			getCommentService({
				page: commentsPage,
				newsreelId: id
			})
		)
		setCommentsPage(p => p + 1)
		updateComments(prev => [...prev, ...data])
	}

	return (
		<div
			className={cn(
				'flex flex-col items-start gap-2 overflow-hidden p-px',
				rootStyles
			)}
		>
			<div
				className={cn(
					'relative w-full rounded-md bg-[#2196F3]/15 p-4',
					cardStyles
				)}
			>
				{isNewsreelOwner && (
					<ConfirmDialog
						message="This will permanently delete this post."
						onConfirm={handleDeleteNewsreel}
					>
						<span className="absolute right-3 top-2 cursor-pointer">
							<i className="fas fa-trash-can text-white" />
						</span>
					</ConfirmDialog>
				)}
				<div className="flex items-start gap-1 sm:gap-3">
					<UserAvatar user={user} />
					<UserInfo user={user} createdAt={createdAt} project={project} />
				</div>
				<div>
					<p className="text-white">{content}</p>
					{media.length > 0 && (
						<div className="mt-2">
							<ImageList
								variant="masonry"
								cols={Math.min(media.length, 3) || 2}
								gap={0}
							>
								<ImageWithViewerNewsreel media={media} />
							</ImageList>
						</div>
					)}
					<InteractionsBar
						id={id}
						type={item.type}
						hasComments
						commentsCount={commentsCount}
						reactions={itemReactions}
						setReactions={setItemReactions}
						onCommentClick={() => setExpandedComments(prev => !prev)}
					/>
				</div>
			</div>
			<CommentInput
				colors={inputStyles}
				value={comment}
				onChange={handleComment}
				onSubmit={handleSubmitComment}
				onClear={clearComment}
			/>
			{expandedComments && (
				<>
					<div className="ml-2 w-full divide-y">
						{itemComments.map(comment => (
							<Comment key={comment.id} comment={comment} />
						))}
					</div>
					<LoadMoreCommentsButton
						onClick={loadMoreComments}
						isLoading={false}
						hasMoreComments={itemComments.length < commentsCount}
					/>
				</>
			)}
		</div>
	)
}
