import { useDispatch, useSelector } from 'react-redux'
import { CustomDialog } from '@/components/common-ui/CustomDialog'
import { formatDate } from 'utils/date.utils'
import { setSuperfanItemDialogData } from '../signInSlice'
import superfanPerk from 'assets/images/superfan/superfanPerk.svg'
import { RootState } from '@/store/store'

export const CreditPerkDialog = () => {
	const { superfanItemDialogData } = useSelector(
		(state: RootState) => state.signIn
	)
	const { open, createdAt, displayName, title } = superfanItemDialogData
	const dispatch = useDispatch()

	const handleOpenChange = (open: boolean) => {
		dispatch(
			setSuperfanItemDialogData({
				open
			})
		)
	}

	return (
		<CustomDialog
			open={open}
			onOpenChange={handleOpenChange}
			className="max-w-[590px]"
		>
			<div className="mt-[40px] md:mt-[20px] flex flex-col items-center text-center">
				<div className="relative w-[346px] items-center gap-2">
					<img
						src={superfanPerk.src}
						alt="Superfan Perk"
						className="absolute left-0 top-0 w-full"
					/>
					<div
						className="relative z-10 h-[390px] text-left font-semibold tracking-normal text-black"
						style={{ letterSpacing: 'normal' }}
					>
						<p
							className="mb-[10px] ml-[25px] pt-[188px] text-[34px] font-bold"
							style={{ textShadow: '0px -1px 1px #F5C600' }}
						>
							FILMIO SUPERFAN
						</p>
						<p className="ml-[25px] text-[26px] font-bold leading-6">
							Film Credit
						</p>
						<p className="my-[15px] ml-[25px] text-[16px]">
							Snagged: {formatDate(createdAt, 'full')}
						</p>
						<p className="ml-[25px] text-[26px] font-bold leading-6">
							{displayName}
						</p>
						<p className="ml-[25px] text-[16px]">Project: {title}</p>
					</div>
				</div>

				<p className="hyphens-none break-words px-[12px] pb-[20px] text-left font-titillium text-sm italic leading-normal text-white">
					Superfan Film Credits put your name in the end credits of films & TV
					projects that you become a Superfan of when these participating films
					are produced! Get ready to become a permanent part of the legacy of
					the next blockbuster film with your name in lights!
				</p>
			</div>
		</CustomDialog>
	)
}
