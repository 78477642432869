import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ReviewRating } from '../reviewRating'
import { ReviewInfoTimestamp } from '../reviewInfoTimestamp'
import { CommentReaction } from '../../../common/commentReaction'
import { getTransactionLink } from 'utils/utils'
import { Box, Typography } from '@mui/material'
import styles from './ReviewItem.module.scss'
import { formatDateToRelative } from 'utils/date.utils'
import { Link } from 'react-router-dom'

function ReviewItem({
	id,
	user,
	rating,
	heading,
	content,
	comments,
	reactions,
	updatedAt,
	commentsCount,
	blockchainHash,
	project
}) {
	const { user: signInUser, isAuthenticated } = useSelector(
		state => state.signIn
	)
	const isSignInUserReview = signInUser.id === user.id
	return (
		<Box className={styles.review_item}>
			<Box className={styles.reviews}>
				<Box
					className={
						isSignInUserReview
							? `${styles.review_first_fhild} ${styles.review}`
							: styles.review
					}
				>
					<Box className={styles.userReview}>
						{isSignInUserReview ? <h5>Your Review</h5> : ''}
					</Box>
					<Box className={styles.heading}>
						<ReviewInfoTimestamp
							{...{ updatedAt, user, project, blockchainHash }}
						/>
					</Box>

					<Box className={styles.content}>
						<div className="flex items-center gap-1">
							<ReviewRating readOnly {...{ rating }} />
							<h5 className="-top-[0.1rem] ml-2">
								<label>{heading}</label>
							</h5>
						</div>
						<Typography className={styles.profession}>
							{project ? (
								<>
									<span className={styles.hash}>
										{isAuthenticated ? (
											getTransactionLink(blockchainHash)
										) : (
											<a>{blockchainHash}</a>
										)}
									</span>{' '}
									Reviewed{' '}
									<Link to={`/project/${project?.slug}`}>{project?.title}</Link>{' '}
									{formatDateToRelative(updatedAt)}
								</>
							) : (
								<div className="flex items-center gap-2">
									Reviewed {formatDateToRelative(updatedAt)}
									{!project && (
										<span className={styles.hash}>
											{isAuthenticated ? (
												getTransactionLink(blockchainHash)
											) : (
												<a>{blockchainHash}</a>
											)}
										</span>
									)}
								</div>
							)}
						</Typography>
						<Typography className={styles.review}>{content}</Typography>
					</Box>
					<CommentReaction
						{...{
							comments,
							commentsCount,
							defaultReactions: reactions,
							type: 'review',
							feedId: id
						}}
					/>
				</Box>
			</Box>
		</Box>
	)
}

ReviewItem.propTypes = {
	id: PropTypes.string,
	rating: PropTypes.number,
	content: PropTypes.string,
	comments: PropTypes.array,
	updatedAt: PropTypes.string,
	blockchainHash: PropTypes.string,
	commentsCount: PropTypes.number,
	reactions: PropTypes.array,
	heading: PropTypes.string,
	project: PropTypes.object,
	user: PropTypes.object
}

export default ReviewItem
