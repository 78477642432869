import { Box, Button, Icon, Typography } from '@mui/material'
import { LightTooltip } from '@/components/common'
import infoIcon from 'assets/images/walletIcons/info.svg'
import { RewardUnlockInfo } from '../rewardUnlockInfo'
import { formatDate } from 'utils/date.utils'
import InfoDiamond from 'assets/images/diamond_icon_info.svg'
import newToken from 'assets/images/new_token_blue.svg'
import styles from './HodlOverviewSection.module.scss'
import { abbreviateNumberFixed } from 'utils/utils'

export const HodlOverviewSection = ({
	type,
	balance,
	hodlAmount,
	balanceTotal,
	rewardsTokens,
	shortwalletAddress,
	isIngXRestrictedInvestor,
	handleCopyWalletAddress,
	availableToWithdraw,
	onClickChangeTab,
	earningsToDate,
	stakesTotal,
	startDate,
	endDate
}) => {
	return (
		<Box className={styles.row_group}>
			<Box className={styles.total_tokens}>
				<Box className={styles.body}>
					<Box className={styles.top}>
						<Typography component={'h2'} className={styles.header_token_hodl}>
							Total Tokens
							<LightTooltip
								enterTouchDelay={0}
								leaveTouchDelay={4000}
								placement="bottom"
								title="The Platform Wallet is a combination of both Available and Reward Token. Available tokens are FAN$ transferred in, and they can be transferred out. Reward tokens are tokens received on the platform."
							>
								<span>
									<img alt="info Icon" src={infoIcon.src} />
								</span>
							</LightTooltip>
						</Typography>
					</Box>
					<Box className={styles.number_copy}>
						<Box className={styles.center}>
							<img alt="" className={styles.width_20} src={newToken.src} />
							<Typography component={'h1'} className={styles.text}>
								{abbreviateNumberFixed(balanceTotal ?? 0, 2)}
							</Typography>
						</Box>
						<Box className={styles.bottom}>
							<Button
								onClick={handleCopyWalletAddress}
								startIcon={<Icon baseClassName="fas" className="fa-copy" />}
								className={styles.copy}
							>
								{shortwalletAddress}
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className={styles.diamond_circle_HODL}>
				<Box className={styles.body}>
					<Box className={styles.top}>
						<Typography component={'h2'} className={styles.header_token_hodl}>
							Diamond Circle HODL
							<LightTooltip
								enterTouchDelay={0}
								leaveTouchDelay={4000}
								placement="bottom"
								title={
									<>
										<Typography style={{ lineHeight: '17px' }}>
											<strong>
												Your Diamond Circle Tokens will increase with each
												monthly Vesting release. Your bonus tokens will be based
												on the total amount at the end of the HODL period that
												you selected and will then be released over{' '}
												{type?.hodlPeriod} months
											</strong>
										</Typography>
										<Typography style={{ lineHeight: '17px' }}>
											<strong>{type?.bonusMultiplier}% Bonus</strong>
										</Typography>
										<Typography style={{ lineHeight: '17px' }}>
											<strong>Bonus Earnings To Date:</strong>
											<Typography style={{ lineHeight: '1', marginTop: '3px' }}>
												{abbreviateNumberFixed(earningsToDate, 1)} $FAN
											</Typography>
										</Typography>
										<Typography style={{ lineHeight: '17px' }}>
											<b>HODL Period: </b>
											<Typography style={{ lineHeight: '1', marginTop: '3px' }}>
												{formatDate(startDate)} - {formatDate(endDate)}
											</Typography>
										</Typography>
									</>
								}
							>
								<span>
									<img alt="info icon" src={InfoDiamond.src} />
								</span>
							</LightTooltip>
						</Typography>
					</Box>
					<Box className={styles.number_copy}>
						<Box className={styles.center}>
							<img alt="" className={styles.width_20} src={newToken.src} />
							<Typography component={'h1'} className={styles.text}>
								{abbreviateNumberFixed(hodlAmount, 1)}
							</Typography>
						</Box>
						<Box className={styles.bottom}>
							<Button onClick={onClickChangeTab} className={styles.wht_btn}>
								View More
							</Button>
						</Box>
					</Box>
				</Box>
			</Box>
			<RewardUnlockInfo
				{...{
					balance,
					rewardsTokens,
					stakesTotal,
					isIngXRestrictedInvestor,
					availableToWithdraw
				}}
			/>
		</Box>
	)
}
