import { Card, CardContent } from '@/components/ui/card'
import { Link } from 'react-router-dom'
import { MessageCircle, Coins, Share, Heart, LucideIcon } from 'lucide-react'
import { UserInfo } from './shared/UserInfo'
import { ActivityType, FeedItem } from '../types'

const ACTIVITY_ICONS: Record<ActivityType, LucideIcon> = {
	comment: MessageCircle,
	transaction: Coins,
	socialShare: Share,
	commentReaction: Heart,
	stakeReaction: Heart,
	reviewReaction: Heart,
	newsreelReaction: Heart
}

const getActivityContent = (
	type: ActivityType,
	project?: { title: string; slug: string },
	amount?: number,
	action?: string
) => {
	switch (type) {
		case 'comment':
			return `Commented on ${project ? project.title : 'a post'}`
		case 'transaction':
			return `Received ${amount?.toLocaleString()} FAN tokens ${action || ''}`
		case 'socialShare':
			return `Shared ${project ? project.title : 'a project'}`
		default:
			return `Reacted to a ${type.replace('Reaction', '')}`
	}
}

export const ActivityItem = ({
	item
}: {
	item: FeedItem & {
		type: ActivityType
	}
}) => {
	const type = item.type
	const { user, createdAt, content, project, amount, action } = item.record
	const IconComponent = ACTIVITY_ICONS[type]

	return (
		<Card className="border-none bg-transparent p-0">
			<CardContent className="p-0">
				<div className="flex items-start gap-3 overflow-hidden rounded-md bg-[#2196F3]/15 p-4">
					<div className="rounded-full bg-blue-500/10 p-2">
						<IconComponent className="h-5 w-5" />
					</div>
					<div className="flex-1">
						<UserInfo user={user} createdAt={createdAt} />
						<p className="mt-1 text-white">
							{getActivityContent(type, project, amount, action)}
						</p>
						{content && <p className="mt-2 text-gray-300">{content}</p>}
						{project && (
							<Link
								to={`/project/${project.slug}`}
								className="mt-2 inline-block text-blue-400 hover:underline"
							>
								View Project →
							</Link>
						)}
					</div>
				</div>
			</CardContent>
		</Card>
	)
}
