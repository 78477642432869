import { useState } from 'react'
import {
	Menu,
	MenuItem,
	IconButton,
	Box,
	Checkbox,
	ListItemIcon,
	SvgIcon
} from '@mui/material'
import { chains } from 'utils/blockchain/config/constants'
import styles from './networkSelect.module.scss'
import ArrowIcon from 'assets/images/fanPurchase/arrow.svg'
import PolygonIcon from 'assets/images/fanPurchase/polygon_icon.png'
import EthIcon from 'assets/images/fanPurchase/eth_icon.png'
import { styled } from '@mui/system'
import PropTypes from 'prop-types'

const StyledMenu = styled(Menu)(() => ({
	backgroundColor: 'rgba(0, 0, 0, 0)',
	border: '1px solid rgba(255, 255, 255, 0.12) !important',
	borderColor: 'rgba(255, 255, 255, 0.12)',
	'& .MuiPaper-root': {
		borderRadius: '8px',
		boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1) ',
		minWidth: '225px',
		backgroundColor: 'rgb(20, 20, 22)',
		border: '1px solid rgba(255, 255, 255, 0.12) !important',
		borderColor: 'rgba(255, 255, 255, 0.12)',
		padding: '8px',
		transform: 'translateX(-90%) !important'
	},
	'& .MuiMenuItem-root': {
		color: '#fff',
		fontSize: '16px',
		fontWeight: '700',
		height: '44px',
		'&:hover': {
			backgroundColor: 'rgb(27,27,27)',
			borderRadius: '8px',
			cursor: 'pointer'
		}
	}
}))

const StyledMenuItem = styled(MenuItem)(() => ({
	color: '#333',
	'&:hover': {
		backgroundColor: '#ddd'
	}
}))
const CustomCheckbox = styled(Checkbox)(() => ({
	'& .MuiCheckbox-root': {
		width: '18px',
		height: '18px',
		color: 'black',
		'&.Mui-checked': {
			color: 'black',
			background: '#fff'
		},
	},
	'& .MuiSvgIcon-root': {
		width: '13px',
		height: '13px'
	},
	'& .MuiSvgIcon-root path': {
		stroke: 'black',
		fill: 'white',
		strokeWidth: '3',
		fillRule: 'evenodd'
	},
	'&.Mui-checked .MuiSvgIcon-root path': {
		fill: 'black',
		stroke: 'none'
	}
}))

const CheckboxIcon = () => (
	<SvgIcon sx={{ visibility: 'hidden' }}>
		<path
			d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
			style={{ fill: '#FFFFFF' }}
		/>
	</SvgIcon>
);

const CheckboxCheckedIcon = () => (
	<SvgIcon>
		<path
			d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
			style={{ fill: '#FFFFFF' }}
		/>
	</SvgIcon>
);

const iconMap = {
	PolygonIcon: PolygonIcon,
	EthIcon: EthIcon
}

const NetworkSelect = ({ options, selectOption, selectedItem }) => {
	const [anchorEl, setAnchorEl] = useState(null)
	const [open, setOpen] = useState(false)

	const handleClick = event => {
		setAnchorEl(event.currentTarget)
		setOpen(!open)
	}

	const handleClose = () => {
		setAnchorEl(null)
		setOpen(false)
	}

	const handleSelect = value => {
		selectOption(value?.id)
		handleClose()
	}

	const getNetworkIconSRC = name => {
		const chainIcon = chains.find(chain => chain?.name === name)?.icon
		return iconMap[chainIcon].src
	}

	return (
		<Box className={styles.flex_box}>
			<Box className={styles.icon_list}>
				<img
					src={getNetworkIconSRC(selectedItem?.name)}
					alt={selectedItem?.name}
				/>
			</Box>
			<IconButton className={styles.click_button} onClick={handleClick}>
				<img
					src={ArrowIcon.src}
					alt="Arrow"
					className={`${styles.arrow_icon} ${open ? styles.rotate : ''}`}
				/>
			</IconButton>
			<StyledMenu
				anchorEl={anchorEl}
				open={Boolean(anchorEl) && open}
				onClose={handleClose}
				defaultValue={selectedItem ?? ''}
			>
				{options.map(item => (
					<StyledMenuItem
						onClick={() => handleSelect(item)}
						key={item.id}
						value={item.id}
					>
						<Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
							<ListItemIcon>
								<Box className={styles.icon_list}>
									<img alt={item?.name} src={getNetworkIconSRC(item?.name)} />
								</Box>
							</ListItemIcon>

							<Box sx={{ flexGrow: 1, ml: 1 }}>{item.name}</Box>

							<CustomCheckbox
								edge="end"
								checked={selectedItem?.name === item.name}
								icon={<CheckboxIcon />}
								checkedIcon={<CheckboxCheckedIcon />}
								disableRipple
							/>
						</Box>
					</StyledMenuItem>
				))}
			</StyledMenu>
		</Box>
	)
}

NetworkSelect.propTypes = {
	options: PropTypes.array,
	selectOption: PropTypes.func,
	selectedItem: PropTypes.any
}

export default NetworkSelect
