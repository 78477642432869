import { Badge } from '../ui/badge'

interface ProjectGenreBadgesProps {
	title: string
	stage: { name: string; color: string }
	genres: Array<{ id: number; name: string }>
}

export const ProjectGenreBadges = ({
	title,
	stage,
	genres
}: ProjectGenreBadgesProps) => (
	<section className="space-y-2">
		<h2 className="m-0 text-xl font-bold text-white md:text-2xl">
			About {title}
		</h2>
		<div className="flex flex-wrap gap-2">
			{stage && (
				<Badge
					className="text-base text-black"
					style={{ backgroundColor: stage.color }}
				>
					{stage.name}
				</Badge>
			)}
			{genres.map(genre => (
				<Badge
					key={genre.id}
					variant="secondary"
					className="bg-gray-800 text-base text-white"
				>
					{genre.name}
				</Badge>
			))}
		</div>
	</section>
)
