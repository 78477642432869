import { useState, useEffect } from 'react'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
	DropdownMenuItem
} from '@/components/ui/dropdown-menu'
import { FaChevronDown } from 'react-icons/fa'

import { Area, AreaChart, XAxis, YAxis } from 'recharts'
import { ChartContainer } from '@/components//ui/chart'
import { PiArrowFatLineUpLight } from 'react-icons/pi'
import { TbSquareRotated } from 'react-icons/tb'
import { TableReferral } from './TableReferral/TableReferral'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { getTotalJoinStatsByShareUserId } from 'services/socialJoin.service'
import { SocialShareSection } from './SocialItems/SocialShareSection'
import { abbreviateNumberFixed } from '@/utils/utils'

const INTERVAL_OPTIONS = [
	{ name: '1 day', id: '1d' },
	{ name: '7 days', id: '7d' },
	{ name: '30 days', id: '30d' },
	{ name: '1 year', id: '1y' },
	{ name: 'All Time', id: 'all' }
]

export function ReferralPerformance() {
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()

	const [time, setTime] = useState(INTERVAL_OPTIONS[2])
	const [data, setData] = useState({})

	const afterFetchStats = (response, error) => {
		if (!error) {
			setData(response)
		}
		hideMask()
	}

	const getStats = () => {
		showMask()

		axiosService(
			getTotalJoinStatsByShareUserId({ time: time?.id }),
			afterFetchStats
		)
	}

	useEffect(() => {
		getStats()
	}, [])

	useEffect(() => {
		getStats()
	}, [time])

	return (
		<div className="w-full rounded-lg bg-transparent p-4 text-white">
			<div className="mb-4 flex items-center justify-between gap-11 text-nowrap">
				<div className="text-lg font-semibold text-white">
					Referral Performance
				</div>
				<div className="flex w-full items-center justify-between space-x-4">
					<SocialShareSection className="max-[639px]:hidden sm:!flex" />
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button
								variant="ghost"
								size="sm"
								className="flex items-center space-x-1"
							>
								<span>{time?.name}</span>
								<FaChevronDown />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent className="bg-background text-white">
							{INTERVAL_OPTIONS.map((ele, index) => {
								return (
									<DropdownMenuItem
										key={index}
										onSelect={() => setTime(ele)}
										className="hover:bg-[#000]"
									>
										{ele?.name}
									</DropdownMenuItem>
								)
							})}
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			</div>

			<div className="flex flex-row items-start justify-between gap-6 transition-all duration-300">
				<Card
					className="h-[184px] min-w-[159px] bg-[#312D2D] sm:h-[158px] sm:min-w-[347px]"
					x-chunk="charts-01-chunk-7"
				>
					<CardContent className="relative flex h-full items-center justify-end !rounded-lg p-0 [&>.recharts-wrapper]:!rounded-lg [&>svg]:!rounded-lg">
						<span className="absolute right-2 top-2 rounded-full bg-bgSecondary px-2 py-0.5 text-xs font-semibold text-black">
							{abbreviateNumberFixed(data?.change ?? 0, 2)}%
						</span>
						<ChartContainer
							className="!-mb-12 h-full w-full !rounded-lg [&>.recharts-wrapper]:!rounded-lg [&>svg]:!rounded-lg"
							config={{
								time: {
									label: 'Time',
									color: '#FFFFFF'
								}
							}}
						>
							<AreaChart
								accessibilityLayer
								data={data?.data}
								margin={{
									left: 0,
									right: 0,
									top: 0,
									bottom: 0
								}}
							>
								<XAxis dataKey="time_frame" hide />
								<YAxis domain={['dataMin - 5', 'dataMax + 2']} hide />
								<defs>
									<linearGradient id="fillTime" x1="0" y1="0" x2="0" y2="1">
										<stop offset="0%" stopColor="#2887BD1f" stopOpacity={1} />
										<stop offset="100%" stopColor="#2887BD1f" stopOpacity={1} />
									</linearGradient>
								</defs>
								<Area
									dataKey="total_referrals"
									type="natural"
									fill="#2887BD1f"
									fillOpacity={1}
									className="rounded-lg"
									stroke="var(--color-time)"
									strokeWidth="2px"
									height="100%"
								/>
							</AreaChart>
						</ChartContainer>
					</CardContent>
				</Card>
				<div className="flex flex-col items-end justify-between gap-[9px] border-0 bg-transparent sm:items-center sm:gap-4">
					<SocialShareSection isMobile={true} />
					<div className="flex h-[94px] min-w-[132px] flex-col items-center justify-center gap-3 rounded-lg border border-bgSecondary sm:w-[157px]">
						<p className="text-4xl font-extrabold tracking-[-0.025em]">
							{data?.users}
						</p>
						<span className="text-sm font-bold leading-7">User Join</span>
					</div>
					<div className="flex h-12 min-w-[132px] flex-row items-center justify-center gap-4 rounded-lg border border-bgSecondary text-sm font-normal leading-6 sm:w-[157px]">
						<span className="flex flex-row items-center justify-center gap-[3px]">
							<TbSquareRotated /> {data?.gems}
						</span>
						<span className="flex flex-row items-center justify-center gap-[3px]">
							<PiArrowFatLineUpLight /> {data?.xp}
						</span>
					</div>
				</div>
			</div>
			<TableReferral />
		</div>
	)
}
