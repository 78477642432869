import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginDialogOpen } from '@/components/header/signInSlice'
import { Grid, Box, Typography } from '@mui/material'
import styles from './Dao.module.scss'
import daoHero from 'assets/images/homepage/dao_hero.webp'

export const DaoSection = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { isAuthenticated } = useSelector(state => state.signIn)

	const handleGoToExplore = () => {
		if (!isAuthenticated) dispatch(setLoginDialogOpen(true))
		else {
			navigate('/explore')
		}
	}

	return (
		<>
			<Grid>
				<Box className={styles.dao}>
					<Grid container className={styles.dao_desc}>
						<Box className={styles.explanation}>
							<h2>The Power of the Crowd</h2>
							<Typography>
								Film.io DAO is a <strong>groundbreaking</strong> decentralized
								autonomous organization that puts the <strong>power</strong> of
								the entertainment industry into the hands of{' '}
								<strong>creators and fans</strong>.
							</Typography>
							<Typography>
								By joining the Film.io DAO, participants gain a{' '}
								<strong>voice</strong> in greenlighting projects, shaping the{' '}
								<strong>future of film</strong>, and democratizing content
								creation. This <strong>revolutionary</strong> platform empowers
								a global community to{' '}
								<strong>collaborate, invest, and innovate</strong>, transforming
								the way films are made and distributed.
							</Typography>
							<Typography>
								With Film.io DAO, you&lsquo;re not just a spectator—you become a
								key <strong>player</strong> in the entertainment world, driving{' '}
								<strong>change</strong> and discovering the{' '}
								<strong>next big hit</strong>.
							</Typography>
							<button onClick={handleGoToExplore}>
								Take Your Seat at the Table Now
							</button>
						</Box>
						<Box className={styles.hero}>
							<img alt="" src={daoHero.src} />
						</Box>
					</Grid>
				</Box>
			</Grid>
		</>
	)
}

export default DaoSection
