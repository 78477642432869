import { createSlice } from '@reduxjs/toolkit'
import { DateTime } from 'luxon'

const initialState = {
	transactions: {
		count: 0,
		rows: []
	},
	projectsFgr: {
		count: 0,
		rows: []
	},
	followedProjects: [],
	overview: {
		balance: 0,
		stakesTotal: 0,
		totalBalance: 0,
		unlockedTokens: 0,
		unlockedRewards: 0,
		totalEarnings: 0,
		actionsEarnings: 0,
		earningsStaking: 0,
		gemTotal: 0,
		unlockRewardsStartDate: DateTime.now().plus({ days: 2 }).toISODate(),
		unlockRewardsEndDate: DateTime.now().plus({ days: 2 }).toISODate()
	},
	hodl: {
		id: null,
		hodlAmount: 0,
		type: null,
		startDate: null,
		endDate: null
	},
	pagin: {
		page: 0,
		limit: 6,
		offset: 0
	},
	fgrPagin: {
		page: 0,
		limit: 6,
		offset: 0
	},
	fgrClaimed: null,
	fgrProjectIdInProgress: null,
	orderBy: 'createdAt',
	order: 'desc',
	only: {
		stake: false,
		earnings: false
	},
	reloadTransactions: false,
	openDonationModal: false,
	claimWalletDialogOpen: false,
	retyingTransaction: false
}

export const walletTabSlice = createSlice({
	name: 'walletTab',
	initialState,
	reducers: {
		setTransactions: (state, { payload }) => {
			state.transactions = payload
		},
		setProjectsFgr: (state, { payload }) => {
			state.projectsFgr = payload
		},
		fgrClaimingCompleted: state => {
			state.fgrClaimed = !state.fgrClaimed
		},
		setClaimWalletDialogOpen: (state, { payload }) => {
			state.claimWalletDialogOpen = payload
		},
		setOverview: (state, { payload }) => {
			state.overview = {
				...state.overview,
				...payload
			}
		},
		setHodl: (state, { payload }) => {
			state.hodl = {
				...state.hodl,
				...payload
			}
		},
		setPaging: (state, { payload }) => {
			state.pagin = {
				...state.pagin,
				...payload
			}
		},
		setFgrPaging: (state, { payload }) => {
			state.fgrPagin = {
				...state.fgrPagin,
				...payload
			}
		},
		setOrderBy: (state, { payload }) => {
			state.orderBy = payload
		},
		setOrder: (state, { payload }) => {
			state.order = payload
		},
		setOnly: (state, { payload }) => {
			state.only = payload
		},
		setFollowedProjects: (state, { payload }) => {
			state.transactions = payload
		},
		setRetyingTransaction: (state, { payload }) => {
			state.retyingTransaction = payload
		},
		setFgrProjectIdInProgress: (state, { payload }) => {
			state.fgrProjectIdInProgress = payload
		},
		reloadRecentActivity: state => {
			state.reloadTransactions = !state.reloadTransactions
		}
	}
})

export const {
	fgrClaimingCompleted,
	setFollowedProjects,
	setTransactions,
	setFgrPaging,
	setOverview,
	setHodl,
	setOrder,
	setOnly,
	setPaging,
	setOrderBy,
	setProjectsFgr,
	reloadRecentActivity,
	setRetyingTransaction,
	setClaimWalletDialogOpen,
	setFgrProjectIdInProgress
} = walletTabSlice.actions

export default walletTabSlice.reducer
