import { buildFormData } from 'utils/form.util'

export const addDaoProposalService = (data: any) => {
	const fields = [
		'name',
		'owner',
		'description',
		'startDate',
		'endDate',
		'blockchainHash',
		'blockchainId'
	]
	const files = ['poster']
	const formData = buildFormData(data, fields, files)
	return {
		method: 'post',
		url: '/daoProposal',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}
}

export const updateDaoProposalService = (data: any) => ({
	method: 'put',
	url: '/daoProposal',
	data,
	autoexec: false
})

export const deleteDaoProposalService = (id: any) => ({
	method: 'delete',
	url: '/daoProposal',
	data: { id },
	autoexec: false
})

export const getDaoProposalsService = (data: any) => ({
	method: 'get',
	url: '/daoProposal',
	data,
	autoexec: false
})

export const getDaoProposalByIdService = (id: any) => ({
	method: 'get',
	url: `/daoProposal/${id}`,
	autoexec: false
})

export const voteDaoProposalService = (data: any) => ({
	method: 'post',
	url: '/daoProposal/vote',
	data,
	autoexec: false
})

export const updateVoteDaoProposalService = (data: any) => ({
	method: 'put',
	url: '/daoProposal/vote',
	data,
	autoexec: false
})

export const getProposalVotersService = (params: any) => ({
	method: 'get',
	url: '/daoProposal/voters',
	params,
	autoexec: false
})
