import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addCommentService } from '@/services/comment.service'
import { Mixpanel } from '@/services/mixpanel.service'
import useNotification from '@/hooks/useNotification'
import { setLoginDialogOpen } from '@/components/header/signInSlice'
import useAxios from '@/hooks/useAxios'
import { newsreelCommentAddedHook } from '@/components/common/newsreelTab/newsreelTabSlice'
import { commentAddedHook } from '@/components/newsreelPage/newsreelPageSlice'
import { reviewCommentAddedHook } from '@/components/projectDetail/projectDetailSlice'
import { Comment } from '../types'
import { RootState } from '@/store/store'

export const useCommentHandler = (
	itemId: string,
	type: string,
	onCommentAdded?: (_comment: Comment) => void
) => {
	const [comment, setComment] = useState('')
	const dispatch = useDispatch()
	const { showNotification } = useNotification()
	const { user, isAuthenticated } = useSelector(
		(state: RootState) => state.signIn
	)
	const { axiosService } = useAxios()

	const handleComment = (value: string) => {
		setComment(value)
	}

	const handleSubmitComment = async () => {
		if (!isAuthenticated) {
			dispatch(setLoginDialogOpen(true))
			return
		}

		axiosService(
			addCommentService({
				[`${type}Id`]: itemId,
				content: comment
			}),
			(data: any, error: any) => {
				if (!error) {
					Mixpanel.track('new_comment_add_event', {
						distinct_id: user.id,
						displayName: user.displayName,
						username: user.username,
						...data
					})

					const newComment = { ...data, feedId: itemId }
					onCommentAdded?.(newComment)

					if (type === 'newsreel')
						dispatch(newsreelCommentAddedHook(newComment))
					dispatch(commentAddedHook(newComment))
					dispatch(reviewCommentAddedHook(newComment))
					clearComment()
					showNotification({ message: 'Comment added successfully' })
				}
			}
		)
	}

	const clearComment = () => {
		setComment('')
	}

	return {
		comment,
		handleComment,
		handleSubmitComment,
		clearComment
	}
}
