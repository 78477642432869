import React, { ReactNode, useCallback } from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import useEmblaCarousel from 'embla-carousel-react'
import { EmblaOptionsType } from 'embla-carousel'

interface BaseCarouselProps {
	children: ReactNode
	className?: string
	slideWidth?: string
	slideSpacing?: string
	options?: EmblaOptionsType
}

export const BaseCarousel = ({
	children,
	className,
	options = {
		align: 'start',
		slidesToScroll: 1,
		containScroll: 'trimSnaps',
		dragFree: true,
		loop: true
	}
}: BaseCarouselProps) => {
	const [emblaRef, emblaApi] = useEmblaCarousel(options)

	const scrollPrev = useCallback(() => {
		if (emblaApi) emblaApi.scrollPrev()
	}, [emblaApi])

	const scrollNext = useCallback(() => {
		if (emblaApi) emblaApi.scrollNext()
	}, [emblaApi])

	return (
		<div className={`relative w-full min-w-0 px-14 ${className}`}>
			<div className="overflow-hidden" ref={emblaRef}>
				<div className="flex">{children}</div>
			</div>

			<button
				onClick={scrollPrev}
				className="absolute left-0 top-1/2 -translate-y-1/2 rounded-full bg-transparent p-0 md:p-2 opacity-20 transition-colors hover:opacity-100"
			>
				<ChevronLeft className="h-12 w-12" />
			</button>
			<button
				onClick={scrollNext}
				className="absolute right-0 top-1/2 -translate-y-1/2 rounded-full bg-transparent p-0 md:p-2 opacity-20 transition-colors hover:opacity-100"
			>
				<ChevronRight className="h-12 w-12" />
			</button>
		</div>
	)
}
