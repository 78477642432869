import { useDispatch, useSelector } from 'react-redux'
import {
	showNotification,
	hideNotification,
	NotificationType
} from './notificationSlice'
import { RootState } from '@/store/store'

interface NotificationOptions {
	type?: NotificationType
	title?: string
	message: string
}

export const useNotificationDialog = () => {
	const dispatch = useDispatch()
	const notificationState = useSelector(
		(state: RootState) => state.notificationDialog
	)

	const notify = (options: NotificationOptions) => {
		dispatch(
			showNotification({
				isOpen: true,
				type: options.type,
				title: options.title,
				message: options.message
			})
		)
	}

	const closeNotification = () => {
		dispatch(hideNotification())
	}

	return {
		notify,
		closeNotification,
		notificationState
	}
}
