'use client'

import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger
} from '@/components/ui/alert-dialog'
import { ReactNode } from 'react'

interface ConfirmDialogProps {
	children: ReactNode
	message: string | ReactNode
	onConfirm: () => void
	confirmText?: string
	cancelText?: string
	title?: string
}

export const ConfirmDialog = ({
	children,
	message,
	onConfirm,
	confirmText = 'OK',
	cancelText = 'Cancel',
	title = 'Are you sure?'
}: ConfirmDialogProps) => {
	return (
		<AlertDialog>
			<AlertDialogTrigger asChild>{children}</AlertDialogTrigger>
			<AlertDialogContent className="z-[1350] gap-4 rounded-lg border border-[#1b568c] bg-[#0A1C2A] px-4 py-8 font-inter">
				<AlertDialogHeader>
					<AlertDialogTitle className="text-center text-2xl font-bold text-white">
						{title}
					</AlertDialogTitle>
					<AlertDialogDescription>{message}</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter className="mt-4 flex w-full items-center gap-4 sm:justify-center">
					<AlertDialogCancel className="h-[35px] rounded-[25px] border-none bg-[#4e4bde] px-8 text-white">
						{cancelText}
					</AlertDialogCancel>
					<AlertDialogAction
						className="h-[35px] rounded-[25px] border-none bg-transparent px-8 text-white"
						onClick={onConfirm}
					>
						{confirmText}
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}
