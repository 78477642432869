import mixpanel from 'mixpanel-browser'

export function initMixpanel() {
	mixpanel.init('8c0a51476ad0690058a86637dc33c657', {
		debug: true,
		ignore_dnt: true
	})
}
const envCheck = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production'
const actions = {
	identify: (id: string) => {
		if (envCheck) mixpanel.identify(id)
	},
	alias: (id: string) => {
		if (envCheck) mixpanel.alias(id)
	},
	track: (name: string, props: any) => {
		if (envCheck) mixpanel.track(name, props)
	},
	people: {
		set: (id: string, props: any) => {
			if (envCheck) mixpanel.people.set(id, props)
		}
	},
	reset: () => {
		if (envCheck) mixpanel.reset()
	}
}

export const Mixpanel = actions
