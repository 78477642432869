import { Box, Typography } from '@mui/material'
import { updateMetaTags } from 'utils/utils'
import { SidebarNewsreel } from './sidebarNewsreel'
import { FilteredDataChip } from './filteredDataChip'
import { AddNewsreelForm } from './addNewsreelForm'
import { Search } from '@/components/common'
import { NewsreelElements } from './newsreelElements'
import styles from './NewsreelPage.module.scss'
import useNewsreelPage from './useNewsreelPage'

const NewsreelPage = () => {
	updateMetaTags({})
	const { search, isAuthenticated, handleSearch: setSearch } = useNewsreelPage()
	return (
		<Box className={styles.newsreel_page}>
			<FilteredDataChip />
			<Box className={styles.content}>
				<SidebarNewsreel />
				<Box className={styles.center}>
					<Box className={styles.heading}>
						<Typography>Latest Newsreel Updates</Typography>
						<Search
							{...{
								setSearch,
								search,
								placeholder: 'Search Updates'
							}}
						/>
					</Box>
					{isAuthenticated && <AddNewsreelForm />}
					<NewsreelElements />
				</Box>
			</Box>
		</Box>
	)
}

export default NewsreelPage
