import { Link, useParams, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Tabs, Box, Tab } from '@mui/material'
import DashboardTab from '../dashboardTab/DashboardTab'
import { StakesTab } from '../stakesTab'
import { ReviewsTab } from '../reviewsTab'
import { TeamTab } from '../teamTab'
import { setTabIndex } from '../projectDetailSlice'
import { NewsreelTab, NEWSREEL_FEATURES } from '@/components/common'
import { useEffect } from 'react'
import { HomeTab } from '../homeTab'
import styles from './TabsSection.module.scss'

export const TAB_MAP = {
	dashboard: 0,
	newsreel: 1,
	stakes: 2,
	reviews: 3,
	team: 4
}

function TabPanel({ children, value, index, ...other }) {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	}
}

export default function TabsSection() {
	const dispatch = useDispatch()
	const {
		tabIndex,
		isOwnerSignedIn
		// , isCreatorView
	} = useSelector(state => state.projectDetail)
	const { user: signInUser } = useSelector(state => state.signIn)
	const { slug } = useParams()
	const [searchParams, setSearchParams] = useSearchParams()
	const handleChange = (_event, newValue) => {
		setSearchParams({ tab: newValue })
	}

	// const handleSwitchView = () => {
	// 	dispatch(setIsCreatorView(!isCreatorView))
	// }
	const isCreatorView = true // TODO: forcing creator view for now

	useEffect(() => {
		const tab = searchParams.get('tab')
		dispatch(setTabIndex(Number(tab) || 0))
	})

	useEffect(() => {
		// dispatch(setIsCreatorView(isOwnerSignedIn))
		// if (!isOwnerSignedIn) {
		// 	navigate(`/project/${slug}`)
		// }
	}, [isOwnerSignedIn])

	const switchButtonText = isCreatorView ? 'View as Fan' : 'View as Creator'
	const isCreatorOrAdmin = isOwnerSignedIn || Number(signInUser.isAdmin) === 1

	return (
		<div className={styles.details_tab}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }} component="div">
				<Tabs
					value={tabIndex}
					onChange={handleChange}
					variant="fullWidth"
					className={styles.tabs_list}
					TabIndicatorProps={{
						className: styles.tab_custom_indicator
					}}
					aria-label="basic tabs example"
				>
					{isCreatorOrAdmin && isCreatorView ? (
						<Tab label="Home" {...a11yProps(0)} />
					) : (
						<Tab label="Dashboard" {...a11yProps(0)} />
					)}
					<Tab label="Newsreel" {...a11yProps(1)} />
					<Tab label="Stakes" {...a11yProps(2)} />
					<Tab label="Reviews" {...a11yProps(3)} />
					<Tab label="Team" {...a11yProps(4)} />
					{isCreatorOrAdmin && (
						<Link className={styles.switch_fan_creator} to={`/project/${slug}`}>
							{switchButtonText}
						</Link>
					)}
				</Tabs>
			</Box>

			{isCreatorOrAdmin && isCreatorView ? (
				<TabPanel value={tabIndex} index={0}>
					<HomeTab />
				</TabPanel>
			) : (
				<TabPanel value={tabIndex} index={0}>
					<DashboardTab />
				</TabPanel>
			)}
			<TabPanel value={tabIndex} index={1}>
				<NewsreelTab feature={NEWSREEL_FEATURES.project} />
			</TabPanel>
			<TabPanel value={tabIndex} index={2}>
				<StakesTab />
			</TabPanel>
			<TabPanel value={tabIndex} index={3}>
				<ReviewsTab />
			</TabPanel>
			<TabPanel value={tabIndex} index={4}>
				<TeamTab />
			</TabPanel>
		</div>
	)
}
