import { Box } from '@mui/material'
import { AidropJoinDialog } from '@/components/header/aidropJoinDialog'
import { updateMetaTags } from 'utils/utils'
import { Hero } from './hero'
import { Works } from './works'
import styles from './Home.module.scss'

const Home = () => {
	updateMetaTags({})

	return (
		<>
			<AidropJoinDialog />
			<Box className={styles.home}>
				<Box className={styles.blanket}></Box>
				<Hero />
				<Works />
			</Box>
		</>
	)
}

export default Home
