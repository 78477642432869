import _ from 'lodash'
import {
	OutlinedInput,
	MenuItem,
	FormControl,
	Select,
	Typography,
	FormHelperText,
	Box
} from '@mui/material'
import PropTypes from 'prop-types'
import styles from './SelectBox.module.scss'
import { getFileUrl } from 'utils/url.util'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			backgroundColor: '#000000',
			borderRadius: 0
		}
	},
	disableScrollLock: true
}

export const SelectBox = ({
	error,
	value = '',
	setValue,
	options = [],
	placeholder,
	label,
	variant = 'black',
	displayField = 'name',
	valueField = 'id',
	imageField,
	...others
}) => {
	const renderOptionName = () => {
		const option = options.find(el => el[valueField] === value)
		return option[displayField]
	}

	return (
		<FormControl
			className={`${styles.select_box} ${error ? styles.error : ''}`}
			size="small"
		>
			{label && (
				<Typography component={'h2'} className="label">
					{label}
				</Typography>
			)}
			<Select
				displayEmpty
				value={value}
				className={styles[variant]}
				onChange={e => {
					setValue(e.target.value)
				}}
				input={<OutlinedInput />}
				renderValue={selected =>
					selected ? renderOptionName(options, selected) : placeholder
				}
				MenuProps={MenuProps}
				classes={{ select: styles.mui_select }}
				inputProps={{ 'aria-label': 'Without label' }}
				sx={{ color: value === '' ? '#7b7b7b' : '#fff' }}
				{...others}
			>
				{options &&
					options?.map(option => {
						return (
							<MenuItem
								key={`item-${option[valueField]}`}
								value={option[valueField]}
								className={styles.select_box_menu_item}
							>
								{_.isNil(imageField) ? (
									option[displayField]
								) : (
									<Box className={styles.image_variant}>
										<Typography>{option[displayField]}</Typography>
										<img alt="" src={getFileUrl(option[imageField])} />
									</Box>
								)}
							</MenuItem>
						)
					})}
			</Select>
			{error && <FormHelperText>{error}</FormHelperText>}
		</FormControl>
	)
}

SelectBox.propTypes = {
	error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	setValue: PropTypes.func,
	options: PropTypes.array,
	placeholder: PropTypes.string,
	label: PropTypes.string,
	variant: PropTypes.string,
	displayField: PropTypes.string,
	valueField: PropTypes.string,
	imageField: PropTypes.string,
	others: PropTypes.object
}
