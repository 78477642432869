import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginDialogOpen } from '@/components/header/signInSlice'
import { Grid, Box, Typography } from '@mui/material'
import styles from './GoScoreSection.module.scss'
import evolution from 'assets/images/homepage/goscore-evol.png'
import fingerprint from 'assets/images/homepage/fingerprint.webp'

export const GoScoreSection = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { isAuthenticated } = useSelector(state => state.signIn)

	const handleGoToExplore = () => {
		if (!isAuthenticated) dispatch(setLoginDialogOpen(true))
		else {
			navigate('/explore')
		}
	}

	return (
		<>
			<Grid>
				<Box className={styles.goscore}>
					<Grid container className={styles.goscore_desc}>
						<img alt="" src={evolution.src} />
						<Box className={styles.hero}>
							<img alt="" src={fingerprint.src} />
						</Box>
						<Box className={styles.explanation}>
							<h2>The Fingerprint of Success</h2>
							<Typography>
								Film.io&lsquo;s Go Score is a game-changing tool that provides
								unparalleled market validation for film projects. This
								innovative metric aggregates real-time audience feedback and
								data-driven insights to assess a project&lsquo;s potential
								success.
							</Typography>
							<Typography>
								By leveraging the Go Score, filmmakers and studios can
								confidently make informed decisions, identifying projects with
								the highest chances of resonating with audiences and achieving
								box office success. It&lsquo;s the ultimate tool for navigating
								the entertainment landscape, turning intuition into data-backed
								certainty.
							</Typography>
							<button onClick={handleGoToExplore}>Login / Signup</button>
						</Box>
					</Grid>
				</Box>
			</Grid>
		</>
	)
}

export default GoScoreSection
