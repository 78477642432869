export const createImage = url =>
	new Promise((resolve, reject) => {
		const image = new Image()
		image.addEventListener('load', () => resolve(image))
		image.addEventListener('error', error => reject(error))
		image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
		image.src = url.startsWith('https://filmio-assets')
			? `${url}?not-from-cache-please`
			: url
	})

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(imageSrc, pixelCrop, cropSize) {
	const image = await createImage(imageSrc)
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')

	if (!ctx || !pixelCrop) {
		return null
	}

	// set canvas size to match the bounding box
	canvas.width = cropSize.width
	canvas.height = cropSize.height

	ctx.fillStyle = 'transparent'
	ctx.fillRect(0, 0, cropSize.width, cropSize.height)
	const offsetWidth = (pixelCrop.width - cropSize.width) / 2
	let dx = pixelCrop?.x - offsetWidth
	const offsetHeight = (pixelCrop.height - cropSize.height) / 2
	const dy = pixelCrop?.y - offsetHeight
	if (pixelCrop?.width < cropSize?.width) dx = Math.abs(offsetWidth)
	ctx.drawImage(image, dx, dy, pixelCrop?.width, pixelCrop?.height)

	// croppedAreaPixels values are bounding box relative
	// extract the cropped image using these values
	const data = ctx.getImageData(0, 0, cropSize.width, cropSize.height)

	// paste generated rotate image at the top left corner
	ctx.putImageData(data, 0, 0)

	// As Base64 string
	// return canvas.toDataURL('image/jpeg');

	// As a blob
	return new Promise((resolve, _reject) => {
		canvas.toBlob(
			file => {
				resolve(file)
			},
			'image/jpg',
			1
		)
	})
}
