import { useEffect } from 'react'
import { Box, Button, Grid, Icon, Typography } from '@mui/material'
import LinearProgress, {
	linearProgressClasses
} from '@mui/material/LinearProgress'
import { styled } from '@mui/material/styles'
import { LoadMask, LightTooltip } from '@/components/common'
import useSuperfan from './useSuperfan'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { abbreviateNumberFixed, zeroPad } from 'utils/utils'
import { SuperFanDialog } from './superFanDialog'
import SuperFanImages from 'assets/images/superFan1'
import images from 'assets/images'
import perkProgramIcon from 'assets/images/superfan/perkProgramIcon.svg'
import { PerkProgramDialog } from './perkProgramDialog'
import styles from './Superfan.module.scss'

const Superfan = () => {
	const {
		user,
		project,
		fanLevel,
		showMask,
		projectId,
		fgrEarned,
		openDialog,
		setOpenDialog,
		openPerkDialog,
		updateSuperfanLevel,
		setOpenPerkDialog,
		disabledClaimBtn,
		superfanUpdatedEvent,
		isAuthenticated,
		handleClaim
	} = useSuperfan()

	const nextLevel = fanLevel?.nextLevel ?? fanLevel
	const levelsNumber = fanLevel?.nextLevel
		? fanLevel?.nextLevel?.id - 1
		: fanLevel?.id
	const superfanUnlocked = levelsNumber !== 0
	const numberBeforehash = zeroPad(fanLevel?.userSuperFanRank ?? 0, 1)
	const numberAfterhash = zeroPad(fanLevel?.totalSuperFans ?? 0, 1)
	const superfanImg = !superfanUnlocked
		? SuperFanImages.locked.src
		: fanLevel?.image

	const BorderLinearProgress = styled(LinearProgress)(() => ({
		height: 5,
		width: '100%',
		position: 'relative',
		marginTop: 8,
		marginBottom: 4,
		borderRadius: 5,
		[`&.${linearProgressClasses.colorPrimary}`]: {
			background: '#000000'
		},
		[`&>.${linearProgressClasses.barColorPrimary}`]: {
			background:
				nextLevel?.id !== 5
					? `#000 linear-gradient(90deg, ${nextLevel?.barColor} 75%, #01ACBD 80%, #000000);`
					: `#000 linear-gradient(90deg, ${nextLevel?.barColor}, #01ACBD);`
		}
	}))

	const getLevelMetricInfo = (info, id) => {
		return (
			<Box className={`${styles.level_metric} ${styles.complete}`} key={id}>
				<LightTooltip
					arrow
					placement="top"
					className="tooltip"
					title={info.toolTip}
				>
					<p>
						{info.iconEl}
						{info.message}
					</p>
				</LightTooltip>
			</Box>
		)
	}

	const renderLevelMetrics = () => {
		const projectMetrics = fanLevel?.projectMetrics
		const stakingMetric = {
			message: `${abbreviateNumberFixed(projectMetrics?.stakesTotal, 1)} FAN`,
			toolTip: "How many tokens you've staked to this project",
			iconEl: <Icon baseClassName="fa-kit" className="fa-fan-stake" />
		}
		const reviewMetric = {
			message: `${Number(projectMetrics?.review?.rating).toFixed(1)}`,
			toolTip: 'Your review for this project',
			iconEl: <Icon baseClassName="fas" className="fa-star-half-stroke" />
		}
		const shareMetric = {
			message: `${projectMetrics?.sharesCount}`,
			toolTip: "How many times you've shared this project",
			iconEl: <Icon baseClassName="fal" className="fa-share" />
		}
		const followingMetric = {
			message: 'Following',
			toolTip: projectMetrics?.isFollowing
				? "You're following this project"
				: "You're not following this project",
			iconEl: projectMetrics?.isFollowing ? (
				<FavoriteIcon />
			) : (
				<FavoriteBorderIcon />
			)
		}

		return [
			getLevelMetricInfo(stakingMetric, 1),
			getLevelMetricInfo(reviewMetric, 2),
			getLevelMetricInfo(shareMetric, 3),
			getLevelMetricInfo(followingMetric, 4)
		]
	}

	const renderSuperfanMetrics = () => {
		const message = `${nextLevel.totalPoints}/${nextLevel.pointsThreshold} pts`
		const toolTip = 'Your points progress on this level'
		const iconEl = <Icon baseClassName="fa-kit" className="fa-fan-stake" />

		return (
			<Box className={`${styles.level_metric}`}>
				<LightTooltip arrow placement="top" className="tooltip" title={toolTip}>
					<p>
						{iconEl}
						{message}
					</p>
				</LightTooltip>
			</Box>
		)
	}

	const handleOpenDialog = () => {
		setOpenDialog(true)
	}

	useEffect(() => {
		if (superfanUpdatedEvent?.projectId === projectId) {
			updateSuperfanLevel()
		}
	}, [superfanUpdatedEvent])

	return (
		<>
			{showMask ? (
				<LoadMask />
			) : (
				<Grid container direction="row" spacing={3}>
					<Grid item container spacing={3}>
						<Grid item md={6} className={styles.project_impact}>
							<Grid item md={8}>
								<Box className={styles.impact_info}>
									<Typography className={styles.impact_header}>
										Your Impact on this Project
									</Typography>
									<Box
										className={superfanUnlocked ? styles.ranks : styles.locked}
									>
										<img
											className={styles.rank_img}
											src={superfanImg}
											alt=""
											onClick={handleOpenDialog}
										/>
										<Typography
											className={styles.levels_section}
											onClick={handleOpenDialog}
										>
											Superfan{' '}
											<span>
												{fanLevel?.completed ? fanLevel?.name : 'Locked'}
											</span>
										</Typography>
										<Box className={styles.separator}></Box>
										{fanLevel && (
											<span
												className={`${styles.next_level} ${
													superfanUnlocked ? styles.graytext : ''
												}`}
											>
												#{numberBeforehash} of {numberAfterhash}
											</span>
										)}
										<img
											src={perkProgramIcon.src}
											alt=""
											className={`${styles.perk_program_icon} ${
												project?.isOnPerksProgram
													? ''
													: styles.is_on_perks_program
											}`}
											onClick={() => {
												setOpenPerkDialog(true)
											}}
										/>
									</Box>
									<SuperFanDialog
										open={openDialog}
										handleClose={setOpenDialog}
									/>
									<PerkProgramDialog
										{...{
											isOnPerksProgram: project?.isOnPerksProgram,
											projectName: project?.title,
											open: openPerkDialog,
											handleClose: () => {
												setOpenPerkDialog(false)
											}
										}}
									/>
									<Box className={styles.progressBox}>
										<BorderLinearProgress
											variant="determinate"
											value={nextLevel?.progress ?? 0}
										/>
										{fanLevel?.nextLevel && nextLevel?.id !== 5 && (
											<span className={styles.levelText}>
												{nextLevel?.name?.toLowerCase()}
											</span>
										)}
									</Box>
									<Box className={styles.superfan}>
										<Box className={styles.level_metrics}>
											{nextLevel?.pointsThreshold && renderSuperfanMetrics()}
										</Box>
									</Box>
									<Box className={styles.superfan}>
										<Box className={styles.level_metrics}>
											{fanLevel?.projectMetrics && renderLevelMetrics()}
										</Box>
									</Box>
									<Box className={styles.benefits}>
										<Typography>
											{fanLevel?.nextLevel && (
												<span>
													<i className="fal fa-info-circle"></i>{' '}
													{nextLevel?.name}
												</span>
											)}
											{fanLevel?.nextLevel && (
												<span>
													{' '}
													benefits include a{' '}
													<strong>
														<span>+{fanLevel?.nextLevel?.bonus}%</span> bonus
													</strong>
												</span>
											)}
											{fanLevel?.id === 1 && (
												<span>
													{' '}
													and a <em>Superfan Badge</em>.
												</span>
											)}
										</Typography>
									</Box>
								</Box>
							</Grid>
							<Grid item md={4} xs={12}>
								<Box className={styles.top}>
									<Box className={styles.fgr_info}>
										<Box>
											<Typography component={'h2'}>Your Earned FGR</Typography>
											<Typography
												component={'h3'}
												className={styles.earned_fan}
											>
												<img src={images.newToken} alt="token" />
												{fgrEarned} FAN
											</Typography>
										</Box>
										<Box>
											<Typography>
												{isAuthenticated ? user?.fgrRate : 0}% FGR
												{fanLevel?.completed && (
													<>
														{' '}
														+ <span>{fanLevel?.bonus}% Bonus</span>
													</>
												)}
											</Typography>
											<Button onClick={handleClaim} disabled={disabledClaimBtn}>
												Claim
											</Button>
										</Box>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	)
}

export default Superfan
