export const getAgregatedRecordsService = (data: any) => ({
	method: 'post',
	url: '/aggregator',
	data,
	autoexec: false
})

export const getNewsreelRecordsService = (data: any) => ({
	method: 'post',
	url: '/aggregator/newsreel',
	data,
	autoexec: false
})
