import _ from 'lodash'
import { createRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { setTrailerInfo } from '../projectDetailSlice'
import { getConfiguration } from 'services/configuration.service'
import { getUserReviewService } from 'services/review.service'
import useAxiosFetch from 'hooks/useAxiosFetch'
import useAxios from 'hooks/useAxios'
import { getVideoInfoFromUrl } from 'utils/video.utils'

const useDashboardTab = () => {
	const { project, trailerInfo } = useSelector(state => state.projectDetail)
	const { user: signInUser, isAuthenticated } = useSelector(
		state => state.signIn
	)
	const setSearchParams = useSearchParams()[1]
	const dispatch = useDispatch()
	const { axiosService } = useAxios()

	const [storyHeight, setStoryHeight] = useState(0)
	const [showMore, setShowMore] = useState(false)

	const [configuration, setConfiguration] = useState({})
	const [userReview, setUserReview] = useState(null)

	const [{ loading }] = useAxiosFetch(
		getConfiguration(),
		(response, error) => {
			if (!error) {
				setConfiguration(response)
			}
		},
		!isAuthenticated
	)

	const fetchUserReview = projectId => {
		if (!isAuthenticated) {
			return
		}
		axiosService(getUserReviewService(projectId), (response, error) => {
			if (!error) {
				setUserReview(response)
			}
		})
	}

	const showMoreToggle = () => {
		setShowMore(!showMore)
	}

	const storyRef = createRef()

	const buildCategoriesLine = () =>
		project?.genres?.map(g => (
			<li id={`genre-${g.id}`} key={`genre-${g.id}`}>
				{g.name}
			</li>
		))

	const isOwnerSignedIn = () => {
		return _.get(project, ['user', 'id']) === signInUser?.id
	}

	const handleSocialClick = tab => () => {
		setSearchParams({ tab })
	}

	const isDraft = project.stageId === 1

	useEffect(() => {
		setStoryHeight(storyRef?.current?.clientHeight)
	})

	useEffect(() => {
		if (!_.isNil(project?.id)) {
			fetchUserReview(project?.id)
		}
		const getTrailerInfo = async () => {
			const info = await getVideoInfoFromUrl(project?.trailer)
			dispatch(setTrailerInfo(info))
		}
		getTrailerInfo()
		return () => {
			dispatch(setTrailerInfo(null))
		}
	}, [project])

	const disabledSocialBtns = isDraft || isOwnerSignedIn()
	const disableShareLink =
		configuration?.enableProjectShare === 0 || project?.stageId !== 4

	return {
		loading,
		isDraft,
		project,
		userReview,
		storyRef,
		showMore,
		trailerInfo,
		storyHeight,
		disableShareLink,
		disabledSocialBtns,
		buildCategoriesLine,
		handleSocialClick,
		showMoreToggle
	}
}

export default useDashboardTab
