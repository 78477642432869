import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useHeader from '../useHeader'
import { RootState } from '@/store/store'
import { setSuperfanWelcomeDialogOpen } from '../signInSlice'

export const useWelcomeDialog = () => {
	const { superfanWelcomeDialogOpen: open } = useSelector(
		(state: RootState) => state.signIn
	)
	const dispatch = useDispatch()
	const { showConfetti } = useHeader()

	const handleClose = () => {
		dispatch(setSuperfanWelcomeDialogOpen(false))
	}

	useEffect(() => {
		if (open) {
			showConfetti()
		}
	}, [open])

	return {
		open,
		handleClose
	}
}
