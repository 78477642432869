import { Grid, Box, Icon } from '@mui/material'
import styles from './Works.module.scss'
import PersonaSwitcher from './personaSwitcher/PersonaSwitcher'
import NewsSlider from './newsSlider/NewsSlider'
import TokenSection from './token/TokenSection'
import SuperfanSection from './superfan/SuperfanSection'
import DaoSection from './fdao/DaoSection'
import GoScoreSection from './goscore/GoScoreSection'
import footerImage from 'assets/images/homepage/footer-image.webp'
import curlyImage from 'assets/images/homepage/curly.webp'
import dots1Image from 'assets/images/homepage/dots1.png'
import dots2Image from 'assets/images/homepage/dots2.png'
import triangleImage from 'assets/images/homepage/triangle.webp'
import rectangleImage from 'assets/images/homepage/rectangle.webp'

const Works = () => {
	return (
		<>
			<Grid container className={styles.works}>
				<h2>How it Works</h2>
				<Box className={styles.divider}></Box>
				<PersonaSwitcher />
			</Grid>
			<Grid container className={styles.nws}>
				<Box className={styles.news}>
					<h2 className={styles.left}>In the Press</h2>
					<Box className={styles.divider}></Box>
					<NewsSlider />
				</Box>
			</Grid>
			<Grid container className={styles.sprfn}>
				<Box className={styles.superfan}>
					<h2 className={styles.left}>Superfan</h2>
					<Box className={styles.divider}></Box>
					<SuperfanSection />
				</Box>
			</Grid>
			<Grid container className={styles.tkns}>
				<Box className={styles.token}>
					<h2 className={styles.left}>The FAN Token</h2>
					<Box className={styles.divider}></Box>
					<TokenSection />
				</Box>
			</Grid>
			<Grid container className={styles.fdao}>
				<Box className={styles.dao}>
					<h2 className={styles.left}>The Film.io DAO</h2>
					<Box className={styles.divider}></Box>
					<DaoSection />
				</Box>
			</Grid>
			<Grid container className={styles.gscre}>
				<Box className={styles.goscore}>
					<h2 className={styles.left}>The Go Sore™</h2>
					<Box className={styles.divider}></Box>
					<GoScoreSection />
				</Box>
			</Grid>
			<Grid container className={styles.callout}>
				<Box>
					<p>
						Join 75,000+ Visionaries building
						<br />
						the Hollywood of Tomorrow.
					</p>
				</Box>
			</Grid>
			<Grid>
				<Box className={styles.footer}>
					<Box>
						<ul>
							<li>
								<a href="mailto:info@film.io" title="Contact us">
									info@film.io
								</a>
							</li>
							<li>
								<a
									href="https://film.us12.list-manage.com/subscribe?u=671c0bf1c1805e740ae36d507&id=7e37c9f834"
									title="sign up now to stay up to date!"
								>
									Sign up for our Newsletter
								</a>
							</li>
							<li>
								<a
									href="https://www.facebook.com/filmioofficial"
									title="Follow us on Facebook"
								>
									<Icon baseClassName="fa-brands" className="fa-facebook-f" />
								</a>
								<a
									href="https://www.instagram.com/filmio_official/"
									title="Follow us on Instagram"
								>
									<Icon baseClassName="fa-brands" className="fa-instagram" />
								</a>
								<a
									href="https://t.me/officialfilmio"
									title="Join us on Telgram"
								>
									<Icon baseClassName="fa-brands" className="fa-telegram" />
								</a>
							</li>
						</ul>
					</Box>
					<Box>
						<ul>
							<li>
								<a href="https://hackenproof.com/filmio" title="">
									Bug Bounty Program
								</a>
							</li>
							<li>
								<a
									href="http://film.io/wp-content/uploads/2022/07/Filmio-Black-Paper-July-14-2022.pdf"
									title=""
								>
									Blackpaper
								</a>
							</li>
							<li>
								<a href="https://help.film.io/knowledgebase/" title="">
									Knowledgebase
								</a>
							</li>
							<li>
								<a href="https://film.io/blog/" title="">
									Blog
								</a>
							</li>
						</ul>
					</Box>
					<Box>
						<ul>
							<li>
								<a href="https://film.io/who-we-are/" title="">
									Who We Are
								</a>
							</li>
							<li>
								<a href="https://film.io/roadmap/" title="">
									Roadmap
								</a>
							</li>
							<li>
								<a href="https://film.io/dao/" title="">
									DAO
								</a>
							</li>
							<li>
								<a href="https://film.io/tokens/" title="">
									Tokens
								</a>
							</li>
						</ul>
					</Box>
					<Box>
						<ul className={styles.plain}>
							<li>
								<a href="https://film.io/privacy-policy/" title="">
									Privacy Policy
								</a>
							</li>
							<li>
								<a href="https://film.io/code-of-conduct/" title="">
									Code of Conduct
								</a>
							</li>
							<li>
								<a href="https://film.io/cookie-policy/" title="">
									Cookie Policy
								</a>
							</li>
							<li>
								<a href="https://film.io/terms-of-use/" title="">
									Terms of Use
								</a>
							</li>
							<li>
								<a href="https://film.io/token-terms-conditions/" title="">
									Token Terms & Conditions
								</a>
							</li>
						</ul>
					</Box>
				</Box>
			</Grid>
			<Box className={styles.end_box}>
				<img alt="" src={footerImage.src} />
			</Box>
			<Box className={styles.curly}>
				<img alt="" src={curlyImage.src} />
			</Box>
			<Box className={styles.dots2}>
				<img alt="" src={dots2Image.src} />
			</Box>
			<Box className={styles.dots1}>
				<img alt="" src={dots1Image.src} />
			</Box>
			<Box className={styles.triangle}>
				<img alt="" src={triangleImage.src} />
			</Box>
			<Box className={styles.rectangle}>
				<img alt="" src={rectangleImage.src} />
			</Box>
		</>
	)
}

export default Works
