import axios from 'axios'

export const followUnfollowProject = async (
	projectId: string,
	follow = false
) => {
	const path = follow ? 'unfollow' : 'follow'
	const res = await axios.post(`/${path}/project`, { projectId })
	return res
}

export const followUnfollowUser = async (userId: any, follow = false) => {
	const path = follow ? 'unfollow' : 'follow'
	const res = await axios.post(`/${path}/user`, { userId })
	return res
}

export const getFollowingFollowerUsers = (params: any) => ({
	method: 'get',
	url: '/follow/users',
	params
})

export const getFollowers = (userId: string) => ({
	method: 'get',
	url: '/follow/followers',
	params: { userId }
})

export const getFollowing = (userId: string, autoexec = false) => ({
	method: 'get',
	url: '/follow/following',
	params: { userId },
	autoexec
})

export const getFollowedProjectsByUserId = (userId: string) => ({
	method: 'get',
	url: `/follow/followedProjects/${userId}`
})
