import { Box, Dialog, Icon, IconButton, Typography } from '@mui/material'
// import { SocialMediaShareMenu } from './socialMediaShareMenu'
import { RingAvatar } from '@/components/common/customAvatar'
import loadingChest from 'assets/images/dao-tiers/loading.svg'
import { useDaoTierLevelChangeDialog } from './useDaoTierLevelChangeDialog'
import styles from './DaoTierLevelChangeDialog.module.scss'

export const DaoTierLevelChangeDialog = () => {
	const {
		user,
		currentTier,
		handleClose,
		tierBenefits,
		nextTierBenefits,
		daoTierLevelingChangeDialogOpen
	} = useDaoTierLevelChangeDialog()

	const renderBenefits = () =>
		tierBenefits
			?.filter(b => {
				const isAidropUser =
					Number(user?.isAirdropFan) === 1 ||
					Number(user?.isAirdropCreator) === 1
				if (!isAidropUser && b.highlight) return false
				return true
			})
			.map(b => {
				return (
					<Typography key={b.id} className={b.highlight ? styles.green : ''}>
						{b.description}
					</Typography>
				)
			})

	const renderNextBenefits = () =>
		nextTierBenefits
			?.filter(b => {
				const isAidropUser =
					Number(user?.isAirdropFan) === 1 ||
					Number(user?.isAirdropCreator) === 1
				if (!isAidropUser && b.highlight) return false
				return true
			})
			.map(b => {
				return (
					<Typography key={b.id} className={b.highlight ? styles.green : ''}>
						<span>
							<img alt="" src={loadingChest.src} />
						</span>{' '}
						{b.description}
					</Typography>
				)
			})

	const nextTierName = user?.nextTierName ?? ''
	return (
		<Dialog
			open={daoTierLevelingChangeDialogOpen}
			disableScrollLock={false}
			className={styles.dialog}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
		>
			<IconButton onClick={handleClose}>
				<Icon baseClassName="fas" className="fa-xmark" />
			</IconButton>
			<Box className={styles.container}>
				<Typography component={'h2'}>
					You just reached the <strong>{currentTier} Membership Tier</strong>
				</Typography>
				<Box className={styles.info}>
					<RingAvatar
						{...{
							avatar: user?.avatar,
							ring: user?.daoTierRingImg,
							displayName: user?.displayName
						}}
					/>
					<Typography>
						Congrats on reaching the{' '}
						<strong>{currentTier} Membership Tier</strong> of the Film.io DAO!
						You can find what privileges you get as a {currentTier} Tier member
						below!
					</Typography>
				</Box>
				<Box className={styles.center}>
					<Box className={styles.left}>
						<Typography component={'h6'}>WHAT YOU’VE RECEIVED!</Typography>
						{renderBenefits()}
					</Box>
					<Box className={styles.right}>
						<Typography component={'h6'}>
							WHAT’S NEXT? THE {nextTierName.toUpperCase()} TIER!
						</Typography>
						{renderNextBenefits()}
					</Box>
				</Box>
				<Box className={styles.buttons}>
					<a
						href="https://help.film.io/knowledgebase/film.io-dao/what-are-dao-tiers"
						target="_blank"
						rel="noreferrer"
					>
						Learn about DAO Tiers
						<Icon
							baseClassName="fal"
							className="fa-arrow-up-right-from-square"
						/>
					</a>
				</Box>
			</Box>
		</Dialog>
	)
}
