import _ from 'lodash'
import { useEffect, useState } from 'react'
import {
	getProjectStakeService,
	unstakeService
} from 'services/stake.service'
import { useDispatch, useSelector } from 'react-redux'
import { notificationExtTypes } from '@/components/common'
import {
	setStakes
} from '@/components/projectDetail/projectDetailSlice'
import useNotification from 'hooks/useNotification'
import useHeader from '@/components/header/useHeader'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { reloadRecentActivity } from '@/components/profile/walletTab/walletTabSlice'
import { Mixpanel } from 'services/mixpanel.service'
import { StakingContract } from 'utils/blockchain/functions'
import {
	setCreatePlatformWalletDialogOpen,
	setFrontTransactionsInProgress,
	setLoginDialogOpen
} from '@/components/header/signInSlice'
import useCommon from 'hooks/useCommon'
import useProjectCommon from '@/components/project/useProjectCommon'

const useStakesTable = () => {
	const { user, isAuthenticated } = useSelector(state => state.signIn)
	const { project, stakes, projectId } = useSelector(
		state => state.projectDetail
	)
	const { stakeCompletedEvent } = useSelector(state => state.websocket)
	const {
		showNotificationExtended,
		completeNotificationExtended,
		closeNotificationExtended
	} = useNotification()
	const dispatch = useDispatch()
	const { reloadUserInfo } = useHeader()
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const { checkNcNoMagic } = useCommon()
	const [count, setCount] = useState(0)
	const [pagin, setPagin] = useState({
		page: 0,
		limit: 10,
		offset: 0,
		orderBy: 'createdAt',
		order: 'desc'
	})
	const { reloadProject } = useProjectCommon()
	const { stakeUnstakedEvent } = useSelector(
		state => state.websocket
	)

	const { order, orderBy, limit, offset } = pagin

	const afterFetchTransactions = (response, error) => {
		if (!error) {
			setCount(response.count)
			dispatch(setStakes(response.rows))
		}
		hideMask()
	}

	const getProjectStakes = () => {
		showMask()
		if (!_.isNil(projectId))
			axiosService(
				getProjectStakeService(projectId, {
					limit,
					offset,
					order,
					orderBy
				}),
				afterFetchTransactions
			)
	}

	const afterUnstake = async (stake, error) => {
		if (!error) {
			getProjectStakes()
			dispatch(reloadRecentActivity())
			dispatch(setFrontTransactionsInProgress(true))
			hideMask()
			showNotificationExtended({
				projectTitle: project?.title,
				total: stake?.amount,
				type: notificationExtTypes.UNSTAKING
			})
			const blockchainHash = await StakingContract.unstakeTokens(
				stake?.blockchainHash,
				stake?.unstakeId
			)
			// unstakeBlockchain(stake?.id, blockchainHash)
			dispatch(setFrontTransactionsInProgress(false))

			if (_.isNil(blockchainHash)) {
				Mixpanel.track('transaction_failed_unstake_evt', {
					distinct_id: user.id,
					displayName: user.displayName,
					username: user.username
				})
			} else {
				Mixpanel.track('transaction_successful_unstake_evt', {
					distinct_id: user.id,
					displayName: user.displayName,
					username: user.username,
					amount: stake?.amount,
					transactionId: stake?.id,
					blockchainHash: stake?.blockchainHash
				})
			}
		}
		getProjectStakes()
		reloadProject()
	}

	const unstake = transactionId => {
		showMask()
		axiosService(unstakeService({ transactionId }), afterUnstake)
	}

	const handleRequestSort = (_event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setPagin({
			...pagin,
			order: isAsc ? 'desc' : 'asc',
			orderBy: property
		})
	}

	const handleChangePage = (_event, newPage) => {
		const offset = Number(newPage) * Number(pagin.limit)
		setPagin({
			...pagin,
			offset,
			page: newPage
		})
	}

	const handleChangeRowsPerPage = event => {
		setPagin({
			...pagin,
			page: 0,
			offset: 0,
			limit: event.target.value
		})
	}

	const handleUnstake =
		(transactionId, amount, stakedProject = null) =>
			() => {
				if (checkNcNoMagic()) {
					dispatch(setCreatePlatformWalletDialogOpen(true))
				} else {
					const projectTitle = stakedProject?.title ?? project?.title
					showNotificationExtended({
						projectTitle,
						total: amount,
						type: notificationExtTypes.UNSTAKING
					})
					unstake(transactionId)
				}
			}

	const handleClickTransaction = () => {
		if (isAuthenticated) return
		dispatch(setLoginDialogOpen(true))
	}

	useEffect(() => {
		if (!_.isNil(projectId)) getProjectStakes()
	}, [pagin, projectId])

	useEffect(() => {
		if (!_.isNil(stakeUnstakedEvent) && stakeUnstakedEvent?.projectId === project?.id) {
			!_.isNil(stakeUnstakedEvent?.blockchainHash)
				? completeNotificationExtended()
				: closeNotificationExtended()
			reloadUserInfo()
			dispatch(reloadRecentActivity())
			getProjectStakes()
		}
	}, [stakeUnstakedEvent])

	const emptyRows =
		pagin.page > 0 ? Math.max(0, (1 + pagin.page) * pagin.limit - count) : 0

	return {
		user,
		pagin,
		count,
		order,
		stakes,
		project,
		orderBy,
		emptyRows,
		projectId,
		isAuthenticated,
		stakeCompletedEvent,
		handleChangeRowsPerPage,
		handleClickTransaction,
		handleRequestSort,
		getProjectStakes,
		handleChangePage,
		handleUnstake,
	}
}

export default useStakesTable
