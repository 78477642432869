export const loginService = (data: any) => ({
	method: 'post',
	url: '/user/login',
	data
})

export const socialLoginService = (data: any) => ({
	method: 'post',
	url: '/user/socialLogin',
	data,
	autoexec: true
})

export const registerService = (data: any) => ({
	method: 'post',
	url: '/user',
	data
})

export const claimUserRewardsService = (data: any) => ({
	method: 'post',
	url: '/user/claimRewards',
	data
})

export const authTokenSaleUserService = (data: any) => ({
	method: 'post',
	url: '/user/authTokenSaleUser',
	data
})

export const authNcWalletService = (data: any) => ({
	method: 'post',
	url: '/user/authNcWallet',
	data
})

export const getMagicStatusService = (data: any) => ({
	method: 'post',
	url: '/user/magicStatus',
	data
})

export const checkDeviceStatusService = () => ({
	method: 'get',
	url: '/user/deviceStatus'
})

export const updateUserSaleEmailService = (data: any) => ({
	method: 'put',
	url: '/user/saleEmail',
	data
})

export const resetPasswordService = (data: any) => ({
	method: 'put',
	url: '/user/resetPassword',
	data,
	autoexec: false
})

export const airdropRecoverService = (data: any) => ({
	method: 'put',
	url: '/user/airdropJoin',
	data,
	autoexec: false
})

export const resetPasswordIsValidService = ({ userId, token }: any) => ({
	method: 'get',
	url: `/user/resetPassword/${userId}/${token}`,
	autoexec: true
})

export const airdropIsValidService = ({ userId, token }: any) => ({
	method: 'get',
	url: `/user/airdropJoin/${userId}/${token}`,
	autoexec: userId && token
})

export const verifyAirdropJoin = (email: any) => ({
	method: 'post',
	url: '/user/airdropJoin',
	data: { email },
	autoexec: false
})
