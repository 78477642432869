'use client'

import { useEffect, useState } from 'react'
import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { ScrollArea } from '@/components/ui/scroll-area'
import Image from 'next/image'
import { Link, Link as RouterLink } from 'react-router-dom'
import { IoIosArrowForward } from 'react-icons/io'
import {
	FiCompass,
	FiLifeBuoy,
	FiLogOut,
	FiUser,
	FiArrowRight
} from 'react-icons/fi'
import { LuNewspaper, LuFilm, LuSettings, LuBadgeCheck } from 'react-icons/lu'
import { CiSearch } from 'react-icons/ci'
import { FaRegSquareFull } from 'react-icons/fa6'
import { FanTierInfoMenu } from '../profileMenu/fanTierInfoMenu'
import useProfileMenu from '../profileMenu/useProfileMenu'
import useHeader from '../../../header/useHeader'
import useProfileMenuItems from '../profileMenu/profileMenuItems/useProfileMenuItems'
import useNotifications from '@/components/header/notifications/useNotifications'
import { useSuperfanSection } from '@/components/profile/dashboardTab/superfanSection/useSuperfanSection'
import useFanTierInfoMenu from '../profileMenu/fanTierInfoMenu/useFanTierInfoMenu'
import { cn } from '../../../../lib/utils'
import { UserKingIcon } from '../../sidebar'
import { useKyc } from '../../hooks/useKyc'
import { AvatarFilmio } from '@/components/common-ui'
import { FiBell } from 'react-icons/fi'
import medal from '@/assets/images/medal-2.svg'
import MobileNotifications from '../notifications/mobileNotifications'

const adminBaseUrl = process.env.NEXT_PUBLIC_ADMIN_URL
const helpBaseUrl = process.env.NEXT_PUBLIC_HELP_BASE_URL

const NavItem = ({
	icon: Icon,
	label,
	to,
	onClick
}: {
	icon: any
	label: string
	to: string
	onClick: () => void
}) => (
	<RouterLink
		to={to}
		className="hover:bg-highlight/70 flex cursor-pointer items-center gap-3 rounded-md p-2 text-white hover:text-muted-foreground"
		onClick={onClick}
	>
		<Icon className="h-5 w-5" />
		<div className="text-lg">{label}</div>
	</RouterLink>
)

export default function MobileProfileSheet({
	triggerClassName
}: {
	triggerClassName: string
}) {
	const [open, setOpen] = useState(false)
	const [isShowingTierInfo, setIsShowingTierInfo] = useState(false)
	const [isShowingNotifs, setIsShowingNotifs] = useState(false)
	const { signInUser } = useProfileMenu()
	const {
		jwt,
		tierName,
		tierImage,
		profileHref,
		tierProgress,
		nextTierName,
		upgradeAvailable,
		handleClickUpgradme,
		onOpenProfileMenu,
		toggleSearchMobile
	} = useProfileMenuItems()

	const { daoTierInfo } = useFanTierInfoMenu()
	const { totalPoints } = useSuperfanSection()
	const threshold = daoTierInfo?.nextDaoTier?.pointsThreshold
	const { notificationsCount } = useNotifications()

	const { showKyc } = useKyc()
	const { handleLogout } = useHeader()

	const {
		avatar,
		isVerified,
		isAdmin,
		username,
		daoTierRingImg,
		projectCount,
		displayName,
		nextTierId
	} = signInUser

	const handleClose = () => setOpen(false)

	function logout() {
		handleLogout()
		handleClose()
	}

	const isTokenSaleWithoutAccess =
		signInUser?.isTokenSale === 1 && signInUser?.appAccess === 0

	const closeDaoTierInfo = () => {
		setIsShowingTierInfo(false)
	}

	const closeNotifs = () => {
		setIsShowingNotifs(false)
	}

	useEffect(() => {
		if (open) {
			onOpenProfileMenu()
		}
	}, [open])

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetTrigger asChild>
				<Button
					variant="outline"
					size="icon"
					className={cn(
						'relative overflow-hidden rounded-full bg-transparent hover:bg-transparent focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-opacity-50',
						triggerClassName
					)}
				>
					<AvatarFilmio
						ringImage={daoTierRingImg}
						avatar={avatar}
						displayName={displayName}
						className="h-[40px] w-[40px]"
					/>
				</Button>
			</SheetTrigger>
			<SheetContent side="right" className="w-full max-w-full sm:max-w-md">
				<ScrollArea className="h-full">
					{isShowingNotifs ? (
						<MobileNotifications closeNotifs={closeNotifs} />
					) : isShowingTierInfo ? (
						<FanTierInfoMenu closeDaoTierInfo={closeDaoTierInfo} />
					) : (
						<div className="flex flex-col space-y-4 py-4">
							<div className="flex items-center space-x-4">
								<Link
									className="relative"
									to={profileHref}
									onClick={handleClose}
								>
									<AvatarFilmio
										ringImage={daoTierRingImg}
										avatar={avatar}
										displayName={displayName}
										className="h-[50px] w-[50px]"
									/>
								</Link>
								<div>
									<div className="flex items-center space-x-2">
										<RouterLink
											to={profileHref}
											className="text-base font-bold text-white"
											onClick={handleClose}
										>
											{displayName}
										</RouterLink>
										{isVerified === 0 ? (
											<Button
												className="h-6 rounded-md bg-primary p-2 text-background"
												onClick={showKyc}
											>
												<LuBadgeCheck className="mr-0 h-4 w-4" />
												Verify
											</Button>
										) : isVerified === 1 ? (
											<Button className="h-6 w-6 rounded-md bg-primary p-2 text-background">
												<LuBadgeCheck className="h-4 w-4" />
											</Button>
										) : null}

										<Button
											className={`h-6 p-0 px-2 text-background hover:bg-secondary hover:text-white ${
												notificationsCount === 0
													? 'bg-[#222] text-[#fff]'
													: 'bg-primary text-background'
											}`}
											onClick={() => {
												setIsShowingNotifs(true)
											}}
										>
											<FiBell />
											{notificationsCount} <FiArrowRight className="ml-1" />
										</Button>
									</div>
									<p className="text-sm font-medium text-icons opacity-50">
										{tierName} DAO Member
									</p>
								</div>
							</div>

							<button
								className="rounded-lg bg-highlight p-4 text-white"
								onClick={() => {
									setIsShowingTierInfo(true)
								}}
							>
								{upgradeAvailable && nextTierId ? (
									<div
										className="flex items-center justify-between"
										onClick={handleClickUpgradme}
									>
										<div>
											<h4 className="text-lg font-semibold">
												Click to Upgrade
											</h4>
											<p>
												Congrats on reaching{' '}
												<strong>{nextTierName} DAO!</strong>
											</p>
										</div>
										<Image
											src={tierImage}
											width={36}
											height={36}
											alt="Tier Avatar"
											className="overflow-hidden rounded-full"
										/>
									</div>
								) : (
									<div className="flex items-center justify-start gap-4">
										<div>
											<Image
												src={tierImage}
												width={36}
												height={36}
												alt="Tier Image"
												className="overflow-hidden rounded-full"
											/>
										</div>
										<div className="flex w-full items-center justify-between gap-4">
											<div className="w-full flex-col items-center justify-between">
												<div className="flex items-center justify-between text-base">
													{tierName} DAO Member
												</div>
												<div className="relative mt-2 h-[8px] w-full rounded-full bg-black">
													<div
														className="h-full rounded-full bg-gradient-to-r from-primary to-black"
														style={{ width: tierProgress ?? 0 }}
													></div>
												</div>
												{nextTierId ? (
													<>
														<p className="mt-2 flex text-sm">
															<img
																className="w-[16px]"
																src={medal?.src}
																alt="Superfan Points Icon"
															/>{' '}
															<strong className="ml-1">
																{' '}
																{totalPoints}/{threshold}
															</strong>
														</p>
														<p className="flex text-sm text-gray-400">
															Next Tier: <strong>{nextTierName}</strong>
														</p>
													</>
												) : (
													<>
														<p className="mt-2 flex text-sm">
															<strong className="ml-1">
																You have reached the final tier!
															</strong>
														</p>
													</>
												)}
											</div>
											<div className="flex items-center justify-center">
												<IoIosArrowForward className="h-6 w-6" />
											</div>
										</div>
									</div>
								)}
							</button>

							<div className="space-y-2">
								<NavItem
									icon={UserKingIcon}
									label="Fan Dashboard"
									to="/fan-dashboard"
									onClick={handleClose}
								/>

								<NavItem
									icon={FiCompass}
									label="Explore Projects"
									to="/explore"
									onClick={handleClose}
								/>
								<NavItem
									icon={LuNewspaper}
									label="Newsreels"
									to="/newsreel"
									onClick={handleClose}
								/>
								{projectCount > 0 && (
									<NavItem
										icon={LuFilm}
										label="Your Projects"
										to={`/profile/${username}?tab=2`}
										onClick={handleClose}
									/>
								)}
								<NavItem
									icon={LuSettings}
									label="Settings"
									to="/settings"
									onClick={handleClose}
								/>
								<NavItem
									icon={CiSearch}
									label="Search"
									to="#"
									onClick={() => {
										toggleSearchMobile()
										handleClose()
									}}
								/>

								<RouterLink to="/newProject" onClick={handleClose}>
									<Button className="w-full bg-primary text-background hover:bg-secondary hover:text-white">
										Submit Your Project <FiArrowRight className="ml-2" />
									</Button>
								</RouterLink>

								{Number(isAdmin) === 1 && (
									<NavItem
										icon={FaRegSquareFull}
										label="FET Management"
										to="/tile-management"
										onClick={handleClose}
									/>
								)}
								{jwt && Number(isAdmin) === 1 && (
									<NavItem
										icon={FiUser}
										label="Platform Administration"
										to={`${adminBaseUrl}/admin/auth/${jwt}`}
										onClick={() => {
											window.open(`${adminBaseUrl}/admin/auth/${jwt}`, '_blank')
											handleClose()
										}}
									/>
								)}
								<NavItem
									icon={FiLogOut}
									label={isTokenSaleWithoutAccess ? 'Disconnect' : 'Logout'}
									to="#"
									onClick={logout}
								/>
								<NavItem
									icon={FiLifeBuoy}
									label="Get Help"
									to={`${helpBaseUrl}/knowledgebase/welcome/what-is-film.io`}
									onClick={() => {
										window.open(
											`${helpBaseUrl}/knowledgebase/welcome/what-is-film.io`,
											'_blank'
										)
										handleClose()
									}}
								/>
							</div>
						</div>
					)}
				</ScrollArea>
			</SheetContent>
		</Sheet>
	)
}
