/* eslint-disable no-unused-vars */
'use client'

import React, { useState, useRef, useCallback, useEffect } from 'react'
import {
	useQueryState,
	parseAsBoolean,
	useQueryStates,
	parseAsString
} from 'nuqs'
import {
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	PointerSensor,
	closestCenter,
	useSensor,
	useSensors
} from '@dnd-kit/core'
import {
	SortableContext,
	sortableKeyboardCoordinates,
	useSortable,
	verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { format } from 'date-fns'
import axios from 'axios'
import Cropper from 'react-easy-crop'

// Import your UI components
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { Checkbox } from '@/components/ui/checkbox'
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow
} from '@/components/ui/table'
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage
} from '@/components/ui/form'
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle
} from '@/components/ui/dialog'
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue
} from '@/components/ui/select'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import {
	Popover,
	PopoverContent,
	PopoverTrigger
} from '@/components/ui/popover'
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Calendar } from '@/components/ui/calendar'
import { Slider } from '@/components/ui/slider'

import {
	Calendar as CalendarIcon,
	GripVertical,
	ImageIcon,
	XIcon
} from 'lucide-react'
import { cn } from '@/lib/utils'
import { humanDateDiff } from '@/utils/date.utils'

// Import services and hooks
import {
	addTileService,
	updateTileService,
	updateTilesService
} from '@/services/tile.service'
import useTileManagement from './useTileManagement'
import { showNotification } from '../common/notification/notificationSlice'
import { LoadMask } from '../common'
import getCroppedImg from './utils/getCropImage'
import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'
import styles from './tileManagement.module.scss'
import FilmioDaoContract from 'utils/blockchain/functions/filmioDao'
import {
	addDaoProposalService,
	updateDaoProposalService
} from '../../services/daoProposal.service'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { getProjectListService } from '../../services/project.service'
import useLoadingMask from '../../hooks/useLoadingMask'
import { logger } from '@/utils/logger'

type Tile = {
	id: string
	startDate: string
	endDate: string | null
	name: string
	link: string | null
	clicks: number
	socialShares: string
	tileTypeId: number
	content: string
	projectId: string
	cta: string | null
	showOnFanDashboard: boolean
}

type TileData = {
	scheduled: Tile[]
	active: Tile[]
	expired: Tile[]
}

const TILE_TYPES = {
	update: {
		id: 1,
		name: 'Update',
		slug: 'update'
	},
	link: {
		id: 2,
		name: 'Link',
		slug: 'link'
	},
	'dao-proposal': {
		id: 3,
		name: 'DAO Proposal',
		slug: 'dao-proposal'
	},
	'buy-now': {
		id: 4,
		name: 'Buy Now',
		slug: 'buy-now'
	}
} as const

const getTileType = (tileTypeId: number) => {
	switch (tileTypeId) {
		case 1:
			return TILE_TYPES['update'].name
		case 2:
			return TILE_TYPES['link'].name
		case 3:
			return TILE_TYPES['dao-proposal'].name
		case 4:
			return TILE_TYPES['buy-now'].name
		default:
			return 'Unknown'
	}
}

// Form validation schema
const createFormSchema = (_type: String) => {
	return z.object({
		owner: z.string().optional(),
		projectId: z.string().optional(),
		name: z
			.string()
			.min(3, 'Title must be at least 3 characters')
			.max(100, 'Title must not exceed 100 characters'),
		content: z
			.string()
			.min(10, 'Content must be at least 10 characters')
			.max(180, 'Content must not exceed 180 characters'),
		link: z.string().url('Please enter a valid URL').or(z.literal('')),
		cta: z
			.string()
			.min(2, 'CTA must be at least 2 characters')
			.max(20, 'CTA must not exceed 20 characters'),
		startDate: z.date({
			required_error: 'Start date is required'
		}),
		endDate: z.date().optional(),
		showOnFanDashboard: z.boolean().default(false),
		poster: z.any().optional()
	})
}

type TileFormValues = z.infer<ReturnType<typeof createFormSchema>>

function TileManagementHeader({
	isReorderMode,
	toggleReorderMode,
	onSearch,
	setCreateFetOpts
}: {
	isReorderMode: boolean
	toggleReorderMode: () => void
	onSearch: (value: string) => void
	setCreateFetOpts: ({
		create,
		type
	}: {
		create: boolean
		type: string
	}) => void
}) {
	return (
		<div className="mb-4 flex items-center justify-end gap-4">
			<Button variant="white" onClick={toggleReorderMode}>
				{isReorderMode ? 'Done Reordering' : 'Reorder'}
			</Button>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button>New FET</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent className="text-white hover:text-white/80">
					<DropdownMenuItem
						onSelect={() =>
							setCreateFetOpts({ create: true, type: TILE_TYPES.link.slug })
						}
					>
						Link
					</DropdownMenuItem>
					<DropdownMenuItem
						onSelect={() =>
							setCreateFetOpts({ create: true, type: TILE_TYPES.update.slug })
						}
					>
						Update
					</DropdownMenuItem>
					<DropdownMenuItem
						onSelect={() =>
							setCreateFetOpts({
								create: true,
								type: TILE_TYPES['buy-now'].slug
							})
						}
					>
						Buy Now
					</DropdownMenuItem>
					<DropdownMenuItem
						onSelect={() =>
							setCreateFetOpts({
								create: true,
								type: TILE_TYPES['dao-proposal'].slug
							})
						}
					>
						DAO Proposal
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
			<Input
				placeholder="Search tiles..."
				className="w-64"
				onChange={e => onSearch(e.target.value)}
			/>
		</div>
	)
}

// TilePreview Component
function TilePreview({
	title,
	content,
	cta,
	image,
	setCroppedImage,
	fileName
}: {
	title: string
	content: string
	cta: string
	image: string | null
	setCroppedImage: (file: File) => void
	fileName: string | null
}) {
	const [crop, setCrop] = useState({ x: 0, y: 0 })
	const [zoom, setZoom] = useState(1)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null)

	const onCropComplete = useCallback((_, croppedAreaPixels: any) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	useEffect(() => {
		const updateCroppedImage = async () => {
			if (image && croppedAreaPixels && fileName) {
				try {
					const croppedImage = await getCroppedImg(image, croppedAreaPixels)
					if (croppedImage) {
						const fileCropped = new File([croppedImage], fileName, {
							type: 'image/jpeg'
						})
						setCroppedImage(fileCropped)
					}
				} catch (error) {
					logger.error('Error cropping image:', error)
					showNotification({
						type: 'error',
						message: 'Failed to process image. Please try again.'
					})
				}
			}
		}
		updateCroppedImage()
	}, [zoom, croppedAreaPixels, image, fileName, setCroppedImage])

	return (
		<div className="sm:px-6">
			<div className="flex flex-col rounded-lg bg-slate-800 p-6">
				<div className="relative flex aspect-video w-full items-center justify-center overflow-hidden rounded-lg bg-gray-700">
					{image ? (
						<Cropper
							image={image}
							crop={crop}
							zoom={zoom}
							maxZoom={4}
							minZoom={1}
							aspect={16 / 9}
							restrictPosition={true}
							onCropChange={setCrop}
							onCropComplete={onCropComplete}
							onZoomChange={setZoom}
							objectFit="contain"
							showGrid={false}
							zoomWithScroll={false}
							classes={{
								mediaClassName: 'aspect-video',
								cropAreaClassName: '!w-full !h-full !border-none'
							}}
						/>
					) : (
						<ImageIcon className="h-10 w-10" />
					)}
				</div>
				<div className="w-full pt-4">
					<Slider
						min={1}
						max={4}
						step={0.01}
						value={[zoom]}
						onValueChange={([value]) => setZoom(value)}
					/>
				</div>
				<h2 className="mb-2 truncate text-xl font-semibold text-white">
					{title || 'Film.io Tutorials'}
				</h2>
				<p className="mb-4 text-base text-slate-300">
					{content || 'Learn how to use the Film.io platform...'}
				</p>
				<Button className="self-end">{cta || 'Watch Now'}</Button>
			</div>
		</div>
	)
}

const modules = {
	toolbar: [
		[{ header: [1, 2, false] }],
		['bold', 'italic', 'underline', 'strike'],
		[
			{ list: 'ordered' },
			{ list: 'bullet' },
			{ indent: '-1' },
			{ indent: '+1' }
		],
		['link', 'image'],
		['clean']
	]
}

const formats = [
	'header',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'bullet',
	'indent',
	'link',
	'image'
]

export function CreateFETDialog() {
	const [fetModalOpts, setFetModalOpts] = useQueryStates({
		create: parseAsBoolean.withDefault(false),
		type: parseAsString.withDefault(TILE_TYPES.update.slug)
	})
	const [editFetParams, setEditFetParams] = useQueryStates({
		edit: parseAsBoolean.withDefault(false),
		id: parseAsString.withDefault('')
	})

	const tileType = Object.values(TILE_TYPES).find(
		tile => tile.slug === fetModalOpts.type
	)!
	const { tiles, getTiles } = useTileManagement(false)
	const mode = editFetParams.edit ? 'Edit' : 'Create'
	const isOpened = fetModalOpts.create || editFetParams.edit
	const fileInputRef = useRef<HTMLInputElement>(null)
	const [file, setFile] = useState<string | null>(null)
	const [image, setImage] = useState<string | null>(null)

	const { showMask, hideMask } = useLoadingMask()

	const isDaoProposal = fetModalOpts.type === TILE_TYPES['dao-proposal'].slug

	const form = useForm<TileFormValues>({
		resolver: zodResolver(createFormSchema(fetModalOpts.type)),
		defaultValues: {
			owner: '',
			projectId: '',
			name: '',
			content: '',
			cta: '',
			link: '',
			startDate: new Date(),
			endDate: undefined,
			showOnFanDashboard: false,
			poster: null
		}
	})

	// Reset form when editing a tile
	useEffect(() => {
		if (editFetParams.edit && editFetParams.id) {
			const currentTile = [
				...tiles.scheduled,
				...tiles.active,
				...tiles.expired
			].find(tile => tile.id === editFetParams.id)

			if (currentTile) {
				form.reset({
					projectId: currentTile.projectId,
					name: currentTile.name,
					content: currentTile.content,
					cta: currentTile.cta || '',
					link: currentTile.link || '',
					startDate: new Date(currentTile.startDate),
					endDate: currentTile.endDate
						? new Date(currentTile.endDate)
						: undefined,
					showOnFanDashboard: currentTile.showOnFanDashboard,
					poster: currentTile.poster
				})
			}
		}
	}, [editFetParams.edit, editFetParams.id, tiles, form])

	const handleOpenChange = (open: boolean) => {
		if (!open) {
			form.reset()
			setFile(null)
			setImage(null)
			if (mode === 'Edit') {
				setEditFetParams({ edit: false, id: '' })
			} else {
				setFetModalOpts({ create: false, type: null })
			}
		}
	}

	const updateTile = async (values: TileFormValues) => {
		try {
			const response = await axios(
				updateTileService({
					...values,
					tileTypeId: tileType.id,
					id: editFetParams.id
				})
			)

			if (response.status === 200) {
				showNotification({
					message: 'Tile updated successfully',
					type: 'success'
				})
				handleOpenChange(false)
			}
		} catch (error) {
			showNotification({
				type: 'error',
				message: 'Failed to update tile. Please try again.'
			})
		}
	}

	const createTile = async (values: TileFormValues) => {
		try {
			const response = await axios(
				addTileService({
					...values,
					tileTypeId: tileType.id
				})
			)

			if (response.status === 200) {
				showNotification({
					message: 'Tile created successfully',
					type: 'success'
				})
				handleOpenChange(false)
			}
		} catch (error) {
			showNotification({
				type: 'error',
				message: 'Failed to create tile. Please try again.'
			})
		}
	}

	const addDaoProposal = async (values: TileFormValues) => {
		try {
			const response = await axios(
				addDaoProposalService({
					...values,
					description: values.content
				})
			)

			await updateDaoProposal(response?.id, response?.ipfsHash, values.endDate)
		} catch (error) {
			logger.error('Error creating DAO proposal:', error)
		}
	}

	const updateDaoProposal = async (
		id: string,
		ipfsHash: any,
		endDateParam: Date
	) => {
		try {
			const startDate = Math.floor(Date.now() / 1000) + 300
			const endDate = Math.floor(endDateParam.getTime() / 1000)
			const duration = endDate - startDate
			const { hash, proposalId } = await FilmioDaoContract.createProposal(
				ipfsHash,
				startDate,
				duration,
				id
			)
			await axios(
				updateDaoProposalService({
					id,
					blockchainHash: hash,
					blockchainId: proposalId
				})
			)
		} catch (error) {
			logger.error('Error creating DAO proposal:', error)
		}
	}

	const handleCreateOrEditTile = async (values: TileFormValues) => {
		try {
			showMask()
			const isEdit = mode === 'Edit'
			const isDaoProposal =
				fetModalOpts.type === TILE_TYPES['dao-proposal'].slug
			const action = isDaoProposal
				? addDaoProposal(values)
				: isEdit
					? updateTile(values)
					: createTile(values)

			await action
			await getTiles()
		} catch (error) {
			showNotification({
				type: 'error',
				message: `Failed to ${mode.toLowerCase()} tile. Please try again.`
			})
		} finally {
			hideMask()
		}
	}

	const [{ response }] = useAxiosFetch(getProjectListService())
	const projects = response?.data || []

	const startDate = form.watch('startDate')
	const endDate = form.watch('endDate')

	return (
		<Dialog open={isOpened} onOpenChange={handleOpenChange}>
			<DialogContent className="min-h-0 max-w-5xl gap-0 sm:px-12 sm:py-6">
				<DialogHeader>
					<DialogTitle className="text-left text-white">
						{mode} an FET
					</DialogTitle>
				</DialogHeader>
				<div className="mt-6 grid gap-4 md:grid-cols-2">
					<div>
						<Form {...form}>
							<form className="space-y-4">
								{/* Project Selection */}
								{!isDaoProposal && (
									<FormField
										control={form.control}
										name="projectId"
										render={({ field }) => (
											<FormItem>
												<FormControl>
													<Select
														onValueChange={field.onChange}
														value={field.value}
													>
														<SelectTrigger className="h-10 border-none bg-background">
															<SelectValue placeholder="Select a Project" />
														</SelectTrigger>
														<SelectContent className="bg-background">
															{projects.map((project: any) => (
																<SelectItem
																	key={project.id}
																	value={project.id}
																	className="max-w-[430px] text-ellipsis text-txt"
																>
																	{project.title}
																</SelectItem>
															))}
														</SelectContent>
													</Select>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								)}

								{/* Owner Field */}
								{isDaoProposal && (
									<FormField
										control={form.control}
										name="owner"
										render={({ field }) => (
											<FormItem>
												<FormControl>
													<Input
														placeholder="Owner"
														className={cn(
															'bg-background',
															form.formState.errors.owner &&
																'border-red-500 focus-visible:ring-red-500' // Add red border if error exists
														)}
														{...field}
													/>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								)}

								{/* Title Field */}
								<FormField
									control={form.control}
									name="name"
									render={({ field }) => (
										<FormItem>
											<FormControl>
												<Input
													placeholder="Film.io Tutorials"
													className={cn(
														'w-full bg-background',
														form.formState.errors.name &&
															'border-red-500 focus-visible:ring-red-500' // Add red border if error exists
													)}
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								{/* Content Field */}
								{isDaoProposal ? (
									<FormField
										control={form.control}
										name="content"
										render={({ field }) => (
											<FormItem>
												<FormControl>
													<ReactQuill
														className={styles.editor}
														modules={modules}
														formats={formats}
														{...field}
													/>
												</FormControl>
												<div className="flex flex-row items-center">
													<FormMessage />
													<div className="ml-auto text-sm text-muted-foreground">
														{field.value?.length || 0}/180
													</div>
												</div>
											</FormItem>
										)}
									/>
								) : (
									<FormField
										control={form.control}
										name="content"
										render={({ field }) => (
											<FormItem>
												<FormControl>
													<Textarea
														placeholder="Learn how to use the Film.io platform..."
														className={cn(
															'h-[80px] resize-none bg-background',
															form.formState.errors.content &&
																'border-red-500 focus-visible:ring-red-500'
														)}
														maxLength={180}
														{...field}
													/>
												</FormControl>
												<div className="flex flex-row items-center">
													<FormMessage />
													<div className="ml-auto text-sm text-muted-foreground">
														{field.value?.length || 0}/180
													</div>
												</div>
											</FormItem>
										)}
									/>
								)}

								{/* Image Upload Field */}
								<FormField
									control={form.control}
									name="poster"
									render={({ field: { value, onChange, ...fieldProps } }) => (
										<FormItem>
											<FormControl>
												<div className="relative">
													<Input
														{...fieldProps}
														ref={fileInputRef}
														type="file"
														accept="image/*"
														className={cn(
															'bg-background file:mr-4 file:rounded-md file:border-0 file:bg-white file:px-4 file:py-1 file:text-sm file:font-semibold file:text-primary-foreground hover:file:bg-white/90',
															file && 'text-ellipsis'
														)}
														onChange={event => {
															const file = event.target.files?.[0]
															if (file) {
																setFile(file.name)
																setImage(URL.createObjectURL(file))
																onChange(file)
															}
														}}
													/>
													{file && (
														<Button
															type="button"
															variant="ghost"
															size="icon"
															className="absolute right-2 top-1/2 -translate-y-1/2"
															onClick={() => {
																setFile(null)
																setImage(null)
																onChange(null)
																if (fileInputRef.current) {
																	fileInputRef.current.value = ''
																}
															}}
														>
															<XIcon className="h-4 w-4" />
															<span className="sr-only">Remove file</span>
														</Button>
													)}
												</div>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>

								{/* URL Field */}
								{!isDaoProposal && (
									<FormField
										control={form.control}
										name="link"
										render={({ field }) => (
											<FormItem>
												<FormControl>
													<Input
														placeholder="https://youtube.com/playlist?list=..."
														className="bg-background"
														{...field}
													/>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								)}

								{/* CTA Field */}
								{!isDaoProposal && (
									<FormField
										control={form.control}
										name="cta"
										render={({ field }) => (
											<FormItem>
												<FormControl>
													<Input
														placeholder="Watch now"
														className={cn(
															'bg-background',
															form.formState.errors.cta &&
																'border-red-500 focus-visible:ring-red-500' // Add red border if error exists
														)}
														{...field}
													/>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								)}

								{/* Dashboard Visibility Checkbox */}
								<FormField
									control={form.control}
									name="showOnFanDashboard"
									render={({ field }) => (
										<FormItem className="flex items-center space-x-2 space-y-0 py-2">
											<FormControl>
												<Checkbox
													checked={field.value}
													onCheckedChange={field.onChange}
												/>
											</FormControl>
											<FormLabel className="text-sm font-medium leading-none">
												Show on Creator and Fan Dashboards
											</FormLabel>
											<FormMessage />
										</FormItem>
									)}
								/>

								{/* Date Fields */}
								<div className="grid grid-cols-2 gap-4">
									<FormField
										control={form.control}
										name="startDate"
										render={({ field }) => (
											<FormItem className="flex flex-col">
												<FormLabel>Start Date*</FormLabel>
												<Popover>
													<PopoverTrigger asChild>
														<Button
															variant="outline"
															className={cn(
																'h-10 justify-start bg-background text-left font-normal text-txt',
																form.formState.errors.startDate &&
																	'border-red-500 focus-visible:ring-red-500'
															)}
														>
															<CalendarIcon className="mr-2 h-4 w-4" />
															{field.value ? (
																format(field.value, 'MM/dd/yy')
															) : (
																<span>Pick a date</span>
															)}
														</Button>
													</PopoverTrigger>
													<PopoverContent className="w-auto p-0">
														<Calendar
															mode="single"
															selected={field.value}
															onSelect={field.onChange}
															initialFocus
														/>
													</PopoverContent>
												</Popover>
												<FormMessage />
											</FormItem>
										)}
									/>

									<FormField
										control={form.control}
										name="endDate"
										render={({ field }) => (
											<FormItem className="flex flex-col">
												<FormLabel>End Date</FormLabel>
												<Popover>
													<PopoverTrigger asChild>
														<Button
															variant="outline"
															className={cn(
																'h-10 justify-start bg-background text-left font-normal text-txt'
															)}
														>
															<CalendarIcon className="mr-2 h-4 w-4" />
															{field.value ? (
																format(field.value, 'MM/dd/yy')
															) : (
																<span>Pick a date</span>
															)}
														</Button>
													</PopoverTrigger>
													<PopoverContent className="w-auto p-0">
														<Calendar
															mode="single"
															selected={field.value}
															onSelect={field.onChange}
															initialFocus
														/>
													</PopoverContent>
												</Popover>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
							</form>

							<div className="py-2 text-sm font-medium leading-none text-txt">
								<span>Tile Duration:</span>{' '}
								{endDate
									? humanDateDiff(
											startDate.toISOString(),
											endDate.toISOString()
										)
									: 'indefinite'}
							</div>
						</Form>
					</div>

					{/* Preview Section */}
					<div>
						<TilePreview
							title={form.watch('name')}
							content={form.watch('content')}
							cta={form.watch('cta')}
							image={image}
							setCroppedImage={file => form.setValue('poster', file)}
							fileName={file}
						/>
					</div>
				</div>

				{/* Submit Button */}
				<div className="flex justify-end pt-4">
					<Button
						type="submit"
						disabled={form.formState.isSubmitting}
						onClick={form.handleSubmit(handleCreateOrEditTile)}
					>
						{form.formState.isSubmitting
							? 'Saving...'
							: mode === 'Edit'
								? 'Update Tile'
								: 'Create Tile'}
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	)
}

type Tab = 'active' | 'scheduled' | 'expired'

function TabNavigation({
	tiles,
	currentTab,
	onTabChange
}: {
	tiles: TileData
	currentTab: Tab
	onTabChange: (_value: Tab) => void
}) {
	return (
		<Tabs
			value={currentTab}
			className="w-full"
			onValueChange={value => onTabChange(value as Tab)}
		>
			<TabsList>
				<TabsTrigger value="active">Active ({tiles.active.length})</TabsTrigger>
				<TabsTrigger value="scheduled">
					Scheduled ({tiles.scheduled.length})
				</TabsTrigger>
				<TabsTrigger value="expired">
					Expired ({tiles.expired.length})
				</TabsTrigger>
			</TabsList>
		</Tabs>
	)
}

function SortableTableRow({
	tile,
	isReorderMode,
	handleEditTile,
	handleDuplicateTile,
	handleExpireTile
}: {
	tile: Tile
	isReorderMode: boolean
	handleEditTile: () => void
	handleDuplicateTile: () => void
	handleExpireTile: () => void
}) {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging
	} = useSortable({ id: tile.id })

	const style = {
		transform: CSS.Transform.toString(transform),
		transition
	}

	const formatDate = (dateString: string) => {
		return format(new Date(dateString), 'MM/dd/yy')
	}

	return (
		<TableRow
			ref={setNodeRef}
			style={style}
			className={cn(isDragging && 'bg-gray-800')}
		>
			{isReorderMode && (
				<TableCell>
					<div {...attributes} {...listeners} className="cursor-move">
						<GripVertical className="h-5 w-5" />
					</div>
				</TableCell>
			)}
			<TableCell className="max-w-14 overflow-hidden text-ellipsis break-all text-left">
				{tile.name}
			</TableCell>
			<TableCell>{getTileType(tile.tileTypeId)}</TableCell>
			<TableCell>{formatDate(tile.startDate)}</TableCell>
			<TableCell>{tile.endDate ? formatDate(tile.endDate) : 'NEVER'}</TableCell>
			<TableCell>{tile.clicks}</TableCell>
			<TableCell>
				<div className="flex space-x-2">
					<Button size="sm" onClick={handleEditTile}>
						Edit
					</Button>
					<Button size="sm" onClick={handleExpireTile}>
						Archive
					</Button>
					<Button size="sm" onClick={handleDuplicateTile}>
						Duplicate
					</Button>
				</div>
			</TableCell>
		</TableRow>
	)
}

export default function TileManagement() {
	const [currentTab, setCurrentTab] = useQueryState('tab', {
		defaultValue: 'active'
	})
	const [, setCreateFetOpts] = useQueryStates({
		create: parseAsBoolean.withDefault(false),
		type: parseAsString.withDefault(TILE_TYPES.update.slug)
	})
	const [, setEditFetParams] = useQueryStates({
		edit: parseAsBoolean.withDefault(false),
		id: parseAsString.withDefault('')
	})
	const [isReorderMode, setIsReorderMode] = useState(false)
	const [searchTerm, setSearchTerm] = useState('')
	const { tiles, loading, setTiles, getTiles } = useTileManagement()

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates
		})
	)

	const handleDuplicateTile = useCallback(
		(tile: any) => async () => {
			try {
				const { ...tileWithoutId } = tile
				const response = await axios(addTileService(tileWithoutId))
				showNotification({ message: response?.data?.message })
				getTiles()
			} catch (error) {
				showNotification({
					type: 'error',
					message: 'Failed to duplicate tile'
				})
			}
		},
		[getTiles]
	)

	const handleExpireTile = useCallback(
		async (id: string) => {
			try {
				const response = await axios(
					updateTileService({
						id,
						isExpired: true
					})
				)

				if (response.status === 200) {
					showNotification({ message: 'Tile expired successfully' })
					getTiles()
				}
			} catch (error) {
				showNotification({
					type: 'error',
					message: 'Failed to expire tile'
				})
			}
		},
		[getTiles]
	)

	const handleUpdateTilesOrder = useCallback(async (tiles: Tile[]) => {
		try {
			const tilesToUpdate = tiles.map((tile, index) => ({
				...tile,
				order: index
			}))

			const response = await axios(
				updateTilesService({
					tiles: tilesToUpdate
				})
			)

			if (response.status === 200) {
				showNotification({ message: 'Tiles reordered successfully' })
			}
		} catch (error) {
			showNotification({
				type: 'error',
				message: 'Failed to reorder tiles'
			})
		}
	}, [])

	const handleDragEnd = useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event

			if (over && active.id !== over.id) {
				const currentTiles = tiles[currentTab as Tab]
				const oldIndex = currentTiles.findIndex(tile => tile.id === active.id)
				const newIndex = currentTiles.findIndex(tile => tile.id === over.id)

				const newItems = [...currentTiles]
				const [movedItem] = newItems.splice(oldIndex, 1)
				newItems.splice(newIndex, 0, movedItem)

				const updatedTiles = {
					...tiles,
					[currentTab]: newItems
				}

				setTiles(updatedTiles)
				handleUpdateTilesOrder(updatedTiles[currentTab as Tab])
			}
		},
		[tiles, currentTab, setTiles, handleUpdateTilesOrder]
	)

	if (loading) {
		return <LoadMask />
	}

	const filteredTiles = {
		...tiles,
		[currentTab]: tiles[currentTab as Tab].filter(
			tile =>
				tile.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
				tile.link?.toLowerCase().includes(searchTerm.toLowerCase())
		)
	}

	return (
		<div className="p-4 text-white">
			<h1 className="mb-6 text-4xl font-bold">FET Management</h1>
			<div className="mb-6 space-y-6">
				<TabNavigation
					tiles={tiles}
					currentTab={currentTab as Tab}
					onTabChange={setCurrentTab}
				/>
				<TileManagementHeader
					isReorderMode={isReorderMode}
					toggleReorderMode={() => setIsReorderMode(!isReorderMode)}
					onSearch={setSearchTerm}
					setCreateFetOpts={setCreateFetOpts}
				/>
			</div>

			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragEnd={handleDragEnd}
			>
				<Table>
					<TableHeader>
						<TableRow>
							{isReorderMode && <TableHead className="w-8"></TableHead>}
							<TableHead>Title</TableHead>
							<TableHead>Type</TableHead>
							<TableHead>Begins</TableHead>
							<TableHead>Ends</TableHead>
							<TableHead>Clicks</TableHead>
							<TableHead>Actions</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						<SortableContext
							items={filteredTiles[currentTab as Tab].map(tile => tile.id)}
							strategy={verticalListSortingStrategy}
						>
							{filteredTiles[currentTab as Tab].map(tile => (
								<SortableTableRow
									key={tile.id}
									tile={tile}
									isReorderMode={isReorderMode}
									handleEditTile={() =>
										setEditFetParams({ edit: true, id: tile.id })
									}
									handleDuplicateTile={handleDuplicateTile(tile)}
									handleExpireTile={() => handleExpireTile(tile.id)}
								/>
							))}
						</SortableContext>
					</TableBody>
				</Table>
			</DndContext>

			<CreateFETDialog />
		</div>
	)
}
