import { Button } from '@/components/ui/button'
import { NewsreelItem } from './components/NewsreelItem'

import { ActivityItem } from './components/ActivityItem'
import { ReviewItem } from './components/ReviewItem'
import { StakeItem } from './components/StakeItem'
import { FeedItem } from './types'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import { FaChevronDown } from 'react-icons/fa'
import { useCallback, useEffect, useState } from 'react'
import useLoadingMask from '@/hooks/useLoadingMask'
import useAxios from '@/hooks/useAxios'
import { getNewsreelRecordsService } from '@/services/aggregator.service'

const NEWSREEL_FILTERS = ['All projects', 'Following']

interface NewsreelState {
	items: any[]
	page: number
}

/**
 * Hook for handling newsreel feed with manual pagination and followed/all projects filter
 * @param followedOnly - Whether to show only followed projects
 */
const useNewsreelElements = (followedOnly: boolean) => {
	const [state, setState] = useState<NewsreelState>({ items: [], page: 0 })
	const [hasMore, setHasMore] = useState(true)
	const { showMask, hideMask } = useLoadingMask()
	const { axiosServiceSync } = useAxios()

	const fetchItems = useCallback(
		async (pageNumber: number) => {
			showMask()
			const data = await axiosServiceSync(
				getNewsreelRecordsService({
					page: pageNumber,
					followedOnly
				})
			)
			setState(prevState => ({
				page: pageNumber,
				items: pageNumber === 1 ? data : [...prevState.items, ...data]
			}))
			setHasMore(data?.length > 0)
			hideMask()
		},
		[followedOnly]
	) // Now we only depend on followedOnly

	const onDeleteItem = useCallback((id: string) => {
		setState(prevState => ({
			...prevState,
			items: prevState.items.filter(item => item.record.id !== id)
		}))
	}, [])

	// Initial load and when followedOnly changes
	useEffect(() => {
		setState({ items: [], page: 0 })
		setHasMore(true)
		fetchItems(1)
	}, [followedOnly, fetchItems])

	return {
		items: state.items,
		loadMore: () => fetchItems(state.page + 1),
		hasMore,
		onDeleteItem
	}
} // TODO: Move to a independent file

export function Newsreel() {
	const [filter, setFilter] = useState(NEWSREEL_FILTERS[0])
	const { items, loadMore, hasMore, onDeleteItem } = useNewsreelElements(
		filter === 'Following'
	)
	const feedItems = items as FeedItem[]

	const renderFeedItem = (item: FeedItem) => {
		switch (item.type) {
			case 'newsreel':
				return (
					<NewsreelItem
						key={item.record.id}
						item={item}
						onDelete={onDeleteItem}
					/>
				)
			case 'review':
				return <ReviewItem key={item.record.id} item={item} />
			case 'stake':
				return <StakeItem key={item.record.id} item={item} />
			default:
				return <ActivityItem key={item.record.id} item={item} />
		}
	}

	return (
		<div className="custom-scrollbar max-h-[800px] w-full max-w-2xl overflow-auto rounded-md bg-[#2196F3]/10 p-4">
			<div className="flex w-full items-center justify-between">
				<div className="text-lg font-bold">Latest From the Feed</div>

				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button
							variant="ghost"
							size="sm"
							className="flex items-center space-x-1"
						>
							<span>{filter}</span>
							<FaChevronDown />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent className="bg-background text-white">
						{NEWSREEL_FILTERS.map((ele, index) => {
							return (
								<DropdownMenuItem
									key={index}
									onSelect={() => setFilter(ele)}
									className="hover:bg-[#000]"
								>
									{ele}
								</DropdownMenuItem>
							)
						})}
					</DropdownMenuContent>
				</DropdownMenu>
			</div>

			<div className="mt-4 space-y-4 text-base">
				{feedItems.map(renderFeedItem)}
			</div>
			{hasMore && (
				<Button
					className="mt-4 h-12 w-full bg-blue-600 text-white hover:bg-blue-700"
					variant="default"
					onClick={loadMore}
				>
					More
				</Button>
			)}
		</div>
	)
}
