import {
	Popover,
	PopoverContent,
	PopoverTrigger
} from '@/components/ui/popover'
import { Button } from '@/components/ui/button'
import { SmileIcon } from 'lucide-react'
import { cn } from '@/lib/utils'
import { Reaction } from '../../types'
import REACTIONS from '../../../../../../../../assets/images/reactions'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger
} from '../../../../../../../ui/tooltip'
import { useState } from 'react'

interface ReactionsPopoverProps {
	isOpen: boolean
	onOpenChange: (_open: boolean) => void
	reactions: Reaction[]
	onReactionSelect: (_id: number) => void
	className?: string
}

export const ReactionsPopover = ({
	isOpen,
	onOpenChange,
	reactions,
	onReactionSelect,
	className
}: ReactionsPopoverProps) => {
	const [activeTooltip, setActiveTooltip] = useState<number | null>(null)

	return (
		<TooltipProvider delayDuration={100}>
			<Popover open={isOpen} onOpenChange={onOpenChange}>
				<PopoverTrigger asChild>
					<Button
						variant="ghost"
						size="icon"
						className={cn(
							'text-gray-400 hover:bg-gray-700/50 hover:text-gray-300',
							className
						)}
					>
						<SmileIcon className="h-4 w-4 sm:h-5 sm:w-5" />
					</Button>
				</PopoverTrigger>
				<PopoverContent className="w-auto p-2" align="center" side="top">
					<div className="flex gap-1">
						{reactions.map(reaction => (
							<Tooltip key={reaction.id} open={activeTooltip === reaction.id}>
								<TooltipTrigger asChild>
									<Button
										variant="ghost"
										size="icon"
										onClick={() => onReactionSelect(reaction.id)}
										className="h-8 w-8 p-1 hover:bg-gray-700/50"
										onMouseEnter={() => setActiveTooltip(reaction.id)}
										onMouseLeave={() => setActiveTooltip(null)}
									>
										<img
											src={REACTIONS[reaction.icon]}
											alt={reaction.name}
											className="h-full w-full transition-transform hover:scale-110"
										/>
									</Button>
								</TooltipTrigger>
								<TooltipContent className="w-auto p-2">
									{reaction.name}
								</TooltipContent>
							</Tooltip>
						))}
					</div>
				</PopoverContent>
			</Popover>
		</TooltipProvider>
	)
}
