import { useQuests } from './hooks/useQuests'
import { Card, CardContent } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { LuDiamond, LuArrowBigUpDash, LuGamepad2 } from 'react-icons/lu'
import { FaArrowRight } from 'react-icons/fa'
import { RiCloseLine } from 'react-icons/ri'
import { Countdown } from './Countdown'
import _ from 'lodash'

export function Quests() {
	const { loading, quests } = useQuests()

	if (_.isNil(quests) || loading) {
		return null
	}

	return (
		<div className="custom-scrollbar max-h-[800px] min-h-[517px] overflow-auto rounded-lg bg-[#28202C] p-4">
			<div className="mb-4 text-lg font-semibold text-white">Quests</div>
			<div className="space-y-2">
				{quests.map(quest => (
					<Card
						key={quest.id}
						className={`rounded-sm border-none text-white ${
							quest.isClaimable ? 'bg-[#382D44]' : 'bg-[#09090B]'
						}`}
					>
						<CardContent className="px-3 py-3">
							<div className="flex items-center justify-between gap-4">
								<div className="flex min-w-0 flex-grow items-center space-x-4">
									<div className="flex-shrink-0 align-top">
										<LuGamepad2 className="h-4 w-4 text-white" />
									</div>
									<div className="min-w-0 flex-shrink">
										<h2 className="mb-2 truncate text-base font-medium">
											{quest.name}
										</h2>
										<p className="max-w-24 truncate text-base text-gray-400">
											<Countdown date={quest.endDate} message={'Ends in'} />
										</p>
									</div>
								</div>
								<div className="flex flex-shrink-0 items-center space-x-4">
									<div className="flex items-center space-x-1 text-sm">
										<LuDiamond className="h-3 w-3 text-white" />
										<span>{quest.xpToReward}</span>
									</div>
									<div className="flex items-center space-x-1 text-sm">
										<LuArrowBigUpDash className="h-3 w-3 text-white" />
										<span>
											{quest.rewardAmount} {quest.rewardCurrencySymbol}
										</span>
									</div>
									<Button
										className={`ml-2 w-25 ${
											quest.isClaimable
												? 'bg-[#af6cc3] hover:bg-[#af6cc3]/80'
												: 'bg-transparent text-gray-400 hover:bg-transparent'
										}`}
										disabled={!quest.isClaimable}
										asChild={quest.isClaimable}
									>
										{quest.isClaimable ? (
											<a
												href={`${process.env.NEXT_PUBLIC_ON3_URL}/quests/${quest.id}`}
												target="_blank"
											>
												{['Daily Login', ].includes(quest.name) ? 'Claim' : 'Participate'}{' '}
												<span>
													<FaArrowRight className="h-3 w-3" />
												</span>
											</a>
										) : (
											<>
												{['Daily Login', ].includes(quest.name) ? 'Claim' : 'Participate'}{' '}
												<span>
													<RiCloseLine className="h-3 w-3" />
												</span>
											</>
										)}
									</Button>
								</div>
							</div>
						</CardContent>
					</Card>
				))}
			</div>
		</div>
	)
}
