import { useState } from 'react'
import { Drawer, Icon, IconButton } from '@mui/material'
import { SidebarMenu } from '../sidebarMenu'
import styles from './NewsreelMobileMenu.module.scss'

export default function NewsreelMobileMenu() {
	const [open, setOpen] = useState(false)

	const toggle = () => {
		setOpen(!open)
	}

	return (
		<>
			<IconButton onClick={toggle} className={styles.open_button}>
				<Icon baseClassName="fal" className="fa-gear" />
			</IconButton>
			<Drawer
				anchor={'left'}
				open={open}
				onClose={toggle}
				onOpen={toggle}
				// ModalProps={{ hideBackdrop: true }}
				classes={{
					paperAnchorLeft: styles.test
				}}
			>
				<SidebarMenu {...{ handleClose: toggle }} />
			</Drawer>
		</>
	)
}
