import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Dashboard from '@/components/dashboard/Dashboard'
import ForgotPassword from '@/components/forgotPassword/ForgotPassword'
import ProjectDetail from '@/components/projectDetail/ProjectDetail'
import Profile from '@/components/profile/Profile'
import Settings from '@/components/settings/Settings'
import TileManagement from '@/components/tileManagement/TileManagement'
import TransactionInformation from '@/components/profile/walletTab/transactionInformation/TransactionInformation'
import { ProjectEdit } from '@/components/projectEdit'
import { ProfileEdit } from '@/components/profileEdit'
import { Home } from '@/components/home'
import { SocialLogin } from '@/components/header/socialLogin'
import { TermsOfService } from '@/components/termsOfService'
import { CodeOfConduct } from '@/components/codeOfConduct'
import { SocialJoin } from '@/components/socialJoin'
import { ResetPassword } from '@/components/resetPassword'
import { OnboardJoin } from '@/components/onboardJoin'
import { ProjectAddWizard } from '@/components/projectAddWizard'
import { SharedTwitter } from '@/components/sharedTwitter'
import { NewsreelPage } from '@/components/newsreelPage'
import { NotFound } from '@/components/notFound'
import { addObjectToLocalStorage } from 'utils/utils'
import { setLoginDialogOpen } from '@/components/header/signInSlice'
import { isRouteMatch } from 'utils/url.util'
import { useEffect } from 'react'
import { TokenSaleJoin } from '@/components/tokenSaleJoin'
import { TokenSaleExperience } from '@/components/tokenSaleExperience'
import { DashboardProduct } from '@/components/dashboard'
import { Loading } from '@/components/loading'
import Layout from '@/components/fanDashboard/layout/Layout'
import { FanDashboard } from '@/components/fanDashboard/FanDashboard'
import { ProjectPage } from '@/components/project/ProjectPage'

const AppRoutes = () => {
	const dispatch = useDispatch()
	const { pathname, search } = useLocation()
	const { isAuthenticated, user } = useSelector(state => state.signIn)

	useEffect(() => {
		// Routes skipped that doesn't show the login modal by default
		const routes = [
			'/',
			'/forgotPassword',
			'/airdropJoin/:userId/:token',
			'/resetPassword/:userId/:token',
			'/resetPassword/:action/:userId/:token',
			'/login/:provider/:id/:token',
			'/terms-of-service',
			'/code-of-conduct',
			'/join/:network/:userShortUrl/:shortUrl',
			'/join/:network/:userShortUrl',
			'/onboard/join/:slug',
			'/tokenSale/:id',
			'/explore',
			'/project/:slug',
			'/project-details/:slug',
			'/tokenSale',
			'/profile/:username',
			'/newsreel',
			'/loading'
		]
		if (!isRouteMatch(pathname, routes)) {
			const redirectTo = search === '' ? pathname : `${pathname}/${search}`
			addObjectToLocalStorage({ redirectTo })
			dispatch(setLoginDialogOpen(true))
		}
	}, [])

	if (isAuthenticated) {
		if (user?.isTokenSale === 1 && user?.appAccess === 0) {
			return (
				<Routes>
					<Route element={<Layout />}>
						<Route path="/tokenSale" element={<TokenSaleExperience />} />
						<Route path="*" element={<Navigate to="/tokenSale" replace />} />
					</Route>
				</Routes>
			)
		} else {
			return (
				<Routes>
					<Route element={<Layout />}>
						<Route path="/tokenSale" element={<TokenSaleExperience />} />
						<Route path="/" element={<Home />} />
						<Route path="/loading" element={<Loading />} />
						<Route path="/explore" element={<Dashboard />} />
						<Route path="/newsreel" element={<NewsreelPage />} />
						<Route path="/project/:slug" element={<ProjectPage />} />
						<Route path="/project-details/:slug" element={<ProjectDetail />} />
						<Route path="/project/edit/:id" element={<ProjectEdit />} />
						<Route
							path="/project/draft/:id"
							element={<ProjectDetail draft={true} />}
						/>
						<Route path="/profile/:username" element={<Profile />} />
						<Route path="/profile/edit/:userId" element={<ProfileEdit />} />
						<Route
							path="/transactionInformation"
							element={<TransactionInformation />}
						/>
						<Route path="/settings" element={<Settings />} />
						<Route path="/newProject" element={<ProjectAddWizard />} />
						<Route path="/terms-of-service" element={<TermsOfService />} />
						<Route path="/code-of-conduct" element={<CodeOfConduct />} />
						<Route path="/tweet/:token/:secret" element={<SharedTwitter />} />
						<Route path="/notfound" element={<NotFound />} />
						<Route path="*" element={<Navigate to="/" replace />} />

						<Route path="/tile-management" element={<TileManagement />} />
						<Route path="/fan-dashboard" element={<FanDashboard />} />
					</Route>
				</Routes>
			)
		}
	} else {
		return (
			<Routes>
				<Route element={<Layout />}>
					<Route path="/" element={<Home />} />
					<Route path="/loading" element={<Loading />} />
					<Route path="/forgotPassword" element={<ForgotPassword />} />
					<Route path="/airdropJoin/:userId/:token" element={<Home />} />
					<Route
						path="/resetPassword/:userId/:token"
						element={<ResetPassword />}
					/>
					<Route
						path="/resetPassword/:action/:userId/:token"
						element={<ResetPassword />}
					/>
					<Route path="/login/:provider/:id/:token" element={<SocialLogin />} />
					<Route path="/terms-of-service" element={<TermsOfService />} />
					<Route path="/code-of-conduct" element={<CodeOfConduct />} />
					<Route
						path="/join/:network/:userShortUrl/:shortUrl"
						element={<SocialJoin />}
					/>
					<Route
						path="/join/:network/:userShortUrl"
						element={<DashboardProduct />}
					/>
					<Route path="/onboard/join/:slug" element={<OnboardJoin />} />
					<Route path="/tokenSale" element={<TokenSaleJoin />} />
					<Route path="/tokenSale/:id" element={<TokenSaleJoin />} />
					<Route path="/project-details/:slug" element={<ProjectDetail />} />
					<Route path="/project/:slug" element={<ProjectPage />} />
					<Route path="/profile/:username" element={<Profile />} />
					<Route path="/explore" element={<Dashboard />} />
					<Route path="/newsreel" element={<NewsreelPage />} />
					<Route path="*" element={<Navigate to="/" replace />} />
				</Route>
			</Routes>
		)
	}
}

export default AppRoutes
