import { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Icon, IconButton, Typography } from '@mui/material'
import { followUnfollowProject } from 'services/followUnfollow.service'
import { TAB_MAP } from '@/components/projectDetail'
import { LightTooltip } from '@/components/common'
import useHeader from '@/components/header/useHeader'
import { useSelector } from 'react-redux'
import { GoScoreV2 } from './goScoreV2'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import projectImages from 'assets/images/product-detail'
import { Button } from '@/components/ui/button'
import images from 'assets/images'
import { abbreviateNumberFixed } from 'utils/utils'
import packageOpenWhite from '@/assets/images/package-open-white.svg'
import packageClosedWhite from '@/assets/images/package-closed-white.svg'
import { ActiveContestBadge } from '../activeContestBanner/ActiveContestBadge'
import { usePrefetchProject } from '../../project/prefetch/usePrefetchProject'
import { FollowProjectWrapper } from '@/components/common-ui/followProjectWrapper'
import { SuperfanBenefitsDialog } from '@/components/project/projectSuperfan/superfanBenefits/superfanBenefitsDialog/SuperfanBenefitsDialog'
import { PerkProgramDialog } from '@/components/projectDetail/dashboardTab/superfan/perkProgramDialog'
import Image from 'next/image'
import styles from './ProjectItem.module.scss'
import { LoaderCircle } from 'lucide-react'

const ProjectItem = props => {
	const {
		id,
		likes,
		poster,
		reviewsAvg,
		stakesTotal,
		goScoreStage,
		isFollowedByUser,
		variant,
		title,
		slug,
		user,
		goscore,
		staffPick,
		contests = [],
		createdAt,
		isOnPerksProgram
	} = props

	const { isAuthenticated } = useSelector(state => state.signIn)
	const [isFollowed, setIsFollowed] = useState(isFollowedByUser)
	const [loadingFollow, setLoadingFollow] = useState(false)
	const [followCount, setFollowCount] = useState(likes)
	const { loadUserTierInfo } = useHeader()
	const navigate = useNavigate()
	const [benefitsIsOpen, setBenefitsIsOpen] = useState(false)
	const { prefetchHandlers } = usePrefetchProject(slug, {
		delay: 300,
		mouseMoveTrigger: true,
		minMoveDistance: 30
	})

	const updatefollowProject = async (projectId, followingProject) => {
		setLoadingFollow(true)
		const res = await followUnfollowProject(projectId, followingProject)
		if (res.status === 200) {
			const followCountValue = isFollowed ? followCount - 1 : followCount + 1
			setFollowCount(followCountValue)
			setIsFollowed(prev => !prev)
			loadUserTierInfo()
		}
		setLoadingFollow(false)
	}

	const handleFollowClick = projectId => async () => {
		if (!isAuthenticated) {
			navigate(`/project/${slug}`)
			return
		}
		await updatefollowProject(projectId, isFollowed)
	}

	const showModal = async () => {
		setBenefitsIsOpen(true)
	}

	const secondary = isOnPerksProgram ? 'inPerks' : 'noInPerks'

	// TODO: Fix this for FollowProjectWrapper is not receiving the isSuperfan prop
	return (
		<Box
			className={[styles.project_item, styles[variant]].join(' ')}
			{...prefetchHandlers}
		>
			{benefitsIsOpen &&
				(isOnPerksProgram ? (
					<SuperfanBenefitsDialog
						open={benefitsIsOpen}
						onOpenChange={open => setBenefitsIsOpen(open)}
						projectId={id}
					/>
				) : (
					<PerkProgramDialog
						{...{
							isOnPerksProgram: isOnPerksProgram,
							projectName: title,
							open: benefitsIsOpen,
							handleClose: () => {
								setBenefitsIsOpen(false)
							}
						}}
					/>
				))}

			<div className={[styles.posterWrapper, styles[secondary]].join(' ')}>
				<Link to={`/project/${slug}`}>
					<Image
						className={styles.artwork}
						src={poster || projectImages.posterNotAvailable}
						alt="project hero"
						width={276}
						height={404}
						quality={100}
						blurDataURL="data:image/webp;base64,UklGRpIDAABXRUJQVlA4IIYDAAAwVACdASq+Ak8BPpFGoEylo6aioPM44NASCWlu4XSEDmNwuOAEUiAFaPl3Ser7/rMxWvv+szFa+/6zMVr7/okkfNoPV9/1mYrX3/WZitff9ZmK1+AOeD1ff9ZmK19/1mYrX3/WZitff95d9X3/WZitff9ZmK19/1mYrX3/XCS5cGoAg9X3/WZc9iuJ7rHBqAIPV9/18utZmK19/1mYrUKJFzYboLKG+1QKXLKBqAIPV59oOFHrMxWvv9pUSP+fr+wgR8afC3bSFNSovmpgBmdoxVvq+/6zMVrzpoL89X3/WZb9zcn9cNAiP5WOQW6oMvqPZJHPA7Av5Xc6L96y4DKBqAIPVKSEAQer7/rEMzGFACdzBky0qhmvMZw45Y4VWg3qF69ki5ZQNOi7r/rMxWvvucKXmFrs1SShl0fJFDkgAgnW5Dfvu43VDlA1AEHq86aC/PV9/1mEX8hDIatiDhCsSNjNYXlyxGLEf/YufVqnNcmSLllA0/l9W+DCigCD1QNE19DjQq8nJ0F4aPJAOgFx7xIYENATNqOlVoJvKraRcsoGoAOkyrX3/WZitRhTMHt3fy5Cm8vD2mA5cPBX4dPHcCMe+weAo4UeszFa8oafBllA1AEHq8zTJJcIJTJIcTA6mrILe5HD9ajiI4UeszFa8uPwCPWZitff9Nh0UMWGK+GffJvvlUk4/fb08W/slFAEHq+++BsDg1AEHq+/13EFXUzi4KAQq5V9sgOt3eJ7R/AUUAQer774GwODUAQer7/xxDVPi++HPq+/6zMVqKzW0bCxy7B+lA2xc0RMbJjpYfLkWOXYP0oG2Fc8u9qAIPV9/1mYrX3/WZitff9ZmKr9XwooAg9X3/WZitff9ZmK19/1mXK7r/rMxWvv+szFa+/6zMVr7/rMxG9AAP7+9R+AuXeB9CSb0oxgAAAVNp5FaYvHQrqFPKJjE7+ttDemOoumGorCrON8YDxzfeVjAZRJVR9eN7VVG/UFCyMC4V3WtA/9hrJ78sBVRXq+SV0vOyAxHZ2/c5D91k4zaRRhlxxTzlAVteCCTmNzqwrAGbG+yeRxDNxuqG/qnsoItqvWosHcfgP8XUIVTP6ckDhje5zzWEuSLV4TJTV0DpNDxYG0xLIIm592NCRcQLJgruNTmoMguwLxBkAMwTSUDb5NexQxcYABx94AEjIAJyQAAOBckAAAAAAAAA=="
					/>
				</Link>
				<Box className={styles.SocialContainer}>
					<Box className={styles.likes}>
						<FollowProjectWrapper
							project={props}
							onConfirm={handleFollowClick(id)}
						>
							<IconButton>
								{loadingFollow ? (
									<LoaderCircle className="mr-1 h-4 w-4 animate-spin" />
								) : isFollowed ? (
									<FavoriteIcon />
								) : (
									<FavoriteBorderIcon />
								)}
							</IconButton>
						</FollowProjectWrapper>
						<span>{abbreviateNumberFixed(followCount, 2)}</span>
					</Box>

					{isOnPerksProgram ? (
						<Button
							variant="secondary"
							className="flex h-8 justify-center gap-2 bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] px-3 text-sm font-bold leading-5 text-[#FAFAFA] hover:bg-opacity-70"
							onClick={showModal}
						>
							<img
								src={packageOpenWhite.src}
								alt="Superfan Perks"
								className="h-4 w-4"
							/>
							<span className="hidden opacity-100 md:block">Superfan</span>
							Perks
						</Button>
					) : (
						<Button
							variant="secondary"
							className="flex h-8 justify-center gap-2 bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] px-3 text-sm font-bold leading-5 text-[#ffffff] grayscale-[1]"
							onClick={showModal}
						>
							<img
								src={packageClosedWhite.src}
								alt="Superfan Perks"
								className="h-4 w-4 opacity-25"
							/>
							<span className="hidden opacity-25 md:block">Superfan</span>{' '}
							<span className="opacity-25">Perks</span>
						</Button>
					)}

					{staffPick === 1 && (
						<LightTooltip title="Chosen as a Daily Pick by Film.io staff">
							<Box className={styles.staff_pick}>
								<img
									alt=""
									src={images.staffPickLarge}
									className={styles.staff_pick}
								/>
							</Box>
						</LightTooltip>
					)}
				</Box>
			</div>
			<Box className={styles.item_box}>
				<Box className={styles.stage_button}>
					<span className={styles[goScoreStage?.label]}>
						{goScoreStage?.name}
					</span>
				</Box>
				<Box className={styles.likes}>
					<IconButton>
						<Icon baseClassName="fas" className="fa-star-half-stroke" />
					</IconButton>
					<span>{reviewsAvg}</span>
				</Box>
				<Box className={styles.likes}>
					<IconButton>
						<Icon baseClassName="fa-kit" className="fa-fan-stake" />
					</IconButton>
					<span>{abbreviateNumberFixed(stakesTotal, 2)}</span>
				</Box>
			</Box>
			<Box className={styles.item_content}>
				<Box className={styles.goscore}>
					<GoScoreV2 {...{ goscore, showText: false }} />
				</Box>
				<Typography component={'h3'}>
					<LightTooltip title={title}>
						<Link to={`/project/${slug}?${TAB_MAP.dashboard}`}>{title}</Link>
					</LightTooltip>
					<span>
						<Link to={`/profile/${user.username}`}>{user.displayName}</Link>
						{user?.isVerified === 1 && (
							<Icon baseClassName="fas" className="fa-badge-check" />
						)}
					</span>
				</Typography>
				<Box className={styles.badges}>
					{new Date(createdAt) < new Date('2023-11-30T12:00:00') && (
						<LightTooltip title="Participated in the 2023 Airdrop">
							<img src={images.airdrop2023} alt="airdrop 2023 participant" />
						</LightTooltip>
					)}
					{contests.map(contest => {
						const isContestWinner = contest.isWinner && !contest.isActive
						const src = isContestWinner ? contest?.badgeWinner : contest?.badge
						const tootipTitle = isContestWinner
							? `Winner in ${contest?.name}`
							: `Participation in ${contest?.name}`
						return (
							<LightTooltip title={tootipTitle} key={contest?.id}>
								<ActiveContestBadge {...{ src }} />
							</LightTooltip>
						)
					})}
				</Box>
			</Box>
		</Box>
	)
}

ProjectItem.propTypes = {
	id: PropTypes.string,
	slug: PropTypes.string,
	poster: PropTypes.string,
	likes: PropTypes.number,
	stakesTotal: PropTypes.number,
	reviewsAvg: PropTypes.number,
	goScoreStage: PropTypes.object,
	title: PropTypes.string,
	user: PropTypes.object,
	stakes: PropTypes.object,
	reviews: PropTypes.object,
	socialMediaShares: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
	isFollowedByUser: PropTypes.bool,
	variant: PropTypes.string,
	goscore: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	goScoreMilestones: PropTypes.array,
	staffPick: PropTypes.number,
	contests: PropTypes.array,
	createdAt: PropTypes.string,
	isOnPerksProgram: PropTypes.number
}

export default ProjectItem
