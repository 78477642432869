'use client'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Icon, IconButton, Tooltip } from '@mui/material'
import { addReactionService } from 'services/reaction.service'
import { Mixpanel } from 'services/mixpanel.service'
import useOutsideClick from 'hooks/useOutsideClick'
import REACTIONS_ICONS from 'assets/images/reactions'
import { setLoginDialogOpen } from '@/components/header/signInSlice'
import styles from './Reactions.module.scss'

export const Reactions = ({ feedId, updateReactions, type }) => {
	const [open, setOpen] = useState(false)
	const { reactions } = useSelector(state => state.reactions)
	const { user, isAuthenticated } = useSelector(state => state.signIn)
	const dispatch = useDispatch()

	const toggleReaction = () => {
		if (!isAuthenticated) {
			dispatch(setLoginDialogOpen(true))
			return
		}
		setOpen(prev => !prev)
	}
	const handleUpdateReaction = id => async () => {
		const res = await addReactionService(id, feedId, type)
		if (res.status === 200) {
			Mixpanel.track('comment_reaction_add_event', {
				distinct_id: user.id,
				displayName: user.displayName,
				username: user.username,
				id,
				feedId,
				type
			})
			updateReactions(res.data)
			setOpen(false)
		}
	}

	const renderReactions = reactions => {
		return reactions.map(({ icon, name, id }) => (
			<li key={icon}>
				<Tooltip title={name} placement="top">
					<IconButton aria-label="reaction" onClick={handleUpdateReaction(id)}>
						<img src={REACTIONS_ICONS[icon]} alt={icon} />
					</IconButton>
				</Tooltip>
			</li>
		))
	}

	const handleClickOutside = () => {
		setOpen(false)
	}

	const ref = useOutsideClick(handleClickOutside, open)

	return (
		<>
			<div className={styles.reactions}>
				<IconButton aria-label="reactions" onClick={toggleReaction}>
					<Icon baseClassName="fas" className="fa-light fa-face-smile-hearts" />
				</IconButton>
				{open && (
					<div className={styles.reactions_box} ref={ref}>
						<ul className={styles.reaction_list}>
							{renderReactions(reactions, feedId, updateReactions, type)}
						</ul>
					</div>
				)}
			</div>
		</>
	)
}

Reactions.propTypes = {
	feedId: PropTypes.string,
	updateReactions: PropTypes.func,
	type: PropTypes.string
}

export const ReactionBtn = ({
	id,
	icon,
	name,
	count,
	highlight,
	feedId,
	setReactions,
	type
}) => {
	const highlightClass = highlight ? styles.highlight : ''
	const classes = [styles.reaction_btn, highlightClass].join(' ')
	const { isAuthenticated } = useSelector(state => state.signIn)
	const dispatch = useDispatch()
	const handleUpdateReaction = id => async () => {
		if (!isAuthenticated) {
			dispatch(setLoginDialogOpen(true))
			return
		}
		const res = await addReactionService(id, feedId, type)
		if (res.status === 200) {
			setReactions(res.data)
		}
	}
	return (
		<>
			<Tooltip title={name} placement="top">
				<Button
					aria-label="reaction"
					size="small"
					className={classes}
					onClick={handleUpdateReaction(id)}
				>
					<img src={REACTIONS_ICONS[icon]} alt={icon} />
					<Box>{count}</Box>
				</Button>
			</Tooltip>
		</>
	)
}

ReactionBtn.propTypes = {
	id: PropTypes.number,
	icon: PropTypes.string,
	name: PropTypes.string,
	count: PropTypes.number,
	highlight: PropTypes.bool,
	feedId: PropTypes.string,
	setReactions: PropTypes.func,
	type: PropTypes.string
}

export const renderReactionsButtons = (
	reactions,
	feedId,
	setReactions,
	type
) => {
	return reactions.map(reaction => (
		<ReactionBtn
			{...reaction}
			{...{ feedId, setReactions, type }}
			key={reaction.icon}
		/>
	))
}
