import { abbreviateNumber } from 'js-abbreviation-number'
import _ from 'lodash'

const blockExploreBaseUrl = process.env.NEXT_PUBLIC_BLOCK_EXPLORE_BASE_URL
const ethExploreBaseUrl = process.env.NEXT_PUBLIC_ETH_EXPLORE_BASE_URL

export const createSelectOptions = (arr: string[]) =>
	arr.map((el: string) => {
		return { name: el, id: el.toLowerCase().replaceAll(' ', '_') }
	}, [])

export const formatAddress = (hash: string | number) => {
	if (_.isNil(hash)) return ''
	const hashString = hash.toString()
	return `${hashString.slice(0, 6)}...${hashString.slice(-4)}`
}

export const formatShortAddress = (hash: string) => {
	if (_.isNil(hash)) return ''
	const hashString = hash.toString()
	return `${hashString.slice(0, 4)}...${hashString.slice(-3)}`
}

export const getTransactionLink = (blockchainHash: string) => {
	if (_.isNil(blockchainHash))
		return (
			<a target="_blank" rel="noreferrer">
				Processing...
			</a>
		)
	const blockchainHashText = formatAddress(blockchainHash)
	const blockchainTransactionUrl = `${blockExploreBaseUrl}${blockchainHash}`
	return (
		<a href={blockchainTransactionUrl} target="_blank" rel="noreferrer">
			{blockchainHashText}
		</a>
	)
}

export const getEthTransactionLink = (blockchainHash: string) => {
	if (_.isNil(blockchainHash))
		return (
			<a target="_blank" rel="noreferrer">
				Processing...
			</a>
		)
	const blockchainHashText = `${blockchainHash.slice(
		0,
		4
	)}...${blockchainHash.slice(-4)}`
	const blockchainTransactionUrl = `${ethExploreBaseUrl}${blockchainHash}`
	return (
		<a href={blockchainTransactionUrl} target="_blank" rel="noreferrer">
			{blockchainHashText}
		</a>
	)
}

const addMetaTag = (name: string, content: string) => {
	const tag = document.querySelector(`meta[name="${name}"]`)
	if (_.isNil(tag)) {
		const tagDesc = document.querySelector('meta[name="description"]')
		const meta = document.createElement('meta')
		meta.setAttribute('name', name)
		document.getElementsByTagName('head')[0].insertBefore(meta, tagDesc)
	}
	const metaTag = document.querySelector(`meta[name="${name}"]`)
	if (metaTag) {
		metaTag.setAttribute('content', content)
	}
}

export const updateMetaTags = ({
	title = 'Filmio',
	description = `Film.io is the world's most powerful decentralized filmmaking ecosystem, placing Hollywood decision-making into the hands of creators and fans.`,
	url = null,
	image = null,
	author = null
}) => {
	addMetaTag('twitter:card', 'summary')
	addMetaTag('og:type', 'article')

	if (!_.isNil(description)) {
		const desc = description.replace('<p>', '').replace('</p>', '')
		addMetaTag('description', desc)
		addMetaTag('twitter:description', desc)
		addMetaTag('og:description', desc)
	}
	if (document.title !== title && !_.isNil(title)) {
		document.title = title
		addMetaTag('twitter:title', title)
		addMetaTag('og:title', title)
	}
	if (!_.isNil(url)) {
		addMetaTag('twitter:url', url)
		addMetaTag('og:url', url)
	}
	if (!_.isNil(image)) {
		addMetaTag('twitter:image', image)
		addMetaTag('og:image', image)
	}
	if (!_.isNil(author)) {
		addMetaTag('og:author', author)
	}
}

export const addObjectToLocalStorage = (data: Record<string, any>) => {
	Object.keys(data).forEach(key => {
		let value = _.get(data, [key], '')
		if (typeof value === 'object') value = JSON.stringify(value)
		localStorage.setItem(key, value)
	})
}

function isObjectString(str: string | null) {
	if (_.isNil(str)) return false
	try {
		JSON.parse(str)
	} catch (e) {
		return false
	}
	return true
}

export const getObjectFromLocalStorage = (
	keys: string[]
): Record<string, any> => {
	return keys.reduce(
		(acc, key) => {
			const value = localStorage.getItem(key)
			if (_.isNil(value)) acc[key] = null
			else if (isObjectString(value)) acc[key] = JSON.parse(value!)
			else acc[key] = isNaN(Number(value)) ? value : Number(value)

			return acc
		},
		{} as Record<string, any>
	)
}

export const getNumberFromLocalStorage = (key: string): number | null => {
	const value = localStorage.getItem(key)
	if (_.isNil(value) || value === 'null') return null
	return Number(value)
}

export const getOneObjectFromLocalStorage = (
	key: string
): Record<string, any> | null => {
	let value: string | null = localStorage.getItem(key)
	if (_.isNil(value) || value === 'null') return null
	if (isObjectString(value)) value = JSON.parse(value)
	return value as unknown as Record<string, any>
}

export const isMobile = () => {
	const isBrowser = typeof window !== 'undefined'
	const userAgent = isBrowser ? _.get(window, 'navigator.userAgent') : ''
	const isAndroid = /(Android)/i.test(userAgent)
	const isIphone = /(iPhone|iPod)/i.test(userAgent)
	const isIpad = /(iPad)/i.test(userAgent)
	return isIphone || isAndroid || isIpad
}

export const isMetamask = () => {
	const isBrowser = typeof window !== 'undefined'
	const hasEthereum = isBrowser && _.has(window, 'ethereum')
	return isMobile() && hasEthereum
}

export const isSafariMacOs = () => {
	const userAgent = navigator.userAgent.toLowerCase()
	return (
		/safari/.test(userAgent) &&
		!/chrome/.test(userAgent) &&
		/macintosh/.test(userAgent)
	)
}

const wait = (ms: number) =>
	new Promise(resolve => {
		setTimeout(() => resolve(void 0), ms)
	})

export const retryWithDelay = async (
	fn: () => Promise<any>,
	failResult = null,
	retries = 240,
	interval = 500
): Promise<any> => {
	try {
		const result = await fn()
		return result
	} catch (err) {
		if (retries <= 0) {
			return Promise.resolve(failResult)
		}
		await wait(interval)
		return retryWithDelay(fn, failResult, retries - 1, interval)
	}
}

export const abbreviateNumberFixed = (
	value: number,
	digit: number,
	decimals = 2
) => {
	const valueFixed = Number(value).toFixed(decimals)
	return decimals <= 2
		? abbreviateNumber(Number(valueFixed), digit)
		: valueFixed
}

export const NumberWithDecimals = (value = 0, decimals = 4) => {
	const numberValue = isNaN(value) ? 0 : value
	return hasDecimalPart(numberValue)
		? parseFloat(Number(numberValue).toFixed(decimals))
		: numberValue
}

export const hasDecimalPart = (value: number) => value % 1 > 0

export const delay = (ms: number) =>
	new Promise(resolve => setTimeout(resolve, ms))

export const removeDuplicates = (arr: any[]) =>
	arr.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i)

export const zeroPad = (num: number, length: number) => {
	const numLength = String(num).length
	if (numLength >= length) return String(num)
	return String(num).padStart(length, '0')
}

export const formatWithLeadingZeros = (num: number) => {
	if (num === 0) return '0.0{15}1'
	const numStr = num.toFixed(20)
	const leadingZeros = numStr.match(/0\.(0+)[1-9]/)
	if (leadingZeros) {
		const zeroCount = leadingZeros[1].length
		const significantDigit = numStr[leadingZeros[0].length]
		return `0.0{${zeroCount}}${significantDigit}`
	}

	return numStr
}
