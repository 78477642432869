import React, { useEffect, useState } from 'react'
import { cn } from '@/lib/utils'
import { Project } from '@/components/project/useProjectPage'
import { ProjectNoSuperfan } from './ProjectNoSuperfan'
import { ProjectBecomeSuperfanContent } from './ProjectBecomeSuperfanContent'

interface Props {
	className?: string
	project: Project
	handleSuperfanActionsClick: () => void
}

export function ProjectSuperfanMobile({
	className,
	project,
	handleSuperfanActionsClick
}: Props) {
	const { id, title, isSuperfan, isOnPerksProgram } = project
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		const intercomElement = document.querySelector(
			'.intercom-launcher'
		) as HTMLElement

		if (intercomElement) {
			// Add transition style
			intercomElement.style.transition = 'opacity 0.3s ease-in-out'

			if (isOpen) {
				// Fade out
				intercomElement.style.opacity = '0'
				// Wait for fade out to complete before hiding
				setTimeout(() => {
					intercomElement.style.display = 'none'
				}, 300) // Match this with transition duration
			} else {
				// Show element first
				intercomElement.style.display = 'block'
				// Small delay to ensure display: block is applied before starting fade in
				requestAnimationFrame(() => {
					intercomElement.style.opacity = '1'
				})
			}
		}
	}, [isOpen])

	useEffect(() => {
		// Check if screen is smaller than md breakpoint (768px)
		const isMobile = window.matchMedia('(max-width: 767px)')

		if (!isMobile.matches) {
			return // Don't run the observer if screen is md or larger
		}

		// Function to handle the element when it's found
		const handleElement = (element: HTMLElement) => {
			element.style.bottom = '90px'
		}

		// Create an observer instance
		const observer = new MutationObserver(() => {
			const intercomElement = document.querySelector(
				'.intercom-launcher'
			) as HTMLElement
			if (intercomElement) {
				handleElement(intercomElement)
				observer.disconnect()
			}
		})

		// Start observing the document with the configured parameters
		observer.observe(document.body, {
			childList: true,
			subtree: true
		})

		// Cleanup function
		return () => {
			observer.disconnect()
			const intercomElement = document.querySelector(
				'.intercom-launcher'
			) as HTMLElement
			if (intercomElement) {
				intercomElement.style.bottom = ''
				intercomElement.style.display = 'block'
			}
		}
	}, [])

	const peekHeight = '76px' // Always visible portion
	const fullHeight = isSuperfan ? '460px' : peekHeight // Full height when expanded

	// Calculate translateY: when closed, show only peekHeight; when open, show full height
	const translateY = isOpen
		? '0' // Fully visible
		: `calc(${fullHeight} - ${peekHeight})` // Shift down so only peekHeight shows

	const toggleDrawer = () => {
		setIsOpen(prev => !prev)
	}

	return (
		<div
			className={cn(
				'fixed bottom-0 left-0 right-0 isolate z-50 bg-black outline-none transition-transform duration-300 ease-in-out',
				className
			)}
			style={{
				height: fullHeight, // Fixed height of the full content
				transform: `translateY(${translateY})` // Slide effect
			}}
		>
			{isSuperfan ? (
				<ProjectBecomeSuperfanContent
					isOpen={isOpen}
					className="h-96"
					toggle={toggleDrawer}
					project={project}
					handleSuperfanActionsClick={handleSuperfanActionsClick}
				/>
			) : (
				<ProjectNoSuperfan
					projectId={id}
					projectTitle={title}
					isOnPerksProgram={isOnPerksProgram}
				/>
			)}
		</div>
	)
}
