import { Popover } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import LinearProgress, {
	linearProgressClasses
} from '@mui/material/LinearProgress'
import styles from './BadgePreview.module.scss'

const BorderLinearProgress = styled(LinearProgress)(() => ({
	height: 15,
	width: '85%',
	marginLeft: '7.5%',
	position: 'absolute',
	bottom: 40,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		background: '#000000'
	},
	[`&>.${linearProgressClasses.barColorPrimary}`]: {
		background: '#FFF linear-gradient(to right, #17CEC2, #F1C500)'
	}
}))

export const BadgePreview = ({
	anchorEl,
	handlePopoverClose,
	image,
	reward,
	name,
	description,
	percent
}) => {
	const open = Boolean(anchorEl)
	const percentStatus =
		percent === 100 ? 'Complete' : percent === 0 ? 'Not Started' : 'In Progress'
	return (
		<Popover
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
			transformOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
			open={open}
			anchorEl={anchorEl}
			onClose={handlePopoverClose}
			PaperProps={{
				className: styles.badge_preview
			}}
		>
			<span className={styles.reward}>+{reward} REP</span>
			<div className={styles.badge_image}>
				<img src={image} alt={name} />
			</div>
			<h2>{name}</h2>
			<p>{description} </p>
			<p className={styles.creator_reward}>
				Creator reward <span>{reward}</span>{' '}
			</p>
			<BorderLinearProgress variant="determinate" value={percent} />
			<p className={styles.badge_status}>{percentStatus}</p>
		</Popover>
	)
}

BadgePreview.propTypes = {
	anchorEl: PropTypes.string,
	image: PropTypes.string,
	handlePopoverClose: PropTypes.func,
	reward: PropTypes.number,
	name: PropTypes.string,
	description: PropTypes.string,
	percent: PropTypes.number
}
