import React, { useEffect, useState } from 'react'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger
} from '@/components/ui/tooltip'

interface CustomTooltipProps {
	children: React.ReactNode
	content: React.ReactNode
	side?: 'top' | 'right' | 'bottom' | 'left'
	sideOffset?: number
	className?: string
}

export const CustomTooltip = ({
	children,
	content,
	side = 'bottom',
	sideOffset = 5,
	className = 'bg-white px-3 py-4 text-black'
}: CustomTooltipProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const [isMobile, setIsMobile] = useState(false)

	useEffect(() => {
		// Simple mobile detection
		const checkMobile = () => {
			setIsMobile(window.matchMedia('(max-width: 768px)').matches)
		}

		checkMobile()
		window.addEventListener('resize', checkMobile)
		return () => window.removeEventListener('resize', checkMobile)
	}, [])

	const handleTrigger = (event: React.MouseEvent | React.TouchEvent) => {
		if (isMobile) {
			event.preventDefault()
			setIsOpen(!isOpen)
		}
	}

	return (
		<TooltipProvider>
			<Tooltip
				open={isMobile ? isOpen : undefined}
				onOpenChange={isMobile ? setIsOpen : undefined}
			>
				<TooltipTrigger asChild onClick={handleTrigger}>
					{children}
				</TooltipTrigger>
				<TooltipContent
					side={side}
					sideOffset={sideOffset}
					className={className}
				>
					{content}
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	)
}
