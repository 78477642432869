export const getTokenSaleCampaignById = (id: string) => ({
	method: 'get',
	url: `/tokenSaleCampaign/${id}`,
	autoexec: true
})

export const getUserTokenSaleCampaign = () => ({
	method: 'get',
	url: '/tokenSaleCampaign/user',
	autoexec: true
})
