export const claimNcWalletService = () => ({
	method: 'put',
	url: '/wallet/claimNc'
})

export const addFanWithdrawalService = (data: any) => ({
	method: 'post',
	url: '/wallet/withdraw',
	data
})

export const addBlockchainFanWithdrawalService = (data: any) => ({
	method: 'post',
	url: '/wallet/blockchain/withdraw',
	data
})

export const addWhitelistWalletService = (data: any) => ({
	method: 'post',
	url: '/wallet/whitelist',
	data
})

export const removeWhitelistWalletService = () => ({
	method: 'delete',
	url: '/wallet/whitelist'
})
