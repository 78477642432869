import { cn } from '@/lib/utils'
import { getFileUrl } from '@/utils/url.util'
import _ from 'lodash'

function stringAvatar(name: string) {
	if (!name) name = '?'
	const splitedName = name.split(' ').map(el => el?.toUpperCase())
	return `${_.get(splitedName, [0, 0], '')}${_.get(splitedName, [1, 0], '')}`
}

interface AvatarFilmioProps {
	ringImage?: string
	avatar?: string
	displayName: string
	className?: string
	textClassName?: string
}

export function AvatarFilmio({
	ringImage,
	avatar,
	displayName,
	className,
	textClassName
}: AvatarFilmioProps) {
	return (
		<div className={cn(`relative h-9 w-9 rounded-full bg-black`, className)}>
			{ringImage && (
				<div className="absolute h-full w-full overflow-hidden rounded-full">
					<img
						className="absolute z-10 aspect-square h-full w-full object-cover"
						src={getFileUrl(ringImage)}
						alt="Ring image"
					/>
				</div>
			)}
			<div className="absolute h-full w-full overflow-hidden rounded-full">
				{avatar ? (
					<img
						src={getFileUrl(avatar)}
						alt={`${displayName}'s avatar`}
						className="aspect-square h-full w-full object-cover"
					/>
				) : (
					<div
						className={cn(
							'flex aspect-square h-full w-full items-center justify-center rounded-full border border-white bg-black font-inter text-base font-bold text-white',
							textClassName
						)}
					>
						{stringAvatar(displayName)}
					</div>
				)}
			</div>
		</div>
	)
}
