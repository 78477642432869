'use client'

import type React from 'react'
import { useState, useCallback, useEffect } from 'react'
import { PlayIcon, ChevronLeftIcon, ChevronRightIcon } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent } from '@/components/ui/dialog'
import { cn } from '@/lib/utils'

interface MediaItem {
	url: string
	kind: 'image' | 'video'
	thumbnailUrl?: string
}

interface MediaThumbnailProps {
	item: MediaItem
	onClick: () => void
}

const MediaThumbnail: React.FC<MediaThumbnailProps> = ({ item, onClick }) =>
	item.kind === 'video' ? (
		<div
			className="relative flex aspect-[16/11] h-[210px] flex-shrink-0 cursor-pointer items-center justify-center overflow-hidden rounded-md bg-gray-800 lg:h-[440px]"
			onClick={onClick}
		>
			{item.thumbnailUrl && (
				<img
					src={item.thumbnailUrl || '/placeholder.svg'}
					alt="Video thumbnail"
					className="absolute inset-0 h-full w-full object-cover"
				/>
			)}
			<PlayIcon className="absolute h-24 w-24 text-white opacity-50" />
		</div>
	) : (
		<img
			src={item.url || '/placeholder.svg'}
			alt="Project media"
			className="h-[210px] w-auto flex-shrink-0 cursor-pointer overflow-hidden rounded-md bg-gray-800 object-cover lg:h-[440px]"
			onClick={onClick}
		/>
	)

interface FullScreenMediaViewerProps {
	media: MediaItem[]
	currentIndex: number
	isOpen: boolean
	onClose: () => void
	onPrev: () => void
	onNext: () => void
}

const FullScreenMediaViewer: React.FC<FullScreenMediaViewerProps> = ({
	media,
	currentIndex,
	isOpen,
	onClose,
	onPrev,
	onNext
}) => {
	const currentItem = media[currentIndex]

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (!isOpen) return
			switch (event.key) {
				case 'ArrowLeft':
					onPrev()
					break
				case 'ArrowRight':
					onNext()
					break
				case 'Escape':
					onClose()
					break
			}
		}

		window.addEventListener('keydown', handleKeyDown)
		return () => window.removeEventListener('keydown', handleKeyDown)
	}, [isOpen, onPrev, onNext, onClose])

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className="h-svh max-h-[100svh] w-screen max-w-[100svw] overflow-hidden border-none bg-black/30 p-0">
				<div
					className={cn('flex h-full w-full items-center justify-center', {
						'pt-12': currentItem?.kind !== 'image'
					})}
				>
					<Button
						variant="ghost"
						size="icon"
						className="fixed left-4 top-1/2 z-10 -translate-y-1/2 transform text-white"
						onClick={onPrev}
					>
						<ChevronLeftIcon className="h-8 w-8" />
					</Button>
					<Button
						variant="ghost"
						size="icon"
						className="fixed right-4 top-1/2 z-10 -translate-y-1/2 transform text-white"
						onClick={onNext}
					>
						<ChevronRightIcon className="h-8 w-8" />
					</Button>
					{currentItem?.kind === 'image' ? (
						<img
							src={currentItem.url || '/placeholder.svg'}
							alt="Full screen preview"
							className="h-screen w-screen object-contain"
						/>
					) : (
						<iframe
							src={currentItem?.url}
							title="Video player"
							className="h-full w-full"
							allowFullScreen
						/>
					)}
				</div>
			</DialogContent>
		</Dialog>
	)
}

interface ProjectMediaCarouselProps {
	media: MediaItem[]
}

export const ProjectMediaCarousel: React.FC<ProjectMediaCarouselProps> = ({
	media
}) => {
	const [currentIndex, setCurrentIndex] = useState(0)
	const [isFullScreen, setIsFullScreen] = useState(false)

	const handlePrev = useCallback(() => {
		setCurrentIndex(prevIndex =>
			prevIndex > 0 ? prevIndex - 1 : media.length - 1
		)
	}, [media.length])

	const handleNext = useCallback(() => {
		setCurrentIndex(prevIndex =>
			prevIndex < media.length - 1 ? prevIndex + 1 : 0
		)
	}, [media.length])

	const handleItemClick = useCallback((index: number) => {
		setCurrentIndex(index)
		setIsFullScreen(true)
	}, [])

	return (
		<div className="relative w-full">
			<div className="custom-scrollbar flex w-full gap-x-4 overflow-x-auto">
				{media.map((item, index) => (
					<MediaThumbnail
						key={item.url ?? index}
						item={item}
						onClick={() => handleItemClick(index)}
					/>
				))}
			</div>

			<div className="pointer-events-none absolute bottom-0 right-0 top-0 h-full w-20 bg-gradient-to-l from-background from-0% via-45% to-transparent to-100% sm:w-32 md:w-40 lg:w-80" />

			<FullScreenMediaViewer
				media={media}
				currentIndex={currentIndex}
				isOpen={isFullScreen}
				onClose={() => setIsFullScreen(false)}
				onPrev={handlePrev}
				onNext={handleNext}
			/>
		</div>
	)
}
