import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { useDispatch } from 'react-redux'
import {
	closeBecomeSuperfanDialog,
	setFanPurchanseDialogOpen
} from '@/components/header/signInSlice'
import { useStake } from '@/hooks/useStake'
import { useEffect, useState } from 'react'
import useAxios from '@/hooks/useAxios'
import {
	becomeFanService,
	checkAllFanRequirementsService
} from '@/services/superfan.service'

export interface FanRequirement {
	id: number
	name: string
	threshold: number
	completed: boolean
}

interface AllFanRequirementsResult {
	completed: boolean
	requirements: FanRequirement[]
}

export const useBecomeSuperFanDialog = () => {
	const dispatch = useDispatch()
	const [fanRequirements, setFanRequirements] =
		useState<AllFanRequirementsResult>({
			completed: false,
			requirements: []
		})
	const { becomeSuperfanDialog, user } = useSelector(
		(state: RootState) => state.signIn
	)
	const { newSuperfanEvent } = useSelector(
		(state: RootState) => state.websocket
	)
	const { addStakeHandler, stakeInProgress } = useStake()
	const { axiosServiceSync } = useAxios()

	const getAllFanRequirements = async (projectId: string) => {
		const result = await axiosServiceSync(
			checkAllFanRequirementsService(projectId)
		)
		setFanRequirements(result)
	}

	const becomeSuperfan = async (projectId: string) => {
		await axiosServiceSync(becomeFanService(projectId))
	}

	const getFanRequirement = (id: number) => {
		return fanRequirements.requirements.find(
			requirement => requirement.id === id
		)
	}
	const stakeRequirement = getFanRequirement(1)
	const followRequirement = getFanRequirement(2)
	const { open, projectTitle, projectId, isOnPerksProgram } =
		becomeSuperfanDialog
	const balanceEnough = user?.balance >= (stakeRequirement?.threshold ?? 0)
	const isSuperfan = user?.isSuperfan

	const handleClose = () => {
		dispatch(closeBecomeSuperfanDialog())
	}

	const handleBecomeSuperFan = () => {
		if (!stakeRequirement?.completed) {
			addStakeHandler(stakeRequirement?.threshold ?? 0, projectId)()
		} else {
			becomeSuperfan(projectId)
		}
	}

	const showFanPurchaseDialog = () => {
		dispatch(setFanPurchanseDialogOpen(true))
		handleClose()
	}

	useEffect(() => {
		if (newSuperfanEvent?.projectId === projectId) {
			setTimeout(() => {
				handleClose()
			}, 1000)
		}
	}, [newSuperfanEvent])

	useEffect(() => {
		if (open) {
			getAllFanRequirements(projectId)
		}
	}, [open, projectId])

	return {
		open,
		isSuperfan,
		projectTitle,
		balanceEnough,
		stakeInProgress,
		stakeRequirement,
		isOnPerksProgram,
		followRequirement,
		showFanPurchaseDialog,
		handleBecomeSuperFan,
		handleClose
	}
}
