'use client'
'use client'

import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import profileImages from 'assets/images/profile'
import styles from './ProjectActivityItem.module.scss'
import { formatDate } from 'utils/date.utils'
import { ACTIVITY_TYPES } from '../DashboardTab'
import { Icon } from '@mui/material'

const ProjectActivityItem = ({ project, createdAt, type }) => {
	const MESSAGE_MAP = {
		[ACTIVITY_TYPES.stake]: 'Staked to',
		[ACTIVITY_TYPES.follow]: 'Followed',
		[ACTIVITY_TYPES.share]: 'Shared'
	}

	const ICON_MAP = {
		[ACTIVITY_TYPES.stake]: 'fan-stake',
		[ACTIVITY_TYPES.follow]: 'heart-circle-plus',
		[ACTIVITY_TYPES.share]: 'share'
	}

	const ICON_KIT_MAP = {
		[ACTIVITY_TYPES.stake]: 'fa-kit',
		[ACTIVITY_TYPES.follow]: 'fal',
		[ACTIVITY_TYPES.share]: 'fal'
	}

	return (
		<>
			<div className={styles.activity_item}>
				<Icon
					baseClassName={ICON_KIT_MAP[type]}
					className={`fa-${ICON_MAP[type]}`}
				/>
				<div className={styles.follow_text}>
					<h6>
						{MESSAGE_MAP[type]}
						<Link to={`/project/${project?.slug}`}> {project?.title}</Link>
					</h6>
					<p className={styles.pubdate}>{formatDate(createdAt, 'med')}</p>
					<p className={styles.logline}>{project?.logline}</p>
				</div>
				<img
					src={project?.poster || profileImages.noActivityPoster}
					alt="cover"
					className={styles.poster}
				/>
			</div>
		</>
	)
}

ProjectActivityItem.propTypes = {
	project: PropTypes.object,
	createdAt: PropTypes.string,
	type: PropTypes.string
}

export default ProjectActivityItem
