import { cn } from '@/lib/utils'
import images from 'assets/images/contest'
import { getFileUrl } from 'utils/url.util'

export const ActiveContestBadge = ({
	src,
	className
}: {
	src: string
	className?: string
}) => {
	const imgSrc = getFileUrl(src)
	return (
		<img
			className={cn('max-w-auto h-[15.8px]', className)}
			src={imgSrc ?? images.contestBadge}
			alt="contest-badge"
		/>
	)
}
