import PropTypes from 'prop-types'
import { CustomDialog, TextArea, TextFieldControl } from '@/components/common'
import { Box, Button, Icon, IconButton, Typography } from '@mui/material'
import { useSocialShareSection } from 'hooks/useSocialShareSection'
import styles from './SocialShareSection.module.scss'

export const SocialShareSection = () => {
	const {
		open,
		link,
		message,
		limitChars,
		handleOpenDialog,
		setMessage,
		handleClose,
		handleCopy,
		handleCopyLink,
		handleCopyMobile
	} = useSocialShareSection()

	return (
		<>
			<Typography className={styles.shareHeader}>
				Help Film.io grow by inviting your friends to join! For every person who
				creates an account, you&apos;ll be awarded in FAN. Click the icons below
				to share your unique link with your friends and family!
			</Typography>
			<Box className={styles.shareSection}>
				<Box className={styles.emailShre}>
					<IconButton
						aria-label="mail"
						className={styles.walletBtn}
						onClick={handleCopyLink}
					>
						<Box className={styles.backDrop}>
							<Icon baseClassName="fas" className="fa-envelope" />
						</Box>
					</IconButton>
					<Typography>Copy Link</Typography>
				</Box>
				<Box className={styles.customShre}>
					<IconButton
						aria-label="custom"
						className={styles.walletBtn}
						onClick={handleOpenDialog('custom')}
					>
						<Box className={styles.backDrop}>
							<Icon baseClassName="fas" className="fa-hammer-brush" />
						</Box>
					</IconButton>
					<Typography>Custom</Typography>
				</Box>
				<Box className={styles.facebookShre}>
					<IconButton
						aria-label="facebook"
						onClick={handleOpenDialog('facebook')}
						className={styles.walletBtn}
					>
						<Box className={styles.backDrop}>
							<Icon baseClassName="fab" className="fa-facebook" />
						</Box>
					</IconButton>
					<Typography>Facebook</Typography>
				</Box>
				<Box className={styles.twitterShre}>
					<IconButton
						aria-label="x"
						onClick={handleOpenDialog('x')}
						className={styles.walletBtn}
					>
						<Box className={styles.backDrop}>
							<Icon baseClassName="fab" className="fa-x-twitter" />
						</Box>
					</IconButton>
					<Typography>Twitter/X</Typography>
				</Box>
				<Box className={styles.linkedinShre}>
					<IconButton
						aria-label="linkedin"
						onClick={handleOpenDialog('linkedin')}
						className={styles.walletBtn}
					>
						<Box className={styles.backDrop}>
							<Icon baseClassName="fab" className="fa-linkedin" />
						</Box>
					</IconButton>
					<Typography>LinkedIn</Typography>
				</Box>
			</Box>

			{open && (
				<CustomDialog {...{ open, handleClose, variant: 'edit_tweet' }}>
					<Box className={styles.edit_tweet}>
						<Typography component={'h2'}>Share</Typography>
						<TextArea
							label="Message"
							minRows={3}
							maxRows={5}
							aria-label="Message"
							placeholder="Message"
							value={message}
							limitChars={limitChars}
							onChange={e => {
								if (e.target.value?.length <= limitChars)
									setMessage(e.target.value)
							}}
						/>
						<a href="#" onClick={handleCopyMobile} className={styles.copy_link}>
							<Icon baseClassName="far" className="fa-copy" /> Copy Your Message
						</a>
						<Box className={styles.link}>
							<TextFieldControl
								classes={{ root: styles.first_name }}
								hiddenLabel
								disabled
								label="Link"
								value={link}
							/>
						</Box>
						<Box className={styles.note}>
							When you click <b>share</b> your message will be copied to the
							clipboard. Simply paste it into the share box at your social
							network of choice.
						</Box>
						<Box className={styles.footer}>
							<Button onClick={handleClose}>Cancel</Button>
							<Button onClick={handleCopy} className={styles.share_btn}>
								Share!
							</Button>
						</Box>
					</Box>
				</CustomDialog>
			)}
		</>
	)
}

SocialShareSection.propTypes = {
	isDraft: PropTypes.bool,
	closeMenu: PropTypes.func
}
