import { IconButton, Icon, Button, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { TextArea } from '@/components/common/textArea'
import useAddNewsreelForm from './useAddNewsreelForm'
import styles from './AddNewsreelForm.module.scss'
import { SelectBox } from '@/components/common'

const renderThumbnails = (files, removeImg) => {
	const urlList = files.map(file => URL.createObjectURL(file))
	return urlList.map((src, i) => (
		<Box className={styles.thumbnail} key={`thumbnail-${i}`}>
			<IconButton
				size="small"
				onClick={() => {
					removeImg(i)
				}}
			>
				<Icon baseClassName="fas" className="fa-light fa-xmark" />
			</IconButton>
			<img src={src} alt="" />
		</Box>
	))
}

const Input = styled('input')({
	display: 'none'
})

const AddNewsreelForm = () => {
	const {
		clearForm,
		removeImg,
		handleAddNewsreel,
		handleUploadClick,
		handleContentChange,
		handleSelectedOption,
		selectedOption,
		parentOptions,
		content,
		errors,
		media
	} = useAddNewsreelForm()

	return (
		<Box className={styles.add_newsreel_form}>
			<Box className={styles.header}>
				<h5>Create a new post</h5>
				<SelectBox
					{...{
						options: parentOptions,
						value: selectedOption || '',
						variant: 'blue',
						displayField: 'title',
						imageField: 'poster',
						setValue: handleSelectedOption
					}}
				/>
			</Box>
			<Box>
				<TextArea
					error={errors?.content}
					aria-label="What say you?"
					placeholder="What say you?"
					value={content}
					onChange={handleContentChange}
				/>
				<Box className={styles.thumbnails}>
					{renderThumbnails(media, removeImg)}
				</Box>
				<Box className={styles.actions}>
					<label htmlFor="icon-button-file">
						<Input
							accept="image/*"
							id="icon-button-file"
							type="file"
							onChange={handleUploadClick}
							multiple
							autoFocus
						/>
						<IconButton aria-label="upload picture" component="span">
							<Icon baseClassName="fas" className="fa-light fa-images" />
						</IconButton>
					</label>
					<Box>
						<Button
							variant="text"
							className="cancel-btn"
							size="small"
							onClick={clearForm}
						>
							Clear
						</Button>
						<Button
							onClick={handleAddNewsreel}
							className="yellow-bg-button"
							size="small"
						>
							Post
						</Button>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

AddNewsreelForm.propTypes = {
	feature: PropTypes.string
}

export default AddNewsreelForm
