import { ethers } from 'ethers'
import _ from 'lodash'
import { jsonRpcProvider, defaultNetwork } from './constants'
import axios from 'axios'
import { prepareContractTransaction, getMagicWalletAddress } from '../magic.js'
import { logger } from '@/utils/logger'

const env = process.env.NEXT_PUBLIC_ENVIRONMENT || 'development'

export const getTransactionReceipt = async (
	transactionHash,
	network = defaultNetwork,
	confirmations = 1
) => {
	if (typeof transactionHash === 'undefined' || transactionHash === undefined) {
		return false
	}

	await waitForTransaction(transactionHash, network, confirmations)
	return await jsonRpcProvider(network).getTransactionReceipt(transactionHash)
}

export const waitForTransaction = async (
	hash,
	network = defaultNetwork,
	confirmations = 1
) => {
	await jsonRpcProvider(network).waitForTransaction(hash, confirmations)
}

export const retrieveEventArgs = async (
	txHash,
	eventName,
	contractABI,
	txReceipt = null
) => {
	const receipt = txReceipt ?? (await getTransactionReceipt(txHash))

	const isValidEvent = item => {
		const fragmentItem = ethers.utils.Fragment.from(item)
		if (_.isNil(fragmentItem.name) || _.isNil(fragmentItem.type)) {
			return false
		}

		return fragmentItem.name === eventName && fragmentItem.type === 'event'
	}

	const isValidEventInReceipt = item => {
		try {
			eventInterface.parseLog(item)
			return true
		} catch {
			return false
		}
	}

	const abiInterface = new ethers.utils.Interface(contractABI) // this is contract's ABI
	const humanReadableABI = abiInterface.format(ethers.utils.FormatTypes.full) // convert to human readable ABI
	if (typeof humanReadableABI === 'string') {
		throw Error('Invalid ABI!')
	}

	const abiFragments = humanReadableABI.map(item =>
		ethers.utils.Fragment.from(item)
	)

	const eventFragment = abiFragments.filter(isValidEvent)

	if (eventFragment.length === 0) {
		throw Error('Invalid Given Event!')
	}

	const eventInterface = new ethers.utils.Interface(eventFragment)

	const eventLogs = receipt.logs.filter(isValidEventInReceipt)

	return eventLogs.map(log => eventInterface.parseLog(log).args)
}

export const getNetworkFeesData = async () => {
	let maxFeePerGas = ethers.BigNumber.from(100000000000).toBigInt() // fallback to 100 gwei
	let maxPriorityFeePerGas = ethers.BigNumber.from(100000000000).toBigInt() // fallback to 100 gwei

	// get max fees from gas station
	try {
		const { data } = await axios({
			method: 'get',
			url:
				env === 'production'
					? 'https://gasstation.polygon.technology/v2'
					: 'https://gasstation.polygon.technology/amoy'
		})

		maxFeePerGas = ethers.utils
			.parseUnits(
				Math.ceil(
					Number(data.fast.maxFee) + Number(data.fast.maxPriorityFee)
				).toString(),
				'gwei'
			)
			.toBigInt()

		maxPriorityFeePerGas = ethers.utils
			.parseUnits(
				Math.ceil(
					Number(data.fast.maxPriorityFee) + Number(data.fast.maxPriorityFee)
				).toString(),
				'gwei'
			)
			.toBigInt()
	} catch { }

	return {
		maxFeePerGas,
		maxPriorityFeePerGas
	}
}

export const signVerificationMessage = async (signer, message) => {
	const hash = ethers.utils.hashMessage(message)
	const signature = await signer.signMessage({ message })

	return {
		hash,
		signature
	}
}

export const getTransactionFee = async (
	signer,
	contractName,
	functionName,
	functionArgs,
	network = defaultNetwork
) => {
	try {
		const gasPrice = (await jsonRpcProvider(network).getGasPrice()).toBigInt()

		const transaction = await prepareContractTransaction(
			contractName,
			functionName,
			functionArgs,
			'',
			network
		)

		const estimate = (
			await jsonRpcProvider(network).estimateGas({
				...transaction,
				nonce: await jsonRpcProvider(network).getTransactionCount(
					signer?.account?.address
				),
				from: signer?.account?.address,
				gasPrice
			})
		).toBigInt()

		const transactionFee = Number(ethers.utils.formatEther(gasPrice * estimate))
		const usdValue = await maticToUsd(transactionFee)

		return {
			transactionFee,
			usdValue
		}
	} catch (e) {
		logger.error(contractName, functionName, functionArgs, e)
		return {
			transactionFee: -1,
			usdValue: -1
		}
	}
}

export const maticToUsd = async matic => {
	const { data } = await axios({
		method: 'get',
		url: 'https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd'
	})
	return Number(data['matic-network'].usd) * Number(matic)
}

export const getNativeTokenBalance = async (
	address,
	network = defaultNetwork
) => {
	const balance = await jsonRpcProvider(network).getBalance(address)
	return Number(ethers.utils.formatEther(balance))
}

export const getCurrentActiveWalletAddress = async wagmiSigner => {
	try {
		const magicWalletAddress = await getMagicWalletAddress()
		return magicWalletAddress
	} catch {
		logger.error('No Magic Wallet detected!')
	}

	try {
		const ncWalletAddress = wagmiSigner.account.address
		return ncWalletAddress
	} catch {
		logger.error('No NC Wallet detected!')
		return ''
	}
}
