import { createSlice } from '@reduxjs/toolkit'
import { dashboardItemTypes } from '@/components/common'

const initialState = {
	sort: 'trending',
	filter: {
		types: ['project'],
		stages: [],
		genres: []
	},
	daoProposalUpdated: null,
	types: dashboardItemTypes,
	reloadProposals: false,
	stages: [],
	genres: [],
	items: [],
	page: {
		value: 0,
		reload: true
	}
}

export const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		setSort: (state, { payload }) => {
			state.sort = payload
		},
		clearFilters: state => {
			state.filter = initialState.filter
		},
		removeFilter: (state, { payload }) => {
			const { option, id } = payload
			state.filter[option] = state.filter[option].filter(el => el !== id)
		},
		updateFilters: (state, { payload }) => {
			state.filter = {
				...state.filter,
				...payload
			}
		},
		clearItems: state => {
			state.items = initialState.items
		},
		updateItems: (state, { payload }) => {
			const newItems = state.items.map(item => {
				const updatedItem = payload.find(p => p.id === item.id)
				return updatedItem || item
			})

			// Add new items that don't exist in the current state
			const itemsToAdd = payload.filter(
				p => !state.items.some(item => item.id === p.id)
			)

			state.items = [...newItems, ...itemsToAdd]
		},
		setStages: (state, { payload }) => {
			state.stages = payload
		},
		setGenres: (state, { payload }) => {
			state.genres = payload
		},
		setDaoProposalUpdated: (state, { payload }) => {
			state.daoProposalUpdated = payload
		},
		setReloadProposals: state => {
			state.reloadProposals = true
		},
		clearReloadProposals: state => {
			state.reloadProposals = false
		},
		setPage: (state, { payload }) => {
			state.page = { value: payload, reload: !state.page.reload }
		}
	}
})

export const {
	setSort,
	setStages,
	setGenres,
	clearItems,
	clearFilters,
	setDaoProposalUpdated,
	clearReloadProposals,
	setReloadProposals,
	updateFilters,
	removeFilter,
	updateItems,
	setPage
} = dashboardSlice.actions

export default dashboardSlice.reducer
