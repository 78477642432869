import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { claimAllUserFgrService, getUserFgrService } from 'services/fgr.service'
import useNotification from 'hooks/useNotification'
import { Mixpanel } from 'services/mixpanel.service'

const useFgrOverview = () => {
	const [userFgr, setUserFgr] = useState({
		claimed: 0,
		unclaimed: 0
	})
	const { reloadTransactions } = useSelector(state => state.walletTab)
	const { userId } = useSelector(state => state.profile)
	const { user: signInUser } = useSelector(state => state.signIn)
	const { axiosService } = useAxios()
	const { showNotification } = useNotification()

	const reloadFgrOverview = () => {
		axiosService(getUserFgrService(), (result, error) => {
			if (!error) {
				setUserFgr(result)
			}
		})
	}

	const claimAllUserFgr = () => {
		showNotification({ message: 'Claiming all unclaimed fgr' })
		axiosService(claimAllUserFgrService(), (result, error) => {
			if (!error) {
				Mixpanel.track('user_fgr_claim_all_event', {
					distinct_id: signInUser.id,
					displayName: signInUser.displayName,
					username: signInUser.username,
					fgrClaimed: result?.total ?? userFgr.unclaimed,
					userId
				})
				reloadFgrOverview()
				showNotification({ message: 'All unclaimed fgr claimed' })
			}
		})
	}

	useEffect(() => {
		if (!_.isNil(userId)) reloadFgrOverview()
	}, [userId, reloadTransactions])

	return { userFgr, claimAllUserFgr }
}

export default useFgrOverview
