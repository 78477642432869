import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import InfiniteScroll from 'react-infinite-scroll-component'
import { NewsreelItem, TRANSACTION_TYPES } from '@/components/common'
import { ReviewItem } from '@/components/projectDetail/reviewsTab'
import { ActivityItem } from '../activityItem'
import { StakeItem } from '../stakeItem'
import useNewsreelElements from './useNewsreelElements'
import images from 'assets/images'
import styles from './NewsreelItems.module.scss'

const NewsreelElements = () => {
	const { items, onScroll } = useNewsreelElements()

	const emptyMessage =
		'You need to follow some projects and creators to begin seeing posts here on your newsreel.'

	const components = {
		newsreel: NewsreelItem,
		review: ReviewItem,
		comment: ActivityItem,
		transaction: ActivityItem,
		commentReaction: ActivityItem,
		stakeReaction: ActivityItem,
		reviewReaction: ActivityItem,
		newsreelReaction: ActivityItem,
		socialShare: ActivityItem,
		stake: StakeItem
	}

	const renderItems = () => {
		return items.map(el => {
			const record = el?.record
			let type = el?.type
			if (
				type === 'transaction' &&
				record?.typeId === TRANSACTION_TYPES.TOKEN_STAKE
			)
				type = 'stake'
			const Component = components[type]
			const props = { ...record, fromNewsreel: true, type, showInfo: true }
			return !_.isNil(Component) ? <Component {...props} key={el.id} /> : ''
		})
	}

	return (
		<InfiniteScroll {...{ onScroll, dataLength: 10000000 }}>
			{items.length > 0 ? (
				renderItems()
			) : (
				<div className={styles.message_box}>
					<img alt="" src={images.newsreelEmpty} />
					<Typography component={'h1'}>
						{emptyMessage} Go to the <Link to={`/explore/`}>Explore Page</Link>{' '}
						to find projects to follow!
					</Typography>
				</div>
			)}
		</InfiniteScroll>
	)
}

NewsreelElements.propTypes = {}

export default NewsreelElements
