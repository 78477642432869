import React from 'react'

interface ProjectTitleProps {
	children: React.ReactNode
	projectId: string
}
export const ProjectTitle = ({ children }: ProjectTitleProps) => (
	<div className="flex items-end justify-start gap-2">
		<h1 className="mb-2 text-3xl font-bold text-white md:text-4xl">
			{children}
		</h1>
	</div>
)
