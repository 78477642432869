import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Icon, Typography, useMediaQuery } from '@mui/material'
import { CircularProgressBar } from '@/components/common/circularProgressBar'
import styles from './Stage.module.scss'

const Stage = ({
	ringImage,
	centerImage,
	name,
	color,
	progress,
	stagemetrics,
	...props
}) => {
	const [isHover, setIsHover] = useState(false)
	const isMobileScreen = useMediaQuery('(max-width:420px)')

	const handleMouseEnter = () => {
		setIsHover(true)
	}
	const handleMouseLeave = () => {
		setIsHover(false)
	}

	const renderMetrics = (metrics = []) =>
		metrics.map(sm => {
			const completed = sm.isCompleted ? styles.completed : ''
			let base = ''

			switch (sm.metricId) {
				case 3:
					base = 'fas'
					break
				case 5:
					base = 'fa-kit'
					break
				default:
					base = 'fal'
			}

			// const base = [3].includes(sm.metricId) ? 'fas' : 'fal'

			return (
				<span key={`metric-${sm.metricId}`}>
					<Icon baseClassName={base} className={`fa-${sm.icon} ${completed}`} />
				</span>
			)
		})

	const progressHeight = isMobileScreen ? 60 : 80

	return (
		<Box
			className={styles.stage_container}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			{...props}
		>
			{progress >= 100 ? (
				<Icon baseClassName="fas" className="fa-check" />
			) : (
				<Typography className={styles.percent}>
					{progress?.toFixed(0)}%
				</Typography>
			)}
			<Box
				className={styles.stage}
				style={{
					boxShadow: isHover ? `0 0 0.9rem 0.4rem ${color}` : 'none'
				}}
			>
				<CircularProgressBar
					{...{
						height: progressHeight,
						lineWidth: 8,
						lineColor: color,
						background: '#121212',
						lineEmptyColor: '#121212',
						time: 0.5,
						value: progress
					}}
				/>
				<img alt="" src={ringImage} className={styles.ring_image} />
				<img alt="" src={centerImage} className={styles.center_image} />
			</Box>
			<Typography className={styles.text}>{name}</Typography>
			<Box className={styles.metrics}>{renderMetrics(stagemetrics)}</Box>
		</Box>
	)
}

Stage.propTypes = {
	ringImage: PropTypes.string,
	centerImage: PropTypes.string,
	name: PropTypes.string,
	color: PropTypes.string,
	progress: PropTypes.number,
	stagemetrics: PropTypes.array
}

export default Stage
