export const updateFanNotifiedService = (data: any) => ({
	method: 'put',
	url: '/userFanLevel/updateNotified',
	data
})

export const updateFanNotifiedAllService = (data: any) => ({
	method: 'put',
	url: '/userFanLevel/updateNotified/all',
	data
})

export const getProjectSuperfanLeaderboard = (params: any) => ({
	method: 'get',
	url: `/userFanLevel/leaderboard/${params?.projectId}`,
	params
})
