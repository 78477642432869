'use client'

import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle
} from '@/components/ui/dialog'
import { cn } from '@/lib/utils'
import { Description } from '@radix-ui/react-dialog'
import { ReactNode, useEffect } from 'react'

interface CustomDialogProps {
	open: boolean
	title?: string
	icon?: ReactNode
	children: ReactNode
	className?: string
	titleClassName?: string
	onOpenChange: (_open: boolean) => void
}

export function CustomDialog({
	open,
	icon,
	title,
	children,
	className,
	titleClassName,
	onOpenChange
}: CustomDialogProps) {
	const cleanup = () => {
		document.body.style.removeProperty('pointer-events')
	}
	useEffect(() => {
		if (!open) {
			cleanup()
		}
		return cleanup
	}, [open])
	return (
		<Dialog open={open} onOpenChange={onOpenChange}>
			<DialogContent
				aria-describedby={title}
				onPointerDownOutside={e => {
					e.preventDefault()
				}}
				className={cn(
					'w-[calc(100vw-16px)] max-w-[550px] gap-4 rounded-lg border border-[#1b568c] bg-[#0A1C2A] p-4 font-inter sm:p-6',
					className
				)}
			>
				{title && (
					<DialogHeader className="m-0 p-0">
						<DialogTitle
							className={cn(
								'm-0 text-left text-lg text-white sm:text-xl',
								titleClassName
							)}
						>
							{icon}
							{title}
						</DialogTitle>
					</DialogHeader>
				)}
				<Description className="sr-only">{title}</Description>
				{children}
			</DialogContent>
		</Dialog>
	)
}
