import { useMediaQuery } from '@mui/material'
import { NewsreelMobileMenu } from './newsreelMobileMenu'
import { SidebarMenu } from './sidebarMenu'
import styles from './SidebarNewsreel.module.scss'

const SidebarNewsreel = () => {
	const isMobileScreen = useMediaQuery('(max-width:430px)')

	return (
		<>
			{isMobileScreen ? (
				<NewsreelMobileMenu />
			) : (
				<section className={styles.sidebar_newsreel}>
					<SidebarMenu />
				</section>
			)}
		</>
	)
}

export default SidebarNewsreel
