import useAxios from 'hooks/useAxios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getNotificationsService,
	getUnvisiteNotificationCountService
} from 'services/notification.service'
import {
	setNotificationsCount,
	setTransactionsInProgress
} from '../signInSlice'

export default function useNotifications() {
	const [anchorEl, setAnchorEl] = useState(null)
	const [notifications, setNotifications] = useState({
		rows: [],
		count: 0,
		unvisitedCount: 0
	})
	const {
		notificationsCount,
		user, 
		isAuthenticated
	} = useSelector(state => state.signIn)
	const dispatch = useDispatch()
	const { axiosService } = useAxios()

	const getNotifications = () => {
		axiosService(
			getNotificationsService({
				limit: 30,
				offset: 0
			}),
			(result, error) => {
				if (!error) {
					setNotifications(result)
					dispatch(setNotificationsCount(result?.unvisitedCount))
				}
			}
		)
	}

	const getUnvisiteNotificationCount = () => {
		if (!isAuthenticated) {
			return
		}
		axiosService(getUnvisiteNotificationCountService(), (result, error) => {
			if (!error) {
				const { transactionInProgress, unvisitedCount } = result
				dispatch(setTransactionsInProgress(transactionInProgress))
				dispatch(setNotificationsCount(unvisitedCount))
			}
		})
	}

	const handleOpen = event => {
		getNotifications()
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	useEffect(() => {
		if (anchorEl !== null) getNotifications()
	}, [anchorEl])

	return {
		user,
		anchorEl,
		notifications,
		notificationsCount,
		getUnvisiteNotificationCount,
		getNotifications,
		handleClose,
		handleOpen
	}
}
