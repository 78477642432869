'use client'
'use client'

import useNotification from 'hooks/useNotification'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getSocialShareLink } from 'utils/socialShare.util'

export const useSocialShareSection = () => {
	const [open, setOpen] = useState(false)
	const [socialNetwork, setSocialNetwork] = useState(null)
	const { user } = useSelector(state => state.signIn)
	const [message, setMessage] = useState('')
	const { showNotification } = useNotification()
	const baseUrl = window.location.origin
	const link = encodeURI(
		`${baseUrl}/join/${socialNetwork}/${user?.shortUrl}?utm_source=prd&utm_campaign=share&utm_medium=prof`
	)
	const limitChars = 280 - (user?.displayName?.length ?? 0) - link?.length - 3

	const handleCopy = () => {
		if (socialNetwork !== 'custom') {
			const socialShareLink = getSocialShareLink({ socialNetwork, link })
			navigator.clipboard.writeText(message).then(() => {
				const win = window.open(socialShareLink, '_blank')
				if (win !== null) {
					win.focus()
				}
			})
		} else {
			const shareUrl = `${baseUrl}/join/custom/${user?.shortUrl}?utm_source=prd&utm_campaign=share&utm_medium=prof`
			navigator.clipboard.writeText(`${message}\n${shareUrl}`).then(() => {
				showNotification({
					message:
						'Your referral link and message have been copied to your clipboard.'
				})
			})
			setOpen(false)
		}
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleOpenDialog = sn => () => {
		setOpen(true)
		setSocialNetwork(sn)
	}

	const handleCopyLink = () => {
		const shareUrl = `${baseUrl}/join/mail/${user?.shortUrl}?utm_source=prd&utm_campaign=share&utm_medium=prof`
		navigator.clipboard.writeText(shareUrl).then(() => {
			showNotification({
				message: 'Your referral link has been copied to your clipboard.'
			})
		})
	}

	const handleCopyMobile = () => {
		if (socialNetwork !== 'custom') {
			navigator.clipboard.writeText(message).then(() => {
				showNotification({
					message: 'Your message has been copied to your clipboard.'
				})
			})
		} else {
			const shareUrl = `${baseUrl}/join/mail/${user?.shortUrl}?utm_source=prd&utm_campaign=share&utm_medium=prof`

			navigator.clipboard.writeText(message + ' ' + shareUrl).then(() => {
				showNotification({
					message:
						'Your referral link and message have been copied to your clipboard.'
				})
			})

			setOpen(false)
		}
	}

	useEffect(() => {
		setMessage(
			`Join me at Film.io, the world’s most powerful decentralized filmmaking ecosystem. Be at the forefront of the community-led, cinematic revolution and help change filmmaking forever. #filmio`
		)
	}, [])

	return {
		open,
		link,
		message,
		limitChars,
		handleOpenDialog,
		setMessage,
		handleClose,
		handleCopy,
		handleCopyLink,
		handleCopyMobile
	}
}
