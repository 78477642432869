// components/shared/InteractionsBar.tsx
import { Button } from '@/components/ui/button'
import { ChatBubbleIcon } from '@radix-ui/react-icons'
import { Reaction } from '../../types'
import { ReactionList, Reactions } from './Reactions'

type InteractionType = 'newsreel' | 'review' | 'stake' | 'comment'

interface BaseInteractionsBarProps {
	id: string
	type: InteractionType
	reactions: Reaction[]
	setReactions: (_reactions: Reaction[]) => void
}

interface WithCommentsProps extends BaseInteractionsBarProps {
	hasComments: true
	commentsCount: number
	onCommentClick: () => void
}

interface WithoutCommentsProps extends BaseInteractionsBarProps {
	hasComments: false
	commentsCount?: never
	onCommentClick?: never
}

type InteractionsBarProps = WithCommentsProps | WithoutCommentsProps

export const InteractionsBar = ({
	id,
	type,
	commentsCount,
	reactions,
	hasComments,
	setReactions,
	onCommentClick
}: InteractionsBarProps) => (
	<div className="flex flex-col-reverse items-start gap-1 pt-2 sm:flex-row sm:items-center sm:gap-2">
		<div className="flex flex-row-reverse items-center justify-end gap-1 sm:flex-row">
			{hasComments ? (
				<Button
					variant="ghost"
					size="sm"
					className="text-gray-400"
					onClick={onCommentClick}
				>
					<ChatBubbleIcon className="mr-1 h-4 w-4 sm:mr-1.5" />
					{commentsCount}
				</Button>
			) : null}
			<Reactions
				feedId={id}
				type={type}
				setReactions={setReactions}
				className="ml-auto"
			/>
		</div>
		<div>
			<ReactionList
				reactions={reactions}
				feedId={id}
				type={type}
				setReactions={setReactions}
			/>
		</div>
	</div>
)
