import useAxios from '@/hooks/useAxios'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useHeader from '../useHeader'
import { RootState } from '@/store/store'
import { setYouAreSuperfanProjectDialogData } from '../signInSlice'
import { updateFanNotifiedAllService } from '@/services/userFanLevel.service'

export const useBecomeSuperFanFirstDialog = () => {
	const { axiosServiceSync } = useAxios()
	const { youAreSuperfanProjectDialogData } = useSelector(
		(state: RootState) => state.signIn
	)

	const { user: signInUser } = useSelector((state: RootState) => state.signIn)

	const { newSuperfanEvent } = useSelector(
		(state: RootState) => state.websocket
	)

	const dispatch = useDispatch()
	const { showConfetti } = useHeader()
	const {
		open,
		title,
		isOnPerksProgram,
		projectId,
		createdAt,
		isFirstSuperfan
	} = youAreSuperfanProjectDialogData

	const updateSuperfanNotified = () => {
		axiosServiceSync(updateFanNotifiedAllService())
	}

	const handleClose = () => {
		dispatch(setYouAreSuperfanProjectDialogData({ open: false }))
	}

	useEffect(() => {
		if (open) {
			updateSuperfanNotified()
			showConfetti()
		}
	}, [open])

	useEffect(() => {
		if (newSuperfanEvent?.projectId) {
			dispatch(
				setYouAreSuperfanProjectDialogData({
					open: true,
					...newSuperfanEvent
				})
			)
		}
	}, [newSuperfanEvent])

	return {
		open,
		title,
		projectId,
		createdAt,
		signInUser,
		isFirstSuperfan,
		isOnPerksProgram,
		handleClose
	}
}
