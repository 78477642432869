import { useState } from 'react'
import { Coins, Link } from 'lucide-react'
import { Card, CardContent } from '@mui/material'
import { useCommentHandler } from '../hooks/useCommentHandler'
import { FeedItem } from '../types'
import { CommentInput } from './shared/CommentInput'
import { InteractionsBar } from './shared/InteractionsBar'
import { UserAvatar } from './shared/UserAvatar'
import { UserInfo } from './shared/UserInfo'
import { Comment } from './shared/Comment'

export const StakeItem = ({ item }: { item: FeedItem & { type: 'stake' } }) => {
	const {
		id,
		user,
		createdAt,
		project,
		amount,
		comments,
		reactions,
		blockchainHash
	} = item.record

	const [itemComments, updateComments] = useState(comments)
	const [itemReactions, setItemReactions] = useState(reactions)
	const [expandedComments, setExpandedComments] = useState(false)
	const { comment, handleComment, handleSubmitComment, clearComment } =
		useCommentHandler(item.record.id, 'stake', comment => {
			updateComments(prev => [comment, ...prev])
		})

	return (
		<Card className="border-none bg-transparent p-0">
			<CardContent className="p-0">
				<div className="flex flex-col items-start gap-3 overflow-hidden rounded-md bg-[#2196F3]/15 p-4">
					<div className="flex items-start gap-3">
						<UserAvatar user={user} />
						<UserInfo user={user} createdAt={createdAt} project={project} />
					</div>
					<div className="w-full">
						<div className="flex items-center gap-2">
							<Coins className="h-5 w-5 text-yellow-400" />
							<p className="text-white">
								Staked{' '}
								<span className="font-semibold">
									{amount.toLocaleString()} FAN
								</span>{' '}
								tokens on{' '}
								<Link
									to={`/project/${project.slug}`}
									className="text-blue-400 hover:underline"
								>
									{project.title}
								</Link>
							</p>
						</div>
						{blockchainHash && (
							<a
								href={`https://etherscan.io/tx/${blockchainHash}`}
								target="_blank"
								rel="noopener noreferrer"
								className="mt-2 inline-block text-sm text-gray-400 hover:text-blue-400"
							>
								View on Etherscan ↗
							</a>
						)}
						<InteractionsBar
							id={id}
							type={item.type}
							hasComments
							commentsCount={itemComments.length}
							reactions={itemReactions}
							setReactions={setItemReactions}
							onCommentClick={() => setExpandedComments(prev => !prev)}
						/>
					</div>
					<CommentInput
						value={comment}
						onChange={handleComment}
						onSubmit={handleSubmitComment}
						onClear={clearComment}
					/>
					{expandedComments && (
						<div className="ml-2 w-full divide-y">
							{itemComments.map(comment => (
								<Comment key={comment.id} comment={comment} />
							))}
						</div>
					)}
				</div>
			</CardContent>
		</Card>
	)
}
