import { useState, useEffect } from 'react'

interface CountdownProps {
	endDate: string
	className?: string
}

export const Countdown = ({ endDate, className }: CountdownProps) => {
	const [timeLeft, setTimeLeft] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0
	})

	useEffect(() => {
		const calculateTimeLeft = () => {
			const difference = new Date(endDate).getTime() - new Date().getTime()

			if (difference > 0) {
				setTimeLeft({
					days: Math.floor(difference / (1000 * 60 * 60 * 24)),
					hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
					minutes: Math.floor((difference / 1000 / 60) % 60),
					seconds: Math.floor((difference / 1000) % 60)
				})
			}
		}

		const timer = setInterval(calculateTimeLeft, 1000)
		calculateTimeLeft() // Initial calculation

		return () => clearInterval(timer)
	}, [endDate])

	return (
		<span className={className}>
			{timeLeft.days}d {timeLeft.hours}:{timeLeft.minutes}:{timeLeft.seconds}
		</span>
	)
}
