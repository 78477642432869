import { getContractValue, sendContractBlockchainTransaction } from '../magic'
import { getTransactionFee } from '../config/utils'
import { truncateToDecimals } from 'utils/form.util'
import { ethers } from 'ethers'

class FanTokenContract {
	static contractName = 'fanToken'

	// Write Functions
	static async transfer(
		destinationWalletAddress,
		amount,
		transactionNote,
		signer = null
	) {
		const trancatedAmount = truncateToDecimals(amount, 4)
		return await sendContractBlockchainTransaction(
			FanTokenContract.contractName,
			'transfer',
			[
				destinationWalletAddress,
				ethers.utils.parseUnits(trancatedAmount.toString(), 8)
			],
			transactionNote,
			signer
		)
	}

	static async getFeesTransfer(to, amount, signer) {
		return await getTransactionFee(
			signer,
			FanTokenContract.contractName,
			'transfer',
			[to, ethers.utils.parseUnits(amount.toString(), 8)]
		)
	}

	static async approve(spender, amount, signer = null) {
		const trancatedAmount = truncateToDecimals(amount, 4)
		return await sendContractBlockchainTransaction(
			FanTokenContract.contractName,
			'approve',
			[spender, ethers.utils.parseUnits(trancatedAmount.toString(), 8)],
			null,
			signer
		)
	}

	static async getFeesApprove(spender, amount, signer) {
		return await getTransactionFee(
			signer,
			FanTokenContract.contractName,
			'approve',
			[spender, ethers.utils.parseUnits(amount.toString(), 8)]
		)
	}

	// Read Functions
	static async balanceOf(address) {
		return await getContractValue(FanTokenContract.contractName, 'balanceOf', [
			address
		])
	}

	static async allowance(owner, spender) {
		return await getContractValue(FanTokenContract.contractName, 'allowance', [
			owner,
			spender
		])
	}

	static async isBlacklisted(address) {
		return await getContractValue(
			FanTokenContract.contractName,
			'isblacklistedAccount',
			[address]
		)
	}
}

export default FanTokenContract
