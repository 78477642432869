import { cn } from '@/lib/utils'
import { LuBadgeAlert } from 'react-icons/lu'
import { useState } from 'react'
import {
	Popover,
	PopoverTrigger,
	PopoverContent
} from '@/components/ui/popover'
import { formatDate } from 'utils/date.utils'
import infoBackground from 'assets/images/dao-tiers/info_background.svg'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'

type TierItemProps = {
	id: number
	className: string
	image: string
	title: string
	date: string
	completed: boolean
	confirmed: boolean
	pointsThreshold: number
	totalPoints: number
}

const TierItem = ({
	id,
	className,
	date,
	image,
	title,
	completed,
	confirmed,
	pointsThreshold,
	totalPoints
}: TierItemProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const { user } = useSelector((state: RootState) => state.signIn)
	const isEmailVerified = user?.isEmailVerified === 1
	const isKYCed = user?.isVerified === 1
	const handlePopoverOpen = () => {
		setIsOpen(true)
	}

	const renderMetrics = () => {
		const threshold = pointsThreshold
		const value = totalPoints
		const isCompleted = value >= threshold
		const isBronze = id === 2
		const isGold = id === 4

		return (
			<div>
				<p
					key={1}
					className={`text-xs ${
						isCompleted ? 'font-semibold text-green-500' : ''
					}`}
				>
					Superfan Points:
					<span>
						{' '}
						({value}/{threshold})
					</span>
				</p>
				{isBronze && (
					<p
						key={2}
						className={`text-xs ${
							isEmailVerified ? 'font-semibold text-green-500' : ''
						}`}
					>
						Setup Platform Wallet
					</p>
				)}
				{isGold && (
					<p
						key={3}
						className={`text-xs ${
							isKYCed ? 'font-semibold text-green-500' : ''
						}`}
					>
						Verified Account (KYC)
					</p>
				)}
			</div>
		)
	}
	const name = _.capitalize(title ?? '')
	return (
		<div className={cn('flex flex-col items-center', className)}>
			<div
				className={cn(
					'z-10 flex h-6 w-6 items-center justify-center rounded-full border',
					completed || confirmed
						? 'border-primary bg-primary text-transparent'
						: 'border-primary bg-primary text-transparent opacity-20'
				)}
			>
				<img src={image ? image : ''} alt="steps" className="h-4 w-4" />
			</div>
			<div className="text-xs font-semibold leading-5 text-white sm:text-base">
				<div className="mt-2 flex flex-col items-center justify-center gap-1">
					{title}
					<Popover open={isOpen} onOpenChange={setIsOpen}>
						<PopoverTrigger asChild>
							<button className="flex h-6 w-6 items-center justify-center rounded-md bg-secondary">
								<LuBadgeAlert onClick={handlePopoverOpen} />
							</button>
						</PopoverTrigger>
						<PopoverContent
							className="w-60 rounded-md bg-background p-4 text-center text-secondary-foreground shadow-lg"
							style={{ backgroundImage: `url(${infoBackground})` }}
						>
							<div className="mb-2 flex h-6 w-full items-center justify-center rounded-md bg-transparent">
								<LuBadgeAlert />
							</div>
							<div>
								<div className="mb-1 text-sm font-semibold">{name} Rank</div>
								{confirmed ? (
									<>
										<p className="text-xs">You achieved {name} Rank</p>
										<p className="text-xs">on {formatDate(date, 'full')}</p>
									</>
								) : (
									<>
										<div className="text-xs font-semibold">
											To Earn {name}, you need to have:
										</div>
										{renderMetrics()}
									</>
								)}
							</div>
						</PopoverContent>
					</Popover>
				</div>
			</div>
		</div>
	)
}

export default TierItem
