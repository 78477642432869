import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import styles from './DaoProposalItem.module.scss'
import { Box, Icon, IconButton, Typography } from '@mui/material'
import { getFileUrl } from 'utils/url.util'
import { CustomCountdown } from '@/components/common/customCountdown'
import { getNowDateDiff } from 'utils/date.utils'
import DaoProposalDialog from './daoProposalDialog/DaoProposalDialog'
import { useState } from 'react'
import useCommon from 'hooks/useCommon'
import { abbreviateNumberFixed } from 'utils/utils'

const DaoProposalItem = daoProposal => {
	const { checkNoAuthToShowJoin } = useCommon()
	const [open, setOpen] = useState(false)

	const handleOpen = () => {
		if (checkNoAuthToShowJoin()) {
			setOpen(true)
		}
	}

	const handleClose = () => {
		setOpen(false)
	}
	const {
		name,
		owner,
		description,
		totalVotesBalance,
		votedByUser,
		yesPercent,
		noPercent,
		endDate,
		poster
	} = daoProposal
	const dateDiff = getNowDateDiff(endDate).seconds
	const isExpired = dateDiff > 0
	const pillText = isExpired ? 'Closed' : 'Active'
	const pillClass = isExpired
		? `${styles.status_pill} ${styles.ended}`
		: styles.status_pill
	const buttonText = isExpired || votedByUser ? 'VIEW' : 'VOTE'
	const percent = Math.max(yesPercent, noPercent)
	const percentText = yesPercent >= noPercent ? 'Yes' : 'No'
	const finalText = yesPercent >= noPercent ? 'Accepted' : 'Rejected'
	const percentBackground =
		yesPercent >= noPercent
			? `linear-gradient(90deg, #01BF52, #01ACBD, #333333 ${percent}%);`
			: `linear-gradient(90deg, #e88b8b, #ef3e3e, #ff0000 ${percent}%)`

	return (
		<>
			<Box className={styles.proposal_item}>
				<Box className={styles.header}>
					<img
						loading="lazy"
						className={styles.poster}
						src={getFileUrl(poster + '?tr=w-276')}
						alt=""
					/>
				</Box>
				<Box className={styles.info}>
					<Box className={styles.top}>
						<CustomCountdown date={new Date(endDate)} variant="dao_proporsal" />
						<Box className={pillClass}>{isExpired ? finalText : pillText}</Box>
					</Box>
					<Box className={styles.votingWeight_container}>
						<IconButton>
							<Icon baseClassName="fa-kit" className="fa-fan-stake" />
						</IconButton>
						<Box className={styles.text_weight}>
							{abbreviateNumberFixed(totalVotesBalance)} FAN
						</Box>
						<Box
							className={styles.bar}
							sx={{
								background: percentBackground
							}}
						></Box>
						<Box className={styles.text_outcome}>{percentText}</Box>
					</Box>
				</Box>
				<Box className={styles.content}>
					<Typography component={'h3'} className={styles.owner}>
						{' '}
						{owner}{' '}
					</Typography>
					<Typography component={'h2'} className={styles.title}>
						{' '}
						{name}{' '}
					</Typography>
					<Typography component={'div'} className={styles.desc}>
						<div
							dangerouslySetInnerHTML={{
								__html: description.substring(0, 180) + '...'
							}}
						/>
					</Typography>
					<Button onClick={handleOpen}>{buttonText}</Button>
				</Box>
			</Box>
			{open && <DaoProposalDialog {...{ open, handleClose, daoProposal }} />}
		</>
	)
}

DaoProposalItem.propTypes = {
	owner: PropTypes.string,
	name: PropTypes.string,
	description: PropTypes.string,
	poster: PropTypes.string,
	endDate: PropTypes.string,
	votingWeight: PropTypes.number,
	yesPercent: PropTypes.number,
	id: PropTypes.object
}

export default DaoProposalItem
