import Chip from '@mui/material/Chip'
import { useDispatch, useSelector } from 'react-redux'
import { removeFilter } from '../newsreelPageSlice'
import styles from './FilteredDataChip.module.scss'
import { newsreelFilters } from '@/components/common'

const FilteredDataChip = () => {
	const dispatch = useDispatch()
	const { activeFilters } = useSelector(state => state.newsreelPage)

	const handleRemoveFilter = id => () => {
		dispatch(removeFilter(id))
	}

	const renderChips = () => {
		return activeFilters.map(filter => {
			const item = newsreelFilters.find(el => el.id === filter)
			return (
				<Chip
					key={`${filter}`}
					label={item?.name}
					onClick={handleRemoveFilter(filter)}
					onDelete={handleRemoveFilter(filter)}
				/>
			)
		})
	}

	return (
		<div className={styles.filtered_data_chip}>
			<ul>{renderChips()}</ul>
		</div>
	)
}

export default FilteredDataChip
