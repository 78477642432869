import PropTypes from 'prop-types'
import { Box, Button, Icon } from '@mui/material'
import goscoreLockIcon from 'assets/images/goscore_lock.svg'
import styles from './SocialButton.module.scss'

export const SocialButton = ({
	handleClick,
	text,
	icon,
	img,
	disabled,
	dark
}) => {
	const startIcn = icon ? (
		<Icon baseClassName="fal" className={`fa-${icon}`} />
	) : (
		img
	)

	return (
		<Box
			className={`${styles.social_button} ${dark ? styles.dark : ''} ${
				disabled ? styles.disabled : ''
			}`}
		>
			<Box>
				<Button startIcon={startIcn} onClick={handleClick} disabled={disabled}>
					{text}
				</Button>
				{disabled && <img alt="" src={goscoreLockIcon.src} />}
			</Box>
		</Box>
	)
}

SocialButton.propTypes = {
	handleClick: PropTypes.func,
	text: PropTypes.string,
	icon: PropTypes.string,
	img: PropTypes.string,
	disabled: PropTypes.bool,
	dark: PropTypes.bool
}
