export const fanTokenABI = [
	{
		inputs: [],
		stateMutability: 'nonpayable',
		type: 'constructor'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'owner',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'spender',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'value',
				type: 'uint256'
			}
		],
		name: 'Approval',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'bool',
				name: 'status',
				type: 'bool'
			}
		],
		name: 'BlacklistedAccount',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint8',
				name: 'version',
				type: 'uint8'
			}
		],
		name: 'Initialized',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'lockId',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'to',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'quantity',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'duration',
				type: 'uint256'
			}
		],
		name: 'LockCreated',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint256',
				name: 'lockid',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'duration',
				type: 'uint256'
			}
		],
		name: 'LockModified',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'previousOwner',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'OwnershipTransferred',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'newAdminEOAWallet',
				type: 'address'
			}
		],
		name: 'SetAdminEOAWallet',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'from',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'to',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'value',
				type: 'uint256'
			}
		],
		name: 'Transfer',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		name: 'TrustedForwarderModified',
		type: 'event'
	},
	{
		inputs: [],
		name: 'adminEOAWallet',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'owner',
				type: 'address'
			},
			{
				internalType: 'address',
				name: 'spender',
				type: 'address'
			}
		],
		name: 'allowance',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'spender',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			}
		],
		name: 'approve',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'balanceOf',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_target',
				type: 'address'
			},
			{
				internalType: 'bool',
				name: '_isBlacklisted',
				type: 'bool'
			}
		],
		name: 'blacklistAccount',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '_amount',
				type: 'uint256'
			}
		],
		name: 'burn',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'decimals',
		outputs: [
			{
				internalType: 'uint8',
				name: '',
				type: 'uint8'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'spender',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'subtractedValue',
				type: 'uint256'
			}
		],
		name: 'decreaseAllowance',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'getTrustedForwarder',
		outputs: [
			{
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_user',
				type: 'address'
			}
		],
		name: 'getlockedAmount',
		outputs: [
			{
				internalType: 'uint256',
				name: 'lockedAmount',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'spender',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'addedValue',
				type: 'uint256'
			}
		],
		name: 'increaseAllowance',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_trustedForwarder',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_adminEOAWallet',
				type: 'address'
			}
		],
		name: 'initialize',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		name: 'isTrustedForwarder',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		name: 'isblacklistedAccount',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'lockCounter',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'lockDetails',
		outputs: [
			{
				internalType: 'uint256',
				name: 'lockId',
				type: 'uint256'
			},
			{
				internalType: 'address',
				name: 'user',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'lockAmount',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'lockDuration',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_to',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '_amount',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_duration',
				type: 'uint256'
			}
		],
		name: 'lockUp',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '_amount',
				type: 'uint256'
			}
		],
		name: 'mintTokens',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '_lockId',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_duration',
				type: 'uint256'
			}
		],
		name: 'modifylock',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'name',
		outputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'renounceOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_adminEOAWallet',
				type: 'address'
			}
		],
		name: 'setAdminEOAWallet',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_trustedForwarder',
				type: 'address'
			}
		],
		name: 'setTruestedForwarder',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'symbol',
		outputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'totalSupply',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'to',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			}
		],
		name: 'transfer',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'from',
				type: 'address'
			},
			{
				internalType: 'address',
				name: 'to',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			}
		],
		name: 'transferFrom',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'transferOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'userLocks',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	}
]

export const filmioProjectABI = [
	{
		inputs: [],
		stateMutability: 'nonpayable',
		type: 'constructor'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'evaluationId',
				type: 'uint256'
			},
			{
				indexed: true,
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				indexed: false,
				internalType: 'string',
				name: 'questionIds',
				type: 'string'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'rating',
				type: 'uint256'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'user',
				type: 'address'
			}
		],
		name: 'EvaluationCreated',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'evaluationId',
				type: 'uint256'
			},
			{
				indexed: true,
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				indexed: false,
				internalType: 'string',
				name: 'questionIds',
				type: 'string'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'rating',
				type: 'uint256'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'user',
				type: 'address'
			}
		],
		name: 'EvaluationModified',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				indexed: false,
				internalType: 'string',
				name: 'questionIds',
				type: 'string'
			}
		],
		name: 'EvaluationQuestionsSet',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint8',
				name: 'version',
				type: 'uint8'
			}
		],
		name: 'Initialized',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'createdBy',
				type: 'address'
			}
		],
		name: 'LockCreated',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'previousOwner',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'OwnershipTransferred',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'userRating',
				type: 'uint256'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'givenBy',
				type: 'address'
			}
		],
		name: 'RatingAdded',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'userRating',
				type: 'uint256'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'modifiedBy',
				type: 'address'
			}
		],
		name: 'RatingModified',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'givenBy',
				type: 'address'
			}
		],
		name: 'ReviewGiven',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'addressLinker',
				type: 'address'
			}
		],
		name: 'SetAddressLinker',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		name: 'SetTrustedForwarder',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				indexed: false,
				internalType: 'string',
				name: 'remark',
				type: 'string'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'createdBy',
				type: 'address'
			}
		],
		name: 'UpdateCreated',
		type: 'event'
	},
	{
		inputs: [],
		name: 'addressLinker',
		outputs: [
			{
				internalType: 'contract IAddressLinker',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				internalType: 'uint256',
				name: 'rating',
				type: 'uint256'
			}
		],
		name: 'createEvaluation',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			}
		],
		name: 'createLock',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				internalType: 'uint256',
				name: 'userRating',
				type: 'uint256'
			},
			{
				internalType: 'bool',
				name: 'reviewGiven',
				type: 'bool'
			}
		],
		name: 'createRating',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				internalType: 'string',
				name: 'remark',
				type: 'string'
			}
		],
		name: 'createUpdate',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			}
		],
		name: 'doesProjectExist',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'evaluationId',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'evaluationIndicies',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'getTrustedForwarder',
		outputs: [
			{
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_trustedForwarder',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_addressLinker',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_newOwner',
				type: 'address'
			}
		],
		name: 'initV3',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_trustedForwarder',
				type: 'address'
			}
		],
		name: 'initialize',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		name: 'isTrustedForwarder',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				internalType: 'uint256',
				name: 'rating',
				type: 'uint256'
			}
		],
		name: 'modifyEvaluation',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				internalType: 'uint256',
				name: 'userRating',
				type: 'uint256'
			}
		],
		name: 'modifyRating',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string'
			}
		],
		name: 'projectEvaluation',
		outputs: [
			{
				internalType: 'uint256',
				name: 'evaluationId',
				type: 'uint256'
			},
			{
				internalType: 'string',
				name: 'questionIds',
				type: 'string'
			},
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				internalType: 'uint256',
				name: 'rating',
				type: 'uint256'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string'
			}
		],
		name: 'projectEvaluationQuestions',
		outputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string'
			}
		],
		name: 'projectLockDetails',
		outputs: [
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				internalType: 'uint256',
				name: 'createdAt',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string'
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'projectUpdateDetails',
		outputs: [
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				internalType: 'string',
				name: 'remark',
				type: 'string'
			},
			{
				internalType: 'uint256',
				name: 'timestamp',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string'
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'ratingById',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'ratingDetails',
		outputs: [
			{
				internalType: 'uint256',
				name: 'ratingId',
				type: 'uint256'
			},
			{
				internalType: 'address',
				name: 'user',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'rating',
				type: 'uint256'
			},
			{
				internalType: 'bool',
				name: 'reviewGiven',
				type: 'bool'
			},
			{
				internalType: 'uint256',
				name: 'timestamp',
				type: 'uint256'
			},
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'ratingId',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'renounceOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_addressLinker',
				type: 'address'
			}
		],
		name: 'setAddressLinker',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				internalType: 'string',
				name: 'questionIds',
				type: 'string'
			}
		],
		name: 'setEvaluationQuestions',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_trustedForwarder',
				type: 'address'
			}
		],
		name: 'setTrustedForwarder',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'transferOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'userEvaluation',
		outputs: [
			{
				internalType: 'uint256',
				name: 'evaluationId',
				type: 'uint256'
			},
			{
				internalType: 'string',
				name: 'questionIds',
				type: 'string'
			},
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				internalType: 'uint256',
				name: 'rating',
				type: 'uint256'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		name: 'userRatings',
		outputs: [
			{
				internalType: 'address',
				name: 'user',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	}
]

export const stakingABI = [
	{
		inputs: [],
		stateMutability: 'nonpayable',
		type: 'constructor'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint8',
				name: 'version',
				type: 'uint8'
			}
		],
		name: 'Initialized',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'previousOwner',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'OwnershipTransferred',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'addressLinker',
				type: 'address'
			}
		],
		name: 'SetAddressLinker',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		name: 'SetTrustedForwarder',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'user',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'stakeId',
				type: 'uint256'
			},
			{
				indexed: true,
				internalType: 'string',
				name: 'typeId',
				type: 'string'
			},
			{
				indexed: false,
				internalType: 'string',
				name: 'stakeType',
				type: 'string'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'to',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'timestamp',
				type: 'uint256'
			}
		],
		name: 'StakeTokens',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'newAdmin',
				type: 'address'
			}
		],
		name: 'TempAdminChanged',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'user',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'stakeId',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'quantity',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'timestamp',
				type: 'uint256'
			}
		],
		name: 'UnstakeTokens',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: '_escrowAccount',
				type: 'address'
			}
		],
		name: 'UpdateEscrowAccount',
		type: 'event'
	},
	{
		inputs: [],
		name: '__tempAdmin',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: '_devCalculated',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'accountStakeDetails',
		outputs: [
			{
				internalType: 'string',
				name: 'stakeType',
				type: 'string'
			},
			{
				internalType: 'string',
				name: 'typeId',
				type: 'string'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'stakeId',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'quantity',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'stakedTimestamp',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'unstakedTimestamp',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'addressLinker',
		outputs: [
			{
				internalType: 'contract IAddressLinker',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'escrowAccount',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'filmioProject',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'getFirstStakedUnpopulated',
		outputs: [
			{
				internalType: 'uint32',
				name: '',
				type: 'uint32'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'getTrustedForwarder',
		outputs: [
			{
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'user',
				type: 'address'
			}
		],
		name: 'getUserStakes',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_forwarder',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_addressLinker',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_escrowAccount',
				type: 'address'
			},
			{
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'initOwner',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_admin',
				type: 'address'
			}
		],
		name: 'initV4',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'token',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_escrowAccount',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_trustedForwarder',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_filmioProject',
				type: 'address'
			}
		],
		name: 'initialize',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		name: 'isTrustedForwarder',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string'
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'objectStakeDetails',
		outputs: [
			{
				internalType: 'string',
				name: 'stakeType',
				type: 'string'
			},
			{
				internalType: 'string',
				name: 'typeId',
				type: 'string'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'stakeId',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'quantity',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'stakedTimestamp',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'unstakedTimestamp',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'start',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'end',
				type: 'uint256'
			}
		],
		name: 'populateStaked',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'renounceOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'renounceTempAdmin',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_addressLinker',
				type: 'address'
			}
		],
		name: 'setAddressLinker',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_forwarder',
				type: 'address'
			}
		],
		name: 'setTrustedForwarder',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'stakeDetails',
		outputs: [
			{
				internalType: 'string',
				name: 'stakeType',
				type: 'string'
			},
			{
				internalType: 'string',
				name: 'typeId',
				type: 'string'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'stakeId',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'quantity',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'stakedTimestamp',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'unstakedTimestamp',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'stakeIdToIndicies',
		outputs: [
			{
				internalType: 'uint256',
				name: 'objectsIndex',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'accountIndex',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'stakeIndex',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'stakes',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'stakeType',
				type: 'string'
			},
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				internalType: 'uint256',
				name: 'quantity',
				type: 'uint256'
			}
		],
		name: 'staketokens',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		name: 'totalNumberOfAccountStakes',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string'
			}
		],
		name: 'totalNumberOfObjectStakes',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'totalStakes',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'transferOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'stakeId',
				type: 'uint256'
			}
		],
		name: 'unstaketokens',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_escrowAccount',
				type: 'address'
			}
		],
		name: 'updateEscrowAccount',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'version',
		outputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string'
			}
		],
		stateMutability: 'pure',
		type: 'function'
	}
]

export const stakingABIV1 = [
	{
		inputs: [],
		stateMutability: 'nonpayable',
		type: 'constructor'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint8',
				name: 'version',
				type: 'uint8'
			}
		],
		name: 'Initialized',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'user',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'stakeId',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'amount',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'address',
				name: 'to',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'timestamp',
				type: 'uint256'
			}
		],
		name: 'StakeTokens',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'user',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'uint256',
				name: 'stakeId',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'quantity',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'timestamp',
				type: 'uint256'
			}
		],
		name: 'UnstakeTokens',
		type: 'event'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'accountStakeDetails',
		outputs: [
			{
				internalType: 'string',
				name: 'stakeType',
				type: 'string'
			},
			{
				internalType: 'string',
				name: 'typeId',
				type: 'string'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'stakeId',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'quantity',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'stakedTimestamp',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'unstakedTimestamp',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'escrowAccount',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'filmioProject',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'getTrustedForwarder',
		outputs: [
			{
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'token',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_escrowAccount',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_trustedForwarder',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_filmioProject',
				type: 'address'
			}
		],
		name: 'initialize',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		name: 'isTrustedForwarder',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string'
			},
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'objectStakeDetails',
		outputs: [
			{
				internalType: 'string',
				name: 'stakeType',
				type: 'string'
			},
			{
				internalType: 'string',
				name: 'typeId',
				type: 'string'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'stakeId',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'quantity',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'stakedTimestamp',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'unstakedTimestamp',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'stakeDetails',
		outputs: [
			{
				internalType: 'string',
				name: 'stakeType',
				type: 'string'
			},
			{
				internalType: 'string',
				name: 'typeId',
				type: 'string'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				internalType: 'uint256',
				name: 'stakeId',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'quantity',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'stakedTimestamp',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'unstakedTimestamp',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'stakeIdToIndicies',
		outputs: [
			{
				internalType: 'uint256',
				name: 'objectsIndex',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'accountIndex',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'stakeIndex',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'stakes',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: 'stakeType',
				type: 'string'
			},
			{
				internalType: 'string',
				name: 'projectId',
				type: 'string'
			},
			{
				internalType: 'uint256',
				name: 'quantity',
				type: 'uint256'
			}
		],
		name: 'staketokens',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		name: 'totalNumberOfAccountStakes',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: '',
				type: 'string'
			}
		],
		name: 'totalNumberOfObjectStakes',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'totalStakes',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: 'stakeId',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: 'quantity',
				type: 'uint256'
			}
		],
		name: 'unstaketokens',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'nonpayable',
		type: 'function'
	}
]

export const addressLinkerABI = [
	{
		inputs: [],
		stateMutability: 'nonpayable',
		type: 'constructor'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'oldAddress',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newAddress',
				type: 'address'
			}
		],
		name: 'AddressesLinked',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint8',
				name: 'version',
				type: 'uint8'
			}
		],
		name: 'Initialized',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'previousOwner',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'OwnershipTransferred',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		name: 'SetTrustedForwarder',
		type: 'event'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'oldOwner',
				type: 'address'
			}
		],
		name: 'getNewOwner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'getOldOwner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'owner',
				type: 'address'
			}
		],
		name: 'getOriginalOwner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'getTrustedForwarder',
		outputs: [
			{
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_forwarder',
				type: 'address'
			}
		],
		name: 'initialize',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		name: 'isTrustedForwarder',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newAddress',
				type: 'address'
			},
			{
				internalType: 'bytes',
				name: 'message',
				type: 'bytes'
			},
			{
				internalType: 'bytes',
				name: 'signature',
				type: 'bytes'
			}
		],
		name: 'isValidSignature',
		outputs: [
			{
				internalType: 'bool',
				name: 'isValid',
				type: 'bool'
			}
		],
		stateMutability: 'pure',
		type: 'function'
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes',
				name: 'message',
				type: 'bytes'
			},
			{
				internalType: 'bytes',
				name: 'signature',
				type: 'bytes'
			}
		],
		name: 'recoverSigner',
		outputs: [
			{
				internalType: 'address',
				name: 'signer',
				type: 'address'
			}
		],
		stateMutability: 'pure',
		type: 'function'
	},
	{
		inputs: [],
		name: 'renounceOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newAddress',
				type: 'address'
			},
			{
				internalType: 'bytes',
				name: 'message',
				type: 'bytes'
			},
			{
				internalType: 'bytes',
				name: 'signature',
				type: 'bytes'
			}
		],
		name: 'setNewUserAddress',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_forwarder',
				type: 'address'
			}
		],
		name: 'setTrustedForwarder',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'transferOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newAddress',
				type: 'address'
			},
			{
				internalType: 'bytes',
				name: 'message',
				type: 'bytes'
			},
			{
				internalType: 'bytes',
				name: 'signature',
				type: 'bytes'
			}
		],
		name: 'verifyIdentity',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'pure',
		type: 'function'
	}
]

export const filmioDaoABI = [
	{
		anonymous: false,
		inputs: [
			{
				indexed: false,
				internalType: 'uint8',
				name: 'version',
				type: 'uint8'
			}
		],
		name: 'Initialized',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'member',
				type: 'address'
			}
		],
		name: 'MemberAdded',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'member',
				type: 'address'
			}
		],
		name: 'MemberRemoved',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'proposalId',
				type: 'uint256'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'creator',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'string',
				name: 'ipfsHash',
				type: 'string'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'startDate',
				type: 'uint256'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'duration',
				type: 'uint256'
			}
		],
		name: 'NewProposal',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'previousOwner',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'OwnershipTransferred',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'proposalId',
				type: 'uint256'
			},
			{
				indexed: true,
				internalType: 'enum FilmioDAOV1.ProposalStatus',
				name: 'newStatus',
				type: 'uint8'
			}
		],
		name: 'ProposalStatusChanged',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'previousAdminRole',
				type: 'bytes32'
			},
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'newAdminRole',
				type: 'bytes32'
			}
		],
		name: 'RoleAdminChanged',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'sender',
				type: 'address'
			}
		],
		name: 'RoleGranted',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'account',
				type: 'address'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'sender',
				type: 'address'
			}
		],
		name: 'RoleRevoked',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		name: 'SetTrustedForwarder',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{
				indexed: true,
				internalType: 'uint256',
				name: 'proposalId',
				type: 'uint256'
			},
			{
				indexed: true,
				internalType: 'bool',
				name: 'inFavor',
				type: 'bool'
			},
			{
				indexed: true,
				internalType: 'address',
				name: 'voter',
				type: 'address'
			},
			{
				indexed: false,
				internalType: 'uint256',
				name: 'voteWeight',
				type: 'uint256'
			}
		],
		name: 'Voted',
		type: 'event'
	},
	{
		inputs: [],
		name: 'ADMIN_ROLE',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'DEFAULT_ADMIN_ROLE',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'MEMBER_ROLE',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'PROPOSER_ROLE',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'addressLinker',
		outputs: [
			{
				internalType: 'contract IAddressLinker',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'voter',
				type: 'address'
			}
		],
		name: 'calculateVotingWeight',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'string',
				name: '_ipfsHash',
				type: 'string'
			},
			{
				internalType: 'uint64',
				name: 'startDate',
				type: 'uint64'
			},
			{
				internalType: 'uint64',
				name: 'duration',
				type: 'uint64'
			}
		],
		name: 'createProposal',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '_proposalId',
				type: 'uint256'
			}
		],
		name: 'getProposal',
		outputs: [
			{
				components: [
					{
						internalType: 'uint256',
						name: 'id',
						type: 'uint256'
					},
					{
						internalType: 'string',
						name: 'ipfsHash',
						type: 'string'
					},
					{
						components: [
							{
								internalType: 'uint32',
								name: 'uniqueVoters',
								type: 'uint32'
							},
							{
								internalType: 'uint256',
								name: 'votesWeight',
								type: 'uint256'
							}
						],
						internalType: 'struct FilmioDAOV1.Votes',
						name: 'votesFor',
						type: 'tuple'
					},
					{
						components: [
							{
								internalType: 'uint32',
								name: 'uniqueVoters',
								type: 'uint32'
							},
							{
								internalType: 'uint256',
								name: 'votesWeight',
								type: 'uint256'
							}
						],
						internalType: 'struct FilmioDAOV1.Votes',
						name: 'votesAgainst',
						type: 'tuple'
					},
					{
						internalType: 'uint64',
						name: 'startDate',
						type: 'uint64'
					},
					{
						internalType: 'uint64',
						name: 'duration',
						type: 'uint64'
					},
					{
						internalType: 'enum FilmioDAOV1.ProposalStatus',
						name: 'status',
						type: 'uint8'
					},
					{
						internalType: 'address',
						name: 'creator',
						type: 'address'
					}
				],
				internalType: 'struct FilmioDAOV1.Proposal',
				name: '',
				type: 'tuple'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'getProposals',
		outputs: [
			{
				components: [
					{
						internalType: 'uint256',
						name: 'id',
						type: 'uint256'
					},
					{
						internalType: 'string',
						name: 'ipfsHash',
						type: 'string'
					},
					{
						components: [
							{
								internalType: 'uint32',
								name: 'uniqueVoters',
								type: 'uint32'
							},
							{
								internalType: 'uint256',
								name: 'votesWeight',
								type: 'uint256'
							}
						],
						internalType: 'struct FilmioDAOV1.Votes',
						name: 'votesFor',
						type: 'tuple'
					},
					{
						components: [
							{
								internalType: 'uint32',
								name: 'uniqueVoters',
								type: 'uint32'
							},
							{
								internalType: 'uint256',
								name: 'votesWeight',
								type: 'uint256'
							}
						],
						internalType: 'struct FilmioDAOV1.Votes',
						name: 'votesAgainst',
						type: 'tuple'
					},
					{
						internalType: 'uint64',
						name: 'startDate',
						type: 'uint64'
					},
					{
						internalType: 'uint64',
						name: 'duration',
						type: 'uint64'
					},
					{
						internalType: 'enum FilmioDAOV1.ProposalStatus',
						name: 'status',
						type: 'uint8'
					},
					{
						internalType: 'address',
						name: 'creator',
						type: 'address'
					}
				],
				internalType: 'struct FilmioDAOV1.Proposal[]',
				name: '',
				type: 'tuple[]'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			}
		],
		name: 'getRoleAdmin',
		outputs: [
			{
				internalType: 'bytes32',
				name: '',
				type: 'bytes32'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'getTrustedForwarder',
		outputs: [
			{
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '_proposalId',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_from',
				type: 'uint256'
			},
			{
				internalType: 'uint256',
				name: '_to',
				type: 'uint256'
			}
		],
		name: 'getVotes',
		outputs: [
			{
				components: [
					{
						internalType: 'address',
						name: 'voter',
						type: 'address'
					},
					{
						internalType: 'bool',
						name: 'inFavor',
						type: 'bool'
					},
					{
						internalType: 'uint256',
						name: 'voteWeight',
						type: 'uint256'
					},
					{
						internalType: 'uint256',
						name: 'timestamp',
						type: 'uint256'
					}
				],
				internalType: 'struct FilmioDAOV1.Vote[]',
				name: '',
				type: 'tuple[]'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'grantRole',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32[]',
				name: '_roles',
				type: 'bytes32[]'
			},
			{
				internalType: 'address[]',
				name: '_addresses',
				type: 'address[]'
			}
		],
		name: 'grantRoles',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'hasRole',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '_proposalId',
				type: 'uint256'
			},
			{
				internalType: 'address',
				name: '_voter',
				type: 'address'
			}
		],
		name: 'hasVoted',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_tokenAddress',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_trustedForwarder',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_stakingContract',
				type: 'address'
			},
			{
				internalType: 'address',
				name: '_addressLinker',
				type: 'address'
			}
		],
		name: 'initialize',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'forwarder',
				type: 'address'
			}
		],
		name: 'isTrustedForwarder',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'owner',
		outputs: [
			{
				internalType: 'address',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'proposalCount',
		outputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '',
				type: 'uint256'
			}
		],
		name: 'proposals',
		outputs: [
			{
				internalType: 'uint256',
				name: 'id',
				type: 'uint256'
			},
			{
				internalType: 'string',
				name: 'ipfsHash',
				type: 'string'
			},
			{
				components: [
					{
						internalType: 'uint32',
						name: 'uniqueVoters',
						type: 'uint32'
					},
					{
						internalType: 'uint256',
						name: 'votesWeight',
						type: 'uint256'
					}
				],
				internalType: 'struct FilmioDAOV1.Votes',
				name: 'votesFor',
				type: 'tuple'
			},
			{
				components: [
					{
						internalType: 'uint32',
						name: 'uniqueVoters',
						type: 'uint32'
					},
					{
						internalType: 'uint256',
						name: 'votesWeight',
						type: 'uint256'
					}
				],
				internalType: 'struct FilmioDAOV1.Votes',
				name: 'votesAgainst',
				type: 'tuple'
			},
			{
				internalType: 'uint64',
				name: 'startDate',
				type: 'uint64'
			},
			{
				internalType: 'uint64',
				name: 'duration',
				type: 'uint64'
			},
			{
				internalType: 'enum FilmioDAOV1.ProposalStatus',
				name: 'status',
				type: 'uint8'
			},
			{
				internalType: 'address',
				name: 'creator',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'renounceOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'renounceRole',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32',
				name: 'role',
				type: 'bytes32'
			},
			{
				internalType: 'address',
				name: 'account',
				type: 'address'
			}
		],
		name: 'revokeRole',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes32[]',
				name: '_roles',
				type: 'bytes32[]'
			},
			{
				internalType: 'address[]',
				name: '_addresses',
				type: 'address[]'
			}
		],
		name: 'revokeRoles',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: '_forwarder',
				type: 'address'
			}
		],
		name: 'setTrustedForwarder',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [],
		name: 'stakingContract',
		outputs: [
			{
				internalType: 'contract IStakeV4',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'bytes4',
				name: 'interfaceId',
				type: 'bytes4'
			}
		],
		name: 'supportsInterface',
		outputs: [
			{
				internalType: 'bool',
				name: '',
				type: 'bool'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [],
		name: 'token',
		outputs: [
			{
				internalType: 'contract IERC20Upgradeable',
				name: '',
				type: 'address'
			}
		],
		stateMutability: 'view',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'address',
				name: 'newOwner',
				type: 'address'
			}
		],
		name: 'transferOwnership',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{
				internalType: 'uint256',
				name: '_proposalId',
				type: 'uint256'
			},
			{
				internalType: 'bool',
				name: '_inFavor',
				type: 'bool'
			}
		],
		name: 'vote',
		outputs: [],
		stateMutability: 'nonpayable',
		type: 'function'
	}
]

export const USDTABI = [
	{
		constant: true,
		inputs: [],
		name: 'name',
		outputs: [{ name: '', type: 'string' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [{ name: '_upgradedAddress', type: 'address' }],
		name: 'deprecate',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{ name: '_spender', type: 'address' },
			{ name: '_value', type: 'uint256' }
		],
		name: 'approve',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'deprecated',
		outputs: [{ name: '', type: 'bool' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [{ name: '_evilUser', type: 'address' }],
		name: 'addBlackList',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'totalSupply',
		outputs: [{ name: '', type: 'uint256' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{ name: '_from', type: 'address' },
			{ name: '_to', type: 'address' },
			{ name: '_value', type: 'uint256' }
		],
		name: 'transferFrom',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'upgradedAddress',
		outputs: [{ name: '', type: 'address' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [{ name: '', type: 'address' }],
		name: 'balances',
		outputs: [{ name: '', type: 'uint256' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'decimals',
		outputs: [{ name: '', type: 'uint256' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'maximumFee',
		outputs: [{ name: '', type: 'uint256' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: '_totalSupply',
		outputs: [{ name: '', type: 'uint256' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [],
		name: 'unpause',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [{ name: '_maker', type: 'address' }],
		name: 'getBlackListStatus',
		outputs: [{ name: '', type: 'bool' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{ name: '', type: 'address' },
			{ name: '', type: 'address' }
		],
		name: 'allowed',
		outputs: [{ name: '', type: 'uint256' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'paused',
		outputs: [{ name: '', type: 'bool' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [{ name: 'who', type: 'address' }],
		name: 'balanceOf',
		outputs: [{ name: '', type: 'uint256' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [],
		name: 'pause',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'getOwner',
		outputs: [{ name: '', type: 'address' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'owner',
		outputs: [{ name: '', type: 'address' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'symbol',
		outputs: [{ name: '', type: 'string' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{ name: '_to', type: 'address' },
			{ name: '_value', type: 'uint256' }
		],
		name: 'transfer',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [
			{ name: 'newBasisPoints', type: 'uint256' },
			{ name: 'newMaxFee', type: 'uint256' }
		],
		name: 'setParams',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [{ name: 'amount', type: 'uint256' }],
		name: 'issue',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [{ name: 'amount', type: 'uint256' }],
		name: 'redeem',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [
			{ name: '_owner', type: 'address' },
			{ name: '_spender', type: 'address' }
		],
		name: 'allowance',
		outputs: [{ name: 'remaining', type: 'uint256' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'basisPointsRate',
		outputs: [{ name: '', type: 'uint256' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: true,
		inputs: [{ name: '', type: 'address' }],
		name: 'isBlackListed',
		outputs: [{ name: '', type: 'bool' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [{ name: '_clearedUser', type: 'address' }],
		name: 'removeBlackList',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: true,
		inputs: [],
		name: 'MAX_UINT',
		outputs: [{ name: '', type: 'uint256' }],
		payable: false,
		stateMutability: 'view',
		type: 'function'
	},
	{
		constant: false,
		inputs: [{ name: 'newOwner', type: 'address' }],
		name: 'transferOwnership',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		constant: false,
		inputs: [{ name: '_blackListedUser', type: 'address' }],
		name: 'destroyBlackFunds',
		outputs: [],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'function'
	},
	{
		inputs: [
			{ name: '_initialSupply', type: 'uint256' },
			{ name: '_name', type: 'string' },
			{ name: '_symbol', type: 'string' },
			{ name: '_decimals', type: 'uint256' }
		],
		payable: false,
		stateMutability: 'nonpayable',
		type: 'constructor'
	},
	{
		anonymous: false,
		inputs: [{ indexed: false, name: 'amount', type: 'uint256' }],
		name: 'Issue',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [{ indexed: false, name: 'amount', type: 'uint256' }],
		name: 'Redeem',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [{ indexed: false, name: 'newAddress', type: 'address' }],
		name: 'Deprecate',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: false, name: 'feeBasisPoints', type: 'uint256' },
			{ indexed: false, name: 'maxFee', type: 'uint256' }
		],
		name: 'Params',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: false, name: '_blackListedUser', type: 'address' },
			{ indexed: false, name: '_balance', type: 'uint256' }
		],
		name: 'DestroyedBlackFunds',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [{ indexed: false, name: '_user', type: 'address' }],
		name: 'AddedBlackList',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [{ indexed: false, name: '_user', type: 'address' }],
		name: 'RemovedBlackList',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, name: 'owner', type: 'address' },
			{ indexed: true, name: 'spender', type: 'address' },
			{ indexed: false, name: 'value', type: 'uint256' }
		],
		name: 'Approval',
		type: 'event'
	},
	{
		anonymous: false,
		inputs: [
			{ indexed: true, name: 'from', type: 'address' },
			{ indexed: true, name: 'to', type: 'address' },
			{ indexed: false, name: 'value', type: 'uint256' }
		],
		name: 'Transfer',
		type: 'event'
	},
	{ anonymous: false, inputs: [], name: 'Pause', type: 'event' },
	{ anonymous: false, inputs: [], name: 'Unpause', type: 'event' }
]

export const USDCABI = USDTABI
