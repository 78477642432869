import { Button, Box, Grid } from '@mui/material'
import { TextFieldControl } from '@/components/common'
import { useAddStakeForm } from './useAddStakeForm'
import { NumericFormat } from 'react-number-format'
import { useEffect } from 'react'
import styles from './AddStakeForm.module.scss'

export const AddStakeForm = () => {
	const {
		user,
		limit,
		errors,
		amount,
		balance,
		project,
		inputRef,
		projectId,
		stakeLimit,
		loadingLimit,
		disableAddBtn,
		isAbleToStake,
		isProjectOwner,
		handleStakeClick,
		handleRestoreForm,
		stakeCompletedEvent,
		stakeUnstakedEvent,
		getUserStakeLimit,
		handleChangeForm,
		handleKeypress,
		handleMaxStake,
		handleFocus
	} = useAddStakeForm()

	useEffect(() => {
		if (
			(stakeCompletedEvent?.projectId &&
				projectId === stakeCompletedEvent?.projectId) ||
			(stakeUnstakedEvent?.projectId &&
				projectId === stakeUnstakedEvent?.projectId)
		) {
			getUserStakeLimit()
		}
	}, [stakeCompletedEvent, stakeUnstakedEvent])

	return (
		<>
			{!loadingLimit && (
				<Grid item md={6}>
					<Box id={styles.stake_dialogue}>
						<Grid
							container
							className="w-full items-center justify-between pb-2"
						>
							<div className="flex">
								<h1 className="font-bold">Add your stake</h1>
							</div>
							<div className="align-right flex !text-[15px]">
								FGR: {user?.fgrRate}%{' '}
								<p className={styles.green}>
									+{project?.superfanStats?.bonus ?? 0}%
								</p>
							</div>
						</Grid>
						<Box className={styles.button_container}>
							<TextFieldControl
								inputRef={inputRef}
								type="number"
								error={errors}
								onKeyPress={handleKeypress}
								onFocus={handleFocus}
								value={amount}
								variant={'blue'}
								autoFocus
								onChange={e => {
									handleChangeForm(e.target.value)
								}}
							/>
							<Button onClick={handleMaxStake}>Max</Button>
						</Box>
						<small
							className={!isAbleToStake || limit < amount ? styles.danger : ''}
						>
							{limit >= amount &&
								(isAbleToStake ? (
									<>
										<p className="text-sm">
											Staking Limit is{' '}
											<span className={styles.green}>
												<NumericFormat
													value={stakeLimit}
													displayType="text"
													thousandSeparator=","
												/>
											</span>{' '}
											FAN. You can stake{' '}
											<span className={styles.green}>
												<NumericFormat
													value={limit}
													displayType="text"
													thousandSeparator=","
												/>
											</span>{' '}
											more.
										</p>
									</>
								) : (
									<>
										You don’t have enough tokens. You can stake up to{' '}
										<strong>{balance} FAN</strong>
									</>
								))}
							{limit < amount && (
								<>
									This exceeds the staking limit of{' '}
									<strong>{stakeLimit} FAN</strong>. You can stake up to{' '}
									<strong>{limit} FAN</strong>.
								</>
							)}
						</small>
						<Box className={styles.controls}>
							{!isProjectOwner && (
								<Button
									className="square_button"
									onClick={handleStakeClick(amount)}
									disabled={disableAddBtn}
								>
									Stake
								</Button>
							)}
							<Button
								className={styles.cancelButton}
								onClick={handleRestoreForm}
							>
								Clear
							</Button>
						</Box>
						<p className="pt-4 !text-[13px] leading-[18px]">
							When you stake <strong>$FAN Tokens</strong> to this project, you
							are exercising your governance power as a member of the Film.io
							DAO.
						</p>
					</Box>
				</Grid>
			)}
		</>
	)
}
