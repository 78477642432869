import useCommon from 'hooks/useCommon'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatDate } from 'utils/date.utils'
import _ from 'lodash'

export const ContestDialogState = {
	CLOSED: 'closed',
	OPENED_AS_READ_ONLY: 'openedAsReadOnly',
	OPENED_AS_AGREEMENT: 'openedAsAgreement'
}

export const NOT_JOINED_TEXT = 'Not Joined'

const useContestTable = () => {
	const [activeContestId, setActiveContestId] = useState(null)
	const [contestDialogState, setContestDialogState] = useState(
		ContestDialogState.CLOSED
	)
	const { activeContests } = useSelector(state => state.signIn)
	const { project } = useSelector(state => state.projectDetail)
	const { reloadActiveContests } = useCommon()

	const openReadOnlyJoinContestDialog = contestId => () => {
		setActiveContestId(contestId)
		setContestDialogState(ContestDialogState.OPENED_AS_READ_ONLY)
	}
	const openAgreementJoinContestDialog = contestId => () => {
		setActiveContestId(contestId)
		setContestDialogState(ContestDialogState.OPENED_AS_AGREEMENT)
	}
	const closeJoinContestDialog = () => {
		setContestDialogState(ContestDialogState.CLOSED)
	}

	const getContests = () =>
		activeContests.map(contest => {
			const projectContest = project?.contests?.find(
				el => el?.id === contest?.id
			)
			const joined = !_.isNil(projectContest)
			const joinedDate = joined
				? formatDate(projectContest?.Contest_Projects?.createdAt, 'med')
				: NOT_JOINED_TEXT
			return {
				...contest,
				joined,
				joinedDate
			}
		})

	useEffect(() => {
		reloadActiveContests()
	}, [])

	return {
		project,
		activeContestId,
		contestDialogState,
		setContestDialogState,
		openReadOnlyJoinContestDialog,
		openAgreementJoinContestDialog,
		closeJoinContestDialog,
		getContests
	}
}

export default useContestTable
