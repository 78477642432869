import React, { useState } from 'react'
import { FlameIcon } from 'lucide-react'
import { useGoScore } from './useGoScore'
import { ScoreDialogV2 } from '../../projectDetail/dashboardTab/scoreDialogV2'
import { CircularBar } from './goScore/GoScoreCircularBar'
import { cn } from '@/lib/utils'
import stageImages from 'assets/images/stages'
import { FiLoader } from 'react-icons/fi'
import { LockedGoscoreDialog } from './lockedGoscoreDialog'

interface ProjectStatsProps {
	projectId: string
	projectDecayStatus: string
}

export const ProjectStats = ({
	projectId,
	projectDecayStatus
}: ProjectStatsProps) => {
	const {
		goScoreData,
		goScoreConfig,
		isLoading,
		error,
		creatorPercent,
		creatorPercentage
	} = useGoScore(projectId)

	const [openLockedDialog, setOpenLockedDialog] = useState(false)

	const [open, setOpen] = useState(false)

	if (error || !goScoreData) {
		return null
	}

	const handleOpen = () => {
		if (goScoreData && !goScoreData.locked) {
			setOpen(true)
		}
	}

	const handleClose = (e?: React.MouseEvent) => {
		if (e) {
			e.stopPropagation();
		}
		setOpen(false)
	}

	// Calculate creator status (active/inactive)
	const creatorStatus =
		goScoreData.ces >= 1 || projectDecayStatus === '0' ? 1 : 2
	const isCreatorActive = creatorStatus === 1
	const score = goScoreData.goScore
	const locked = score ? false : true

	return (
		<div
			role="button"
			className={cn(
				'mb-2 flex items-center space-x-3 rounded-xl bg-black/75 p-4',
				!locked ? 'max-w-[210px]' : 'max-w-[95px]'
			)}
			onClick={handleOpen}
		>
			<CircularBar
				value1={goScoreData?.stakingPercent || 0}
				value2={goScoreData?.socialPercent || 0}
				value3={goScoreData?.projectPercent || 0}
				value4={creatorPercentage || 0}
				height={64}
				lineWidth={6}
			>
				{isLoading ? (
					<FiLoader className="size-4 animate-spin" />
				) : Number.isFinite(score) ? (
					Math.round(score!)
				) : (
					<>
						<img
							alt=""
							src={stageImages.scoreRing}
							className="absolute inset-0 flex items-center justify-center"
							onClick={() => {
								setOpenLockedDialog(true)
							}}
						/>
						<img alt="" src={stageImages.locked} className="h-6 w-8" />

						<LockedGoscoreDialog
							{...{
								open: openLockedDialog,
								handleClose: () => {
									setOpenLockedDialog(false)
								}
							}}
						/>
					</>
				)}
			</CircularBar>

			{!locked && (
				<div
					className={cn(
						'flex flex-col items-center space-x-1 rounded-lg border border-[#EC865B] p-1.5 text-xs text-[#EC865B]',
						!isCreatorActive && 'flex-row bg-zinc-800 py-3 grayscale'
					)}
				>
					<div className="font-bold">
						{isCreatorActive ? 'Active Project' : 'Inactive Project'}
					</div>
					{creatorPercent <= 100 && isCreatorActive && (
						<div className="flex items-center gap-1">
							{creatorPercent <= 10 && isCreatorActive && (
								<FlameIcon className="h-3 w-3" />
							)}
							{isCreatorActive && (
								<span className="text-white">
									Top {Math.round(creatorPercent)}%
								</span>
							)}
						</div>
					)}
				</div>
			)}

			{/* Score Dialog */}
			<ScoreDialogV2
				open={open}
				handleClose={handleClose}
				data={goScoreData}
				goScoreConfig={goScoreConfig}
				createrPercent={creatorPercentage}
				createrStatus={creatorStatus}
			/>
		</div>
	)
}
