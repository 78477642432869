import { Box, Icon, IconButton, Button, Typography } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useRef } from 'react'
import {
	SnapList,
	SnapItem,
	useScroll,
	useDragToScroll,
	useVisibleElements
} from 'react-snaplist-carousel'
import { getFileUrl } from 'utils/url.util'
import useSidebarDashboard, {
	CONTEST_PREFIX
} from '../sidebarDashboard/useSidebarDashboard'
import { setLoginDialogOpen } from '@/components/header/signInSlice'
import styles from './ContestCarousel.module.scss'
import { ActiveContestBanner } from '../activeContestBanner/ActiveContestBanner'
import Image from 'next/image'

const ContestCarousel = () => {
	const snapList = useRef(null)
	const itemsCount = 1
	const { activeContests, handleFilterChange } = useSidebarDashboard()
	const { isAuthenticated } = useSelector(state => state.signIn)
	const dispatch = useDispatch()

	const visible = useVisibleElements(
		{ debounce: 1, ref: snapList },
		([element]) => element
	)
	const goToSnapItem = useScroll({ ref: snapList })
	useDragToScroll({ ref: snapList, disabled: false })

	const handleNextClick = () => {
		goToSnapItem(visible + itemsCount)
	}

	const handlePrevClick = () => {
		goToSnapItem(visible > itemsCount ? visible - itemsCount : 0)
	}

	const handleOpenSignup = () => {
		if (!isAuthenticated) dispatch(setLoginDialogOpen(true))
	}

	const renderChildren = () =>
		activeContests.map(contest => {
			const src = getFileUrl(contest?.banner)
			return (
				<SnapItem
					className={styles.banner_item}
					snapAlign="center"
					key={contest?.id}
				>
					{contest?.banner ? (
						<Image
							src={src}
							alt="Contest banner"
							width={1116}
							height={88.72}
							quality={100}
							onClick={handleFilterChange(
								'types',
								`${CONTEST_PREFIX}${contest.id}`
							)}
						/>
					) : (
						<ActiveContestBanner {...{ contest }} />
					)}
				</SnapItem>
			)
		})

	const renderIndicators = () => {
		return activeContests.map((_el, i) => {
			return (
				<Box
					key={`indicator-${i}`}
					className={`${styles.indicator} ${
						i === visible ? styles.selected : ''
					}`}
				></Box>
			)
		})
	}

	if (activeContests?.length === 0) {
		return null
	}

	return (
		<Box className={styles.carousel}>
			{isAuthenticated ? (
				<>
					<IconButton
						size="small"
						onClick={handlePrevClick}
						className={styles.prev}
						disabled={visible === 0}
					>
						<Icon baseClassName="fas" className="fa-chevron-left" />
					</IconButton>

					<SnapList className={styles.list_container} ref={snapList}>
						{renderChildren()}
					</SnapList>

					<IconButton
						size="small"
						onClick={handleNextClick}
						className={styles.next}
						disabled={visible === activeContests.length - itemsCount}
					>
						<Icon baseClassName="fas" className="fa-chevron-right" />
					</IconButton>
					<Box className={styles.indicators}>{renderIndicators()}</Box>
				</>
			) : (
				<Box className={styles.list_container}>
					<Box className={styles.loggedOutBanner}>
						<Typography>
							Not a Film.io Superfan yet? Claim your 600 $FAN Tokens (includes
							DAO Tier Bonuses), stake and get perks from incredible films -
							100% FREE.
							<br />
						</Typography>
						<Button onClick={handleOpenSignup} className={styles.white_button}>
							Join now
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	)
}

export default ContestCarousel
