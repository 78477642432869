import { Input } from '@/components/ui/input'
import { Send, XIcon } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { KeyboardEvent } from 'react'
import { cn } from '../../../../../../../../lib/utils'

interface CommentInputProps {
	colors?: string
	value: string
	onChange: (_value: string) => void
	onSubmit: () => void
	onClear: () => void
}

export const CommentInput = ({
	colors,
	value,
	onChange,
	onSubmit,
	onClear
}: CommentInputProps) => {
	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter' && !e.shiftKey && value.trim()) {
			e.preventDefault()
			onSubmit()
		}
	}

	return (
		<div className="mt-2 flex w-full flex-1 flex-col items-center gap-2 sm:mt-4">
			<div className="relative w-full">
				<Input
					className={cn(
						'h-12 border-[#2196F3]/15 bg-[#2196F3]/10 pl-4 pr-24 text-base text-white focus-visible:border-[#2196F3]/10',
						colors
					)}
					placeholder="Comment"
					type="text"
					value={value}
					onChange={e => onChange(e.target.value)}
					onKeyDown={handleKeyDown}
					aria-label="Comment input"
				/>
				<div className="absolute right-2 top-1/2 flex -translate-y-1/2 items-center gap-2">
					<Button
						className="h-8 w-8 text-gray-400 hover:bg-gray-700 hover:text-white"
						size="icon"
						variant="ghost"
						onClick={onClear}
						aria-label="Clear comment"
					>
						<XIcon className="h-5 w-5" />
					</Button>
					<Button
						className="h-8 w-8 text-gray-400 hover:bg-gray-700 hover:text-white"
						size="icon"
						variant="ghost"
						onClick={onSubmit}
						disabled={value.trim() === ''}
						aria-label="Submit comment"
					>
						<Send className="h-5 w-5" />
					</Button>
				</div>
			</div>
			<span className="ml-auto text-sm text-gray-400">{value.length}/700</span>
		</div>
	)
}
