export const addCommentService = (data: any) => ({
	method: 'post',
	url: '/comment',
	data
})

export const deleteCommentService = (id: any) => ({
	method: 'delete',
	url: '/comment',
	data: { id }
})

export const getCommentService = (data: any) => ({
	method: 'post',
	url: '/comment/page',
	data
})
