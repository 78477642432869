import useWeb3 from 'hooks/useWeb3'
import { Box, Button, Dialog, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { setImportFanTokenDialogOpen, updateSignInUser } from '../signInSlice'
import fgrClaimTop from 'assets/images/fan_token_top.svg'
import styles from './ImportFanTokenDialog.module.scss'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { updateUserFields } from 'services/profile.service'

export const ImportFanTokenDialog = () => {
	const { importFanTokenDialogOpen, isAuthenticated, user } = useSelector(
		state => state.signIn
	)
	const { importFanTokenIntoWallet, isConnected } = useWeb3()
	const dispatch = useDispatch()
	const handleClose = () => {
		importFanTokenIntoWallet()
		importFanTokens()
		dispatch(setImportFanTokenDialogOpen(false))
	}

	const [{ loading }, importFanTokens] = useAxiosFetch(
		updateUserFields({ fanTokenImported: 1, id: user?.id }),
		(_data, error) => {
			if (!error) {
				localStorage.setItem('fanTokenImported', 1)
				dispatch(updateSignInUser({ fanTokenImported: 1 }))
			}
		}
	)

	const open = isAuthenticated && isConnected && importFanTokenDialogOpen
	return (
		<Dialog
			open={open}
			disableScrollLock={true}
			className={styles.fgr_dialog}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleClose}
		>
			<img alt="" src={fgrClaimTop.src} />
			<Box className={styles.content}>
				<Typography component={'h1'}>Congratulations!</Typography>
				<Typography>
					One last step, please import the $FAN token into your wallet! Simply
					click the &quot;Add $FAN&quot; button below and we&rsquo;ll take care
					of the rest. If you have $FAN associated to this wallet, it may take
					around 5 minutes to be reflected in your platform wallet.
				</Typography>
				<Box className={styles.controls}>
					<Button onClick={handleClose} disabled={loading}>
						Add $FAN
					</Button>
				</Box>
			</Box>
			<Box className={styles.detail_dialog}></Box>
		</Dialog>
	)
}
