export const getNetworkService = (params: any) => ({
	method: 'get',
	url: '/fanPurchasesTransaction/networks',
	autoexec: true,
	params
})

export const getTokenPriceService = (networkId: any) => ({
	method: 'get',
	url: `/fanPurchasesTransaction/tokens?networkId=${networkId}`,
	autoexec: false
})

export const checkInProgressTransactionService = () => ({
	method: 'get',
	url: `/fanPurchasesTransaction/check`,
	autoexec: false
})

export const postPurchaseService = (data: any) => ({
	method: 'post',
	url: '/fanPurchasesTransaction',
	data
})

export const postFailPurchaseService = (data: any) => ({
	method: 'post',
	url: '/fanPurchasesTransaction/fail',
	data
})
