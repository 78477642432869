import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Icon, Typography, Button, Dialog } from '@mui/material'
import newToken from 'assets/images/new_token_blue.svg'
import transferIllustration from 'assets/images/transfer-illustration.png'
import useWalletOverview from './useWalletOverview'
import { CustomButton, LightTooltip } from '@/components/common'
import { ClaimWalletDialog } from './claimWalletDialog'
import { ImportFanTokenDialog } from '@/components/header/importFanTokenDialog'
import infoIcon from 'assets/images/walletIcons/info.svg'
import { SpeedUpDialog } from '../speedUpDialog'
import { abbreviateNumberFixed } from 'utils/utils'
import { RewardUnlockInfo } from './rewardUnlockInfo'
import { WhiteListWallet } from './whiteListWallet'
import useCommon from 'hooks/useCommon'
import { RewardUnlockProgression } from '../rewardUnlockProgression'
import { HodlOverviewSection } from './hodlOverviewSection'
import styles from './WalletOverview.module.scss'

const WalletOverview = ({ changeTab }) => {
	const {
		type,
		isHodl,
		isRestrictedHodl,
		endDate,
		balance,
		progress,
		gemTotal,
		startDate,
		hodlAmount,
		stakesTotal,
		balanceTotal,
		timeToUnlock,
		earningsTotal,
		walletAddress,
		isWhitelisted,
		earningsToDate,
		unlockedTokens,
		isNonCustodial,
		unlockedRewards,
		isNcWithoutMagic,
		openSpeedUpDialog,
		shortwalletAddress,
		isIngXRestrictedInvestor,
		whitelistTransferDialogOpen,
		handleOpenWhitelistTransfer,
		handleCopyWalletAddress,
		handleOpenClaimWallet,
		handleUnlockRewards,
		handleRemoveWallet,
		handleCloseSpeedUp,
		whitelistAddress,
		updateOverview,
		isRestricted
	} = useWalletOverview()
	const { isMobileScreen } = useCommon()

	const availableToWithdraw = `${abbreviateNumberFixed(
		unlockedTokens ?? 0,
		2
	)} Withdrawable`
	const rewardsTokens = (earningsTotal ?? 0) - (unlockedRewards ?? 0)
	const [open, setOpen] = useState(false)

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const onClickChangeTab = () => {
		changeTab(3)
	}

	return (
		<Box className={styles.overview}>
			{isNcWithoutMagic && (
				<Box className={styles.rewards_locked}>
					<CustomButton
						customStyle={'outlined_square'}
						onClick={handleUnlockRewards}
					>
						Setup Your Platform Wallet
					</CustomButton>
				</Box>
			)}
			{isHodl ? (
				<HodlOverviewSection
					{...{
						type,
						balance,
						hodlAmount,
						balanceTotal,
						rewardsTokens,
						shortwalletAddress,
						isIngXRestrictedInvestor,
						handleCopyWalletAddress,
						availableToWithdraw,
						onClickChangeTab,
						earningsToDate,
						stakesTotal,
						startDate,
						endDate
					}}
				/>
			) : (
				<Box className={styles.row_group}>
					<Box className={styles.total_balance}>
						<Box className={styles.body}>
							<Box className={styles.top}>
								<Typography component={'h2'} className={styles.text}>
									Platform Wallet
									<LightTooltip
										enterTouchDelay={0}
										leaveTouchDelay={4000}
										placement="bottom"
										title="The platform wallet represents your total FAN balance, which is available + staked tokens.  You can also Transfer FAN into this wallet address. Note: please do not transfer any crypto other than FAN into this wallet, as it may not be recoverable."
									>
										<span className={styles.help_icon} aria-haspopup="true">
											<img alt="info Icon" src={infoIcon.src} />
										</span>
									</LightTooltip>
								</Typography>
							</Box>
							<Box className={styles.center}>
								<img src={newToken.src} alt="" />
								<Typography component={'h1'} className={styles.text}>
									{abbreviateNumberFixed(balanceTotal ?? 0, 2)}
								</Typography>
							</Box>
							<Box className={styles.bottom}>
								<Button
									onClick={handleCopyWalletAddress}
									startIcon={<Icon baseClassName="fas" className="fa-copy" />}
								>
									{walletAddress}
								</Button>
							</Box>
						</Box>
					</Box>

					<RewardUnlockInfo
						{...{
							balance,
							rewardsTokens,
							stakesTotal,
							isIngXRestrictedInvestor,
							availableToWithdraw
						}}
					/>
				</Box>
			)}
			{isMobileScreen && <RewardUnlockProgression />}
			{!isRestricted ? (
				<WhiteListWallet
					{...{
						isWhitelisted,
						isNonCustodial,
						whitelistAddress,
						handleRemoveWallet,
						handleOpenWhitelistTransfer,
						whitelistTransferDialogOpen,
						handleOpen,
						isRestrictedHodl
					}}
				/>
			) : (
				<Box></Box>
			)}
			{open && (
				<Dialog
					open={open}
					disableScrollLock={true}
					className={styles.transferIn}
					modal="true"
					classes={{
						paper: styles.dialog_paper,
						container: styles.dialog_container
					}}
					onClose={handleClose}
				>
					<Box className={styles.dialog_content}>
						<Typography component={'h1'}>
							Transfer $FAN Into the Platform
						</Typography>
						<Typography>
							You can transfer $FAN into your platform wallet by using the below
							wallet address
						</Typography>

						<img
							className={styles.illustration}
							src={transferIllustration.src}
							alt=""
						/>

						<Box className={styles.walletCopy}>
							<button onClick={handleCopyWalletAddress}>Copy</button>
							{walletAddress}
						</Box>

						<Typography className={styles.warning}>
							Before transferring FAN$ onto the platform, please ensure that you
							double-check your wallet address
						</Typography>

						<Box className={styles.controls}>
							<Button onClick={handleClose}>Close</Button>
						</Box>
					</Box>
					<Box className={styles.detail_dialog}></Box>
				</Dialog>
			)}
			<ImportFanTokenDialog />
			<SpeedUpDialog
				open={openSpeedUpDialog}
				handleClose={handleCloseSpeedUp}
				gemTotal={gemTotal}
				timeToUnlock={timeToUnlock}
				rewardsTokens={rewardsTokens}
				releasedTokens={unlockedRewards}
				percentage={progress}
				updateOverview={updateOverview}
			/>
			<Box className={styles.external_wallet}>
				{!isNonCustodial && (
					<>
						<CustomButton
							customStyle={'outlined'}
							onClick={handleOpenClaimWallet}
						>
							Claim Your Wallet!
						</CustomButton>
						<Typography>
							You can now take control of your Film.io wallet, allowing you to
							use a 3rd party application like metamask to manage your FAN
							Tokens, login to and transact within the app.
						</Typography>
						<ClaimWalletDialog />
					</>
				)}
			</Box>
		</Box>
	)
}

WalletOverview.propTypes = {
	changeTab: PropTypes.func.isRequired
}

export default WalletOverview
