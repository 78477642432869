export const queryKeys = {
	dashboard: {
		list: (filter: any, sort: any) => ['dashboard', filter, sort]
	},
	contests: {
		active: () => ['activeContests']
	},
	sharer: {
		name: (userShortUrl: string) => ['sharerName', userShortUrl]
	},
	project: {
		detail: (slug: string | undefined) => ['project', slug],
		trailer: (slug: string | undefined) => ['project', slug, 'trailer'],
		permissions: (projectId: string | undefined) => [
			'project',
			projectId,
			'permissions'
		],
		superfansLeaderboard: (projectId: string) => [
			'superfanLeaderboard',
			projectId
		],
		superfanMilestones: () => ['superfanMilestones'],
		superfanActions: () => ['superfanActions'],
		perks: (projectId: string) => ['user', projectId, 'perks'],
		perksProgramProjects: () => ['perksProgramProjects'],
		newsreel: (projectId: string) => ['newsreel', projectId],
		superfans: (projectId: string) => ['superfans', projectId]
	}
} as const
