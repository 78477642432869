import PropTypes from 'prop-types'
import { Box, Icon, IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { deleteNewsreelByIdService } from 'services/newsreel.service'
import {
	UserInfoTimestamp,
	CommentReaction,
	ImageWithViewerNewsreel
} from '@/components/common'
import { getFileUrl } from 'utils/url.util'
import ImageList from '@mui/material/ImageList'
import useNotification from 'hooks/useNotification'
import { reloadComments } from '@/components/projectDetail/projectDetailSlice'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import styles from './NewsreelItem.module.scss'
import { newsreelDeletedHook } from '@/components/newsreelPage/newsreelPageSlice'
import { useConfirm } from 'material-ui-confirm'

const NewsreelItem = ({
	id,
	user,
	media,
	content,
	comments,
	commentsCount,
	reactions,
	updatedAt,
	project,
	showInfo
}) => {
	const { showNotification } = useNotification()
	const { user: signInUser } = useSelector(state => state.signIn)
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const confirm = useConfirm()
	const dispatch = useDispatch()

	const deleteNewsreel = () => {
		showMask()
		axiosService(deleteNewsreelByIdService(id), (data, error) => {
			if (!error) {
				dispatch(reloadComments())
				dispatch(newsreelDeletedHook(id))
				showNotification({ message: data?.message })
			}
			hideMask()
		})
	}

	const handleDeleteNewsreel = () => {
		confirm({
			variant: styles.dark,
			description: `This will permanently delete this post.`
		}).then(() => deleteNewsreel())
	}

	const isOwnerSignedIn = signInUser?.id === user?.id
	const { isAdmin } = signInUser

	let cols = 1

	switch (media?.length) {
		case 1:
			cols = 1
			break
		case 2:
			cols = 2
			break
		case 3:
			cols = 3
			break
		default:
			cols = 2
			break
	}

	return (
		<Box id={id} className={styles.newsreel_item}>
			<Box className={styles.heading}>
				{project?.poster && (
					<img
						alt=""
						loading="lazy"
						className={styles.project_poster}
						src={getFileUrl(project?.poster)}
					/>
				)}
				<UserInfoTimestamp {...{ updatedAt, user, project, id, showInfo }} />
				{(Number(isAdmin) === 1 || isOwnerSignedIn === true) && (
					<IconButton
						aria-label="delete"
						size="small"
						onClick={handleDeleteNewsreel}
					>
						<Icon baseClassName="fas" className="fa-regular fa-trash-can" />
					</IconButton>
				)}
			</Box>
			<Box className={styles.content}>
				<p>{content}</p>
				<Box className={styles.contentImages}>
					<ImageList variant="masonry" cols={cols} gap={0}>
						<ImageWithViewerNewsreel {...{ media }} />
					</ImageList>
				</Box>
			</Box>
			<CommentReaction
				{...{
					comments,
					commentsCount,
					defaultReactions: reactions,
					type: 'newsreel',
					feedId: id
				}}
			/>
		</Box>
	)
}

NewsreelItem.propTypes = {
	id: PropTypes.string,
	user: PropTypes.object,
	media: PropTypes.array,
	content: PropTypes.string,
	comments: PropTypes.array,
	commentsCount: PropTypes.number,
	reactions: PropTypes.array,
	updatedAt: PropTypes.string,
	fromNewsreel: PropTypes.bool,
	project: PropTypes.object,
	showInfo: PropTypes.bool
}

export default NewsreelItem
