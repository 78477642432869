import PropTypes from 'prop-types'
import { Dialog, DialogContent, Icon, IconButton } from '@mui/material'
import styles from './CustomDialog.module.scss'

const CustomDialog = ({
	open,
	handleClose,
	children,
	variant,
	removeX,
	...props
}) => {
	const onClose = (_event, reason) => {
		if (reason === 'backdropClick' || reason === 'escapeKeyDown') handleClose()
	}
	return (
		<Dialog
			open={open}
			disableScrollLock={true}
			className={[styles[variant], styles.dialog].join(' ')}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={onClose}
			{...props}
		>
			{!removeX && (
				<IconButton
					size="small"
					onClick={handleClose}
					className={styles.close_btn}
				>
					<Icon baseClassName="fas" className="fa-xmark" />
				</IconButton>
			)}
			<DialogContent className={styles.dialog_content}>
				{children}
			</DialogContent>
		</Dialog>
	)
}

CustomDialog.propTypes = {
	open: PropTypes.bool,
	removeX: PropTypes.bool,
	handleClose: PropTypes.func,
	children: PropTypes.node,
	variant: PropTypes.string,
	props: PropTypes.object
}

export default CustomDialog
