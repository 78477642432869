import _ from 'lodash'
import { useEffect, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
	useWalletClient,
	useDisconnect,
	useAccount,
	useNetwork,
	useConnect,
	useSwitchNetwork
} from 'wagmi'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import {
	contractsConfig,
	chains,
	defaultNetwork
} from 'utils/blockchain/config/constants'
import { isMetamask, isMobile } from 'utils/utils'
import useNotification from './useNotification'
import images from 'assets/images'

const useWeb3 = () => {
	const { connectors, connectAsync } = useConnect({})
	const { isAuthenticated, user } = useSelector(state => state.signIn)
	const { isConnected, address } = useAccount()
	const { data: signer } = useWalletClient()
	const { disconnectAsync } = useDisconnect()
	const { chain } = useNetwork()
	const { open: openConnectWalletModal } = useWeb3Modal()
	const [isConnectedWrongNetwork, setIsConnectedWrongNetwork] = useState(false)
	const { switchNetwork, switchNetworkAsync } = useSwitchNetwork()
	const { showNotification } = useNotification()

	const metamaskLogin = async () => {
		if (isMetamask() || (!isMobile() && !isConnected)) {
			const injectedConnector = connectors.find(
				connector => connector.id === 'injected'
			)
			if (!_.isNil(injectedConnector)) {
				if (injectedConnector.name !== 'MetaMask') {
					showNotification({
						message: (
							<p>
								It looks like Metamask isn&apos;t your active wallet. To continue, please disable <b>{injectedConnector.name}</b> and try again.
								<br></br>
								<br></br>For more info check{' '}
								<a
									target="_blank"
									href="https://support.metamask.io/third-party-platforms-and-dapps/i-cant-connect-metamask-extension-to-a-dapp-/"
								>
									here
								</a>
							</p>
						)
					})
				} else {
					await connectAsync({ connector: injectedConnector })
				}
			}
		}
	}

	const importFanTokenIntoWallet = useCallback(async () => {
		if (!signer) {
			return
		}

		return await signer.watchAsset({
			type: 'ERC20',
			options: {
				address: contractsConfig.fanToken.address[defaultNetwork],
				symbol: 'FAN',
				decimals: 8,
				image: images.tokenIcon
			}
		})
	}, [signer, contractsConfig])

	// TODO find a better place for this useEffect
	useEffect(() => {
		const isNonCustodial = user?.isNonCustodial === 1
		const isWrongNetwork =
			isAuthenticated &&
			!(chain?.id in chains.map(chain => chain?.id)) &&
			isNonCustodial

		if (isWrongNetwork !== isConnectedWrongNetwork) {
			setIsConnectedWrongNetwork(
				isAuthenticated &&
				!(chain?.id in chains.map(chain => chain?.id)) &&
				isNonCustodial
			)
		}
	}, [isAuthenticated, chain, user?.isNonCustodial, chains])

	return {
		chain,
		signer,
		address,
		connectors,
		isConnected,
		isConnectedWrongNetwork,
		chain,
		importFanTokenIntoWallet,
		openConnectWalletModal,
		switchNetworkAsync,
		disconnectAsync,
		metamaskLogin,
		connectAsync,
		switchNetwork
	}
}

export default useWeb3
