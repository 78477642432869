export const getStudios = () => ({
	method: 'get',
	url: '/studio',
	autoexec: true
})

export const createStudio = (data: any) => ({
	method: 'get',
	url: '/studio',
	data
})
