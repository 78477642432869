import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import React, { useCallback } from 'react'
import useAxios from '@/hooks/useAxios'
import { getNewsreelsByFeatureIdService } from '../../services/newsreel.service'
import { NewsreelRecord } from '../fanDashboard/main-content/tabsDashboard/Dashboard/Newsreel/types'
import { NewsreelItem } from '../fanDashboard/main-content/tabsDashboard/Dashboard/Newsreel/components/NewsreelItem'
import { Button } from '../ui/button'
import ProjectNewsreelForm from './projectNewsreelForm/ProjectNewsreelForm'
import { queryKeys } from '@/lib/queryKeys'
import { RolePermission } from '@/types/project'
import useProjectCommon from './useProjectCommon'
import { RootState } from '@/store/store'
import { useSelector } from 'react-redux'
import { ProjectEmptyContent } from './ProjectEmptyContent'

interface Props {
	projectId: string
}

interface NewsreelPaginatedResponse {
	newsreels: NewsreelRecord[]
	pagination: {
		currentPage: number
		totalPages: number
		hasMore: boolean
	}
}

/**
 * Button component with loading state
 */
const LoadMoreButton = ({
	onClick,
	isLoading
}: {
	onClick: () => void
	isLoading: boolean
}) => (
	<Button size="sm" onClick={onClick} disabled={isLoading} className="w-min">
		{isLoading ? 'Loading...' : 'Load More'}
	</Button>
)

/**
 * Component that displays a paginated newsreel feed for a project
 * with a load more button
 */
export const ProjectNewsreel = ({ projectId }: Props) => {
	const { axiosServiceSync } = useAxios()
	const queryClient = useQueryClient()
	const { project } = useProjectCommon()
	const { user } = useSelector((state: RootState) => state.signIn)
	const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
		useInfiniteQuery<NewsreelPaginatedResponse>({
			queryKey: queryKeys.project.newsreel(projectId),
			queryFn: ({ pageParam = 1 }) =>
				axiosServiceSync(
					getNewsreelsByFeatureIdService({
						projectId,
						page: pageParam
					})
				),
			getNextPageParam: lastPage =>
				lastPage.pagination.hasMore
					? Number(lastPage?.pagination?.currentPage) + 1
					: undefined,
			initialPageParam: 1
		})

	const reloadNewsreels = useCallback(() => {
		queryClient.invalidateQueries({
			queryKey: queryKeys.project.newsreel(projectId)
		})
	}, [projectId])

	const rolePermissions = queryClient.getQueryData<RolePermission[]>(
		queryKeys.project.permissions(projectId)
	)

	const haveNewsreelPermissions = () =>
		rolePermissions?.some((el: RolePermission) => el?.permissionId === 2)

	const hasAnyItems = data?.pages.some(page => page?.newsreels?.length > 0)

	if (isLoading) {
		return <ProjectNewsreelForm />
	}

	const isNotNewsreelsOwner = project?.userId !== user?.id
	const emptyMessage =
		isNotNewsreelsOwner && !haveNewsreelPermissions()
			? 'There are no Newsreel posts. You will be able to join the discussion as soon as the owner of the page creates one!'
			: 'Create a post so that your followers can join your conversation!'

	return (
		<div className="flex w-full flex-col min-h-[50vh]">
			<ProjectNewsreelForm />

			{hasAnyItems ? (
				<>
					<div className="mt-3 text-lg font-bold">Latest From the Newsreel</div>

					<div className="mt-4 space-y-4 text-base">
						{data!.pages.map(page =>
							page.newsreels.map((item: NewsreelRecord) => (
								<NewsreelItem
									key={item.id}
									cardStyles="bg-[#242426]"
									inputStyles="bg-[#242426] border-[#838385]"
									item={{ type: 'newsreel', record: item }}
									onDelete={reloadNewsreels}
								/>
							))
						)}
					</div>

					{hasNextPage && (
						<LoadMoreButton
							onClick={() => fetchNextPage()}
							isLoading={isFetchingNextPage}
						/>
					)}
				</>
			) : (
				<ProjectEmptyContent emptyMessage={emptyMessage} />
			)}
		</div>
	)
}
