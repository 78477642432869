import { Box, Button, Container, Grid, Typography } from '@mui/material'
import {
	ChipSelectBox,
	LoadMask,
	SelectBox,
	TextArea,
	TextFieldControl
} from '@/components/common'
import { CropPoster } from '@/components/common/cropPoster'
import { DropZone } from '@/components/common/dropZone'
import HtmlEditor from '@/components/common/htmlEditor/HtmlEditor'
import { createRef, useEffect } from 'react'
import { getVideoInfoFromUrl } from 'utils/video.utils'
import { CropImage } from '../common/cropImage'
import styles from './ProjectEdit.module.scss'
import useProjectEdit from './useProjectEdit'
import { Link } from 'react-router-dom'

const ProjectEdit = () => {
	const {
		errors,
		genres,
		project,
		showMask,
		scrolled,
		videoInfo,
		projectTypes,
		changeDeteted,
		projectStatus,
		savingPreview,
		handleSaveChangesWithoutValidate,
		handleSavePreviewChanges,
		handleSubmitForApproval,
		handleSaveChanges,
		setChangeDeteted,
		setHeaderImage,
		setVideoInfo,
		handleCancel,
		handleChange,
		updateErrors,
		setScrolled,
		setPoster
	} = useProjectEdit()

	const lookbookRef = createRef()
	const posterRef = createRef()
	const loglineRef = createRef()
	const storyRef = createRef()

	const renderButtons = stageId => {
		const isDraft = stageId === 1
		return (
			<>
				<Button
					className={styles.preview_btn}
					variant="outlined"
					onClick={handleSavePreviewChanges}
					startIcon={
						savingPreview && (
							<i className="fa-duotone fa-spinner-third fa-spin"></i>
						)
					}
				>
					Preview
				</Button>
				{isDraft && (
					<Button
						className={styles.save_btn}
						onClick={handleSaveChangesWithoutValidate}
						disabled={!changeDeteted}
					>
						Save
					</Button>
				)}
				{isDraft ? (
					<Button
						className="yellow-bg-button"
						onClick={handleSubmitForApproval}
					>
						Submit for approval
					</Button>
				) : (
					<Button
						className="yellow-bg-button"
						onClick={handleSaveChanges}
						disabled={!changeDeteted}
					>
						Save Changes
					</Button>
				)}
				<Button className={styles.cancel_btn} onClick={handleCancel}>
					Exit
				</Button>
			</>
		)
	}

	useEffect(() => {
		if (!scrolled) {
			if (errors.header || errors.title) setScrolled(true)
			else if (errors.lookbook) {
				lookbookRef.current.scrollIntoView()
				setScrolled(true)
			} else if (errors.poster) {
				posterRef.current.scrollIntoView()
				setScrolled(true)
			} else if (errors.logline) {
				loglineRef.current.scrollIntoView()
				setScrolled(true)
			} else if (errors.story) {
				storyRef.current.scrollIntoView()
				setScrolled(true)
			}
		}
	}, [errors, scrolled])

	return (
		<>
			{showMask ? (
				<LoadMask />
			) : (
				<Container disableGutters maxWidth="md" className={styles.project_edit}>
					<Grid container>
						<Grid item xs={12} md={6} className={styles.welcome}>
							<h1>Tell us about your project</h1>
							<p>
								Please make sure your project is ready for submission, required
								fields are marked with an *
							</p>
						</Grid>
						<Grid item xs={12} md={6} className={styles.edit_controls}>
							{renderButtons(project?.stageId)}
						</Grid>
						<Grid
							item
							xs={12}
							className={styles.edit_form}
							container
							spacing={4}
						>
							<Grid item xs={12}>
								<TextFieldControl
									error={errors?.title}
									label="Title*"
									value={project.title || ''}
									variant={'blue'}
									autoFocus
									onChange={e => {
										handleChange({
											title: e.target.value
										})
									}}
								/>
							</Grid>

							<Grid item xs={12}>
								<CropImage
									{...{
										helperTextLeft:
											'png, jpeg accepted. 24mb max. 1400px x 538px maximum',
										error: errors?.header,
										setCroppedImage: image => {
											setHeaderImage(image)
											updateErrors({ header: false })
											setChangeDeteted(true)
										},
										defaultImage: project?.coverImg
									}}
								/>
							</Grid>

							<Grid item xs={12} md={8} container spacing={2}>
								<Grid item xs={12} md={6}>
									<SelectBox
										{...{
											options: projectTypes,
											value: project?.typeId,
											variant: 'blue',
											placeholder: 'Project Type',
											label: 'Project Type*',
											setValue: value => {
												handleChange({
													typeId: value
												})
											}
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<SelectBox
										{...{
											options: projectStatus,
											value: project?.statusId,
											variant: 'blue',
											placeholder: 'Status',
											label: 'Status',
											setValue: value => {
												handleChange({
													statusId: value
												})
											}
										}}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									{project.genres && (
										<ChipSelectBox
											{...{
												values: project.genres,
												variant: 'blue',
												label: 'Genres*',
												options: genres?.data || [],
												placeholder: 'Your Genres. Choose up to 3',
												helperText: 'Choose up to 3',
												setValues: genres => {
													handleChange({ genres })
												}
											}}
										/>
									)}
								</Grid>
								<Grid item xs={12} md={6}>
									<TextFieldControl
										label="Trailer"
										value={project.trailer ?? ''}
										variant={'blue'}
										placeholder="The link to your Trailer at YouTube"
										helperText="Youtube or Vimeo link"
										onChange={async e => {
											handleChange({ trailer: e.target.value })
											const info = await getVideoInfoFromUrl(e.target.value)
											setVideoInfo(info)
										}}
									/>

									<Grid item xs={12} md={12}>
										{videoInfo && !videoInfo?.error && (
											<Box className={styles.video_info}>
												<img src={videoInfo?.thumbnail_url} alt="" />
												<Box className={styles.info}>
													<Typography component={'h3'}>
														{videoInfo?.author_name}
													</Typography>
													<Typography component={'h4'}>
														{videoInfo?.title}
													</Typography>
												</Box>
											</Box>
										)}
										{videoInfo?.error && (
											<Typography component={'h4'}>
												Trailer video not found, put the id or the complete url
											</Typography>
										)}
									</Grid>
								</Grid>
								<Grid item xs={12} md={12} ref={loglineRef}>
									<TextArea
										error={errors.logline}
										variant={'blue'}
										minRows={5}
										label="Logline*"
										placeholder="-- EXAMPLE LOGLINE -- Wounded and on the run, notorious gunman Quirt Evans (John Wayne) gallops onto a farm owned by Quaker Thomas Worth (John Halloran) and his family and collapses. When Quirt urgently insists upon sending a telegram, Thomas and his daughter Penelope (Gail Russell) drive him into town in their wagon. -- EXAMPLE LOGLINE --"
										aria-label="logline"
										helperText="Your story’s synopsis"
										value={project?.logline ?? ''}
										onChange={e => {
											handleChange({
												logline: e.target.value
											})
										}}
									/>
								</Grid>
								<Grid item xs={12} md={12} ref={storyRef}>
									<HtmlEditor
										{...{
											error: errors.story,
											label: 'Project Story*',
											helperText:
												'The project story is a place to get your fans excited about your project and your journey as a filmmaker. (You can use some HTML in your description as well as add images.)',
											value: project?.story,
											setValue: story => {
												handleChange({ story })
											}
										}}
									/>
								</Grid>
							</Grid>

							<Grid item xs={12} md={4} container spacing={2}>
								<Grid item xs={12} md={12} ref={posterRef}>
									<Typography component={'h2'} sx={{ marginBottom: '0.5rem' }}>
										Poster*
									</Typography>
									<CropPoster
										{...{
											helperTextLeft:
												'png, jpeg accepted. 24mb max. 976px x 1371px maximum',
											error: errors?.poster,
											setCroppedImage: image => {
												setPoster(image)
												updateErrors({ poster: false })
												setChangeDeteted(true)
											},
											defaultImage: project?.poster
										}}
									/>
								</Grid>
								<Grid item xs={12} md={12} ref={lookbookRef}>
									<DropZone
										{...{
											error: errors.lookbook,
											maxFiles: 1,
											multiple: false,
											maxSize: 24 * 1024 * 1024,
											accept: {
												'document/pdf': [],
												'application/pdf': []
											},
											label: 'Lookbook*',
											helperTextLeft: 'pdf accepted. 24mb max size',
											helperTextRightComponent: (
												<Link href="/#">What is a Lookbook?</Link>
											),
											defaultFile: project?.lookbook,
											setFile: lookbook => {
												handleChange({ lookbook })
											},
											clearFile: () => {
												handleChange({ lookbook: null })
											}
										}}
									/>
								</Grid>
								<Grid item md={12} xs={12} className={styles.dropB}>
									<DropZone
										{...{
											error: errors.treatment,
											maxFiles: 1,
											multiple: false,
											maxSize: 24 * 1024 * 1024,
											accept: {
												'document/pdf': [],
												'application/pdf': []
											},
											label: 'Treatment',
											helperTextLeft: 'pdf accepted. 24mb max size',
											defaultFile: project?.treatment,
											setFile: treatment => {
												handleChange({ treatment })
											},
											clearFile: () => {
												handleChange({
													treatment: null
												})
											}
										}}
									/>
								</Grid>
								<Grid item md={12} className={styles.dropB}>
									<DropZone
										{...{
											error: errors.script,
											maxFiles: 1,
											multiple: false,
											maxSize: 24 * 1024 * 1024,
											accept: {
												'document/pdf': [],
												'application/pdf': []
											},
											label: 'Script',
											helperTextLeft: 'pdf accepted. 24mb max size',
											defaultFile: project?.script,
											setFile: script => {
												handleChange({ script })
											},
											clearFile: () => {
												handleChange({
													script: null
												})
											}
										}}
									/>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} md={12} className={styles.edit_controls_bottom}>
							{renderButtons(project?.stageId)}
						</Grid>
					</Grid>
				</Container>
			)}
		</>
	)
}

export default ProjectEdit
