export const getGemOptionsService = () => ({
	method: 'get',
	url: '/gemOption'
})

export const speedUpService = (optionId: any) => ({
	method: 'post',
	url: '/gemOption/speedUp',
	data: { optionId }
})

export const speedUpByAmountService = (data: any) => ({
	method: 'post',
	url: '/gemOption/speedUp/byAmount',
	data
})

export const getSpeedUpValuesService = (data: any) => ({
	method: 'post',
	url: '/gemOption/speedUp/values',
	data
})
