import { createRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { setTrailerInfo } from '../projectDetailSlice'
import { getVideoInfoFromUrl } from 'utils/video.utils'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { getConfiguration } from 'services/configuration.service'

const useHomeTab = () => {
	const [linkCopied, setLinkCopied] = useState(false)
	const [configuration, setConfiguration] = useState({})
	const { project, trailerInfo, projectId, signupsStats } = useSelector(
		state => state.projectDetail
	)
	const { user: signInUser, isAuthenticated } = useSelector(
		state => state.signIn
	)
	const setSearchParams = useSearchParams()[1]
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [storyHeight, setStoryHeight] = useState(0)
	const [showMore, setShowMore] = useState(false)

	const [{ loading }] = useAxiosFetch(
		getConfiguration(),
		(response, error) => {
			if (!error) {
				setConfiguration(response)
			}
		},
		!isAuthenticated
	)

	const showMoreToggle = () => {
		setShowMore(!showMore)
	}
	const storyRef = createRef()

	const buildCategoriesLine = () =>
		project.genres?.map(g => <li key={`genre-${g.id}`}>{g.name}</li>)

	const handleSocialClick = tab => () => {
		setSearchParams({ tab })
	}

	const handleGoToEditProject = () => {
		navigate(`/project/edit/${projectId}`)
	}

	const handleCopyLink = () => {
		const baseUrl = window.location.origin
		const shareUrl = `${baseUrl}/join/c/${signInUser.shortUrl}/${project?.shortUrl}`
		navigator.clipboard.writeText(shareUrl)
		setLinkCopied(true)
		setTimeout(() => {
			setLinkCopied(false)
		}, 3000)
	}

	useEffect(() => {
		if (storyRef?.current?.clientHeight)
			setStoryHeight(storyRef?.current?.clientHeight)
	})

	useEffect(() => {
		const getTrailerInfo = async () => {
			const info = await getVideoInfoFromUrl(project?.trailer)
			dispatch(setTrailerInfo(info))
		}
		getTrailerInfo()
		return () => {
			dispatch(setTrailerInfo({}))
		}
	}, [project])

	const contests = project.contests
	const isAlreadyEnrolledInContest = contests?.length > 0
	const allActiveContests = project.allContests || []
	const canEnrollInContest =
		!isAlreadyEnrolledInContest && allActiveContests.length > 0
	const activeContest = allActiveContests[0]

	return {
		loading,
		project,
		storyRef,
		showMore,
		linkCopied,
		storyHeight,
		trailerInfo,
		signupsStats,
		configuration,
		handleGoToEditProject,
		buildCategoriesLine,
		handleSocialClick,
		showMoreToggle,
		handleCopyLink,
		canEnrollInContest,
		isAlreadyEnrolledInContest,
		activeContest
	}
}

export default useHomeTab
