import { useDispatch, useSelector } from 'react-redux'
import {
	addToast,
	removeToast,
	toggleToastExpand,
	toggleGlobalExpand,
	clearToasts,
	ToastNotification
} from './toastNotificationSlice'
import { RootState } from '@/store/store'

export const useToastNotification = () => {
	const dispatch = useDispatch()
	const { notifications, globalExpanded } = useSelector(
		(state: RootState) => state.toastNotification
	)

	const showToast = (toast: ToastNotification) => {
		dispatch(addToast(toast))
	}

	const dismissToast = (id: string) => {
		dispatch(removeToast(id))
	}

	const expandToast = (id: string) => {
		dispatch(toggleToastExpand(id))
	}

	const toggleExpand = () => {
		dispatch(toggleGlobalExpand())
	}

	const clearAllToasts = () => {
		dispatch(clearToasts())
	}

	return {
		showToast,
		dismissToast,
		expandToast,
		toggleExpand,
		clearAllToasts,
		notifications,
		globalExpanded
	}
}
