import _ from 'lodash'
import { useState } from 'react'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useNotification from 'hooks/useNotification'
import { isValidEmail } from 'utils/form.util'
import { setClaimUserDialogOpen } from '../tokenSaleExperienceSlice'
import {
	claimExistingSaleUserService,
	claimUserService
} from 'services/profile.service'
import useHeader from '@/components/header/useHeader'
import useLoginDialog from '@/components/header/loginDialog/useLoginDialog'
import useLoadingMask from 'hooks/useLoadingMask'
import useAxios from 'hooks/useAxios'
import { logger } from '@/utils/logger'

const useClaimUserDialog = () => {
	const initialData = {
		email: '',
		password: '',
		firstName: null,
		lastName: null
	}

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const [data, setData] = useState(initialData)
	const [newsletter, setNewsletter] = useState(false)
	const [passwordtoggle, setPasswordtoggle] = useState(true)
	const [isExistingUser, setIsExistingUser] = useState(false)
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const { reloadUserInfo } = useHeader()
	const { afterLogin } = useLoginDialog()

	const { claimUserDialogOpen: open } = useSelector(
		state => state.tokenSaleExperience
	)
	const { showNotification } = useNotification()
	const [errors, setErrors] = useState({})

	const updateErrors = payload => {
		setErrors({
			...errors,
			...payload
		})
	}

	const clearForm = () => {
		setData(initialData)
		setNewsletter(false)
		setPasswordtoggle(true)
		setErrors({})
	}

	const afterClaim = (data, error, responseError) => {
		if (!error) {
			if (data?.userExist) {
				setIsExistingUser(true)
			} else {
				showNotification({
					message:
						'Please check your email and use the link we provided to verify.',
					title: 'Verify Your Email!'
				})
				reloadUserInfo()
				handleClose()
				// TODO: should probably navigate to explore page
				logger.info('useClaimUserDialog: navigating to /')
				navigate('/')
			}
		} else {
			if (_.get(responseError, ['response', 'status']) === 409)
				setErrors({ email: 'This email is alreay in use' })
		}
	}

	const [{ loading }, claimSaleUser] = useAxiosFetch(
		claimUserService(data),
		afterClaim
	)

	const claimExistingSaleUser = () => {
		dispatch(setClaimUserDialogOpen(false))
		showMask(
			'Merging Accounts!  This may take a few minutes, please do not close or refresh the browser.'
		)
		axiosService(claimExistingSaleUserService(data), (response, error) => {
			if (!error) {
				afterLogin(response, false)
			} else {
				dispatch(setClaimUserDialogOpen(true))
			}
			hideMask()
		})
	}

	const isValid = () => {
		let isValid = true
		const errorsTmp = {}
		const message = 'This field is required'
		if (_.isNil(data.email) || data.email.trim() === '') {
			errorsTmp.email = message
			isValid = false
		}
		if (_.isNil(data.password) || data.password.trim() === '') {
			errorsTmp.password = message
			isValid = false
		}
		if (!_.isNil(data.email) && !isValidEmail(data.email)) {
			errorsTmp.email = 'The email format is incorrect.'
			isValid = false
		}
		if (!_.isNil(data.password) && !containsUpperAndLowerCase(data.password)) {
			errorsTmp.password =
				'The password should contain upper and lower case characters.'
			isValid = false
		}
		if (
			!_.isNil(data.password) &&
			!containsNumberOrSpecialChar(data.password)
		) {
			errorsTmp.password =
				'The password should contain some number or special character.'
			isValid = false
		}
		if (!_.isNil(data.password) && data.password.length < 8) {
			errorsTmp.password = 'The password should have at least 8 characters.'
			isValid = false
		}
		updateErrors(errorsTmp)
		return isValid
	}

	const handleSubmit = () => {
		if (isValid()) claimSaleUser()
		else
			showNotification({
				message: 'Please fix the form errors to save changes',
				type: 'error'
			})
	}

	const passwordShow = () => {
		setPasswordtoggle(!passwordtoggle)
	}

	const handleClose = () => {
		dispatch(setClaimUserDialogOpen(false))
		clearForm()
	}

	const containsUpperAndLowerCase = () => {
		return /(?=.*[a-z])(?=.*[A-Z])/.test(data.password)
	}

	const containsNumberOrSpecialChar = () => {
		return /[0-9!@#$%^&*)(+=.\-_]+/.test(data.password)
	}

	const handleChange = payload => {
		const key = Object.keys(payload)[0]
		updateErrors({ [key]: false })
		setData({
			...data,
			...payload
		})
	}

	const showLoading = loading

	return {
		data,
		open,
		errors,
		newsletter,
		showLoading,
		isExistingUser,
		passwordtoggle,
		claimExixtingSaleUser: claimExistingSaleUser,
		setNewsletter,
		passwordShow,
		handleSubmit,
		handleChange,
		handleClose
	}
}

export default useClaimUserDialog
