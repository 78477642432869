import { useWelcomeDialog } from './useWelcomeDialog'
import { Button } from '@mui/material'
import { ProjectCarrousel } from '../becomeSuperFanFirstDialog'
import { CustomDialog } from '@/components/common-ui/CustomDialog'
import superfan2 from '@/assets/images/superfan2.svg'
import { useNavigate } from 'react-router-dom'
import { ProjectList } from './ProjectList'
import { useState } from 'react'
import { cn } from '@/lib/utils'

const pStyle =
	'font-inter text-sm font-normal leading-6 md:text-base md:leading-7'

export const WelcomeDialog = () => {
	const { open, handleClose } = useWelcomeDialog()
	const navigate = useNavigate()
	const [showProjects, setShowProjects] = useState(false)

	const handleViewProjects = () => {
		setShowProjects(true)
		navigate('/explore')
	}

	const handleCloseProjects = () => {
		setShowProjects(false)
	}

	return (
		<CustomDialog
			open={open}
			onOpenChange={handleClose}
			className="max-w-[850px] p-0 sm:p-0"
		>
			<div className="relative">
				<div className="mb-0 flex flex-col items-center gap-2 p-2 sm:p-2 md:flex-row md:gap-3 md:p-5 md:pr-8">
					<img
						src={superfan2.src}
						alt="fan icon"
						className="w-[100%] rounded-lg md:mr-2 md:mt-2 md:w-[320px]"
					/>
					<div className="flex flex-col gap-2 md:gap-4">
						<h4 className="mb-0 mt-2 text-[22px] md:mt-0 md:text-[24px]">
							Your name in lights start here!
						</h4>
						<p className={pStyle}>
							We&apos;re giving you <strong>100 $FAN</strong> tokens for free -
							use them to become a superfan and earn epic perks on projects you
							love, such as your name forever immortalized in their end credits!
						</p>
					</div>
				</div>
				<div className="flex w-full flex-col items-center justify-center gap-2 rounded-b-lg !bg-[#081622] p-6 md:gap-4">
					<div className="hidden w-full max-w-[800px] text-center md:inline-block">
						<p className="mb-2 text-center font-inter text-sm font-bold md:text-base">
							Check out these projects to start your journey to becoming a
							superfan!
						</p>
						<ProjectCarrousel />
					</div>
					<div className="inline-block md:hidden">
						<Button
							onClick={handleViewProjects}
							className={cn(
								'flex h-9 w-full items-center justify-center gap-2 rounded-md bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] px-3 py-2 text-white',
								{ invisible: showProjects }
							)}
						>
							Show Me The Projects!
						</Button>
						{showProjects && <ProjectList handleClose={handleCloseProjects} />}
					</div>
				</div>
			</div>
		</CustomDialog>
	)
}
