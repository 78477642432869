import { Box, Icon, IconButton, Typography } from '@mui/material'
import { useRef } from 'react'
import {
	SnapList,
	SnapItem,
	useScroll,
	useDragToScroll,
	useVisibleElements
} from 'react-snaplist-carousel'
import styles from './NewsSlider.module.scss'
import entr from 'assets/images/homepage/entr.png'
import ihodl from 'assets/images/homepage/ihodl.png'
import forbes from 'assets/images/homepage/forbes.png'
import ibt from 'assets/images/homepage/ibt.png'
import ub from 'assets/images/homepage/ub-logo.png'

export const NewsSlider = () => {
	const snapList = useRef(null)

	const visible = useVisibleElements(
		{ debounce: 3, ref: snapList },
		([element]) => element
	)

	const goToSnapItem = useScroll({ ref: snapList })
	useDragToScroll({ ref: snapList, disabled: false })

	const handleNextClick = () => {
		goToSnapItem(visible + 3)
	}

	const handlePrevClick = () => {
		goToSnapItem(visible > 3 ? visible - 3 : 0)
	}

	const renderItems = () => {
		return (
			<>
				<SnapItem key={'news-1'} className={styles.snap_item}>
					<Box className={styles.news_item}>
						<img alt="" src={entr.src} />
						<Typography>
							“A creative incubator, market validator, and distribution
							launchpad for everyone who’s frustrated with Hollywood’s current
							model.”
						</Typography>
					</Box>
				</SnapItem>
				<SnapItem key={'news-2'} className={styles.snap_item}>
					<Box className={styles.news_item}>
						<img alt="" src={ihodl.src} />
						<Typography>
							“By introducing comprehensive algorithmic projections into the
							industry, Film.io provides financiers with a reason to invest in
							emerging creators instead of clinging tightly to traditional
							studios.”
						</Typography>
					</Box>
				</SnapItem>
				<SnapItem key={'news-3'} className={styles.snap_item}>
					<Box className={styles.news_item}>
						<img alt="" src={forbes.src} />
						<Typography>
							“[Film.io] is leveraging blockchain to speed up the script to
							screen, upload ideas, garner feedback and expose projects to
							studios and production companies without the need for personal
							connections…”
						</Typography>
					</Box>
				</SnapItem>
				<SnapItem key={'news-4'} className={styles.snap_item}>
					<Box className={styles.news_item}>
						<img alt="" src={ibt.src} />
						<Typography>
							“Film.io’s platform creates a secure ecosystem for the
							entertainment industry that facilitates collaboration, sharing and
							innovative production.”
						</Typography>
					</Box>
				</SnapItem>
				<SnapItem key={'news-5'} className={styles.snap_item}>
					<Box className={styles.news_item}>
						<img alt="" src={ub.src} />
						<Typography>
							“Decentralized filmmaking platform Film.io debuts during Sundance
							Film Festival announcing it will be making the platform available
							to the public for the first time ever, timed to the kick-off of
							the world-renowned Sundance Film Festival.”
						</Typography>
					</Box>
				</SnapItem>
			</>
		)
	}

	return (
		<Box className={styles.news}>
			{visible > 0 && (
				<IconButton
					size="small"
					onClick={handlePrevClick}
					className={styles.prev}
				>
					<Icon baseClassName="far" className="fa-chevron-left" />
				</IconButton>
			)}

			<SnapList ref={snapList} tabIndex={0}>
				{renderItems()}
			</SnapList>
			{visible < 6 - 3 && (
				<IconButton
					size="small"
					onClick={handleNextClick}
					className={styles.next}
				>
					<Icon baseClassName="far" className="fa-chevron-right" />
				</IconButton>
			)}
		</Box>
	)
}

export default NewsSlider
