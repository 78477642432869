import { Typography, Grid, Button, Box } from '@mui/material'
import { ActivityTable } from './activityTable'
import { WalletOverview } from './walletOverview'
import { FgrOverview } from './fgrOverview'
import { VestingOverwiew } from './vestingOverwiew'
import { DiamondCircleHodl } from './diamondCircleHodl'
import { VestingScheduleDialog } from './vestingScheduleDialog/vestingScheduleDialog'
import { FgrTable } from './fgrTable'
import { FraudDialog } from './fraudDialog'
import useWalletTab from './useWalletTab'
import { RewardUnlockProgression } from './rewardUnlockProgression'
import useCommon from 'hooks/useCommon'
import styles from './WalletTab.module.scss'

export default function WalletTab() {
	const {
		tabSelectedIndex,
		schedules,
		openVestingScheduleDialog,
		isHodl,
		isFraud,
		isVesting,
		changeTab,
		handleOpenVestingScheduleDialog,
		handleCloseVestingScheduleDialog
	} = useWalletTab()
	const { isMobileScreen } = useCommon()

	const isVestingSchedule = isVesting && schedules && schedules.length > 0
	const xs = tabSelectedIndex === 1 ? 9 : 12
	return (
		<Grid container spacing={2} className={styles.wallet_tab}>
			{isVestingSchedule && (
				<VestingScheduleDialog
					open={openVestingScheduleDialog}
					schedules={schedules}
					handleClose={handleCloseVestingScheduleDialog}
				/>
			)}
			<Grid
				item
				xs={12}
				className={
					isVestingSchedule
						? `${styles.header}` + ' ' + `${styles.header_with_btn}`
						: `${styles.header}`
				}
			>
				<Typography component={'h1'}>Fan Governance Tokens</Typography>
				<span
					onClick={() => changeTab(1)}
					className={`${tabSelectedIndex === 1 && styles.selected}`}
				>
					View Wallet Overview
				</span>
				<span
					className={`${tabSelectedIndex === 2 && styles.selected}`}
					onClick={() => changeTab(2)}
				>
					View Your FGR Balances
				</span>
				{isHodl && (
					<span
						className={`${tabSelectedIndex === 3 && styles.selected}`}
						onClick={() => changeTab(3)}
					>
						Diamond Circle HODL
					</span>
				)}
				{isVestingSchedule && (
					<Grid item md={4} sm={3} xs={2} className={styles.btns_container}>
						<Button
							className={styles.edit_profile_btn}
							onClick={handleOpenVestingScheduleDialog}
						>
							Vesting Schedule
						</Button>
					</Grid>
				)}
			</Grid>
			<Grid
				item
				xs={xs}
				sm={12}
				md={tabSelectedIndex === 1 ? 9 : 12}
				className={styles.body}
			>
				{tabSelectedIndex === 1 && (
					<>
						<WalletOverview changeTab={changeTab} />
						<VestingOverwiew />
						{isFraud && <FraudDialog />}
						<ActivityTable />
					</>
				)}
				{tabSelectedIndex === 2 && (
					<>
						<FgrOverview />
						<FgrTable />
					</>
				)}

				{tabSelectedIndex === 3 && (
					<Box className={styles.flex_box}>
						<DiamondCircleHodl changeTab={changeTab} />
						<VestingOverwiew />
						{isFraud && <FraudDialog />}
						<ActivityTable />
					</Box>
				)}
			</Grid>

			{tabSelectedIndex === 1 && !isMobileScreen && (
				<Grid item xs={xs} sm={12} md={3} className={styles.body}>
					<RewardUnlockProgression />
				</Grid>
			)}
		</Grid>
	)
}
