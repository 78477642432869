'use client'
import { Box } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import AppRoutes from './routes/AppRoutes'
import useQuery from './hooks/useQuery'
import { ErrorFallback, ErrorHandler } from '@/components/common/errorFallback'
import { initMixpanel } from 'services/mixpanel.service'
import { initSentry } from 'services/sentry.service'
import { ModalNotificationExtended } from '@/components/common/notificationExtendedModal'
import useWebSocket from 'hooks/websocket/useWebsockets'
import { FanPurchaseBanner } from '@/components/header/fanPurchaseBanner'
import Intercom from '@intercom/messenger-js-sdk'
import { NotificationDialog } from './components/common-ui/notificationDialog/NotificationDialog'
import { RootState } from './store/store'
import 'utils/axios.util'
import './App.scss'
import { ToastNotificationStack } from './components/common-ui/toastNotification/ToastNotification'

initSentry()
initMixpanel()

function App() {
	const { user, isAuthenticated } = useSelector(
		(state: RootState) => state.signIn
	)
	const isHodl = user?.isHodl
	const query = useQuery()
	useWebSocket()
	const isProfileTab4 = query.get('tab') === '4'

	useEffect(() => {
		Intercom({
			app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? '',
			user_id: user?.id,
			name: user?.displayName,
			email: user?.email,
			created_at: user?.createdAt,
		})
	}, [isAuthenticated])

	return (
		<ErrorBoundary FallbackComponent={ErrorFallback} onError={ErrorHandler}>
			{isHodl && isProfileTab4 ? (
				<Box className="diamond_bg"></Box>
			) : (
				<>
					<Box className="blue_light"></Box>
					<Box className="red_light"></Box>
				</>
			)}
			<Box className="App">
				<NotificationDialog />
				<ModalNotificationExtended />
				<FanPurchaseBanner />
				<ToastNotificationStack />
				<AppRoutes />
			</Box>
		</ErrorBoundary>
	)
}

export default App
