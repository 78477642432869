import { useDispatch } from 'react-redux'
import {
	setStakeCompletedEvent,
	setStakeUnstakedEvent
} from '../webSocketSlice'

const useStakeHandlers = () => {
	const dispatch = useDispatch()

	const handleStakeCompletedNotification = (data: any) => {
		dispatch(setStakeCompletedEvent(data))
	}

	const handleStakeUnstakedNotification = (data: any) => {
		dispatch(setStakeUnstakedEvent(data))
	}

	return {
		handleStakeCompletedNotification,
		handleStakeUnstakedNotification
	}
}

export default useStakeHandlers
