'use client'
'use client'

import { useEffect, useState } from 'react'
import useNotification from 'hooks/useNotification'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { getJoinUsersByProjectId } from 'services/socialJoin.service'
import { followUnfollowUser } from 'services/followUnfollow.service'

const useRecentJoinsTable = () => {
	const { showNotification } = useNotification()
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const [signups, setSignups] = useState([])
	const [count, setCount] = useState(0)
	const [pagin, setPagin] = useState({
		page: 0,
		limit: 6,
		offset: 0
	})

	const { limit, offset } = pagin

	const getSignups = () => {
		showMask()
		axiosService(
			getJoinUsersByProjectId({ limit, offset }),
			(response, error) => {
				if (!error) {
					const { rows, ...stats } = response
					setCount(stats.count)
					setSignups(rows)
				}
				hideMask()
			}
		)
	}

	useEffect(() => {
		getSignups()
	}, [pagin])

	const handleChangePage = (_event, newPage) => {
		const offset = Number(newPage) * Number(pagin.limit)
		setPagin({
			...pagin,
			offset,
			page: newPage
		})
	}

	const handleChangeRowsPerPage = event => {
		setPagin({
			...pagin,
			page: 0,
			offset: 0,
			limit: event.target.value
		})
	}

	const handleFollowUnfollowUser =
		({ following, userId, displayName }) =>
		async () => {
			const res = await followUnfollowUser(userId, following)
			if (res.status === 200) {
				const message = `You are ${
					following ? 'no longer' : 'now'
				} following ${displayName}`
				showNotification({ message })
				getSignups()
			}
		}

	const emptyRows =
		pagin.page > 0 ? Math.max(0, (1 + pagin.page) * pagin.limit - count) : 0

	return {
		pagin,
		count,
		signups,
		emptyRows,
		handleFollowUnfollowUser,
		handleChangeRowsPerPage,
		handleChangePage
	}
}

export default useRecentJoinsTable
