import { Box, Typography } from '@mui/material'
import styles from './FraudDialog.module.scss'
import { useDispatch } from 'react-redux'
import { setFraudWarningDialogOpen } from '@/components/header/signInSlice'

export default function FraudDialog() {
	const dispatch = useDispatch()

	const handleOpen = () => {
		dispatch(setFraudWarningDialogOpen(true))
	}

	return (
		<>
			<Box className={styles.fraud_container} onClick={handleOpen}>
				<Typography className={styles.fraud_message}>
					Unfortunately you failed to pass our fraud prevention system, click
					this banner for more information.
				</Typography>
			</Box>
		</>
	)
}
