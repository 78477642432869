export const addReviewService = (data: any) => ({
	method: 'post',
	url: '/review',
	data
})

export const updateReviewHashService = (data: any) => ({
	method: 'put',
	url: '/review',
	data
})

export const retryReviewBonusService = (reviewId: string) => ({
	method: 'post',
	url: '/review/bonus/retry',
	data: { reviewId }
})

export const getReviewService = (projectId: string, page: number) => ({
	method: 'get',
	url: `/review/${projectId}`,
	params: { page },
	autoexec: false
})

export const getUserReviewService = (projectId: string) => ({
	method: 'get',
	url: `/review/user/${projectId}`,
	autoexec: true
})

export const getReviewByIdService = (reviewId: string) => ({
	method: 'get',
	url: `/review/byId/${reviewId}`,
	params: { reviewId },
	autoexec: false
})

export const isReviewedService = (projectId: string) => ({
	method: 'get',
	url: `/review/isReviewed/${projectId}`,
	autoexec: false
})
