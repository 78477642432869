import { Button } from '@/components/ui/button'
import logo from '@/assets/images/fanDashboard/logo.svg'
import logoMobile from '@/assets/images/fanDashboard/logo-mobile.png'
import filmioIcon from '@/assets/images/fanDashboard/filmio-icon.png'
import { Link } from 'react-router-dom'
import { ProfileMenu } from './profileMenu'
import { SearchInput } from './searchInput/SearchInput'
import { Notifications } from './notifications'
import Image from 'next/image'
import { useDispatch, useSelector } from 'react-redux'
import {
	setLoginDialogOpen,
	setFanPurchanseDialogOpen,
	setShowFanSearchMobile
} from '@/components/header/signInSlice'
import { cn } from '@/lib/utils'
import { abbreviateNumberFixed } from '@/utils/utils'
import MobileProfileSheet from './mobileProfileSheet/MobileProfileSheet'
import { CustomButton } from '@/components/common'
import medal from '@/assets/images/medal-2.svg'
import { useSuperfanSection } from '@/components/profile/dashboardTab/superfanSection/useSuperfanSection'
import { RootState } from '@/store/store'
import { Mixpanel } from 'services/mixpanel.service'

export const Header = ({ className }: { className?: string }) => {
	const dispatch = useDispatch()
	const { showFanSearchMobile, user, isAuthenticated } = useSelector(
		(state: RootState) => state.signIn
	)
	const { totalPoints } = useSuperfanSection()

	const handleCloseSearch = () => {
		dispatch(setShowFanSearchMobile(false))
	}

	const showFanPurchaseDialog = () => {
		Mixpanel.track('fan-purchase-button-click', {
			distinct_id: user?.id,
			displayName: user?.displayName,
			username: user?.username,
			balance: user?.balance,
			totalPoints
		})
		dispatch(setFanPurchanseDialogOpen(true))
	}

	const openLoginDialog = () => {
		dispatch(setLoginDialogOpen(true))
	}

	const isBuyFanButtonVisible =
		user?.isOnRamperEnabled || user?.isFanPurchaseWithCryptoEnabled

	return (
		<header
			className={cn(
				`${
					showFanSearchMobile
						? 'max-[639px]:!h-[120px] max-[639px]:flex-wrap'
						: 'h-header'
				} flex w-full items-center justify-between border-b-[0.25px] border-[#373833] bg-[#121212] px-[16px] transition-all duration-300 zoom-in-150`,
				className
			)}
		>
			<div
				className={`text-3xl font-bold ${
					showFanSearchMobile ? 'max-[639px]:order-1' : ''
				}`}
			>
				<Link to="/explore">
					<Image
						className="object-contain max-[639px]:hidden sm:block"
						src={logo.src}
						alt="logo"
						width={120}
						height={41}
					/>
					<Image
						className="max-[639px]:block sm:hidden"
						src={logoMobile.src}
						alt="logo"
						width={41}
						height={41}
					/>
				</Link>
			</div>
			<div
				className={`${
					showFanSearchMobile
						? 'max-[639px]:order-3 max-[639px]:basis-full'
						: ''
				} px-1 sm:relative sm:!flex sm:w-1/2 sm:items-center sm:justify-center`}
			>
				<SearchInput
					showFanSearchMobile={showFanSearchMobile}
					handleCloseSearch={handleCloseSearch}
				/>
			</div>
			<div
				className={`${
					showFanSearchMobile ? 'max-[639px]:order-2' : ''
				} flex h-10 items-center justify-center gap-4 sm:gap-6`}
			>
				{isAuthenticated ? (
					<>
						<div className="flex hidden items-center justify-center sm:inline-flex">
							<Notifications />
						</div>

						{isBuyFanButtonVisible && (
							<CustomButton
								onClick={showFanPurchaseDialog}
								customStyle="buy_fan"
								className="h-10 shrink-0 font-bold"
							>
								Buy $FAN
							</CustomButton>
						)}

						<Button
							variant="outline"
							className="flex items-center justify-center border-none bg-blue-500 text-black"
							asChild
						>
							<Link to={`/profile/${user?.username}?tab=4`}>
								<img
									className="h-[16px] w-[16px] object-contain"
									src={filmioIcon.src}
									alt="Filmio Icon"
								/>
								{abbreviateNumberFixed(user?.balance ?? 0, 2)} /
								<img
									className="w-[16px] object-contain"
									src={medal?.src}
									alt="Superfan Points Icon"
								/>
								{abbreviateNumberFixed(totalPoints, 2)}
							</Link>
						</Button>

						<ProfileMenu triggerClassName="hidden sm:inline-flex" />
						<MobileProfileSheet triggerClassName="sm:hidden" />
					</>
				) : (
					<>
						<Button
							variant="ghost"
							className="inline-flex px-2 text-white hover:text-primary hover:bg-dark"
							asChild
						>
							<Link to="/explore">Explore</Link>
						</Button>

						<Button
							className="inline-flex px-2 text-white hover:text-primary hover:bg-dark"
							variant="ghost"
							asChild
						>
							<Link to="/newsreel">Newsreel</Link>
						</Button>

						<Button className="inline-flex" onClick={openLoginDialog}>
							Login/Signup
						</Button>
					</>
				)}
			</div>
		</header>
	)
}
