export const addTokenSaleTransaction = (data: any) => ({
	method: 'post',
	url: '/tokenSaleTransaction',
	data
})

export const getUserTokenSaleTransactions = (params: any) => ({
	method: 'get',
	params,
	url: '/tokenSaleTransaction/user',
	autoexec: false
})

export const getUserLastTokenSaleTransaction = (params: any) => ({
	method: 'get',
	params,
	url: '/tokenSaleTransaction/user/last',
	autoexec: false
})

export const getTokenSalePhase = () => ({
	method: 'get',
	url: '/tokenSaleCampaign/phase',
	autoexec: false
})
