import { Box, Button } from '@mui/material'
import {
	TextFieldControl,
	CustomButton,
	CustomDialog,
	LoadMask,
	PasswordStrenght
} from '@/components/common'
import useJoinDialog from './useClaimUserDialog'
import styles from './ClaimUserDialog.module.scss'
import _ from 'lodash'

export const ClaimUserDialog = () => {
	const {
		data,
		open,
		errors,
		showLoading,
		isExistingUser,
		passwordtoggle,
		claimExixtingSaleUser,
		passwordShow,
		handleSubmit,
		handleChange,
		handleClose
	} = useJoinDialog()

	return (
		<>
			{showLoading ? (
				<LoadMask />
			) : (
				<CustomDialog {...{ open, handleClose, variant: 'join' }}>
					<Box className={styles.title}>
						Be a Part of the World’s
						<br />
						First Filmmaking DAO.
						{isExistingUser ? (
							<span>
								Enter password of your email to claim the sale account
							</span>
						) : (
							<span>
								Complete your profile to begin your creative journey on the
								world’s first entertainment DAO!
							</span>
						)}
					</Box>
					<Box className={styles.content}>
						<Box className={styles.fields}>
							{!isExistingUser && (
								<Box className={styles.row}>
									<TextFieldControl
										error={errors?.firstName}
										classes={{ root: styles.first_name }}
										hiddenLabel
										fullWidth
										name="firstName"
										placeholder="First Name"
										value={data.firstName}
										onChange={e => {
											handleChange({ firstName: e.target.value })
										}}
									/>
									<TextFieldControl
										error={errors?.lastName}
										classes={{ root: styles.last_name }}
										hiddenLabel
										fullWidth
										name="username"
										placeholder="Last Name"
										value={data.lastName}
										onChange={e => {
											handleChange({ lastName: e.target.value })
										}}
									/>
								</Box>
							)}
							<TextFieldControl
								error={errors?.email}
								classes={{ root: styles.email_input }}
								hiddenLabel
								fullWidth
								name="email"
								placeholder="Email"
								value={data.email}
								onChange={e => {
									handleChange({ email: _.trim(e.target.value) })
								}}
							/>
							<Box className={styles.password_container}>
								<TextFieldControl
									error={errors?.password}
									FormHelperTextProps={{
										component: 'div',
										classes: { root: styles.form_helper }
									}}
									helperText={<PasswordStrenght password={data.password} />}
									type={passwordtoggle ? 'password' : 'text'}
									hiddenLabel
									fullWidth
									name="password"
									placeholder="Password"
									value={data.password}
									onChange={e => {
										handleChange({ password: e.target.value })
									}}
								/>

								<Button variant="text" onClick={passwordShow}>
									{passwordtoggle ? 'Show' : 'Hide'}
								</Button>
							</Box>
						</Box>
						{isExistingUser ? (
							<CustomButton
								customStyle={'outlined'}
								onClick={claimExixtingSaleUser}
							>
								Merge Accounts
							</CustomButton>
						) : (
							<CustomButton
								customStyle={'blue_w_border'}
								onClick={handleSubmit}
							>
								Join
							</CustomButton>
						)}
					</Box>
				</CustomDialog>
			)}
		</>
	)
}
