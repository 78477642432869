import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow
} from '@/components/ui/table'
import { useEffect, useState } from 'react'
import { Icon } from '@mui/material'
import {
	Pagination,
	PaginationContent,
	PaginationFirst,
	PaginationItem,
	PaginationLast,
	PaginationNext,
	PaginationPrevious
} from '@/components/ui/pagination'
import { ShareMenu } from '@/components/common-ui/shareMenu'
import { StakeFormDialog } from '@/components/common-ui/stakeFormDialog/StakeFormDialog'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { getUserSuperFanDashboardProjectsService } from 'services/superfan.service'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Button } from '@/components/ui/button'
import { GoShareAndroid } from 'react-icons/go'
import medal from '@/assets/images/medal-2.svg'

const ProjectRow = ({ project }) => {
	return (
		<>
			<TableRow className="border-b border-gray-700">
				<TableCell className="w-32 min-w-32 p-4">
					<div className="flex flex-row justify-between gap-4">
						{project.poster ? (
							<img
								className="rounded-sm"
								src={project.poster}
								alt="poster"
								width={20}
								height={24}
							/>
						) : (
							<span className="flex !h-6 w-5 !min-w-5 items-center justify-center rounded-sm bg-white text-xs font-bold text-background"></span>
						)}
						<span className="flex w-full justify-start">
							<Link to={`/project/${project?.slug}`}>{project?.name}</Link>
						</span>
					</div>
				</TableCell>
				<TableCell className="p-4">{project.place}</TableCell>
				<TableCell className="p-4">{project.totalPoints}</TableCell>
				<TableCell className="p-4">{project.staked}</TableCell>
				<TableCell className="p-4">{project?.fgrPlus || 0}%</TableCell>
				<TableCell className="p-4">
					<div className="flex items-center space-x-2">
						<ShareMenu
							project={project}
							trigger={
								<Button variant="table" className="h-9 w-8 p-2">
									<GoShareAndroid />
								</Button>
							}
						/>
						<StakeFormDialog
							trigger={
								<Button variant="table" className="h-9 w-8 p-2">
									<Icon
										baseClassName="fa-kit"
										className="fa-fan-stake !max-h-4"
									/>
								</Button>
							}
							selectedProject={{
								id: project.projectId,
								title: project.name,
								stageId: project.stageId,
								user: { id: project.userId }
							}}
						/>
					</div>
				</TableCell>
			</TableRow>
		</>
	)
}

export const SuperFanPerformance = () => {
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const [currentPage, setCurrentPage] = useState(1)
	const [totalPages, setTotalPages] = useState(1)
	const [data, setData] = useState([])
	const { stakeCompletedEvent } = useSelector(state => state.websocket)

	const afterFetchProjects = (response, error) => {
		if (!error) {
			setTotalPages(Math.ceil(response?.count / 5))
			setData(response?.rows)
		}
		hideMask()
	}

	const getProjects = page => {
		showMask()

		axiosService(
			getUserSuperFanDashboardProjectsService({
				limit: 5,
				offset: 5 * page - 5
			}),
			afterFetchProjects
		)
	}

	const handlePageChange = page => {
		setCurrentPage(page)
	}

	useEffect(() => {
		getProjects(currentPage)
	}, [currentPage])

	useEffect(() => {
		if (stakeCompletedEvent) {
			getProjects(currentPage)
		}
	}, [stakeCompletedEvent])

	return (
		<div className="custom-scrollbar p-4">
			<div className="text-lg font-bold">Superfan Performance</div>
			<Table className="w-full px-4 text-left text-sm">
				<TableHeader className="min-w-full">
					<TableRow>
						<TableHead className="p-4">NAME</TableHead>
						<TableHead className="p-4">PLACE</TableHead>
						<TableHead className="p-4">
							<img
								className="mx-auto w-[16px]"
								src={medal?.src}
								alt="Superfan Points Icon"
							/>
						</TableHead>
						<TableHead className="p-4">STAKED</TableHead>
						<TableHead className="p-4">FGR+</TableHead>
						<TableHead className="p-4">
							<span className="invisible">Actions</span>
						</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{data.map((row, index) => (
						<ProjectRow key={index} project={row} />
					))}
				</TableBody>
			</Table>

			{data.length === 0 && (
				<div className="space-y-2 py-4 text-base leading-snug">
					<div className="text-lg">Become a Superfan!</div>
					<div className="text-balance text-muted-foreground">
						Support your favorite projects and unlock exclusive benefits. Stake
						tokens to become a superfan and track your performance here.
					</div>
				</div>
			)}

			{/* Pagination */}
			{data.length > 0 && (
				<div className="flex items-center justify-between p-4">
					<Pagination>
						<PaginationContent>
							<PaginationItem>
								<PaginationPrevious
									onClick={() =>
										handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
									}
									className={currentPage === 1 && 'opacity-50'}
								/>
								<PaginationFirst
									onClick={() => handlePageChange(currentPage > 1 ? 1 : 1)}
									className={currentPage === 1 && 'opacity-50'}
								/>
							</PaginationItem>
							<PaginationItem>
								Page {currentPage} of {totalPages}
							</PaginationItem>

							<PaginationItem>
								<PaginationLast
									onClick={() =>
										handlePageChange(
											currentPage < totalPages ? totalPages : totalPages
										)
									}
									disabled={currentPage === totalPages}
									className={currentPage === totalPages && 'opacity-50'}
								/>
								<PaginationNext
									onClick={() =>
										handlePageChange(
											currentPage < totalPages ? currentPage + 1 : totalPages
										)
									}
									disabled={currentPage === totalPages}
									className={currentPage === totalPages && 'opacity-50'}
								/>
							</PaginationItem>
						</PaginationContent>
					</Pagination>
				</div>
			)}
		</div>
	)
}
