import {
	Avatar,
	Badge,
	Box,
	Button,
	Container,
	Grid,
	Icon,
	Typography
} from '@mui/material'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { ProfileTabSection } from './profileTabSection'
import useProfile from './useProfile'
import { useState } from 'react'
import images from 'assets/images'
import { VerifiedPopover } from './verifiedPopover'
import styles from './Profile.module.scss'
import Image from 'next/image'

const Profile = () => {
	const {
		user,
		isAdmin,
		tabIndex,
		isSignInUserProfile,
		handleFollowUnfollowUserClick
	} = useProfile()
	const [anchorEl, setAnchorEl] = useState(null)

	if (!user) {
		return null
	}

	const {
		id,
		avatar,
		displayName,
		isFollowedByUser,
		daoTierRingImg,
		daoTierName,
		daoTierImg,
		profession,
		headerImg
	} = user

	const daoTier = _.capitalize(daoTierName)

	const handlePopoverOpen = event => {
		setAnchorEl(event.currentTarget)
	}

	const handlePopoverClose = () => {
		setAnchorEl(null)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const profileHeaderImg = headerImg || images.defaultProfileHeader

	return (
		<Box className={styles.profile_section}>
			<Container disableGutters maxWidth="lg">
				<Grid container className={styles.profile_wrapper}>
					<Grid item md={6} xs={8} className={styles.profile_info}>
						<Badge
							overlap="circular"
							anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
							badgeContent={
								<img alt="" src={daoTierImg} className={styles.avatar_badge} />
							}
						>
							<Avatar
								alt={displayName}
								sx={{
									bgcolor: '#000',
									height: '70px',
									width: '70px'
								}}
								src={avatar}
							/>
							<img alt="" src={daoTierRingImg} className={styles.avatar_ring} />
						</Badge>
						<Box className={styles.info_container}>
							<Box className={styles.display_name}>
								<Typography>{displayName}</Typography>
								{user.isVerified === 1 && (
									<>
										<span onClick={handlePopoverOpen}>
											<Icon baseClassName="fas" className="fa-badge-check" />
										</span>
										<VerifiedPopover
											{...{
												anchorEl,
												handlePopoverClose,
												handleClose
											}}
										/>
									</>
								)}
							</Box>
							<Typography className={styles.profession}>
								{profession}
							</Typography>
							<Typography className={styles.membership}>
								{daoTier} DAO Membership
							</Typography>
						</Box>
					</Grid>
					<Grid item md={6} xs={4} className={styles.btns_container}>
						{(Number(isAdmin) === 1 || isSignInUserProfile) && (
							<>
								<Link
									to={`/profile/edit/${id}`}
									className={styles.edit_profile_btn}
								>
									Edit Profile
								</Link>
							</>
						)}
						{!isSignInUserProfile && (
							<Button
								className={styles.follow_btn}
								onClick={handleFollowUnfollowUserClick}
							>
								{`${isFollowedByUser ? 'Unfo' : 'Fo'}llow`}
							</Button>
						)}
					</Grid>
					<Grid
						item
						md={12}
						className={`${styles.profile_header} ${
							tabIndex === 0 ? '' : styles.no_dashboard
						}`}
					>
						<Image
							className={styles.artwork}
							src={profileHeaderImg}
							alt="user header image"
							width={1080}
							height={495}
							quality={100}
							blurDataURL="data:image/webp;base64,UklGRpIDAABXRUJQVlA4IIYDAAAwVACdASq+Ak8BPpFGoEylo6aioPM44NASCWlu4XSEDmNwuOAEUiAFaPl3Ser7/rMxWvv+szFa+/6zMVr7/okkfNoPV9/1mYrX3/WZitff9ZmK1+AOeD1ff9ZmK19/1mYrX3/WZitff95d9X3/WZitff9ZmK19/1mYrX3/XCS5cGoAg9X3/WZc9iuJ7rHBqAIPV9/18utZmK19/1mYrUKJFzYboLKG+1QKXLKBqAIPV59oOFHrMxWvv9pUSP+fr+wgR8afC3bSFNSovmpgBmdoxVvq+/6zMVrzpoL89X3/WZb9zcn9cNAiP5WOQW6oMvqPZJHPA7Av5Xc6L96y4DKBqAIPVKSEAQer7/rEMzGFACdzBky0qhmvMZw45Y4VWg3qF69ki5ZQNOi7r/rMxWvvucKXmFrs1SShl0fJFDkgAgnW5Dfvu43VDlA1AEHq86aC/PV9/1mEX8hDIatiDhCsSNjNYXlyxGLEf/YufVqnNcmSLllA0/l9W+DCigCD1QNE19DjQq8nJ0F4aPJAOgFx7xIYENATNqOlVoJvKraRcsoGoAOkyrX3/WZitRhTMHt3fy5Cm8vD2mA5cPBX4dPHcCMe+weAo4UeszFa8oafBllA1AEHq8zTJJcIJTJIcTA6mrILe5HD9ajiI4UeszFa8uPwCPWZitff9Nh0UMWGK+GffJvvlUk4/fb08W/slFAEHq+++BsDg1AEHq+/13EFXUzi4KAQq5V9sgOt3eJ7R/AUUAQer774GwODUAQer7/xxDVPi++HPq+/6zMVqKzW0bCxy7B+lA2xc0RMbJjpYfLkWOXYP0oG2Fc8u9qAIPV9/1mYrX3/WZitff9ZmKr9XwooAg9X3/WZitff9ZmK19/1mXK7r/rMxWvv+szFa+/6zMVr7/rMxG9AAP7+9R+AuXeB9CSb0oxgAAAVNp5FaYvHQrqFPKJjE7+ttDemOoumGorCrON8YDxzfeVjAZRJVR9eN7VVG/UFCyMC4V3WtA/9hrJ78sBVRXq+SV0vOyAxHZ2/c5D91k4zaRRhlxxTzlAVteCCTmNzqwrAGbG+yeRxDNxuqG/qnsoItqvWosHcfgP8XUIVTP6ckDhje5zzWEuSLV4TJTV0DpNDxYG0xLIIm592NCRcQLJgruNTmoMguwLxBkAMwTSUDb5NexQxcYABx94AEjIAJyQAAOBckAAAAAAAAA=="
						/>
					</Grid>
					<Grid item md={12}>
						<ProfileTabSection />
					</Grid>
				</Grid>
			</Container>
		</Box>
	)
}

export default Profile
