import images from '@/assets/images'

interface Props {
	emptyMessage: string
	onClick?: () => void
}

export const ProjectEmptyContent = ({ emptyMessage, onClick }: Props) => {
	return (
		<div
			className="mt-4 flex cursor-pointer items-center gap-4 rounded-lg border border-[#1b568c] bg-[#182634] p-4 text-base font-bold"
			onClick={onClick}
		>
			<img alt="" src={images?.newsreelEmpty} />
			{emptyMessage}
		</div>
	)
}
