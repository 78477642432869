export const getProfileFgrListByProject = ({
	limit,
	offset
}: {
	limit: number
	offset: number
}) => ({
	method: 'get',
	url: `/fgr/profile/list`,
	params: { limit, offset }
})

export const getUserFgrService = () => ({
	method: 'get',
	url: `/fgr/user`
})

export const claimAllUserFgrService = () => ({
	method: 'post',
	url: `/fgr/user/all`
})
