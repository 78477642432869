import useAxiosFetch from 'hooks/useAxiosFetch'
import useLoadingMask from 'hooks/useLoadingMask'
import { useEffect } from 'react'
import { getDashboardQuestsService } from 'services/dashboard.service'
import _ from 'lodash'

export const useQuests = () => {
	const { showMask, hideMask } = useLoadingMask()

	const [{ loading, response, error }] = useAxiosFetch(
		getDashboardQuestsService()
	)

	useEffect(() => {
		loading ? showMask() : hideMask()
	}, [loading])

	return {
		loading,
		quests: !_.isNil(response?.data) ? response.data : [],
		error
	}
}
