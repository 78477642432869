import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { getProjectBySlugService } from 'services/project.service'
import { useEffect } from 'react'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { getProjectCollaboratorRolePermissions } from 'services/collaboratorRole.service'
import {
	setProjectId,
	setRolePermissions,
	updateProject
} from '../projectDetail/projectDetailSlice'
import { queryKeys } from '@/lib/queryKeys'
import { getVideoInfoFromUrl } from '@/utils/video.utils'
import { RootState } from '@/store/store'

interface Stage {
	id: number
	name: string
}

interface Genre {
	id: number
	name: string
}

interface User {
	id: string
	displayName: string
	avatar: string | undefined
	profession: string | undefined
	username: string
	isVerified: number
	isFollowedByUser: boolean
	isAdmin: number
}

interface ProjectType {
	id: number
	name: string
}

interface GoScoreStage {
	id: number
	name: string
	color: string
	label: string
}

export interface Project {
	id: string
	title: string
	logline: string
	story: string
	lookbook: string | null
	poster: string | null
	thumbnail: string | null
	coverImg: string | null
	trailer: string | null
	slug: string
	vaultLock: number
	likes: number
	socialMediaShares: number
	stakersCount: number
	stakesTotal: number
	reviewsCount: number
	newsreelsCount: number
	budget: number
	treatment: string | null
	vaultlockId: string
	vaultlockQR: string
	projectHash: string
	shortUrl: string
	approval: number
	staffPick: number
	script: string | null
	reviewsAvg: number
	views: number
	isOnPerksProgram: boolean
	userId: string
	typeId: number
	stageId: number
	statusId: number
	studioId: string | null
	goScoreStageId: number
	fgrPercent: number
	fgrPeriod: number
	vaultlockDate: string
	aiSummary: string | null
	keccak256IdHash: string
	isDecayActive: boolean
	decayEffectiveDate: string | null
	decayStatus: string
	createdAt: string
	updatedAt: string
	deletedAt: string | null
	stage: Stage
	genres: Genre[]
	contests: any[]
	user: User
	type: ProjectType
	goScoreStage: GoScoreStage
	followersCount: number
	isFollowedByUser: boolean
	totalSuperfans: number
	isSuperfan: boolean
	allContests: any[] // Update this if you have contest type
	superfanStats: {
		totalPoints: number
		displayName: string
		username: string
		rank: number
		createdAt: string
	}
	userProjectMetrics: {
		reviewRating: number
		fanCount: number
		stakesTotal: number
		commentCount: number
		sharesCount: number
		fanLevelBonus: number
	}
}

export interface TrailerInfo {
	hasTrailer?: boolean | undefined
	player_embed_url?: string | undefined
	thumbnail_url?: string | undefined
}

interface RolePermission {
	permissionId: number
}

export const useProjectPage = ({ slug }: { slug: string }) => {
	const dispatch = useDispatch()
	const { user: signInUser, isAuthenticated } = useSelector(
		(state: RootState) => state.signIn
	)
	const { axiosServiceSync } = useAxios()
	const { hideMask, showMask } = useLoadingMask()
	const navigate = useNavigate()
	const queryClient = useQueryClient()

	const { isLoading: loading, data: project } = useQuery<Project>({
		queryKey: queryKeys.project.detail(slug),
		queryFn: async () => {
			const result = await axiosServiceSync(getProjectBySlugService(slug))
			dispatch(setProjectId(result.id)) // For legacy stakes table, remove this once the stakes table is updated
			return result
		}
	})

	const isProjectAdmin =
		Number(signInUser?.isAdmin) === 1 ||
		_.get(project, ['user', 'id']) === signInUser?.id

	const reloadProject = () => {
		queryClient.invalidateQueries({ queryKey: queryKeys.project.detail(slug) })
	}

	const { data: trailerInfo } = useQuery<TrailerInfo>({
		queryKey: queryKeys.project.trailer(slug),
		queryFn: () =>
			getVideoInfoFromUrl(project?.trailer) as Promise<TrailerInfo>,
		enabled: Boolean(project) && Boolean(project?.trailer),
		retry: 1,
		staleTime: 1000 * 60 * 60 * 24 // 1 day
	})

	// Permissions query
	useQuery<RolePermission[]>({
		queryKey: queryKeys.project.permissions(project?.id),
		queryFn: async () => {
			const result = await axiosServiceSync(
				getProjectCollaboratorRolePermissions({ projectId: project?.id }),
				!isAuthenticated
			)
			dispatch(setRolePermissions(result))
			return result
		},
		enabled: !_.isNil(project?.id) && isAuthenticated
	})

	// for the time being update the redux store with the project data as old components rely on it
	useEffect(() => {
		if (project) {
			const isCreatorSignedIn =
				_.get(project, ['user', 'id']) === signInUser?.id
			const isCreatorOrAdmin =
				isCreatorSignedIn || Number(signInUser?.isAdmin) === 1

			if (project?.stageId !== 4 && !isCreatorOrAdmin) {
				navigate('/explore')
				return
			}

			dispatch(updateProject(project))
		}
	}, [project])

	useEffect(() => {
		loading ? showMask() : hideMask()
	}, [loading])

	return {
		project,
		loading,
		isProjectAdmin,
		trailerInfo: {
			hasTrailer: Boolean(project?.trailer),
			...trailerInfo
		},
		reloadProject
	} as const
}
