import { CustomDialog } from '@/components/common-ui/CustomDialog'
import { SuperfanLeaderboardList } from './SuperfanLeaderboardList'

export const LeaderBoardDialog = ({
	open,
	onOpenChange,
	projectId
}: {
	open: boolean
	onOpenChange: (_open: boolean) => void
	projectId: string
}) => {
	return (
		<CustomDialog
			open={open}
			onOpenChange={onOpenChange}
			title="Superfan Leaderboard"
			className="max-w-[500px]"
		>
			<SuperfanLeaderboardList projectId={projectId} />
		</CustomDialog>
	)
}
