import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@mui/material/Grid'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Box, Icon, IconButton } from '@mui/material'
import { ProjectItem } from '../projectItem'
import { TileItem } from '../tileItem'
import { FilteredDataChip } from '../filteredDataChip'
import useDashboard from '../useDashboard'
import styles from './DashboardProduct.module.scss'
import { DaoProposalItem } from '../daoProposalItem'
import { ContestCarousel } from '../contestCarousel'
import { useMediaQuery } from '@mui/material'
import { MobileMenu } from '../sidebarDashboard/mobileMenu'

const DashBoardProduct = () => {
	const dashboardRef = useRef()
	const [open, setOpen] = useState(false)
	const isMobileScreen = useMediaQuery('(max-width:430px)')
	const { activeContests } = useSelector(state => state.signIn)
	const isThereActiveContests = activeContests?.length > 0

	const {
		items,
		loading,
		loadMore,
		isAuthenticated,
		handleOpenJoinDialog,
		sharerDisplayName,
		onScroll
	} = useDashboard()

	const components = {
		updates: TileItem,
		project: ProjectItem,
		daoproposal: DaoProposalItem
	}

	const toggle = () => {
		setOpen(!open)
	}

	const isJoinPage = !isAuthenticated && sharerDisplayName
	const classes = `${styles.dashboard_product_section} ${
		isJoinPage ? styles.join_page : ''
	}`

	return (
		<>
			{isMobileScreen && (
				<MobileMenu {...{ open, setOpen, isThereActiveContests }} />
			)}
			<InfiniteScroll onScroll={onScroll} dataLength={10000000}>
				<section className={classes} ref={dashboardRef}>
					{isJoinPage && (
						<Box className={styles.top_join}>
							<span>
								<strong>{sharerDisplayName}</strong> has invited you to join
								Film.io!{' '}
								<a onClick={handleOpenJoinDialog}>Create your account here.</a>
							</span>
						</Box>
					)}
					<Box className={styles.product_container}>
						<ContestCarousel />
						<Box className={styles.filtered_data_chip}>
							<IconButton onClick={toggle} className={styles.open_button}>
								<Icon baseClassName="fal" className="fa-filter" />
							</IconButton>
							<FilteredDataChip />
						</Box>
						<Grid container>
							{items.map(item => {
								const type = item?.artifactType?.toLowerCase()
								const Component = components[type]
								return (
									<Box
										id={item.id}
										className={`${styles.itemContainer} ${styles[type]}`}
										key={item.id}
									>
										{Component && <Component {...{ ...item }} />}
									</Box>
								)
							})}
							{!loadMore && (
								<Box className={styles.loader}>
									Looks like you&apos;ve reached the end of the road
								</Box>
							)}
							{loading && (
								<Box className={styles.loader}>
									<Icon
										baseClassName="fas"
										className="fa-spinner fa-spin-pulse"
									/>
								</Box>
							)}
						</Grid>
					</Box>
				</section>
			</InfiniteScroll>
		</>
	)
}

export default DashBoardProduct
