import { useQueryClient } from '@tanstack/react-query'
import useAxios from '@/hooks/useAxios'
import React, { useRef, useCallback, useEffect } from 'react'
import { getProjectBySlugService } from '@/services/project.service'
import { Project } from '../useProjectPage'

interface PrefetchOptions {
	delay?: number
	staleTime?: number
	mouseMoveTrigger?: boolean
	minMoveDistance?: number
}

/**
 * Hook for smart prefetching of project data
 * @param slug - Project slug to prefetch
 * @param options - Configuration options for prefetching behavior
 * @returns Object with event handlers for prefetching
 */
export const usePrefetchProject = (
	slug: string,
	{
		delay = 500,
		mouseMoveTrigger = false,
		minMoveDistance = 50
	}: PrefetchOptions = {}
) => {
	const { axiosServiceSync } = useAxios()
	const queryClient = useQueryClient()
	const timeoutRef = useRef<ReturnType<typeof setTimeout>>()
	const mousePositionRef = useRef<{ x: number; y: number }>()
	const isPreFetchedRef = useRef(false)

	/**
	 * Cleanup function to clear any pending timeouts
	 */
	const cleanup = useCallback(() => {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current)
			timeoutRef.current = undefined
		}
	}, [])

	/**
	 * Main prefetch function
	 */
	const prefetch = useCallback(() => {
		if (isPreFetchedRef.current) return

		queryClient.prefetchQuery<Project>({
			queryKey: ['project', slug],
			queryFn: async () => {
				const project = await axiosServiceSync(getProjectBySlugService(slug))
				return project
			}
		})

		isPreFetchedRef.current = true
	}, [queryClient, axiosServiceSync, slug])

	/**
	 * Mouse movement handler for more precise prefetch triggering
	 */
	const handleMouseMove = useCallback(
		(event: React.MouseEvent) => {
			if (!mouseMoveTrigger || isPreFetchedRef.current) return

			const { clientX, clientY } = event

			if (!mousePositionRef.current) {
				mousePositionRef.current = { x: clientX, y: clientY }
				return
			}

			const distance = Math.sqrt(
				Math.pow(clientX - mousePositionRef.current.x, 2) +
					Math.pow(clientY - mousePositionRef.current.y, 2)
			)

			if (distance > minMoveDistance) {
				cleanup()
				timeoutRef.current = setTimeout(prefetch, delay)
			}
		},
		[prefetch, delay, cleanup, mouseMoveTrigger, minMoveDistance]
	)

	const handleMouseEnter = useCallback(() => {
		if (isPreFetchedRef.current) return
		timeoutRef.current = setTimeout(prefetch, delay)
	}, [prefetch, delay])

	const handleMouseLeave = useCallback(() => {
		cleanup()
		mousePositionRef.current = undefined
	}, [cleanup])

	// Cleanup on unmount
	useEffect(() => {
		return cleanup
	}, [cleanup])

	// Reset prefetch flag when slug changes
	useEffect(() => {
		isPreFetchedRef.current = false
	}, [slug])

	if (isPreFetchedRef.current) {
		return { prefetchHandlers: {} }
	}

	return {
		prefetchHandlers: {
			onMouseEnter: handleMouseEnter,
			onMouseLeave: handleMouseLeave,
			...(mouseMoveTrigger && { onMouseMove: handleMouseMove })
		}
	}
}
