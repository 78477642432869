'use client'

import _ from 'lodash'
import { useState } from 'react'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { addNewsreelService } from 'services/newsreel.service'
import useNotification from 'hooks/useNotification'
import { NEWSREEL_FEATURES } from '../NewsreelTab'
import { useDispatch, useSelector } from 'react-redux'
import { newsreelTabAddedHook } from '../newsreelTabSlice'

const useAddNewsreel = feature => {
	const [media, setMedia] = useState([])
	const [errors, setErrors] = useState({})
	const [content, setContent] = useState('')
	const { showNotification } = useNotification()
	const { projectId } = useSelector(state => state.projectDetail)
	const dispatch = useDispatch()

	const clearForm = () => {
		setContent('')
		setMedia([])
	}

	const FEATURE_MAP = {
		[NEWSREEL_FEATURES.project]: addNewsreelService({
			media,
			content,
			projectId
		}),
		[NEWSREEL_FEATURES.profile]: addNewsreelService({
			media,
			content
		})
	}

	const [{ loading }, createNewsreel] = useAxiosFetch(
		FEATURE_MAP[feature],
		afterCreateNewsreel
	)

	function afterCreateNewsreel(_data, error) {
		if (!error) {
			clearForm()
			dispatch(newsreelTabAddedHook())
			showNotification({ message: 'Newsreel added successfully' })
		}
	}

	const removeImg = idx => {
		setMedia(media.filter((_m, i) => i !== idx))
	}

	const handleContentChange = e => {
		setErrors({})
		setContent(e.target.value)
	}

	const handleAddNewsreel = () => {
		if (_.isNil(content) || content === '')
			setErrors({ content: 'Please add text before posting to your Newsreel.' })
		else createNewsreel()
	}

	const handleUploadClick = e => {
		const files = Array.from(e.target.files)
		setMedia([...media, ...files])
		e.target.value = null
	}

	return {
		removeImg,
		handleContentChange,
		handleAddNewsreel,
		handleUploadClick,
		clearForm,
		loading,
		content,
		errors,
		media
	}
}

export default useAddNewsreel
