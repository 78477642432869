import React, { useRef } from 'react'

interface CircularBarProps {
	value1: number // stakingPercent (green)
	value2: number // socialPercent (yellow)
	value3: number // projectPercent (blue)
	value4: number // creatorPercent (orange)
	height?: number
	lineWidth?: number
	background?: string
	onClick?: () => void
	children: React.ReactNode
}

/**
 * A circular progress bar that closely mimics the original implementation
 * with smooth transitions between color segments
 */
export const CircularBar = ({
	value1 = 0,
	value2 = 0,
	value3 = 0,
	value4 = 0,
	height = 48,
	lineWidth = 4,
	background = '#101010',
	onClick,
	children
}: CircularBarProps) => {
	const innerSize = height - lineWidth
	const circularProgress = useRef<HTMLDivElement>(null)

	// Calculate the conic gradient with smooth transitions
	const conicGradient = (v1: number, v2: number, v3: number, v4: number) => {
		const totalValue =
			[v1, v2, v3, v4].reduce((sum, item) => sum + item, 0) || 100

		const colorArray = [
			{
				color: '#08F996', // Green
				value: (v1 / totalValue) * 100
			},
			{
				color: '#DFD600', // Yellow
				value: (v2 / totalValue) * 100
			},
			{
				color: '#5BCDEC', // Blue
				value: (v3 / totalValue) * 100
			},
			{
				color: '#EC865B', // Orange
				value: (v4 / totalValue) * 100
			}
		]

		// Filter out zero values and sort by value (ascending)
		const filteredColors = colorArray.filter(item => item.value !== 0)
		filteredColors.sort((a, b) => a.value - b.value)

		const stops = []
		let counter = 0
		let prevCounter = 0

		for (let i = 0; i < filteredColors.length; i++) {
			const currentColor = filteredColors[i]

			// Ensure minimum segment size
			counter +=
				currentColor.value < 5
					? currentColor.value + (Number(currentColor.value) % 5) > 2
						? Number(currentColor.value) % 5
						: 5
					: currentColor.value

			// Add gradient stops for all but the last color
			if (i !== filteredColors.length - 1) {
				stops.push(
					`${currentColor.color} ${i === 0 ? '-3.6deg' : prevCounter + '%'} ${
						counter - 5
					}%`
				)

				// Update previous counter
				prevCounter +=
					currentColor.value < 5
						? currentColor.value + (Number(currentColor.value) % 5) > 2
							? Number(currentColor.value) % 5
							: 5
						: currentColor.value
			}

			// Special handling for the last color to create a smooth wrap-around
			if (i === filteredColors.length - 1)
				stops.push(
					`${currentColor.color} ${prevCounter}% 95%, ${filteredColors[0].color} 365.5deg`
				)
		}

		return `
	  conic-gradient(
	  from 270deg at 50% 50%, ${stops.join(', ')})
	`
	}

	return (
		<div
			className="relative cursor-pointer transition-transform duration-200"
			style={{ width: height, height: height }}
			onClick={onClick}
		>
			{/* Outer circle with gradient */}
			<div
				ref={circularProgress}
				className="absolute inset-0 rounded-full transition-all duration-300"
				style={{
					background: `${conicGradient(value1, value2, value3, value4)}`
				}}
			/>

			{/* Inner circle (background) */}
			<div
				className="absolute flex items-center justify-center rounded-full"
				style={{
					width: innerSize,
					height: innerSize,
					background,
					top: lineWidth / 2,
					left: lineWidth / 2
				}}
			>
				<span className="text-xl font-medium text-white">{children}</span>
			</div>
		</div>
	)
}
