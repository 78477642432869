import { Button } from '@/components/ui/button'
import { ChevronLeft, ChevronDown } from 'lucide-react'
import medal from '@/assets/images/medal-2.svg'
import { AvatarFilmio } from '@/components/common-ui/AvatarFilmio'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { MilestoneProgress } from './milestoneProgress'
import { YourImpactSection } from './yourImpactSection'
import useProjectCommon from '../useProjectCommon'
import { cn } from '@/lib/utils'
import { useState } from 'react'
import { SuperfanBenefits } from './superfanBenefits/SuperfanBenefits'
import { SuperfanLeaderboardList } from './superfanLeaderboard/SuperfanLeaderboardList'
import { SuperfanLeaderboard } from './superfanLeaderboard/SuperfanLeaderboard'

export interface ProjectSuperfanProps {
	className?: string
	id: string
	superfanStats?: { rank: number }
	totalSuperfans?: number
	handleSuperfanActionsClick: () => void
}

const SuperfanLearnMoreMessage = ({
	handleSuperfanActionsClick
}: {
	handleSuperfanActionsClick: () => void
}) => {
	return (
		<div
			role="button"
			className="cursor-pointer pl-14 pr-14 text-center"
			onClick={handleSuperfanActionsClick}
		>
			<p className="from-17.11% to-61.84% items-center justify-center gap-1 bg-gradient-to-r from-[#21F359] to-[#6B3BFA] bg-clip-text text-center font-inter text-sm font-medium leading-4 text-transparent">
				Get Superfan points to climb the leaderboard and earn perks!
				<span className="ml-1 cursor-pointer font-inter text-sm font-medium leading-4 text-[#2196F3] hover:text-[#2196F3] hover:underline">
					Learn More
				</span>
			</p>
		</div>
	)
}

export const ProjectSuperfan = ({
	className,
	id: projectId,
	superfanStats,
	totalSuperfans,
	handleSuperfanActionsClick
}: ProjectSuperfanProps) => {
	const [isLeaderboardOpen, setIsLeaderboardOpen] = useState(false)
	const { user } = useSelector((state: RootState) => state.signIn)
	const { project } = useProjectCommon()
	if (!project) return null
	const superfanPoints = project?.superfanStats?.totalPoints

	const handleLeaderboardToggle = () => {
		setIsLeaderboardOpen(!isLeaderboardOpen)
	}

	return (
		<div
			className={cn(
				'flex w-full flex-col gap-4 rounded-lg border border-[#1b568c] bg-[#192531] pb-5',
				className
			)}
		>
			<div className="flex flex-col gap-3 rounded-lg rounded-bl-[0] rounded-br-[0] border-0 border-b-[1px] border-[#1b568c] bg-[#192B3B] px-4 py-4">
				<div className="flex items-center">
					<AvatarFilmio
						avatar={user?.avatar}
						displayName={user?.displayName}
						className="h-[64px] w-[64px] border border-[3px] border-[#2196F3]"
					/>
					<div className="-ml-5 flex items-center justify-center rounded-sm border border-[#1b568c] bg-[#2195f3] bg-opacity-10 pl-7 pr-4">
						<span className="px-2 py-1 font-inter text-base font-bold leading-[28px] text-white">
							Superfan
						</span>
						<span className="mr-2 flex h-[28px] flex-col justify-center font-inter text-base font-bold leading-[28px] text-[#2196F3]">
							#{superfanStats?.rank} of {totalSuperfans}
						</span>
					</div>
					<Button
						onClick={handleLeaderboardToggle}
						variant="ghost"
						size="icon"
						className="ml-auto w-[50px] rounded-sm border border-[#2196F3] bg-[#2196F3]"
					>
						{isLeaderboardOpen ? (
							<ChevronDown className="h-5 w-5 animate-pulse" />
						) : (
							<ChevronLeft className="h-5 w-5 animate-pulse" />
						)}
					</Button>
				</div>
				{/* Milestone Progress Section */}
				{isLeaderboardOpen ? (
					<div className="flex cursor-pointer flex-col gap-5">
						<div
							onClick={handleSuperfanActionsClick}
							className="flex items-center justify-between"
						>
							<span className="font-inter text-sm font-bold text-white">
								Milestone Progress
							</span>
							<div className="flex items-center gap-1">
								<img src={medal?.src} alt="" className="z-10 h-10" />
								<span className="relative -top-1 -ml-4 rounded-sm bg-[#2196F3] bg-opacity-40 py-1 pl-5 pr-2 font-inter text-sm font-bold text-white">
									{superfanPoints}
								</span>
							</div>
						</div>
						<MilestoneProgress {...{ value: superfanPoints }} />

						{/* FGR Info */}
						<div className="rounded border-[1px] border-primary border-opacity-40 px-3 py-2 text-center font-inter text-sm font-medium text-white">
							Your FGR:{' '}
							<strong>
								{user?.fgrRate}% +{project?.userProjectMetrics?.fanLevelBonus}%
								bonus
							</strong>
						</div>

						{/* Impact Stats */}
						<YourImpactSection />

						{/* Learn More Link */}
						<SuperfanLearnMoreMessage
							handleSuperfanActionsClick={handleSuperfanActionsClick}
						/>
					</div>
				) : (
					<SuperfanLearnMoreMessage
						handleSuperfanActionsClick={handleSuperfanActionsClick}
					/>
				)}
			</div>

			{isLeaderboardOpen && <SuperfanLeaderboardList {...{ projectId }} />}

			<div className="flex items-center justify-center gap-4">
				<SuperfanLeaderboard projectId={projectId} />
				<SuperfanBenefits projectId={projectId} isOnPerksProgram={project?.isOnPerksProgram} />
			</div>
		</div>
	)
}
