import { Grid, Button, Typography, Icon } from '@mui/material'
import useProjectDetail from '@/components/projectDetail/useProjectDetail'
import styles from './StatusMessages.module.scss'

const StatusMessages = () => {
	const { handleCancelApproval, project } = useProjectDetail()

	const isWaitingApproval = project.stageId === 2
	const isApproved = project.stageId === 3
	const isDisputed = project.stageId === 5

	return (
		<Grid item container className={styles.banner} xs={12} md={12}>
			<div className={styles.bannerMessage}>
				{isWaitingApproval && (
					<>
						<h3>
							<Icon baseClassName="fas" className="fa-regular fa-clock" />{' '}
							Project Approval
						</h3>
						<Typography>
							You made it! Your project is being reviewed by the{' '}
							<strong>Global Creativity Foundation</strong>. We’ll reach out to
							the email you used for this entry should we have any questions
							about your submission. Once your project is approved, you will see
							the status change here.
						</Typography>
						<Button
							id="shareButton"
							onClick={handleCancelApproval}
							startIcon={<Icon baseClassName="fal" className="fa-ban" />}
						>
							Cancel Approval
						</Button>
					</>
				)}
				{isApproved && (
					<>
						<h3>
							<Icon baseClassName="fas" className="fa-solid fa-thumbs-up" />{' '}
							Project Approval: Accepted!
						</h3>
						<Typography>
							Congratulations! Your project has been approved by the{' '}
							<strong>Global Creativity Foundation</strong>. You are in the
							queue to be published live on the platform, there is nothing left
							for you to do at this time!
						</Typography>
					</>
				)}
				{isDisputed && (
					<>
						<h3>
							<Icon
								baseClassName="fas"
								className="fa-solid fa-triangle-exclamation"
							/>{' '}
							Dispute in Progress
						</h3>
						<Typography>
							A dispute has been lodged with your project. The Filmio team is
							investigating the issue and will be in touch soon.
						</Typography>
					</>
				)}
			</div>
		</Grid>
	)
}

export default StatusMessages
