import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { useDispatch } from 'react-redux'
import { openBecomeSuperfanDialog } from '../header/signInSlice'
import { Film, Star, ThumbsUp } from 'lucide-react'
import useCommon from '@/hooks/useCommon'
import { SuperfanBenefitsDialog } from '@/components/project/projectSuperfan/superfanBenefits/superfanBenefitsDialog/SuperfanBenefitsDialog'
import { PerkProgramDialog } from '@/components/projectDetail/dashboardTab/superfan/perkProgramDialog'
import { cn } from '@/lib/utils'
import packageClosedWhite from '@/assets/images/package-closed-white.svg'

const benefits = [
	{
		icon: Film,
		title: 'Filmio Superfan Film Credit',
		description:
			"Get your name in the film's credits* as well as a superfan digital collectible"
	},
	{
		icon: Star,
		title: 'Early Access',
		description: 'Get sneak peeks and updates from your favorite creators.'
	},
	{
		icon: ThumbsUp,
		title: 'Influence the project',
		description:
			'Help greenlight new films and have a say in certain key elements.'
	}
]

const benefitTitleClasses =
	'mt-0 mb-1 font-inter text-base font-semibold leading-normal text-white'
const benefitDescriptionClasses =
	'font-inter text-sm font-medium leading-5 text-white'

export const ProjectNoSuperfan = ({
	className,
	title: projectTitle,
	id: projectId,
	isOnPerksProgram
}: {
	className?: string
	title: string
	id: string
	isOnPerksProgram: boolean
}) => {
	const dispatch = useDispatch()
	const { showLoginIfNotAuthenticated } = useCommon()
	const [benefitsIsOpen, setBenefitsIsOpen] = useState(false)

	const handleBecomeSuperfan = () => {
		if (showLoginIfNotAuthenticated()) {
			return
		}
		dispatch(
			openBecomeSuperfanDialog({
				projectTitle,
				projectId,
				isOnPerksProgram
			})
		)
	}

	const handleShowBenefits = async () => {
		setBenefitsIsOpen(true)
	}
	
	const showModal = async () => {
		setBenefitsIsOpen(true)
	}

	return (
		<>
			<div
				className={cn(
					'flex h-auto flex-col items-center justify-center gap-8 rounded-lg border border-[#1b568c] bg-black/75 p-6 px-[52px] py-[90px] text-base backdrop-blur-sm',
					className
				)}
			>
				<Button
					onClick={handleBecomeSuperfan}
					className="flex h-[76px] w-[339px] items-center justify-center gap-2 rounded-md bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] px-3 py-2 font-inter text-[22px] font-semibold text-[#FAFAFA]"
				>
					Become a Superfan!
				</Button>

				<div className="flex flex-col gap-6">
					<>
						{isOnPerksProgram ? (
							<Button
								className="m-0 bg-transparent text-center font-inter text-[20px] font-semibold leading-normal text-[#2196F3] hover:bg-transparent hover:text-white"
								onClick={handleShowBenefits}
							>
								View Superfan Perks
							</Button>
						) : (
							<>
								<div className="max-w-[339px]">
									<Button
										variant="secondary"
										className="flex h-8 justify-center gap-2 bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] px-3 text-sm font-bold leading-5 text-[#ffffff] grayscale-[1]"
										onClick={showModal}
									>
										<img
											src={packageClosedWhite.src}
											alt="Superfan Perks"
											className="h-4 w-4 opacity-25"
										/>
										<span className="hidden opacity-25 md:block">Superfan</span>{' '}
										<span className="opacity-25">Perks</span>
									</Button>
								</div>
							</>
						)}

						{isOnPerksProgram && (
							<div className="flex flex-col gap-4">
								{benefits.map(benefit => (
									<div key={benefit.title} className="flex gap-3">
										<benefit.icon className="mt-1 h-5 w-5 shrink-0" />
										<div className="flex flex-col">
											<h3 className={benefitTitleClasses}>{benefit.title}</h3>
											<p className={benefitDescriptionClasses}>
												{benefit.description}
											</p>
										</div>
									</div>
								))}
							</div>
						)}
					</>
				</div>
			</div>

			{benefitsIsOpen &&
				(isOnPerksProgram ? (
					<SuperfanBenefitsDialog
						open={benefitsIsOpen}
						onOpenChange={open => setBenefitsIsOpen(open)}
						projectId={projectId}
					/>
				) : (
					<PerkProgramDialog
						{...{
							isOnPerksProgram: isOnPerksProgram,
							projectName: projectTitle,
							open: benefitsIsOpen,
							handleClose: () => {
								setBenefitsIsOpen(false)
							}
						}}
					/>
				))}
		</>
	)
}
