import { Button, Grid, Icon, Typography, Box } from '@mui/material'
import { TAB_MAP } from '../tabsSection'
import { SocialMediaShareMenu } from './socialMediaShareMenu'
import { RecentActivity } from './recentActivity'
import { Stages } from './stages'
import { GoScoreBadges } from './goScoreBadges'
import { Medias } from './medias'
import { ImageWithViewer } from '@/components/common'
import { TrailerDialog } from './trailerDialog'
import { GoScoreCircle } from './goScoreCircle'
import { Superfan } from './superfan'
import styles from './DashboardTab.module.scss'
import useDashboardTab from './useDashboardTab'
import { ReviewRating } from '../reviewsTab/reviewRating'

const DashboardTab = () => {
	const {
		isDraft,
		project,
		userReview,
		storyRef,
		showMore,
		trailerInfo,
		storyHeight,
		disableShareLink,
		disabledSocialBtns,
		buildCategoriesLine,
		handleSocialClick,
		showMoreToggle
	} = useDashboardTab()

	const {
		type,
		title,
		story,
		script,
		poster,
		logline,
		lookbook,
		coverImg,
		treatment,
		thumbnail,
		goScoreStage,
		decayStatus
	} = project

	return (
		<Grid container spacing={'2'} sx={{ marginBottom: '80px' }}>
			<Grid item container md={12} className={styles.header_title}>
				<Grid item md={6}>
					<Typography className={styles.greenlightHeader}>
						Help Greenlight {title}{' '}
					</Typography>
				</Grid>
				<Grid item md={6}>
					<Typography className={styles.impactHeader}>
						Your Impact on this Project{' '}
					</Typography>
				</Grid>
			</Grid>
			<Grid item md={12} className={styles.header_outside}>
				<Grid item container md={12} className={styles.header}>
					<Grid item md={6} id="socialsBox" className={styles.socials}>
						<Box className={styles.top}>
							<Box className={disabledSocialBtns ? styles.disabled : ''}>
								<Button
									id="stakeButton"
									startIcon={
										<Icon baseClassName="fa-kit" className="fa-fan-stake" />
									}
									disabled={disabledSocialBtns}
									onClick={handleSocialClick(TAB_MAP.stakes)}
								>
									Stake
								</Button>
							</Box>
							<Box className={disabledSocialBtns ? styles.disabled : ''}>
								{userReview ? (
									<Button
										className={styles.overallButton}
										id="reviewButton"
										onClick={handleSocialClick(TAB_MAP.reviews)}
										disabled={disabledSocialBtns}
									>
										<Box>
											Your Rating
											<Box className={styles.ratingBox}>
												<ReviewRating
													{...{
														rating: userReview?.rating ?? 0,
														readOnly: true,
														className: styles.small_font
													}}
												/>
											</Box>
										</Box>
									</Button>
								) : (
									<Button
										id="reviewButton"
										startIcon={<Icon baseClassName="fal" className="fa-star" />}
										onClick={handleSocialClick(TAB_MAP.reviews)}
										disabled={disabledSocialBtns}
									>
										Review
									</Button>
								)}
							</Box>
							{disableShareLink ? (
								<Box className={styles.disabled}>
									<Button
										id="shareButton"
										startIcon={
											<Icon baseClassName="fal" className="fa-share" />
										}
									>
										Share
									</Button>
									<Icon
										baseClassName="fas"
										className={`fa-lock ${styles.lock}`}
									/>
								</Box>
							) : (
								<SocialMediaShareMenu
									{...{ isDraft }}
									className={disabledSocialBtns ? styles.disabled : ''}
								/>
							)}
							<Box className={styles.disabled}>
								<Button
									id="donateButton"
									startIcon={
										<Icon
											baseClassName="fal"
											className="fa-circle-dollar-to-slot"
										/>
									}
								>
									Donate
								</Button>
								<Icon
									baseClassName="fas"
									className={`fa-lock ${styles.lock}`}
								/>
							</Box>
						</Box>
						<Box>
							<Typography>
								<Icon baseClassName="fal" className="fa-circle-info" />
								By staking, reviewing and sharing {title}, you are helping to
								Greenlight this project.
							</Typography>
						</Box>
					</Grid>
					<Superfan />
				</Grid>
			</Grid>

			<Grid item container md={12}>
				<Grid item md={4}>
					<Typography>The Go Score Journey</Typography>
					<GoScoreCircle
						{...{
							clickable: true,
							decayStatus
						}}
					/>
				</Grid>
				<Grid item container md={1}></Grid>
				<Grid item container md={7}>
					<Stages />
				</Grid>
			</Grid>

			<Grid item container md={12} spacing={4}>
				<Grid item container md={6}>
					<Grid item md={12} className={styles.projectinfo}>
						<h5>About the Project</h5>
						<ul className={styles.categories}>
							<li
								key="goscore"
								style={{ backgroundColor: goScoreStage?.color }}
							>
								{goScoreStage?.name}
							</li>
							{type && <li key="type">{type.name}</li>}
							{buildCategoriesLine()}
						</ul>

						<Box className={styles.media}>
							<Typography component={'h3'}>Media</Typography>
							<Medias {...{ items: [poster, coverImg, thumbnail] }}>
								{trailerInfo?.thumbnail_url && (
									<TrailerDialog
										{...{
											thumbnail: trailerInfo?.thumbnail_url,
											trailerSrc: trailerInfo?.player_embed_url
										}}
									/>
								)}
								{poster && <ImageWithViewer src={poster} />}
								{coverImg && <ImageWithViewer src={coverImg} />}
								{thumbnail && <ImageWithViewer src={thumbnail} />}
							</Medias>
						</Box>
						<Box className={styles.documents}>
							<Typography component={'h3'}>{'Files & Attachments'}</Typography>
							<Box>
								{lookbook && (
									<a href={lookbook} target="_blank" rel="noreferrer">
										<Icon baseClassName="fal" className="fa-file-pdf" />
										Lookbook
									</a>
								)}
								{treatment && false && (
									<a href={treatment} target="_blank" rel="noreferrer">
										<Icon baseClassName="fal" className="fa-file-pdf" />
										Treatment
									</a>
								)}
								{script && false && (
									<a href={script} target="_blank" rel="noreferrer" disabled>
										<Icon baseClassName="fal" className="fa-file-pdf" />
										Script
									</a>
								)}
							</Box>
						</Box>

						<Box className={styles.about}>
							<Typography component={'h3'}>Logline</Typography>
							<Typography>{logline}</Typography>
						</Box>
						<Box
							className={
								showMore ? styles.about : `${styles.about} ${styles.showMore}`
							}
						>
							<Box>
								<Typography component={'h3'}>Story</Typography>
							</Box>
							<Box
								ref={storyRef}
								dangerouslySetInnerHTML={{ __html: story }}
							></Box>
						</Box>
						{storyHeight > 252 && (
							<Box
								className={`${styles.show_about} ${
									showMore ? '' : styles.show_more
								}`}
							>
								<Button
									variant="outlined"
									size="small"
									onClick={showMoreToggle}
								>
									{showMore ? 'LESS' : 'MORE'}
								</Button>
							</Box>
						)}
					</Grid>
				</Grid>

				<Grid item container md={6}>
					<Grid item md={12} className={styles.badges_achievements}>
						<Grid item md={12} className={styles.badges}>
							<Typography component={'h3'}>
								{'Badges & Achievements'}
							</Typography>
							<GoScoreBadges />
						</Grid>
						<Grid item md={12} className={styles.recent_activity}>
							<Typography component={'h3'}>Recent Activity</Typography>
							<RecentActivity />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default DashboardTab
