import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import Persona from 'persona'
import { formatDate } from '../../../utils/date.utils'
import { InquiryOptions } from 'persona/dist/lib/interfaces'
import { updateSignInUser } from 'components/header/signInSlice'
import { completeKycService } from 'services/profile.service'
import { useDispatch } from 'react-redux'
import { calculateUserDaoTierProgress } from '../../../services/daoTier.service'
import { reloadDaoTiers } from '../../profile/profileSlice'
import useAxiosFetch from '../../../hooks/useAxiosFetch'
import useLoadingMask from '../../../hooks/useLoadingMask'
import useAxios from '../../../hooks/useAxios'
import useHeader from '../../header/useHeader'
import useNotification from '../../../hooks/useNotification'

const kycTemplateId = process.env.NEXT_PUBLIC_KYC_TEMPLATE_ID
const kycEnv = process.env.NEXT_PUBLIC_KYC_ENVIRONMENT

export const useKyc = () => {
	const { user } = useSelector(state => state.signIn)
	const personaKycRef = useRef<any>(null)
	const dispatch = useDispatch()
	const [, setTierProgress] = useState(null)
	const { showNotification } = useNotification()
	const { loadUserTierInfo } = useHeader()
	const { axiosService } = useAxios()
	const { showMask: showLoading, hideMask } = useLoadingMask()

	const [, refreshProgress] = useAxiosFetch(
		calculateUserDaoTierProgress(),
		(data, error) => {
			if (!error) {
				setTierProgress(data.progress)
			}
		}
	)

	const onKycComplete: InquiryOptions['onComplete'] = ({ inquiryId }) => {
		showLoading()
		axiosService(completeKycService({ inquiryId }), (data, error) => {
			if (error) {
				showNotification({ message: 'Verification failed!' })
				hideMask()
				return
			}
			dispatch(updateSignInUser({ isVerified: data.isVerified }))
			showNotification({ message: 'Verification completed successfully!' })
			refreshProgress()
			loadUserTierInfo()
			dispatch(reloadDaoTiers())
			hideMask()
		})
	}

	const cleanupPersona = () => {
		const personaKyc = personaKycRef.current
		if (!personaKyc) {
			return
		}
		personaKyc.destroy()
		personaKycRef.current = null
	}

	const showKyc = () => {
		let personaClient = personaKycRef.current
		if (!personaClient) {
			personaClient = new Persona.Client({
				templateId: kycTemplateId,
				referenceId: user.id,
				environmentId: kycEnv,
				onComplete: params => {
					onKycComplete(params)
					cleanupPersona()
				},
				fields: {
					nameFirst: user?.firstName,
					nameLast: user?.lastName,
					birthdate: formatDate(user.birthday, 'finputDate'),
					addressCountryCode: user.countryId,
					emailAddress: user.email
				}
			})
			personaKycRef.current = personaClient
		}

		personaClient.open()
	}

	useEffect(() => {
		return cleanupPersona
	}, [])

	return {
		showKyc
	}
}
