import _ from 'lodash'
import {
	Box,
	Table,
	TableBody,
	TableCell,
	Typography,
	TableContainer,
	TableRow,
	Button,
	Slider,
	Icon
} from '@mui/material'
import { formatDate, humanizeDays } from 'utils/date.utils'
import useRewardUnlockProgression from './useRewardUnlockProgression'
import { NumericFormat } from 'react-number-format'
import {
	abbreviateNumberFixed,
	formatWithLeadingZeros,
	getObjectFromLocalStorage,
	NumberWithDecimals
} from 'utils/utils'
import { TextFieldControl } from '@/components/common'
import styles from './RewardUnlockProgression.module.scss'

export default function RewardUnlockProgression() {
	const {
		data,
		overview,
		gemAmount,
		isVesting,
		speedUpInfo,
		loadingProgression,
		handleSpeedUpChange,
		handleSpeedUp
	} = useRewardUnlockProgression()
	const lockedRewards = overview?.earningsTotal - overview?.unlockedRewards
	const gemMax = Math.min(overview?.gemsTotalToUnlock, overview?.gemTotal)
	const valueLabelFormat = value => `${value} Gems`
	const speedUpDisabled = gemAmount === 0
	const speedUpText = speedUpDisabled
		? 'Speed Up'
		: `Spend ${gemAmount} Gems to Speed Up`
	const { fgrClaimed } = getObjectFromLocalStorage(['fgrClaimed'])

	const renderRows = (data = []) => {
		return data.map(row => {
			const { dailyRelease, date, day } = row
			const decimalDigits = dailyRelease < 0.01 ? 4 : 2
			return (
				<TableRow key={`day-${day}`}>
					<TableCell align="left" width="55%" className={styles.join_date}>
						{dailyRelease > 0.0001
							? abbreviateNumberFixed(
									dailyRelease,
									decimalDigits,
									decimalDigits
							  )
							: formatWithLeadingZeros(dailyRelease)}{' '}
						$FAN
					</TableCell>
					<TableCell align="left" width="45%" className={styles.join_date}>
						{formatDate(date, 'inputDate')}
					</TableCell>
				</TableRow>
			)
		})
	}

	return (
		<Box
			className={`${styles.reward_progression} ${
				isVesting ? styles.is_vesting : ''
			}`}
		>
			{loadingProgression && (
				<Box className={styles.reward_progression_mask}>
					<Box>
						Processing{' '}
						<span>
							<i className="fa-solid fa-spin fa-loader"></i>
						</span>
					</Box>
				</Box>
			)}
			<Box className={styles.header}>
				<Box className={styles.title}>
					<Typography>
						<b>Rewards Release Schedule</b>
					</Typography>
					<Typography>
						Gems: <b>{overview?.gemTotal}</b>
					</Typography>
				</Box>
				{fgrClaimed === 1 && (
					<Typography className={styles.fgr_change}>
						You recently claimed FGR, which has changed your release schedule.{' '}
						<br />
						<b>Your updated schedule is below.</b>
					</Typography>
				)}
				<Box>
					<Box className={styles.gem_slider}>
						<Box className={styles.input_container}>
							<TextFieldControl
								value={gemAmount}
								onChange={e => {
									handleSpeedUpChange(e.target.value)
								}}
								type="number"
							/>
							<Button
								onClick={() => {
									handleSpeedUpChange(gemMax)
								}}
								startIcon={<Icon baseClassName="fas" className="fa-gem" />}
							>
								MAX
							</Button>
						</Box>

						<Slider
							size="small"
							value={gemAmount}
							aria-label="Small"
							valueLabelDisplay="auto"
							min={0}
							max={gemMax}
							step={1}
							getAriaValueText={valueLabelFormat}
							valueLabelFormat={valueLabelFormat}
							onChange={e => {
								handleSpeedUpChange(e.target.value)
							}}
						/>
					</Box>
					{gemAmount === 0 ? (
						<Typography className={styles.info}>
							You currently have{' '}
							<b>
								{
									<NumericFormat
										value={NumberWithDecimals(lockedRewards, 2)}
										displayType="text"
										thousandSeparator=","
									/>
								}{' '}
								$FAN
							</b>{' '}
							to unlock. Spending gems will speed this process up!
						</Typography>
					) : (
						<Typography className={styles.info}>
							<b>
								{
									<NumericFormat
										value={NumberWithDecimals(gemAmount, 2)}
										displayType="text"
										thousandSeparator=","
									/>
								}{' '}
								Gems
							</b>{' '}
							will remove {humanizeDays(speedUpInfo?.days)} from your release
							schedule, and immediately unlock{' '}
							{NumberWithDecimals(speedUpInfo?.rewardsToUnlock, 2)} $FAN
						</Typography>
					)}
				</Box>
			</Box>
			{_.isEmpty(data) ? (
				<Typography className={styles.emptyMessage}>
					You don’t have any rewards tokens. Earn rewards tokens to activate the
					Rewards Release Schedule.
				</Typography>
			) : (
				<>
					<Box className={styles.table_container}>
						<Box className={styles.table_header}>
							<Typography>
								{'Tokens released to date: '}
								<NumericFormat
									value={NumberWithDecimals(overview?.unlockedRewards, 2)}
									displayType="text"
									thousandSeparator=","
								/>
							</Typography>
							<Typography>Upcoming releases</Typography>
						</Box>
						<TableContainer>
							<Table stickyHeader>
								<TableBody>{renderRows(data)}</TableBody>
							</Table>
						</TableContainer>
					</Box>
					<Box className={styles.speed_up}>
						<Button disabled={speedUpDisabled} onClick={handleSpeedUp}>
							{speedUpText}
						</Button>
					</Box>
				</>
			)}
		</Box>
	)
}
