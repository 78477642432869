import axios from 'axios'

export const addCommentReaction = async (
	reactionId: string,
	feedId: string
) => {
	return await axios.post('/reaction/comment', {
		reactionId,
		commentId: feedId
	})
}

export const addReactionService = async (
	reactionId: string,
	feedId: string,
	type: string
) => {
	return await axios.post(`/reaction/${type}`, {
		reactionId,
		[`${type}Id`]: feedId
	})
}

export const getReactions = async () => {
	return await axios.get('/reaction')
}
