import { useState } from 'react'
import { Star } from 'lucide-react'
import { Card, CardContent } from '@/components/ui/card'
import { useCommentHandler } from '../hooks/useCommentHandler'
import { FeedItem } from '../types'
import { CommentInput } from './shared/CommentInput'
import { InteractionsBar } from './shared/InteractionsBar'
import { UserAvatar } from './shared/UserAvatar'
import { UserInfo } from './shared/UserInfo'
import { Comment } from './shared/Comment'

export const ReviewItem = ({
	item
}: {
	item: FeedItem & { type: 'review' }
}) => {
	const {
		id,
		user,
		createdAt,
		project,
		heading,
		content,
		rating,
		comments,
		reactions
	} = item.record

	const [itemComments, updateComments] = useState(comments)
	const [itemReactions, setItemReactions] = useState(reactions)
	const [expandedComments, setExpandedComments] = useState(false)
	const { comment, handleComment, handleSubmitComment, clearComment } =
		useCommentHandler(item.record.id, 'review', comment => {
			updateComments(prev => [comment, ...prev])
		})

	const renderRatingStars = (rating: number) => (
		<div className="flex items-center">
			{Array.from({ length: 5 }).map((_, index) => (
				<Star
					key={index}
					className={`h-5 w-5 ${
						index < rating ? 'text-yellow-400' : 'text-gray-400'
					}`}
				/>
			))}
		</div>
	)

	return (
		<Card className="border-none bg-transparent p-0">
			<CardContent className="p-0">
				<div className="flex flex-col items-start gap-3 overflow-hidden rounded-md bg-[#2196F3]/15 p-4">
					<div className="flex items-start gap-3">
						<UserAvatar user={user} />
						<UserInfo user={user} createdAt={createdAt} project={project} />
					</div>
					<div className="w-full">
						{renderRatingStars(rating)}
						<h3 className="mt-2 text-lg font-semibold text-white">{heading}</h3>
						<p className="mt-1 text-white">{content}</p>
						<InteractionsBar
							id={id}
							type={item.type}
							hasComments
							commentsCount={itemComments.length}
							reactions={itemReactions}
							setReactions={setItemReactions}
							onCommentClick={() => setExpandedComments(prev => !prev)}
						/>
					</div>
					<CommentInput
						value={comment}
						onChange={handleComment}
						onSubmit={handleSubmitComment}
						onClear={clearComment}
					/>
					{expandedComments && (
						<div className="ml-2 w-full divide-y">
							{itemComments.map(comment => (
								<Comment key={comment.id} comment={comment} />
							))}
						</div>
					)}
				</div>
			</CardContent>
		</Card>
	)
}
