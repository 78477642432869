export const getDashboardService = (data: any, autoexec = false) => ({
	method: 'post',
	url: '/dashboard',
	data,
	autoexec
})

export const getGlobalSearchService = (data: any) => ({
	method: 'post',
	url: '/dashboard/search',
	data,
	autoexec: false
})

export const getSharerNameService = (data: any) => ({
	method: 'post',
	url: '/dashboard/sharerName',
	data,
	autoexec: false
})

export const getExploreInfoService = (params: any) => ({
	method: 'get',
	url: '/dashboard/explore',
	autoexec: true,
	params
})

export const getDashboardTilesService = () => ({
	method: 'get',
	url: '/dashboard/tiles',
	autoexec: true
})

export const getCheckoutProjectsService = (params: any) => ({
	method: 'get',
	url: '/dashboard/checkout',
	autoexec: true,
	params
})

export const getStatsService = () => ({
	method: 'get',
	url: '/dashboard/stats',
	autoexec: true
})

export const getDashboardQuestsService = () => ({
	method: 'get',
	url: '/dashboard/quests/ongoing',
	autoexec: true
})
