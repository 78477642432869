import { openBecomeSuperfanDialog } from '../../../header/signInSlice'
import { Button } from '../../../ui/button'
import { useDispatch } from 'react-redux'
import useCommon from '../../../../hooks/useCommon'
import packageOpenWhite from '@/assets/images/package-open-white.svg'
import packageClosedWhite from '@/assets/images/package-closed-white.svg'
import { SuperfanBenefitsDialog } from '@/components/project/projectSuperfan/superfanBenefits/superfanBenefitsDialog/SuperfanBenefitsDialog'
import { PerkProgramDialog } from '@/components/projectDetail/dashboardTab/superfan/perkProgramDialog'
import { useState } from 'react'

interface Props {
	projectId: string
	projectTitle: string
	isOnPerksProgram: boolean
}

export const ProjectNoSuperfan = ({
	projectId,
	projectTitle,
	isOnPerksProgram
}: Props) => {
	const dispatch = useDispatch()
	const [benefitsIsOpen, setBenefitsIsOpen] = useState(false)
	const { showLoginIfNotAuthenticated } = useCommon()

	const handleBecomeSuperfan = () => {
		if (showLoginIfNotAuthenticated()) {
			return
		}
		dispatch(
			openBecomeSuperfanDialog({
				projectTitle,
				projectId,
				isOnPerksProgram
			})
		)
	}

	const handleShowBenefits = async () => {
		setBenefitsIsOpen(true)
	}

	return (
		<>
			<div className="m-auto flex justify-center gap-3 border-t border-[#234a76] p-4">
				<Button
					onClick={handleBecomeSuperfan}
					className="flex h-11 items-center justify-center rounded-md bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] px-[10%] py-2 text-xl font-semibold text-[#FAFAFA]"
				>
					Become a Superfan!
				</Button>
				{isOnPerksProgram ? (
					<Button
						variant="secondary"
						className="flex h-11 justify-center bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] text-sm font-bold leading-5 text-[#FAFAFA] hover:bg-opacity-70"
						onClick={handleShowBenefits}
					>
						<img
							src={packageOpenWhite.src}
							alt="Superfan Perks"
							className="h-7"
						/>
					</Button>
				) : (
					<Button
						variant="secondary"
						className="flex h-11 justify-center bg-[#18181B] text-sm font-bold leading-5 text-[#FAFAFA] hover:bg-opacity-70"
						onClick={handleShowBenefits}
					>
						<img
							src={packageClosedWhite.src}
							alt="Superfan Perks"
							className="h-7"
						/>
					</Button>
				)}
			</div>
			{benefitsIsOpen &&
				(isOnPerksProgram ? (
					<SuperfanBenefitsDialog
						open={benefitsIsOpen}
						onOpenChange={open => setBenefitsIsOpen(open)}
						projectId={projectId}
					/>
				) : (
					<PerkProgramDialog
						{...{
							isOnPerksProgram,
							projectName: projectTitle,
							open: benefitsIsOpen,
							handleClose: () => {
								setBenefitsIsOpen(false)
							}
						}}
					/>
				))}
		</>
	)
}
