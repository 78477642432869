import { useEffect } from 'react'

export const useScrollToTop = (
	options: {
		smooth?: boolean
		delay?: number
	} = {}
) => {
	const { smooth = false, delay = 0 } = options

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			// Try scrolling the main content element instead of window
			const scrollElement = document.getElementById('scroll-provider')
			if (scrollElement) {
				scrollElement.scrollTo({
					top: 0,
					behavior: smooth ? 'smooth' : 'auto'
				})
			}

			// Also scroll the window as fallback
			window.scrollTo({
				top: 0,
				behavior: smooth ? 'smooth' : 'auto'
			})
		}, delay)

		return () => clearTimeout(timeoutId)
	}, [smooth, delay])
}
