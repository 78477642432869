import _ from 'lodash'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Container, Grid, Typography } from '@mui/material'
import useTokenSaleExperience from './useTokenSaleExperience'
import { PhaseNoStartedDialog } from './phaseNoStartedDialog'
import { ValidateIpDialog } from './validateIpDialog'
import { TransactionsTable } from './transactionsTable'
import { CustomCountdown } from '@/components/common/customCountdown'
import { CustomButton } from '@/components/common'
import { getTransactionLink } from 'utils/utils'
import { ClaimUserDialog } from './claimUserDialog'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import tokenBlue from 'assets/images/new_token_blue.svg'
import styles from './TokenSaleExperience.module.scss'
import './widgetOverrides.scss'

const TokenSaleExperience = () => {
	const {
		user,
		sale,
		userInfo,
		buyCompleted,
		configuration,
		saleTransactions,
		validateIpDialogOpen,
		unallowedLocationsOpen,
		phaseNoStartedDialogOpen,
		handleShowEmergencyNotification,
		handleOpenClaimUserDialog,
		handleShowNotification,
		saleUserAccessClaim,
		buyAgain,
		percentage
	} = useTokenSaleExperience()
	const { lastTransaction, total = 0 } = userInfo
	const baseCurrencyIcon = lastTransaction?.baseCurrency?.icon
	const isCompleted = _.toNumber(percentage) === 100
	const baseCurrencyId = lastTransaction?.baseCurrency?.id
	const iconBase = baseCurrencyId === 1 ? 'fa-brands' : 'fa-regular'
	const iconClass = `${iconBase} fa-${baseCurrencyIcon}`
	const isEmailVerified = user?.isEmailVerified === 1
	const isDaoTermsAgreed = user?.isDaoTermsAgreed === 1

	const accessAction =
		configuration?.saleFreeJoin === 1
			? handleShowEmergencyNotification
			: saleUserAccessClaim

	const verifyMessages = (
		<>
			{isDaoTermsAgreed && !isEmailVerified && (
				<Box className={styles.completeProfile}>
					<p>
						Please verify your email to complete account creation and wallet
						linking.
					</p>
					<p>
						{`Didn't receive an email?  You can go through the email verification process again `}
						<a onClick={handleOpenClaimUserDialog}>Here</a>.
					</p>
				</Box>
			)}
			{isEmailVerified && (
				<Box className={styles.completeProfile}>
					<p>
						Thank you for verifying your email {user?.email}. When it&rsquo;s
						ready, you will be able to access the main application by clicking
						the button below and providing your email/password.
					</p>
					{user?.appAccess === 0 ? (
						<p className={styles.inactiveLogin} onClick={accessAction}>
							Enter Early Access
						</p>
					) : (
						<Link to={'/explore'} className={styles.inactiveLogin}>
							Explore
						</Link>
					)}
				</Box>
			)}
		</>
	)

	return (
		<>
			{!phaseNoStartedDialogOpen &&
				!validateIpDialogOpen &&
				!unallowedLocationsOpen && (
					<Container
						disableGutters
						maxWidth="md"
						className={styles.token_sale_page}
					>
						<Grid container className={styles.top_detail_list} spacing={0}>
							{isCompleted ? (
								<>
									<Box className={styles.saleClosed}>
										<h3>Pre-Sale SOLD OUT!</h3>
										<span>
											Thank you for participating in the Presale! We are
											processing all of the purchase data now, and will be
											delivering tokens to your Film.io platform wallets{' '}
											<strong>within 48 hours</strong> of the original close of
											the sale, <strong>Wednesday, November 15th, 2023</strong>.
										</span>
										{!isEmailVerified && !isDaoTermsAgreed && (
											<Typography className={styles.profile}>
												Now that you have purchased FAN Tokens,{' '}
												<a onClick={handleOpenClaimUserDialog}>Click Here</a> to
												complete your profile which enables your account to
												receive FAN Tokens, within 48 hours of the presale
												close. See the{' '}
												<strong>What&rsquo;s Next Section</strong> below for
												more info.
											</Typography>
										)}
										{verifyMessages}
									</Box>
								</>
							) : (
								<>
									{sale?.phase?.endDate ? (
										<>
											<CustomCountdown
												date={new Date(sale?.phase?.endDate)}
												message={'Price Increases In'}
												variant={'blue'}
											/>
										</>
									) : (
										<>
											<CustomCountdown
												date={new Date(sale?.saleEndDate)}
												message={'Price Increases In'}
												variant={'blue'}
											/>
										</>
									)}
								</>
							)}
							{!isCompleted && (
								<>
									<p className={styles.increaseCTA}>
										{!_.isNil(sale?.nextPhase) ? (
											<>
												🔥 Contribute now before the price increases to{' '}
												<strong>${sale?.nextPhase?.price}</strong> 🔥
											</>
										) : (
											<>🔥 Contribute now before the sale ends! 🔥</>
										)}
									</p>
									<Grid className={styles.bigProgress}>
										<Box className={styles.stageProgress}>
											<Box className={styles.progressContainer}>
												<span>{percentage}% of the target</span>
											</Box>
											<Box
												className={styles.progressMeter}
												style={{
													width: `${percentage}%`
												}}
											></Box>
										</Box>
									</Grid>
								</>
							)}
							<Grid className={styles.preSaleWidget} item xs={12} md={5}>
								{isCompleted ? (
									<Box className={styles.shadow}>
										<TransactionsTable />
									</Box>
								) : (
									<>
										{buyCompleted ? (
											<Box className={styles.last_transaction_info} key="info">
												<Typography>
													<span>
														Your transaction is complete! Here are the details:
													</span>
												</Typography>
												<Box className={styles.txnCard}>
													<Typography>
														<span>TXN:</span>{' '}
														{getTransactionLink(
															lastTransaction?.blockchainHash
														)}
													</Typography>
													<Typography>
														<span>Purchase Amount:</span>
														<i className={iconClass}></i>
														{lastTransaction?.baseCurrencyAmount}
													</Typography>
													<Typography>
														<span>Total FAN Tokens Purchased:</span>
														<img alt="" src={tokenBlue.src} />
														{total}
													</Typography>
												</Box>
											</Box>
										) : (
											<Box
												id="token-sale-widget-root"
												className={styles.tokenWidget}
												key="widget"
											></Box>
										)}
										{saleTransactions.length > 0 &&
											!isEmailVerified &&
											!isDaoTermsAgreed && (
												<Box className={styles.completeProfile}>
													<Typography>
														Now that you have purchased FAN Tokens,{' '}
														<a onClick={handleOpenClaimUserDialog}>
															Click Here
														</a>{' '}
														to complete your profile which enables your account
														to receive FAN Tokens, within 48 hours of the
														presale close. See the{' '}
														<strong>What&rsquo;s Next Section</strong> to the
														right for more info.
													</Typography>
												</Box>
											)}
										{verifyMessages}
										{buyCompleted && (
											<CustomButton
												customStyle={'outlined'}
												className={styles.custom_btn}
												onClick={buyAgain}
											>
												Buy Again
											</CustomButton>
										)}
									</>
								)}
							</Grid>
							<Grid className={styles.history} item xs={12} md={7}>
								<Box className={styles.recap}>
									<Box className={styles.shadow}>
										{!isCompleted ? (
											<TransactionsTable />
										) : (
											<>
												<Typography className={styles.ended} component={'h2'}>
													This sale has ended
												</Typography>
												<Box className={styles.nextSteps}>
													<Typography>
														Thank you for participating! You have purchased{' '}
														{total} FAN Tokens.
													</Typography>
												</Box>
											</>
										)}
										<Typography component={'h2'}>Whats next?</Typography>
										<Box className={styles.nextSteps}>
											<Typography>
												Tokens purchased are governed by our{' '}
												<strong>Release Schedule:</strong>
											</Typography>
											<ul>
												<li>
													<strong>Initial Release:</strong> 1/10 of your total
													available in your platform wallet within 48 hours of
													the finalization of presale data.
												</li>
												<li>
													<strong>Release Schedule:</strong> 10 months
												</li>
												<li>
													<strong>Release Begins:</strong> Upon listing of FAN
													Token on an exchange.
												</li>
											</ul>
											<Typography>
												Once the presale ends, your initial deposit of tokens
												will be transferred to your Film.io{' '}
												<a
													onClick={handleShowNotification(
														'wallet',
														styles.message_modal
													)}
												>
													Platform Wallet
												</a>
												. You will be able to use those tokens within the
												platform to stake on projects and{' '}
												<a
													onClick={handleShowNotification(
														'fgr',
														styles.message_modal
													)}
												>
													Earn FGR
												</a>
												.
											</Typography>
										</Box>
									</Box>
								</Box>
							</Grid>
						</Grid>
						<Grid container spacing={0}>
							<Box className={[styles.recap, styles.rounded]}>
								<TokenSaleFaqs />
							</Box>
						</Grid>
					</Container>
				)}
			{validateIpDialogOpen && <ValidateIpDialog />}
			<PhaseNoStartedDialog />
			<ClaimUserDialog />
		</>
	)
}

const TokenSaleFaqs = () => {
	const [expanded, setExpanded] = useState(false)

	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false)
	}

	return (
		<>
			<Typography component="h1">Pre Sale FAQs</Typography>
			<div className={styles.accordian}>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel1'}
					onChange={handleChange('panel1')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							How do I access the Film.io pre-sale?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							To access the Film.io pre-sale, you must utilize the unique URL
							you received from BCW Group, which serves as your exclusive entry
							key. It&#39;s important to use this link, as it&#39;s directly
							tied to your wallet for the purchase process and ensures
							you&#39;re recognized as an authorized participant. If you have
							any difficulties or require further guidance, Film.io support is
							here to assist you.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel2'}
					onChange={handleChange('panel2')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2bh-content"
						id="panel2bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							Why haven&#39;t I received my private invite URL yet?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							The Film.io Private Sale is being hosted by BCW Group and they
							would have all the relevant information on how your private codes
							are passed out.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel3'}
					onChange={handleChange('panel3')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3bh-content"
						id="panel3bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							Can I participate in Phase 2 or 3 if I have an invite for Phase 1?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							Absolutely! If you have an invite for Phase 1, you’re more than
							welcome to participate in Phase 2 or 3 as well. Your early access
							is your all-access pass to later phases, so feel free to join us
							at any stage!
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel4'}
					onChange={handleChange('panel4')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel4bh-content"
						id="panel4bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							I have an invite for Phase 3; can I get earlier access?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							Having an invite for Phase 3 doesn&#39;t automatically grant
							earlier access to the token sale phases due to the way the sale is
							technologically structured. Please reach out to BCW, and they’ll
							explore other potential options for you.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel5'}
					onChange={handleChange('panel5')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5bh-content"
						id="panel5bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							The phase countdown timer has finished, but I can&#39;t access the
							sale. What&#39;s wrong?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							It sounds like there might be a small hiccup! Here’s what you can
							do:
						</Typography>
						<ul>
							<li>
								Refresh your browser page to make sure you&#39;re seeing the
								most current information.
							</li>
							<li>
								Clear your browser cache as sometimes outdated data can cause
								display issues.
							</li>
							<li>
								If the problem persists, don&#39;t worry, we&#39;re here to
								assist. Reach out to our{' '}
								<a
									target="_blank"
									href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
									rel="noreferrer"
								>
									support team
								</a>
								, and we&#39;ll work swiftly to get you access to the sale.
							</li>
						</ul>
						<Typography>
							We appreciate your patience and are here to ensure your experience
							is smooth!
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel6'}
					onChange={handleChange('panel6')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel6bh-content"
						id="panel6bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							I was invited to Phase 1, but I can see a countdown timer. Is this
							an error?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							If you&#39;re seeing a countdown timer and you&#39;re invited to
							Phase 1, it&#39;s just there to let you know when the action kicks
							off. When the timer reaches zero, you should be ready to join in.
							If it doesn&#39;t update right away, try refreshing your browser.
							If any issues remain, our{' '}
							<a
								target="_blank"
								href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
								rel="noreferrer"
							>
								support team
							</a>{' '}
							is on standby to help out!
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel7'}
					onChange={handleChange('panel7')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel7bh-content"
						id="panel5bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							Which wallets are compatible with Wallet Connect for this
							pre-sale?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							For this pre-sale, any wallets that are compatible with Wallet
							Connect on the Ethereum chain can be used. To find out if your
							wallet is compatible, you can visit the Wallet Connect explorer at{' '}
							<a
								target="_blank"
								href="https://walletconnect.com/explorer?type=wallet&chains=eip155%3A1"
								rel="noreferrer"
							>
								Wallet Connect Wallets
							</a>{' '}
							and check the list. Plus, during the wallet connection prompt in
							the sale process, you can also search for your wallet to ensure a
							smooth connection.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel8'}
					onChange={handleChange('panel8')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel8bh-content"
						id="panel5bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							I&#39;m having trouble connecting my wallet to the platform; can
							you help me troubleshoot?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							Here are a few steps you can follow to troubleshoot connecting
							your wallet to the platform:
						</Typography>
						<ol>
							<li>
								<b>Check Compatibility</b>: Make sure your wallet is compatible
								with Wallet Connect and supports the Ethereum chain.
							</li>
							<li>
								<b>Update Your Wallet</b>: Ensure that your wallet app is
								updated to the latest version.
							</li>
							<li>
								<b>Internet Connection</b>: Verify that your internet connection
								is stable.
							</li>
							<li>
								<b>Browser Refresh</b>: Sometimes a simple refresh of your
								browser can resolve the issue.
							</li>
							<li>
								<b>Clear Cache</b>: Clear your browser cache to remove any old
								data that might be causing conflicts.
							</li>
							<li>
								<b>WalletConnect Explorer</b>: Use the{' '}
								<a
									target="_blank"
									href="https://walletconnect.com/explorer?type=wallet&chains=eip155%3A1"
									rel="noreferrer"
								>
									Wallet Connect explorer
								</a>{' '}
								to confirm your wallet is supported.
							</li>
							<li>
								<b>Search During Prompt</b>: When attempting to connect, use the
								search function during the wallet connection prompt to locate
								your wallet.
							</li>
							<li>
								<b>Restart Device</b>: If all else fails, try restarting your
								device as this can resolve many technical issues.
							</li>
						</ol>
						<Typography>
							If you continue to experience trouble after following these steps,
							please reach out to our{' '}
							<a
								target="_blank"
								href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
								rel="noreferrer"
							>
								Wallet Connect Wallets
							</a>{' '}
							for further assistance. We&#39;re here to ensure your connection
							is successful!
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel9'}
					onChange={handleChange('panel9')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel9bh-content"
						id="panel5bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							What should I do if my wallet connection fails?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							If your wallet connection fails, here&#39;s what you can do to
							troubleshoot the issue:
						</Typography>
						<ul>
							<li>
								<b>Refresh and Retry</b>: Sometimes, a simple page refresh can
								solve the connection issue. After refreshing, attempt to connect
								your wallet again.
							</li>
							<li>
								<b>Check Wallet Compatibility</b>: Ensure your wallet is
								compatible with Wallet Connect and supports Ethereum
								transactions. You can check compatibility on the Wallet Connect
								website.
							</li>
							<li>
								<b>Update Your Wallet</b>: Make sure your wallet app is up to
								date. An outdated wallet app may have connection issues.
							</li>
							<li>
								<b>Stable Internet Connection</b>: Confirm you have a stable
								internet connection, as a poor connection can disrupt the
								process.
							</li>
							<li>
								<b>Clear Browser Cache</b>: Clearing your browser cache can
								solve conflicts that might be preventing the connection.
							</li>
							<li>
								<b>Enable Browser Permissions</b>: If you’re using a web wallet,
								ensure that it is permitted to interact with third-party
								websites in your browser settings.
							</li>
							<li>
								<b>Restart Your Wallet App</b>: If you&#39;re using a mobile
								wallet, try closing and reopening the app.
							</li>
							<li>
								<b>Search for Your Wallet</b>: During the wallet connection
								prompt, use the search function to find and select your wallet.
							</li>
						</ul>
						<Typography>
							If you&#39;ve tried these steps and still can&#39;t connect,
							don&#39;t hesitate to get in touch with our{' '}
							<a
								target="_blank"
								href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
								rel="noreferrer"
							>
								support team
							</a>{' '}
							for personalized assistance. We&#39;re here to help
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel10'}
					onChange={handleChange('panel10')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel10bh-content"
						id="panel5bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							I&#39;m having trouble accessing the sale, could my ad blocker be
							causing an issue?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							Yes, it&#39;s possible that an ad blocker could interfere with
							your access to the sale. Ad blockers can sometimes block scripts
							that are essential for the platform&#39;s operation. Here&#39;s
							what you can do:
						</Typography>
						<ul>
							<li>
								<b>Disable Ad Blocker</b>: Temporarily disable your ad blocker
								and refresh the page to see if this resolves the issue.
							</li>
							<li>
								<b>Whitelist Our Site</b>: Add our website to your ad blocker’s
								whitelist to allow all functionalities to work properly.
							</li>
							<li>
								<b>Browser Extensions</b>: Check for other browser extensions
								that may be causing conflicts and disable them if necessary.
							</li>
							<li>
								<b>Try a Different Browser</b>: If the issue persists, attempt
								to access the sale using a different web browser without any ad
								blockers installed.
							</li>
						</ul>
						<Typography>
							If you&#39;re still experiencing trouble after these steps, please
							reach out to our{' '}
							<a
								target="_blank"
								href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
								rel="noreferrer"
							>
								support team
							</a>{' '}
							for assistance. We&#39;re here to help!
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel11'}
					onChange={handleChange('panel11')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel11bh-content"
						id="panel5bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							Should I disable my ad blocker or any browser extensions to
							participate in the sale?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							Yes, we recommend disabling your ad blocker or any browser
							extensions that could potentially interfere with the sale
							platform&#39;s functionality. Here&#39;s what you can do for a
							smoother experience:
						</Typography>
						<ul>
							<li>
								<b>Disable Ad Blocker</b>: Turn off your ad blocker while using
								the platform to prevent it from blocking necessary scripts and
								content.
							</li>
							<li>
								<b>Browser Extensions</b>: Disable any other browser extensions
								that might conflict with the site’s operation.
							</li>
							<li>
								<b>Whitelist</b>: If you prefer not to disable your ad blocker
								entirely, consider adding our website to the whitelist within
								your ad blocker settings.
							</li>
							<li>
								<b>Different Browser</b>: If you&#39;re unsure which extension
								may be causing an issue, try using a different browser without
								any extensions enabled.
							</li>
						</ul>
						<Typography>
							Doing this can help ensure that all features of the sale platform
							work correctly and that you don&#39;t run into any access issues.
							If you need further assistance, our{' '}
							<a
								target="_blank"
								href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
								rel="noreferrer"
							>
								support team
							</a>{' '}
							is here.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel12'}
					onChange={handleChange('panel12')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5bh-content"
						id="panel12bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							How do I disable my ad blocker for Film.io&#39;s pre-sale site?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							To disable your ad blocker for the Film.io pre-sale site, you can
							follow these general steps, which may vary slightly depending on
							the browser and ad blocker you use:
						</Typography>
						<ol>
							<li>
								<b>Access Ad Blocker Settings</b>: Click on the ad blocker
								extension icon in your browser’s toolbar. This icon is typically
								located near the top-right corner of the browser window.
							</li>
							<li>
								<b>Pause or Disable</b>: Look for an option to pause or disable
								the ad blocker for the site you are on. This might say{' '}
								<b>Pause on this site</b>, <b>Disable on this site</b>, or
								something similar.
							</li>
							<li>
								<b>Whitelist Film.io</b>: If there is an option to add sites to
								a whitelist, you can enter the Film.io pre-sale site’s URL.
								There may be a button or link saying “Whitelist site,” “Add to
								whitelist,” “Allow on this site,” or a settings option where you
								can manage a list of sites.
							</li>
							<li>
								<b>Refresh Your Browser</b>: After you&#39;ve paused, disabled,
								or whitelisted the site, refresh your browser to allow the
								changes to take effect.
							</li>
							<li>
								<b>Re-Access the Sale</b>: Now try accessing the pre-sale again.
								With the ad blocker turned off for the site, you should be able
								to use all the functionality without issue.
							</li>
						</ol>
						<Typography>
							If you have any trouble with these steps or if you&#39;re not
							seeing the expected results, don’t hesitate to reach out to our{' '}
							<a
								target="_blank"
								href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
								rel="noreferrer"
							>
								support team
							</a>{' '}
							for further assistance.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel13'}
					onChange={handleChange('panel13')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel13bh-content"
						id="panel5bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							How do I return to the pre-sale if I&#39;ve closed my browser or
							my session has expired?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							If you&#39;ve closed your browser or your session has expired,
							returning to the Film.io pre-sale is simple. Follow these steps to
							relaunch your session:
						</Typography>
						<ol>
							<li>
								<b>Open Your Browser</b>: Launch the web browser you originally
								used to access the pre-sale.
							</li>
							<li>
								<b>Use Your Private Pre-Sale URL</b>: Go directly to the private
								pre-sale by using the unique URL provided to you. This ensures
								you&#39;re entering through the correct access point.
							</li>
							<li>
								<b>Connect Your Wallet Again</b>: Once on the pre-sale page,
								reconnect your wallet by selecting the <b>Connect Wallet</b>{' '}
								button. This is the same wallet you claimed with your unique URL
								and where your information is stored.
							</li>
							<li>
								<b>Resume Your Session</b>: After your wallet is connected, you
								should be able to pick up where you left off in the pre-sale
								process.
							</li>
							<li>
								<b>Refresh Your Browser</b>: If you&#39;re not seeing the latest
								information, refresh your browser page to update the content.
							</li>
							<li>
								<b>Troubleshooting</b>: Should any issues arise, such as
								connection problems or unexpected errors, clear your browser’s
								cache and cookies before trying again.
							</li>
						</ol>
						<Typography>
							And remember, the Film.io{' '}
							<a
								target="_blank"
								href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
								rel="noreferrer"
							>
								support team
							</a>{' '}
							is ready to assist you if you need any help throughout this
							process.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel14'}
					onChange={handleChange('panel14')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5bh-content"
						id="panel14bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							What steps should I take if I get disconnected or my page
							doesn&#39;t load when I try to come back to the pre-sale?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							If you get disconnected or encounter issues when trying to return
							to the Film.io pre-sale, here are some steps you can follow to
							troubleshoot the problem:
						</Typography>
						<ol>
							<li>
								<b>Check Your Internet Connection</b>: Ensure you have a stable
								internet connection, as intermittent connectivity might cause
								disconnection or loading issues.
							</li>
							<li>
								<b>Use Your Private Pre-Sale URL</b>: Always access the pre-sale
								using the unique URL that was provided to you. This ensures
								you&#39;re entering through the designated access point.
							</li>
							<li>
								<b>Connect Your Wallet</b>: If the page loads but your session
								isn&#39;t active, reconnect your wallet by clicking on the
								<b>Connect Wallet</b> button. This should restore your session
								and save your progress.
							</li>
							<li>
								<b>Clear Cache and Cookies</b>: Sometimes your browser can store
								outdated information. Clearing your cache and cookies can help
								refresh the data and potentially solve the loading issue.
							</li>
							<li>
								<b>Browser Compatibility</b>: Verify that you&#39;re using a
								compatible browser and that it&#39;s up to date. Outdated
								browsers may have trouble interacting with newer web
								technologies.
							</li>
							<li>
								<b>Disable Extensions</b>: Disable any unnecessary browser
								extensions, particularly ad blockers or script blockers, as they
								may interfere with the functionality of the pre-sale page.
							</li>
							<li>
								<b>Try a Different Browser or Device</b>: If the issue persists,
								try accessing the pre-sale from a different browser or another
								device altogether.
							</li>
							<li>
								<b>Restart Your Device</b>: Occasionally, a simple restart of
								your computer or mobile device can resolve underlying issues
								that might be causing the disconnection or loading problems.
							</li>
							<li>
								<b>Contact Support</b>: If you&#39;ve tried these steps and
								still can&#39;t access the pre-sale, reach out to Film.io{' '}
								<a
									target="_blank"
									href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
									rel="noreferrer"
								>
									support
								</a>{' '}
								for assistance. Be ready to provide details about the issue, any
								error messages you&#39;re seeing, and the steps you&#39;ve
								already taken to try to resolve it.
							</li>
						</ol>
						<Typography>
							The Film.io team is dedicated to providing a seamless experience,
							and they&#39;re there to help ensure you can participate in the
							pre-sale without any further hiccups.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel15'}
					onChange={handleChange('panel15')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5bh-content"
						id="panel15bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							Will my place in the pre-sale queue be saved if I leave the site
							or have to refresh my page?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							Yes, your place in the pre-sale queue will be preserved if you
							leave the site or need to refresh your page, thanks to the unique
							identification associated with your wallet. Here&#39;s what to
							keep in mind:
						</Typography>
						<ul>
							<li>
								<b>Use Your Unique URL</b>: Always re-access the pre-sale using
								the specific URL that was provided to you for the private
								pre-sale.
							</li>
							<li>
								<b>Connect the Same Wallet</b>:Ensure that you reconnect using
								the same wallet address you used initially to secure your place
								in the queue.
							</li>
						</ul>
						<Typography>
							If you encounter any difficulties or if your spot doesn&#39;t
							appear to be saved upon your return, don’t hesitate to get in
							touch with our{' '}
							<a
								target="_blank"
								href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
								rel="noreferrer"
							>
								support team
							</a>{' '}
							for assistance.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel16'}
					onChange={handleChange('panel16')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5bh-content"
						id="panel16bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							Can I pay with a currency other than USDT or ETH?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							No, for the Film.io pre-sale, the only accepted cryptocurrencies
							are USDT (Tether) and ETH (Ethereum). Please ensure you have
							sufficient funds in one of these two currencies to participate in
							the pre-sale. If you have any questions or need assistance with
							the payment process, the Film.io{' '}
							<a
								target="_blank"
								href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
								rel="noreferrer"
							>
								support team
							</a>{' '}
							is available to help.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel17'}
					onChange={handleChange('panel17')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel17bh-content"
						id="panel5bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							Why hasn&#39;t my payment been reflected in my account yet?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							Crypto transactions sometimes take a bit of time to be confirmed
							on the blockchain, which could cause a delay in reflecting the
							payment in your account. Here’s what you can do:
						</Typography>
						<ul>
							<li>
								<b>Transaction Confirmation</b>: Check the transaction on the
								blockchain via a block explorer to confirm it has been completed
							</li>
							<li>
								<b></b>: Understand that during times of high traffic, the
								blockchain network may experience congestion, which can delay
								transaction confirmations.
							</li>
							<li>
								<b></b>: Wait for a short while, as the Film.io platform
								processes payments periodically and your account balance should
								update following a successful transaction confirmation.
							</li>
							<li>
								<b></b>: If your payment still isn&#39;t reflected after a
								significant amount of time, please reach out to Film.io{' '}
								<a
									target="_blank"
									href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
									rel="noreferrer"
								>
									support team
								</a>{' '}
								with your transaction details for further assistance.
							</li>
						</ul>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel18'}
					onChange={handleChange('panel18')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5bh-content"
						id="panel18bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							How much will the gas fees be for this transaction?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							The gas fees for transactions on the blockchain can vary widely
							based on network congestion and the complexity of the transaction.
							For the Film.io pre-sale, here&#39;s what you should consider:
						</Typography>
						<ul>
							<li>
								<b>Variable Fees</b>: Gas fees are determined by the Ethereum
								network and can fluctuate based on demand. They are not set by
								Film.io and are paid directly to network miners for processing
								transactions.
							</li>
							<li>
								<b>Estimation Tools</b>: To get an estimate of the current gas
								fees, you can use online tools like Etherscan’s Gas Tracker or
								ETH Gas Station before initiating a transaction.
							</li>
							<li>
								<b>Transaction Speed</b>: The higher the gas fee you&#39;re
								willing to pay, the faster your transaction will likely be
								processed. You can choose to pay less in gas fees, but this may
								result in a slower transaction.
							</li>
							<li>
								<b>In Transaction</b>: When you are making the transaction, most
								wallets will provide you with a gas fee estimate and options to
								set your gas price and limit.
							</li>
						</ul>
						<Typography>
							Remember, gas fees are an inherent part of using the Ethereum
							blockchain and are subject to change. It&#39;s important to check
							the latest fees at the time of your transaction to ensure
							you&#39;re prepared for the cost. If you have questions about gas
							fees or need assistance estimating them, feel free to contact the
							Film.io{' '}
							<a
								target="_blank"
								href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
								rel="noreferrer"
							>
								support team
							</a>{' '}
							.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel19'}
					onChange={handleChange('panel19')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5bh-content"
						id="panel19bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							Who is responsible for paying the gas fees during the purchase?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							The buyer is always responsible for paying the gas fees during the
							transaction on the Ethereum blockchain. When you participate in
							the Film.io pre-sale and make a purchase using USDT or ETH, it’s
							important to ensure that your wallet has sufficient funds to cover
							both the purchase amount and the associated gas fees required to
							execute the transaction.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel20'}
					onChange={handleChange('panel20')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5bh-content"
						id="panel20bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							I&#39;ve completed my purchase, but I don&#39;t see my tokens in
							my wallet. What should I do?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							If you&#39;ve completed your purchase in the Film.io pre-sale but
							dont see your tokens in your wallet, there&#39;s no need to worry.
							Here&#39;s what happens:
						</Typography>
						<ul>
							<li>
								<b>Transaction Confirmation</b>: Once your purchase is
								confirmed, you&#39;ll see a transaction hash indicating the
								blockchain record of your payment.
							</li>
							<li>
								<b>Token Allocation</b>: Your purchased tokens will be visible
								on the Film.io platform, reflecting the amount you&#39;ve
								bought. They won&#39;t be sent to your personal wallet
								immediately due to the presale conditions.
							</li>
							<li>
								<b>Vesting Schedule</b>: Tokens bought in the pre-sale are
								subject to a vesting schedule. This means they will be locked on
								the Film.io platform until they&#39;re released according to the
								terms of the vesting period.
							</li>
							<li>
								<b>Platform Balance</b>: Check your Film.io account to see the
								balance of your purchased tokens. This is where your tokens will
								be held until the vesting schedule allows for their release.
							</li>
							<li>
								<b>Support Confirmation</b>: If you&#39;re still unclear about
								the status of your tokens or if you believe there is a
								discrepancy, please contact Film.io{' '}
								<a
									target="_blank"
									href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
									rel="noreferrer"
								>
									support
								</a>{' '}
								with your transaction details. They&#39;ll be able to provide
								you with specific information regarding your tokens and the
								vesting process.
							</li>
						</ul>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel21'}
					onChange={handleChange('panel21')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5bh-content"
						id="panel21bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							How long does it take for the tokens to show up in my wallet after
							the sale?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							During the Film.io pre-sale, your purchased tokens will be subject
							to a vesting period and will not be immediately transferred to
							your wallet. Here&#39;s what to expect:
						</Typography>
						<ul>
							<li>
								<b>Immediate Record</b>: After completing your purchase, the
								transaction will be recorded on the blockchain, and you&#39;ll
								receive a transaction hash as proof of the purchase.
							</li>
							<li>
								<b>Platform Display</b>: Your tokens will be displayed in your
								Film.io platform account, not in your personal wallet, as they
								are locked during the vesting period.
							</li>
							<li>
								<b>Vesting Period</b>: The specific duration of the vesting
								period will be outlined in the terms of the pre-sale. This
								information will indicate when the tokens are scheduled to be
								released to your wallet.
							</li>
							<li>
								<b>Token Release</b>: Once the vesting period is complete,
								tokens will then be released and made available to transfer to
								your personal wallet according to the schedule provided by
								Film.io.
							</li>
							<li>
								<b>Notifications</b>: Film.io will provide notifications or
								instructions on how to transfer your vested tokens to your
								personal wallet when the time comes.
							</li>
						</ul>
						<Typography>
							For the exact timing and process, please refer to the terms of the
							pre-sale or contact Film.io{' '}
							<a
								target="_blank"
								href="https://filmio.atlassian.net/servicedesk/customer/portal/4"
								rel="noreferrer"
							>
								support team
							</a>{' '}
							for the vesting schedule and details about the release of your
							tokens.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel22'}
					onChange={handleChange('panel22')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5bh-content"
						id="panel22bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							When exactly will the public beta launch, and how will I be
							notified?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							The exact launch date of the public beta for Film.io will be
							announced by the company through their official channels.
							Here&#39;s how you can stay informed about the launch and
							notifications:
						</Typography>
						<ul>
							<li>
								<b>Official Announcements</b>: Keep an eye on our official
								Film.io communications, such as our website, press releases, and
								social media accounts for the announcement of the public beta
								launch date.
							</li>
							<li>
								<b>Email Notifications</b>: If you&#39;re registered with
								Film.io or have participated in the pre-sale, you&#39;ll likely
								receive email notifications with updates about the public beta
								and its official launch date.
							</li>
							<li>
								<b>Community Channels</b>: We will also use community channels
								such as Telegram groups, Discord servers, or forums to share
								updates with its community.
							</li>
							<li>
								<b>Platform Updates</b>: Check the Film.io platform itself for
								any updates or countdowns to the beta launch.
							</li>
						</ul>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel23'}
					onChange={handleChange('panel23')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5bh-content"
						id="panel23bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							Can I transfer my tokens to a non-custodial wallet of my choice?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							Yes, after the public beta launch and once the vesting schedule is
							met, you will be able to transfer your tokens to a non-custodial
							wallet of your choice.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel24'}
					onChange={handleChange('panel24')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel5bh-content"
						id="panel24bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							Is there a cap on how many tokens I can buy during the pre-sale?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							No, there is no cap on the number of tokens you can buy during the
							pre-sale. The only limit is the number of pre-allocated tokens for
							each phase of the sale. These limits will be clearly displayed on
							the sale page, allowing you to see how many tokens are available
							for purchase at any given time during the pre-sale event. Make
							sure to check the Film.io pre-sale page regularly for real-time
							updates on token availability.
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion
					className={styles.faqHeader}
					expanded={expanded === 'panel25'}
					onChange={handleChange('panel25')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel25bh-content"
						id="panel24bh-header"
					>
						<Typography sx={{ width: '90%', flexShrink: 0 }}>
							What happens if the pre-sale tokens sell out before I can buy?
						</Typography>
					</AccordionSummary>
					<AccordionDetails className={styles.faqContent}>
						<Typography>
							If the pre-sale tokens sell out before you can make a purchase, we
							recommend staying tuned for any future sales or token release
							events. To increase your chances of acquiring tokens, it&#39;s
							advisable to participate early when a new phase of the sale opens.
						</Typography>
					</AccordionDetails>
				</Accordion>
			</div>
		</>
	)
}

export default TokenSaleExperience
