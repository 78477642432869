import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginDialogOpen } from '@/components/header/signInSlice'
import { Grid, Box } from '@mui/material'
import styles from './PersonaSwitcher.module.scss'
import fan from 'assets/images/homepage/fan.png'
import creator from 'assets/images/homepage/creator.png'
import investor from 'assets/images/homepage/investor.png'
import studio from 'assets/images/homepage/studio.png'
import fanHero from 'assets/images/homepage/fan-hero.webp'
import stakes from 'assets/images/homepage/stakes.webp'
import rating from 'assets/images/homepage/rating.webp'
import creatorHero from 'assets/images/homepage/creator-hero.webp'
import investorHero from 'assets/images/homepage/investor-hero.webp'
import creatorHero2 from 'assets/images/homepage/creator-hero2.webp'
import creatorHero3 from 'assets/images/homepage/creator-hero3.webp'

const PersonaSwitcher = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { isAuthenticated } = useSelector(state => state.signIn)

	const switchPersona = persona => {
		const ele = document.getElementById('switch')

		for (const child of ele.children) {
			child.classList.remove(styles.active)
		}

		const cnt = document.getElementById('description')

		for (const child of cnt.children) {
			child.classList.remove(styles.active)
		}

		const element = document.getElementById(persona)
		element.classList.toggle(styles.active)

		switch (persona) {
			case 1: {
				const fan = document.getElementById('fan')
				fan.classList.add(styles.active)
				break
			}
			case 2: {
				const creator = document.getElementById('creator')
				creator.classList.add(styles.active)
				break
			}
			case 3: {
				const investor = document.getElementById('investor')
				investor.classList.add(styles.active)
				break
			}
		}
	}

	const handleGoToExplore = () => {
		if (!isAuthenticated) dispatch(setLoginDialogOpen(true))
		else {
			navigate('/explore')
		}
	}

	const handlePersonaClick = persona => () => {
		switchPersona(persona)
	}

	const handleGoTo = () => {
		window.open('https://forms.gle/gXScTWk7V8HCdMmy5', '_blank')
	}

	return (
		<>
			<Grid className={styles.personas}>
				<Grid id="switch" container className={styles.persona_switcher}>
					<Box id="1" className={styles.active} onClick={handlePersonaClick(1)}>
						<Box className={styles.persona}>
							<img src={fan.src} alt="How it works for Fans" />
							<p>Fans</p>
						</Box>
					</Box>
					<Box id="2" onClick={handlePersonaClick(2)}>
						<Box className={styles.persona}>
							<img src={creator.src} alt="How it works for Creators" />
							<p>Creators</p>
						</Box>
					</Box>
					<Box id="3" onClick={handlePersonaClick(3)}>
						<Box className={styles.persona}>
							<img src={investor.src} alt="How it works for Investors" />
							<p>Investors</p>
						</Box>
					</Box>
					<Box className={styles.inactive}>
						<Box className={styles.obscure}>
							<p>Coming Soon</p>
						</Box>
						<Box className={`${styles.persona} ${styles.hide}`}>
							<img src={studio.src} alt="How it works for Studios" />
							<p>Studios</p>
						</Box>
					</Box>
				</Grid>
				<Grid id="description">
					<Grid id="fan" container className={`${styles.persona_content_fan} ${styles.active}`}>
						<Box className={styles.persona_explanation}>
							<Box className={styles.persona_hero}>
								<img className={styles.hero_img} src={fanHero.src} alt="" />
								<img className={styles.hero_rating} src={rating.src} alt="" />
								<img className={styles.hero_stakes} src={stakes.src} alt="" />
							</Box>
							<Box className={styles.person_description}>
								<h2>Power to the People</h2>
								<p>
									As a fan, you now have a say in what entertainment gets
									created. Fans are empowered to claim and stake the native FAN
									token to projects they support, earning more governance power,
									increasing the project’s Go Score, and enabling film investors
									to scout market validated projects with pre-developed
									audiences.
								</p>
								<h3>Steps to Success</h3>
								<ul>
									<li>
										Discover new projects and Influence the ones you want to see
										get made
									</li>
									<li>
										Shape the New Hollywood: Stake to Vote on projects to help
										move them through the Filmio ranks and be the decision maker
										on what projects get made
									</li>
									<li>
										Get Recognized: Become a Superfan for the chance of special
										prizes, badges, NFTs, and other early access opportunities
									</li>
									<li>
										Get Rewarded: Staking $FAN to a project rewards you with
										additional FAN Tokens in the form of FGR (FAN Governance
										Rewards)
									</li>
									<li>
										Share the Love: bring your friends and connections and help
										the projects you want to see get made succeed!
									</li>
								</ul>
								<button onClick={handleGoToExplore}>Join Now</button>
							</Box>
						</Box>
					</Grid>
					<Grid
						id="creator"
						container
						className={styles.persona_content_creator}
					>
						<Box className={styles.persona_explanation}>
							<Box className={styles.persona_hero}>
								<img className={styles.hero_img} src={creatorHero.src} alt="" />
								<img
									className={styles.hero_three}
									src={creatorHero2.src}
									alt=""
								/>
								<img
									className={styles.hero_two}
									src={creatorHero3.src}
									alt=""
								/>
							</Box>
							<Box className={styles.person_description}>
								<h2>Liberating Creators</h2>
								<p>
									Film.io provides creators a highly concentrated social
									ecosystem in the film vertical within which to post and
									promote their film, TV and NFT projects. This enables creators
									to achieve validation, engage fans and build a fanbase,
									setting them up for success in fundraising and licensing.
								</p>
								<p>
									By submitting your project as a{' '}
									<strong>Film.io DAO Creativity Proposal</strong>, you are
									taking the first step towards a future where you are in
									control. Film.io provides the tools you need to create a{' '}
									<strong>beautiful project page, grow your audience</strong>{' '}
									and prove the commercial viability of your idea through
									advancing your project’s <strong>Go Score</strong>, and
									unlocking advanced features, like{' '}
									<strong>NFT creation</strong> and{' '}
									<strong>Funding Tools</strong>.
								</p>
								<h3 className={styles.creator_steps}>Steps to Success</h3>
								<ul className={styles.creator_steps}>
									<li>Submit Your DAO Creativity Proposal</li>
									<li>Build a beautiful project packaging page</li>
									<li>
										Grow Your Audience with our integrated referral tools.
									</li>
									<li>
										Leverage the power of the crowd to advance Your Go Score.
									</li>
									<li>Unlock Exciting Advanced Features (such as funding)</li>
									<li>
										Promote your project with The Newsreel our integrated social
										platform.
									</li>
								</ul>
								<button
									className={styles.creator_steps}
									onClick={handleGoToExplore}
								>
									Start Creating
								</button>
							</Box>
						</Box>
					</Grid>
					<Grid
						id="investor"
						container
						className={styles.persona_content_investor}
					>
						<Box className={styles.persona_explanation}>
							<Box className={styles.persona_hero}>
								<img
									className={styles.hero_img}
									src={investorHero.src}
									alt=""
								/>
							</Box>
							<Box className={styles.person_description}>
								<h2>Invest in Great Films, Vetted by industry experts</h2>
								<p>
									By Joining a Funding Syndicate on the Film.io platform, you
									will leverage cutting-edge tools to make informed investment
									decisions, providing funding for de-risked projects with
									built-in loyal audiences, sourced directly from the Film.io
									Platform.
								</p>
								<p>
									<strong>Think AngelList for the Film Market.</strong>
								</p>
								<button onClick={handleGoTo}>Apply Today!</button>
								<h3>Syndicate Benefits</h3>
								<ul>
									<li>Participate in a Community Led Film Investment Fund</li>
									<li>Audience Validation powered by Go Score™ technology</li>
									<li>
										Leverage on-chain voting and reputation metrics for safety
										and rewards
									</li>
									<li>Opportunities to partner with major Studios</li>
									<li>
										Enjoy a streamlined process for claiming Executive Producer
										credits
									</li>
									<li>
										Better, Faster path for investment in film and tv production
									</li>
								</ul>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default PersonaSwitcher
