import { useNavigate } from 'react-router-dom'
import { Grid, Box, Typography } from '@mui/material'
import styles from './Superfan.module.scss'
import crowns from 'assets/images/homepage/crown.webp'
import superfanHero from 'assets/images/homepage/superfan_hero.webp'

export const SuperfanSection = () => {
	const navigate = useNavigate()

	const handleGoToExplore = () => {
		navigate('/explore')
	}

	return (
		<>
			<Grid>
				<Box className={styles.superfan}>
					<Grid container className={styles.superfan_desc}>
						<img alt="" src={crowns.src} />
						<Box className={styles.hero}>
							<img alt="" src={superfanHero.src} />
						</Box>
						<Box className={styles.explanation}>
							<h2>Super Benefits for Superfans</h2>
							<Typography>
								A <strong>SuperFAN</strong> is an early supporter who believes
								in a film project&lsquo;s potential. When you stake at least{' '}
								<strong>100 $FAN Tokens</strong>, you not only become a part of
								that project’s community — you also secure exclusive benefits
								and opportunities as it evolves. The benefits are endless!
							</Typography>
							<Typography>
								Your SuperFAN Position isn’t just a neutral number — it’s your
								very own place in the community. Higher ranks get better chances
								for <strong>exclusive NFTs</strong>,{' '}
								<strong>funding participation</strong> and other{' '}
								<strong>perks</strong>.
							</Typography>
							<button onClick={handleGoToExplore}>
								Discover Your Next Obsession
							</button>
						</Box>
					</Grid>
				</Box>
			</Grid>
		</>
	)
}

export default SuperfanSection
