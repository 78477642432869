import { ConfirmDialog } from '../ConfirmDialog'
import { Project } from '@/components/project/useProjectPage'
import { ReactNode } from 'react'

interface FollowProjectWrapperProps {
	project: Project
	children: ReactNode
	onConfirm: () => void
}

export const FollowProjectWrapper = ({
	project,
	children,
	onConfirm
}: FollowProjectWrapperProps) => {
	const showConfirmDialog = project?.isFollowedByUser && project?.isSuperfan
	return showConfirmDialog ? (
		<ConfirmDialog
			message={
				<>
					<p className="mb-[15px] break-words text-center font-titillium text-[20px] font-normal leading-6 text-white">
						If you unfollow this project, you will lose
						<br />
						your Superfan status and rank!
					</p>
					<p className="mb-[15px] text-center font-titillium text-[20px] font-bold leading-6 text-white">
						You are currently ranked #{project?.superfanStats?.rank} / #
						{project?.totalSuperfans}.
					</p>
					<p className="text-center font-titillium text-[20px] font-normal leading-6 text-white">
						Do you want to continue?
					</p>
				</>
			}
			onConfirm={onConfirm}
			title="Wait a Minute!"
			confirmText="Yes, Unfollow"
			cancelText="Nevermind"
		>
			{children}
		</ConfirmDialog>
	) : (
		<div className="flex w-auto flex-1 cursor-pointer" onClick={onConfirm}>
			{children}
		</div>
	)
}
