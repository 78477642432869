import _ from 'lodash'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from '@/components/ui/button'
import { useEffect } from 'react'
import useNotifications from '@/components/header/notifications/useNotifications'
import { formatDateToRelative } from 'utils/date.utils'
import reactions from 'assets/images/reactions'
import { Icon } from '@mui/material'
import { ArrowLeftIcon } from 'lucide-react'

export default function MobileNotifications({ closeNotifs }) {
	const {
		user,
		notifications,
		getNotifications,
		handleClose
	} = useNotifications()

	useEffect(() => {
		getNotifications()
	}, [])

	const renderNotifications = () => {
		return notifications.rows.map(n => {
			const inProgress = Number(n?.inProgress) === 1
			let description = n.description ?? ''
			let link = `/profile/${user.username}?tab=4`
			let base = ''
			const icon = _.get(n, ['type', 'icon'], 'info')
			const iconSx = n?.levelUpImage
				? { backgroundImage: `url( ${n?.levelUpImage} )`, color: '#000' }
				: {}

			if ([3, 4].includes(n?.typeId)) {
				base = 'fas'
			}
			if ([7, 8, 9].includes(n?.typeId)) {
				base = 'fal'
			}
			if ([1, 2, 5, 6, 10, 11, 12, 13].includes(n?.typeId)) {
				base = 'fa-kit'
			}
			if (n.typeId === 9) {
				const splitDesc = description.split('$$reaction')
				const reactionIcon = _.get(n, 'newsreelReaction.reaction.icon', '')
				description = (
					<>
						{splitDesc[0]}{' '}
						<img
							src={reactions[reactionIcon]}
							alt="reaction"
							className="inline-block h-4 w-4"
						/>{' '}
						{splitDesc[1]}
					</>
				)
			}

			if ([7, 8, 9].includes(n?.typeId)) {
				const linkType = _.isNil(n?.newsreel?.projectId) ? 'profile' : 'project'
				const slug = _.isNil(n?.newsreel?.projectId)
					? n?.newsreel?.user?.username
					: n?.newsreel?.project.slug
				link = `/${linkType}/${slug}?tab=1&newsreelId=${n?.newsreelId}`
			}

			return (
				<li key={n.id} className="w-full py-3 last:border-none">
					<Link
						to={link}
						onClick={handleClose}
						className="flex w-full items-center gap-3"
					>
						<span className="relative flex h-[30px] w-[30px] min-w-[30px] items-center justify-center rounded-full bg-primary [&>svg]:!text-lg">
							<Icon baseClassName={base} className={`fa-${icon}`} sx={iconSx} />
							{inProgress && (
								<span className="absolute left-0 top-0 h-2.5 w-2.5 rounded-full bg-[#ff409a]"></span>
							)}
						</span>
						<div className="relative flex w-full flex-col text-white">
							<div className="flex items-center justify-between text-xs font-semibold">
								<div>{n.name}</div>
								<div className="shrink-0 text-[10px] text-white">
									{formatDateToRelative(n.createdAt)}
								</div>
							</div>

							<span className="text-[10px] leading-tight">{description}</span>
						</div>
					</Link>
				</li>
			)
		})
	}

	return (
		<div className="flex w-full flex-col items-center justify-center gap-1 px-0 py-4 text-base">
			<div className="mb-0 flex w-full items-center">
				<Button
					variant="ghost"
					className="-mx-4 text-lg font-bold text-white"
					onClick={closeNotifs}
				>
					<ArrowLeftIcon size={24} />
					Notifications
				</Button>
			</div>
			<ul className="w-full">{renderNotifications()}</ul>
		</div>
	)
}

MobileNotifications.propTypes = {
	handleClose: PropTypes.func
}
