import { getUserPerksProgramProjects } from '@/services/project.service'
import { BaseCarousel } from '@/components/common-ui/BaseCarousel'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import {
	ProjectCarouselItemSkeleton,
	ProjectsCarouselItem
} from './ProjectsCarouselItem'
import { ProjectsCarouselItemProps } from './ProjectsCarouselItem'
import useAxios from '@/hooks/useAxios'
import { queryKeys } from '@/lib/queryKeys'
import { useEffect } from 'react'
import { RootState } from '@/store/store'
import { useSelector } from 'react-redux'

export const ProjectCarrousel = () => {
	const { axiosServiceSync } = useAxios()
	const queryClient = useQueryClient()
	const { newSuperfanEvent } = useSelector(
		(state: RootState) => state.websocket
	)

	const { data: projects = [], isLoading } = useQuery({
		queryKey: queryKeys.project.perksProgramProjects(),
		queryFn: async () => {
			const projects = await axiosServiceSync(getUserPerksProgramProjects())
			return projects
		},
		staleTime: 1000 * 60 * 60 // 1 hour
	})

	useEffect(() => {
		if (projects?.length > 0) {
			queryClient.invalidateQueries({
				queryKey: queryKeys.project.perksProgramProjects()
			})
		}
	}, [newSuperfanEvent])

	return (
		<BaseCarousel className="w-full px-12 pb-2">
			{isLoading
				? // Skeleton items while loading
					[...Array(4)].map((_, index) => (
						<ProjectCarouselItemSkeleton key={`skeleton-${index}`} />
					))
				: projects.map((project: ProjectsCarouselItemProps) => (
						<ProjectsCarouselItem key={project?.id} {...project} />
					))}
		</BaseCarousel>
	)
}
