import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getProfileEarnings,
	getProfileStakes,
	getProfileTransactionsService,
	retryTransactionService,
	reconcileTransactionService
} from 'services/profile.service'
import {
	fgrClaimingCompleted,
	reloadRecentActivity,
	setOnly,
	setOrder,
	setOrderBy,
	setPaging,
	setRetyingTransaction,
	setTransactions
} from '../walletTabSlice'
import { claimDaoTierBonus } from 'services/daoTier.service'
import useAxios from 'hooks/useAxios'
import useNotification from 'hooks/useNotification'
import useHeader from '@/components/header/useHeader'
import useLoadingMask from 'hooks/useLoadingMask'
import {
	getReviewByIdService,
	retryReviewBonusService,
	updateReviewHashService
} from 'services/review.service'
import {
	unstakeBlockchainService,
	unstakeService,
	validateProjectStakeLimitService
} from 'services/stake.service'
import { claimFgrBlockchainService } from 'services/project.service'
import { Mixpanel } from 'services/mixpanel.service'
import {
	FanTokenContract,
	FilmioProjectContract,
	StakingContract
} from 'utils/blockchain/functions'
import { addBlockchainReviewHook } from '@/components/projectDetail/projectDetailSlice'
import {
	setFrontTransactionsInProgress,
	setCreatePlatformWalletDialogOpen
} from '@/components/header/signInSlice'
import { addBlockchainFanWithdrawalService } from 'services/wallet.service'
import useCommon from 'hooks/useCommon'

const useActivityTable = () => {
	const [search, setSearch] = useState('')
	const [openDetails, setOpenDetails] = useState(false)
	const [fanPurchaseDetails, setFanPurchaseDetails] = useState(null)
	const { axiosService } = useAxios()
	const { showNotification } = useNotification()
	const { reloadUserInfo } = useHeader()
	const { hideMask, showMask } = useLoadingMask()
	const {
		transactions,
		orderBy,
		pagin,
		only,
		order,
		retyingTransaction,
		reloadTransactions
	} = useSelector(state => state.walletTab)
	const { userId } = useSelector(state => state.profile)
	const { stakeCompletedEvent } = useSelector(state => state.websocket)
	const { user: signInUser } = useSelector(
		state => state.signIn
	)
	const { checkNcNoMagic } = useCommon()
	const dispatch = useDispatch()

	const claimTierBenefitsBlockchain = () => {
		axiosService(claimDaoTierBonus(), (_data, error) => {
			if (!error) {
				showNotification({ message: 'Tier bonus completed successfully!' })
				dispatch(reloadRecentActivity())
				reloadUserInfo()
			}
			dispatch(setRetyingTransaction(false))
		})
	}

	const getReviewById = async reviewId => {
		return new Promise(resolve => {
			axiosService(getReviewByIdService(reviewId), (review, error) => {
				if (!error) {
					resolve(review)
				}
			})
		})
	}

	const updateBlockchainReview = (reviewId, blockchainHash) => {
		axiosService(
			updateReviewHashService({ reviewId, blockchainHash }),
			(data, error) => {
				if (!error) {
					dispatch(addBlockchainReviewHook(data))
					dispatch(reloadRecentActivity())
					reloadUserInfo()
					()
				}
			}
		)
	}

	const handleReconcileBlockchainTransaction = transaction => () => {
		if (checkNcNoMagic()) {
			dispatch(setCreatePlatformWalletDialogOpen(true))
		} else {
			dispatch(setRetyingTransaction(transaction.id))
			axiosService(
				reconcileTransactionService(transaction.id),
				(data, error) => {
					if (!error && !_.isNil(data) && data?.isReconciled === true) {
						showNotification({
							message: 'Transaction reconciled successfully!'
						})
						dispatch(reloadRecentActivity())
						reloadUserInfo()
						dispatch(setRetyingTransaction(false))
					} else {
						handleRetryTransaction(transaction)
					}
				}
			)
		}
	}

	const retryReview = async reviewId => {
		const review = await getReviewById(reviewId)
		if (_.isNil(review?.blockchainHash)) {
			dispatch(setFrontTransactionsInProgress(true))
			const blockchainHash = await FilmioProjectContract.addRating(
				review?.projectId,
				review?.rating,
				!_.isNil(review?.heading) || !_.isNil(review?.content),
				reviewId
			)
			updateBlockchainReview(reviewId, blockchainHash)
			dispatch(setFrontTransactionsInProgress(false))
		}
	}

	const handleRetryReviewBonus = async reviewId => {
		await retryReview(reviewId)
		axiosService(retryReviewBonusService(reviewId), (_data, error) => {
			if (!error) {
				showNotification({ message: 'Review bonus completed successfully!' })
				dispatch(reloadRecentActivity())
				reloadUserInfo()
			}
			dispatch(setRetyingTransaction(false))
		})
	}

	const unstakeBlockchain = (transactionId, blockchainHash) => {
		axiosService(
			unstakeBlockchainService({ transactionId, blockchainHash }),
			(data, error) => {
				if (!error) {
					const message = _.isNil(data?.blockchainHash)
						? 'Unstake failed.'
						: 'Unstake completed successfully!'
					showNotification({ message })
				}
				reloadUserInfo()
				dispatch(reloadRecentActivity())
				dispatch(setRetyingTransaction(false))
			}
		)
	}

	const handleRetryUnstake = (stakeId) => {
		dispatch(setRetyingTransaction(false))
		axiosService(unstakeService({ transactionId: stakeId }), afterUnstake)
	}

	const afterUnstake = async stake => {
		dispatch(setFrontTransactionsInProgress(true))
		const blockchainHash = await StakingContract.unstakeTokens(
			stake?.blockchainHash,
			stake?.unstakeId
		)
		unstakeBlockchain(stake?.id, blockchainHash)
		dispatch(setFrontTransactionsInProgress(false))
		if (_.isNil(blockchainHash)) {
			Mixpanel.track('transaction_failed_unstake_evt', {
				distinct_id: signInUser.id,
				displayName: signInUser.displayName,
				username: signInUser.username,
				amount: stake?.amount,
				transactionId: stake?.id
			})
		} else {
			Mixpanel.track('transaction_successful_unstake_evt', {
				distinct_id: signInUser.id,
				displayName: signInUser.displayName,
				username: signInUser.username,
				amount: stake?.amount,
				transactionId: stake?.id,
				blockchainHash: stake?.blockchainHash
			})
		}
	}

	const addBlockchainWithdrawal = (transactionId, blockchainHash) => {
		axiosService(
			addBlockchainFanWithdrawalService({
				transactionId,
				blockchainHash
			}),
			() => {
				dispatch(setRetyingTransaction(false))
				dispatch(reloadRecentActivity())
			}
		)
	}

	const handleRetryTransfer = async ({ transactionId, amount }) => {
		dispatch(setFrontTransactionsInProgress(true))
		const hash = await FanTokenContract.transfer(
			signInUser?.whitelistedAddress,
			Number(amount),
			transactionId
		)

		addBlockchainWithdrawal(transactionId, hash)
		dispatch(setFrontTransactionsInProgress(false))
	}

	const validateStakingLimit = (amount, projectId) => {
		return new Promise(resolve => {
			axiosService(
				validateProjectStakeLimitService({ amount, projectId }),
				(_data, error) => {
					resolve(!error)
				}
			)
		})
	}

	const handleRetryStake = async ({ transactionId, amount, projectId }) => {
		dispatch(setFrontTransactionsInProgress(true))
		const isValid = await validateStakingLimit(amount, projectId)
		if (!isValid) {
			dispatch(setRetyingTransaction(false))
			return
		}
		await StakingContract.stakeTokens(
			projectId,
			Number(amount),
			transactionId
		)
	}

	const handleRetryClaimFgr = transactionId => {
		axiosService(claimFgrBlockchainService(transactionId), (data, error) => {
			if (!error) {
				dispatch(fgrClaimingCompleted())
				showNotification({ message: 'FGR tokens claimed successfully!' })
				dispatch(reloadRecentActivity())
				reloadUserInfo()
			}
			dispatch(setRetyingTransaction(false))
		})
	}

	const retryTransaction = transactionId => {
		axiosService(retryTransactionService(transactionId), (data, error) => {
			if (!error) {
				showNotification({ message: 'Tokens claimed successfully!' })
				dispatch(reloadRecentActivity())
				reloadUserInfo()
			}
			dispatch(setRetyingTransaction(false))
		})
	}

	const afterFetchTransactions = (data, error) => {
		if (!error) dispatch(setTransactions(data))
		hideMask()
	}

	const getWalletTransactions = () => {
		showMask()
		axiosService(
			getProfileTransactionsService({
				search,
				limit: pagin.limit,
				offset: pagin.offset,
				order,
				orderBy
			}),
			afterFetchTransactions
		)
	}

	const getWalletStakes = () => {
		showMask()
		axiosService(
			getProfileStakes(
				userId,
				pagin.limit,
				pagin.offset,
				order,
				orderBy,
				search
			),
			afterFetchTransactions
		)
	}

	const getWalletEarnings = () => {
		showMask()
		axiosService(
			getProfileEarnings(
				userId,
				pagin.limit,
				pagin.offset,
				order,
				orderBy,
				search
			),
			afterFetchTransactions
		)
	}

	const handleRetryTransaction = transaction => {
		const { typeId, objectId, amount, id, project } = transaction
		if ([4, 16].includes(typeId) && signInUser?.balance < amount) {
			showNotification({
				message: 'You need more $FAN tokens to retry this transaction.'
			})
			return
		}
		switch (typeId) {
			case 2:
				handleRetryReviewBonus(objectId, id)
				break
			case 4:
				handleRetryStake({
					amount,
					projectId: project?.id,
					transactionId: id
				})
				break
			case 7:
				handleRetryUnstake(objectId, id, project)
				break
			case 8:
				handleRetryClaimFgr(id)
				break
			case 10:
			case 13:
				claimTierBenefitsBlockchain(id)
				break
			case 16:
				handleRetryTransfer({
					transactionId: id,
					amount
				})
				break
			case 1:
			case 3:
			case 6:
			case 14:
			case 15:
			case 17:
			case 20:
				retryTransaction(id)
				break
			default:
				break
		}
	}

	const handleRequestSort = (_event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		dispatch(setOrder(isAsc ? 'desc' : 'asc'))
		dispatch(setOrderBy(property))
	}

	const handleChangePage = (event, newPage) => {
		const offset = Number(newPage) * Number(pagin.limit)
		dispatch(
			setPaging({
				offset,
				page: newPage
			})
		)
	}

	const handleChangeRowsPerPage = event => {
		dispatch(
			setPaging({
				page: 0,
				offset: 0,
				limit: event.target.value
			})
		)
	}

	const handleEarningsOnlyClick = () => {
		dispatch(setOnly({ stake: false, earnings: !only.earnings }))
	}

	const handleStakesOnlyClick = () => {
		dispatch(setOnly({ earnings: false, stake: !only.stake }))
	}

	const handleOpenDetails = row => {
		setFanPurchaseDetails(row)
		setOpenDetails(true)
	}

	const handleCloseDetails = () => {
		setOpenDetails(false)
		setFanPurchaseDetails(null)
	}
	const emptyRows =
		pagin.page > 0
			? Math.max(0, (1 + pagin.page) * pagin.limit - transactions.count)
			: 0
	const isFraud = Number(signInUser.isFraud) === 1

	useEffect(() => {
		if (!_.isNil(userId)) {
			if (only.stake) getWalletStakes()
			else if (only.earnings) getWalletEarnings()
			else getWalletTransactions()
		}
	}, [search, pagin, order, orderBy, only, reloadTransactions, userId])

	return {
		only,
		pagin,
		order,
		search,
		orderBy,
		isFraud,
		emptyRows,
		openDetails,
		transactions,
		retyingTransaction,
		fanPurchaseDetails,
		stakeCompletedEvent,
		handleReconcileBlockchainTransaction,
		handleChangeRowsPerPage,
		handleEarningsOnlyClick,
		handleStakesOnlyClick,
		handleCloseDetails,
		handleOpenDetails,
		handleRequestSort,
		showNotification,
		handleChangePage,
		setSearch
	}
}

export default useActivityTable
