import packageOpenWhite from '@/assets/images/package-open-white.svg'
import { Button } from '@/components/ui/button'
import { MouseEvent, useState } from 'react'
import { SuperfanBenefitsDialog } from './superfanBenefitsDialog/SuperfanBenefitsDialog'
import { PerkProgramDialog } from '@/components/projectDetail/dashboardTab/superfan/perkProgramDialog'
import packageClosedWhite from '@/assets/images/package-closed-white.svg'

interface SuperfanBenefitsProps {
	projectId: string
	isOnPerksProgram: boolean
}

export const SuperfanBenefits = ({
	projectId,
	isOnPerksProgram
}: SuperfanBenefitsProps) => {
	const [isOpen, setIsOpen] = useState(false)

	const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
		// Stop propagation to parent elements
		e.stopPropagation()
		// Prevent default browser behavior
		e.preventDefault()
		// Set dialog open state
		setIsOpen(true)

		return false
	}

	return (
		<>
			{isOnPerksProgram ? (
				<Button
					variant="secondary"
					className="flex h-12 md:h-9 justify-center gap-2 bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] px-3 text-sm font-bold leading-5 text-[#FAFAFA] hover:bg-opacity-70"
					onClick={handleButtonClick}
				>
					<img
						src={packageOpenWhite.src}
						alt="Superfan Perks"
						className="h-4 w-4"
					/>
					<span className="hidden opacity-100 md:block">Superfan</span>
					Perks
				</Button>
			) : (
				<Button
					variant="secondary"
					className="flex h-12 md:h-9 justify-center gap-2 bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] px-3 text-sm font-bold leading-5 text-[#ffffff] grayscale-[1]"
					onClick={handleButtonClick}
				>
					<img
						src={packageClosedWhite.src}
						alt="Superfan Perks"
						className="h-4 w-4 opacity-25"
					/>
					<span className="hidden opacity-25 md:block">Superfan</span>{' '}
					<span className="opacity-25">Perks</span>
				</Button>
			)}

			{isOnPerksProgram ? (
				<SuperfanBenefitsDialog
					open={isOpen}
					onOpenChange={open => setIsOpen(open)}
					projectId={projectId}
				/>
			) : (
				<PerkProgramDialog
					{...{
						isOnPerksProgram: isOnPerksProgram,
						projectName: 'The Title',
						open: isOpen,
						handleClose: () => {
							setIsOpen(false)
						}
					}}
				/>
			)}
		</>
	)
}
