import { useDispatch, useSelector } from 'react-redux'
import { authNcWalletService } from 'services/auth.service'
import { setConnectDialogOpen } from '@/components/header/signInSlice'
import useLoadingMask from 'hooks/useLoadingMask'
import useAxios from 'hooks/useAxios'
import useWeb3 from 'hooks/useWeb3'
import { logger } from '@/utils/logger'
const useConnectDialog = () => {
	const dispatch = useDispatch()
	const { connectDialogOpen: open } = useSelector(state => state.signIn)
	const { showMask, hideMask } = useLoadingMask()
	const { axiosService } = useAxios()
	const { disconnectAsync, metamaskLogin, openConnectWalletModal } = useWeb3()

	const authNcWallet = data => {
		showMask()
		axiosService(authNcWalletService({ ...data }), (response, error) => {
			if (!error) {
				logger.info('connected!')
			} else {
				disconnectAsync()
			}
			hideMask()
		})
	}

	const handleMMLogin = () => {
		metamaskLogin()
		handleClose()
	}

	const handleNcLogin = () => {
		openConnectWalletModal()
		handleClose()
	}

	const handleClose = () => {
		dispatch(setConnectDialogOpen(false))
	}

	return {
		open,
		handleNcLogin,
		handleMMLogin,
		authNcWallet,
		handleClose
	}
}

export default useConnectDialog
