import {
	Button,
	Box,
	Icon,
	Typography,
	Dialog,
	IconButton
} from '@mui/material'
import useConnectDialog from './useConnectDialog'
import styles from './ConnectDialog.module.scss'
import metamaskLogo from 'assets/images/metamask-color.png'
import { isMetamask, isMobile, isSafariMacOs } from 'utils/utils'

export const ConnectDialog = () => {
	const { open, handleNcLogin, handleMMLogin, handleClose } = useConnectDialog()

	const currentURL = window.location.href
	const metaMaskButtonUrl = currentURL
		.replace('https://', '')
		.replace('http://', '')

	return (
		<Dialog
			open={open}
			disableScrollLock={true}
			className={styles.connectDialog}
			modal="true"
			classes={{
				paper: styles.dialogPaper,
				container: styles.dialogContainer
			}}
			onClose={handleClose}
		>
			<IconButton
				size="small"
				onClick={handleClose}
				className={styles.closeBtn}
			>
				<Icon baseClassName="fas" className="fa-xmark" />
			</IconButton>
			<Box className={styles.body}>
				<Box className={styles.header}>Connect Wallet</Box>
				<Box className={styles.otherLogin}>
					{!isSafariMacOs() && (
						<>
							{isMetamask() || !isMobile() ? (
								<Button
									className={styles.walletMobileCnct}
									onClick={handleMMLogin}
								>
									<img src={metamaskLogo.src} alt="MetaMask" />
									MetaMask
								</Button>
							) : (
								<Button className={styles.walletMobileCnct}>
									<a
										href={`https://metamask.app.link/dapp/${metaMaskButtonUrl}`}
									>
										<img src={metamaskLogo.src} alt="MetaMask" />
										<Typography>MetaMask</Typography>
									</a>
								</Button>
							)}
						</>
					)}
					<Button
						className={styles.walletCnct}
						onClick={handleNcLogin}
						startIcon={<Icon baseClassName="fas" className="fa-wallet" />}
					>
						Wallet
					</Button>
				</Box>
			</Box>
		</Dialog>
	)
}
