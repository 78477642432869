import _ from 'lodash'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	setCreatePlatformWalletDialogOpen,
	setDaoTierLevelingChangeDialogOpen,
	setShowFanSearchMobile
} from 'components/header/signInSlice'
import {
	calculateUserDaoTierProgress,
	upgradeUserDaoTier,
	claimDaoTierBonus
} from 'services/daoTier.service'
import useHeader from '@/components/header/useHeader'
import useAxios from 'hooks/useAxios'
import useNotification from 'hooks/useNotification'
import { reloadRecentActivity } from '@/components/profile/walletTab/walletTabSlice'
import { reloadDaoTiers } from '@/components/profile/profileSlice'
import useLoadingMask from 'hooks/useLoadingMask'
import useCommon from 'hooks/useCommon'
import { RootState } from '@/store/store'

export default function useProfileMenuItems() {
	const dispatch = useDispatch()
	const { user: signInUser, jwt } = useSelector(
		(state: RootState) => state.signIn
	)
	const { checkNcNoMagic } = useCommon()
	const [tierProgress, setTierProgress] = useState(null)
	const { showNotification } = useNotification()
	const { loadUserTierInfo, reloadUserInfo, showConfetti } = useHeader()
	const { axiosServiceSync } = useAxios()
	const { showMask: showLoading, hideMask } = useLoadingMask()

	const refreshProgress = async () => {
		const result = await axiosServiceSync(calculateUserDaoTierProgress())
		setTierProgress(result.progress)
	}

	const claimTierBenefitsBlockchain = async () => {
		await axiosServiceSync(claimDaoTierBonus())
		showNotification({ message: 'Tier bonus completed successfully!' })
		dispatch(reloadRecentActivity())
		reloadUserInfo()
	}

	const upgradeTier = async () => {
		const result = await axiosServiceSync(upgradeUserDaoTier())
		if (!result.error) {
			claimTierBenefitsBlockchain()
			loadUserTierInfo(() => {
				dispatch(setDaoTierLevelingChangeDialogOpen(true))
				showConfetti()
				refreshProgress()
			})
			dispatch(reloadDaoTiers())
			reloadUserInfo()
		}
		hideMask()
	}

	const toggleSearchMobile = () => {
		dispatch(setShowFanSearchMobile(true))
	}

	const handleClickUpgradme = () => {
		if (checkNcNoMagic()) {
			dispatch(setCreatePlatformWalletDialogOpen(true))
		} else {
			showLoading()
			upgradeTier()
		}
	}

	const handleCopyWalletAddress = () => {
		navigator.clipboard.writeText(signInUser?.walletAddress).then(() => {
			showNotification({
				message: 'Your wallet address has been copied to your clipboard.'
			})
		})
	}

	const onOpenProfileMenu = () => {
		refreshProgress()
		loadUserTierInfo()
	}

	const profileHref = `/profile/${signInUser?.username}`
	const tierName = _.capitalize(signInUser?.daoTierName)
	const nextTierName = _.capitalize(signInUser?.nextTierName)
	const isDaoTierConfirmed = Number(signInUser?.daoTierConfirmed) === 1
	const isDaoTierCompleted = Number(signInUser?.daoTierCompleted) === 1
	const upgradeAvailable =
		(!isDaoTierConfirmed && isDaoTierCompleted) ||
		Number(signInUser?.nextTierCompleted) === 1
	const tierImage = upgradeAvailable
		? signInUser?.nextTierImage
		: signInUser?.daoTierImg

	return {
		jwt,
		tierName,
		tierImage,
		signInUser,
		profileHref,
		tierProgress,
		nextTierName,
		upgradeAvailable,
		handleCopyWalletAddress,
		handleClickUpgradme,
		toggleSearchMobile,
		onOpenProfileMenu
	}
}
