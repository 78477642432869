import { CSSProperties } from 'react'
import useAxios from '@/hooks/useAxios'
import { queryKeys } from '@/lib/queryKeys'
import { cn } from '@/lib/utils'
import { getSuperfanLevelsService } from '@/services/superfan.service'
import { abbreviateNumberFixed } from '@/utils/utils'
import { useQuery } from '@tanstack/react-query'
import React from 'react'

interface MilestoneProgressProps {
	value: number
}

const milestoneStyles =
	'flex h-8 w-8 items-center justify-center rounded-full border-2 font-inter text-xs font-bold leading-4 text-white'

interface Milestone {
	id: number
	bonus: number
	pointsThreshold: number
	description: string
}

export const MilestoneProgress = ({ value = 0 }: MilestoneProgressProps) => {
	const { axiosServiceSync } = useAxios()

	const { data: milestones } = useQuery<Milestone[]>({
		queryKey: queryKeys.project.superfanMilestones(),
		queryFn: async () => {
			const data = await axiosServiceSync(getSuperfanLevelsService())
			return data
		},
		staleTime: 1000 * 60 * 60 * 24 // 24 hours
	})

	if (!milestones) return null

	const POINT_WIDTH = 32 // fixed point width in pixels
	const EDGE_WIDTH = 16 // x padding pixels
	const X_SEGMENT_WIDTH = POINT_WIDTH / 2 + EDGE_WIDTH // x padding pixels
	const TOTAL_WIDTH = 386 // fixed total width in pixels
	const MIDDLE_WIDTH = TOTAL_WIDTH - X_SEGMENT_WIDTH * 2
	const lastMilestone = milestones[milestones.length - 1]
	const maxValue = lastMilestone.pointsThreshold * 2

	const getSegmentProgress = (value: number) => {
		// For values below first milestone
		const minThreshold = milestones[0].pointsThreshold
		if (value < minThreshold) {
			return {
				fillStyle: {
					width: `${(value / minThreshold) * EDGE_WIDTH}px`
				},
				nextMilestoneValue: minThreshold
			}
		}

		// For values above last milestone
		if (value > lastMilestone.pointsThreshold) {
			const extraValue = value - lastMilestone.pointsThreshold
			const segmentSize = lastMilestone.pointsThreshold
			const progress = Math.min(extraValue / segmentSize, 1)

			return {
				fillStyle: {
					width: `${TOTAL_WIDTH - X_SEGMENT_WIDTH + progress * X_SEGMENT_WIDTH}px`
				},
				nextMilestoneValue: maxValue
			}
		}

		// Find current segment
		const currentIndex = milestones.findIndex((m, i) => {
			const nextMilestone = milestones[i + 1]?.pointsThreshold || Infinity
			return value >= m.pointsThreshold && value < nextMilestone
		})

		const currentMilestone = milestones[currentIndex]
		const nextMilestone = milestones[currentIndex + 1]

		// Calculate progress within current segment
		const segmentProgress =
			(value - currentMilestone.pointsThreshold) /
			(nextMilestone.pointsThreshold - currentMilestone.pointsThreshold)

		const segmentWidth = MIDDLE_WIDTH / (milestones.length - 1)
		const fullFilledSegmentWidth = segmentWidth * currentIndex
		const currentSegmentWidth = segmentProgress * segmentWidth
		const fillWidth =
			X_SEGMENT_WIDTH + fullFilledSegmentWidth + currentSegmentWidth

		return {
			fillStyle: {
				width: `${fillWidth}px`
			},
			nextMilestoneValue: nextMilestone.pointsThreshold
		}
	}

	const { fillStyle, nextMilestoneValue } = getSegmentProgress(value)

	return (
		<div
			className="relative h-14 w-full"
			style={
				{
					'--light-blue': '#2196F3',
					'--dark-blue': '#1A364D'
				} as CSSProperties
			}
		>
			<div className="h-2 w-full bg-[var(--dark-blue)]">
				<div
					className="h-full bg-[var(--light-blue)] transition-all duration-300"
					style={fillStyle}
				/>
			</div>

			<div
				className="absolute left-0 top-0 flex w-full -translate-y-3 justify-between"
				style={{ padding: `0 ${EDGE_WIDTH}px` }}
			>
				{milestones.map(milestone => (
					<div
						key={milestone?.id}
						className="flex flex-col items-center"
						style={{ maxWidth: `${POINT_WIDTH}px` }}
					>
						<div
							className={cn(
								milestoneStyles,
								value >= milestone?.pointsThreshold
									? 'border-[var(--light-blue)] bg-[var(--light-blue)] text-white'
									: 'border-[var(--dark-blue)] border-opacity-0 bg-[var(--dark-blue)] text-opacity-25',
								{
									'text-opacity-75':
										nextMilestoneValue === milestone?.pointsThreshold
								}
							)}
							style={{
								width: `${POINT_WIDTH}px`,
								height: `${POINT_WIDTH}px`
							}}
						>
							{abbreviateNumberFixed(milestone?.pointsThreshold, 0)}
						</div>
						<span
							className={cn(
								value >= milestone?.pointsThreshold
									? 'mt-3 rounded-md bg-[#1D6197] px-2 py-1 font-inter text-xs font-medium leading-4 text-white shadow-sm'
									: 'mt-3 rounded-md bg-[#1D6197] px-2 py-1 font-inter text-xs font-medium leading-4 text-white text-opacity-25 shadow-sm',
								{
									'text-opacity-75':
										nextMilestoneValue === milestone?.pointsThreshold
								}
							)}
						>
							{milestone?.bonus}%
						</span>
					</div>
				))}
			</div>
		</div>
	)
}
