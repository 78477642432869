import { PackagePlus, CircleCheckBig, PartyPopper } from 'lucide-react'
import { Perk } from './SuperfanBenefitsDialog'
import { Countdown } from '@/components/common-ui'
import { openPerkDialog } from '@/store/modalSlice'
import { useDispatch } from 'react-redux'

export const ActivePerkItem = ({
	perk,
	userRank
}: {
	perk: Perk
	userRank: number
}) => {
	const dispatch = useDispatch()

	if (!perk?.qty || !perk?.endDate) return null
	
	const handleViewPerk = () => {
		dispatch(openPerkDialog({ image: perk?.image || '' }))
	}
	return (
		<div className="flex flex-col gap-1" key={perk?.id}>
			<div className="relative rounded-lg bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] p-[1px]">
				<div className="rounded-lg bg-[#0A1C2A]">
					<div className="relative flex gap-4 rounded-lg bg-[#2196F3]/10 px-3 py-3">
						<PackagePlus className="h-10 w-10" strokeWidth={0.75} />
						<div className="flex flex-1 flex-col items-start">
							<div className="flex items-center gap-2">
								<span className="text-sm text-white">
									<span className="font-bold">{perk?.name}</span>
								</span>
							</div>
							<div className="flex items-center gap-2">
								<span className="text-sm font-bold text-white">
									{perk?.qty} available
								</span>
							</div>
						</div>
						<button
							onClick={handleViewPerk}
							className="rounded-[0.4rem] bg-[#134872]/50 px-4 py-0 text-sm text-white"
						>
							View
						</button>
					</div>
				</div>
			</div>
			{perk?.qty >= userRank && userRank !== 0 ? (
				<div className="mt-1 flex items-center justify-between gap-1 text-xs text-[#3CBB5B]">
					<div className="flex">
						<PartyPopper className="mr-1 h-5 w-5" />
						<p className="m-0 text-sm text-white">
							Maintain your rank to snag this perk.
						</p>
					</div>
					<Countdown
						endDate={perk?.endDate}
						className="w-full text-right text-sm text-[#36BA3B] md:w-[150px]"
					/>
				</div>
			) : (
				<div className="mt-1 flex items-center justify-between gap-1 text-xs text-[#FF9A00]">
					<div className="flex">
						<CircleCheckBig className="mr-1 h-5 w-5" />
						<p className="m-0 text-sm text-white">
							Superfan rank <span className="font-bold">1 - {perk?.qty}</span>{' '}
							needed to snag
						</p>
					</div>
					<Countdown
						endDate={perk?.endDate}
						className="mt-1 text-xs text-[#36BA3B]"
					/>
				</div>
			)}
		</div>
	)
}
