import _ from 'lodash'
import { useEffect, useState } from 'react'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { addNewsreelService } from 'services/newsreel.service'
import useNotification from 'hooks/useNotification'
import { useDispatch, useSelector } from 'react-redux'
import useLoadingMask from 'hooks/useLoadingMask'
import { getProfileProjectListService } from 'services/profile.service'
import { Mixpanel } from 'services/mixpanel.service'
import { reloadFirtsPage } from '../newsreelPageSlice'

const useAddNewsreelForm = () => {
	const [media, setMedia] = useState([])
	const [errors, setErrors] = useState({})
	const [content, setContent] = useState('')
	const [parentOptions, setParentOptions] = useState([])
	const [selectedOption, setSelectedOption] = useState(null)
	const { user: signInUser } = useSelector(state => state.signIn)
	const { showNotification } = useNotification()
	const { showMask, hideMask } = useLoadingMask()
	const dispatch = useDispatch()

	const clearForm = () => {
		setContent('')
		setMedia([])
	}

	const [{ loading: loadingOptions }] = useAxiosFetch(
		getProfileProjectListService(),
		(data, error) => {
			if (!error) {
				const profileRecord = {
					id: 'profile',
					title: 'Profile',
					poster: signInUser?.avatar
				}
				setParentOptions([profileRecord, ...data])
				setSelectedOption('profile')
			}
		}
	)

	const [{ loading }, createNewsreel] = useAxiosFetch(
		addNewsreelService({
			media,
			content,
			projectId: selectedOption === 'profile' ? null : selectedOption
		}),
		afterCreateNewsreel
	)

	function afterCreateNewsreel(_data, error) {
		if (!error) {
			const projectId = selectedOption === 'profile' ? null : selectedOption
			Mixpanel.track('new_post_add_event', {
				distinct_id: signInUser.id,
				displayName: signInUser.displayName,
				username: signInUser.username,
				media,
				content,
				postContext: projectId ? 'project' : 'profile',
				projectId,
				id: _data.id
			})
			clearForm()
			dispatch(reloadFirtsPage())
			showNotification({ message: 'Newsreel added successfully' })
		}
	}

	const removeImg = idx => {
		setMedia(media.filter((_m, i) => i !== idx))
	}

	const handleContentChange = e => {
		setErrors({})
		setContent(e.target.value)
	}

	const handleAddNewsreel = () => {
		if (_.isNil(content) || content === '')
			setErrors({ content: 'Please add text before posting to your Newsreel.' })
		else createNewsreel()
	}

	const handleUploadClick = e => {
		const files = Array.from(e.target.files)
		setMedia([...media, ...files])
		e.target.value = null
	}

	const handleSelectedOption = value => {
		setSelectedOption(value)
	}

	useEffect(() => {
		if (loading || loadingOptions) showMask()
		else hideMask()
	}, [loading, loadingOptions])

	return {
		clearForm,
		removeImg,
		handleAddNewsreel,
		handleUploadClick,
		handleContentChange,
		handleSelectedOption,
		selectedOption,
		parentOptions,
		content,
		errors,
		media
	}
}

export default useAddNewsreelForm
