import { isNil } from 'lodash'
import { useSelector } from 'react-redux'
import { Box, Typography } from '@mui/material'
import styles from './FanPurchaseBanner.module.scss'

export const FanPurchaseBanner = () => {
	const { fanPurchaseTransaction } = useSelector(state => state.websocket)
	const progress = fanPurchaseTransaction?.progress

	if (isNil(fanPurchaseTransaction?.message)) {
		return null
	}

	return (
		<Box className={styles.fan_purchase_banner}>
			<Box className={styles.payment_content}>
				<Box
					className={`${styles.progress_bar} ${
						progress >= 100 ? styles.completed : ''
					}`}
				>
					<Box className={styles.progress} sx={{ width: `${progress}%` }}></Box>
					<Typography>{fanPurchaseTransaction?.message}</Typography>
				</Box>
			</Box>
		</Box>
	)
}
