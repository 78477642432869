import { TabsDashboard } from './main-content/tabsDashboard'
import { UserCard } from './main-content/userCard'

export const FanDashboard = () => {
	return (
		<div className="mt-6 space-y-6 p-2 md:mt-0 md:p-6">
			<UserCard />
			<TabsDashboard />
		</div>
	)
}
