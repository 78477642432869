export const getProfileUserById = (userId: string) => ({
	method: 'get',
	url: `/profile/${userId}`
})
export const getProfileByUsername = (username: string) => ({
	method: 'get',
	url: `/profile/username/${username}`,
	autoexec: true
})

export const getProfileEditUserById = (userId: string) => ({
	method: 'get',
	url: `/profile/edit/${userId}`,
	autoexec: true
})

export const getProfileProjectListService = () => ({
	method: 'get',
	url: '/profile/projects/list',
	autoexec: true
})

export const magicSignUpService = (data: any) => ({
	method: 'put',
	url: '/profile/magicSignUp',
	data
})

export const magicSignInService = (data: any) => ({
	method: 'put',
	url: '/profile/magicSignIn',
	data
})

export const ncMagicWalletClaimService = (data: any) => ({
	method: 'put',
	url: '/profile/ncMagicWalletClaim',
	data
})

export const magicEmailUpdateService = (data: any) => ({
	method: 'put',
	url: '/profile/magicEmailUpdate',
	data
})

export const isEmailAvailableService = (email: string) => ({
	method: 'get',
	url: `/profile/isEmailAvailable/${email}`
})

export const claimUserService = (data: any) => ({
	method: 'post',
	url: '/profile/claimUser',
	data
})

export const claimExistingSaleUserService = (data: any) => ({
	method: 'post',
	url: '/profile/claimExistingUser',
	data
})

export const completeKycService = (data: any) => ({
	method: 'post',
	url: '/profile/completeKyc',
	data
})

export const editProfileService = (data: any) => {
	const formData = new FormData()
	const projectFields = [
		'id',
		'email',
		'username',
		'displayName',
		'firstName',
		'lastName',
		'about',
		'countryId',
		'birthday',
		'website',
		'profession',
		'genderId',
		'headerImg',
		'avatar',
		'socialNetworks'
	]

	const files = ['avatar', 'headerImg']

	projectFields.forEach(key => {
		if (key === 'socialNetworks') {
			formData.append(key, JSON.stringify(data[key]))
		} else if (data[key] instanceof File) {
			formData.append(key, data[key], data[key].name)
		} else if (!files.includes(key)) {
			formData.append(key, data[key])
		}
	})

	return {
		method: 'put',
		url: '/profile',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		autoexec: false
	}
}

export const getProfileSettingsUserById = () => ({
	method: 'get',
	url: '/profile/settings'
})

export const validateEmailCodeService = (data: any) => ({
	method: 'post',
	url: '/profile/validateEmailCode',
	data
})

export const sendEmailCodeService = (data: any) => ({
	method: 'post',
	url: '/profile/sendEmailCode',
	data
})

export const updateProfileSettingsService = (data: any) => ({
	method: 'put',
	url: '/profile/settings',
	data,
	autoexec: false
})

export const getProfileTransactionsService = ({
	search,
	limit,
	offset,
	order,
	orderBy
}: {
	search: string
	limit: number
	offset: number
	order: string
	orderBy: string
}) => ({
	method: 'get',
	url: `/profile/transactions`,
	params: { search, limit, offset, order, orderBy }
})

export const getProfileStakes = (
	userId: string,
	limit: number,
	offset: number,
	order: string,
	orderBy: string,
	search: string
) => ({
	method: 'get',
	url: `/profile/stakes/${userId}`,
	params: { limit, offset, order, orderBy, search },
	autoexec: false
})

export const getProfileEarnings = (
	userId: string,
	limit: number,
	offset: number,
	order: string,
	orderBy: string,
	search: string
) => ({
	method: 'get',
	url: `/profile/earnings/${userId}`,
	params: { limit, offset, order, orderBy, search },
	autoexec: false
})

export const getProfileActionsEarnings = (
	userId: string,
	autoexec: boolean = true
) => ({
	method: 'get',
	url: `/profile/actionEarnings/${userId}`,
	autoexec
})

export const getProfileStakesTotal = (userId: string) => ({
	method: 'get',
	url: `/profile/stakesTotal/${userId}`,
	autoexec: true
})

export const getProfileBalanceVariance = (userId: string) => ({
	method: 'get',
	url: `/profile/balance/variance/${userId}`,
	autoexec: true
})

export const getProfileEarningsVariance = (userId: string) => ({
	method: 'get',
	url: `/profile/earnings/variance/${userId}`,
	autoexec: true
})

export const getLoggedInUserInfo = () => ({
	method: 'get',
	url: '/profile/loggedUserInfo'
})

export const getTeamUsers = (params: any) => ({
	method: 'get',
	url: '/profile/teamUsers',
	params,
	autoexec: true
})

export const donateToUser = (data: any) => ({
	method: 'post',
	url: '/profile/donate',
	data,
	autoexec: false
})

export const getProfileBalance = (userId: string) => ({
	method: 'get',
	url: `/profile/balance/${userId}`,
	autoexec: true
})

export const getProfileWalletOverview = (userId: string) => ({
	method: 'get',
	url: `/profile/wallet/overview/${userId}`
})

export const getHodl = () => ({
	method: 'get',
	url: `/profile/hodl`
})

export const donateToProject = (data: any) => ({
	method: 'post',
	url: '/profile/donateProject',
	data
})

export const updateUserFields = (data: any) => ({
	method: 'put',
	url: '/profile/fields',
	data
})

export const getWelcomeMessageService = () => ({
	method: 'get',
	url: `profile/welcomeMessage`
})

export const reconcileTransactionService = (transactionId: string) => ({
	method: 'post',
	url: `profile/reconcile/transaction`,
	data: { transactionId }
})

export const retryTransactionService = (transactionId: string) => ({
	method: 'post',
	url: `profile/retry/transaction`,
	data: { transactionId }
})

export const saleUserAccessClaimService = () => ({
	method: 'post',
	url: `profile/saleAccessClaim`
})

export const logoutService = () => ({
	method: 'post',
	url: `profile/logout`
})
