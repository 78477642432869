import {
	Typography,
	Box,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	TableHead
} from '@mui/material'
import { formatDate } from 'utils/date.utils'
import useContestTable, {
	ContestDialogState,
	NOT_JOINED_TEXT
} from './useContestTable'
import { JoinContestDialog } from '@/components/projectDetail/joinContestDialog/joinContestDialog'
import { getFileUrl } from 'utils/url.util'
import styles from './ContestTable.module.scss'

export default function ContestTable() {
	const {
		project,
		activeContestId,
		contestDialogState,
		openReadOnlyJoinContestDialog,
		openAgreementJoinContestDialog,
		closeJoinContestDialog,
		getContests
	} = useContestTable()
	const contests = getContests()

	const renderRows = (data = []) => {
		return data.map(row => {
			const {
				id,
				name,
				badge,
				joined,
				endDate,
				startDate,
				joinedDate,
				onlyAdminJoin
			} = row
			const isCloseEntry = !joined && onlyAdminJoin
			const joinEl = isCloseEntry ? (
				'Closed Entry'
			) : joined ? (
				'Joined'
			) : (
				<span onClick={openAgreementJoinContestDialog(id)}>Join</span>
			)
			return (
				<TableRow key={id} className={styles.head_cells}>
					<TableCell align="left" width="5%">
						<img src={getFileUrl(badge)} alt="contest-badge" />
					</TableCell>
					<TableCell align="left" width="16%" className={styles.highlight}>
						{name}
					</TableCell>
					<TableCell align="left" width="12%">
						{formatDate(startDate, 'med')}
					</TableCell>
					<TableCell align="left" width="12%">
						{formatDate(endDate, 'med')}
					</TableCell>
					<TableCell
						align="left"
						width="12%"
						className={joinedDate === NOT_JOINED_TEXT ? styles.disabled : ''}
					>
						{joinedDate}
					</TableCell>
					<TableCell
						align="left"
						width="12%"
						className={isCloseEntry ? styles.disabled : ''}
					>
						{isCloseEntry ? (
							'View'
						) : (
							<span onClick={openReadOnlyJoinContestDialog(id)}>View</span>
						)}
					</TableCell>
					<TableCell align="left" width="14%" maxwidth="18%">
						{joinEl}
					</TableCell>
				</TableRow>
			)
		})
	}

	if (contests.length === 0) return null

	return (
		<>
			<Box className={styles.contest_table}>
				<Box className={styles.header}>
					<Typography className={styles.title}>
						Below are the contests currently live on Film.io
					</Typography>
				</Box>
				<Box>
					<TableContainer className={styles.table_container}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell></TableCell>
									<TableCell align="left">Name</TableCell>
									<TableCell>Start Date</TableCell>
									<TableCell>End Date</TableCell>
									<TableCell>Joined</TableCell>
									<TableCell>Terms</TableCell>
									<TableCell>Join</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>{renderRows(contests)}</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Box>

			{project && (
				<JoinContestDialog
					projectId={project.id}
					contestId={activeContestId}
					open={contestDialogState !== ContestDialogState.CLOSED}
					readOnly={
						contestDialogState === ContestDialogState.OPENED_AS_READ_ONLY
					}
					handleClose={closeJoinContestDialog}
				/>
			)}
		</>
	)
}
