import {
	getMagicWalletAddress,
	sendContractBlockchainTransaction
} from '../magic'
import { ethers } from 'ethers'
import FanTokenContract from './fanToken'
import AddressLinkerContract from './addressLinker'
import { contractsConfig, defaultNetwork } from '../config/constants'
import {
	retrieveEventArgs,
	getTransactionReceipt,
	getTransactionFee,
	getCurrentActiveWalletAddress
} from '../config/utils'
import { retryWithDelay } from '../../utils'
import { truncateToDecimals } from 'utils/form.util'
import _ from 'lodash'

const isValidStakeAllowance = (walletAddress, oldAllowance) => async () => {
	const allowance = await FanTokenContract.allowance(
		walletAddress,
		StakingContract.address
	)
	if (allowance.toBigInt() <= oldAllowance.toBigInt()) {
		return Promise.reject(new Error('Allowance not updated yet'))
	}
	Promise.resolve(true)
}

class StakingContract {
	static contractName = 'staking'
	static address = contractsConfig.staking.address[defaultNetwork]

	// Write Functions
	static async stakeTokens(projectId, amount, transactionNote, signer = null) {
		const trancatedAmount = truncateToDecimals(amount, 4)
		let walletAddress = signer?.account?.address
		if (_.isNil(signer)) {
			walletAddress = await getMagicWalletAddress()
		}

		const balance = await FanTokenContract.balanceOf(walletAddress)
		const weiAmount = ethers.utils.parseUnits(trancatedAmount.toString(), 8)
		if (balance.toBigInt() < weiAmount.toBigInt()) {
			throw new Error('Insufficient balance')
		}

		const allowance = await FanTokenContract.allowance(
			walletAddress,
			StakingContract.address
		)

		if (allowance.toBigInt() < weiAmount.toBigInt()) {
			const hash = await FanTokenContract.approve(
				StakingContract.address,
				Number(ethers.utils.formatUnits(balance.toString(), 8)),
				signer
			)
			await getTransactionReceipt(hash, defaultNetwork, 3)
			await retryWithDelay(isValidStakeAllowance(walletAddress, allowance))
		}

		return await sendContractBlockchainTransaction(
			StakingContract.contractName,
			'staketokens',
			['PRJ', projectId, weiAmount],
			transactionNote,
			signer
		)
	}

	static async getFeesStakeTokens(projectId, amount, signer) {
		const walletAddress = signer?.account?.address

		const balance = await FanTokenContract.balanceOf(walletAddress)
		const weiAmount = ethers.utils.parseUnits(amount.toString(), 8)
		if (balance.toBigInt() < weiAmount.toBigInt()) {
			throw new Error('Insufficient balance')
		}

		const allowance = await FanTokenContract.allowance(
			walletAddress,
			StakingContract.address
		)

		let approveFees = {
			transactionFee: 0,
			usdValue: 0
		}
		if (allowance.toBigInt() < weiAmount.toBigInt()) {
			approveFees = await FanTokenContract.getFeesApprove(
				StakingContract.address,
				Number(ethers.utils.formatUnits(balance.toString(), 8)),
				signer
			)
		}

		const stakingFees = await getTransactionFee(
			signer,
			StakingContract.contractName,
			'staketokens',
			['PRJ', projectId, weiAmount]
		)

		return {
			transactionFee: stakingFees.transactionFee + approveFees.transactionFee,
			usdValue: stakingFees.usdValue + approveFees.usdValue
		}
	}

	static async unstakeTokens(
		stakeTransactionHash,
		transactionNote,
		signer = null
	) {
		const { stakeId } =
			await StakingContract.getStakeDetails(
				stakeTransactionHash,
				signer
			)

		return await sendContractBlockchainTransaction(
			StakingContract.contractName,
			'unstaketokens',
			[stakeId],
			transactionNote,
			signer
		)
	}

	static async getFeesUnstakeTokens(stakeTransactionHash, signer) {
		const { stakeId } = await StakingContract.getStakeDetails(
			stakeTransactionHash
		)

		return await getTransactionFee(
			signer,
			StakingContract.contractName,
			'unstaketokens',
			[stakeId]
		)
	}

	// Read Functions
	static async getStakeDetails(stakeTransactionHash, signer) {
		const walletAddress = await getCurrentActiveWalletAddress(signer)

		const receipt = await getTransactionReceipt(stakeTransactionHash)

		let rawStakeDetails = await retrieveEventArgs(
			stakeTransactionHash,
			'StakeTokens',
			contractsConfig.staking.abi,
			receipt
		)
		let stakeDetails

		// old event scheme
		if (_.isNil(rawStakeDetails) || rawStakeDetails.length === 0) {
			rawStakeDetails = await retrieveEventArgs(
				stakeTransactionHash,
				'StakeTokens',
				contractsConfig.staking.abiV1,
				receipt
			)

			if (rawStakeDetails.length !== 1) {
				const originWallet = await AddressLinkerContract.getOriginWallet(
					walletAddress
				)
				rawStakeDetails = rawStakeDetails.filter(item => {
					return item[0]?.toLowerCase() === originWallet?.toLowerCase()
				})[0]
			} else {
				rawStakeDetails = rawStakeDetails[0]
			}

			stakeDetails = {
				user: rawStakeDetails[0],
				stakeId: rawStakeDetails[1],
				amount: rawStakeDetails[2]
				// to: rawStakeDetails[3],
				// timestamp: rawStakeDetails[4]
			}
		}
		// new event scheme
		else {
			if (rawStakeDetails.length !== 1) {
				const originWallet = await AddressLinkerContract.getOriginWallet(
					walletAddress
				)
				rawStakeDetails = rawStakeDetails.filter(item => {
					return item[0]?.toLowerCase() === originWallet?.toLowerCase()
				})[0]
			} else {
				rawStakeDetails = rawStakeDetails[0]
			}

			stakeDetails = {
				user: rawStakeDetails[0],
				stakeId: rawStakeDetails[1],
				// typeId: rawStakeDetails[2],
				// stakeType: rawStakeDetails[3],
				amount: rawStakeDetails[4]
				// to: rawStakeDetails[5],
				// timestamp: rawStakeDetails[6]
			}
		}

		return stakeDetails
	}
}

export default StakingContract
