import Persona from 'persona'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formatDate } from 'utils/date.utils'
import useLoadingMask from './useLoadingMask'
import useAxios from './useAxios'
import { completeKycService } from 'services/profile.service'
import useNotification from './useNotification'
import useAxiosFetch from './useAxiosFetch'
import { calculateUserDaoTierProgress } from 'services/daoTier.service'
import { setTierProgress, updateSignInUser } from '@/components/header/signInSlice'
import { reloadDaoTiers } from '@/components/profile/profileSlice'
import useHeader from '@/components/header/useHeader'

const kycTemplateId = process.env.NEXT_PUBLIC_KYC_TEMPLATE_ID
const kycEnv = process.env.NEXT_PUBLIC_KYC_ENVIRONMENT

const useKyc = ({ onFailKyc, onSuccessKyc } = {}) => {
	const { user: signInUser } = useSelector(state => state.signIn)
	const personaKycRef = useRef(null)
	const { showMask, hideMask } = useLoadingMask()
	const { axiosService } = useAxios()
	const { showNotification } = useNotification()
	const { loadUserTierInfo } = useHeader()
	const dispatch = useDispatch()

	const [, refreshProgress] = useAxiosFetch(
		calculateUserDaoTierProgress(false),
		(data, error) => {
			if (!error) {
				dispatch(setTierProgress(data.progress))
			}
		}
	)

	const onKycComplete = data => {
		showMask()
		const { inquiryId } = data
		axiosService(completeKycService({ inquiryId }), (data, error) => {
			if (error) {
				showNotification({ message: 'Verification failed!' })
				hideMask()
				onFailKyc && onFailKyc()
				return
			}
			dispatch(updateSignInUser({ isVerified: data.isVerified }))
			showNotification({ message: 'Verification completed successfully!' })
			refreshProgress()
			loadUserTierInfo()
			dispatch(reloadDaoTiers())
			hideMask()
			onSuccessKyc && onSuccessKyc()
		})
	}

	const showKyc = () => {
		let personaClient = personaKycRef.current
		if (!personaClient) {
			personaClient = new Persona.Client({
				templateId: kycTemplateId,
				referenceId: signInUser.id,
				environmentId: kycEnv,
				onComplete: onKycComplete,
				onCancel: () => {
					onFailKyc && onFailKyc()
				},
				fields: {
					nameFirst: signInUser?.firstName,
					nameLast: signInUser?.lastName,
					birthdate: formatDate(signInUser.birthday, 'finputDate'),
					addressCountryCode: signInUser.countryId,
					emailAddress: signInUser.email
				}
			})
			personaKycRef.current = personaClient
		}

		personaClient.open()
	}

	useEffect(() => {
		return () => {
			const personaKyc = personaKycRef.current
			if (!personaKyc) {
				return
			}
			personaKyc.destroy()
			personaKycRef.current = null
		}
	}, [])

	return { showKyc }
}

export default useKyc
