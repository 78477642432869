import React, { forwardRef, useState } from 'react'
import { Icon } from '@mui/material'
import { Link, type LinkProps } from 'react-router-dom'
import { type ComponentPropsWithoutRef } from 'react'
import { cn } from '../../lib/utils'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
	TooltipArrow
} from '@/components/ui/tooltip'
import { HeartFilledIcon, HeartIcon } from '@radix-ui/react-icons'
import { NumericFormat } from 'react-number-format'
import { LoaderCircle, ShareIcon, StarIcon } from 'lucide-react'
import { StakeFormDialog } from '../common-ui/stakeFormDialog/StakeFormDialog'
import { ShareMenu } from '../common-ui/shareMenu'
import { Project } from './useProjectPage'
import { FollowProjectWrapper } from '../common-ui/followProjectWrapper/FollowProjectWrapper'
import { setLoginDialogOpen } from '../header/signInSlice'
import { followUnfollowProject } from '@/services/followUnfollow.service'
import { useSelector } from 'react-redux'
import useProjectCommon from './useProjectCommon'
import { RootState } from '@/store/store'
import { useDispatch } from 'react-redux'
import useHeader from '../header/useHeader'

const Divider = () => <div className="w-px bg-[#1b568c]" />

interface StatsBarProps {
	className?: string
	project: Project
	isFollowedByUser: boolean
	likes: number
	stakesTotal: number
	reviewsAvg: number
	socialMediaShares: number
	onTabChange: (_tab: string) => void
}

interface StatsButtonProps extends ComponentPropsWithoutRef<'button'> {
	icon: React.ReactNode
	label: React.ReactNode
	tooltip?: string // Add optional tooltip prop
}

interface StatsLinkProps extends Omit<LinkProps, 'to'> {
	icon: React.ReactNode
	label: React.ReactNode
	to: string
	tooltip?: string // Add optional tooltip prop
}

// Updated styles with focus and active states
const statsItemStyles = cn(
	// Base styles
	'flex flex-1 items-center justify-center font-bold',
	'text-gray-200 text-base py-2 md:py-4',
	// Hover state
	'hover:bg-gray-700/50',
	// Focus visible state (keyboard navigation)
	'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2',
	'focus-visible:ring-blue-500 focus-visible:ring-offset-[#182634]',
	// Active/pressed state
	'active:bg-gray-600/50',
	// Disabled state
	'disabled:pointer-events-none disabled:opacity-50',
	// Transition
	'transition-colors duration-200'
)

const StatsBar = ({
	className,
	project,
	isFollowedByUser,
	likes,
	stakesTotal,
	reviewsAvg,
	socialMediaShares,
	onTabChange
}: StatsBarProps) => {
	const dispatch = useDispatch()
	const { isAuthenticated } = useSelector((state: RootState) => state.signIn)
	const { loadUserTierInfo } = useHeader()
	const { reloadProject } = useProjectCommon()
	const [loadingFollow, setLoadingFollow] = useState(false)

	const updatefollowProject = async (
		projectId: string,
		followingProject: boolean
	) => {
		const res = await followUnfollowProject(projectId, followingProject)
		if (res.status === 200) {
			reloadProject?.()
			loadUserTierInfo()
		}
	}

	const handleFollowUnfollowProjectClick = async () => {
		if (!isAuthenticated) {
			dispatch(setLoginDialogOpen(true))
			return
		}
		if (!project) return
		setLoadingFollow(true)
		const followingProject = project.isFollowedByUser
		await updatefollowProject(project.id, followingProject)
		setLoadingFollow(false)
	}

	return (
		<TooltipProvider>
			<div
				className={cn(
					'rounded-lg border border-[#1b568c] bg-[#182634]',
					className
				)}
			>
				<div className="flex justify-between">
					<FollowProjectWrapper
						project={project}
						onConfirm={handleFollowUnfollowProjectClick}
					>
						{!loadingFollow ? (
							<StatsButton
								tooltip="Like"
								icon={
									isFollowedByUser ? (
										<HeartFilledIcon className="mr-1 h-4 w-4" />
									) : (
										<HeartIcon className="mr-1 h-4 w-4" />
									)
								}
								label={
									<NumericFormat
										value={likes ?? 0}
										displayType="text"
										thousandSeparator=","
									/>
								}
							/>
						) : (
							<StatsButton
								tooltip="Loading..."
								icon={<LoaderCircle className="mr-1 h-4 w-4 animate-spin" />}
								label=""
							/>
						)}
					</FollowProjectWrapper>

					<Divider />

					<StakeFormDialog
						trigger={
							<StatsButton
								tooltip="Stake"
								icon={
									<Icon
										baseClassName="fa-kit"
										className="fa-fan-stake mr-1 mt-1 !h-4 !w-4"
									/>
								}
								label={
									<NumericFormat
										value={stakesTotal ?? 0}
										displayType="text"
										thousandSeparator=","
									/>
								}
							/>
						}
						selectedProject={{
							id: project.id,
							title: project.title,
							stageId: project.stageId,
							user: { id: project.user.id }
						}}
					/>

					<Divider />

					<StatsButton
						tooltip="Leave review"
						icon={<StarIcon className="mr-1 h-4 w-4" />}
						label={`${reviewsAvg ?? 0}`}
						onClick={() => onTabChange('reviews')}
					/>

					<Divider />

					<ShareMenu
						project={{
							title: project.title,
							shortUrl: project.shortUrl,
							poster: project.poster ?? ''
						}}
						trigger={
							<StatsButton
								tooltip="Share"
								icon={<ShareIcon className="mr-1 h-4 w-4" />}
								label={
									<NumericFormat
										value={socialMediaShares}
										displayType="text"
										thousandSeparator=","
									/>
								}
							/>
						}
					/>
				</div>
			</div>
		</TooltipProvider>
	)
}

const StatsButton = forwardRef<HTMLButtonElement, StatsButtonProps>(
	({ icon, label, tooltip, className, ...props }, ref) => (
		<Tooltip>
			<TooltipTrigger asChild>
				<button ref={ref} className={cn(statsItemStyles, className)} {...props}>
					{icon}
					{label}
				</button>
			</TooltipTrigger>
			<TooltipContent
				className="bg-background p-3 text-white"
				side="top"
				align="center"
				sideOffset={-16}
			>
				<TooltipArrow className="fill-background" />
				{tooltip}
			</TooltipContent>
		</Tooltip>
	)
)

const StatsLink = forwardRef<HTMLAnchorElement, StatsLinkProps>(
	({ icon, label, tooltip, className, ...props }, ref) => (
		<Tooltip>
			<TooltipTrigger asChild>
				<Link ref={ref} className={cn(statsItemStyles, className)} {...props}>
					{icon}
					{label}
				</Link>
			</TooltipTrigger>
			{tooltip && <TooltipContent>{tooltip}</TooltipContent>}
		</Tooltip>
	)
)

StatsButton.displayName = 'StatsButton'
StatsLink.displayName = 'StatsLink'

export { StatsBar, StatsButton, StatsLink }
export type { StatsBarProps, StatsButtonProps, StatsLinkProps }
