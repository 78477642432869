import {
	Box,
	Button,
	Container,
	Grid,
	Icon,
	IconButton,
	Typography,
	Avatar
} from '@mui/material'
import { Link } from 'react-router-dom'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { VaultlockPopover } from '@/components/projectDetail/vaultlockPopover'
import { ImageWithViewer, LightTooltip } from '@/components/common'
import { Medias } from '@/components/projectDetail/dashboardTab/medias'
import { TrailerDialog } from '@/components/projectDetail/dashboardTab/trailerDialog'
import { SuperfanStatic } from '@/components/projectDetail/dashboardTab/superfan'
import { GoScoreBadgesStatic } from '@/components/projectDetail/dashboardTab/goScoreBadges'
import { RecentActivityStatic } from '@/components/projectDetail/dashboardTab/recentActivity'
import useSocialJoin from './useSocialJoin'
import styles from './SocialJoin.module.scss'

const SocialJoin = () => {
	const {
		project,
		storyRef,
		showMore,
		trailerInfo,
		storyHeight,
		isAuthenticated,
		handleOpenJoinDialog,
		showMoreToggle
	} = useSocialJoin()

	const displayName = project?.user?.displayName

	const {
		type,
		story,
		title,
		likes,
		poster,
		genres,
		logline,
		coverImg,
		thumbnail,
		stakesTotal,
		goScoreStage,
		sharerDisplayName,
		socialMediaShares,
		activities = [],
		vaultlockDate,
		reviewsCount,
		vaultlockId,
		vaultlockQR,
		badges
	} = project

	const buildCategoriesLine = () =>
		genres?.map(g => <li key={`genre-${g.id}`}>{g.name}</li>)

	return (
		<Container disableGutters maxWidth="md" className={styles.social_join}>
			{!isAuthenticated && (
				<Box className={styles.top_join}>
					<span>
						<strong>{sharerDisplayName}</strong> has invited you to join
						Film.io!{' '}
						<a onClick={handleOpenJoinDialog}>Create your account here.</a>
					</span>
				</Box>
			)}
			<Grid container className={styles.top_detail_list} spacing={4}>
				<Grid item xs={12} md={8}>
					<Grid item container md={12}>
						<Typography component="h2">{title}</Typography>
					</Grid>

					<Grid item md={12} className={styles.social_info}>
						<ul>
							<li>
								<IconButton>
									<FavoriteBorderIcon />
								</IconButton>
								<span>{likes}</span>
							</li>
							<li>
								<IconButton size="small">
									<Icon baseClassName="fas" className="fa-star-half-stroke" />
								</IconButton>
								<span>{reviewsCount}</span>
							</li>
							<li>
								<IconButton size="small">
									<Icon baseClassName="fas" className="fa-share" />
								</IconButton>
								<span>{socialMediaShares}</span>
							</li>
							<li>
								<IconButton size="small">
									<Icon baseClassName="fas" className="fa-gem" />
								</IconButton>
								<span>{stakesTotal}</span>
							</li>
							{vaultlockId && (
								<li>
									<VaultlockPopover
										title={title}
										date={vaultlockDate}
										vaultlockQR={vaultlockQR}
									/>
								</li>
							)}
						</ul>
					</Grid>
				</Grid>
				<Grid item md={4} className={styles.avatar_container}>
					<ul>
						<li>
							<Avatar
								alt={displayName}
								sx={{
									bgcolor: '#000000',
									border: '1px solid #ffffff'
								}}
								src={project?.user?.avatar}
							/>
						</li>
						<li className={styles.owner}>
							<LightTooltip title={`Visit ${displayName}'s profile`}>
								<Typography component={'h3'}>
									<Link to={`/profile/${project.user?.username}`}>
										{displayName}
										<br />
										<span>{project?.user?.profession}</span>
									</Link>
								</Typography>
							</LightTooltip>
						</li>
					</ul>
				</Grid>
			</Grid>

			<Grid item md={12} className={styles.cover_container} loading="lazy">
				<img src={coverImg} alt="cover" />
			</Grid>
			<Grid item container md={12} className={styles.header_title}>
				<Grid item md={6}>
					<Typography className={styles.greenlightHeader}>
						Help Greenlight {title}{' '}
					</Typography>
				</Grid>
				<Grid item md={6}>
					<Typography className={styles.impactHeader}>
						Your Impact on this Project{' '}
					</Typography>
				</Grid>
			</Grid>
			<Grid item md={12} className={styles.header_outside}>
				<Grid item container md={12} className={styles.header}>
					<Grid item md={6} id="socialsBox" className={styles.socials}>
						<Box className={styles.top}>
							<Box className={styles.disabled}>
								<Button
									id="stakeButton"
									startIcon={
										<Icon baseClassName="fa-kit" className="fa-fan-stake" />
									}
								>
									Stake
								</Button>
							</Box>
							<Box className={styles.disabled}>
								<Button
									id="reviewButton"
									startIcon={<Icon baseClassName="fal" className="fa-star" />}
									disabled={true}
								>
									Review
								</Button>
							</Box>
							<Box className={styles.disabled}>
								<Button
									id="shareButton"
									startIcon={<Icon baseClassName="fal" className="fa-share" />}
								>
									Share
								</Button>
							</Box>
							<Box className={styles.disabled}>
								<Button
									id="donateButton"
									startIcon={
										<Icon
											baseClassName="fal"
											className="fa-circle-dollar-to-slot"
										/>
									}
								>
									Donate
								</Button>
							</Box>
						</Box>
						<Box>
							<Typography>
								<Icon baseClassName="fal" className="fa-circle-info" />
								By staking, reviewing and sharing {title}, you are helping to
								Greenlight this project.
							</Typography>
						</Box>
					</Grid>
					<SuperfanStatic />
				</Grid>
			</Grid>
			<Grid className={styles.column_holder} item container md={12} spacing={4}>
				<Grid className={styles.project_info} item container xs={12} md={6}>
					<Grid item md={12} className={styles.projectinfo}>
						<h5>About the Project</h5>
						<ul className={styles.categories}>
							<li
								key="goscore"
								style={{ backgroundColor: goScoreStage?.color }}
							>
								{goScoreStage?.name}
							</li>
							{type && <li key="type">{type.name}</li>}
							{buildCategoriesLine()}
						</ul>

						<Box className={styles.media}>
							<Typography component={'h3'}>Media</Typography>
							<Medias {...{ items: [poster, coverImg, thumbnail] }}>
								{trailerInfo?.thumbnail_url && (
									<TrailerDialog
										{...{
											thumbnail: trailerInfo?.thumbnail_url,
											trailerSrc: trailerInfo?.player_embed_url
										}}
									/>
								)}
								{poster && <ImageWithViewer src={poster} />}
								{coverImg && <ImageWithViewer src={coverImg} />}
								{thumbnail && <ImageWithViewer src={thumbnail} />}
							</Medias>
						</Box>

						<Box
							className={
								showMore ? styles.about : `${styles.about} ${styles.showMore}`
							}
						>
							<Typography component={'h3'}>Logline</Typography>
							<Typography>{logline}</Typography>
							<Box>
								<Typography component={'h3'}>Story</Typography>
							</Box>
							<Box
								ref={storyRef}
								dangerouslySetInnerHTML={{ __html: story }}
							></Box>
						</Box>
						{storyHeight > 290 && (
							<Box
								className={`${styles.show_about} ${
									showMore ? '' : styles.show_more
								}`}
							>
								<Button
									variant="outlined"
									size="small"
									onClick={showMoreToggle}
								>
									{showMore ? 'LESS' : 'MORE'}
								</Button>
							</Box>
						)}
					</Grid>
				</Grid>
				<Grid item container md={6}>
					<Grid item md={12} className={styles.badges_achievements}>
						<Grid item md={12} className={styles.badges}>
							<Typography component={'h3'}>
								{'Badges & Achievements'}
							</Typography>
							<GoScoreBadgesStatic badges={badges ?? []} />
						</Grid>
						<Grid item md={12} className={styles.recent_activity}>
							<Typography component={'h3'}>Recent Activity</Typography>
							<RecentActivityStatic {...{ activities, title }} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	)
}

export default SocialJoin
