import useProjectNewsreelForm from './useProjectNewsreelForm'
import { CustomTextArea } from '@/components/common-ui/customTextArea'

const renderThumbnails = (
	files: File[],
	removeImg: (_index: number) => void
) => {
	const urlList = files.map(file => URL.createObjectURL(file))
	return urlList.map((src, i) => (
		<div
			className="relative mb-1.5 mr-3 flex h-[100px] w-[100px] items-center justify-center overflow-hidden rounded-md bg-[#21202e]"
			key={`thumbnail-${i}`}
		>
			<button
				className="absolute right-[-3px] top-[-3px] p-2 text-white/35 hover:text-white/50"
				onClick={() => removeImg(i)}
			>
				<i className="fas fa-light fa-xmark" />
			</button>
			<img src={src} alt="" className="w-full" />
		</div>
	))
}

const ProjectNewsreelForm = () => {
	const {
		isCreator,
		removeImg,
		handleContentChange,
		handleAddNewsreel,
		handleUploadClick,
		clearForm,
		content,
		errors,
		media
	} = useProjectNewsreelForm()

	if (!isCreator) return null

	return (
		<div className="w-full rounded-md bg-[#192531] p-4">
			<h5>New Post</h5>
			<div className="post-section space-y-2">
				<CustomTextArea
					className="w-full rounded-xl border border-[#1b568c] bg-[#0A1C2A] p-4 font-inter text-base text-white placeholder:text-white/50 focus:outline-none focus:ring-0"
					error={errors?.content}
					aria-label="What say you?"
					placeholder="What say you?"
					value={content}
					onChange={handleContentChange}
				/>
				<div className="flex flex-row flex-wrap">
					{renderThumbnails(media, removeImg)}
				</div>
				<div className="post-group flex items-center justify-between">
					<label
						className="cursor-pointer p-2 hover:text-gray-300"
						htmlFor="icon-button-file"
					>
						<input
							id="icon-button-file"
							type="file"
							className="hidden"
							accept="image/*"
							onChange={handleUploadClick}
							multiple
							autoFocus
						/>
						<i className="fas fa-light fa-images" />
					</label>
					<div className="flex gap-2">
						<button
							className="px-4 py-2 border border-[rgba(255, 255, 255, 0.5)] rounded-md text-sm hover:text-gray-300"
							onClick={clearForm}
						>
							Clear
						</button>
						<button
							onClick={handleAddNewsreel}
							className="square_button text-sm"
						>
							Post
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProjectNewsreelForm
