import _ from 'lodash'
import { createRef, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	addStakeService,
	getUserStakeLimitService
} from 'services/stake.service'
import useNotification from 'hooks/useNotification'
import { notificationExtTypes } from '@/components/common'
import useAxiosFetch from 'hooks/useAxiosFetch'
import useStakesTable from '../stakesTable/useStakesTable'
import { StakingContract } from 'utils/blockchain/functions'
import {
	addProjectsStakeInProgress,
	setCreatePlatformWalletDialogOpen,
	setFrontTransactionsInProgress
} from '@/components/header/signInSlice'
import useLoadingMask from 'hooks/useLoadingMask'
import useCommon from 'hooks/useCommon'
import { truncateToDecimals } from 'utils/form.util'
import useProjectCommon from '@/components/project/useProjectCommon'

export const useAddStakeForm = () => {
	const dispatch = useDispatch()
	const {
		user: signInUser,
		isAuthenticated,
		projectsStakeInProgress
	} = useSelector(state => state.signIn)
	const { project } = useProjectCommon()
	const { stakeCompletedEvent, stakeUnstakedEvent } = useSelector(state => state.websocket)
	const [amount, setAmount] = useState('')
	const [errors, setErrors] = useState(null)
	const [disableStake, setDisableStake] = useState(false)
	const [limit, setLimit] = useState(Number.MAX_SAFE_INTEGER)
	const [stakeLimit, setStakeLimit] = useState(0)
	const [failedStakesTotal, setFailedStakesTotal] = useState(0)
	const { showNotificationExtended } = useNotification()
	const { getProjectStakes } = useStakesTable()
	const inputRef = createRef()
	const { showMask, hideMask } = useLoadingMask()
	const { nonCustodialWithoutMatic, checkNcNoMagic } = useCommon()
	const isProjectOwner = signInUser?.id === project?.user?.id
	const stakeInProgress = (projectsStakeInProgress ?? []).includes(project.id)
	const projectId = project?.id

	const afterCreateStake = async (transaction, error) => {
		if (!error) {
			getProjectStakes()
			const transactionId = transaction?.id
			dispatch(setFrontTransactionsInProgress(true))
			hideMask()
			showNotificationExtended({
				projectTitle: project?.title,
				total: amount,
				type: notificationExtTypes.STAKING
			})
			handleRestoreForm()
			await StakingContract.stakeTokens(
				projectId,
				Number(amount),
				transactionId
			)
		}
	}

	const [{ loading: loadingConfig }, addStake] = useAxiosFetch(
		addStakeService({
			amount,
			projectId
		}),
		afterCreateStake
	)

	const [{ loading: loadingLimit }, getUserStakeLimit] = useAxiosFetch(
		getUserStakeLimitService(projectId),
		(data, error) => {
			if (!error) {
				setLimit(data?.limit)
				setStakeLimit(data?.stakeLimit)
				setFailedStakesTotal(data?.failedStakesTotal ?? 0)
			}
		},
		!isAuthenticated
	)

	const handleChangeForm = value => {
		const trancatedValue = truncateToDecimals(value, 4)
		setAmount(trancatedValue)
	}

	const handleRestoreForm = () => {
		setAmount('')
		inputRef ?? inputRef?.current?.focus()
	}

	const handleStakeClick = amount => async () => {
		if (checkNcNoMagic()) {
			dispatch(setCreatePlatformWalletDialogOpen(true))
		} else {
			setDisableStake(true)
			const isNonCustodialWithoutMatic = await nonCustodialWithoutMatic()
			if (isNonCustodialWithoutMatic) {
				return
			}
			if (amount === '') {
				setErrors('This field is required')
				setDisableStake(false)
				return
			}
			if (amount < 10) {
				setErrors('The minimun value allowed is 10')
				setDisableStake(false)
				return
			}
			showMask()
			setDisableStake(false)
			dispatch(addProjectsStakeInProgress(projectId))
			addStake()
		}
	}

	const handleKeypress = useMemo(() => {
		return _.debounce(e => {
			if (e.charCode === 13 && !isProjectOwner && !disableAddBtn) {
				setDisableStake(true)
				handleStakeClick(amount)()
			} else {
				setErrors(null)
				setDisableStake(false)
			}
		}, 500)
	}, [amount])

	const handleFocus = event => event.target.select()

	const handleMaxStake = () => {
		const rawAmount = Math.min(limit, signInUser.balance)
		const flooredAmount = Math.floor(rawAmount * 100) / 100
		setAmount(flooredAmount.toFixed(2))
	}

	const balance = Number(signInUser.balance).toFixed(2)
	const isAbleToStake = Number(signInUser.balance) >= Number(amount)
	const disableAddBtn =
		disableStake ||
		!isAbleToStake ||
		loadingConfig ||
		limit < amount ||
		stakeInProgress ||
		project?.stageId === 5

	useEffect(() => {
		if (!_.isNil(projectId)) getUserStakeLimit()
	}, [projectId])

	return {
		user: signInUser,
		limit,
		errors,
		amount,
		project,
		balance,
		inputRef,
		projectId,
		stakeLimit,
		signInUser,
		loadingLimit,
		isAbleToStake,
		disableAddBtn,
		isProjectOwner,
		getUserStakeLimit,
		failedStakesTotal,
		stakeCompletedEvent,
		stakeUnstakedEvent,
		handleRestoreForm,
		handleStakeClick,
		handleChangeForm,
		handleMaxStake,
		handleKeypress,
		handleFocus
	}
}
