import PropTypes from 'prop-types'
import { Grid, Button, Box, Avatar, Typography } from '@mui/material'
import {
	TextFieldControl,
	SelectBox,
	LoadMask,
	AutocompleteBox,
	CustomDialog
} from '@/components/common'
import useAddTeamForm from './useAddTeamForm'
import styles from './AddTeamForm.module.scss'
import { createRef, useEffect } from 'react'
import { getTeamUsers } from 'services/profile.service'

const AddTeamForm = () => {
	const {
		teams,
		errors,
		formData,
		showMask,
		departments,
		collaboratorRoles,
		showAddTeamMemberForm,
		handleCloseForm,
		handleChange,
		handleSave
	} = useAddTeamForm()

	const formRef = createRef()

	useEffect(() => {
		if (formRef.current && !showMask) formRef.current.scrollIntoView()
	}, [showMask])

	return (
		<>
			{showMask ? (
				<LoadMask />
			) : (
				<CustomDialog
					{...{
						open: showAddTeamMemberForm,
						handleClose: handleCloseForm,
						variant: 'join'
					}}
				>
					<Grid container spacing={2} className={styles.add_team_form}>
						<Grid item xs={12}>
							<h5>Add Cast & Crew</h5>

							<AutocompleteBox
								{...{
									error: errors.userId,
									label: 'Name*',
									variant: 'blue',
									service: getTeamUsers,
									setValue: userId => {
										handleChange({ userId })
									},
									value: formData.userId,
									renderOption: (props, option) => {
										return (
											<li
												{...props}
												key={option?.id}
												className={styles.team_user}
											>
												<Avatar
													alt={option?.name}
													sx={{
														bgcolor: '#000000',
														border: '1px solid #ffffff',
														height: 60,
														width: 60,
														marginRight: '12px'
													}}
													src={option?.image}
												/>
												<Box>
													<Typography component={'h2'}>
														{' '}
														{option?.name}
													</Typography>
													<Typography>@{option?.username}</Typography>
												</Box>
											</li>
										)
									}
								}}
							/>

							<SelectBox
								{...{
									label: 'Team',
									options: teams,
									value: formData.teamId,
									variant: 'blue',
									setValue: teamId => {
										handleChange({
											teamId: Number(teamId)
										})
									}
								}}
							/>

							{Number(formData.teamId) === 3 && (
								<SelectBox
									{...{
										error: errors.collaboratorRolId,
										label: 'Permission*',
										options: collaboratorRoles,
										value: formData.collaboratorRolId,
										variant: 'blue',
										setValue: collaboratorRolId => {
											handleChange({
												collaboratorRolId: Number(collaboratorRolId)
											})
										}
									}}
								/>
							)}

							<SelectBox
								{...{
									label: 'Department',
									options: departments,
									value: formData.departmentId,
									variant: 'blue',
									setValue: departmentId => {
										handleChange({ departmentId })
									}
								}}
							/>

							<TextFieldControl
								label="Role/Position"
								value={formData.position || ''}
								variant={'blue'}
								onChange={e => {
									handleChange({ position: e.target.value })
								}}
							/>

							<Box className={styles.actions}>
								<Button
									variant="text"
									className="cancel-button"
									onClick={handleCloseForm}
								>
									Cancel
								</Button>
								<Button className="yellow-bg-button" onClick={handleSave}>
									Add
								</Button>
							</Box>
						</Grid>
					</Grid>
				</CustomDialog>
			)}
		</>
	)
}

AddTeamForm.propTypes = {
	closeForm: PropTypes.func
}

export default AddTeamForm
