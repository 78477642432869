import React from 'react'
import { cn } from '../../lib/utils'

interface GradientStyles {
	from?: string
	to?: string
	height?: string
	width?: string
}

interface ScrollGradientProps {
	children: React.ReactNode
	direction?: 'vertical' | 'horizontal'
	className?: string
	contentClassName?: string
	containerRef?: React.RefObject<HTMLDivElement>
	gradientStyles?: GradientStyles
}

export function ScrollGradient({
	children,
	direction = 'vertical',
	className,
	contentClassName,
	containerRef: externalRef,
	gradientStyles = {}
}: ScrollGradientProps) {
	const localRef = React.useRef<HTMLDivElement>(null)
	const containerRef = externalRef || localRef
	const [showStartShadow, setShowStartShadow] = React.useState(false)
	const [showEndShadow, setShowEndShadow] = React.useState(false)

	const {
		from = 'from-black',
		to = 'to-transparent',
		height = 'h-6',
		width = 'w-6'
	} = gradientStyles

	const handleScroll = React.useCallback(() => {
		if (containerRef.current) {
			if (direction === 'vertical') {
				const { scrollTop, scrollHeight, clientHeight } = containerRef.current
				setShowStartShadow(scrollTop > 0)
				setShowEndShadow(scrollTop < scrollHeight - clientHeight - 1)
			} else {
				const { scrollLeft, scrollWidth, clientWidth } = containerRef.current
				setShowStartShadow(scrollLeft > 0)
				setShowEndShadow(scrollLeft < scrollWidth - clientWidth - 1)
			}
		}
	}, [direction, containerRef])

	React.useEffect(() => {
		if (containerRef.current) {
			if (direction === 'vertical') {
				const { scrollHeight, clientHeight } = containerRef.current
				setShowEndShadow(scrollHeight > clientHeight)
			} else {
				const { scrollWidth, clientWidth } = containerRef.current
				setShowEndShadow(scrollWidth > clientWidth)
			}
		}
	}, [direction, containerRef, children])

	return (
		<div className={cn('relative overflow-hidden', className)}>
			<div
				className={cn(
					'absolute z-10 transition-opacity duration-200',
					!showStartShadow && 'opacity-0',
					direction === 'vertical'
						? cn(
								'left-0 right-2 top-0',
								height,
								`bg-gradient-to-b ${from} ${to}`
							)
						: cn(
								'bottom-0 left-0 top-0',
								width,
								`bg-gradient-to-r ${from} ${to}`
							)
				)}
			/>

			<div
				ref={containerRef}
				onScroll={handleScroll}
				className={cn(
					'h-full w-full',
					direction === 'vertical' ? 'overflow-y-auto' : 'overflow-x-auto',
					contentClassName
				)}
			>
				{children}
			</div>

			<div
				className={cn(
					'absolute z-10 transition-opacity duration-200',
					!showEndShadow && 'opacity-0',
					direction === 'vertical'
						? cn(
								'bottom-0 left-0 right-2',
								height,
								`bg-gradient-to-t ${from} ${to}`
							)
						: cn(
								'bottom-0 right-0 top-0',
								width,
								`bg-gradient-to-l ${from} ${to}`
							)
				)}
			/>
		</div>
	)
}
