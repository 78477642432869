import { useQuery } from '@tanstack/react-query'
import {
	getProjectGoScoreMilestoneService,
	getProjectGoScoreService,
	getGoScoreConfigService
} from 'services/goscore.service'
import useAxios from 'hooks/useAxios'

export interface GoScoreConfig {
	id: number
	perfectScore: number
	goScoreStageAllowed: number
	reviewWeightage: number
	ratingWeightage: number
	fanTokenWeightage: number
	shareWeightage: number
	followersWeightage: number
	viewsWeightage: number
	fanTokenCatWeightage: number
	shareCatWeightage: number
	followersCatWeightage: number
	ratingreviewCatWeightage: number
	viewsCatWeightage: number
	decayPeriodDays: number
	decayRate: number
	bonusMultiplier: number
	benchmarkNewsreelPosts: number
	lastNewsreelWeight: number
	newsreelActivityWeight: number
	effectiveDate: string
	lowerThreshold: number
	preDecayDays: string
	postDecayDays: string
	testEmails: null
}

export interface MilestoneMetric {
	id: number
	name: string
	weight: number
	total: number
	metricId: number
	milestoneId: number
}

export interface Milestone {
	id: number
	name: string
	color: string
	milestoneMetrics: MilestoneMetric[]
	progress: number
}

export interface GoScoreData {
	id: string
	projectId: string
	fanScore: number
	shareScore: number
	ratingScore: number
	reviewRatingScore: number
	followersScore: number
	viewsScore: number
	goScore: number
	fes: number
	ces: number
	percentile: number
	percentage: number
	rank: number
	cesRank: number
	cesPercent: number
	decayPeriodDays: number
	createdAt: string
	updatedAt: string
	stakingPercent: number
	socialPercent: number
	projectPercent: number
	stakedImpact: number
	followersImpact: number
	sharesImpact: number
	ratingReviewImpact: number
	viewsImpact: number
	projectCount: number
	locked: boolean
}

/**
 * Custom hook to fetch and manage GoScore related data
 * @param projectId - The ID of the project
 * @returns Object containing GoScore data, config, milestones and calculated metrics
 */
export const useGoScore = (projectId: string | null) => {
	const { axiosServiceSync } = useAxios()

	const {
		data: goScoreConfig,
		isLoading: isConfigLoading,
		error: configError
	} = useQuery<GoScoreConfig>({
		queryKey: ['goScoreConfig'],
		queryFn: () => {
			const data = axiosServiceSync(getGoScoreConfigService())
			return data
		}
	})

	const {
		data: milestones,
		isLoading: isMilestonesLoading,
		error: milestonesError
	} = useQuery<Milestone[]>({
		queryKey: ['goScoreMilestones', projectId],
		queryFn: () => {
			const data = axiosServiceSync(
				getProjectGoScoreMilestoneService(projectId!)
			)
			return data
		},
		enabled: !!projectId
	})

	const {
		data: goScoreData,
		isLoading: isGoScoreLoading,
		error: goScoreError
	} = useQuery<GoScoreData>({
		queryKey: ['goScore', projectId],
		queryFn: () => {
			const data = axiosServiceSync(getProjectGoScoreService(projectId!))
			return data
		},
		enabled: !!projectId
	})

	/**
	 * Calculate creator percentage based on ces and fes values
	 */
	const calculateCreatorPercentage = (data: GoScoreData | undefined) => {
		if (!data?.ces) return 0
		if (data.ces < 1) return 0

		const ces = data.ces
		const fes = data.fes
		const finalGoScore = Math.round(ces * fes)

		if (finalGoScore <= 0) return 0
		return ((ces - 1) * fes * 100) / finalGoScore
	}

	const creatorPercent = goScoreData?.cesPercent
		? Math.round(100 - goScoreData.cesPercent)
		: 99

	const creatorPercentage = calculateCreatorPercentage(goScoreData)

	return {
		goScoreData,
		goScoreConfig,
		milestones,
		creatorPercent,
		creatorPercentage,
		isLoading: isConfigLoading || isMilestonesLoading || isGoScoreLoading,
		error: configError || milestonesError || goScoreError
	}
}
