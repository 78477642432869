import _ from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { CircularProgressBar } from '@/components/common/circularProgressBar'
import {
	getProjectGoScoreMilestoneService,
	getProjectGoScoreService
} from 'services/goscore.service'
import stageImages from 'assets/images/stages'
import styles from './GoScore.module.scss'
import { useSelector } from 'react-redux'
import useAxios from 'hooks/useAxios'

const GoScore = ({ color, showText = true, projectId = null }) => {
	const [data, setData] = useState({})
	const [isHover, setIsHover] = useState(false)
	const [milestones, setMilestones] = useState(false)
	const { projectId: pId } = useSelector(state => state.projectDetail)
	const { axiosService } = useAxios()
	const id = projectId ?? pId

	const getProjectGoScoreMilestone = () => {
		axiosService(getProjectGoScoreMilestoneService(id), (data, error) => {
			if (!error) {
				setMilestones(data)
			}
		})
	}

	const getProjectGoScore = () => {
		axiosService(getProjectGoScoreService(id), (data, error) => {
			if (!error) {
				setData(data)
			}
		})
	}

	useEffect(() => {
		if (!_.isNil(projectId)) {
			getProjectGoScoreMilestone()
			getProjectGoScore()
		}
	}, [projectId])

	const handleMouseEnter = () => {
		setIsHover(true)
	}

	const handleMouseLeave = () => {
		setIsHover(false)
	}

	return (
		<Box className={styles.container}>
			<Box className={styles.score_container}>
				<Box
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					className={styles.score}
					style={{
						boxShadow: isHover ? `0 0 0.9rem 0.4rem ${color}` : 'none'
					}}
				>
					<CircularProgressBar
						{...{
							height: 32,
							lineWidth: 2,
							lineColor: _.get(milestones, [0, 'color']),
							background: '#1d6878',
							lineEmptyColor: '#1c6878',
							time: 0.5,
							value: data?.stakingPercent,
							classes: `${styles.bar}`,
							id: 'stake'
						}}
					/>
					<CircularProgressBar
						{...{
							height: 30,
							lineWidth: 2,
							lineColor: _.get(milestones, [1, 'color']),
							background: '#1d6878',
							lineEmptyColor: '#1c6878',
							time: 0.5,
							value: data?.socialPercent,
							classes: `${styles.bar}`,
							id: 'social'
						}}
					/>
					<CircularProgressBar
						{...{
							height: 28,
							lineWidth: 2,
							lineColor: _.get(milestones, [2, 'color']),
							background: '#121212',
							lineEmptyColor: '#1c6878',
							time: 0.5,
							value: data?.projectPercent,
							classes: `${styles.bar}`,
							id: 'projectbar'
						}}
					/>
					<img
						alt=""
						src={stageImages.scoreRing}
						className={styles.ring_image}
					/>
					{data?.locked ? (
						<img
							alt=""
							src={stageImages.locked}
							className={styles.center_image}
						/>
					) : (
						<Typography className={styles.center_text}>
							{data.goScore && Math.round(data.goScore)}
						</Typography>
					)}
				</Box>
			</Box>
			{showText && (
				<Typography className={styles.bottom_text}>
					Go Score <sup>TM</sup>
				</Typography>
			)}
		</Box>
	)
}

GoScore.propTypes = {
	color: PropTypes.string,
	projectId: PropTypes.string,
	showText: PropTypes.bool
}

export default GoScore
