import { useDispatch } from 'react-redux'
import {
	setNewSuperfanEvent,
	setSuperfanPointsEarnedEvent,
	setSuperfanUpdatedEvent,
	SuperfanPointsEarnedEvent
} from '../webSocketSlice'
import { useToastNotification } from '@/components/common-ui'

const getSuperfanPointsEarnedMessage = (
	metricId: number,
	title: string,
	amount: number
) => {
	switch (metricId) {
		case 1:
			return `For following ${title}`
		case 2:
			return `For reviewing ${title}`
		case 3:
			return `For staking ${amount} to ${title}`
		case 4:
			return `For referring ${title}`
		case 5:
			return `For KYC verified referral on ${title}`
		case 6:
			return `For referral fan purchase on ${title}`
		case 7:
			return `For referral DAO on ${title}`
		case 8:
			return `For newsreel comment or reaction on ${title}`
		default:
			return null
	}
}

const useSuperfanHandlers = () => {
	const dispatch = useDispatch()
	const { showToast } = useToastNotification()
	const handleNewSuperfanNotification = (data: any) => {
		dispatch(setNewSuperfanEvent(data))
	}

	const handleSuperfanUpdatedNotification = (data: any) => {
		dispatch(setSuperfanUpdatedEvent(data))
	}

	const handleSuperfanPointsEarned = (data: SuperfanPointsEarnedEvent) => {
		const message = getSuperfanPointsEarnedMessage(
			data.metricId,
			data.title ?? '',
			data.amount
		)
		if (!message) return
		if (data?.isIncrease) {
			showToast({
				message,
				title: `You just earned ${data.points} Superfan Points!`,
				type: 'success',
				id: data?.id ?? ''
			})
		}
		dispatch(setSuperfanPointsEarnedEvent(data))
	}

	return {
		handleNewSuperfanNotification,
		handleSuperfanUpdatedNotification,
		handleSuperfanPointsEarned
	}
}

export default useSuperfanHandlers
