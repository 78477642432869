export const isValidEmail = email => {
	const emailLowercase = email.toLowerCase()
	const regex =
		/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
	return regex.test(emailLowercase)
}

export const buildFormData = (data, fields = [], files = [], objects = []) => {
	const formData = new FormData()

	fields.forEach(key => {
		if (data[key] !== undefined) formData.append(key, data[key])
	})
	files.forEach(key => {
		if (data[key] instanceof File) {
			formData.append(key, data[key], data[key]?.name)
		}
	})
	objects.forEach(key => {
		formData.append(key, JSON.stringify(data[key]))
	})
	return formData
}

export const truncateToDecimals = (num, decimals) => {
	const factor = Math.pow(10, decimals)
	return Math.floor(num * factor) / factor
}
