import { useDispatch } from 'react-redux'
import {
	setLoadingMessage,
	setShowLoading
} from '@/components/header/signInSlice'
import { useSelector } from 'react-redux'

const useLoadingMask = () => {
	const dispatch = useDispatch()
	const { showLoading } = useSelector(state => state.signIn)

	const showMask = (message = null) => {
		if (showLoading) {
			return
		}

		dispatch(setShowLoading(true))
		dispatch(setLoadingMessage(message))
	}

	const hideMask = () => {
		dispatch(setShowLoading(false))
	}

	return {
		showMask,
		hideMask
	}
}

export default useLoadingMask
