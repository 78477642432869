import { useDispatch } from 'react-redux'
import {
	// showNotification as showNotificationFn,
	updateExtended,
	closeNotificationExt
} from '@/components/common/notification/notificationSlice'
import { useNotificationDialog } from '@/components/common-ui/notificationDialog'

const useNotification = () => {
	const dispatch = useDispatch()
	const { notify } = useNotificationDialog()
	const showNotification = payload => {
		notify(payload)
		// dispatch(showNotificationFn(payload))
	}

	const closeNotificationExtended = () => {
		dispatch(closeNotificationExt())
	}

	const showNotificationExtended = payload => {
		dispatch(
			updateExtended({
				...payload,
				openExtended: true,
				completed: false
			})
		)
	}

	const completeNotificationExtended = (timeout = 3000) => {
		dispatch(
			updateExtended({
				completed: true
			})
		)
		setTimeout(() => {
			closeNotificationExtended()
		}, timeout)
	}

	return {
		showNotification,
		showNotificationExtended,
		completeNotificationExtended,
		closeNotificationExtended
	}
}

export default useNotification
