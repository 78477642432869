'use client'
import { useRef } from 'react'
import { Box, Icon, IconButton, Typography } from '@mui/material'
import {
	SnapList,
	SnapItem,
	useScroll,
	useDragToScroll,
	useVisibleElements
} from 'react-snaplist-carousel'
import { useSuperfanSection } from './useSuperfanSection'
import superfanPerkItem from 'assets/images/superfan/superfanPerkItem.svg'
import styles from './SuperfanSection.module.scss'

export const SuperfanSection = () => {
	const { superFanProjects, handleOpenSuperfanProjectsDialog } =
		useSuperfanSection()
	const snapList = useRef(null)
	const step = 4

	const visible = useVisibleElements(
		{ debounce: step, ref: snapList },
		([element]) => element
	)
	const goToSnapItem = useScroll({ ref: snapList })
	useDragToScroll({ ref: snapList, disabled: false })

	const handleNextClick = () => {
		goToSnapItem(visible + step)
	}

	const handlePrevClick = () => {
		goToSnapItem(visible > step ? visible - step : 0)
	}

	const renderItems = () => {
		return superFanProjects.map(project => {
			return (
				<SnapItem key={project?.slug} className={styles.snap_item}>
					<Box
						className={styles.superfan_perk_item}
						onClick={handleOpenSuperfanProjectsDialog(project)}
					>
						<img
							src={superfanPerkItem.src}
							alt="Superfan Perk Program Project"
						/>
						<Typography>{project?.title}</Typography>
					</Box>
				</SnapItem>
			)
		})
	}

	return (
		<Box className={styles.projects}>
			{visible > 0 && (
				<IconButton
					size="small"
					onClick={handlePrevClick}
					className={styles.prev}
				>
					<Icon baseClassName="fas" className="fa-chevron-left" />
				</IconButton>
			)}

			<SnapList ref={snapList} tabIndex={0}>
				{renderItems()}
			</SnapList>

			{visible < superFanProjects?.length - step && (
				<IconButton
					size="small"
					onClick={handleNextClick}
					className={styles.next}
				>
					<Icon baseClassName="fas" className="fa-chevron-right" />
				</IconButton>
			)}
		</Box>
	)
}
