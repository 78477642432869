import { Button } from '@/components/ui/button'
import { useSelector } from 'react-redux'
import React, { useState } from 'react'
import { Project } from '@/components/project/useProjectPage'
import { RootState } from '@/store/store'
import { AvatarFilmio } from '@/components/common-ui'
import medal from '@/assets/images/medal-2.svg'
import { MilestoneProgress } from '../milestoneProgress'
import { ArrowUpIcon, Share, Table, XIcon } from 'lucide-react'
import { SuperfanBenefits } from '../superfanBenefits/SuperfanBenefits'
import { SuperfanLeaderboardList } from '../superfanLeaderboard/SuperfanLeaderboardList'
import { YourImpactSection } from '../yourImpactSection/YourImpactSection'
import { ShareMenu } from '@/components/common-ui/shareMenu/ShareMenu'

interface Props {
	isOpen: boolean
	className?: string
	toggle: () => void
	project: Project
	handleSuperfanActionsClick: () => void
}

export const ProjectBecomeSuperfanContent = ({
	isOpen,
	toggle,
	project,
	handleSuperfanActionsClick
}: Props) => {
	const { user } = useSelector((state: RootState) => state.signIn)
	const { superfanStats, totalSuperfans } = project
	const [view, setView] = useState<'leaderboard' | 'milestone'>('milestone')

	/**
	 * Somehow when the dialog closes event bubbles up to the container
	 * and triggers the toggle function. This function prevents that.
	 * @param e
	 * @returns
	 */
	const handleContainerClick = (e: React.MouseEvent) => {
		// Get the target element
		const target = e.target as Element
		const isFromDialog = target.closest('[role="dialog"]') !== null
		if (isFromDialog) {
			e.stopPropagation()
			return
		}

		// Otherwise, toggle the slider
		toggle()
	}

	return (
		<div className="relative">
			<div
				className={`border-t-2 border-[#234a76] p-3`}
				onClick={handleContainerClick}
			>
				{/* Half-bordered Circle Arrow Container */}
				<div className="absolute -top-6 left-1/2 -translate-x-1/2">
					<div className="relative flex h-12 w-12 items-center justify-center rounded-full bg-gradient-to-b from-black from-[55%] to-transparent to-[45%] before:absolute before:inset-0 before:rounded-full before:border-2 before:border-[#234a76] before:[clip-path:polygon(0_0,_100%_0,_100%_54%,_0_54%)]">
						<ArrowUpIcon
							className={`z-20 -mt-2 h-5 w-5 text-white transition-transform duration-700 ease-in-out ${
								isOpen ? 'rotate-180' : ''
							}`}
						/>
					</div>
				</div>

				<div className="flex gap-3">
					<div className="flex h-12 flex-1 items-center gap-2 rounded border-2 border-[#234a76] bg-[#0d1e2f] px-4">
						<AvatarFilmio
							avatar={user?.avatar}
							displayName={user?.displayName}
							className="h-7 w-7 border border-[#2196F3]"
							textClassName="text-xs"
						/>
						<div className="flex items-center justify-center gap-2 font-inter text-base font-bold leading-7">
							<span className="text-white">Superfan</span>
							<span className="text-[#2196F3]">
								#{superfanStats?.rank} of {totalSuperfans}
							</span>
						</div>
					</div>
					<SuperfanBenefits
						projectId={project.id}
						isOnPerksProgram={project?.isOnPerksProgram}
					/>
				</div>
			</div>

			{/* Main Container */}
			<div className="space-y-4 px-3 pb-3">
				{/* Milestone Progress Section */}
				{view === 'milestone' ? (
					<div className="flex flex-col gap-4 pt-2">
						<div
							className="flex flex-col gap-6"
							onClick={handleSuperfanActionsClick}
						>
							<div className="flex items-center justify-between">
								<span className="font-inter text-sm font-medium text-white">
									Milestone Progress
								</span>
								<div className="flex items-center gap-1">
									<img src={medal?.src} alt="" className="z-10 h-8" />
									<span className="-ml-3 rounded-sm bg-[#2196F3] bg-opacity-40 px-2 py-1 font-inter text-sm font-bold text-white">
										{superfanStats?.totalPoints}
									</span>
								</div>
							</div>
							<MilestoneProgress {...{ value: superfanStats?.totalPoints }} />
						</div>

						{/* FGR Info */}
						<div className="rounded border border-[#234a76] px-3 py-2 text-center font-inter text-sm font-medium text-white">
							Your FGR: {user?.fgrRate}% +
							{project?.userProjectMetrics?.fanLevelBonus}% bonus
						</div>

						{/* Impact Stats */}
						<YourImpactSection />

						{/* Learn More Link */}
						<div className="flex items-center justify-center gap-4">
							<ShareMenu
								project={{
									title: project.title,
									shortUrl: project.shortUrl,
									poster: project.poster ?? ''
								}}
								trigger={
									<Button className="rounded-md bg-white px-3 text-sm font-medium leading-5 text-black hover:bg-white/90">
										<Share className="h-4 w-4" />
										Share
									</Button>
								}
							/>

							<Button
								className="rounded-md bg-[#2196F3] px-3 text-sm font-medium leading-5 text-black hover:bg-[#2196F3]/90"
								onClick={() => setView('leaderboard')}
							>
								<Table className="h-4 w-4" />
								Leaderboard
							</Button>
						</div>

						<div className="text-center" onClick={handleSuperfanActionsClick}>
							<p className="items-center justify-center gap-1 text-balance bg-gradient-to-r from-[#21F359] from-[17.11%] to-[#6B3BFA] to-[61.84%] bg-clip-text font-inter text-sm font-medium leading-4 text-transparent">
								Get Superfan points to climb the leaderboard and earn perks!
								<span className="ml-1 cursor-pointer font-inter text-sm font-medium leading-4 text-[#2196F3] hover:text-[#2196F3] hover:underline">
									Learn More
								</span>
							</p>
						</div>
					</div>
				) : (
					<div className="relative">
						<div className="absolute -right-3 -top-3">
							<Button variant="ghost" onClick={() => setView('milestone')}>
								<XIcon className="h-4 w-4" />
							</Button>
						</div>
						<div className="mt-6">
							<SuperfanLeaderboardList projectId={project.id} />
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
