import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'

export default function useProfileMenu() {
	const { user: signInUser } = useSelector((state: RootState) => state.signIn)
	const isDaoTierConfirmed = Number(signInUser?.daoTierConfirmed) === 1
	const isDaoTierCompleted = Number(signInUser?.daoTierCompleted) === 1
	const upgradeAvailable =
		(!isDaoTierConfirmed && isDaoTierCompleted) ||
		Number(signInUser?.nextTierCompleted) === 1

	return {
		signInUser,
		upgradeAvailable
	}
}
