import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isNil, pickBy } from 'lodash'

export type NotificationType = 'success' | 'error' | 'warning' | 'info'

interface NotificationState {
	isOpen: boolean
	type?: NotificationType
	title?: string
	message: string
	buttonText?: string
}

const initialState: NotificationState = {
	isOpen: false,
	type: 'success',
	title: undefined,
	message: '',
	buttonText: 'Thanks'
}

export const notificationSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		showNotification: (
			state,
			action: PayloadAction<Partial<NotificationState>>
		) => {
			const cleanPayload = pickBy(action.payload, value => !isNil(value))
			Object.assign(state, { ...initialState, isOpen: true, ...cleanPayload })
		},
		hideNotification: state => {
			state.isOpen = false
		}
	}
})

export const { showNotification, hideNotification } = notificationSlice.actions
export default notificationSlice.reducer
