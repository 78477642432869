import _ from 'lodash'
import { useState, useEffect, useRef } from 'react'
import axios, { AxiosResponse } from 'axios'
import useNotification from './useNotification'
import { notificationTypes } from '@/components/common'
import useLoadingMask from './useLoadingMask'
import { useNavigate } from 'react-router-dom'

const useAxiosFetch = (
	initialConfig: any,
	callback: any,
	stopRequest: any = false
): Array<any> => {
	const [response, setResponse] = useState<AxiosResponse | null>(null)
	const [error, setError] = useState('')
	const [loading, setLoading] = useState(Boolean(initialConfig.autoexec))
	const controller = useRef<AbortController | null>(null)
	const { showNotification } = useNotification()
	const { hideMask } = useLoadingMask()
	const navigate = useNavigate()

	// Function to include extra config in FormData
	const includeInFormData = (config: any, extraConfig: any) => {
		if (_.get(config, ['headers', 'Content-Type']) === 'multipart/form-data') {
			Object.keys(extraConfig).forEach(key => {
				config.data.set(key, extraConfig[key])
			})
		}
		return config
	}

	// Function to fetch data using axios
	const axiosFetch = async (extraConfig = {}) => {
		setLoading(true)
		controller.current = new AbortController()

		let finalConfig = _.merge({}, initialConfig, extraConfig) // Avoid mutating the original config

		if (
			_.get(finalConfig, ['headers', 'Content-Type']) === 'multipart/form-data'
		) {
			finalConfig = includeInFormData(finalConfig, extraConfig)
		}

		try {
			if (stopRequest) {
				setResponse(null)
			} else {
				const res = await axios({
					...finalConfig,
					signal: controller.current.signal
				})
				setResponse(res)
				const notFound = res.status === 404
				const existError = res.status !== 200

				// Navigate to not found page if 404 status
				if (notFound) {
					navigate('/notfound')
					hideMask()
					return
				}

				callback && callback(res.data, existError, error)
				if (res.data?.message && existError) {
					showNotification({
						title: res.data.title,
						message: res.data.message,
						type: notificationTypes.ERROR
					})
					hideMask()
				}

				return res
			}
		} catch (err: any) {
			setResponse(err.response)
			setError(err.message)
			callback && callback(null, true, err.message)
			if (err?.response?.data?.message) {
				showNotification({
					title: err?.response?.data?.title,
					message: err?.response?.data?.message,
					type: notificationTypes.ERROR
				})
				hideMask()
			}
		} finally {
			setLoading(false)
		}
	}

	// Function to cancel the axios call
	const cancelCall = () => {
		controller.current && controller.current.abort()
	}

	// Effect to auto-execute axiosFetch if autoexec is true
	useEffect(() => {
		if (initialConfig?.autoexec) {
			axiosFetch()
		}
	}, [initialConfig.autoexec])

	return [{ response, error, loading }, axiosFetch, cancelCall]
}

export default useAxiosFetch
