import {
	Box,
	Grid,
	Button,
	Typography,
	Dialog,
	DialogContent
} from '@mui/material'
import PropTypes from 'prop-types'
import GoScoreCircle from '../goScoreCircle/GoScoreCircle'
import styles from './ScoreDialogV2.module.scss'
import scoreDetailImages from 'assets/images/score-details'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material/styles'
import { GoScoreCreatorEngagementBar } from '../goScoreCreatorEngagementBar'
import { GoScoreImpactBar } from '../GoScoreImpactBar'
import FireIcon from 'assets/images/creatorType/fire.svg'

import _ from 'lodash'

const DialogWithbackground = styled(Dialog)(({ theme }) => ({
	backgroundColor: theme.palette.type === 'dark' ? '#000000b3' : '#000000b3',
	...theme.typography.body2
}))

const CustonButton = styled(Button)(({ theme }) => ({
	backgroundColor: theme.palette.type === 'dark' ? '#2E2C6A' : '#2E2C6A',
	...theme.typography.body2,
	fontSize: '16px',
	padding: theme.spacing(1),
	textAlign: 'center',
	width: '100%',
	color: theme.palette.primary.main,
	border: 'none',
	borderRadius: '25px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	marginBottom: '10px',
	height: '40px',
	transition: 'all 600ms ease-out !important',
	'&:hover': {
		background: '#332E82'
	}
}))

export const ScoreDialogV2 = ({
	open,
	handleClose,
	data,
	goScoreConfig,
	createrPercent,
	createrStatus
}) => {
	const { project } = useSelector(state => state.projectDetail)
	const topPercent = Math.round(100 - data?.percentile) + 1

	const calculateCESProgress = () => {
		if (
			_.isNil(data?.decayPeriodDays) ||
			_.isNil(goScoreConfig?.decayPeriodDays)
		)
			return 0
		if (data?.decayPeriodDays <= 0) return 100
		if (goScoreConfig?.decayPeriodDays <= 0) return 100
		if (data?.decayPeriodDays >= goScoreConfig?.decayPeriodDays) return 0
		return 100 - (data?.decayPeriodDays / goScoreConfig?.decayPeriodDays) * 100
	}

	const stakingPercent = data?.stakingPercent ?? 0
	const socialPercent = data?.socialPercent ?? 0
	const projectPercent = data?.projectPercent ?? 0
	const creatorPercent = data?.cesPercent
		? Math.round(100 - data?.cesPercent)
		: 99
	return (
		<DialogWithbackground
			open={open}
			disableScrollLock={true}
			className={`${styles.dialog}`}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleClose}
			onClick={e => {
				if (e.target === e.currentTarget) {
					handleClose()
				}
			}}
		>
			<DialogContent className={styles.dialog_content}>
				<Grid container className={styles.score_detail} spacing={2}>
					<Grid item xs={12} className={styles.title} container>
						<Typography component={'h1'}>Go Score Detail</Typography>
					</Grid>
					<Grid container item xs={12}>
						<Grid item xs={6} className={styles.legend}>
							<Box className={styles.legend_item}>
								<Typography>Staking</Typography>
								<Box className={styles.bg_green}></Box>
							</Box>
							<Box className={styles.legend_item}>
								<Typography>Social</Typography>
								<Box className={styles.bg_yellow}></Box>
							</Box>
							<Box className={styles.legend_item}>
								<Typography>Project</Typography>
								<Box className={styles.bg_blue}></Box>
							</Box>
							{data?.ces > 1 && (
								<Box className={styles.legend_item}>
									<Typography>Creator</Typography>
									<Box className={styles.bg_orange}></Box>
								</Box>
							)}
						</Grid>
						<Grid
							item
							xs={6}
							justifyContent={'center'}
							container
							className={styles.goScoreModal}
						>
							<GoScoreCircle
								{...{
									color: '#3FA8F4',
									showText: false,
									height: 140,
									lineWidth: 50
								}}
							/>
						</Grid>
						<Grid item xs={12} container>
							<Typography className={styles.explainerHeader}>
								Go Score Impact
							</Typography>
							<Typography className={styles.explainer}>
								This is a visual breakdown of how each metric category impacts
								the make up the Go Score.
							</Typography>
						</Grid>
						<Grid item xs={12} container>
							<GoScoreImpactBar
								value1={stakingPercent}
								value2={socialPercent}
								value3={projectPercent}
								value4={createrPercent}
							/>
						</Grid>
						<Grid item xs={12} className={styles.info_container}>
							<Box className={`${styles.info_item}`}>
								<Box className={styles.info_title}>
									<img alt="" src={scoreDetailImages.tokensV2} />
									<Typography>Staked Tokens</Typography>
								</Box>
								<Typography>{project?.stakesTotal}</Typography>
							</Box>
							<Box className={styles.info_item}>
								<Box className={styles.info_title}>
									<img alt="" src={scoreDetailImages.stakersV2} />
									<Typography>Unique Stakers</Typography>
								</Box>
								<Typography>{project?.stakersCount}</Typography>
							</Box>
							<Box className={`${styles.info_item}`}>
								<Box className={styles.info_title}>
									<img alt="" src={scoreDetailImages.commentsV2} />
									<Typography>Followers</Typography>
								</Box>
								<Typography>{project?.followersCount}</Typography>
							</Box>
							<Box className={styles.info_item}>
								<Box className={styles.info_title}>
									<img alt="" src={scoreDetailImages.sharesV2} />
									<Typography>Referrals</Typography>
								</Box>
								<Typography>{project?.socialMediaShares}</Typography>
							</Box>
							<Box className={styles.info_item}>
								<Box className={styles.info_title}>
									<img alt="" src={scoreDetailImages.ratingsV2} />
									<Typography className={styles.blue}>
										Ratings/Reviews
									</Typography>
								</Box>
								<Typography className={styles.blue}>
									{project?.reviewsCount}
								</Typography>
							</Box>
							<Box className={`${styles.info_item}`}>
								<Box className={styles.info_title}>
									<img alt="" src={scoreDetailImages.viewsV2} />
									<Typography className={styles.blue}>Views</Typography>
								</Box>
								<Typography className={styles.blue}>
									{project?.views}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} container>
							<Typography className={styles.explainerHeader}>
								Creator Engagement
							</Typography>
							<Typography className={styles.explainer}>
								This is a visual representation how the creator is involved with
								his project.
							</Typography>
							<GoScoreCreatorEngagementBar
								value={calculateCESProgress()}
								createrStatus={createrStatus}
							/>
						</Grid>
						<Grid item xs={12} className={styles.ranking}>
							{createrStatus !== 2 ? (
								<Typography className={styles.withFire}>
									{creatorPercent <= 3 && (
										<span className={styles.fire_container}>
											<img width={11} src={FireIcon.src} alt="fire" />{' '}
										</span>
									)}
									This project is the top {creatorPercent}% most active project
								</Typography>
							) : (
								<Typography className={styles.withFire}>
									This project is inactive
								</Typography>
							)}
							<Typography>
								Go Score Rank: {data?.rank} of {data?.projectCount} {'('}top{' '}
								{topPercent}%{')'}
							</Typography>
						</Grid>
						<Grid item xs={12} className={styles.custom_padding}>
							<CustonButton
								onClick={e => {
									e.stopPropagation()
									handleClose()
								}}
							>
								<Typography component={'h3'}>Close </Typography>
							</CustonButton>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</DialogWithbackground>
	)
}

ScoreDialogV2.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	data: PropTypes.object,
	goScoreConfig: PropTypes.object,
	createrPercent: PropTypes.number,
	createrStatus: PropTypes.number
}
