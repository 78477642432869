'use client'

import React from 'react'
import * as Toast from '@radix-ui/react-toast'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/store'
import { removeToast, toggleGlobalExpand } from './toastNotificationSlice'
import medal from '@/assets/images/medal-2.svg'
import toastBg from '@/assets/images/toast-bg.svg'
import { cn } from '@/lib/utils'
import { X } from 'lucide-react'

export const ToastNotificationStack = () => {
	const dispatch = useDispatch()
	const { notifications, globalExpanded } = useSelector(
		(state: RootState) => state.toastNotification
	)

	return (
		<Toast.Provider swipeDirection="right">
			{notifications.map((toast, index) => (
				<Toast.Root
					key={toast.id}
					duration={10000}
					open={true}
					onOpenChange={() => dispatch(removeToast(toast.id))}
					className={cn(
						'relative flex w-full max-w-md items-center gap-4 rounded-lg bg-[#1E1E1E] bg-opacity-90 p-4 text-white shadow-lg transition-all duration-300',
						index === 0 && !globalExpanded
							? 'absolute left-1/2 top-1 z-50 -translate-x-1/2'
							: '',
						index === 1 && !globalExpanded
							? 'absolute left-1/2 top-1 top-[30px] z-40 -translate-x-1/2 scale-[0.9] opacity-80'
							: '',
						index === 2 && !globalExpanded
							? 'absolute left-1/2 top-1 top-[56px] z-40 -translate-x-1/2 scale-[0.8] opacity-80'
							: ''
					)}
					style={{
						backgroundImage: `url(${toastBg.src})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						transformOrigin: 'top center'
					}}
					onClick={() => {
						// If not the top toast, bring it to the top
						if (index !== 0) {
							dispatch(toggleGlobalExpand())
							return
						}
					}}
				>
					<img src={medal.src} alt="medal" className="h-10" />
					<div className="flex flex-col gap-2">
						<Toast.Title className="text-sm font-bold">
							{toast.title}
						</Toast.Title>
						<Toast.Description
							className={cn(
								'flex-1',
								{
									'line-clamp-1': !(globalExpanded || toast.isExpanded)
								},
								'font-inter text-sm font-medium leading-[140%] text-white/50'
							)}
						>
							{toast.message}
						</Toast.Description>
					</div>
					<Toast.Close
						className="ml-auto rounded-full p-1 text-base text-white/50 hover:bg-blue-200/10"
						onClick={e => {
							e.stopPropagation()
							dispatch(removeToast(toast.id))
						}}
					>
						<X className="h-4 w-4" />
					</Toast.Close>
				</Toast.Root>
			))}

			<Toast.Viewport
				className={cn(
					'center top-right fixed left-1/2 top-1 z-[99] flex w-full max-w-lg -translate-x-1/2 flex-col items-center gap-[10px] md:left-auto md:right-4 md:z-[1000] md:max-w-md md:translate-x-0',
					{ 'flex-col-reverse': globalExpanded }
				)}
			/>
		</Toast.Provider>
	)
}
