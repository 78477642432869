import { logger } from "./logger"

export const getEmbedFromUrl = url => {
	if (!url) return null
	if (url.includes('youtu')) return getYoutubeEmbedFromUrl(url)
	if (url.includes('vimeo')) return getVimeoEmbedFromUrl(url)
}

export const getVideoInfoFromUrl = url => {
	if (!url) return null
	if (url.includes('youtu')) return getYoutubeInfo(url)
	if (url.includes('vimeo')) {
		const id = url.split('/').pop()
		return getVimeoInfo(id)
	}
}

export const getVimeoEmbedFromUrl = url => {
	const videoId = url.split('/').pop()
	return `https://player.vimeo.com/video/${videoId}?h=94ff479f07&title=0&byline=0&portrait=0`
}

export const getYoutubeEmbedFromUrl = url => {
	let videoId = url.split('v=').pop()
	const ampersandPosition = videoId.indexOf('&')

	if (ampersandPosition !== -1) {
		videoId = videoId.substring(0, ampersandPosition)
	} else {
		videoId = url.split('/').pop()
	}
	return `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`
}

export const getYoutubeInfo = async video => {
	if (!video || video.trim() === '') {
		return null
	}
	const url =
		video.includes('youtube.com') || video.includes('youtu.be')
			? video
			: `https://www.youtube.com/watch?v=${video}`
	const urlObj = new URL('https://noembed.com/embed')
	urlObj.searchParams.append('format', 'json')
	urlObj.searchParams.append('url', url)
	try {
		const info = await fetch(urlObj)
		const result = await info.json()
		const splitChar = video.includes('watch?v=') ? '=' : '/'
		const videoId = result.url.split(splitChar).pop()
		result.player_embed_url = `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`
		return result
	} catch (error) {
		logger.error({ error })
		return null
	}
}

export const getVimeoInfo = async id => {
	const myHeaders = new Headers()
	myHeaders.append('Authorization', 'Bearer 075a101f8c2963d7f793e9f2eb02199c')

	const requestOptions = {
		method: 'GET',
		headers: myHeaders,
		redirect: 'follow'
	}
	try {
		const info = await fetch(
			`https://api.vimeo.com/videos/${id}`,
			requestOptions
		)
		const result = await info.json()
		return {
			error: result?.error,
			thumbnail_url: result?.pictures?.base_link,
			author_name: result?.user?.name,
			title: result?.name,
			html: result?.embed?.html,
			player_embed_url: result?.player_embed_url
		}
	} catch (error) {
		logger.error({ error })
		return null
	}
}
