import _ from 'lodash'
import { Box, Icon, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import reactions from 'assets/images/reactions'
import styles from './ActivityItem.module.scss'
import { formatDate } from 'utils/date.utils'
import { Link } from 'react-router-dom'
import { TRANSACTION_TYPES } from '@/components/common'

const getTransactionInfo = typeId => {
	switch (typeId) {
		case TRANSACTION_TYPES.WELCOME_BONUS_REWARD:
			return 'from the Welcome Bonus reward'
		case TRANSACTION_TYPES.AIRDROP_TOKENS_UNLOCKED:
			return 'from unlocking airdrop tokens'
		case TRANSACTION_TYPES.CAMPAIGN_REWARD:
			return 'as a campaign reward'
		case TRANSACTION_TYPES.DAO_TIER_BONUS:
			return 'as a DAO tier bonus'
		case TRANSACTION_TYPES.FGR_CLAIMING:
			return 'for claiming FGR on a project'
		case TRANSACTION_TYPES.PROJECT_REVIEW:
			return 'for a review '
		case TRANSACTION_TYPES.PROJECT_SHARE:
			return 'for sharing a project '
		case TRANSACTION_TYPES.SOCIAL_JOIN_REWARD:
			return 'for joining using a reward link'
		case TRANSACTION_TYPES.TOKEN_DISBURSEMENT:
			return 'as a token disbursement'
		case TRANSACTION_TYPES.TOKEN_UNSTAKE:
			return 'from unstaking tokens '
		default:
			return ''
	}
}

const getInfo = (type, data) => {
	switch (type) {
		case 'comment': {
			const info = _.pick(data, [
				'createdAt',
				'content',
				'user',
				'newsreel',
				'review',
				'stake'
			])
			const project =
				info?.newsreel?.project ??
				info?.review?.project ??
				info?.stake?.destinationWallet?.project
			const extra = _.isNil(project)
				? { profile: info?.newsreel?.user }
				: { project }
			const { content, createdAt, user } = info
			return { content, createdAt, user, ...extra }
		}
		case 'transaction': {
			const info = _.pick(data, [
				'createdAt',
				'amount',
				'user',
				'project',
				'typeId',
				'blockchainHash'
			])
			const action = getTransactionInfo(info?.typeId)
			return { action, ...info }
		}
		case 'stakeReaction': {
			const info = _.pick(data, [
				'createdAt',
				'user',
				'transaction',
				'reaction'
			])
			const action = 'to a stake'
			const reactionIcon = data?.reaction?.icon
			const project = data?.transaction?.destinationWallet?.project
			return { project, reactionIcon, action, ...info }
		}
		case 'reviewReaction': {
			const info = _.pick(data, ['createdAt', 'user', 'review', 'reaction'])
			const action = 'to a review'
			const reactionIcon = data?.reaction?.icon
			const project = data?.review?.project
			return { project, reactionIcon, action, ...info }
		}
		case 'newsreelReaction': {
			const info = _.pick(data, ['createdAt', 'user', 'newsreel', 'reaction'])
			const action = 'to a newsreel'
			const reactionIcon = data?.reaction?.icon
			const project = data?.newsreel?.project
			const extra = _.isNil(project)
				? { profile: info?.newsreel?.user }
				: { project }
			return { project, reactionIcon, action, ...info, ...extra }
		}
		case 'commentReaction': {
			const info = _.pick(data, ['createdAt', 'user', 'comment', 'reaction'])
			const action = 'to a comment'
			const reactionIcon = data?.reaction?.icon
			const project = data?.comment?.newsreel?.project
			const extra = _.isNil(project)
				? { profile: info?.comment?.newsreel?.user }
				: { project }
			return { project, reactionIcon, action, ...info, ...extra }
		}
		case 'socialShare': {
			return _.pick(data, ['createdAt', 'user', 'project'])
		}
		default:
			break
	}
}

const ActivityItem = data => {
	const type = data?.type
	const {
		createdAt,
		content,
		user,
		project,
		profile,
		amount,
		action,
		reactionIcon
	} = getInfo(type, data)

	const profileProjectLink = _.isNil(project) ? (
		<>
			<Link to={`/profile/${profile?.username}`}>
				{profile?.displayName}&apos;s
			</Link>{' '}
			profile
		</>
	) : (
		<Link to={`/project/${project?.slug}`}>{project?.title}</Link>
	)
	const userLink = (
		<Link to={`/profile/${user?.username}`}>{user?.displayName}</Link>
	)

	const reaction = {
		title: <>Reaction added by {userLink}</>,
		description: (
			<>
				{user?.displayName} added a <img src={reactions[reactionIcon]} alt="" />{' '}
				{action} on {profileProjectLink}
			</>
		),
		icon: 'face-smile-hearts',
		iconBase: 'fal'
	}

	const infoMap = {
		reviewReward: {
			title: (
				<>
					{userLink} received a reward of {amount} by sharing{' '}
					{profileProjectLink}{' '}
				</>
			),
			description: content,
			icon: 'fan-stake',
			iconBase: 'fa-kit'
		},
		socialShare: {
			title: <>Project shared by {userLink}</>,
			description: (
				<>
					{user?.displayName} shared{' '}
					<Link to={`/project/${project?.slug}`}>{project?.title}</Link>.
				</>
			),
			icon: 'share',
			iconBase: 'fal'
		},
		comment: {
			title: (
				<>
					{userLink} commented on {profileProjectLink}
				</>
			),
			description: content,
			icon: 'comment',
			iconBase: 'fal'
		},
		transaction: {
			title: (
				<>
					{userLink} received {amount} FAN tokens {action}{' '}
					{project && <>on {profileProjectLink}</>}
				</>
			),
			description: content,
			icon: 'fan-stake',
			iconBase: 'fa-kit'
		},
		stakeReaction: reaction,
		reviewReaction: reaction,
		newsreelReaction: reaction,
		commentReaction: reaction
	}

	const icon = infoMap[type]?.icon
	const iconVariant = infoMap[type]?.iconBase

	return (
		<Box className={styles.activity_item}>
			<Box className={styles.header}>
				<Icon baseClassName={iconVariant} className={`fa-${icon}`} />
				<Typography>{infoMap[type]?.title}</Typography>
				<Typography className={styles.date}>
					{formatDate(createdAt, 'med')}
				</Typography>
			</Box>
			<Typography className={styles.description}>
				{infoMap[type]?.description}
			</Typography>
		</Box>
	)
}

ActivityItem.propTypes = { data: PropTypes.object }

export default ActivityItem
