import { cn } from '@/lib/utils'
import { Reaction } from '../../types'
import { ReactionButton } from './ReactionButton'
import { ReactionsPopover } from './ReactionsPopover'
import { useReactions } from '../../hooks/useReactions'

interface ReactionsProps {
	feedId: string
	type: string
	setReactions: (_reactions: Reaction[]) => void
	className?: string
}

export const Reactions = ({
	feedId,
	type,
	setReactions,
	className
}: ReactionsProps) => {
	const { isOpen, reactions, handleReaction, setIsOpen } = useReactions({
		feedId,
		type,
		onUpdate: setReactions
	})

	return (
		<ReactionsPopover
			isOpen={isOpen}
			onOpenChange={setIsOpen}
			reactions={reactions}
			onReactionSelect={handleReaction}
			className={className}
		/>
	)
}

interface ReactionListProps {
	reactions: Reaction[]
	feedId: string
	type: string
	setReactions: (_reactions: Reaction[]) => void
	className?: string
}

export const ReactionList = ({
	reactions,
	feedId,
	type,
	setReactions,
	className
}: ReactionListProps) => {
	const { handleReaction } = useReactions({
		feedId,
		type,
		onUpdate: setReactions
	})

	return (
		<div className={cn('flex gap-1', className)}>
			{reactions.map(reaction => (
				<ReactionButton
					key={reaction.icon}
					reaction={reaction}
					onClick={() => handleReaction(reaction.id)}
				/>
			))}
		</div>
	)
}
