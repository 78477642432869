import { setLoginDialogOpen } from '@/components/header/signInSlice'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export const useSocialMediaShareMenu = () => {
	const [anchorEl, setAnchorEl] = useState(null)
	const { isAuthenticated } = useSelector(state => state.signIn)
	const dispatch = useDispatch()

	const handleClick = event => {
		if (!isAuthenticated) {
			dispatch(setLoginDialogOpen(true))
			return
		}
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	return {
		anchorEl,
		handleClose,
		handleClick
	}
}
