import { useState } from 'react'
import stageImages from 'assets/images/stages'
import { CircleBar } from '@/components/common-ui/circleBar'
import styles from './GoScoreV2.module.scss'

const GoScoreV2 = ({
	color,
	showText = true,
	goscore
}: {
	color: string
	showText: boolean
	goscore: any
}) => {
	const [isHover, setIsHover] = useState(false)

	const handleMouseEnter = () => {
		setIsHover(true)
	}

	const handleMouseLeave = () => {
		setIsHover(false)
	}

	return (
		<div className={styles.container}>
			<div className={styles.score_container}>
				<div
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					className={styles.score}
					style={{
						boxShadow: isHover ? `0 0 0.9rem 0.4rem ${color}` : 'none'
					}}
				>
					<CircleBar
						{...{
							height: 32,
							lineWidth: 10,
							background: '#101010',
							time: 0.5,
							value1: goscore?.stakingPercent,
							value2: goscore?.socialPercent,
							value3: goscore?.projectPercent,
							value4: 1,
							classes: goscore?.locked
								? `${styles.bar} ${styles.lockedBar}`
								: `${styles.bar}`,
							id: 'stake'
						}}
					/>

					<img
						alt=""
						src={stageImages.scoreRing}
						className={styles.ring_image}
					/>
					{goscore?.locked ? (
						<img
							alt=""
							src={stageImages.locked}
							className={styles.center_image}
						/>
					) : (
						<p className={styles.center_text}>
							{Math.round(goscore?.goScore) && Math.round(goscore?.goScore)}
						</p>
					)}
				</div>
			</div>
			{showText && (
				<p className={styles.bottom_text}>
					Go Score <sup>TM</sup>
				</p>
			)}
		</div>
	)
}

export default GoScoreV2
