import { Box } from '@mui/material'
import stagesImages from 'assets/images/stages'
import styles from './Stages.module.scss'
import { getProjectGoScoreStagesService } from 'services/goscore.service'
import { useEffect, useState } from 'react'
import Stage from './stage/Stage'
import { StageDialog } from './stageDialog'
import { useSelector } from 'react-redux'
import useAxios from 'hooks/useAxios'
import _ from 'lodash'

const Stages = () => {
	const initialDialog = {
		open: false,
		stage: 0
	}
	const [stages, setStages] = useState([])
	const [dialog, setDialog] = useState(initialDialog)
	const { projectId } = useSelector(state => state.projectDetail)
	const { axiosService } = useAxios()

	const getProjectGoScoreStages = () => {
		axiosService(getProjectGoScoreStagesService(projectId), (data, error) => {
			if (!error) {
				setStages(data)
			}
		})
	}

	useEffect(() => {
		if (!_.isNil(projectId)) {
			getProjectGoScoreStages()
		}
	}, [projectId])

	const handleClose = () => {
		setDialog(initialDialog)
	}

	const handleStageClick = stage => () => {
		setDialog({
			open: true,
			stage
		})
	}

	const renderStages = () => {
		return stages.map((el, idx) => {
			const centerImage = stagesImages[el.label]
			const { ...item } = el
			const stage = {
				...item,
				centerImage,
				ringImage: stagesImages[`${item.label}Ring`]
			}
			return (
				<Stage
					{...stage}
					key={`stage-${idx}`}
					onClick={handleStageClick(idx)}
				/>
			)
		})
	}

	return (
		<Box className={styles.stages}>
			<StageDialog {...{ handleClose, ...dialog }} />
			{renderStages()}
		</Box>
	)
}

export default Stages
