import { createRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	setIsAddReviewDisabled,
	addBlockchainReviewHook,
	addReviewHook,
	setIsReviewed,
	reloadProject
} from '../../projectDetailSlice'
import {
	updateReviewHashService,
	addReviewService
} from 'services/review.service'
import useNotification from 'hooks/useNotification'
import { notificationExtTypes, notificationTypes } from '@/components/common'
import useAxios from 'hooks/useAxios'
import { reloadRecentActivity } from '@/components/profile/walletTab/walletTabSlice'
import useHeader from '@/components/header/useHeader'
import { Mixpanel } from 'services/mixpanel.service'
import useLoadingMask from 'hooks/useLoadingMask'
import { FilmioProjectContract } from 'utils/blockchain/functions'
import {
	setCreatePlatformWalletDialogOpen,
	setFrontTransactionsInProgress,
	setLoginDialogOpen
} from '@/components/header/signInSlice'
import _ from 'lodash'
import useCommon from 'hooks/useCommon'

const useWriteReview = () => {
	const dispatch = useDispatch()
	const [heading, setHeading] = useState('')
	const [content, setContent] = useState('')
	const [rating, setRating] = useState(0)
	const { isAuthenticated } = useSelector(state => state.signIn)
	const { project } = useSelector(state => state.projectDetail)
	const { captchaToken } = useSelector(state => state.captchaCheck)
	const {
		showNotification,
		showNotificationExtended,
		completeNotificationExtended
	} = useNotification()
	const { showMask, hideMask } = useLoadingMask()
	const { reloadUserInfo } = useHeader()
	const { nonCustodialWithoutMatic, checkNcNoMagic } = useCommon()
	const { axiosService } = useAxios()
	const inputRef = createRef()
	const projectId = project?.id

	const addBlockchainReview = (reviewId, blockchainHash) => {
		axiosService(
			updateReviewHashService({ reviewId, blockchainHash }),
			(data, error) => {
				if (!error) {
					completeNotificationExtended()
					Mixpanel.track('usr_review_added_evt', {
						distinct_id: localStorage.getItem('id'),
						reviewId,
						displayName: localStorage.getItem('displayName'),
						id: localStorage.getItem('id'),
						username: localStorage.getItem('username')
					})
					dispatch(addBlockchainReviewHook(data))
				} else {
					Mixpanel.track('transaction_failed_review_evt', {
						distinct_id: localStorage.getItem('id'),
						reviewId,
						displayName: localStorage.getItem('displayName'),
						id: localStorage.getItem('id'),
						username: localStorage.getItem('username')
					})
				}
				dispatch(setIsAddReviewDisabled(false))
				dispatch(reloadRecentActivity())
				reloadUserInfo()
			}
		)
	}

	const sanitizeInput = object => {
		for (const key in object) {
			object[key] = object[key] === '' ? null : object[key]
		}
		return object
	}

	const createReview = () => {
		showMask()
		const params = sanitizeInput({
			heading,
			content,
			rating,
			projectId
		})
		const contentLength = params?.content?.length ?? 0

		if (contentLength < 140) {
			dispatch(setIsAddReviewDisabled(false))
			hideMask()
			showNotification({
				message: 'Reviews must be at least 140 characters long.'
			})
			return
		}

		if (contentLength > 750) {
			dispatch(setIsAddReviewDisabled(false))
			hideMask()
			showNotification({
				message: 'Review is too long. Maximum 750 characters allowed.'
			})
			return
		}

		axiosService(
			addReviewService({ ...params, captchaToken }),
			async (data, error) => {
				if (!error) {
					const reviewId = data?.id
					dispatch(addReviewHook(data))
					dispatch(setIsReviewed(true))
					hideMask()
					dispatch(setFrontTransactionsInProgress(true))
					hideMask()
					showNotificationExtended({
						projectTitle: project?.title,
						total: 1, // @TODO fetch from database
						type: notificationExtTypes.REVIEW
					})
					const blockchainHash = await FilmioProjectContract.addRating(
						projectId,
						rating,
						!_.isNil(heading) || !_.isNil(content),
						reviewId
					)
					addBlockchainReview(reviewId, blockchainHash)
					dispatch(setFrontTransactionsInProgress(false))
				}
				hideMask()
				dispatch(setIsAddReviewDisabled(false))
				dispatch(reloadProject())
			}
		)
	}

	const handleClearForm = () => {
		setHeading('')
		setContent('')
		setRating(0)
		inputRef.current.focus()
	}

	const handlePostReviewClick = async () => {
		if (!isAuthenticated) {
			dispatch(setLoginDialogOpen(true))
			return
		}
		if (checkNcNoMagic()) {
			dispatch(setCreatePlatformWalletDialogOpen(true))
		} else {
			const isNonCustodialWithoutMatic = await nonCustodialWithoutMatic()
			if (isNonCustodialWithoutMatic) {
				return
			}
			if (rating === 0) {
				showNotification({
					message: 'Please select a star rating.',
					type: notificationTypes.ERROR
				})
			} else {
				dispatch(setIsAddReviewDisabled(true))
				createReview()
			}
		}
	}

	return {
		rating,
		heading,
		content,
		inputRef,
		isAuthenticated,
		handlePostReviewClick,
		handleClearForm,
		setHeading,
		setContent,
		setRating
	}
}

export default useWriteReview
