import _ from 'lodash'
import { setSuperfanItemDialogData } from '@/components/header/signInSlice'
import useAxios from 'hooks/useAxios'
import useLoadingMask from 'hooks/useLoadingMask'
import { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	getSuperfanProjectsService,
	getSuperfanTotalPointsService
} from 'services/superfan.service'

export const useSuperfanSection = () => {
	const { user } = useSelector(state => state.signIn)
	const { user: profile } = useSelector(state => state.profile)
	const { axiosService } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const [superFanProjects, setSuperFanProjects] = useState([])
	const [totalPoints, setTotalPoints] = useState(0)
	const dispatch = useDispatch()
	const userId = user?.id
	const { superfanPointsEarnedEvent } = useSelector(state => state.websocket)
	const getSuperfanProjects = useCallback((profileId) => {
		showMask()
		axiosService(getSuperfanProjectsService(profileId), (response, error) => {
			if (!error) {
				setSuperFanProjects(response)
			}
			hideMask()
		})
	}, [])

	const getSuperfanTotalPoints = useCallback(() => {
		if (!userId) return
		axiosService(getSuperfanTotalPointsService(userId), (response, error) => {
			if (!error) {
				setTotalPoints(response?.totalPoints)
			}
		})
	}, [userId])

	const handleOpenSuperfanProjectsDialog = useCallback((project) => () => {
		const createdAt = _.get(project, ['userFanLevel', 0, 'createdAt'])
		const displayName = profile?.displayName
		const title = project?.title
		dispatch(
			setSuperfanItemDialogData({
				open: true,
				createdAt,
				displayName,
				title
			})
		)
	}, [dispatch, user?.displayName])

	useEffect(() => {
		if (userId) {
			getSuperfanProjects(userId)
			getSuperfanTotalPoints()
		}
	}, [userId, getSuperfanProjects, getSuperfanTotalPoints, superfanPointsEarnedEvent])

	return {
		superFanProjects,
		handleOpenSuperfanProjectsDialog,
		totalPoints
	}
}
