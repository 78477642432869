export const getProjectPreviewByIdService = (projectPreviewId: string) => ({
	method: 'get',
	url: `/projectPreview/${projectPreviewId}`
})

export const createProjectPreviewService = (data: any) => {
	const formData = new FormData()
	const projectFields = [
		'typeId',
		'studioId',
		'statusId',
		'creator',
		'genres',
		'title',
		'logline',
		'story',
		'trailer',
		'poster',
		'lookbook',
		'thumbnail',
		'coverImg',
		'projectId'
	]

	const files = ['poster', 'lookbook', 'thumbnail', 'coverImg']

	projectFields.forEach(key => {
		if (key === 'genres') {
			formData.append(key, JSON.stringify(data[key]))
		} else if (data[key] instanceof File) {
			formData.append(key, data[key], data[key].name)
		} else if (!files.includes(key)) {
			formData.append(key, data[key])
		}
	})

	return {
		method: 'post',
		url: '/projectPreview',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}
}
