import _ from 'lodash'
import { useSelector } from 'react-redux'
import { Box, Grid, Icon, Typography } from '@mui/material'
import { ProjectActivityItem } from './projectActivityItem'
import { convertToMillis } from 'utils/date.utils'
import { DaoTiers } from './daoTiers'
import { FeaturedProjects } from './featuredProjects'
import airdropImg from 'assets/images/airdrop_2023.png'
import { Link } from 'react-router-dom'
import { SocialShareSection } from './socialShareSection'
import { StatItem } from '@/components/common/statItem'
import { ReferralTrackingSection } from '@/components/common/referralTrackingSection'
import { RecentJoinsTable } from './recentJoinsTable'
import { SuperfanSection } from './superfanSection/SuperfanSection'
import styles from './DashboardTab.module.scss'

export const ACTIVITY_TYPES = {
	stake: 'stake',
	follow: 'follow',
	share: 'share'
}

const renderSocialIcons = (socials = [], website) => {
	const socialIcons = socials.map(({ link, icon, username }) => {
		const href = `${link}${username}`
		return (
			<a href={href} target="_blank" key={`link-to-${icon}`} rel="noreferrer">
				<Icon baseClassName="fab" className={`fa-${icon}`} />
			</a>
		)
	})
	if (website) {
		socialIcons.push(
			<a
				href={website}
				target="_blank"
				key={'link-to-website'}
				rel="noreferrer"
			>
				<Icon baseClassName="fas" className="fa-duotone fa-browser" />
			</a>
		)
	}
	return socialIcons
}

export const renderProfileActivities = activities => {
	return activities?.map(activity => {
		const id = activity.followingId || convertToMillis(activity.createdAt)
		return <ProjectActivityItem {...{ ...activity }} key={id} />
	})
}

const DashboardTab = () => {
	const { user } = useSelector(state => state.profile)
	const { user: signInUser } = useSelector(state => state.signIn)
	const {
		about,
		country,
		createdAt,
		socialNetworks,
		lastActivities,
		joinStats,
		projects,
		website
	} = user

	const isSignedInProfile = signInUser?.id === user?.id

	return (
		<>
			<Grid
				container
				spacing={{ xs: 2, md: 6 }}
				className={styles.profile_dashboard}
			>
				{isSignedInProfile && (
					<Grid className={styles.daoWrapper} item md={12}>
						<DaoTiers />
					</Grid>
				)}
				<Grid item md={6}>
					<Grid item md={12}>
						<Typography component={'h5'}>About {user?.displayName}</Typography>
						<Box className={styles.social_container}>
							{(!_.isEmpty(socialNetworks) || website !== null) && (
								<Box className={styles.socials}>
									{renderSocialIcons(socialNetworks, website)}
								</Box>
							)}
							{country && (
								<Box className={styles.country}>
									Lives in <strong>{user?.country?.name}</strong>
								</Box>
							)}
						</Box>
					</Grid>
					<Grid item md={12}>
						<Typography component={'h3'} className={styles.sub_header}>
							Filmio Superfan Credits
						</Typography>
						<SuperfanSection />
					</Grid>
					<Grid item md={12}>
						<Typography component={'h3'} className={styles.sub_header}>
							Featured Projects
						</Typography>
						<FeaturedProjects>
							{projects.map(p => {
								return (
									<Link key={p.id} to={`/project/${p.slug}`}>
										<img alt="" src={p.poster} />
									</Link>
								)
							})}
						</FeaturedProjects>
					</Grid>
					<Grid item md={12}>
						<Typography component={'h3'} className={styles.sub_header}>
							{'Recent Awards & Achievements'}
						</Typography>
						<Box className={styles.achievments}>
							{new Date(createdAt) < new Date('2023-11-30T12:00:00') && (
								<img alt="" src={airdropImg} />
							)}
						</Box>
					</Grid>
					<Grid item md={12}>
						<Typography component={'h3'} className={styles.sub_header}>
							My Story
						</Typography>
						<Box className={styles.about}>
							<Box dangerouslySetInnerHTML={{ __html: about }}></Box>
						</Box>
					</Grid>
				</Grid>

				<Grid item md={6}>
					{isSignedInProfile && (
						<>
							<Grid className={styles.shareStats} item md={12}>
								<SocialShareSection />
							</Grid>
							<Grid item md={12}>
								<ReferralTrackingSection>
									<StatItem
										{...{
											label: 'Users Joined',
											amount: joinStats?.totalJoined ?? 0,
											variant: 'green_highlight'
										}}
									/>
									<StatItem
										{...{
											label: 'Joined Today',
											amount: joinStats?.joinedToday ?? 0
										}}
									/>
									<StatItem
										{...{
											label: 'Joined Yesterday',
											amount: joinStats?.joinedYesterday ?? 0
										}}
									/>
								</ReferralTrackingSection>
							</Grid>
							<Grid item md={12}>
								<RecentJoinsTable />
							</Grid>
						</>
					)}
					<Grid item md={12}>
						<Box className={styles.recent_activity}>
							<h5>Recent Activity</h5>
							{renderProfileActivities(lastActivities)}
						</Box>
					</Grid>
				</Grid>
			</Grid>
		</>
	)
}

export default DashboardTab
