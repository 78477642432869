import { LightTooltip } from '@/components/common'
import { Link } from 'react-router-dom'
import { Avatar, Box, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import styles from './ReviewInfoTimestamp.module.scss'
import { getFileUrl } from 'utils/url.util'
import { Crown } from 'lucide-react'

const ReviewInfoTimestamp = ({ user }) => {
	const displayName = user.displayName

	return (
		<Box className={styles.profile_link}>
			<LightTooltip title={`View ${user?.displayName}'s Profile`}>
				<Link to={`/profile/${user?.username}`}>
					<Avatar
						alt={user?.displayName}
						sx={{
							bgcolor: '#000000',
							border: '1px solid #ffffff',
							height: 27,
							width: 27,
							marginRight: '8px'
						}}
						src={getFileUrl(user?.avatar)}
					/>
				</Link>
			</LightTooltip>
			<Box className={styles.info_container}>
				<LightTooltip title={`View ${user?.displayName}'s Profile`}>
					<Link to={`/profile/${user?.username}`}>
						<Box className={styles.display_name}>
							<Box className="flex items-center gap-2">
								<Typography>{displayName}</Typography>
								{user?.isVerified === 1 && (
									<span className="flex items-center gap-1 rounded-full !bg-blue-500/10 px-2 py-0.5 !text-xs tracking-[0.1rem] !text-blue-500">
										<Crown className="h-3 w-3 !text-blue-500" />
										Verified
									</span>
								)}
							</Box>
						</Box>
					</Link>
				</LightTooltip>
			</Box>
		</Box>
	)
}

ReviewInfoTimestamp.propTypes = {
	updatedAt: PropTypes.string,
	user: PropTypes.object,
	project: PropTypes.object,
	blockchainHash: PropTypes.string
}

export default ReviewInfoTimestamp
