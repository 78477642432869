import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
	getDashboardService,
	getSharerNameService
} from 'services/dashboard.service'
import { getActiveContests } from 'services/contest.service'
import {
	setActiveContests,
	setJoinDialogOpen,
	setSocialJoinParams
} from '@/components/header/signInSlice'
import { useEffect } from 'react'
import useAxios from 'hooks/useAxios'
import { queryKeys } from '@/lib/queryKeys'

interface Stage {
	id: number
	name: string
}

interface GoScoreStage {
	id: number
	name: string
	color: string
	label: string
}

interface User {
	displayName: string
	username: string
	isVerified: number
}

interface Genre {
	id: number
	name: string
}

interface Contest {
	id: string
	name: string
	isCompleted: boolean
	startDate: string
	endDate: string
	badge: string
	badgeWinner: string
	winnerId: string | null
	isActive: boolean
	isWinner: boolean
}

interface GoScoreMilestone {
	id: number
	name: string
	color: string
}

interface GoScore {
	locked: boolean
}

interface DashboardItem {
	id: string
	likes: number
	poster: string
	reviewsAvg: number
	stakesTotal: number
	stakersCount: number
	goScoreStageId: number
	decayStatus: string
	createdAt: string
	updatedAt: string
	staffPick: number
	title: string
	slug: string
	views: number
	stage: Stage
	goScoreStage: GoScoreStage
	user: User
	genres: Genre[]
	contests: Contest[]
	artifactType: string
	isFollowedByUser: boolean
	lookbook: string | null
	script: string | null
	treatment: string | null
	goScoreMilestones: GoScoreMilestone[]
	goscore: GoScore
	isSuperfan: boolean
}

interface RootState {
	dashboard: {
		filter: {
			types: string[]
			[key: string]: any
		}
		sort: any
		reloadProposals: boolean
	}
	signIn: {
		isAuthenticated: boolean
		activeContests: Contest[]
	}
}

interface SharerResponse {
	sharerName: string
}

const PAGE_SIZE = 12

export const useDashboard = () => {
	const dispatch = useDispatch()
	const { axiosServiceSync } = useAxios()
	const { userShortUrl, network } = useParams()

	const { filter, sort, reloadProposals } = useSelector(
		(state: RootState) => state.dashboard
	)
	const { isAuthenticated, activeContests } = useSelector(
		(state: RootState) => state.signIn
	)

	const { data, fetchNextPage, hasNextPage, isFetching, refetch } =
		useInfiniteQuery({
			queryKey: queryKeys.dashboard.list(filter, sort),
			queryFn: async ({ pageParam = 0 }) => {
				const data: DashboardItem[] = await axiosServiceSync(
					getDashboardService({
						...filter,
						sort,
						page: pageParam
					})
				)
				return data
			},
			getNextPageParam: (lastPage, allPages) => {
				return lastPage?.length === PAGE_SIZE ? allPages.length : undefined
			},
			initialPageParam: 0
		})

	useQuery({
		queryKey: queryKeys.contests.active(),
		queryFn: async () => {
			const data: Contest[] = await axiosServiceSync(getActiveContests())
			dispatch(setActiveContests(data))
			return data
		}
	})

	const { data: sharerData } = useQuery({
		queryKey: queryKeys.sharer.name(userShortUrl!),
		queryFn: async () => {
			const data: SharerResponse = await axiosServiceSync(
				getSharerNameService({ userShortUrl })
			)
			return data
		},
		enabled: !isAuthenticated && Boolean(userShortUrl)
	})

	const onScroll = () => {
		if (
			window.innerHeight + window.scrollY >= document.body.offsetHeight - 10 &&
			hasNextPage &&
			!isFetching
		) {
			fetchNextPage()
		}
	}

	const handleOpenJoinDialog = () => {
		dispatch(setJoinDialogOpen(true))
	}

	useEffect(() => {
		dispatch(setSocialJoinParams({ userShortUrl, network }))
	}, [userShortUrl, network])

	useEffect(() => {
		if (reloadProposals && filter?.types.includes('dao')) {
			refetch()
		}
	}, [reloadProposals])

	const items = data?.pages.flat() ?? []
	const sharerDisplayName = sharerData?.sharerName

	return {
		items,
		loading: isFetching,
		loadMore: hasNextPage || isFetching,
		isAuthenticated,
		sharerDisplayName,
		handleOpenJoinDialog,
		onScroll,
		hasBanner: activeContests?.length > 0
	} as const
}

export default useDashboard
