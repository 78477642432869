import React, { useState } from 'react'
import { Button } from '@/components/ui/button'
import { LeaderBoardDialog } from './LeaderBoardDialog'
import { Table } from 'lucide-react'

export const SuperfanLeaderboard = ({ projectId }: { projectId: string }) => {
	const [open, setOpen] = useState(false)

	const handleOpenChange = () => {
		setOpen(false)
	}

	return (
		<>
			<Button
				className="rounded-md bg-[#2196F3] px-3 text-sm font-medium leading-5 text-black hover:bg-[#2196F3]/90"
				onClick={() => setOpen(true)}
			>
				<Table className="h-4 w-4" />
				Leaderboard
			</Button>
			<LeaderBoardDialog
				open={open}
				onOpenChange={handleOpenChange}
				projectId={projectId}
			/>
		</>
	)
}
