import _ from 'lodash'
import { useEffect, useState } from 'react'
import { notificationExtTypes } from '@/components/common'
import { useDispatch, useSelector } from 'react-redux'
import { setWhitelistTransferDialogOpen } from '@/components/profile/profileSlice'
import { abbreviateNumberFixed, formatAddress } from 'utils/utils'
import useWeb3 from 'hooks/useWeb3'
import useAxios from 'hooks/useAxios'
import {
	addBlockchainFanWithdrawalService,
	addFanWithdrawalService
} from 'services/wallet.service'
import useNotification from 'hooks/useNotification'
import { reloadRecentActivity } from '../walletTabSlice'
import { setFrontTransactionsInProgress } from '@/components/header/signInSlice'
import { FanTokenContract } from 'utils/blockchain/functions'
import { signVerificationMessage } from 'utils/blockchain/config/utils'
import { truncateToDecimals } from 'utils/form.util'
import useKyc from 'hooks/useKyc'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { getFanTransferMaxService } from 'services/seed.service'

const useWhitelistTransferDialog = () => {
	const [amount, setAmount] = useState('')
	const [disableWithdraw, setDisableWithdraw] = useState(false)
	const [openConnectWalletModal, setOpenConnectWalletModal] = useState(false)
	const [clickedWithdraw, setClickedWithdraw] = useState(false)
	const [showKycMessage, setShowKycMessage] = useState(false)
	const [fanTransferMax, setFanTransferMax] = useState(0)
	const { isConnected, signer } = useWeb3()
	const { user: signInUser } = useSelector(state => state.signIn)
	const { whitelistTransferDialogOpen: open } = useSelector(
		state => state.profile
	)
	const { overview: data } = useSelector(state => state.walletTab)

	const onFailKyc = () => {
		setShowKycMessage(false)
	}

	const onSuccessKyc = () => {
		setShowKycMessage(false)
		handleWithdrawClick()
	}

	const { showKyc } = useKyc({
		onFailKyc,
		onSuccessKyc
	})

	const {
		showNotification,
		showNotificationExtended,
		completeNotificationExtended,
		closeNotificationExtended
	} = useNotification()
	const dispatch = useDispatch()
	const { axiosService } = useAxios()

	const earningsTotal =
		Number(data.earningsStaking) + Number(data.actionsEarnings)

	const unlockedRewards = Number(data.unlockedRewards)
	const unlockedTokens = Number(data.unlockedTokens)
	const stakesTotal = Number(data.stakesTotal)
	const lockedRewards = earningsTotal - unlockedRewards

	const availableToWithdraw = unlockedTokens

	const whitelistAddress = formatAddress(signInUser?.whitelistedAddress)

	const addBlockchainWithdrawal = (transactionId, blockchainHash) => {
		axiosService(
			addBlockchainFanWithdrawalService({
				transactionId,
				blockchainHash
			}),
			(_data, error) => {
				if (!error) {
					if (_.isNil(blockchainHash)) {
						closeNotificationExtended()
					} else {
						completeNotificationExtended()
					}
				}
				dispatch(reloadRecentActivity())
			}
		)
	}

	const afterCreateWithdrawal = async (transaction, error) => {
		if (!error) {
			const transactionId = transaction?.id
			dispatch(setFrontTransactionsInProgress(true))
			handleClose()
			showNotificationExtended({
				total: amount,
				type: notificationExtTypes.WITHDRAW
			})
			const hash = await FanTokenContract.transfer(
				signInUser?.whitelistedAddress,
				Number(amount),
				transactionId
			)

			addBlockchainWithdrawal(transactionId, hash, transaction?.amount)
			dispatch(setFrontTransactionsInProgress(false))

			if (!_.isNil(hash)) {
				setAmount('')
			}
		}
		setDisableWithdraw(false)
	}

	const addFanWithdrawal = async () => {
		try {
			const { hash, signature } = await signVerificationMessage(
				signer,
				'Please sign this message to verify you are the owner of this wallet.'
			)
			axiosService(
				addFanWithdrawalService({
					amount,
					hash,
					signature
				}),
				afterCreateWithdrawal
			)
		} catch {
			setDisableWithdraw(false)
		}
	}

	useAxiosFetch(getFanTransferMaxService(), data => {
		setFanTransferMax(Number(data?.fanTransferMax))
	})

	const handleWithdrawClick = async () => {
		setDisableWithdraw(true)
		const isToBlacklisted = await FanTokenContract.isBlacklisted(
			signInUser?.whitelistedAddress
		)
		if (isToBlacklisted === true) {
			showNotification({
				message:
					'The wallet you have whitelisted has been blocked for fraud. Please whitelist another wallet and try again.'
			})
			setDisableWithdraw(false)
			handleClose()
			return
		}
		setClickedWithdraw(true)
		if (isConnected) {
			setClickedWithdraw(false)
			addFanWithdrawal()
		} else {
			setOpenConnectWalletModal(true)
			setDisableWithdraw(false)
		}
	}

	const handleClose = () => {
		dispatch(setWhitelistTransferDialogOpen(false))
	}

	const handleChangeForm = value => {
		const trancatedValue = truncateToDecimals(value, 4)
		setAmount(trancatedValue)
	}

	const handleClickTransfer = () => {
		if (amount === '') {
			showNotification({
				message: 'Please provide the amount of FAN to transfer'
			})
			setDisableWithdraw(false)
			setClickedWithdraw(false)
			return
		}

		if (
			amount > fanTransferMax &&
			signInUser?.isVerified === 0 &&
			signInUser?.withdrawalRequireKyc
		) {
			setShowKycMessage(true)
			return
		}

		if (amount < 250) {
			showNotification({ message: 'You must transfer at least 250 FAN' })
			setDisableWithdraw(false)
			setClickedWithdraw(false)
			return
		}
		if (amount > availableToWithdraw) {
			showNotification({
				message: `You can only transfer up to ${abbreviateNumberFixed(
					availableToWithdraw,
					2
				)} FAN. Platform rewards are unavailable to withdraw yet. Please stick to the limit and try again.`
			})
			setDisableWithdraw(false)
			setClickedWithdraw(false)
			return
		}

		handleWithdrawClick(amount)
	}

	useEffect(() => {
		if (clickedWithdraw && signer && !openConnectWalletModal) {
			setClickedWithdraw(false)
			addFanWithdrawal()
		}
	}, [clickedWithdraw, signer, openConnectWalletModal])

	return {
		open,
		amount,
		isConnected,
		stakesTotal,
		showKycMessage,
		lockedRewards,
		unlockedTokens,
		disableWithdraw,
		whitelistAddress,
		availableToWithdraw,
		openConnectWalletModal,
		setOpenConnectWalletModal,
		handleClickTransfer,
		setShowKycMessage,
		handleChangeForm,
		handleClose,
		showKyc
	}
}

export default useWhitelistTransferDialog
