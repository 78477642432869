import { CustomDialog } from '@/components/common-ui'
import { closePerkDialog, selectPerkDialog } from '@/store/modalSlice'
import { getFileUrl } from '@/utils/url.util'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

export const PerkDialog = () => {
	const dispatch = useDispatch()
	const { open, image } = useSelector(selectPerkDialog)
	const handleOpenChange = () => {
		dispatch(closePerkDialog())
	}
	if (!open) return null
	return (
		<CustomDialog open={open} onOpenChange={handleOpenChange}>
			<div className="mt-5 rounded-lg bg-gradient-to-r from-[#8C421D] to-[#D4A041] p-[1px]">
				<div className="rounded-lg bg-[#0A1C2A]">
					<img
						src={getFileUrl(image)}
						alt="perk image"
						className="w-full rounded-lg object-cover"
					/>
				</div>
			</div>
		</CustomDialog>
	)
}
