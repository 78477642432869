import { PackagePlus } from 'lucide-react'
import { cn } from '@/lib/utils'
import { Perk } from './SuperfanBenefitsDialog'

export const UpcomingPerkItem = ({
	perk,
	className
}: {
	perk: Perk
	className: string
}) => {
	return (
		<div
			key={perk?.id}
			className={cn('rounded-lg bg-[#2196F3]/10 px-3 py-3', className)}
		>
			<div className="flex items-center justify-between opacity-50">
				<div className="flex items-center gap-4">
					<PackagePlus className="h-10 w-10" strokeWidth={0.75} />
					<div>
						<div className="text-sm">
							<span className="font-bold">{perk?.name}</span>
						</div>
						<div className="text-xs">Starting in {perk?.daysToStart} Days</div>
					</div>
				</div>
				<span className="text-sm font-bold">{perk?.qty} total</span>
			</div>
		</div>
	)
}
