import _ from 'lodash'
import { Grid } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { StakesTable } from './stakesTable'
import { AddStakeForm } from './addStakeForm'
import styles from './StakesTab.module.scss'
import { getUserStakeTotalService } from 'services/stake.service'
import useLoadingMask from 'hooks/useLoadingMask'
import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { abbreviateNumberFixed } from 'utils/utils'
import { setLoginDialogOpen } from '@/components/header/signInSlice'
import useProjectCommon from '@/components/project/useProjectCommon'
import { ProjectEmptyContent } from '@/components/project/ProjectEmptyContent'

const StakesTab = () => {
	const dispatch = useDispatch()
	const [total, setTotal] = useState(0)
	const { updateStakes } = useSelector(state => state.projectDetail)
	const { project } = useProjectCommon()
	const { isAuthenticated } = useSelector(state => state.signIn)
	const { stakeCompletedEvent, stakeUnstakedEvent } = useSelector(
		state => state.websocket
	)
	const { showMask, hideMask } = useLoadingMask()
	const { axiosService } = useAxios()
	const projectId = project?.id

	const getUserStakeTotal = () => {
		if (!isAuthenticated) {
			return
		}
		showMask()
		axiosService(getUserStakeTotalService(projectId), (data, error) => {
			if (!error) {
				setTotal(data?.total)
			}
			hideMask()
		})
	}

	const handleOpenLoginModal = () => {
		dispatch(setLoginDialogOpen(true))
	}

	useEffect(() => {
		if (!_.isNil(projectId)) getUserStakeTotal()
	}, [updateStakes, projectId])

	useEffect(() => {
		if (
			(stakeCompletedEvent?.projectId &&
				projectId === stakeCompletedEvent?.projectId) ||
			(stakeUnstakedEvent?.projectId &&
				projectId === stakeUnstakedEvent?.projectId)
		)
			getUserStakeTotal()
	}, [stakeCompletedEvent, stakeUnstakedEvent])

	return (
		<Grid container spacing={2} className={styles.stakes_section}>
			<Grid item md={12}>
				<div className={styles.projectinfo}>
					<Grid container spacing={2}>
						{isAuthenticated ? (
							<AddStakeForm />
						) : (
							<Grid item md={6}>
								<ProjectEmptyContent
									emptyMessage={'Click to stake on this project'}
									onClick={handleOpenLoginModal}
								/>
							</Grid>
						)}

						<Grid item md={6}>
							<Grid item md={12} className={styles.stats}>
								<h1 className="font-bold">Current Staking Totals</h1>
								<div className={styles.fan_staked}>
									<div className={styles.your_stake}>
										<p className={styles.stat}>
											{abbreviateNumberFixed(total, 2)}
										</p>
										<span>Your Staked FAN</span>
									</div>
									<div className={styles.total_staked}>
										<p className={styles.stat}>
											{abbreviateNumberFixed(project.stakesTotal, 2)}
										</p>
										<span>FAN Staked</span>
									</div>
									<div className={styles.total_stakers}>
										<p className={styles.stat}>
											{abbreviateNumberFixed(project.stakersCount, 2)}
										</p>
										<span>Unique Stakers</span>
									</div>
								</div>
								<div className={styles.explanations}>
									<p>
										<small>
											FAN Tokens can be staked to projects and rewards can be
											earned in the form of FGR (Fan Governance Rewards).
											Staking to a project helps creators unlock functionality
											and shows your support for their projects. FAN Tokens are
											not a financial instrument and have no monetary value.
										</small>
									</p>
								</div>
							</Grid>
						</Grid>
					</Grid>
					<StakesTable />
				</div>
			</Grid>
		</Grid>
	)
}

export default StakesTab
