import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import {
	setUnallowedLocationsOpen,
	setValidateIpDialogOpen
} from '../tokenSaleExperienceSlice'
import { useEffect, useState } from 'react'
import useAxiosFetch from 'hooks/useAxiosFetch'
import { updateUserFields } from 'services/profile.service'
import useLoadingMask from 'hooks/useLoadingMask'
import { isNil } from 'lodash'
import { logger } from '@/utils/logger'

// TODO: We can remove this hook and everything related to token sale experience
export const useValidateIpDialog = () => {
	const [error, setError] = useState(false)
	const [locationAllowed, setLocationAllowed] = useState(null)
	const [approved, setApproved] = useState(false)
	const { user } = useSelector(state => state.signIn)
	const { validateIpDialogOpen } = useSelector(
		state => state.tokenSaleExperience
	)
	const { showMask, hideMask } = useLoadingMask()
	const dispatch = useDispatch()
	const unallowedLocations = [
		{ country: 'US', name: 'USA' },
		{ country: 'CA', name: 'Canada' },
		{ country: 'CU', name: 'Cuba' },
		{ country: 'KP', name: 'Democratic People’s Republic of North Korea' },
		{
			name: `Donetsk People's Republic (DNR) region of Ukraine`,
			country: 'UA',
			region: 'Donetsk'
		},
		{ country: 'IR', name: 'Islamic Republic of Iran' },
		{ country: 'LY', name: 'Libya' },
		{
			name: `Luhansk People's Republic (LNR) region of Ukraine`,
			country: 'UA',
			region: 'Luhansk'
		},
		{ country: 'SS', name: 'People’s Republic of China' },
		{ country: 'SS', name: 'South Sudan' },
		{ country: 'SD', name: 'Sudan (North)' },
		{ country: 'SY', name: 'Syria' },
		{
			name: `Luhansk People's Republic (LNR) region of Ukraine`,
			country: 'UA',
			region: 'Crimea'
		},
		{
			country: '',
			name: 'Any jurisdiction in which the entry into the Filmio T&Cs or the ownership of the Tokens is prohibited by Applicable Law'
		}
	]

	const [{ loading }, agreedCondition] = useAxiosFetch(
		updateUserFields({ tokenSaleConditionsAccepted: approved, id: user?.id }),
		(_data, error) => {
			if (!error) {
				localStorage.setItem('tokenSaleConditionsAccepted', 1)
				handleClose()
			}
		}
	)

	const isLocationAllowed = locationData => {
		const unallow = unallowedLocations.some(el => {
			const country = locationData?.country
			const region = locationData?.region
			if (el?.country === country && isNil(el?.region)) return true
			if (el?.region && el?.country === country && region.includes(el?.region))
				return true
			return false
		})
		return !unallow
	}

	const showListOfUnallowedLocations = () => () => {
		dispatch(setUnallowedLocationsOpen(true))
		handleClose()
		dispatch(setValidateIpDialogOpen(true))
		dispatch(setUnallowedLocationsOpen(false))
	}

	const handleClose = () => {
		dispatch(setValidateIpDialogOpen(false))
	}

	const handleGoToNext = () => {
		agreedCondition()
	}

	useEffect(() => {
		const apiKey = '9c4053d34e58e8'
		const axiosInstance = axios.create({
			headers: {
				Authorization: `Bearer ${apiKey}`
			}
		})

		axiosInstance
			.get('https://ipinfo.io')
			.then(response => {
				const locationData = response.data
				const isAllowed = isLocationAllowed(locationData)
				setLocationAllowed(isAllowed)
				if (user?.tokenSaleConditionsAccepted === 1 && isAllowed) {
					setApproved(true)
					setTimeout(handleClose, 500)
				}
			})
			.catch(error => {
				setError(true)
				logger.error('Error fetching location data:', error)
			})
	}, [])

	useEffect(() => {
		loading ? showMask() : hideMask()
	}, [loading])

	useEffect(() => {
		loading ? showMask() : hideMask()
	}, [user])

	return {
		error,
		approved,
		locationAllowed,
		validateIpDialogOpen,
		showListOfUnallowedLocations,
		handleGoToNext,
		setApproved
	}
}
