'use client'

import React, { useState, useRef, useLayoutEffect } from 'react'
import { Button } from '../ui/button'

interface Props {
	logline: string
	story: string
	maxHeight?: number
}

export const Description = ({ logline, story, maxHeight = 100 }: Props) => {
	const [isExpanded, setIsExpanded] = useState(false)
	const [showToggle, setShowToggle] = useState(false)
	const contentRef = useRef<HTMLDivElement>(null)

	useLayoutEffect(() => {
		if (contentRef.current) {
			// Temporarily remove max-height to get full height
			contentRef.current.style.maxHeight = 'none'
			const fullHeight = contentRef.current.clientHeight

			// Reset max-height
			contentRef.current.style.maxHeight = isExpanded
				? 'none'
				: `${maxHeight}px`

			setShowToggle(fullHeight > maxHeight)
		}
	}, [story, maxHeight, isExpanded])

	return (
		<div className="w-full">
			<h2 className="mb-4 text-base font-bold md:text-lg">Logline</h2>
			<p
				className="prose text-base text-white"
				dangerouslySetInnerHTML={{ __html: logline }}
			/>

			<h2 className="mb-4 mt-6 text-base font-bold md:text-lg">Story</h2>
			<div className="text-base">
				<div
					ref={contentRef}
					className={`prose overflow-hidden text-white transition-all duration-300 ease-in-out`}
					style={{ maxHeight: isExpanded ? '' : `${maxHeight}px` }}
					dangerouslySetInnerHTML={{ __html: story }}
				/>
				{showToggle && (
					<Button
						size="sm"
						onClick={() => {
							setIsExpanded(!isExpanded)
							if (contentRef.current) {
								contentRef.current.style.maxHeight = !isExpanded
									? 'none'
									: `${maxHeight}px`
							}
						}}
						className="mt-2"
						aria-expanded={isExpanded}
					>
						{isExpanded ? 'Less' : 'More'}
					</Button>
				)}
			</div>
		</div>
	)
}
