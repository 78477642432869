import useAxios from '@/hooks/useAxios'
import { PackageOpen } from 'lucide-react'
import packageOpenGold from '@/assets/images/package-open.svg'
import { Button } from '@/components/ui/button'
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from '@/lib/queryKeys'
import {
	getProjectPerksService,
	addPerkViewService
} from '@/services/perk.service'
import useLoadingMask from '@/hooks/useLoadingMask'
import { ActivePerkItem } from './ActivePerkItem'
import { UserPerkItem } from './UserPerkItem'
import { UpcomingPerkItem } from './UpcomingPerkItem'
import { CustomDialog } from '@/components/common-ui/CustomDialog'
import { useEffect, useState } from 'react'
import {
	openBecomeSuperfanDialog,
	setSuperfanWelcomeDialogOpen,
	setYouAreSuperfanProjectDialogData
} from '@/components/header/signInSlice'
import { cn } from '@/lib/utils'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CheckIcon } from 'lucide-react'
import { TooltipArrow } from '@/components/ui/tooltip'
import { SuperfanMetricsDialog } from '../../metrics/SuperfanMetricsDialog'
import { CustomTooltip } from '@/components/common-ui/CustomTooltip'
import { PerkDialog } from './PerkDialog'

interface SuperfanBenefitsDialogProps {
	open: boolean
	onOpenChange: (_isOpen: boolean) => void
	projectId: string
}

export interface Perk {
	id: string
	name: string
	qty?: number
	endDate?: string
	image?: string
	startDate?: string
	daysToStart?: number
}

export interface UserPerk {
	id: string
	perk: Perk
	createdAt: string
	projectTitle?: string
	displayName?: string
}

export const SuperfanBenefitsDialog = ({
	open,
	onOpenChange,
	projectId
}: SuperfanBenefitsDialogProps) => {
	const { axiosServiceSync } = useAxios()
	const { showMask, hideMask } = useLoadingMask()
	const [isActionsDialogOpen, setIsActionsDialogOpen] = useState(false)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const handleBecomeSuperfan = () => {
		dispatch(
			openBecomeSuperfanDialog({
				projectTitle: data?.projectTitle,
				projectId,
				isOnPerksProgram: data?.isOnPerksProgram
			})
		)
		dispatch(setSuperfanWelcomeDialogOpen(false))
		dispatch(setYouAreSuperfanProjectDialogData({ open: false }))
		navigate(`/project/${data?.projectSlug}`)
	}

	useEffect(() => {
		if (open) {
			axiosServiceSync(addPerkViewService(projectId))
		}
	}, [open])

	const { data, isLoading } = useQuery({
		queryKey: queryKeys.project.perks(projectId),
		queryFn: async () =>
			await axiosServiceSync(getProjectPerksService(projectId)),
		enabled: open
	})

	const rank = data?.superfanStats?.rank

	useEffect(() => {
		if (isLoading) {
			showMask()
		} else {
			hideMask()
		}
	}, [isLoading, showMask, hideMask])

	const creditPerk = data?.isOnPerksProgram
		? {
				perk: {
					id: 'credit-perk',
					name: 'Filmio Superfan Film Credit'
				},
				createdAt: data?.superfanStats?.createdAt,
				displayName: data?.superfanStats?.displayName,
				projectTitle: data?.projectTitle,
				id: 'user-credit-perk'
			}
		: null
	const noUserPerks = data?.userPerks?.length === 0 && !creditPerk
	return (
		<>
			<CustomDialog
				open={open}
				icon={<PackageOpen className="h-7 w-7 sm:h-9 sm:w-8" strokeWidth={1} />}
				onOpenChange={onOpenChange}
				className="max-w-[550px]"
				title={'Superfan Perks'}
				titleClassName="text-2xl font-medium text-white sm:text-3xl text-left flex items-start gap-3"
			>
				<div className="text-sm text-white">
					<p>
						<span className="font-bold">Superfan Perks</span> are incentives
						offered by a creator to say &quot;thanks!&quot; for supporting their
						project. Ooh,{' '}
						<CustomTooltip
							content={
								<>
									<TooltipArrow width={11} height={5} className="fill-white" />
									<p className="max-w-[200px] text-sm font-bold">
										Superfan Benefits
									</p>
									<p className="my-[10px] max-w-[200px] text-sm">
										Each project has different benefits, so the more projects
										you support, the more unique benefits you can earn.
									</p>
									<p className="max-w-[200px] text-sm">
										The benefits you are eligible for are determined by your
										Superfan rank, which you can raise by referring new users to
										film.io!
									</p>
								</>
							}
						>
							<span className="cursor-pointer font-bold text-[#2196F3]">
								tell me more
							</span>
						</CustomTooltip>
						!
					</p>
				</div>
				{!data?.isSuperFan ? (
					<div className="flex flex-col gap-3 sm:gap-2">
						<h3 className="m-0 text-base font-semibold text-white">
							Instant Reward!
						</h3>
						<div className="rounded-lg border border-[2px] border-[#2196F3] bg-[#2196F3]/10 px-4 py-3 shadow-[0_0px_75px_rgba(54,186,59,1)]">
							<div className="flex items-center justify-between">
								<div className="flex items-center gap-4">
									<img src={packageOpenGold.src} alt="" className="h-10 w-10" />
									<div>
										<div className="text-sm font-bold text-[#F2BE2F]">
											Filmio Superfan Film Credit
										</div>
									</div>
								</div>
								<Button
									onClick={handleBecomeSuperfan}
									disabled={data?.isSuperFan}
									className="flex h-9 items-center justify-center gap-2 rounded-md bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] px-3 py-2 text-white"
								>
									Superfan Me!
									{data?.isSuperFan && (
										<CheckIcon className="h-5 w-5 text-green-500" />
									)}
								</Button>
							</div>
						</div>
						<span className="mt-0 w-full text-center text-sm">
							<strong>Superfans</strong> are forever immortalized in this
							project&apos;s end credits.
						</span>
					</div>
				) : (
					<div className="flex flex-row justify-between">
						<div className="align-center flex w-full rounded-lg border border-[#F2BE2F] bg-gradient-to-r from-[#8C421D] to-[#D4A041] p-2">
							<span className="w-full text-center text-sm font-bold">
								Your Superfan Rank #{rank}
							</span>
						</div>
						{rank !== 1 && (
							<div className="flex">
								<button
									onClick={() => setIsActionsDialogOpen(true)}
									className="ml-3 min-w-[135px] rounded-[0.4rem] bg-[#36BA3B] px-4 py-0 text-sm text-white disabled:opacity-50"
								>
									Raise your rank
								</button>
							</div>
						)}
					</div>
				)}

				<div className="flex flex-col gap-2">
					<div
						className={cn(
							'flex flex-col gap-2 sm:gap-2',
							data?.isSuperFan === false ? 'opacity-50' : ''
						)}
					>
						<div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
							<h3 className="m-0 mt-2 text-base font-semibold text-white">
								Active Superfan Perks
							</h3>
						</div>
						{data?.activePerks?.length > 0 ? (
							<div className="custom-scrollbar scrollbar-outset flex max-h-[140px] flex-col gap-4 overflow-y-auto md:max-h-[220px]">
								{data?.activePerks?.map((perk: any) => (
									<ActivePerkItem
										key={perk?.id}
										perk={perk}
										userRank={data?.superfanStats?.rank ?? 0}
									/>
								))}
							</div>
						) : (
							<div className="text-sm text-white/60">
								All active perks have been awarded
							</div>
						)}
					</div>

					{data?.isSuperFan === true && (
						<div className="flex flex-col gap-2">
							<h3 className="m-0 text-base font-semibold text-white">
								Your Superfan Perks
							</h3>
							{!noUserPerks ? (
								<div className="custom-scrollbar flex max-h-[125px] flex-col gap-2 overflow-y-auto md:max-h-[160px]">
									{data?.userPerks?.map((userPerk: any) => (
										<UserPerkItem
											key={userPerk?.id}
											userPerk={userPerk}
											isCredit={false}
										/>
									))}
									{creditPerk && (
										<UserPerkItem
											key={creditPerk?.id}
											userPerk={creditPerk}
											isCredit={true}
										/>
									)}
								</div>
							) : (
								<div className="text-sm text-white/60">
									You have no perks at this time
								</div>
							)}
						</div>
					)}

					<div className="flex flex-col gap-2">
						<h3 className="m-0 mt-4 text-base font-semibold text-white">
							Upcoming Superfan Perks
						</h3>
						{data?.upcomingPerks?.length > 0 ? (
							<div className="custom-scrollbar flex max-h-[90px] flex-col gap-4 overflow-y-auto md:max-h-[212px]">
								{data?.upcomingPerks?.map((perk: any, index: number) => (
									<UpcomingPerkItem
										key={perk?.id}
										perk={perk}
										className={index === 0 ? 'text-white/60' : 'text-white/20'}
									/>
								))}
							</div>
						) : (
							<div className="text-sm text-white/60">
								There are no upcoming perks at this time
							</div>
						)}
					</div>
				</div>
			</CustomDialog>
			<SuperfanMetricsDialog
				isOpen={isActionsDialogOpen}
				onOpenChange={setIsActionsDialogOpen}
				totalPoints={data?.superfanStats?.totalPoints}
			/>
			<PerkDialog />
		</>
	)
}
