import { Box, Button, Dialog, Typography } from '@mui/material'
import styles from './LockedGoscoreDialog.module.scss'
import { LockIcon } from 'lucide-react'

export const LockedGoscoreDialog = ({ open, handleClose }) => {
	return (
		<Dialog
			open={open}
			disableScrollLock={true}
			className={styles.locked_goscore_dialog}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleClose}
		>
			<Box className={styles.detail_dialog}>
				<Box className={styles.content}>
					<div className="align-center flex gap-2">
						<LockIcon className="h-5 w-5 text-white" />
						<h1 className="!text-[20px]">Why No GoScore?</h1>
					</div>
					<Typography>
						This project is currently in the Proof of Adoption stage and
						doesn&apos;t have a Go Score just yet. To complete this stage and
						earn a Go Score, the project needs to meet the required threshold of
						FAN tokens staked and unique stakers. Keep showing your support to
						help it reach the goal!
					</Typography>
					<Box className={styles.controls}>
						<Button onClick={handleClose}>Thanks</Button>
					</Box>
				</Box>
			</Box>
		</Dialog>
	)
}
