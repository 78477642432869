import { Grid } from '@mui/material'
import { ReviewItem } from './reviewItem'
import WriteReview from './writeReview/WriteReview'
import useReviewsTab from './useReviewsTab'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './ReviewsTab.module.scss'
import { ProjectEmptyContent } from '@/components/project/ProjectEmptyContent'

const ReviewsTab = () => {
	const {
		showWriteReview,
		reviews,
		isAuthenticated,
		handleOpenLoginModal,
		onScroll
	} = useReviewsTab()

	return (
		<InfiniteScroll {...{ onScroll, dataLength: 10000000 }}>
			<Grid container className={styles.reviewRatting} spacing={2}>
				<Grid item md={12} className="border-top">
					{isAuthenticated ? (
						<WriteReview hide={!showWriteReview} />
					) : (
						<ProjectEmptyContent
							emptyMessage={'Click to leave a review on this project'}
							onClick={handleOpenLoginModal}
						/>
					)}
					<div className="projectinfo">
						{reviews?.map(item => (
							<ReviewItem {...item} key={item.id} />
						))}
					</div>
				</Grid>
			</Grid>
		</InfiniteScroll>
	)
}

export default ReviewsTab
