import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ToastNotification {
	id: string
	title: string
	message: string
	type?: 'success' | 'error' | 'info'
	icon?: string
	timestamp?: number
	isExpanded?: boolean
}

interface ToastState {
	notifications: ToastNotification[]
	globalExpanded: boolean
}

const initialState: ToastState = {
	notifications: [],
	globalExpanded: false
}

export const toastSlice = createSlice({
	name: 'toast',
	initialState,
	reducers: {
		addToast: (state, action: PayloadAction<ToastNotification>) => {
			const toast = {
				...action.payload,
				id: action.payload.id ?? `toast-${Date.now()}`,
				timestamp: Date.now(),
				isExpanded: false
			}

			if (state.notifications.length >= 3) {
				state.notifications.pop()
			}
			const existingToast = state.notifications.find(t => t.id === toast.id)
			if (!existingToast) {
				state.notifications.unshift(toast)
			}
		},
		removeToast: (state, action: PayloadAction<string>) => {
			state.notifications = state.notifications.filter(
				toast => toast.id !== action.payload
			)
		},
		toggleToastExpand: (state, action: PayloadAction<string>) => {
			const toast = state.notifications.find(t => t.id === action.payload)
			if (toast) {
				toast.isExpanded = !toast.isExpanded
			}
		},
		toggleGlobalExpand: state => {
			state.globalExpanded = !state.globalExpanded
		},
		clearToasts: state => {
			state.notifications = []
			state.globalExpanded = false
		}
	}
})

export const {
	addToast,
	removeToast,
	toggleToastExpand,
	toggleGlobalExpand,
	clearToasts
} = toastSlice.actions

export default toastSlice.reducer
