import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import useAxios from '@/hooks/useAxios'
import { getProjectSuperfansService } from '@/services/project.service'
import { useSelector } from 'react-redux'
import { getFileUrl } from '@/utils/url.util'
import { cn } from '@/lib/utils'
import { Skeleton } from '@/components/ui/skeleton'
import { formatDateToRelative } from '@/utils/date.utils'
import { ScrollGradient } from '@/components/common-ui/ScrollGradient'
import { AvatarFilmio } from '@/components/common-ui/AvatarFilmio'
import { RootState } from '@/store/store'
import { queryKeys } from '@/lib/queryKeys'

interface UserFanLevel {
	fanLevelId: number
	ranking: number
	updatedAt: string
}

interface Superfan {
	id: string
	displayName: string
	avatar: string
	userFanLevel: UserFanLevel[]
}

interface CreditEntry {
	id: string
	name: string
	timestamp: string
	imageUrl?: string
	fanLevelId: number
	updatedAt: string
}

interface Props {
	className?: string
	projectId: string
}

function CreditSkeleton() {
	return (
		<div className="flex items-center gap-3 rounded-lg p-4">
			<Skeleton className="h-[38px] w-[38px] rounded-full" />
			<div className="flex-grow">
				<Skeleton className="h-4 w-24" />
			</div>
			<Skeleton className="h-4 w-28" />
		</div>
	)
}

export function ProjectCredits({ className, projectId }: Props) {
	const { axiosServiceSync } = useAxios()
	const { user, isAuthenticated } = useSelector(
		(state: RootState) => state.signIn
	)
	const { superfanUpdatedEvent } = useSelector(
		(state: RootState) => state.websocket
	)
	const queryClient = useQueryClient()
	const scrollRef = React.useRef<HTMLDivElement>(null)

	const { data: superfans, isLoading } = useQuery<Superfan[]>({
		queryKey: queryKeys.project.superfans(projectId),
		queryFn: async () =>
			await axiosServiceSync(getProjectSuperfansService(projectId))
	})

	const reloadSuperfans = () => {
		queryClient.invalidateQueries({
			queryKey: queryKeys.project.superfans(projectId)
		})
	}

	const sortedCredits = React.useMemo(() => {
		if (!superfans) return []

		const credits: CreditEntry[] = superfans.map(superfan => ({
			id: superfan.id,
			name: superfan.displayName,
			timestamp: formatDateToRelative(superfan.userFanLevel[0].updatedAt),
			fanLevelId: superfan.userFanLevel[0].fanLevelId,
			imageUrl: getFileUrl(superfan.avatar),
			updatedAt: superfan.userFanLevel[0].updatedAt
		}))

		const userCredit =
			isAuthenticated && user
				? credits.find(credit => credit.id === user.id)
				: null

		const otherCredits = credits
			.filter(credit => credit.id !== user?.id)
			.sort(
				(a, b) =>
					new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
			)

		return userCredit ? [userCredit, ...otherCredits] : otherCredits
	}, [superfans, isAuthenticated, user])

	useEffect(() => {
		if (superfanUpdatedEvent?.projectId === projectId) {
			reloadSuperfans()
		}
	}, [superfanUpdatedEvent])

	if (sortedCredits.length === 0) {
		return null
	}

	const loggedInUserCredit = user
		? sortedCredits.find(credit => credit.id === user.id)
		: null

	return (
		<div className={cn('relative', className)}>
			<div className="w-full max-w-lg rounded-lg bg-black px-4 py-6">
				<div className="text-center text-lg font-medium text-white">
					Filmio Superfan Film Credits
				</div>

				{/* User Credit - Outside ScrollArea */}
				{isLoading ? (
					<div className="mt-4">
						<div className="rounded-lg bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] p-[1px]">
							<div className="rounded-lg bg-[#0d1d2f]">
								<CreditSkeleton />
							</div>
						</div>
					</div>
				) : (
					loggedInUserCredit && (
						<div className="my-6">
							<div className="rounded-lg bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] p-[1px]">
								<div className="flex items-center gap-3 rounded-lg bg-[#0d1d2f] px-4 py-3">
									<AvatarFilmio
										avatar={loggedInUserCredit.imageUrl}
										displayName={loggedInUserCredit.name}
									/>
									<div className="flex-grow">
										<div className="text-sm font-medium text-white">
											{loggedInUserCredit.name}
										</div>
									</div>
									<span className="text-xs text-gray-400">
										{loggedInUserCredit.timestamp}
									</span>
								</div>
							</div>
						</div>
					)
				)}

				<ScrollGradient
					direction="vertical"
					containerRef={scrollRef}
					className="relative mt-3 h-[400px] overflow-hidden"
					contentClassName="scrollbar-hide"
					gradientStyles={{
						from: 'from-black',
						to: 'to-transparent',
						height: 'h-10'
					}}
				>
					<div className="scrollbar-hide animate-scroll-credits">
						{isLoading
							? Array.from({ length: 50 }).map((_, index) => (
									<CreditSkeleton key={index} />
								))
							: sortedCredits
									.filter(credit => credit.id !== loggedInUserCredit?.id)
									.map(credit => (
										<div
											key={credit.id}
											className={cn(
												'flex items-center gap-3 rounded-lg py-3 pl-3 pr-4',
												'transition-all duration-200 ease-in-out',
												'hover:scale-[1.02] hover:bg-white/5'
											)}
										>
											<AvatarFilmio
												avatar={credit.imageUrl}
												displayName={credit.name}
											/>
											<div className="flex-grow">
												<div className="text-sm font-medium text-white">
													{credit.name}
												</div>
											</div>
											<span className="text-xs text-gray-400">
												{credit.timestamp}
											</span>
										</div>
									))}
					</div>
				</ScrollGradient>
			</div>
		</div>
	)
}
