import { Heart, Star, Share, MessageSquare } from 'lucide-react'
import { Icon } from '@mui/material'
import { useYourImpactSection } from './useYourImpactSection'
import { StakeFormDialog } from '@/components/common-ui/stakeFormDialog/StakeFormDialog'
import { ShareMenu } from '@/components/common-ui/shareMenu/ShareMenu'
import { FollowProjectWrapper } from '@/components/common-ui/followProjectWrapper/FollowProjectWrapper'

const iconAttrs = { className: 'h-4 w-4 text-[#2196F3]', fill: '#2196F3' }
const iconContainerStyle =
	'flex items-center gap-2 text-sm text-white font-inter'

export const YourImpactSection = () => {
	const {
		project,
		isFollowedByUser,
		handleFollowClick,
		handleGoToReviewTab,
		handleGoToNewsreelTab
	} = useYourImpactSection()
	if (!project) return null

	return (
		<div className="flex flex-col gap-2">
			<div className="font-inter text-sm font-medium text-white">
				Your Impact on this project
			</div>
			<div className="flex items-center justify-between gap-2 rounded-md bg-[#2196F3] bg-opacity-20 px-3 py-2">
				<FollowProjectWrapper
					project={project}
					onConfirm={handleFollowClick(project?.id)}
				>
					<div className={iconContainerStyle}>
						<Heart
							{...{
								...iconAttrs,
								fill: isFollowedByUser ? 'currentColor' : 'none'
							}}
						/>
						{isFollowedByUser ? 'Following' : 'Follow'}
					</div>
				</FollowProjectWrapper>
				<div className={iconContainerStyle} onClick={handleGoToReviewTab}>
					<Star {...iconAttrs} />
					{project?.userProjectMetrics?.reviewRating}
				</div>
				<StakeFormDialog
					trigger={
						<div className={iconContainerStyle}>
							<Icon
								baseClassName="fa-kit"
								className="fa-fan-stake !h-4 !w-4 !text-[#2196F3]"
							/>
							{project?.userProjectMetrics?.stakesTotal} FAN
						</div>
					}
					selectedProject={{
						id: project.id,
						title: project.title,
						stageId: project.stageId,
						user: { id: project.user.id }
					}}
				/>

				<ShareMenu
					project={{
						title: project.title,
						shortUrl: project.shortUrl,
						poster: project.poster ?? ''
					}}
					trigger={
						<div className={iconContainerStyle}>
							<Share {...{ ...iconAttrs, fill: 'none' }} />
							{project?.userProjectMetrics?.sharesCount}
						</div>
					}
				/>
				<div className={iconContainerStyle} onClick={handleGoToNewsreelTab}>
					<MessageSquare {...{ ...iconAttrs, fill: 'none' }} />
					{project?.userProjectMetrics?.commentCount}
				</div>
			</div>
		</div>
	)
}
