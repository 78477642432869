import { Box, Typography, Button } from '@mui/material'
import newToken from 'assets/images/new_token_blue.svg'
import InfoDiamond from 'assets/images/diamond_icon_info.svg'
import { abbreviateNumberFixed } from 'utils/utils'
import useDiamondCircleHodl from './useDiamondCircleHodl'
import PropTypes from 'prop-types'
import { formatDate, getNowDateDiff } from 'utils/date.utils'
import styles from './DiamondCircleHodl.module.scss'

const DiamondCircleHodl = ({ changeTab }) => {
	const {
		hodlAmount,
		earningsToDate,
		type,
		startDate,
		endDate,
		balance,
		balanceTotal,
		stakesTotal
	} = useDiamondCircleHodl()

	const onClickChangeTab = () => {
		changeTab(1)
	}

	const nowDiff = getNowDateDiff(endDate).seconds
	const hodlPeriodCompleted = nowDiff > 0

	return (
		<Box className={styles.overview}>
			<Box className={styles.diamond_circle_HODL}>
				<Box className={styles.body}>
					<Box className={styles.top}>
						<Typography component={'h2'}>
							Diamond Circle HODL
							<span>
								<img alt="info Icon" src={InfoDiamond.src} />
							</span>
						</Typography>
					</Box>
					<Box className={styles.center}>
						<Typography component={'h1'}>
							<img alt="info Icon" src={newToken.src} />
							{abbreviateNumberFixed(hodlAmount, 1)}
						</Typography>
					</Box>
					<Box className={styles.bottom}>
						<Button onClick={onClickChangeTab} className={styles.back_to_main}>
							Back to Main Wallet
						</Button>
					</Box>
				</Box>
			</Box>
			<Box className={styles.bones_earings}>
				<Box className={styles.body}>
					<Box className={styles.top}>
						<Typography component={'h2'}>
							Bonus Earnings to Date
							<span>
								<img alt="info Icon" src={InfoDiamond.src} />
							</span>
						</Typography>
					</Box>
					<Box className={styles.center}>
						<Typography component={'h1'}>
							<img alt="info Icon" src={newToken.src} />
							{abbreviateNumberFixed(earningsToDate, 1)}
						</Typography>
					</Box>
					<Box className={styles.bottom}>
						<Box className={styles.btn_bonus}>
							<span className={styles.bonus}>{type?.hodlPeriod} Months</span>
							<span className={styles.bonus}>
								{type?.bonusMultiplier}% Bonus
							</span>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className={styles.both_section}>
				<Box className={styles.hodl_period}>
					<Box className={styles.body}>
						<Box className={styles.top}>
							<Typography component={'h2'} className={styles.header_token_hodl}>
								{!hodlPeriodCompleted
									? 'HODL Period:'
									: 'HODL Period Completed!'}
							</Typography>
						</Box>
						<Box className={styles.number_copy}>
							{!hodlPeriodCompleted ? (
								<>
									<Typography
										component={'h2'}
										className={styles.header_token_hodl}
									>
										{formatDate(startDate)}
									</Typography>
									{' - '}
									<Typography
										component={'h2'}
										className={styles.header_token_hodl}
									>
										{formatDate(endDate)}
									</Typography>
								</>
							) : (
								<Typography component={'h2'} className={styles.hodl_completed}>
									Your tokens will be released monthly over the next 12 months
								</Typography>
							)}
						</Box>
					</Box>
				</Box>
				<Box className={styles.balance_breakdown}>
					<Box className={styles.body}>
						<Box className={styles.top}>
							<Typography component={'h2'} className={styles.header_token_hodl}>
								Balance Breakdown:
								<span className={styles.inside_header}>
									{abbreviateNumberFixed(balanceTotal ?? 0, 2)}
								</span>
							</Typography>
						</Box>
						<Box className={styles.number_copy}>
							<Typography component={'h2'} className={styles.header_token_hodl}>
								{abbreviateNumberFixed(balance ?? 0, 2)} Available
							</Typography>
							<Typography component={'h2'} className={styles.header_token_hodl}>
								{abbreviateNumberFixed(stakesTotal ?? 0, 2)} Staked
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

DiamondCircleHodl.propTypes = {
	changeTab: PropTypes.func.isRequired
}

export default DiamondCircleHodl
