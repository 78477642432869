import PropTypes from 'prop-types'
import { Box, Drawer } from '@mui/material'
import styles from './MobileMenu.module.scss'
import { SidebarMenu } from '../sidebarMenu'

function MobileMenu({ open, setOpen }) {
	const toggleClose = () => {
		setOpen(!open)
	}

	return (
		<Box className={styles.mobile_menu}>
			<Drawer
				anchor={'left'}
				open={open}
				onClose={toggleClose}
				classes={{
					paperAnchorLeft: styles.test
				}}
			>
				<SidebarMenu {...{ handleClose: toggleClose }} />
			</Drawer>
		</Box>
	)
}

MobileMenu.propTypes = {
	isThereActiveContests: PropTypes.bool,
	setOpen: PropTypes.func,
	open: PropTypes.bool
}

export default MobileMenu
