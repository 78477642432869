import { Box, Button, Dialog, Typography } from '@mui/material'
import styles from './PerkProgramDialog.module.scss'
import packageClosedWhite from '@/assets/images/package-closed-white.svg'

export const PerkProgramDialog = ({ open, handleClose, isOnPerksProgram }) => {
	return (
		<Dialog
			open={open}
			disableScrollLock={true}
			className={styles.perk_program_dialog}
			modal="true"
			classes={{
				paper: styles.dialog_paper,
				container: styles.dialog_container
			}}
			onClose={handleClose}
		>
			<Box className={styles.detail_dialog}>
				<Box className={styles.content}>
					<div className="flex gap-2 align-center">
					<img
						src={packageClosedWhite.src}
						alt="Superfan Perks"
						className="h-7"
					/> 
					<h1 className="!text-[20px]">Superfan Perks</h1>
					</div>
					{isOnPerksProgram ? (
						<>
							<Typography component={'h1'}>
								What is the Filmio Superfan Perks Program?
							</Typography>
							<Typography>
								This project is part of the{' '}
								<strong>Filmio Superfan Perks Program</strong>! When you stake{' '}
								<strong>$FAN Tokens</strong> and follow along, you’re not just a
								fan—you’re a Superfan. Superfans get their name in the film’s
								end credits, forever immortalizing you as an early supporter of
								the project!
							</Typography>
							<Typography>
								Because this creator is participating in the Superfan Perks
								Program, by becoming a Superfan you’ll unlock access to more
								exclusive Perks including digital collectibles created just for
								top supporters like you.
							</Typography>
						</>
					) : (
						<>
							<Typography component={'h1'}>
								Not enrolled in the Superfan Perks Program!
							</Typography>
							<Typography>
								This project hasn’t yet joined the Filmio Superfan Perks Program and doesn’t offer rewards like a Superfan Film Credit or other exclusive perks. However, you can still become a Superfan and follow along!
							</Typography>
							<Typography>
								As a Superfan, you will be able to follow this project’s journey, help influence the project’s direction, enjoy increased staking rewards, as well as secure a place on the Leaderboard, showcasing your support!
							</Typography>
						</>
					)}
					<Box className={styles.controls}>
						<Button onClick={handleClose}>Thanks</Button>
					</Box>
				</Box>
			</Box>
		</Dialog>
	)
}
