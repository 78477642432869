import _ from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reloadDaoTiers } from '@/components/profile/profileSlice'
import useHeader from '@/components/header/useHeader'
import useMixpanel from './useMixpanel'
import useNotification from './useNotification'
import { reloadRecentActivity } from '@/components/profile/walletTab/walletTabSlice'
import { removeProjectsStakeInProgress, setFrontTransactionsInProgress } from '@/components/header/signInSlice'

const useCommonEvents = () => {
	const dispatch = useDispatch()
	const { stakeCompletedEvent } = useSelector(state => state.websocket)
	const { loadUserTierInfo, reloadUserInfo } = useHeader()
	const { addStakeMixPanelEvent, addFailStakeMixPanelEvent } = useMixpanel()
	const {
		completeNotificationExtended,
		closeNotificationExtended
	} = useNotification()

	useEffect(() => {
		if (!_.isNil(stakeCompletedEvent)) {
			loadUserTierInfo()
			const { transactionId, amount, blockchainHash, projectId } = stakeCompletedEvent
			if (_.isNil(blockchainHash)) {
				addFailStakeMixPanelEvent(transactionId, amount)
				closeNotificationExtended()
			} else {
				completeNotificationExtended()
				addStakeMixPanelEvent(transactionId, amount)
				dispatch(reloadDaoTiers())
			}

			dispatch(reloadRecentActivity())
			reloadUserInfo()
			dispatch(setFrontTransactionsInProgress(false))
			dispatch(removeProjectsStakeInProgress(projectId))
		}
	}, [stakeCompletedEvent])

}

export default useCommonEvents
