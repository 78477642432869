import { configureStore } from '@reduxjs/toolkit'
import dashboardReducer from '@/components/dashboard/dashboardSlice'
import signInReducer from '@/components/header/signInSlice'
import vestingReducer from '@/components/profile/walletTab/vestingOverwiew/vestingSlice'
import projectDetailReducer from '@/components/projectDetail/projectDetailSlice'
import notificationReducer from '@/components/common/notification/notificationSlice'
import profileReducer from '@/components/profile/profileSlice'
import projectEditReducer from '@/components/projectEdit/projectEditSlice'
import newsreelTabReducer from '@/components/common/newsreelTab/newsreelTabSlice'
import profileEditReducer from '@/components/profileEdit/profileEditSlice'
import tileManagementReducer from '@/components/tileManagement/tileManagementSlice'
import settingsReducer from '@/components/settings/settingsSlice'
import walletTabReducer from '@/components/profile/walletTab/walletTabSlice'
import superfanReducer from '@/components/projectDetail/dashboardTab/superfan/superfanSlice'
import claimFgrReducer from '@/components/header/claimFgrDialog/claimFgrSlice'
import reactionsReducer from '@/components/common/reactions/reactionSlice'
import projectAddWizardReducer from '@/components/projectAddWizard/projectAddWizardSlice'
import newsreelPageReducer from '@/components/newsreelPage/newsreelPageSlice'
import tokenSaleExperienceReducer from '@/components/tokenSaleExperience/tokenSaleExperienceSlice'
import captchaCheckReducer from '@/components/common/captchaCheck/captchaCheckSlice'
import websocketReducer from 'hooks/websocket/webSocketSlice'
import notificationDialogReducer from '@/components/common-ui/notificationDialog/notificationSlice'
import toastNotificationReducer from '@/components/common-ui/toastNotification/toastNotificationSlice'
import modalReducer from './modalSlice'

export const makeStore = () => {
	return configureStore({
		reducer: {
			signIn: signInReducer,
			profile: profileReducer,
			settings: settingsReducer,
			claimFgr: claimFgrReducer,
			walletTab: walletTabReducer,
			dashboard: dashboardReducer,
			newsreelPage: newsreelPageReducer,
			projectDetail: projectDetailReducer,
			projectAddWizard: projectAddWizardReducer,
			tokenSaleExperience: tokenSaleExperienceReducer,
			notificationDialog: notificationDialogReducer,
			toastNotification: toastNotificationReducer,
			tileManagement: tileManagementReducer,
			captchaCheck: captchaCheckReducer,
			notification: notificationReducer,
			projectEdit: projectEditReducer,
			newsreelTab: newsreelTabReducer,
			profileEdit: profileEditReducer,
			websocket: websocketReducer,
			reactions: reactionsReducer,
			superfan: superfanReducer,
			vesting: vestingReducer,
			modal: modalReducer
		}
	})
}

export type RootState = ReturnType<ReturnType<typeof makeStore>['getState']>
