import { useShareMenu } from './useShareMenu'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import { Input } from '@/components/ui/input'
import { cn } from '@/lib/utils'
import { ReactNode, useState } from 'react'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
	DropdownMenuPortal
} from '@/components/ui/dropdown-menu'
import { Label } from '@/components/ui/label'
import { CustomDialog } from '../CustomDialog'
import { getFileUrl } from '@/utils/url.util'

interface ShareMenuProps {
	project: {
		title: string
		shortUrl: string
		poster: string
	}
	trigger: ReactNode
}

export const ShareMenu = ({ project, trigger }: ShareMenuProps) => {
	const [isOpen, setIsOpen] = useState(false)

	function closeMenu() {
		setIsOpen(false)
	}

	const {
		open,
		link,
		message,
		limitChars,
		openDialog,
		setMessage,
		handleClose,
		handleCopy,
		handleCopyMobile,
		handleCopyLink
	} = useShareMenu({ closeMenu, project })

	return (
		<>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>{trigger}</DropdownMenuTrigger>
				<DropdownMenuPortal>
					<DropdownMenuContent
						className="w-56 text-white"
						side="bottom"
						sideOffset={5}
						align="end"
					>
						<DropdownMenuItem
							onSelect={handleCopyLink}
							disabled={isOpen}
							className={cn(
								'flex cursor-pointer items-center space-x-2 hover:opacity-70',
								isOpen && 'cursor-not-allowed opacity-50'
							)}
						>
							<i className="fas fa-envelope mr-2 h-4 w-4" />
							<span>
								Copy <strong>Link</strong>
							</span>
						</DropdownMenuItem>

						<DropdownMenuItem
							onSelect={() => openDialog('custom')}
							disabled={isOpen}
							className={cn(
								'flex cursor-pointer items-center space-x-2 hover:opacity-70',
								isOpen && 'cursor-not-allowed opacity-50'
							)}
						>
							<i className="fas fa-hammer-brush mr-2 h-4 w-4" />
							<span>
								Custom <strong>Share</strong>
							</span>
						</DropdownMenuItem>

						<DropdownMenuItem
							onSelect={() => openDialog('x')}
							disabled={isOpen}
							className={cn(
								'flex cursor-pointer items-center space-x-2 hover:opacity-70',
								isOpen && 'cursor-not-allowed opacity-50'
							)}
						>
							<i className="fab fa-x-twitter mr-2 h-4 w-4" />
							<span>
								Share on <strong>Twitter/X</strong>
							</span>
						</DropdownMenuItem>

						<DropdownMenuItem
							onSelect={() => openDialog('facebook')}
							disabled={isOpen}
							className={cn(
								'flex cursor-pointer items-center space-x-2 hover:opacity-70',
								isOpen && 'cursor-not-allowed opacity-50'
							)}
						>
							<i className="fab fa-facebook-f mr-2 h-4 w-4" />
							<span>
								Share on <strong>Facebook</strong>
							</span>
						</DropdownMenuItem>

						<DropdownMenuItem
							onSelect={() => openDialog('linkedin')}
							disabled={isOpen}
							className={cn(
								'flex cursor-pointer items-center space-x-2 hover:opacity-70',
								isOpen && 'cursor-not-allowed opacity-50'
							)}
						>
							<i className="fab fa-linkedin mr-2 h-4 w-4" />
							<span>
								Share on <strong>LinkedIn</strong>
							</span>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenuPortal>
			</DropdownMenu>
			<CustomDialog
				open={open}
				onOpenChange={handleClose}
				className="max-w-[335px]"
				title={'Share'}
				titleClassName="sm:text-xl font-semibold w-full text-center block"
			>
				<div className="space-y-4">
					<div className="flex justify-center">
						<img
							className="max-h-48 max-w-full rounded-lg"
							src={getFileUrl(project.poster)}
							alt="poster"
						/>
					</div>

					<Label>Message</Label>
					<Textarea
						placeholder="Message"
						value={message}
						maxLength={limitChars}
						onChange={e => setMessage(e.target.value)}
						className="custom-scrollbar w-full text-base"
						rows={4}
					/>

					<a
						href="#"
						onClick={handleCopyMobile}
						className="flex w-full items-center justify-end space-x-2 text-sm text-white hover:cursor-pointer sm:hidden"
					>
						<i className="far fa-copy"></i>
						<span>Copy your Message</span>
					</a>
					<span className="flex items-center space-x-2 text-sm text-white">
						Link
					</span>
					<div className="mt-2 text-gray-800">
						<Input
							value={link}
							readOnly
							className="w-full cursor-not-allowed text-white opacity-60"
						/>
					</div>
					<div className="text-sm">
						When you click <b>share</b>, your message will be copied to the
						clipboard. Simply paste it into the share box on your social network
						of choice.
					</div>
					<div className="flex justify-end space-x-2">
						<Button variant="secondary" onClick={handleClose}>
							Cancel
						</Button>
						<Button
							variant="outline"
							className="text-white"
							onClick={handleCopy}
						>
							Share!
						</Button>
					</div>
				</div>
			</CustomDialog>
		</>
	)
}
