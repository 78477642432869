'use client'

import * as React from 'react'
import medal from '@/assets/images/medal-2.svg'
import { Icon } from '@mui/material'
import { Heart, HelpCircle, MessageCircle, Share, Star } from 'lucide-react'
import { Button } from '@/components/ui/button'
import {
	Tooltip,
	TooltipArrow,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger
} from '@/components/ui/tooltip'
import useAxios from '@/hooks/useAxios'
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from '@/lib/queryKeys'
import { getSuperfanActions } from '@/services/superfan.service'
import { MilestoneProgress } from '../milestoneProgress'
import useProjectCommon from '../../useProjectCommon'
import { CustomDialog } from '@/components/common-ui/CustomDialog'

/**
 * Type for reward action names
 */
type RewardActionName =
	| 'Follow'
	| 'Review'
	| 'Stake'
	| 'Non-Verified Referral'
	| 'KYC-Verified Referral'
	| 'Referral Fan Purchase'
	| 'Referral DAO'
	| 'Newsreels Comments/Reactions'

/**
 * Interface for reward action data
 */
interface RewardAction {
	id: number
	name: RewardActionName
	points: number
	limit: number | null
	limitDurationDays: number | null
	minimalLimit: number | null
}

/**
 * Interface for action configuration
 */
interface ActionConfig {
	label: React.ReactNode
	icon: React.ReactNode
	tooltip: string
}

/**
 * Combined mapping of all action properties
 */
const actionConfigs: Record<RewardActionName, ActionConfig> = {
	Follow: {
		label: <b>Follow</b>,
		icon: <Heart className="size-4 sm:size-5" />,
		tooltip:
			'Follow this project to stay updated with its content and earn more Superfan points.'
	},
	Review: {
		label: <b>Review</b>,
		icon: <Star className="size-4 sm:size-5" />,
		tooltip:
			'Write a thoughtful review to help this project’s Go Score and earn more Superfan points.'
	},
	Stake: {
		label: (
			<>
				<b>Stake</b> per FAN token
			</>
		),
		icon: <Icon baseClassName="fa-kit" className="fa-fan-stake !h-5 !w-5" />,
		tooltip:
			'Stake your FAN tokens to help tis project’s Go Score and earn more Superfan points. You will receive points for every FAN token staked.'
	},
	'Non-Verified Referral': {
		label: <b>Referral</b>,
		icon: <Share className="size-4 sm:size-5" />,
		tooltip:
			'Invite friends to join the platform and earn more Superfan points when they join through your referral link.'
	},
	'KYC-Verified Referral': {
		label: (
			<>
				<b>Referral</b> with KYC
			</>
		),
		icon: <Share className="size-4 sm:size-5" />,
		tooltip:
			'Get bonus Superfan points when your referrals complete KYC verification.'
	},
	'Referral Fan Purchase': {
		label: (
			<>
				<b>Referral</b> with FAN token purchase
			</>
		),
		icon: <Share className="size-4 sm:size-5" />,
		tooltip:
			'Get bonus Superfan points when your referrals make FAN token purchases. You will receive 1 point per dollar they spend purchasing tokens on the platform.'
	},
	'Referral DAO': {
		label: (
			<>
				<b>Referral</b> with Silver DAO Tier
			</>
		),
		icon: <Share className="size-4 sm:size-5" />,
		tooltip:
			'Get bonus Superfan points when your referrals reach Silver DAO Tier.'
	},
	'Newsreels Comments/Reactions': {
		label: <b>Comments & Reactions</b>,
		icon: <MessageCircle className="size-4 sm:size-5" />,
		tooltip:
			'Engage with this project’s newsreel posts to help make a more vibrant community and earn more Superfan points.'
	}
} as const

interface ActionItemProps {
	action: RewardAction
	isEven: boolean
}

/**
 * Component for displaying individual action items
 */
const ActionItem: React.FC<ActionItemProps> = ({ action }) => {
	const config = actionConfigs?.[action?.name]

	return (
		<div
			className={`flex h-[50px] items-center justify-between px-3 odd:bg-[#0e1c29] even:bg-[#1a2734]`}
		>
			<div className="flex items-center gap-3">
				{config?.icon}
				<div className="text-xs leading-none sm:text-base">{config?.label}</div>
			</div>
			<div className="flex items-center gap-2 text-base">
				<div className="flex items-center gap-1">
					<span className="text-xs tabular-nums sm:text-base">
						{action?.points}
					</span>
					<span className="text-yellow-500">
						<img src={medal?.src} alt="" className="z-10 h-7 w-7" />
					</span>
				</div>
				<Tooltip>
					<TooltipTrigger asChild>
						<HelpCircle className="size-4 text-muted-foreground hover:text-white sm:size-5" />
					</TooltipTrigger>
					<TooltipContent
						side="left"
						className="max-w-64 bg-background p-3 text-center font-semibold text-white"
					>
						<TooltipArrow className="fill-background" />
						{config?.tooltip}
					</TooltipContent>
				</Tooltip>
			</div>
		</div>
	)
}

interface Props {
	isOpen: boolean
	onOpenChange: (_isOpen: boolean) => void
	totalPoints: number
}

export function SuperfanMetricsDialog({
	isOpen,
	onOpenChange,
	totalPoints
}: Props) {
	const { axiosServiceSync } = useAxios()

	const {
		data: actions,
		isLoading,
		error
	} = useQuery<RewardAction[]>({
		queryKey: queryKeys.project.superfanActions(),
		queryFn: async () => {
			const data = await axiosServiceSync(getSuperfanActions())
			return data
		},
		staleTime: 1000 * 60 * 60 * 24 // 24 hours
	})

	const { project } = useProjectCommon()
	if (!project) return null

	const renderContent = () => {
		if (isLoading) {
			return null
		}

		if (error) {
			return (
				<div className="p-4 text-center text-red-500">
					Error loading actions. Please try again.
				</div>
			)
		}

		if (!actions?.length) {
			return (
				<div className="p-4 text-center">
					No actions available at the moment.
				</div>
			)
		}

		return actions.map((action, index) => (
			<ActionItem key={action.id} action={action} isEven={index % 2 === 0} />
		))
	}

	return (
		<CustomDialog
			open={isOpen}
			onOpenChange={onOpenChange}
			className="max-h-[90svh] max-w-[450px]"
			title={'How to climb the Superfan leaderboard'}
		>
			<TooltipProvider>
				<div className="text-start text-sm text-white">
					Earn Superfan Points to earn perks, exclusive offers and more!
				</div>

				<div className="flex flex-col gap-5">
					<div className="flex items-center justify-between">
						<span className="font-inter text-sm font-bold text-white">
							Milestone Progress
						</span>
						<div className="flex items-center gap-1">
							<img src={medal?.src} alt="" className="z-10 h-10" />
							<span className="relative -top-1 -ml-4 rounded-sm bg-[#2196F3] bg-opacity-40 py-1 pl-5 pr-2 font-inter text-sm font-bold text-white">
								{totalPoints}
							</span>
						</div>
					</div>
					<MilestoneProgress {...{ value: totalPoints }} />
				</div>

				<div className="space-y-2 overflow-hidden">
					<div className="rounded-md bg-[#193350] px-3 py-2 text-center text-sm font-semibold">
						Earn Superfan Points through these actions
					</div>
					<div className="max-h-[176px] overflow-scroll md:max-h-full md:overflow-auto">
						{renderContent()}
					</div>
				</div>

				<Button
					onClick={() => onOpenChange(false)}
					className="mx-auto flex items-center justify-center gap-2 rounded-md bg-gradient-to-r from-[#6B3BFA] to-[#F11E76] p-6 font-inter text-base font-semibold text-[#FAFAFA]"
				>
					Good to know!
				</Button>
			</TooltipProvider>
		</CustomDialog>
	)
}
