import { createSlice, current } from '@reduxjs/toolkit'
import { removeDuplicates } from 'utils/utils'

interface Newsreel {
	id: string
	comments: any[]
	content: string
	createdAt: string
	media: string[]
	projectId: string
	userId: string
	user: {
		id: string
		username: string
		displayName: string
		avatar: string
	}
}

const initialState: {
	newsreels: Newsreel[]
	newsreelId: string | null
	page: {
		value: number
		reload: boolean
	}
} = {
	newsreels: [],
	newsreelId: null,
	page: {
		value: 1,
		reload: true
	}
}

export const newsreelTabSlice = createSlice({
	name: 'newsreelTab',
	initialState,
	reducers: {
		resetNewsreels: () => initialState,
		updateNewsreels: (state, { payload }) => {
			const oldNewsreels = [...current(state.newsreels)]
			state.newsreels = removeDuplicates([...oldNewsreels, ...payload])
		},
		setNewsreelPage: (state, { payload }) => {
			state.page = { value: payload, reload: !state.page.reload }
		},
		newsreelTabAddedHook: state => {
			state.newsreels = []
			state.page = { value: 1, reload: !state.page.reload }
		},
		newsreelCommentAddedHook: (state, { payload }) => {
			const allNesreels = [...current(state.newsreels)]
			const newsreelUpdated = allNesreels.map(newsreel => {
				if (newsreel?.id === payload?.feedId) {
					return {
						...newsreel,
						comments: [payload, ...newsreel?.comments]
					}
				}
				return newsreel
			})
			state.newsreels = newsreelUpdated
		}
	}
})

export const {
	resetNewsreels,
	updateNewsreels,
	setNewsreelPage,
	newsreelTabAddedHook,
	newsreelCommentAddedHook
} = newsreelTabSlice.actions

export default newsreelTabSlice.reducer
