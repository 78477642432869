import { Grid, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import styles from './Hero.module.scss'
import shine from 'assets/images/homepage/shine.webp'

const Hero = () => {
	const navigate = useNavigate()

	const handleGoToExplore = () => {
		navigate('/explore')
	}

	return (
		<>
			<Grid container className={styles.hero}>
				<h2>
					<span>Democratize</span>
					<br />
					Entertainment.
				</h2>
				<Button className={styles.explore_btn} onClick={handleGoToExplore}>
					Explore Projects
				</Button>
				<img className={styles.shine} src={shine.src} alt="" />
			</Grid>
		</>
	)
}

export default Hero
